export const applicationID = '1K3K4H5N6BV69084J3UEYS63485091KL';

export const userName = JSON.parse(sessionStorage.getItem("MenuDataSource")) ? 
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].userName : "";

export const loginName = JSON.parse(sessionStorage.getItem("MenuDataSource")) ? 
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].login : "";

export const relationId = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].relationId : ""

export const equipeId = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].equipeID : ""

export const specialPermissions = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].specialPermissions : ""

export const equipeNome = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].equipeNome : ""

export const CobrarAgendamentoHorarioProgramado = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].CobrarAgendamentoHorarioProgramado : ""

export const ramal = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].Ramal : ""

export const securityGroupId = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0]._SecurityGroupId : ""

export const haveUpdate = securityGroupId === "0984KLJTN43BVG234I6KB5REW874PO98" ? true : false

export const URLLogin = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].URLLogin : ""

export const URLLoginAbrirPopup = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].URLLoginAbrirPopup : ""

export const isPausarDiscadorPesquisa = sessionStorage.getItem("isPausarDiscadorPesquisa") ?
sessionStorage.getItem("isPausarDiscadorPesquisa") : ""

export const discadorId = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].discadorId : ""

//testar quando o usuario n tiver pausa quando vai pesquisar
// export const isPausarDiscadorPesquisa = 0

export const DiscadorAtivo = JSON.parse(sessionStorage.getItem("MenuDataSource"))?.[0]?.URLLogin ?
true : false

export const permissions = JSON.parse(sessionStorage.getItem("MenuDataSource")) ?
JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].permissions : ""

export const revolutionVersion = `${process.env.REACT_APP_VERSION}`