import UpfHtmlEditor from "../UpfComponents/UpfHtmlEditor";
import { formatFunction } from "../../functions/formatFunction";

export function getColumnProperties(props) {
    const obj = props.obj;
    
    function dxLookupSelect() {
        if (obj.lookUpKeyField !== null && props.dataLookup) {                            
            return {
                fixed: obj.fixed,
                visible: obj.gridVisible === 1,
                groupIndex: obj.groupIndex,
                defaultSortOrder: obj.sortOrder,
                defaultSortIndex: obj.sortIndex,
                showInColumnChooser: obj.gridVisibleForCustomization === 1,
                dataField: obj.fieldName,
                allowEditing: obj.fieldName === "_ocorrenciaid" ? false : (obj.readOnly !== 1),
                caption: obj.fieldCaption,
                dataType: obj.fieldType,
                lookup: {
                    dataSource: props.dataLookup[obj.lookUpListSource],
                    valueExpr: obj.lookUpKeyField,
                    displayExpr: obj.lookUpResultField
                }
            }
        }
    }

    function dxDateBoxCalendar() {
        const dateOpt = {
            datetime: "shortDateShortTime",
            date: "shortDate"
        };

        return {
            fixed: obj.fixed,
            visible: obj.gridVisible === 1,
            groupIndex: obj.groupIndex,
            defaultSortOrder: obj.sortOrder,
            defaultSortIndex: obj.sortIndex,
            showInColumnChooser: obj.gridVisibleForCustomization === 1,
            dataField: obj.fieldName,
            allowEditing: obj.readOnly !== 1,
            caption: obj.fieldCaption,
            dataType: obj.fieldType,
            format: dateOpt[obj.fieldType]
        }
    }

    function upfHtmlViewerBox() {
       return {
            fixed: obj.fixed,
            visible: obj.gridVisible === 1,
            groupIndex: obj.groupIndex,
            defaultSortOrder: obj.sortOrder,
            defaultSortIndex: obj.sortIndex,
            showInColumnChooser: obj.gridVisibleForCustomization === 1,
            dataField: obj.fieldName,
            allowEditing: obj.readOnly !== 1,
            caption: obj.fieldCaption,
            dataType: obj.fieldType,
            cellRender: (data) => {
                return <UpfHtmlEditor
                            referencia={props?.dataGridRef?.current?.instance}
                            setFormDataJson={props.setFormDataJson}
                            setDataEntity={props.setDataEntity}
                            dataEntity={data.data}
                            entityStructor={obj}
                            colSpan={obj.colSpan}
                            caption={obj.fieldCaption}
                            dataField={obj.fieldName}
                            disabled={!props.modeEditDataGrid?.edit}
                            hint={obj.hint}
                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                            showEditBar={false}
                            cellData={data}
                        />
            }
        }
    }

    const columnType = {
        "dxSelectBox": dxLookupSelect,
        "dxLookup": dxLookupSelect,
        "dxCheckBox": () => {
            return {
                fixed: obj.fixed,
                visible: obj.gridVisible === 1,
                groupIndex: obj.groupIndex,
                defaultSortOrder: obj.sortOrder,
                defaultSortIndex: obj.sortIndex,
                showInColumnChooser: obj.gridVisibleForCustomization === 1,
                dataField: obj.fieldName,
                allowEditing: obj.readOnly !== 1,
                caption: obj.fieldCaption,
                dataType: "boolean" 
            }
        },
        "dxDateBox": dxDateBoxCalendar,
        "dxCalendar": dxDateBoxCalendar,
        "upfTime": () => {
            return {
                fixed: obj.fixed,
                visible: obj.gridVisible === 1,
                groupIndex: obj.groupIndex,
                defaultSortOrder: obj.sortOrder,
                defaultSortIndex: obj.sortIndex,
                showInColumnChooser: obj.gridVisibleForCustomization === 1,
                dataField: obj.fieldName,
                allowEditing: obj.readOnly !== 1,
                caption: obj.fieldCaption,
                dataType: "datetime",
                format: "shortTime"
            }
        },
        "upfHtmlViewer": upfHtmlViewerBox,
        "upfHtmlBox": upfHtmlViewerBox,
        "default": () => {
            return {
                fixed: obj.fixed,
                visible: obj.gridVisible,
                showInColumnChooser: obj.gridVisibleForCustomization === 1,
                dataField: obj.fieldName,
                groupIndex: obj.groupIndex,
                sortOrder: obj.sortOrder,
                allowEditing: (obj.readOnly !== 1),
                caption: obj.fieldCaption,
                dataType: obj.fieldType,
                format: formatFunction(obj.fieldMask, "column", obj.fieldName, props.masterEntity, false)
            }          
        }
    }

    return columnType[obj.editorType]
    ? columnType[obj.editorType]()
    : columnType["default"]()
}