import { relationId } from "../api/ApplicationID"

export const changeDataRowColor = (e, data) => {

    if (e.rowType === "data") {
        if (data) {
            for (let count = 0; count <= data.length - 1; count++) {
                let expr = Number(data[count].expr) ? Number(data[count].expr) : data[count].expr
                let fieldValue = Number(e.data[data[count].fieldName]) ? Number(e.data[data[count].fieldName]) : e.data[data[count].fieldName]
                let color = data[count].color
                let operator = data[count].operator

                if (expr == null) {
                    expr = ""
                }

                if (fieldValue == null) {
                    fieldValue = ""
                }

                if(expr !== undefined && fieldValue !== undefined){
                    if(expr === "{date}"){
                        let dataAtual = new Date()
                        dataAtual.setHours(0, 0, 0, 0)
                        
                        expr = dataAtual.getTime()
                       
                        fieldValue = Date.parse(fieldValue)
                    }
                    else if(expr === "{relationId}"){
                        expr = relationId
                    }

                    if (e.cellElement.style.backgroundColor !== color) {
                        
                        if(operator === "="){
                            if (fieldValue == expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        else if(operator === "<"){
                            if (fieldValue < expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        else if(operator === ">"){
                            if (fieldValue > expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        else if(operator === "<="){
                            if (fieldValue <= expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        else if(operator === ">="){
                            if (fieldValue <= expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        else if(operator === "<>"){
                            if (fieldValue !== expr) {
                                e.cellElement.style.backgroundColor = color
                                e.cellElement.style.color = "black"
                            }
                        }
                        
                    }
                }
            }
        }
    }
}