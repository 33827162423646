import ScrollView from 'devextreme-react/scroll-view';
import ConsultaBoletoForm from "../../../ConsultaBoletoForm"
import { Popup } from 'devextreme-react/popup';
import { useEffect, useState, memo } from 'react';
import { fecharPausa } from '../../../../functions/fecharPausa';
import { useDiscadorEmAtendimentoGlobalState } from '../../../../Context/DiscadorEmAtendimentoGlobalState';
import { useProximaFichaDados } from '../../../../Context/ProximaFichaDados';
import { useTabPanelItems } from '../../../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../../../Context/IndexTabPanelMain';
import { useAbrirPausaResultJsonGlobalState } from '../../../../Context/AbrirPausaResultJsonGlobalState';
import { usePausaEscolhidaDadosGlobalState } from '../../../../Context/PausaEscolhidaDadosGlobalState';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { useFichaPesquisadaNaTelaGlobalState } from '../../../../Context/FichaPesquisadaNaTelaGlobalState';
import { httpRequest } from '../../../../functions';
import { applicationID } from '../../../../api/ApplicationID';
import UpfPopupMessage from '../../../../CardComponents/UpfPopupMessage';

const Passo3 = (props) => {

    const { requestsApi } = useRequestsApi()
    const {discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState} = useDiscadorEmAtendimentoGlobalState()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState } = useAbrirPausaResultJsonGlobalState()
    const {pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState} = usePausaEscolhidaDadosGlobalState()
    const {fichaPesquisadaNaTelaGlobalState, setFichaPesquisadaNaTelaGlobalState} = useFichaPesquisadaNaTelaGlobalState()

    const limparDadadosProximaFicha = () => {
        if(proximaFichaDados?.tituloId){
            setProximaFichaDados({...proximaFichaDados,
                tituloId: null,
                templateTelecobrancaId: null,
                widgets: null,
                // indexFichaAtual: 0,
                // indexRegraAtual: 0,
                nomeFilaAtual: null, 
                nomeRegraAtual: null,
                credorId: "",
                clienteId: null,
                _qtdOutrosContratosClienteCredorAtual: 0,
                _qtdOutrosContratosCliente: 0
              })
        }
    } 

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    const [popupBoletoVisibleState, setPopupBoletoVisibleState] = useState(false)


    useEffect(() => {
        if(props.gerarBoletoDataSource?.boleto && props.indexMultiViewItemsGerarBoleto === 2){
            sessionStorage.setItem("_gerouBoletoTelecob", "true")
            setPopupBoletoVisibleState(true)
        }
    }, [props.gerarBoletoDataSource])


    const hidePopupBoleto = () => {
        try{
            const _configuracaoCredor = JSON.parse(sessionStorage.getItem("_configuracaoCredor"))
            const _gerouBoletoTelecob = sessionStorage.getItem("_gerouBoletoTelecob")

            console.log(_configuracaoCredor?.[0]?._finalizaratendimentogerarboleto)


            if(_configuracaoCredor?.[0]?._finalizaratendimentogerarboleto === 1 && _gerouBoletoTelecob === "true"){
                sessionStorage.removeItem("_configuracaoCredor")
                sessionStorage.setItem("_gerouBoletoTelecob", "false")
                // sessionStorage.removeItem("_gerouBoletoTelecob")
                //encerrar atendimento
                fecharPausa(
                    discadorEmAtendimentoGlobalState
                    ,fichaPesquisadaNaTelaGlobalState
                    ,httpRequest
                    ,requestsApi
                    ,abrirPausaResultJsonGlobalState
                    ,applicationID
                    ,setDiscadorEmAtendimentoGlobalState
                    ,setFichaPesquisadaNaTelaGlobalState
                    ,tabPanelItems
                    ,setTabPanelItems
                    ,setIndexTabPanelMain
                    ,pausaEscolhidaDadosGlobalState
                    ,null //props.toastConfiguration
                    ,setPopupErrorMessageState
                    ,setPopupErrorVisibleState
                    ,limparDadadosProximaFicha
                    ,setAbrirPausaResultJsonGlobalState
                    ,null //setDesabilitarBotaoGravarOcorrenciaState
                    ,props.abertoMenuTelecob //telecob
                    ,props.tituloId
                )

                //fecha popup
                setPopupBoletoVisibleState(false)

            }
            else{
                sessionStorage.setItem("_gerouBoletoTelecob", "false")
                setPopupBoletoVisibleState(false)

            }
        }
        catch{
            sessionStorage.setItem("_gerouBoletoTelecob", "false")
            setPopupBoletoVisibleState(false)
        }
    }

    return(
        <>
        {props.gerarBoletoDataSource &&
            <>
                {props.abertoMenuTelecob ?
                    (
                        <Popup
                            visible={popupBoletoVisibleState}
                            onHiding={() => {
                                hidePopupBoleto()
                            }}
                            dragEnabled={false}
                            // closeOnOutsideClick={true}
                            showCloseButton={true}
                            showTitle={true}
                            title="Boleto"
                            fullScreen={true}
                            resizeEnabled={true}
                        >
                            <ScrollView  width='100%' height='100%' useNative={true}>
                                <UpfPopupMessage
                                    hidePopup={hidePopupError}
                                    visible={popupErrorVisibleState}
                                    message={popupErrorMessageState}
                                />
                                <ConsultaBoletoForm 
                                    boletoId={props.gerarBoletoDataSource?.boleto}
                                    email={props.gerarBoletoDataSource?.email}
                                    telefones={props.gerarBoletoDataSource?.telefones}
                                    dataLookup={props.gerarBoletoDataSource?.dataLookup}
                                    masterEntityId={props.gerarBoletoDataSource?.masterEntityId}
                                    dataSourceBoletoState={props.gerarBoletoDataSource}
                                    setIndexMultiViewItemsGerarBoleto={props.setIndexMultiViewItemsGerarBoleto}
                                    indexMultiViewItemsGerarBoleto={props.indexMultiViewItemsGerarBoleto}
                                    hideUpfPopupForm={props.hideUpfPopupForm}
                                />
                            </ScrollView>
                        </Popup>
                    )
                    :
                    (
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <ConsultaBoletoForm 
                                boletoId={props.gerarBoletoDataSource?.boleto}
                                email={props.gerarBoletoDataSource?.email}
                                telefones={props.gerarBoletoDataSource?.telefones}
                                dataLookup={props.gerarBoletoDataSource?.dataLookup}
                                masterEntityId={props.gerarBoletoDataSource?.masterEntityId}
                                dataSourceBoletoState={props.gerarBoletoDataSource}
                                setIndexMultiViewItemsGerarBoleto={props.setIndexMultiViewItemsGerarBoleto}
                                indexMultiViewItemsGerarBoleto={props.indexMultiViewItemsGerarBoleto}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                            />
                        </ScrollView>
                    )
                }
            </>
        }
        </>
    )
}

export default memo(Passo3);