import {useState, useEffect, useRef, useCallback} from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import Loading from "../../../CardComponents/Loading";
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { useProximaFichaDados } from '../../../Context/ProximaFichaDados';
import { applicationID, relationId } from '../../../api/ApplicationID';
import ScrollView from 'devextreme-react/scroll-view';
import { Toast } from 'devextreme-react/toast';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import Funnel, { Export } from 'devextreme-react/funnel';
import HtmlFormPopup from "../../HtmlFormPopup";
import { fecharFormAtual } from "../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    TotalItem
    } from 'devextreme-react/data-grid';
import PopupDesconto from "./PopupDesconto";

const Passo2 = (props) => {
    console.log(props)
    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [gerouAcordo, setGerouAcordo] = useState(false)

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const [formaPagamentoSelecionadaState, setFormaPagamentoSelecionadaState] = useState()
    const [parcelasState, setParcelasState] = useState([])
    const [qtdParcelasSelecionadaState, setQtdParcelasSelecionadaState] = useState([])
    const [emailState, setEmailState] = useState(props.gerarAcordoDataSource?.clienteEmail ? props.gerarAcordoDataSource.clienteEmail : null)

    const [cobrancasPontuaisSelecionadasDataSouceState, setCobrancasPontuaisSelecionadasDataSouceState] = useState({
        arrayCodCobranca: [],
        total: 0
    })


    const _formRef = useRef(null)

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState(false)

    const [states, setStates] = useState({
        toastVisible: false,
        toastType: 'info',
        toastMessage: ""
    })

    const [disabledProcessarState, setDisabledProcessarState] = useState(false)
    const [toastGerarCobrancaVisible, setToastGerarCobrancaVisible] = useState(false)

    const onHidingToastGerarCobranca = () => {
        setToastGerarCobrancaVisible(false)
    }

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }



    const toastConfiguration = (visible, type, message, closeForm) => {
        console.log("entrou toastConfiguration")
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message,
            onHidden: closeForm
        })
    }


    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }


    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const entityStructureFieldsDefs = [

        {
            fieldName: "nm_produto",
            fieldCaption: "Serviço",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "nm_tipo_cobranca",
            fieldCaption: "Tipo",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "vl_total",
            fieldCaption: "Valor Título",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "nm_forma_pagamento",
            fieldCaption: "Forma Pagamento",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "nr_emp_fil",
            fieldCaption: "Empresa",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: 0,
            defaultSortOrder: "asc",
            allowGrouping: true,
            groupIndex: 0
        },
        {
            fieldName: "cd_pedido",
            fieldCaption: "Pedido",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "ds_placa",
            fieldCaption: "Placa",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "ds_chassi",
            fieldCaption: "Chassi",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "nm_status",
            fieldCaption: "Status",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        }
    ]

    const estruturaDataGridNegociacao = [
        {
            fieldName: "emp_fil",
            format: null,
        },
        {
            fieldName: "vl_total",
            format: "money",
        },
        {
            fieldName: "dt_vencto",
            format: null,
        },
        {
            fieldName: "dt_emissao",
            format: null,
        },
        {
            fieldName: "vl_multa",
            format: "money",
        },
        {
            fieldName: "vl_juros",
            format: "money",
        },
        {
            fieldName: "valor",
            format: "money",
        },
        {
            fieldName: "dt_rec_ped",
            format: null,
        },
        {
            fieldName: "status",
            format: null,
        },
    ]

    const convertValuesStringToNumber = (estruturura, dados) => {
        for(let c = 0; c <= estruturura.length - 1; c++){
            if(estruturura[c]?.format === "money"){
                for(let i = 0; i <= dados.length - 1; i++){
                    if(dados[i]?.[estruturura[c].fieldName]){
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName])
                    }
                }
            }
            
        }
    }


    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const [popupDescontoVisibleState, setPopupDescontoVisibleState] = useState(false)

    const [valorComDescontoState, setValorComDescontoState] = useState(0)
    // const [valorSemDescontoState, setValorSemDescontoState] = useState(0)


    // useEffect(() => {
    //     if(valorComDescontoState > 0){
    //         setCobrancasPontuaisSelecionadasDataSouceState({...cobrancasPontuaisSelecionadasDataSouceState,
    //             total: valorComDescontoState
    //         })
    //     }
    // }, [valorComDescontoState]) 

    useEffect(() => {
        if(props.gerarAcordoDataSource?.cobrancasPontuais && props.gerarAcordoDataSource?.tipoOcorrencia == 1){
            
        }
    }, [cobrancasPontuaisSelecionadasDataSouceState]) 

    const convertBinaryForPDF = (boleto) => {
        if (boleto != null) {
            var contentType = "application/pdf";
        
            props.setBoletoState({
                doc: "data:" + contentType + ";base64," + boleto
            });
            } else {
            props.setBoletoState({
                doc: ""
            });
        }
    }


    return(
        <div style={{height: "100%", padding: "0.5em"}}
        >
            {/* <ScrollView> */}
            <Popup
                visible={visible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {message &&
                    <p>{message}</p>
                }
            </Popup>

            <PopupDesconto
                popupDescontoVisibleState={popupDescontoVisibleState}
                setPopupDescontoVisibleState={setPopupDescontoVisibleState}
                descontoMaximo={props.gerarAcordoDataSource?.tipoOcorrencia == 1 ? 100 : props.gerarAcordoDataSource?.campanhaDesconto}
                valorSemDescontoState={cobrancasPontuaisSelecionadasDataSouceState.total}
                valorComDescontoState={valorComDescontoState}
                setValorComDescontoState={setValorComDescontoState}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />


            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
                onHidden={() => {
                    if(states.onHidden){
                        fecharFormAtual(tabPanelItems, indexTabPanelMain, setIndexTabPanelMain, props.hideUpfPopupForm)
                        setStates({...states, 
                            onHidden: false
                        })
                    }
                }}
            />

            <Toast
                visible={toastGerarCobrancaVisible}
                message="Cobrança gerada com sucesso!"
                type="success"
                onHiding={onHidingToastGerarCobranca}
                displayTime={1800}
                height={60}
            />

            {props.gerarAcordoDataSource ? 
            (
                <div>
                    {props.gerarAcordoDataSource?.tipoOcorrencia == 1 &&
                        <>
                            <h4>Cobranças Pontuais</h4>
                            <DataGrid
                                dataSource={props.gerarAcordoDataSource?.cobrancasPontuais.length > 0 ? props.gerarAcordoDataSource?.cobrancasPontuais : []}
                                columnAutoWidth={true}
                                showBorders={true}
                                cacheEnabled={false}
                                onSelectionChanged={(item) => {
                                    setValorComDescontoState(0)
                                    console.log(item.selectedRowsData)
                                    const nr_emp_fil_array = []
                                    const arrayCodCobranca = []
                                    const arrayCodTipoCobranca = []
                                    const statusArray = []
                                    let total = 0

                                    for(let c = 0; c <= item.selectedRowsData.length - 1; c++){
                                        arrayCodCobranca.push({codCobranca: item.selectedRowsData[c]?.cd_cobranca, numero: 0})
                                        arrayCodTipoCobranca.push(item.selectedRowsData[c]?.cd_tipo_cobranca)
                                        total = total + parseFloat(item.selectedRowsData[c]?.vl_total)
                                        nr_emp_fil_array.push(item.selectedRowsData[c]?.nr_emp_fil)
                                        statusArray.push(item.selectedRowsData[c]?.nm_status)
                                    }
                                    setCobrancasPontuaisSelecionadasDataSouceState({
                                        arrayCodCobranca: arrayCodCobranca,
                                        arrayCodTipoCobranca: arrayCodTipoCobranca,
                                        total: total,
                                        nr_emp_fil_array: nr_emp_fil_array,
                                        selectedRowsData: item.selectedRowsData,
                                        statusArray: statusArray
                                    })

                                    // _formRef.current.instance.resetValues()
                                    _formRef.current.instance.updateData("Email", emailState)
                                    _formRef.current.instance.updateData("_parcelas", null)
                                    _formRef.current.instance.updateData("_formaPagamento", null)
                                }}
                            >

                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />

                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />

                                <Selection 
                                    mode="multiple"
                                    keyExpr='ID'
                                />
                                <Scrolling 
                                    rowRenderingMode='infinity'
                                    showScrollbar='always'
                                    useNative={true}
                                    >
                                </Scrolling>

                                <Paging defaultPageSize={6} />

                                <Sorting
                                    mode="none"
                                />
                                
                                <Pager
                                    visible={true}
                                    // allowedPageSizes={6}
                                    // showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />

                                
                                <ColumnChooser 
                                    enabled={false} 
                                    width={400} 
                                    height={800} 
                                    mode={"select"} 
                                    allowSearch={true}
                                />

                                {entityStructureFieldsDefs.map((item) => {
                                    if(item.editorType === 'dxDateBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='date'
                                                visible={item.visible}
                                                defaultSortIndex={item.defaultSortIndex}
                                                defaultSortOrder={item.defaultSortOrder}
                                                allowGrouping={item?.allowGrouping}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.editorType === 'dxCheckBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='boolean'
                                                visible={item.visible}
                                                defaultSortIndex={item.defaultSortIndex}
                                                defaultSortOrder={item.defaultSortOrder}
                                                allowGrouping={item?.allowGrouping}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.format === 'money') {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                format="R$ #,##0.##00"
                                                defaultSortIndex={item.defaultSortIndex}
                                                defaultSortOrder={item.defaultSortOrder}
                                                allowGrouping={item?.allowGrouping}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if (item.format === 'percent') {
                                        return (
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                showInColumnChooser={item.showInColumnChooser}
                                                format="#0'%'"
                                                defaultSortIndex={item.defaultSortIndex}
                                                defaultSortOrder={item.defaultSortOrder}
                                                allowGrouping={item?.allowGrouping}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                showInColumnChooser={item.showInColumnChooser}
                                                defaultSortIndex={item.defaultSortIndex}
                                                defaultSortOrder={item.defaultSortOrder}
                                                allowGrouping={item?.allowGrouping}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    
                                })}
                                    <Summary
                                        calculateCustomSummary={calculateCustomSummary}
                                    >

                                        <TotalItem
                                            column={'vl_total'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />

                                    </Summary>
                            </DataGrid>
                        </>
                    }


                    {props.gerarAcordoDataSource?.tipoOcorrencia == 2 &&
                        <>
                            <h4>Duplicatas em Aberto</h4>
                            <DataGrid
                                dataSource={props.gerarAcordoDataSource?.cobrancasPontuais.length > 0 ? props.gerarAcordoDataSource?.cobrancasPontuais : []}
                                columnAutoWidth={true}
                                showBorders={true}
                                cacheEnabled={false}
                                // onContentReady={() => {convertValuesStringToNumber(estruturaDataGridNegociacao, props.gerarAcordoDataSource?.cobrancasPontuais)}}
                                onSelectionChanged={(item) => {
                                    setValorComDescontoState(0)
                                    console.log(item.selectedRowsData)
                                    const nr_emp_fil_array = []
                                    const arrayCodCobranca = []
                                    const arrayCodTipoCobranca = []
                                    const statusArray = []
                                    let total = 0

                                    for(let c = 0; c <= item.selectedRowsData.length - 1; c++){
                                        arrayCodCobranca.push({codCobranca: item.selectedRowsData[c]?.cd_cobranca, numero: item.selectedRowsData[c]?.numero})
                                        arrayCodTipoCobranca.push(item.selectedRowsData[c]?.cd_tipo_cobranca)
                                        total = total + parseFloat(item.selectedRowsData[c]?.valor)
                                        nr_emp_fil_array.push(item.selectedRowsData[c]?.emp_fil)
                                        statusArray.push(item.selectedRowsData[c]?.status)
                                    }

                                    const arrayCodCobrancaSemRepetidos = arrayCodCobranca.filter(function(elem, index, self) {
                                        return index === self.indexOf(elem);
                                    });

                                    setCobrancasPontuaisSelecionadasDataSouceState({
                                        arrayCodCobranca: arrayCodCobrancaSemRepetidos,
                                        arrayCodTipoCobranca: arrayCodTipoCobranca,
                                        total: total,
                                        nr_emp_fil_array: nr_emp_fil_array,
                                        selectedRowsData: item.selectedRowsData,
                                        statusArray: statusArray
                                    })

                                    // _formRef.current.instance.resetValues()
                                    _formRef.current.instance.updateData("Email", emailState)
                                    _formRef.current.instance.updateData("_parcelas", null)
                                    _formRef.current.instance.updateData("_formaPagamento", null)
                                }}
                            >

                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />

                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />

                                <Selection 
                                    mode="multiple"
                                    keyExpr='ID'
                                />
                                <Scrolling 
                                    rowRenderingMode='infinity'
                                    showScrollbar='always'
                                    useNative={true}
                                    >
                                </Scrolling>

                                <Paging defaultPageSize={6} />

                                <Sorting
                                    mode="none"
                                />
                                
                                <Pager
                                    visible={true}
                                    // allowedPageSizes={6}
                                    // showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />

                                
                                <ColumnChooser 
                                    enabled={false} 
                                    width={400} 
                                    height={800} 
                                    mode={"select"} 
                                    allowSearch={true}
                                />

                                    <Column
                                        dataType='date'
                                        dataField="dt_rec_ped"
                                        caption="Mês de Refêrencia"
                                    />

                                    <Column
                                        dataField="valor"
                                        caption="Vl. Atualizado"
                                        format="R$ #,##0.##00"
                                    />

                                    <Column
                                        dataField="vr_crz"
                                        caption="Vl. Original"
                                        format="R$ #,##0.##00"
                                    />

                                    <Column
                                        dataField="status"
                                        caption="Status"
                                    />

                                    <Column
                                        dataField="emp_fil"
                                        caption="Empresa"
                                        defaultSortIndex={0}
                                        defaultSortOrder="asc"
                                        allowGrouping={true}
                                        groupIndex={0}
                                    />

                                    <Column
                                        dataField="vl_juros"
                                        caption="Juros"
                                        format="R$ #,##0.##00"
                                    />

                                    <Column
                                        dataField="vl_multa"
                                        caption="Multa"
                                        format="R$ #,##0.##00"
                                    />

                                    <Column
                                        dataField="dt_vencto"
                                        caption="Dt. Vencimento"
                                        dataType='date'
                                    />

                                    <Column
                                        dataField="dt_emissao"
                                        caption="Dt. Emissão"
                                        dataType='date'
                                    />

                                    <Summary
                                        calculateCustomSummary={calculateCustomSummary}
                                    >

                                        <TotalItem
                                            column={'valor'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />

                                        <TotalItem
                                            column={'vr_crz'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />


                                        <TotalItem
                                            column={'vl_juros'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />

                                        <TotalItem
                                            column={'vl_multa'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />

                                    </Summary>
                            </DataGrid>
                        </>
                    }

                    <div style={{marginBottom: "2em", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row-reverse"}}>
                        <Button
                            width={130}
                            height={40}
                            text="Desconto"
                            type="default"
                            stylingMode="contained"
                            disabled={cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0 || disabledProcessarState}
                            onClick={() => {
                                // if(cobrancasPontuaisSelecionadasDataSouceState?.statusArray.every((e) => e === "Pendente")){
                                //     //Abrir popup de desconto
                                //     setPopupDescontoVisibleState(true)
                                // }
                                // else{
                                //     //erro sobre o status n ser pendente
                                //     setMessage("Selecione apenas itens com status Pendente!")
                                //     setVisible(true)
                                // }

                                //Abrir popup de desconto
                                setPopupDescontoVisibleState(true)
                            }}
                        />
                        <div> 
                            <h5>Total: R$ {valorComDescontoState > 0 ? valorComDescontoState?.toFixed(2) : cobrancasPontuaisSelecionadasDataSouceState.total?.toFixed(2)}</h5>
                            <h5>Desconto aplicado: R$ {valorComDescontoState > 0 ? `${(parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total)  - valorComDescontoState).toFixed(2)} (${(100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2)}%)` : 0}</h5>
                        </div>
                        
                        {/* <div className="dx-field">
                        <div className="dx-field-label">
                            Email :
                        </div>
                            
                        <div className="dx-field-value">
                            <TextBox
                                value={emailState}
                                onValueChanged={(e) => {
                                    setEmailState(e.value)
                                    console.log(e.value)
                                }}
                            />
                        </div>

                        </div> */}
                    </div>
                    

                    <Form
                        colCount={4}
                        ref={_formRef}
                        formData={{
                            _parcelas: null,
                            _formaPagamento: formaPagamentoSelecionadaState,
                            Email : emailState,
                        }}
                    >
                        <GroupItem
                            colSpan={4}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                colSpan={4}
                                editorType="dxTextBox" 
                                dataField="Email"
                                editorOptions=
                                {{ 
                                    onValueChanged: (e) => {
                                        setEmailState(e.value)
                                        console.log(e.value)
                                    },
                                    // value: emailState,
                                    // disabled: cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0
                                }}
                            />
                        </GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                colSpan={2}
                                // dataSource={props.gerarAcordoDataSource?.formasPagamento ? props.gerarAcordoDataSource.formasPagamento : []}
                                editorType="dxSelectBox" 
                                dataField="_formaPagamento"
                                editorOptions=
                                {{ 
                                    onSelectionChanged: (e) => {
                                        if(e.selectedItem){
                                            setFormaPagamentoSelecionadaState(e.selectedItem)

                                            if(props.gerarAcordoDataSource?.tipoOcorrencia == 2){

                                                if(e.selectedItem?.nr_qtd_parcela_max && e.selectedItem?.vl_parcela_min){
                                                    let nr_qtd_parcela_max = parseFloat(e.selectedItem?.nr_qtd_parcela_max)
    
                                                    let vl_parcela_min = parseFloat(e.selectedItem?.vl_parcela_min)
    
                                                    // const dividaTotal = vl_parcela_min * nr_qtd_parcela_max
                                                    let dividaTotal = valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total
    
                                                    while((dividaTotal / nr_qtd_parcela_max) < vl_parcela_min ){
                                                        nr_qtd_parcela_max = nr_qtd_parcela_max - 1
                                                    }
    
                                                    let arrayParcelas = []
                                                    for(let c = 1; c <= nr_qtd_parcela_max; c++){
                                                        arrayParcelas.push({id: c, nr_parcelas: `${c}x (R$ ${(dividaTotal / c).toFixed(2)})`})
                                                    }
    
                                                    _formRef.current.instance.updateData("Email", emailState)
                                                    _formRef.current.instance.updateData("_parcelas", null)
                                                    _formRef.current.instance.updateData("_formaPagamento", e.selectedItem)
    
                                                    setParcelasState(arrayParcelas)
                                                }

                                            }
                                            else{
                                                if(e.selectedItem?.nr_qtd_parcela_max && e.selectedItem?.vl_parcela_min){
                                                    let nr_qtd_parcela_max = parseFloat(e.selectedItem?.nr_qtd_parcela_max)
    
                                                    let vl_parcela_min = parseFloat(e.selectedItem?.vl_parcela_min)
    
                                                    // const dividaTotal = vl_parcela_min * nr_qtd_parcela_max
                                                    let dividaTotal = valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total
    
                                                    while((dividaTotal / nr_qtd_parcela_max) < vl_parcela_min ){
                                                        nr_qtd_parcela_max = nr_qtd_parcela_max - 1
                                                    }
    
                                                    let arrayParcelas = []
                                                    for(let c = 1; c <= nr_qtd_parcela_max; c++){
                                                        arrayParcelas.push({id: c, nr_parcelas: `${c}x (R$ ${(dividaTotal / c).toFixed(2)})`})
                                                    }
    
                                                    _formRef.current.instance.updateData("Email", emailState)
                                                    _formRef.current.instance.updateData("_parcelas", null)
                                                    _formRef.current.instance.updateData("_formaPagamento", e.selectedItem)
    
                                                    setParcelasState(arrayParcelas)
                                                }
                                            }
                                        }
                                    },
                                    items: props.gerarAcordoDataSource?.formasPagamento ? props.gerarAcordoDataSource.formasPagamento : [],
                                    valueExpr: "cd_forma_pagamento",
                                    displayExpr: "nm_forma_pagamento",
                                    disabled: cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0
                                }}
                            >
                                <Label 
                                    text="Forma de pagamento"
                                />
                            </SimpleItem>

 
                            <SimpleItem
                                colSpan={2}
                                editorType="dxSelectBox" 
                                dataField="_parcelas"
                                editorOptions=
                                {{ 
                                    onSelectionChanged: (e) => {
                                        if(e?.selectedItem?.id){
                                            setQtdParcelasSelecionadaState(e.selectedItem.id)
                                            _formRef.current.instance.updateData("Email", emailState)
                                            _formRef.current.instance.updateData("_parcelas", e.selectedItem.id)
                                            _formRef.current.instance.updateData("_formaPagamento", formaPagamentoSelecionadaState?.cd_forma_pagamento)
                                        }
                                    },
                                    dataSource: parcelasState,
                                    valueExpr: "id",
                                    displayExpr: "nr_parcelas",
                                    disabled: cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0
                                }}
                            >
                                <Label 
                                    text="Parcelas"
                                />
                            </SimpleItem>
                        </GroupItem>
                    </Form>

                    <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                        <div style={{marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Button
                                width={180}
                                height={40}
                                text="Processar"
                                type="default"
                                stylingMode="contained"
                                disabled={cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0 || disabledProcessarState}
                                onClick={() => {
                                    showLoadPanel()

                                    setDisabledProcessarState(true)

                                    const numeroEmpresaArray = cobrancasPontuaisSelecionadasDataSouceState?.nr_emp_fil_array
                                    console.log(numeroEmpresaArray)
                                    if(numeroEmpresaArray?.length > 0){  
                                        if(numeroEmpresaArray.every((e) => e === numeroEmpresaArray[0]) === false){
                                            setDisabledProcessarState(false)
                                            setMessage("Dívidas de empresas diferentes foram selecionadas! Para gerar o acordo as pendências devem ser da mesma empresa.")
                                            setVisible(true)
                                            hideLoadPanel()
                                        }
                                        else{
                                            showLoadPanel()

                                            console.log(cobrancasPontuaisSelecionadasDataSouceState.selectedRowsData)
                                            httpRequest('POST', requestsApi.gerarCobranca, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                tituloId: props.tituloId,
                                                // codCobranca: cobrancasPontuaisSelecionadasDataSouceState.arrayCodCobranca,
                                                listaCobranca: cobrancasPontuaisSelecionadasDataSouceState.arrayCodCobranca,
                                                codFormaPagamento: formaPagamentoSelecionadaState.cd_forma_pagamento,
                                                codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao,
                                                tipoOcorrencia: props.gerarAcordoDataSource?.tipoOcorrencia,
                                                pctDesconto: valorComDescontoState > 0 ? parseFloat((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2)) : 0,
                                                // tipoCobranca : props.gerarAcordoDataSource?.tipoOcorrencia,
                                                email: emailState,
                                                qtdParcela: qtdParcelasSelecionadaState,
                                                valor: valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total
                                            })
                                            .then((sender) => {
                                                setDisabledProcessarState(false)

                                                const resultGerarCobrancaJson = JSON.parse(sender)

                                                let arrayPendenciasProcessadas = props.arrayPendenciasProcessadasState

                                                const itensSelecionados = cobrancasPontuaisSelecionadasDataSouceState.selectedRowsData

                                                console.log(itensSelecionados)
                                                const arrayNumeroParcelas = []

                                                for(let c = 0; c <= itensSelecionados.length - 1; c++){
                                                    if(props.gerarAcordoDataSource?.tipoOcorrencia == 1){
                                                        arrayNumeroParcelas.push(itensSelecionados[c]?.cd_cobranca)

                                                        if(valorComDescontoState > 0){
                                                            const desconto = parseFloat((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2))

                                                            itensSelecionados[c].vl_total = itensSelecionados[c].vl_total - (itensSelecionados[c].vl_total * desconto / 100)
                                                        }

                                                        if(arrayPendenciasProcessadas?.length > 0){
                                                            arrayPendenciasProcessadas = arrayPendenciasProcessadas + ` + ${itensSelecionados[c]?.nm_tipo_cobranca} R$ ${itensSelecionados[c].vl_total}`
                                                        }
                                                        else{
                                                            arrayPendenciasProcessadas = arrayPendenciasProcessadas + `${itensSelecionados[c]?.nm_tipo_cobranca} R$ ${itensSelecionados[c].vl_total}`
                                                        }
                                                    }
                                                    else if(props.gerarAcordoDataSource?.tipoOcorrencia == 2){
                                                        arrayNumeroParcelas.push(itensSelecionados[c]?.numero)

                                                        if(arrayPendenciasProcessadas?.length > 0){
                                                            arrayPendenciasProcessadas = arrayPendenciasProcessadas + ` - ${itensSelecionados[c]?.numero}`
                                                        }
                                                        else{
                                                            arrayPendenciasProcessadas = arrayPendenciasProcessadas + `${itensSelecionados[c]?.numero}`
                                                        }
                                                    }
                                                }
 
                                                if(resultGerarCobrancaJson?.success){
                                                    setToastGerarCobrancaVisible(true)

                                                    // httpRequest('POST', requestsApi.getListarOcorrencia, requestsApi.basicAuth, {
                                                    //     applicationId: applicationID,
                                                    //     tituloId: props.tituloId
                                                    // })
                                                    // .then((sender) => {

                                                    //     const resultGetListarOcorrenciaJson = JSON.parse(sender)
                                                        if(formaPagamentoSelecionadaState?.nm_forma_pagamento === "SANTANDER REGISTRADO"){
                                                            showLoadPanel()
        
                                                            httpRequest('POST', requestsApi.getBoleto, requestsApi.basicAuth, {
                                                                applicationId: applicationID,
                                                                tituloId: props.tituloId,
                                                                codCobranca: resultGerarCobrancaJson?.codCobranca,
                                                                valorBoleto: valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total,
                                                                descontoValor: 0,
                                                                parcelas: arrayNumeroParcelas,
                                                            })
                                                            .then((sender) => {
                                                                const resultGetBoletoJson = JSON.parse(sender)
        
                                                                // convertBinaryForPDF(resultGetBoletoJson?.arquivopdf)
        
                                                                showLoadPanel()
        
                                                                httpRequest('POST', requestsApi.getListarSolucao, requestsApi.basicAuth, {
                                                                    applicationId: applicationID,
                                                                    tituloId: props.tituloId,
                                                                    codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao
                                                                })
                                                                .then((sender) => {
                                                                    //SALVAR DADOS DA LOOKUP DE SOLUÇÃO
                                                                    const resultGetListarSolucaoJson = JSON.parse(sender)
                
                                                                    if(emailState !== props.gerarAcordoDataSource?.clienteEmail){
                                                                        console.log("email diferente")
                                                                        showLoadPanel()
        
                                                                        httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
                                                                            applicationId: applicationID,
                                                                            entityName: '_cadastro',
                                                                            json: {
                                                                                id: props.gerarAcordoDataSource.clienteId,
                                                                                _cryptEmail: emailState
                                                                            }
                    
                                                                        })
                                                                        .then((sender) => {
                                                                            hideLoadPanel()
                                                                            console.log(sender)
                                                                            // props.setListaOcorrenciaState(resultGetListarOcorrenciaJson)
                                                                            props.setListaSolucaoState(resultGetListarSolucaoJson)
                                                                            props.setArrayPendenciasProcessadasState(arrayPendenciasProcessadas)
                                                                            props.setBoletoState(resultGetBoletoJson)
                                                                            props.setTotalCobrancaState(valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total)
                                                                            if(valorComDescontoState > 0){
                                                                                props.setDescontoTotalCobrancaState((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2))
                                                                            }
                                                                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error)
                                                                            if(error?.data?.error){
                                                                                setMessage(error.data.error)
                                                                                setVisible(true)
                                                                            }
                                                                            else{
                                                                                setMessage("Falha ao atualizar email")
                                                                                setVisible(true)
                                                                            }
                                                                            hideLoadPanel()
                                                                        })
                                                                    }
                                                                    else{
                                                                        console.log("email igual")
                                                                        hideLoadPanel()
                                                                        // props.setListaOcorrenciaState(resultGetListarOcorrenciaJson)  
                                                                        props.setListaSolucaoState(resultGetListarSolucaoJson)
                                                                        props.setArrayPendenciasProcessadasState(arrayPendenciasProcessadas)
                                                                        props.setBoletoState(resultGetBoletoJson)
                                                                        props.setTotalCobrancaState(valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total)
                                                                        if(valorComDescontoState > 0){
                                                                            props.setDescontoTotalCobrancaState((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2))
                                                                        }
                                                                        props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error)
                                                                    if(error?.data?.error){
                                                                        setMessage(error.data.error)
                                                                        setVisible(true)
                                                                    }
                                                                    else{
                                                                        setMessage("Falha ao trazer lista de soluções")
                                                                        setVisible(true)
                                                                    }
                                                                    hideLoadPanel()
                                                                })
                                                            })
                                                            .catch((error) => {
                                                                console.log(error)
                                                                if(error?.data?.error){
                                                                    setMessage(error.data.error)
                                                                    setVisible(true)
                                                                }
                                                                else{
                                                                    setMessage("Falha ao gerar boleto")
                                                                    setVisible(true)
                                                                }
                                                                hideLoadPanel()
                                                            })
                                                        }
                                                        else{
                                                            showLoadPanel()
        
                                                            httpRequest('POST', requestsApi.getListarSolucao, requestsApi.basicAuth, {
                                                                applicationId: applicationID,
                                                                tituloId: props.tituloId,
                                                                codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao
                                                            })
                                                            .then((sender) => {
                                                                //SALVAR DADOS DA LOOKUP DE SOLUÇÃO
                                                                const resultGetListarSolucaoJson = JSON.parse(sender)
            
            
                                                                if(emailState !== props.gerarAcordoDataSource?.clienteEmail){
                                                                    console.log("email diferente")
                                                                    showLoadPanel()
        
                                                                    httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
                                                                        applicationId: applicationID,
                                                                        entityName: '_cadastro',
                                                                        json: {
                                                                            id: props.gerarAcordoDataSource.clienteId,
                                                                            _cryptEmail: emailState
                                                                        }
                
                                                                    })
                                                                    .then((sender) => {
                                                                        console.log(sender)
                                                                        // props.setListaOcorrenciaState(resultGetListarOcorrenciaJson) 
                                                                        props.setListaSolucaoState(resultGetListarSolucaoJson)
                                                                        props.setArrayPendenciasProcessadasState(arrayPendenciasProcessadas)
                                                                        props.setTotalCobrancaState(valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total)
                                                                        if(valorComDescontoState > 0){
                                                                            props.setDescontoTotalCobrancaState((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2))
                                                                        }
                                                                        props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error)
                                                                        if(error?.data?.error){
                                                                            setMessage(error.data.error)
                                                                            setVisible(true)
                                                                        }
                                                                        else{
                                                                            setMessage("Falha ao atualizar email")
                                                                            setVisible(true)
                                                                        }
                                                                        hideLoadPanel()
                                                                    })
                                                                }
                                                                else{
                                                                    console.log("email igual")
                                                                    // props.setListaOcorrenciaState(resultGetListarOcorrenciaJson)
                                                                    props.setListaSolucaoState(resultGetListarSolucaoJson)
                                                                    props.setArrayPendenciasProcessadasState(arrayPendenciasProcessadas)
                                                                    props.setTotalCobrancaState(valorComDescontoState > 0 ? valorComDescontoState : cobrancasPontuaisSelecionadasDataSouceState?.total)
                                                                    if(valorComDescontoState > 0){
                                                                        props.setDescontoTotalCobrancaState((100 - valorComDescontoState * (100 / parseFloat(cobrancasPontuaisSelecionadasDataSouceState.total))).toFixed(2))
                                                                    }
                                                                    props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error)
                                                                if(error?.data?.error){
                                                                    setMessage(error.data.error)
                                                                    setVisible(true)
                                                                }
                                                                else{
                                                                    setMessage("Falha ao trazer lista de soluções")
                                                                    setVisible(true)
                                                                }
                                                                hideLoadPanel()
                                                            })
                                                        }
                                                        hideLoadPanel()

                                                    // })
                                                    // .catch((error) => {
                                                    //     console.log(error)
                                                    //     if(error?.data?.error){
                                                    //         setMessage(error.data.error)
                                                    //         setVisible(true)
                                                    //     }
                                                    //     else{
                                                    //         setMessage("Erro no getListarOcorrencia")
                                                    //         setVisible(true)
                                                    //     }
                                                    //     hideLoadPanel()
                                                    // })

                                                }
                                                else{
                                                    hideLoadPanel()
                                                    setMessage(resultGerarCobrancaJson?.error)
                                                    setVisible(true)
                                                }

                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                setDisabledProcessarState(false)

                                                if(error?.data?.error){
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                }
                                                else{
                                                    setMessage("Falha ao gerar cobrança")
                                                    setVisible(true)
                                                }
                                                hideLoadPanel()
                                            })
                                        }
                                    }
                                    else{
                                        setDisabledProcessarState(false)
                                        setMessage("Nenhum item foi selecionado!")
                                        setVisible(true)
                                        hideLoadPanel()
                                    }
                                    
                                }}
                            />
                        </div>

                        <div>
                            <Button
                                width={130}
                                height={40}
                                text="Anterior"
                                type="default"
                                stylingMode="contained"
                                onClick={() => {
                                    props.setArrayPendenciasProcessadasState("")
                                    // props.setGerarAcordoDataSource(null)
                                    //pegar todos os dados e enviar para a API 
                                    //...
                                    //ir para o proximo item
                                    
                                    props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                                }}
                            />
                        </div>
                    </div>

                </div>
            )
            :
            (
                <Loading/>
            )
            }
            {/* <div >
                

            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "end", width: "50%"}}>
                    <b>Máximo</b>
                    <b>Escolhido</b>
                </div>
            </div>

            /> */}
            {/* </ScrollView> */}
        </div>
    )
}

export default Passo2;