const { createContext, useContext, useState } = require("react");

const PopupView = createContext();

export const PopupViewProvider = ({ children }) => {
  const [popupView, setPopupView]= useState(false);

  return (
    <PopupView.Provider value={{ popupView, setPopupView }}>
      {children}
    </PopupView.Provider>
  );
};

export const usePopupView = () => useContext(PopupView);