import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import { relationId } from '../../api/ApplicationID';
import ForeignFieldEdit from '../../Components/ForeignFieldEdit';
import { useState, useEffect, useRef } from 'react';
import { permissions } from '../../api/ApplicationID';
import { validatePermission } from "../validatePermission"
import UpfPasswordComponent from '../../Components/UpfComponents/UpfPasswordComponent';
import {convertCheckBox} from '../convertCheckBox'
import { httpRequest } from '..';
import { useRequestsApi } from '../../Context/RequestsApi';
import { getDefaultValues } from '../getDefaultValues'
// import {Lookup, DropDownOptions} from "devextreme-react/lookup";
import UpfHtmlEditor from '../../Components/UpfComponents/UpfHtmlEditor';
import { repaintJsonStructureConditionalRender } from '../repaintJsonStructureConditionalRender';
import {
    CompareRule,
    EmailRule,
    NumericRule,
    StringLengthRule,
    RangeRule,
    AsyncRule,
  } from 'devextreme-react/validator';
import { formatFunction } from "../formatFunction"
import { colCountFunction } from '../colCountFunction';
import { colSpanFieldsFunction } from '../colSpanFieldsFunction';
import DataSource from 'devextreme/data/data_source';
import EnviarEmailCustomField from '../../Components/EnviarEmailCustomField';
import UpfLookupImage from '../../Components/UpfComponents/UpfLookupImage';
import UpfImageSelect from '../../Components/UpfComponents/UpfImageSelect';

const GetFormFields = (props) => {
    const [FormFields, SetFormFields] = useState()

    const arrayChangeFieldVisible = []

    const [processSelected, setProcessSelected] = useState(false)
    const [refreshState, setRefreshState] = useState(false)


    useEffect(() => {
        SetFormFields(false)
        
    }, [props.dataEntity, props.dataLookup, props.modeEditForm, props.dataEntityEditedFields, props.setDataEntityEditedFields])

    const {requestsApi} = useRequestsApi() 

    // console.log(colCountFunction(props?.fieldsDefs, props.colCount))

    const readOnlyFunction = (readOnly, edit) => {
        //insert
        if(!props.idValue){
            return readOnly
        }
        //update
        else{
            return (edit === 0) || (readOnly)
        }

    }
   
    if (!FormFields){

        SetFormFields(
            <form onSubimit={props.recordInsertUpdateJson}>
                <Form 
                    ref={props.resetFormData}
                    colCount={props.colCount}
                    formData={props.dataEntity}
                    onFieldDataChanged={(e) => { 
                        const objAtual = props.dataEntityEditedFields ? props.dataEntityEditedFields : {}
                        objAtual[e.dataField] = e.value
                        props.setDataEntityEditedFields(objAtual)
                        
                        if(!props.modeEditForm){
                            props.modeEditOn()
                        }
                    }}
                    // labelMode='static'
                    validationGroup={`groupName${props.id}`}
                    // labelLocation="top"
                    // showColonAfterLabel={true}
                    // minColWidth={200}
                    // alignItemLabelsInAllGroups={true}
                    // alignItemLabels={true}
                    // onInitialized={(e) => {
                    //     console.log(e)
                    //     e.component.option("value", Boolean(e.component.option("value")))
                    // }}
                >
                    <GroupItem 
                        // visible={}
                        // colCount={props.groupItemColSpan}
                        // colSpan={props.groupItemColCount}
                        colCount={colCountFunction(props?.fieldsDefs, props.colCount)}
                        colSpan={props.colCount}
                        // cssClass={'group-item'}
                        // colCountByScreen={true}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >
                        
                    {props?.fieldsDefs?.map((field, indice) => {
                        const fieldPermissions = validatePermission(field.objectid)

                        if(!fieldPermissions?._show === 1){
                            field["formVisible"] = 0
                        }

                        if (field.editorType === "dxTextArea") {
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }
                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                return(
                                    <SimpleItem 
                                        editorType={field.editorType} 
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        dataSource={props.dataEntity}
                                        fieldName={field.fieldCaption}
                                        key={indice} 
                                        editorOptions=
                                        {{ 
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            height: 130,
                                            hint: field.hint,
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            // value: getDefaultValues(field),
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}>
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                        {field.rules && field.rules.map((rule) => {
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                    <EmailRule
                                                        //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                        message={rule.message}
                                                    />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }
                        else if (field.editorType === "dxCheckBox") 
                        { 
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }
                            // const fieldPermissions = validatePermission(field.objectid)
                            props.senderFieldNamesCheckBox.push(field.fieldName)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                return(
                                    <SimpleItem 
                                        editorType={field.editorType} 
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        dataSource={props.dataEntity}
                                        fieldName={field.fieldCaption}
                                        key={indice} 
                                        editorOptions={
                                        {
                                            hint: field.hint,
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"],
                                            onInitialized: (e) => {
                                                e.component.option("value", Boolean(e.component.option("value")))
                                            }
                                        }}
                                    >
                                        <Label 
                                            text={field.fieldCaption} 
                                            // location="left"
                                        />
                                        {field.rules && field.rules.map((rule) => {
                                        if(rule.type === "EmailRule"){
                                            
                                            return(
                                                <EmailRule
                                                    //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                    message={rule.message}
                                                />
                                                    
                                            )
                                        }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (
                            field.editorType === 'dxLookupLoteProcessamento'
                                )
                        {
            
                        // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                return(
                                    <SimpleItem 
                                        editorType='dxSelectBox'
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        //dataSource={props.dataEntity.masterEntity[0].records[0]}
                                        editorOptions=
                                        {{
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onSelectionChanged: (sender) => {props.setSelectedProcess(sender.selectedItem)},
                                            //dataSource: props.dataLookup[field.lookUpListSource],
                                            valueExpr: field.lookUpKeyField,
                                            hint: field.hint,
                                            displayExpr: field.lookUpResultField,
                                            showClearButton: true,
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"]
        
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                            {field.rules && field.rules.map((rule) => {
        
                                                if(rule.type === "EmailRule"){
                                                    
                                                    return(
                                                            <EmailRule
                                                                //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                                message={rule.message}
                                                            />
                                                            
                                                    )
                                                }
                                            })}
                                            {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                                <RequiredRule message="Campo obrigatório!" />
                                            
                                            }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.editorType === "upfHtmlViewer"){
                            // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                return(
                                    <SimpleItem
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        render={() => {
                                            return(
                                                <UpfHtmlEditor 
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={field}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    modeEditOn={props.modeEditOn}
                                                    showEditBar={false}
                                                />
                                            )}}
                                        editorOptions={{ 
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.editorType === "upfHtmlBox"){
                            // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                return(
                                    <SimpleItem
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        render={() => {
                                            return(
                                                <UpfHtmlEditor 
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={field}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    modeEditOn={props.modeEditOn}
                                                    showEditBar={true}
                                                />
                                            )}}
                                        editorOptions={{
                                            stylingMode: 'underlined',
                                            // labelMode: 'static', 
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.editorType === 'upfLookupDetailEntity'){
                            let resultArray = []

                            if(props.detailEntity?.length > 0){
                                let resultObj = props.detailEntity.filter(
                                        (e) => e.entityName === field.lookUpListSource)
    
                                resultArray = resultObj?.[0]?.records
    
                            }
                            else if(props.popupRecordFormOpen && props.masterDetailEntity){
                                let resultObj = props.masterDetailEntity.filter(
                                    (e) => e.entityName === field.lookUpListSource)
    
                                resultArray = resultObj?.[0]?.records
                            } 
    
                            // const fieldPermissions = validatePermission(field.objectid)

                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }
                            
                            return(
                                <SimpleItem 
                                    editorType='dxSelectBox' 
                                    hint={field.hint}
                                    helpText={field.hintDocked ? field.hint : null}
                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                    caption={field.fieldCaption} 
                                    dataField={field.fieldName} 
                                    editorOptions=
                                    {{
                                        stylingMode: 'underlined',
                                        // labelMode: 'static',
                                        // value:field.fieldCaption,
                                        readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                        items: resultArray,
                                        valueExpr: field.lookUpKeyField,
                                        displayExpr: field.lookUpResultField,
                                        hint: field.hint,
                                        placeholder: "Selecione uma opção",
                                        visible: true,
                                        showClearButton: true,
                                        allowClearing: true,
                                        // value: getDefaultValues(field),
                                        deferRendering: true,
                                        searchEnabled: true,
                                        wrapItemText: false,
                                        searchMode: "contains",
                                        showDataBeforeSearch: true,
                                        onValueChanged: (sender) => {
                                            if(field.rules?.length > 0 && sender.value){
                                                if(field.rules.find((e) => e.type === "NumericRule")){
                                                    const resultRule = sender.value.replace(/\D/g, '')
                                                    props.dataEntity[field.fieldName] = resultRule
                                                }
                                            }
                                            if(field?.changeFieldVisible === "true"){
                                                repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                            }
                                        },
                                        valueChangeEvent: ["keyup", "paste"],
                                        showTitle: true
                                        // width: 220
                                        //dropDownOptions: {}
                                    }}
                                >
                                    
                                    <Label 
                                        location="top"
                                        showColon={false}
                                        text={field.fieldCaption}
                                        labelMode='static'
                                    />
                                    {field.rules && field.rules.map((rule) => {

                                        if(rule.type === "EmailRule"){
                                            
                                            return(
                                                    <EmailRule
                                                        //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                        message={rule.message}
                                                    />
                                                    
                                            )
                                        } 
                                    })}
                                    {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                        <RequiredRule message="Campo obrigatório!" />
                                    
                                    }
                                </SimpleItem>
                            )
                            
                        }
                        
                        else if (field.editorType === 'dxLookupEdit'){
                            // const fieldPermissions = validatePermission(field.objectid)
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                const productsDataSource = new DataSource({
                                    store: {
                                      data: props.dataLookup[field.lookUpListSource],
                                      type: 'array',
                                      key: field.lookUpKeyField,
                                    },
                                  });

                                return(
                                
                                    <SimpleItem 
                                        editorType='dxSelectBox' 
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        editorOptions=
                                        {{
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            dataSource: props.dataLookup[field.lookUpListSource],
                                            valueExpr: field.lookUpKeyField,
                                            displayExpr: field.lookUpResultField,
                                            acceptCustomValue: true,
                                            customItemCreateEvent: "focusout",
                                            searchTimeout: 200,
                                            minSearchLength: 0,
                                            showDataBeforeSearch: false,
                                            onCustomItemCreating: (args) => {
                                                console.log("function custom item")
                                                console.log(args)

                                                if (!args.text) {
                                                  args.customItem = null;
                                                  return;
                                                }
                                                else{
                                                    const newItem = {};
    
                                                    newItem[field.lookUpKeyField] = args.text
                                                    newItem[field.lookUpResultField] = args.text
                                                
                                                    args.customItem = productsDataSource.store().insert(newItem)
                                                      .then(() => productsDataSource.load())
                                                      .then(() => newItem)
                                                      .catch((error) => {
                                                        throw error;
                                                      });
                                                }
                                                
                                            },
                                            hint: field.hint,
                                            placeholder: "Selecione uma opção",
                                            visible: true,
                                            showClearButton: true,
                                            allowClearing: true,
                                            deferRendering: true,
                                            searchEnabled: true,
                                            wrapItemText: false,
                                            searchMode: "contains",
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                // console.log(field?.changeFieldVisible)
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            // valueChangeEvent: ["keyup", "paste"],
                                            // valueChangeEvent: ["clickouot", "outsideclick" ],
                                            showTitle: true
                                            // width: 220
                                            //dropDownOptions: {}
                                        }}
                                    >
                                        
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (field.editorType === 'upfLookupImage'){
                            const fieldPermissions = validatePermission(field.objectid)
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                const productsDataSource = new DataSource({
                                    store: {
                                      data: props.dataLookup[field.lookUpListSource],
                                      type: 'array',
                                      key: field.lookUpKeyField,
                                    },
                                  });

                                return(
                                
                                    <SimpleItem 
                                        editorType='dxSelectBox' 
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        render={() => {
                                            return(
                                                <UpfLookupImage   
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={props.entityStructure}
                                                    // dataSource={formDataJson}
                                                    entityStructureField={field}
                                                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                    dataLookup={props.dataLookup[field.lookUpListSource]}
                                                    setQueryFormEntityName={props.setQueryFormEntityName}
                                                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                                    displayExpr={field.lookUpResultField}
                                                    valueExpr={field.lookUpKeyField}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    modeEditOn={props.modeEditOn}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    queryFormPopupState={props.queryFormPopupState}
                                                    setQueryFormPopupState={props.setQueryFormPopupState}
                                                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                    resetFormData={props.resetFormData}
                                                />
                                            )}}
                                        editorOptions=
                                        {{
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            dataSource: props.dataLookup[field.lookUpListSource],
                                            valueExpr: field.lookUpKeyField,
                                            displayExpr: field.lookUpResultField,
                                            acceptCustomValue: true,
                                            customItemCreateEvent: "focusout",
                                            searchTimeout: 200,
                                            minSearchLength: 0,
                                            showDataBeforeSearch: false,
                                            onCustomItemCreating: (args) => {
                                                console.log("function custom item")
                                                console.log(args)

                                                if (!args.text) {
                                                  args.customItem = null;
                                                  return;
                                                }
                                                else{
                                                    const newItem = {};
    
                                                    newItem[field.lookUpKeyField] = args.text
                                                    newItem[field.lookUpResultField] = args.text
                                                
                                                    args.customItem = productsDataSource.store().insert(newItem)
                                                      .then(() => productsDataSource.load())
                                                      .then(() => newItem)
                                                      .catch((error) => {
                                                        throw error;
                                                      });
                                                }
                                                
                                            },
                                            hint: field.hint,
                                            placeholder: "Selecione uma opção",
                                            visible: true,
                                            showClearButton: true,
                                            allowClearing: true,
                                            deferRendering: true,
                                            searchEnabled: true,
                                            wrapItemText: false,
                                            searchMode: "contains",
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                // console.log(field?.changeFieldVisible)
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            // valueChangeEvent: ["keyup", "paste"],
                                            // valueChangeEvent: ["clickouot", "outsideclick" ],
                                            showTitle: true
                                            // width: 220
                                            //dropDownOptions: {}
                                        }}
                                    >
                                        
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.editorType === "upfImageSelect"){
                            const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                return(
                                    <SimpleItem
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        render={() => {
                                            return(
                                                <UpfImageSelect 
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={field}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    modeEditOn={props.modeEditOn}
                                                    modeEditForm={props.modeEditForm}
                                                    referencia={props.resetFormData?.current?.instance}
                                                    // setDadosDaEntidadeDetalheState={setDadosDaEntidadeDetalheState}
                                                    // dadosDaEntidadeDetalheState={dadosDaEntidadeDetalheState}
                                                    entityName={props.entityStructure[0]?.masterEntity?.entityName}
                                                />
                                            )}}
                                        editorOptions={{ 
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (field.editorType === 'dxLookup' || 
                                field.editorType === 'dxSelectBox')
                        {
                        
                            // const fieldPermissions = validatePermission(field.objectid)
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                return(
                                
                                    <SimpleItem 
                                        editorType='dxSelectBox' 
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName} 
                                        editorOptions=
                                        {{
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            dataSource: props.dataLookup[field.lookUpListSource],
                                            valueExpr: field.lookUpKeyField,
                                            displayExpr: field.lookUpResultField,
                                            hint: field.hint,
                                            placeholder: "Selecione uma opção",
                                            visible: true,
                                            showClearButton: true,
                                            allowClearing: true,
                                            deferRendering: true,
                                            searchEnabled: true,
                                            wrapItemText: false,
                                            searchMode: "contains",
                                            showDataBeforeSearch: true,
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                // console.log(field?.changeFieldVisible)
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            valueChangeEvent: ["keyup", "paste"],
                                            showTitle: true
                                            // width: 220
                                            //dropDownOptions: {}
                                        }}
                                    >
                                        
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (field.editorType === 'upfForeignResultField') {
                            // console.log(props.foreignFieldSelectedItem)


                            // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                return(
                                    <SimpleItem
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName}
                                        deferRendering={false}
                                        render={() => {
                                            return(
                                                <ForeignFieldEdit  
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={props.entityStructure}
                                                    //dataSource={formDataJson}
                                                    entityStructureField={field}
                                                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                    entityName={field.lookUpListSource}
                                                    setQueryFormEntityName={props.setQueryFormEntityName}
                                                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                                    // displayExpr={field.lookUpResultField}
                                                    // valueExpr={field.lookUpKeyField}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    modeEditOn={props.modeEditOn}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    queryFormPopupState={props.queryFormPopupState}
                                                    setQueryFormPopupState={props.setQueryFormPopupState}
                                                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                    resetFormData={props.resetFormData}
                                                />
                                            )}}
                                        editorOptions={{ 
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            valueChangeEvent: ["keyup", "paste"],
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                // if(field.rules?.length > 0 && sender.value){
                                                //     if(field.rules.find((e) => e.type === "NumericRule")){
                                                //         const resultRule = sender.value.replace(/\D/g, '')
                                                //         props.dataEntity[field.fieldName] = resultRule
                                                //     }
                                                // }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            deferRendering: false
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (field.editorType === 'upfEmail') {
                            // console.log(props.foreignFieldSelectedItem)


                            // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                                console.log("upfEmail")
                                console.log(props.entityStructure)
                                return(
                                    <SimpleItem
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        caption={field.fieldCaption} 
                                        dataField={field.fieldName}
                                        deferRendering={false}
                                        render={() => {
                                            return(
                                                <EnviarEmailCustomField  
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={props.dataEntity}
                                                    entityStructure={props.entityStructure}
                                                    entityStructureField={field}
                                                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                    entityName={props.entityStructure[0]?.masterEntity?.entityName}
                                                    setQueryFormEntityName={props.setQueryFormEntityName}
                                                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                    colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                                    caption={field.fieldCaption} 
                                                    dataField={field.fieldName}
                                                    disabled={(fieldPermissions?._edit === 0) || (field.readOnly)}
                                                    hint={field.hint}
                                                    modeEditOn={props.modeEditOn}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    queryFormPopupState={props.queryFormPopupState}
                                                    setQueryFormPopupState={props.setQueryFormPopupState}
                                                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                    resetFormData={props.resetFormData}
                                                />
                                            )}}
                                        editorOptions={{ 
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            valueChangeEvent: ["keyup", "paste"],
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                // if(field.rules?.length > 0 && sender.value){
                                                //     if(field.rules.find((e) => e.type === "NumericRule")){
                                                //         const resultRule = sender.value.replace(/\D/g, '')
                                                //         props.dataEntity[field.fieldName] = resultRule
                                                //     }
                                                // }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            deferRendering: false
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.fieldType === "datetime" && (field.editorType === "dxDateBox" || field.editorType === "dxCalendar")){

                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            // const fieldPermissions = validatePermission(field.objectid)

                            //converte a data quando esta no formato date time e não é um inseret
                            if(props.dataEntity?.[`${field.fieldName}`] && props.dataEntity?.[`${field.fieldName}`]?.length > 19 && !props.modeEditForm){
                                props.dataEntity[`${field.fieldName}`] = props.dataEntity[`${field.fieldName}`]
                                .substring(0, props.dataEntity[`${field.fieldName}`]?.length - 5)
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                            
                                return(
                                    <SimpleItem 
                                            editorType={field.editorType}
                                            hint={field.hint}
                                            helpText={field.hintDocked ? field.hint : null}
                                            caption={field.fieldCaption}
                                            dataField={field.fieldName} 
                                            dataSource={props.dataEntity}
                                            colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                            fieldName={field.fieldCaption}
                                            editorOptions={{
                                                stylingMode: 'underlined',
                                                // labelMode: 'static',
                                                hint: field.hint,
                                                onValueChanged: (sender) => {
                                                    if(field.rules?.length > 0 && sender.value){
                                                        if(field.rules.find((e) => e.type === "NumericRule")){
                                                            const resultRule = sender.value.replace(/\D/g, '')
                                                            props.dataEntity[field.fieldName] = resultRule
                                                        }
                                                    }
                                                    if(field?.changeFieldVisible === "true"){
                                                        repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                    }
                                                },
                                                // value: getDefaultValues(field),
                                                valueChangeEvent: ["keyup", "paste"],
                                                readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                                // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                                dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss",
                                                type:"datetime"
                                            }}
                                        >
                                            <Label 
                                                location="top"
                                                showColon={false}
                                                text={field.fieldCaption}
                                                labelMode='static'
                                            />
                                            {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if (field.editorType === "upfTime") {

                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            // const fieldPermissions = validatePermission(field.objectid)

                            //converte a data quando esta no formato date time e não é um inseret
                            if(props.dataEntity?.[`${field.fieldName}`] && props.dataEntity?.[`${field.fieldName}`]?.length > 19 && !props.modeEditForm){
                                props.dataEntity[`${field.fieldName}`] = props.dataEntity[`${field.fieldName}`]
                                .substring(0, props.dataEntity[`${field.fieldName}`]?.length - 5)
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                            
                                return(
                                    <SimpleItem 
                                            editorType="dxDateBox"
                                            hint={field.hint}
                                            helpText={field.hintDocked ? field.hint : null}
                                            caption={field.fieldCaption}
                                            dataField={field.fieldName} 
                                            dataSource={props.dataEntity}
                                            colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                            fieldName={field.fieldCaption}
                                            editorOptions={{
                                                stylingMode: 'underlined',
                                                // labelMode: 'static',
                                                hint: field.hint,
                                                onValueChanged: (sender) => {
                                                    if(field.rules?.length > 0 && sender.value){
                                                        if(field.rules.find((e) => e.type === "NumericRule")){
                                                            const resultRule = sender.value.replace(/\D/g, '')
                                                            props.dataEntity[field.fieldName] = resultRule
                                                        }
                                                    }
                                                    if(field?.changeFieldVisible === "true"){
                                                        repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                    }
                                                },
                                                // value: getDefaultValues(field),
                                                valueChangeEvent: ["keyup", "paste"],
                                                // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                                readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                                dateSerializationFormat:"HH:mm",
                                                pickerType:"rollers",
                                                interval:1,
                                                useMaskBehavior:true,
                                                type:"time"
                                            }}
                                        >
                                            <Label 
                                                location="top"
                                                showColon={false}
                                                text={field.fieldCaption}
                                                labelMode='static'
                                            />
                                            {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.fieldType === "date" && (field.editorType === "dxDateBox" || field.editorType === "dxCalendar")){
                            
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                            // const fieldPermissions = validatePermission(field.objectid)

                            //converte a data quando esta no formato date time e não é um inseret
                            if(props.dataEntity?.[`${field.fieldName}`] && props.dataEntity?.[`${field.fieldName}`]?.length > 19 && !props.modeEditForm){
                                props.dataEntity[`${field.fieldName}`] = props.dataEntity[`${field.fieldName}`]
                                .substring(0, props.dataEntity[`${field.fieldName}`]?.length - 5)
                            }


                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                            
                                return(
                                    <SimpleItem 
                                            editorType={field.editorType}
                                            hint={field.hint}
                                            helpText={field.hintDocked ? field.hint : null}
                                            caption={field.fieldCaption}
                                            dataField={field.fieldName} 
                                            dataSource={props.dataEntity}
                                            colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                            fieldName={field.fieldCaption}
                                            editorOptions={{
                                                stylingMode: 'underlined',
                                                // labelMode: 'static',
                                                hint: field.hint,
                                                onValueChanged: (sender) => {
                                                    if(field.rules?.length > 0 && sender.value){
                                                        if(field.rules.find((e) => e.type === "NumericRule")){
                                                            const resultRule = sender.value.replace(/\D/g, '')
                                                            props.dataEntity[field.fieldName] = resultRule
                                                        }
                                                    }
                                                    if(field?.changeFieldVisible === "true"){
                                                        repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                    }
                                                },
                                                // value: getDefaultValues(field),
                                                valueChangeEvent: ["keyup", "paste"],
                                                // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                                dateSerializationFormat:"yyyy-MM-dd",
                                                type: "date",
                                                readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            }}
                                        >
                                            <Label 
                                                location="top"
                                                showColon={false}
                                                text={field.fieldCaption}
                                                labelMode='static'
                                            />
                                            {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else if(field.editorType === "upfPassword"){
                            
                            // const fieldPermissions = validatePermission(field.objectid)

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){
                            
                                return(
                                        <SimpleItem 
                                            hint={field.hint}
                                            helpText={field.hintDocked ? field.hint : null}
                                            colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                            caption={field.fieldCaption} 
                                            dataField={field.fieldName} 
                                            dataSource={props.dataEntity}
                                            editorOptions={{ 
                                                stylingMode: 'underlined',
                                                // labelMode: 'static',
                                                valueChangeEvent: ["keyup", "paste"],
                                                onValueChanged: (sender) => {
                                                    if(field.rules?.length > 0 && sender.value){
                                                        if(field.rules.find((e) => e.type === "NumericRule")){
                                                            const resultRule = sender.value.replace(/\D/g, '')
                                                            props.dataEntity[field.fieldName] = resultRule
                                                        }
                                                    }
                                                    if(field?.changeFieldVisible === "true"){
                                                        repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                    }
                                                },
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            }} 
                                            render={(item) => {
                                                return(
                                                    <UpfPasswordComponent 
                                                        defaultValue={item.editorOptions?.value}
                                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)}
                                                        modeEditOn={props.modeEditOn}
                                                        entityStructure={field}
                                                        setModeEditForm={props.setModeEditForm}
                                                        setFormDataJson={props.setFormDataJson}
                                                        setDataEntity={props.setDataEntity}
                                                        dataEntity={props.dataEntity}
                                                        modeEditForm={props.modeEditForm}
                                                        caption={field.fieldCaption}
                                                        dataEntityEditedFields={props.dataEntityEditedFields}
                                                        setDataEntityEditedFields={props.setDataEntityEditedFields}
                                                    />
                                                )}}
                                        >
                                            <Label 
                                                location="top"
                                                showColon={false}
                                                text={field.fieldCaption}
                                                labelMode='static'
                                            />
                                            {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                        <EmailRule
                                                            //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                            message={rule.message}
                                                        />
                                                        
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                        </SimpleItem>
                                )
                            }
                        } 

                        else if(field.fieldMask === "cpf/cnpj"){
                            // const fieldPermissions = validatePermission(field.objectid)
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm)
                            }

                                return (
                                    <SimpleItem 
                                        // editorType={field.editorType}
                                        editorType="dxTextBox"
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        caption={field.fieldCaption}
                                        dataField={field.fieldName} 
                                        dataSource={props.dataEntity}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        fieldName={field.fieldCaption}
                                        editorOptions={{
                                            stylingMode: 'underlined',
                                            // labelMode: 'static',
                                            hint: field.hint,
                                            mask: !props.modeEditForm? formatFunction("cpf/cnpj", "field", field.fieldName, props.dataEntity) : null,
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            // value: getDefaultValues(field),
                                            // valueChangeEvent: ["keyup", "paste"] se editor for dxTextBox e tiver mask quebra assim
                                            valueChangeEvent: "keyup"
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />

                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                    <EmailRule
                                                        //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                        message={rule.message}
                                                    />
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                        }

                        else if(field.editorType === "dxNumberBox"){
                            // const fieldPermissions = validatePermission(field.objectid)
                            
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm )
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                return (
                                    
                                    <SimpleItem 
                                        editorType={field.editorType}
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        dataField={field.fieldName} 
                                        dataSource={props.dataEntity}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        fieldName={props.dataEntity}

                                        // label={field.fieldCaption}
                                        editorOptions={{
                                            stylingMode: 'underlined',
                                            // label: field.fieldCaption,
                                            // labelMode: 'static',
                                            hint: field.hint,
                                            format: formatFunction(field.fieldMask, "field", field.fieldName, props.dataEntity) ? formatFunction(field.fieldMask, "field", field.fieldName, props.dataEntity) : "#",
                                            //verifica se tem permição pra editar, se for um insert (não tem idValue) verifica a propriedade readOnly
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    // console.log(field?.changeFieldVisible)
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            // value: getDefaultValues(field),
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />

                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                    <EmailRule
                                                        //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                        message={rule.message}
                                                    />
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }

                        else{
                            // const fieldPermissions = validatePermission(field.objectid)
                            
                            if(!props.idValue){
                                getDefaultValues(field, props.dataEntity, props.modeEditForm )
                            }

                            if(fieldPermissions?._show === 1 && field.formVisible === 1){

                                return (
                                    
                                    <SimpleItem 
                                        editorType={field.editorType}
                                        hint={field.hint}
                                        helpText={field.hintDocked ? field.hint : null}
                                        dataField={field.fieldName} 
                                        dataSource={props.dataEntity}
                                        colSpan={colSpanFieldsFunction(field.colSpan, props.colCount)} 
                                        fieldName={props.dataEntity}

                                        // label={field.fieldCaption}
                                        editorOptions={{
                                            stylingMode: 'underlined',
                                            // label: field.fieldCaption,
                                            // labelMode: 'static',
                                            hint: field.hint,
                                            format: formatFunction(field.fieldMask, "field", field.fieldName, props.dataEntity),
                                            //verifica se tem permição pra editar, se for um insert (não tem idValue) verifica a propriedade readOnly
                                            readOnly: readOnlyFunction(field.readOnly, fieldPermissions?._edit),
                                            onValueChanged: (sender) => {
                                                if(field.rules?.length > 0 && sender.value){
                                                    if(field.rules.find((e) => e.type === "NumericRule")){
                                                        const resultRule = sender.value.replace(/\D/g, '')
                                                        props.dataEntity[field.fieldName] = resultRule
                                                    }
                                                }
                                                if(field?.changeFieldVisible === "true"){
                                                    // console.log(field?.changeFieldVisible)
                                                    repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
                                                }
                                            },
                                            // value: getDefaultValues(field),
                                            valueChangeEvent: ["keyup", "paste"]
                                        }}
                                    >
                                        <Label 
                                            location="top"
                                            showColon={false}
                                            text={field.fieldCaption}
                                            labelMode='static'
                                        />

                                        {field.rules && field.rules.map((rule) => {
    
                                            if(rule.type === "EmailRule"){
                                                
                                                return(
                                                    <EmailRule
                                                        //pattern="/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi"
                                                        message={rule.message}
                                                    />
                                                )
                                            }
                                        })}
                                        {field?.rules?.find((rule) => { return rule.ignoreemptyvalue === 0}) &&
                                            <RequiredRule message="Campo obrigatório!" />
                                        
                                        }
                                    </SimpleItem>
                                )
                            }
                        }      
                    })}
                    </GroupItem>
                </Form>
            </form>
        )
    }
    
    return FormFields
}

export default GetFormFields