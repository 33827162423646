//Função para converter as datas no componente dataGrid

export const convertDate = (arrayDataEntityObjects, arrayFieldsStructure) => {
    //varre o array dos campos 
    for(let c = 0; c <= arrayFieldsStructure?.length - 1; c++) {
        //verifica se dentro do objeto data tem uma chave com o nome, e se tem um valor true
        if(arrayFieldsStructure[c].editorType === "dxDateBox" || arrayFieldsStructure[c].editorType === "dxCalendar" || arrayFieldsStructure[c].editorType === "upfTime"){
            for(let i = 0; i <= arrayDataEntityObjects?.length - 1; i++) {
                if(arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName] && arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]?.length === 23){
                    arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName] = arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]
                    .substring(0, arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]?.length - 4)
                }
                else if(arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName] && arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]?.length === 27){
                    console.log(arrayDataEntityObjects[i])
                    console.log(arrayFieldsStructure[c])
                }
                else if(arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName] && arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]?.length > 19){
                    arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName] = arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]
                    .substring(0, arrayDataEntityObjects[i][arrayFieldsStructure[c].fieldName]?.length - 5)
                }
            }
        }
    }
}