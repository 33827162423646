import { FileUploader, Popup, Toolbar } from "devextreme-react";
import { useState, useEffect } from "react"
import { useRequestsApi } from "../../../Context/RequestsApi";
import { applicationID, relationId } from "../../../api/ApplicationID";
import { httpRequest } from "../../../functions";
import "./style.css"

const UpfImageSelect = (props) => {
    const [upfFileSelectValueState, setUpfFileSelectValueState ] = useState()
    const [upfImageUrlState, setUpfImageUrlState ] = useState()

    const {requestsApi, setRequestsApi} = useRequestsApi()

    const salvarImagemNoCaminho =  async (file, name) => {
        var formdata = new FormData();
        formdata.append("files[]", file, name);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        await fetch(requestsApi.uploadFile, requestOptions)
        .then(response => response.text())
        .then(result => {
            setUpfImageUrlState(`${requestsApi.url}${name}`)
            props.referencia.updateData(`${props.dataField}`, `${requestsApi.url}${name}`)
            props.modeEditOn()
        })
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if(props.dataEntity?.[`${props.dataField}`]){
            setUpfImageUrlState(props.dataEntity?.[`${props.dataField}`])
        }
    }, [props.modeEditForm])


    return(
        <div  className="div-upf-image-select">
            <FileUploader
                uploadMode="instantly"
                selectButtonText={"Selecionar arquivo"}
                disabled={props.disabled}
                labelText=""
                multiple={false}
                elementAttr={{
                    class: "uploader"
                }}
                inputAttr="none"
                showFileList={false}                      
                onValueChanged={(e) => {
                    salvarImagemNoCaminho(e.value[0], e.value[0].name)                             
                }}
                height={"100%"}
                width={"100%"}
            />

            {upfImageUrlState &&
                <img style={{height: "40px", position: "relative"}} src={upfImageUrlState}></img>
            }
        </div>
    )
}

export default UpfImageSelect;