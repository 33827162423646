const { createContext, useContext, useState } = require("react");

const LoadPanelVisibleGlobalState = createContext();

export const LoadPanelVisibleGlobalStateProvider = ({ children }) => {
  const [loadPanelVisibleGlobalState, setLoadPanelVisibleGlobalState] = useState(false);

  return (
    <LoadPanelVisibleGlobalState.Provider value={{ loadPanelVisibleGlobalState, setLoadPanelVisibleGlobalState }}>
      {children}
    </LoadPanelVisibleGlobalState.Provider>
  );
};

export const useLoadPanelVisibleGlobalState = () => useContext(LoadPanelVisibleGlobalState);     