import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import { Button } from "devextreme-react/button";
import { useEffect } from 'react';
import './style.css'

const Card = (props) => {

    useEffect(() => {

    },[])

    return(
        <div className="card">
            <h1>{"Título"}</h1>
            
            <Form
                formData={{
                    numerocontrato: "3834759823",
                    primeiraparcelavencimento: "12/12/2020",
                    totalvalor: 3000,
                }}
                colCount={4}
            >
                <GroupItem
                    colCount={4}
                    colSpan={4}
                >
                    <SimpleItem
                        editorType="dxNumberBox"
                        dataField="numerocontrato"
                        disabled={true}
                        colSpan={2}
                        value={2}
                        cssClass="label-card"
                    >
                        <Label 
                            text="Contrato"
                            location="top"
                        />
                    </SimpleItem>

                    <SimpleItem
                        editorType="dxDateBox"
                        dataField="primeiraparcelavencimento"
                        colSpan={2}
                        disabled={true}
                        cssClass="label-card"
                        editorOptions=
                        {{ 
                            dateSerializationFormat:"yyyy-MM-dd"
                        }}
                    >
                        <Label 
                            text="Vencimento"
                            location="top"
                        />
                    </SimpleItem>

                    <SimpleItem
                        editorType="dxNumberBox"
                        colSpan={2}
                        dataField="totalvalor"
                        cssClass="label-card"
                        disabled={true}
                        editorOptions=
                        {{ 
                            format: "R$ #,##0.##;(R$ #,##0.##)",
                            valueChangeEvent: "keyup",
                            disabled: true,
                            // value: gerarBoletoDataSource.arrayTotais.valor,
                            // min: 0
                        }}
                    >
                        <Label 
                            text="Total" 
                            location="top"
                        />
                    </SimpleItem>

                    <SimpleItem
                        colSpan={2}
                    
                    >
                        <Button
                            text="Acessar"
                            icon="arrowright"
                            width="150%"
                            id="button-card"
                            // type="success"
                            rtlEnabled={true}
                            // onClick={() => {trazerOpcoesParcelamentoCredor(titulo)}}
                        />
                    </SimpleItem>
                </GroupItem>
            </Form>
            {/* <div className="button-card-center">
                <Button
                    text="Acessar"
                    colSpan={2}
                    icon="arrowright"
                    width="70%"
                    id="button-card"
                    // type="success"
                    // onClick={() => {trazerOpcoesParcelamentoCredor(titulo)}}
                />
            </div> */}
        </div>

    )
}

export default Card;