const { createContext, useContext, useState } = require("react");

const GerarAcordoDataSource = createContext();

export const GerarAcordoDataSourceProvider = ({ children }) => {
  const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState(null);

  return (
    <GerarAcordoDataSource.Provider value={{ gerarAcordoDataSource, setGerarAcordoDataSource }}>
      {children}
    </GerarAcordoDataSource.Provider>
  );
};

export const useGerarAcordoDataSource = () => useContext(GerarAcordoDataSource);