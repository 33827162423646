import { abrirFichaDiscador } from "./functions/abrirFicha"
import { ramal } from "../../api/ApplicationID"
import { httpRequest } from ".."
import { relationId, applicationID } from "../../api/ApplicationID"

export const processamentoMensagemSocket = (props) => 
{
    //fazer condicionais pela ação
    //abrir ficha
    if(props.mensagem.identificador && props.mensagem.ramal === ramal) {
        httpRequest("POST", props.requestsApi.prendeFicha, props.requestsApi.basicAuth, {
            applicationId: applicationID,
            tituloId: props.mensagem.identificador,
        })
        .then((sender) => {
            console.log(sender)
            abrirFichaDiscador(
                props.mensagem, 
                props.states, 
                props.setStates, 
                props.requestsApi, 
                props.carregarTelecobrancaForm, 
                props.setWidgetsTeleCob, 
                props.setGravouOcorrencia,
                props.setDiscadorEmAtendimentoGlobalState,
                props.backendJson,
                props.setTabPanelItems,
                props.tabPanelItems,
                props.resolve
            )
            props.setChamouProximaFicha(true)
            props.setDiscadorEmAtendimentoGlobalState(true)
        })
        .catch((err) => {
            console.log(err)
        })

    }
    else if(props.mensagem.codigoPausa && props.mensagem.ramal === ramal && !props.popupPausaVisibleState){
        console.log(props?.mensagem?.codigoPausa)
        httpRequest('POST', props.requestsApi.abrirPausa, props.requestsApi.basicAuth, {
            "codigoPausa": props.mensagem.codigoPausa,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            const abrirFichaResultJson = JSON.parse(sender)
            //mudar os dados do popup
            props.setAbrirPausaResultJsonGlobalState(abrirFichaResultJson[0])
            
            //so vai abrir se n tiver ficha
            if(!props.discadorEmAtendimentoGlobalState && props.mensagem.codigoPausa !== '1' && props.mensagem.codigoPausa !== '0'){
                //chamar a função que vai abrir o popup
                props.setPopupPausaVisibleState(true)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
}