const { createContext, useContext, useState } = require("react");

const PausaEscolhidaDadosGlobalState = createContext();

export const PausaEscolhidaDadosGlobalStateProvider = ({ children }) => {
  const [pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState] = useState(null)

  return (
    <PausaEscolhidaDadosGlobalState.Provider value={{ pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState }}>
      {children}
    </PausaEscolhidaDadosGlobalState.Provider>
  );
};

export const usePausaEscolhidaDadosGlobalState = () => useContext(PausaEscolhidaDadosGlobalState);