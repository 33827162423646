const PokeComponent = (cellData) => {
    if(cellData.value === 0){
        return (
            <i
                className={`dx-icon icon-tabtreeview dx-icon-clock`} 
            />
        )
    }
    else if(cellData.value === 1){
        return (
            <i
                className={`dx-icon icon-tabtreeview dx-icon-check`} 
            />
        )
    }
    else if(cellData.value === -1){
        return (
            <i
                className={`dx-icon icon-tabtreeview dx-icon-warning`} 
            />
        )
    }
}

export default PokeComponent;