const { createContext, useContext, useState } = require("react");

const PopupDragAndDrop = createContext();

export const PopupDragAndDropProvider = ({ children }) => {
  const [popupDragAndDrop, setPopupDragAndDrop]= useState(false);

  return (
    <PopupDragAndDrop.Provider value={{ popupDragAndDrop, setPopupDragAndDrop }}>
      {children}
    </PopupDragAndDrop.Provider>
  );
};

export const usePopupDragAndDrop = () => useContext(PopupDragAndDrop);