import { Popup } from 'devextreme-react/popup';
import GerarAcordoForm from '../../Components/TeleCob/GerarAcordoForm';
import GerarBoletoForm from '../../Components/TeleCob/GerarBoletoForm';
import GerarAcordoIturanCustomForm from '../../Components/GerarAcordoIturanCustomForm'
import BaneseCardNegociacaoCustomForm from '../../Components/BaneseCardNegociacaoCustomForm'
import SolNegociacaoCustomForm from '../../Components/SolNegociacaoCustomForm';
import ScrollView from 'devextreme-react/scroll-view';
import { fecharPausa } from '../../functions/fecharPausa';
import { useRequestsApi } from '../../Context/RequestsApi';
import { useProximaFichaDados } from '../../Context/ProximaFichaDados';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import { useAbrirPausaResultJsonGlobalState } from '../../Context/AbrirPausaResultJsonGlobalState';
import { useDiscadorEmAtendimentoGlobalState } from '../../Context/DiscadorEmAtendimentoGlobalState';
import { usePausaEscolhidaDadosGlobalState } from '../../Context/PausaEscolhidaDadosGlobalState';
import { useFichaPesquisadaNaTelaGlobalState } from '../../Context/FichaPesquisadaNaTelaGlobalState';
import { httpRequest } from '../../functions';
import { applicationID } from '../../api/ApplicationID';
import {memo, useEffect, useState} from 'react'
import MensagemCustomForm from '../../Components/MensagemCustomForm';

const UpfPopupForm = (props) => {

    const { requestsApi } = useRequestsApi()
    const {discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState} = useDiscadorEmAtendimentoGlobalState()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState } = useAbrirPausaResultJsonGlobalState()
    const {pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState} = usePausaEscolhidaDadosGlobalState()
    const {fichaPesquisadaNaTelaGlobalState, setFichaPesquisadaNaTelaGlobalState} = useFichaPesquisadaNaTelaGlobalState()

    const limparDadadosProximaFicha = () => {
        if(proximaFichaDados?.tituloId){
            setProximaFichaDados({...proximaFichaDados,
                tituloId: null,
                templateTelecobrancaId: null,
                widgets: null,
                // indexFichaAtual: 0,
                // indexRegraAtual: 0,
                nomeFilaAtual: null, 
                nomeRegraAtual: null,
                credorId: "",
                clienteId: null,
                _qtdOutrosContratosClienteCredorAtual: 0,
                _qtdOutrosContratosCliente: 0
              })
        }
    } 

    const [visibleState, setVisibleState] = useState(false)

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    useEffect(() => {
        if(props.statesUpfPopupForm.visible){
            setVisibleState(true)
        }
    }, [])

    return(
        <Popup
            visible={props.statesUpfPopupForm.visible}
            onHiding={() => {
                try{
                    const _configuracaoCredor = JSON.parse(sessionStorage.getItem("_configuracaoCredor"))
                    const _gerouBoletoTelecob = sessionStorage.getItem("_gerouBoletoTelecob")

                    console.log(_configuracaoCredor?.[0]?._finalizaratendimentogerarboleto)

                    if(props.statesUpfPopupForm.title === "Boleto" && _configuracaoCredor?.[0]?._finalizaratendimentogerarboleto === 1 && _gerouBoletoTelecob === "true"){
                        sessionStorage.removeItem("_configuracaoCredor")
                        // sessionStorage.removeItem("_gerouBoletoTelecob")
                        sessionStorage.setItem("_gerouBoletoTelecob", "false")
                        //encerrar atendimento
                        fecharPausa(
                            discadorEmAtendimentoGlobalState
                            ,fichaPesquisadaNaTelaGlobalState
                            ,httpRequest
                            ,requestsApi
                            ,abrirPausaResultJsonGlobalState
                            ,applicationID
                            ,setDiscadorEmAtendimentoGlobalState
                            ,setFichaPesquisadaNaTelaGlobalState
                            ,tabPanelItems
                            ,setTabPanelItems
                            ,setIndexTabPanelMain
                            ,pausaEscolhidaDadosGlobalState
                            ,null //props.toastConfiguration
                            ,setPopupErrorMessageState
                            ,setPopupErrorVisibleState
                            ,limparDadadosProximaFicha
                            ,setAbrirPausaResultJsonGlobalState
                            ,null //setDesabilitarBotaoGravarOcorrenciaState
                            ,true //telecob
                            ,props.statesUpfPopupForm.tituloId
                        )

                        //fecha popup
                        props.hideUpfPopupForm()
                    }
                    else{
                        props.hideUpfPopupForm()
                    }
                }
                catch{
                    if(props.statesUpfPopupForm.title === "Gerar acordo ituran"){
                        sessionStorage.removeItem("dataSourceIturanJson")
                        props.hideUpfPopupForm()
                    }
                    else if(props.statesUpfPopupForm.title === "Banese card negociação"){
                        sessionStorage.removeItem("dataSourceBaneseJson")
                        props.hideUpfPopupForm()
                    }
                    else{
                        props.hideUpfPopupForm()
                    }
                }
            }}
            dragEnabled={false}
            // hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={props.statesUpfPopupForm.title}
            fullScreen={props.statesUpfPopupForm.title === "Enviar mensagem" ? false : true}
            resizeEnabled={true}
            >
            <ScrollView  width='100%' height='100%' useNative={true}>
                <div>
                    {props.statesUpfPopupForm.title === "Boleto" && props.statesUpfPopupForm.visible &&
                        <>
                            <GerarBoletoForm
                                clienteId={props.statesUpfPopupForm.clienteId}
                                credorId={props.statesUpfPopupForm.credorId}
                                tituloId={props.statesUpfPopupForm.tituloId}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                parcelasIdArray={props.statesUpfPopupForm.parcelasIdArray}
                            />
                        </>
                    }

                    {props.statesUpfPopupForm.title === "Acordo/Novação de dívida" && props.statesUpfPopupForm.visible &&
                        <GerarAcordoForm
                            clienteId={props.statesUpfPopupForm.clienteId}
                            credorId={props.statesUpfPopupForm.credorId}
                            tituloId={props.statesUpfPopupForm.tituloId}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            habilitarGerarAcordo={props.statesUpfPopupForm.execute}
                            parcelasIdArray={props.statesUpfPopupForm.parcelasIdArray}
                        />
                    }

                    {props.statesUpfPopupForm.title === "Gerar acordo ituran" && props.statesUpfPopupForm.visible &&
                        <GerarAcordoIturanCustomForm
                            clienteId={props.statesUpfPopupForm.clienteId}
                            credorId={props.statesUpfPopupForm.credorId}
                            tituloId={props.statesUpfPopupForm.tituloId}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                        />
                    }

                    {props.statesUpfPopupForm.title === "Banese card negociação" && props.statesUpfPopupForm.visible &&
                        <BaneseCardNegociacaoCustomForm
                            clienteId={props.statesUpfPopupForm.clienteId}
                            credorId={props.statesUpfPopupForm.credorId}
                            tituloId={props.statesUpfPopupForm.tituloId}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            popupVisible={props.statesUpfPopupForm.visible}
                        />
                    }

                    {props.statesUpfPopupForm.title === "Sol negociação" && props.statesUpfPopupForm.visible &&
                        <SolNegociacaoCustomForm
                            clienteId={props.statesUpfPopupForm.clienteId}
                            credorId={props.statesUpfPopupForm.credorId}
                            tituloId={props.statesUpfPopupForm.tituloId}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            popupVisible={props.statesUpfPopupForm.visible}
                        />
                    }

                    {props.statesUpfPopupForm.title === "Enviar mensagem" && props.statesUpfPopupForm.visible &&
                        <MensagemCustomForm tituloId={props.statesUpfPopupForm.tituloId}/>
                    }
                </div>
            </ScrollView>
        </Popup>
    )
}

export default UpfPopupForm;