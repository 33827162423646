import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import QueryForm from '../../QueryForm'
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import {useState, memo, useCallback} from "react"
import RecordFormPopup from '../../RecordForm/RecordFormPopup';
import { useProximaFichaDados } from '../../../Context/ProximaFichaDados';
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID, DiscadorAtivo } from '../../../api/ApplicationID';
import { useAbrirPausaResultJsonGlobalState } from '../../../Context/AbrirPausaResultJsonGlobalState';
import { useIndexTabPanelMain } from "../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../Context/TabPanelItems";
import { useLoadPanelVisibleGlobalState } from '../../../Context/LoadPanelVisibleGlobalState';
import { useTelecobTabPanelItemsGlobalState } from '../../../Context/TelecobTabPanelItemsGlobalState';
import { useTelecobIndexTabPanelGlobalState } from '../../../Context/TelecobIndexTabPanelGlobalState';
import MultiViewMainTeleCob from '../../TeleCob/MultiViewMainTeleCob';
import "./style.css"

const QueryFormPopup = (props) => {
    const {telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState} = useTelecobTabPanelItemsGlobalState()
    const {telecobIndexTabPanelGlobalState, setTelecobIndexTabPanelGlobalState} = useTelecobIndexTabPanelGlobalState()

    const [selectItemQueryFormPopup, setSelectItemQueryFormPopup] = useState()
    const [clicouNoBotaoOkState, setClicouNoBotaoOkState] = useState(false)

    const { requestsApi } = useRequestsApi()

    const { abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState } = useAbrirPausaResultJsonGlobalState()

    const {loadPanelVisibleGlobalState, setLoadPanelVisibleGlobalState} = useLoadPanelVisibleGlobalState()

    //usado no telecob
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()

    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();

    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain();


    const enviarSelecionado = (item) => {
        console.log(selectItemQueryFormPopup)
        console.log(props)

        const selectItemQueryForm = selectItemQueryFormPopup ? selectItemQueryFormPopup : item
        
        if(selectItemQueryForm) {
            // sessionStorage.setItem("idTabSelecionada", parseInt(sessionStorage.getItem("idTabSelecionada")) + 1)
            // setTelecobTabPanelItemsGlobalState([...telecobTabPanelItemsGlobalState,
            //     {
            //         title: `Pesquisa`
            //         , icon: "tel"
            //         , badge: ""
            //         , id : parseInt(sessionStorage.getItem("idTabSelecionada"))
            //         , component: <MultiViewMainTeleCob 
            //                 className="tabpanel-main-telecob"
            //                 idTab={parseInt(sessionStorage.getItem("idTabSelecionada"))}
            //             />
            //         , text: Math.random()
            //     }
            // ])
            if(props.abrirFicha){
                //limpa as tabs apos gravar ocorrencia
                let tempTabPanelItems = tabPanelItems

                tempTabPanelItems.splice(1, tabPanelItems.length)

                setTabPanelItems(tempTabPanelItems)
                setIndexTabPanelMain(0)

                setLoadPanelVisibleGlobalState(true)

                setClicouNoBotaoOkState(true)

                httpRequest("POST", requestsApi.prendeFicha, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    tituloId: selectItemQueryForm?.id,
                })
                .then((sender) => {
                    console.log(sender)
                    sessionStorage.removeItem("mensagemSocket")
                    props.abrirFicha({
                        tituloId: selectItemQueryForm?.id,
                        credorId: selectItemQueryForm?._credorid,
                        _clienteId: selectItemQueryForm?._clienteid,
                        nomeFilaAtual: null,
                        nomeRegraAtual: null,
                        _qtdoutroscontratoscliente: selectItemQueryForm?._qtdoutroscontratoscliente,
                        _qtdoutroscontratosclientecredoratual: selectItemQueryForm?._qtdoutroscontratosclientecredoratual
                    })
                })
                .catch((err) => {
                    console.log(err)
                })

                props.setForeignFieldSelectedItem(selectItemQueryForm)
                props.setPopupQueryFormOpen(false)
            } else {
                props.setForeignFieldSelectedItem(selectItemQueryForm)
                props.setPopupQueryFormOpen(false)

                if(props.modeEditOn) {
                    props.modeEditOn()
                }
            }
        }
    }


    const onHidingFunction = () => {
        if(DiscadorAtivo && props.abrirFicha && !clicouNoBotaoOkState && props.queryPopupType !== "outrosContratos"){            
            httpRequest('POST', requestsApi.fecharPausa, requestsApi.basicAuth, {     
                pausaId: "06B6D084A2E94188A06376BF6D9517BF",
                applicationId: applicationID 
            })
            .then((sender) => {
                setAbrirPausaResultJsonGlobalState(null);
                props.setPopupQueryFormOpen(false)
            })
            .catch(console.log)
        } else {
            props.setPopupQueryFormOpen(false)
        }
        
    }

    const RenderQueryForm = useCallback(() => {
        return (
            <QueryForm
                entityName={props.entityName}
                foremFieldSelectedItem={props.foremFieldSelectedItem}
                setForemFieldSelectedItem={props.setForemFieldSelectedItem}
                queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                setSelectItemQueryFormPopup={setSelectItemQueryFormPopup}
                selectItemQueryFormPopup={selectItemQueryFormPopup}
                setQueryFormPopupState={props.setQueryFormPopupState}
                queryFormPopupState={props.queryFormPopupState}
                // enableBatchEditing={true}
                modeEditOn={props.modeEditOn}
                setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                foreignFieldDataSource={props.foreignFieldDataSource?.length > 0 && props.foreignFieldDataSource}
                telecob={props.telecob}
                enviarSelecionado={enviarSelecionado}
                queryPopupType={props.queryPopupType}
                outrosContratosDataSource={props.outrosContratosDataSource}
                trazerTodosRegistros={props.trazerTodosRegistros}
            />
        );
    }, 
    [
        props.popupQueryFormOpen
        ,props.entityName
        ,props.foremFieldSelectedItem
        ,props.setForemFieldSelectedItem
        ,props.queryFormPopupForeignFieldState
        ,props.setPopupQueryFormOpen
        ,props.setQueryFormPopupState
        ,props.queryFormPopupState
        ,props.setTituloQueryFormPopup
        ,props.foreignFieldSelectedItem
        ,props.foreignFieldDataSource
        ,props.telecob
        ,props.queryPopupType
        ,props.outrosContratosDataSource
        ,props.trazerTodosRegistros
    ])

    const buttonQueryFormPopup = {
        width: 120,
        text: "OK",
        type: "default",
        stylingMode: "contained",
        onClick: enviarSelecionado
    };

    return(
            <Popup
                visible={props.popupQueryFormOpen}
                onHiding={onHidingFunction}
                // onHidden={setPopupQuery}
                // dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={props.tituloQueryFormPopup}
                // width={800}
                // height={600}
                // position = "right"
                // resizeEnabled={true}
                dragEnabled={false}
                fullScreen={true}
                animation={null}
                deferRendering={false}
            >
                    <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={buttonQueryFormPopup}
                        />
                    <ScrollView width='100%' height='100%' useNative={true}>
                        {props.popupQueryFormOpen === true ? <RenderQueryForm/> : <></>}
                    </ScrollView>
            </Popup>
    )
}

export default memo(QueryFormPopup);