const { createContext, useContext, useState } = require("react");

const TabPanelItems = createContext();

export const TabPanelItemsProvider = ({ children }) => {
  const [tabPanelItems, setTabPanelItems] = useState([]);

  return (
    <TabPanelItems.Provider value={{ tabPanelItems, setTabPanelItems }}>
      {children}
    </TabPanelItems.Provider>
  );
};

export const useTabPanelItems = () => useContext(TabPanelItems);