import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import {useState, useEffect, memo} from "react"
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import GetFormComponents from '../../functions/functionsMapForm/GetFormComponents';
import Loading from '../Loading';
import { Button } from 'devextreme-react/button';
import UpfPopupCustomForm from '../UpfPopupCustomForm';
import ContextMenu from 'devextreme-react/context-menu';
import RecordFormPopup from '../../Components/RecordForm/RecordFormPopup';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { validatePermission } from '../../functions/validatePermission';
import SelectBox from 'devextreme-react/select-box';

const UpfCardKanban = (props) => {
    const {requestsApi} = useRequestsApi()

    console.log(props)

    const [statusTarefaState, setStatusTarefaState] = useState()
    // const [taskState, setTaskState] = useState()

    useEffect(() => {
        if(props.task?._statustarefaid){
            setStatusTarefaState(props.task?._statustarefaid)
            // setTaskState(props.task)
        }
    }, [])

    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null,
        caption: null
    })
    const [states, setStates] = useState({
        loadedContentState: false,
        entityStructure: null,
        dataEntity: "",
        popup: ""
    })
    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState(false);

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                idValue={props.task.id}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                masterEntityId={props.task.id}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
            />
        );
    })

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }

    // const openTask = () => {
    //     setUpfPopupCustomFormStates({
    //         visible: true,
    //         title: "Tarefa",
    //         formName: "TarefaCustomForm",
    //         tarefaId:props.task.id,
    //         entityName: props.task._entityname,
    //         idValue: props.task._objectid
    //     })
    // }

    const openTask = () => {
        if(states.entityStructure?.[0]?.masterEntity["customRecordForm"]){
            //tipos de customRecordForm
            if(states.entityStructure[0].masterEntity["customRecordForm"] === "tarefaCustomForm"){
                setUpfPopupCustomFormStates({
                    visible: true,
                    title: "Tarefa",
                    formName: "TarefaCustomForm",
                    entityName: props.entityName,
                    tarefaId:props.task.id,
                    idValue: props.task._objectid
                })
            }
        }
        else {
            // setIDValueDetailEntity(idValue)
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tarefa"
            })
            .then((sender) => { 
                const resultGetEntityStructureJson = JSON.parse(sender)

                setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

                if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                    })
                    .then((sender) => {
                        const senderDataLookup = JSON.parse(sender)
                        setDataLookupRecordFormPopupState(senderDataLookup)

                        setDetailEntityName("_tarefa")
                        setDetailCaptionName("Tarefas")
                        setPopupState(true)
                        setPopupRecordFormOpen(true)
                        setIDValueDetailEntity(props.task._objectid)
                        setEmbebedIdValueState(props.task._objectid)
                        setEmbebedEntityNameState(props.task._entityname)

                        // setIdValue(props.task.id)
                        // setMasterEntityId(props.task.id)
                        // setKeyFieldNameState()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
                else{
                    setDetailEntityName("_tarefa")
                    setDetailCaptionName("Tarefas")
                    setPopupState(true)
                    setPopupRecordFormOpen(true)    
                    setIDValueDetailEntity(props.task._objectid)
                    setEmbebedIdValueState(props.task._objectid)
                    setEmbebedEntityNameState(props.task._entityname)
                }
            })
            
            
            // setKeyFieldNameState(senderDetailEntity.keyFieldName)
        }
        
    }

    const createNewTask = () => {
        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            applicationId: applicationID,
            entityName: "_tarefa"
        })
        .then((sender) => { 
            const resultGetEntityStructureJson = JSON.parse(sender)

            setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

            if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                    "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                })
                .then((sender) => { 
                    const senderDataLookup = JSON.parse(sender)
                    setDataLookupRecordFormPopupState(senderDataLookup)

                    setDetailEntityName("_tarefa")
                    setDetailCaptionName("Tarefas")
                    setPopupState(true)
                    setPopupRecordFormOpen(true)
                })
                .catch((error) => {
                    console.log(error)
                })
            }
            else{
                setDetailEntityName("_tarefa")
                setDetailCaptionName("Tarefas")
                setPopupState(true) 
                setPopupRecordFormOpen(true)    
            }
        })
    }

    const deleteTaskFunction = () => {
        httpRequest('POST', requestsApi.deleteRecordFromJson, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_tarefa",
            "fieldName": "id",
            "expressao": props.task.id,
            "relationId": relationId
        })
        .then((sender) => {
            props.refreshData()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const newTask = {
        text: "Nova tarefa"
        ,icon: "add"
        ,disabled: props.objectPermissionsState ? !props.objectPermissionsState?._insert : true
        ,onClick: ()=>{
            createNewTask()
        }
    }

    const updateTask = {
        text: "Visualizar tarefa"
        ,icon: "folder"
        ,disabled: props.objectPermissionsState ? !props.objectPermissionsState?._show : true
        ,onClick: ()=>{
            openTask()
        }
    }

    const deleteTask = {
        text: "Deletar tarefa"
        ,icon: "close"
        ,disabled: props.objectPermissionsState ? !props.objectPermissionsState?._delete : true
        ,onClick: ()=>{
            deleteTaskFunction()
        }
    }

    return(
        <div id={`indiceTask-${props.indiceTask}`} className="cardTarefa dx-card dx-theme-text-color dx-theme-background-color">
            {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                    tarefaId={upfPopupCustomFormStates.tarefaId}
                    // entityStructure={entityStructureRecordFormPopupState}
                />
            }

            {popupRecordFormOpen && <RenderRecordFormPopup />}

            <div className={`card-priority priority-${props.task._indice}`}></div>

            <Toolbar>

                    <Item 
                        location="before"
                        locateInMenu="always"
                        widget="dxButton"
                        options={newTask}
                    />

                
                    <Item 
                        location="before"
                        locateInMenu="always"
                        widget="dxButton"
                        options={deleteTask}
                    />
                
                
                    <Item 
                        location="before"
                        locateInMenu="always"
                        widget="dxButton"
                        options={updateTask}
                    />

                    <Item
                        location="before"
                    >
                        <div className="card-subject">Código: {props.task._codigo}</div>
                        <div className="card-subject">Nome funcionário: {props.task._CryptFuncionarioNome}</div>
                    </Item>

                    {/* <Item
                        location="before"
                       
                    > */}
                        {/* <div className="card-subject">{props.task._TipoTarefaDescricao}</div> */}
                    {/* </Item> */}

                    <Item
                        location="before"
                    >
                        <div className="card-subject"  style={{marginLeft: "20px", maxWidth: "80%"}}>Descrição: 
                           {props.task._descricao}
                        </div>
                    </Item>

                    <Item 
                        location="after"
                        // locateInMenu="always"
                        // widget="dxButton"
                        // options={newTask}
                    >
                        <SelectBox
                            dataSource={props.resultTarefasFuncionarioJsonState}
                            displayExpr={"_descricao"}
                            valueExpr={"id"}
                            width={200}
                            value={statusTarefaState}
                            onValueChanged = {(e) => {
                                if(statusTarefaState){
                                    setStatusTarefaState(e.value)
                                    if(props.task?._statustarefaid){
                                        props.updateData(props.task.id, e.value)
                                    }
                                }
                            }}
                        />

                    </Item>

                </Toolbar>


            {/* <ContextMenu
                dataSource={[{"text": "Abrir tarefa", icon: "folder"}]}
                target={`#indiceTask-${props.indiceTask}`}
                onItemClick={openTask} 
            /> */}

                {/* <SpeedDialAction
                    icon="add"
                    hint="Incluir nova tarefa"
                    index={1}
                    visible={true}
                    onClick={createNewTask}
                /> */}
            
        </div>
    )
}

export default UpfCardKanban;