import { Popup } from 'devextreme-react/popup';
import RecordForm from '../../RecordForm';
import { GetEntityRecords, applicationID } from '../../../api/ApplicationID'
import { useState, useRef } from 'react'
import ScrollView from 'devextreme-react/scroll-view';
// import { usePopupView } from '../../../Context/PopupView'
import { memo } from 'react';
import AttachRecordForm from '../AttachRecordForm';
const RecordFormPopup = (props) => {    
    const [popupView, setPopupView] = useState()
    const [selectedProcess, setSelectedProcess] = useState()
    const [configRecordFormPopup, setConfigRecordFormPopup] = useState({
        fullScreen: true,
        dragEnabled: false,
        icon: "unselectall"
    })

    console.log(props)


    const fecharPopup = () => {
        props.setPopupState(false)
        props.setPopupRecordFormOpen(false)
    }

    if (props.type === "view") {
        return (
            <Popup
                visible={props.popupState}
                onHiding={fecharPopup}
                dragEnabled={configRecordFormPopup.dragEnabled}
                fullScreen={configRecordFormPopup.fullScreen}
                hideOnOutsideClick={false}
                animation={null}
                toolbarItems={
                    [
                        {
                            widget: "dxButton",
                            location: "after",
                            options: {
                                icon: configRecordFormPopup.icon,
                                onClick: () => {
                                    if (configRecordFormPopup.icon === "square") {
                                        setConfigRecordFormPopup(
                                            {
                                                fullScreen: true,
                                                dragEnabled: false,
                                                icon: "unselectall"
                                            }
                                        )
                                    } else {
                                        setConfigRecordFormPopup(
                                            {
                                                fullScreen: false,
                                                dragEnabled: true,
                                                icon: "square"
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    ]}
                showCloseButton={true}
                showTitle={true}
                title={props.caption}
                id="popupForm"
            >
                <ScrollView width='100%' height='100%' useNative={true}>
                    <RecordForm
                        id={Math.random() + 1}
                        idValue={props.idValue}
                        entityName={props.entityName}
                        masterEntityId={props.masterEntityId}
                        keyFieldNameState={props.keyFieldNameState}
                        modeEditOn={props.idValue ? false : true}
                        selectedItens={props.selectedItens}
                        selectedItensCount={props.selectedItensCount}
                        setSelectedProcess={setSelectedProcess}
                        selectedProcess={selectedProcess}
                        detailEntityName={props.detailEntityName}
                        popupRecordForm={props.popupRecordForm}
                        setPopupRecordFormOpen={props.setPopupRecordFormOpen}
                        setPopupState={props.setPopupState}
                        popupRecordFormOpen={props.popupRecordFormOpen}
                        embebedIdValue={props.embebedIdValueState}
                        embebedEntityName={props.embebedEntityNameState}
                        dataLookup={props.dataLookup}
                        entityStructure={props.entityStructure}
                    // IDValueDetailEntity={props.IDValueDetailEntity} linha para abrir em uma nova aba 
                    />

                </ScrollView>
            </Popup>
        )
    } 
    else {
        return (
            <Popup
                ref={props.referenciaPopup}
                visible={props.popupState}
                onHiding={fecharPopup}
                dragEnabled={configRecordFormPopup.dragEnabled}
                fullScreen={configRecordFormPopup.fullScreen}
                hideOnOutsideClick={false}
                animation={null}
                // deferRendering={false}
                // title={props.entityStructure[0].masterEntity.caption}
                toolbarItems={
                    [
                        {
                            widget: "dxButton",
                            location: "after",
                            options: {
                                icon: configRecordFormPopup.icon,
                                onClick: () => {
                                    if (configRecordFormPopup.icon === "square") {
                                        setConfigRecordFormPopup(
                                            {
                                                fullScreen: true,
                                                dragEnabled: false,
                                                icon: "unselectall"
                                            }
                                        )
                                    } else {
                                        setConfigRecordFormPopup(
                                            {
                                                fullScreen: false,
                                                dragEnabled: true,
                                                icon: "square"
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    ]}
                showCloseButton={true}
                showTitle={true}
                title={props.caption}
                id="popupForm"
            >
                <ScrollView width='100%' height='100%' useNative={true}>
                    <RecordForm
                        id={Math.random() + 1}
                        idValue={props.idValue}
                        entityName={props.entityName}
                        masterEntityId={props.popupRecordFormAdd ? (props.popupRecordFormAdd) : (props.masterEntityId)}
                        keyFieldNameState={props.keyFieldNameState}
                        modeEditOn={props.idValue ? false : true}
                        selectedItens={props.selectedItens}
                        setSelectedProcess={setSelectedProcess}
                        selectedProcess={selectedProcess}
                        detailEntityName={props.detailEntityName}
                        popupRecordForm={props.popupRecordForm}
                        IDValueDetailEntity={props.IDValueDetailEntity}
                        setPopupRecordFormOpen={props.setPopupRecordFormOpen}
                        setPopupState={props.setPopupState}
                        popupRecordFormOpen={props.popupRecordFormOpen}
                        masterDetailEntity={props.masterDetailEntity}
                        embebedIdValue={props.embebedIdValueState}
                        embebedEntityName={props.embebedEntityNameState}
                        dataLookup={props.dataLookup}
                        entityStructure={props.entityStructure}
                        referenciaPopup={props.referenciaPopup}
                        fecharPopup={fecharPopup}
                        dataEntity={props.dataEntity}
                        teleCobranca={props.teleCobranca}
                    />
                </ScrollView>
            </Popup>
        )

    }
}

export default memo(RecordFormPopup);