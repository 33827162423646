const { createContext, useContext, useState } = require("react");

const DiscadorEmAtendimentoGlobalState = createContext();

export const DiscadorEmAtendimentoGlobalStateProvider = ({ children }) => {
  const [discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState] = useState(false)

  return (
    <DiscadorEmAtendimentoGlobalState.Provider value={{ discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState }}>
      {children}
    </DiscadorEmAtendimentoGlobalState.Provider>
  );
};

export const useDiscadorEmAtendimentoGlobalState = () => useContext(DiscadorEmAtendimentoGlobalState);