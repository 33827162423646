const { createContext, useContext, useState } = require("react");

const UpfFloatPopupMessageVisibleGlobalState = createContext();

export const UpfFloatPopupMessageVisibleGlobalStateProvider = ({ children }) => {
  const [upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState] = useState({
    visible: false,
    fixo: false,
    mensagemId: null,
    actionName: null
  })

  return (
    <UpfFloatPopupMessageVisibleGlobalState.Provider value={{ upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState }}>
      {children}
    </UpfFloatPopupMessageVisibleGlobalState.Provider>
  );
};

export const useUpfFloatPopupMessageVisibleGlobalState = () => useContext(UpfFloatPopupMessageVisibleGlobalState);