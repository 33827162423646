import { useState, useEffect, useCallback, useRef } from "react"
import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    GroupItem,
    HeaderFilter,
    SearchPanel,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    MasterDetail,
    ColumnChooser,
    FilterRow,
    TotalItem
} from 'devextreme-react/data-grid';
import DateRangeBox from 'devextreme-react/date-range-box';
import './style.css'
import { Button } from 'devextreme-react/button';
import { httpRequest } from "../../../functions";
import { useRequestsApi } from "../../../Context/RequestsApi";
import { applicationID, relationId} from "../../../api/ApplicationID";
import { useBackendJson } from "../../../Context/BackendJson";
import { validatePermission } from "../../../functions/validatePermission";
import { calculateCustomSummary } from "../../../functions/calculateCustomSummary";
import UpfHtmlEditor from "../../UpfComponents/UpfHtmlEditor";
import UpfAudio from "../../UpfComponents/UpfAudio";
import UpfFileSelect from "../../UpfComponents/UpfFileSelect";
import UpfPasswordComponent from "../../UpfComponents/UpfPasswordComponent";
import DataSource from 'devextreme/data/data_source';
import DataGridLog from "./DataGridLog";
import { changeDataRowColor } from "../../../functions/changeDataRowColor";
import { Width } from "devextreme-react/chart";

const LogCustomForm = (props) => {
    const { requestsApi } = useRequestsApi()
    const {backendJson, setBackendJson} = useBackendJson()
    const fieldsPermissionUpdateArray = []
    const refDataGrid = useRef(null)

    const [dataSourceState, setDataSourceState] = useState([])
    const initialValue = [new Date().setDate(new Date().getDate() - 10), new Date().setDate(new Date().getDate())];
    const [endDateState, setEndDateState] = useState(new Date().setDate(new Date().getDate()))
    const [startDateState, setStartDateState] = useState(new Date().setDate(new Date().getDate() - 10))
    const [arrayLookupDataSourceEntityFields, setArrayLookupDataSourceEntityFieldsState] = useState([])


    const httpRequestGetView = (method, url, bodyRequest) =>{
        const isJson = (data) => {
            try {
                JSON.parse(data);
            } catch (e) {
                return false;
            }
        
            return true;
        }
    
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);        
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");
            // xhr.setRequestHeader("revolution-token", "617C6FD67BB04EB78FCACF0EFF619109");//token temporario banese
           
            xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            }
            else if(this.status > 400) {
                reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
                });
            }
            };
            xhr.onerror = function () {
                console.log("onerror")
                if(this.status === 0){
                    reject({
                        status: this.status
                        ,statusText: xhr.statusText
                        ,data: {error: "Ocorreu um erro!"}
                    });
                }
                else{
                    reject({
                        status: this.status
                        ,statusText: xhr.statusText
                        ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
                    });
                }
                
            };
            xhr.send(JSON.stringify(bodyRequest));
        })
    }

    const getLog = (startDate, endDate) => {
        if(startDate, endDate){
            const dataInicio = new Date(startDate).toISOString().slice(0, 10)
            const dataFim = new Date(endDate).toISOString().slice(0, 10)
            let getView = null

            if(props.dados){
                let objectIdsDetalhes = ""

                for(let c = 0; c <= props.dados.length - 1; c++){
                    if(c === 0){
                        objectIdsDetalhes = `${props.dados[c].id}%27,`

                    }
                    else if(c === props.dados.length - 1){
                        objectIdsDetalhes = objectIdsDetalhes + ` %27${props.dados[c].id}`
                    }
                    else{
                        objectIdsDetalhes = objectIdsDetalhes + ` %27${props.dados[c].id}%27,`
                    }
                }

                console.log(objectIdsDetalhes)

                getView = `${backendJson.protocol}//${backendJson.api_host}:${backendJson.api_port}/techfyService/getView?viewName=_viewSystemLog&filtros=data between'${dataInicio}'AND'${dataFim}'AND _objectId IN ('${objectIdsDetalhes}')`
            }
            else{
                getView = `${backendJson.protocol}//${backendJson.api_host}:${backendJson.api_port}/techfyService/getView?viewName=_viewSystemLog&filtros=data between'${dataInicio}'AND'${dataFim}'AND _objectId='${props.objectId}'`
            }





            httpRequestGetView('GET', getView)
            .then((sender) => {
                const resultJson = JSON.parse(sender)
                setDataSourceState(resultJson)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
 
    useEffect(() => {
        if(backendJson){
            console.log(backendJson)
            const dataInicio = new Date(initialValue[0]).toISOString().slice(0, 10)
            const dataFim = new Date(initialValue[1]).toISOString().slice(0, 10)

            let getView = null

            if(props.dados){
                let objectIdsDetalhes = ""

                if(props.dados.length > 1){
                    for(let c = 0; c <= props.dados.length - 1; c++){
                        if(c === 0){
                            objectIdsDetalhes = `${props.dados[c].id}%27,`

                        }
                        else if(c === props.dados.length - 1){
                            objectIdsDetalhes = objectIdsDetalhes + ` %27${props.dados[c].id}`
                        }
                        else{
                            objectIdsDetalhes = objectIdsDetalhes + ` %27${props.dados[c].id}%27,`
                        }
                    }
                }
                else{
                    objectIdsDetalhes = props.dados[0].id
                }

                getView = `${backendJson.protocol}//${backendJson.api_host}:${backendJson.api_port}/techfyService/getView?viewName=_viewSystemLog&filtros=data between'${dataInicio}'AND'${dataFim}'AND _objectId IN ('${objectIdsDetalhes}')`
            }
            else{
                getView = `${backendJson.protocol}//${backendJson.api_host}:${backendJson.api_port}/techfyService/getView?viewName=_viewSystemLog&filtros=data between'${dataInicio}'AND'${dataFim}'AND _objectId='${props.objectId}'`
            }

            console.log(getView)


            httpRequestGetView('GET', getView)
            .then((sender) => {
                const resultJson = JSON.parse(sender)
                setDataSourceState(resultJson)
            })
            .catch((err) => {
                console.log(err)
            })
        }
        
    }, [backendJson])

    const onCurrentValueChange = useCallback(({ value: [startDate, endDate] }) => {
        console.log(startDate)
        console.log(endDate)
        if (startDate) {
            setStartDateState(startDate)
        }
        if (endDate) {
            setEndDateState(endDate) 
        }
    },[setStartDateState, setEndDateState],);

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const onEditorPreparing = (e) => {
        if(e.editorName === "dxCheckBox"){
            e.editorOptions.value = e.value === null ? false : e.value
        }
    }

    function onCellPreparedFunctionColor(e, item) {
        // console.log(item)
        // console.log(e)
        changeDataRowColor(e, item?.rowColor)
    }

    const onCellPrepared = (e) => {
        onCellPreparedFunctionColor(e, props.estrutura)
    }

    const renderDataGridLog = useCallback((item) => {
        return (
            <DataGridLog 
                dataSourceState={dataSourceState}
                idItemPai={item.key}
            />
    )
    }, [dataSourceState]);

    return(
        <>
            <div id="date-range-box-log-custom-form">
                <DateRangeBox 
                    multiView={false} 
                    onValueChanged={onCurrentValueChange}
                    defaultValue={initialValue}
                />

                <Button
                    style={{"marginLeft": "0.5em", "height": "35px", "width": "200px"}}
                    // icon="search"
                    onClick={() => {
                        console.log('getlog')
                        getLog(startDateState, endDateState)
                    }}
                    icon='find'
                    text='Pesquisar'
                    type='default'
                    stylingMode='default'
                    useSubmitBehavior={true}
                />
            </div>


            {props.dados && props.estrutura ?
                (
                    <DataGrid
                        //onEditingStart: event when click to edit or add new registry
                        ref={refDataGrid}
                        keyExpr="id"
                        className="datagrid-detail-entity"
                        dataSource={props.dados}
                        errorRowEnabled={false}
                        //repaintChangesOnly={true} (pode afetar a performance) comentado 17/05/2022, pois quando agrupava no grid e tentava editar e depois salvar quebrava o groupIndex                
                        renderAsync={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        // onRowInserting={onRowInserting}
                        // onRowRemoved={onRowRemoved}
                        // onSaving={handleBatchSave}                
                        onEditorPreparing={onEditorPreparing}
                        onCellPrepared={onCellPrepared}
                        // onRowDblClick={onRowDblClick}
                        allowColumnReordering={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        focusedRowEnabled={true}
                        focusedRowKey={0}
                        columnMinWidth={50}
                        // onContextMenuPreparing={openMenuContextFunction}
                        activeStateEnabled={false}
                        cacheEnabled={false}
                        // onSelectionChanged={onSelectionChanged}
                        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                        // onEditCanceled={() => {
                        //     setModeEditDataGrid({
                        //         edit: false
                        //     })
                        // }}
                    >
                        {/* <Selection
                            keyExpr="id"
                            mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="onClick"
                        /> */}
                        <Scrolling
                            // rowRenderingMode='virtual'
                            // showScrollbar='always'
                            useNative={true}
                            rowRenderingMode="standard"
                            mode="standard"
                        >
                        </Scrolling>

                        <Paging defaultPageSize={10} />

                        <FilterRow visible={props.estrutura.showFilterRow === 1} />

                        <Sorting
                            mode="multiple"
                        />
                        <GroupPanel
                            visible={(props.estrutura.showGroupPanel === 1)}
                            //visible={true}
                            allowColumnDragging={true}
                        />
                        <Pager
                            visible={true}
                            allowedPageSizes={[10, 25, 50, 100]}
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true} />
                        {/* <Editing
                            allowDeleting={modeEditDataGrid.edit && props.itemPermissions?._delete}
                            allowUpdating={modeEditDataGrid.edit && props.itemPermissions?._edit}
                            // allowAdding={modeEditDataGrid.edit && props.itemPermissions?._insert}
                            confirmDelete={true}
                            mode="batch"
                            useIcons={true}
                        //selectTextOnEditStart={selectTextOnEditStart}
                        //startEditAction={startEditAction}
                        /> */}
                        {/* <Column
                            caption="Deletar"
                            visible={modeEditDataGrid.edit && props.itemPermissions?._delete}
                            type="buttons"
                            width={110}
                        >
                            <Button
                                name="delete"
                            />
                        </Column> */}
                        <ColumnChooser
                            enabled={false}
                            width={400}
                            height={800}
                            mode={"select"}
                            allowSearch={true} />
                        <HeaderFilter
                            allowSearch={true}
                            visible={true}
                        />
                        {/* <SearchPanel visible={true} /> */}
                        {props.estrutura.fieldsDefs && props.estrutura.fieldsDefs.map((senderFieldsDefs, senderIndiceFieldsDefs) => {
                            /**
                             * Verifica se o conditionalVisibleFieldName está preenchido,
                             * se estiver, verifica se os dados da entidade são iguais ao valor
                             * do conditionalVisibleFieldValue para determinar se o campo deve ser exibido ou não
                             */

                            const fieldPermissions = validatePermission(senderFieldsDefs.objectid)

                            if(fieldPermissions?._edit === 1){
                                fieldsPermissionUpdateArray.push(senderFieldsDefs.fieldName)
                            }


                            const records = props.dados;

                            if(senderFieldsDefs.conditionalVisibleFieldName){
                                const fieldValueArray = senderFieldsDefs.conditionalVisibleFieldValue.split(",");   

                                if (records && !fieldValueArray.includes(records[senderFieldsDefs.conditionalVisibleFieldName])) {                                             
                                    senderFieldsDefs.gridVisible = 0;
                                    senderFieldsDefs.formVisible = 0;
                                } else {
                                    senderFieldsDefs.gridVisible = 1;
                                    senderFieldsDefs.formVisible = 1;
                                }

                            }

                            if(senderFieldsDefs.conditionalVisibleMasterFieldName){
                                const dataCodicionalAtual = props.dataEntity?.masterEntity?.[0]?.records?.[0][`${senderFieldsDefs.conditionalVisibleMasterFieldName}`]
                                let condicionalValueMaster = senderFieldsDefs.conditionalVisibleMasterFieldValue; 
                                if(condicionalValueMaster.split(",").length > 1){
                                    condicionalValueMaster = condicionalValueMaster.split(",")

                                    console.log(condicionalValueMaster)
                                    console.log(dataCodicionalAtual)


                                    if (records && !condicionalValueMaster.includes(dataCodicionalAtual)) {                                             
                                        senderFieldsDefs.gridVisible = 0;
                                        senderFieldsDefs.formVisible = 0;
                                    } else {
                                        senderFieldsDefs.gridVisible = 1;
                                        senderFieldsDefs.formVisible = 1;
                                    }
                                }
                                else{

                                    console.log(condicionalValueMaster)
                                    console.log(dataCodicionalAtual)
                                    
                                    if (records && condicionalValueMaster !== dataCodicionalAtual) {                                             
                                        senderFieldsDefs.gridVisible = 0;
                                        senderFieldsDefs.formVisible = 0;
                                    } else {
                                        senderFieldsDefs.gridVisible = 1;
                                        senderFieldsDefs.formVisible = 1;
                                    }
                                }
        


                                
                            }        
                            
                            if (senderFieldsDefs.editorType === "dxCheckBox") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // editorOptions={editorOptionsColumns}
                                        dataType={"boolean"}
                                    >

                                    </Column>
                                )
                            }

                            else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "datetime") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // editorOptions={editorOptionsColumns}
                                        dataType={senderFieldsDefs.fieldType}
                                        format="shortDateShortTime"
                                    >

                                    </Column>
                                )
                            }

                            else if (senderFieldsDefs.editorType === "upfTime") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // editorOptions={editorOptionsColumns}
                                        dataType={"datetime"}
                                        format="shortTime"
                                    >

                                    </Column>
                                )
                            }

                            else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "date") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // editorOptions={editorOptionsColumns}
                                        dataType={senderFieldsDefs.fieldType}
                                        format="shortDate"
                                    >

                                    </Column>
                                )
                            }

                            else if (senderFieldsDefs.editorType === "dxLookupEdit") {
                                if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup) {

                                    const newDataSource = new DataSource({
                                        store: {
                                        data: props.dataLookup[senderFieldsDefs.lookUpListSource],
                                        type: 'array',
                                        key: senderFieldsDefs.lookUpKeyField,
                                        },
                                    });


                                    return (
                                        <Column
                                            key={senderIndiceFieldsDefs}
                                            fixed={senderFieldsDefs.fixed}
                                            visible={senderFieldsDefs.gridVisible === 1}
                                            // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                            defaultSortOrder={senderFieldsDefs.sortOrder}
                                            defaultSortIndex={senderFieldsDefs.sortIndex}
                                            showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                            dataField={senderFieldsDefs.fieldName}
                                            allowEditing={senderFieldsDefs.readOnly != 1}
                                            caption={senderFieldsDefs.fieldCaption}
                                            dataType={senderFieldsDefs.fieldType}
                                            editorOptions={{
                                                acceptCustomValue: true,
                                                searchEnabled: true,
                                                customItemCreateEvent: "focusout",
                                                searchTimeout: 200,
                                                minSearchLength: 0,
                                                showDataBeforeSearch: false,
                                                opened: true,
                                                onCustomItemCreating: (args) => {
                                                    if (!args.text) {
                                                        args.customItem = null;
                                                        return;
                                                    }
                                                    else{
                                                        const newItem = {};

                                                        newItem[senderFieldsDefs.lookUpKeyField] = args.text
                                                        newItem[senderFieldsDefs.lookUpResultField] = args.text
                                                    
                                                        args.customItem = newDataSource.store().insert(newItem)
                                                        .then(() => newDataSource.load())
                                                        .then(() => newItem)
                                                        .catch((error) => {
                                                        throw error;
                                                        });
                                                    }
                                                }    
                                            }}
                                        >
                                            <Lookup
                                                // dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                dataSource={newDataSource.store()._array}
                                                valueExpr={senderFieldsDefs.lookUpKeyField}
                                                displayExpr={senderFieldsDefs.lookUpResultField}
                                                allowClearing={true}
                                                showClearButton={true}
                                            />

                                        </Column>
                                    )
                                }
                            }

                            else if (senderFieldsDefs.editorType === "dxLookup" ||
                                senderFieldsDefs.editorType === "dxSelectBox") {
                                if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup) {

                                    return (
                                        <Column
                                            key={senderIndiceFieldsDefs}
                                            fixed={senderFieldsDefs.fixed}
                                            visible={senderFieldsDefs.gridVisible === 1}
                                            // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                            defaultSortOrder={senderFieldsDefs.sortOrder}
                                            defaultSortIndex={senderFieldsDefs.sortIndex}
                                            showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                            dataField={senderFieldsDefs.fieldName}
                                            allowEditing={senderFieldsDefs.readOnly != 1}
                                            caption={senderFieldsDefs.fieldCaption}
                                            dataType={senderFieldsDefs.fieldType}
                                            filterType='include'
                                        // editCellRender={
                                        // format={senderFieldsDefs.fieldMask}
                                        //width={senderFieldsDefs.FieldSize}
                                        >
                                            <Lookup
                                                dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                valueExpr={senderFieldsDefs.lookUpKeyField}
                                                displayExpr={senderFieldsDefs.lookUpResultField}
                                                allowClearing={true}
                                                showClearButton={true}
                                            />

                                        </Column>
                                    )
                                }
                            }

                            else if (senderFieldsDefs.editorType === "upfLookupDetailEntity") {
                                if (senderFieldsDefs.lookUpKeyField != null && props.dataEntity) {
                                    return (
                                        <Column
                                            key={senderIndiceFieldsDefs}
                                            fixed={senderFieldsDefs.fixed}
                                            visible={senderFieldsDefs.gridVisible === 1}
                                            // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                            defaultSortOrder={senderFieldsDefs.sortOrder}
                                            defaultSortIndex={senderFieldsDefs.sortIndex}
                                            showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                            dataField={senderFieldsDefs.fieldName}
                                            allowEditing={senderFieldsDefs.readOnly != 1}
                                            caption={senderFieldsDefs.fieldCaption}
                                            dataType={senderFieldsDefs.fieldType}
                                        // editCellRender={
                                        // format={senderFieldsDefs.fieldMask}
                                        //width={senderFieldsDefs.FieldSize}
                                        >
                                            <Lookup
                                                dataSource={props.dataEntityDetailEntity.find(
                                                    element => element.entityName === senderFieldsDefs.lookUpListSource
                                                )?.records}
                                                valueExpr={senderFieldsDefs.lookUpKeyField}
                                                displayExpr={senderFieldsDefs.lookUpResultField}
                                                allowClearing={true}
                                                showClearButton={true}
                                            />

                                        </Column>
                                    )
                                }
                            }

                            else if (senderFieldsDefs.editorType === "upfLookupMasterEntity") {
                                if (senderFieldsDefs.lookUpKeyField != null && props.dataEntity) {

                                    // const resultQueryArrayLookupDataSourceEntityFieldsQuery = arrayLookupDataSourceEntityFieldsQuery
                                    // alert("upfLookupMasterEntity")
                                    // getEntityFieldsFunction()

                                    return (
                                        <Column
                                            key={senderIndiceFieldsDefs}
                                            fixed={senderFieldsDefs.fixed}
                                            visible={senderFieldsDefs.gridVisible === 1}
                                            // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                            defaultSortOrder={senderFieldsDefs.sortOrder}
                                            defaultSortIndex={senderFieldsDefs.sortIndex}
                                            showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                            dataField={senderFieldsDefs.fieldName}
                                            allowEditing={senderFieldsDefs.readOnly != 1}
                                            caption={senderFieldsDefs.fieldCaption}
                                            dataType={senderFieldsDefs.fieldType}
                                        // editCellRender={
                                        // format={senderFieldsDefs.fieldMask}
                                        //width={senderFieldsDefs.FieldSize}
                                        >
                                            <Lookup
                                                dataSource={arrayLookupDataSourceEntityFields}
                                                valueExpr={senderFieldsDefs.lookUpKeyField}
                                                displayExpr={senderFieldsDefs.lookUpResultField}
                                                allowClearing={true}
                                                showClearButton={true}
                                            />

                                        </Column>
                                    )
                                }
                            }

                            else if (senderFieldsDefs.editorType === "upfHtmlViewer" || senderFieldsDefs.editorType === "upfHtmlBox") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        groupIndex={senderFieldsDefs.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        dataType={senderFieldsDefs.fieldType} 
                                        customizeText={(cellInfo) => {
                                            if(cellInfo.value?.[0] === "<"){
                                                return cellInfo.value.replace(/(<([^>]+)>)/ig, "");

                                            }
                                            else{
                                                return cellInfo.value
                                            }
                                        }}
                                        cellRender={(cellData) => {
                                            return(
                                                <UpfHtmlEditor 
                                                    referencia={refDataGrid?.current?.instance}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                                    entityStructure={senderFieldsDefs}
                                                    colSpan={senderFieldsDefs.colSpan} 
                                                    caption={senderFieldsDefs.fieldCaption} 
                                                    dataField={senderFieldsDefs.fieldName}
                                                    // disabled={!modeEditDataGrid.edit} 
                                                    hint={senderFieldsDefs.hint}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    showEditBar={false}
                                                    cellData={cellData}
                                                />
                                            )}
                                        }
                                    />
                                )
                            }

                            else if (senderFieldsDefs.editorType === "upfAudio") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        groupIndex={senderFieldsDefs.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        dataType={senderFieldsDefs.fieldType} 
                                        cellRender={(cellData) => {
                                            return(
                                                <UpfAudio 
                                                    referencia={refDataGrid?.current?.instance}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    dataEntity={cellData.data?.[senderFieldsDefs.fieldName]}
                                                    entityStructure={senderFieldsDefs}
                                                    colSpan={senderFieldsDefs.colSpan} 
                                                    caption={senderFieldsDefs.fieldCaption} 
                                                    dataField={senderFieldsDefs.fieldName}
                                                    // disabled={!modeEditDataGrid.edit} 
                                                    hint={senderFieldsDefs.hint}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    dadosDaEntidadeDetalheState={props.dados}
                                                    // setDadosDaEntidadeDetalheState={setDadosDaEntidadeDetalheState}
                                                    // showEditBar={false}
                                                    cellData={cellData}
                                                />
                                            )}
                                        }
                                    />
                                )
                            }

                            else if (senderFieldsDefs.editorType === "upfFileSelect") {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        groupIndex={senderFieldsDefs.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        // allowEditing={senderFieldsDefs.readOnly != 1}
                                        allowEditing={false}
                                        caption={senderFieldsDefs.fieldCaption}
                                        dataType={senderFieldsDefs.fieldType}
                                        hint={"Selecionar arquivo"}
                                        disabled={true} 
                                        cellRender={(cellData) => {
                                            return(
                                                <UpfFileSelect
                                                    referencia={refDataGrid?.current?.instance}
                                                    cellData={cellData}
                                                    // setDadosDaEntidadeDetalheState={setDadosDaEntidadeDetalheState}
                                                    dadosDaEntidadeDetalheState={props.dados}
                                                    dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                                    entityStructure={senderFieldsDefs}
                                                    dataField={senderFieldsDefs.fieldName}
                                                    // disabled={!modeEditDataGrid.edit} 
                                                    entityName={props.entityNameDetail}
                                                />
                                            )}
                                        }
                                    />
                                )
                            }

                            else if(senderFieldsDefs.editorType === "upfPassword"){

                                return(
                                    <Column 
                                        hint={senderFieldsDefs.hint}
                                        helpText={senderFieldsDefs.hintDocked ? senderFieldsDefs.hint : null}
                                        // colSpan={colSpanFieldsFunction(senderFieldsDefs.colSpan, props.colCount)} 
                                        caption={senderFieldsDefs.fieldCaption} 
                                        dataField={senderFieldsDefs.fieldName} 
                                        dataSource={props.dataEntity}
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        groupIndex={senderFieldsDefs.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        dataType={senderFieldsDefs.fieldType} 
                                        editorOptions={{mode: "password"}}
                                        allowFiltering={false}
                                        cellRender={(cellData) => {
                                            return(
                                                <UpfPasswordComponent 
                                                    defaultValue={cellData.value}
                                                    // colSpan={colSpanFieldsFunction(senderFieldsDefs.colSpan, props.colCount)}
                                                    modeEditOn={props.modeEditOn}
                                                    entityStructure={senderFieldsDefs}
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    // dataEntity={props.dataEntity}
                                                    modeEditForm={props.modeEditForm}
                                                    isGrid={true}
                                                    referencia={refDataGrid?.current?.instance}
                                                    dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                                    colSpan={senderFieldsDefs.colSpan} 
                                                    caption={senderFieldsDefs.fieldCaption} 
                                                    dataField={senderFieldsDefs.fieldName}
                                                    // disabled={!modeEditDataGrid.edit} 
                                                    hint={senderFieldsDefs.hint}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    showEditBar={false}
                                                    cellData={cellData}
                                                />
                                            )}}
                                    >
                                        
                                    
                                    </Column>
                                )
                            }

                            else if (senderFieldsDefs.fieldType === "money") {
                                return (
                                    <Column
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        showInColumnChooser={senderFieldsDefs.visibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        sortOrder={senderFieldsDefs.sortOrder}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // dataType={senderFieldsDefs.fieldType}
                                        format={{
                                            style: 'currency',
                                            currency: 'BRL',
                                            precision: 2,
                                        }}
                                    />
                                );
                            }

                            else {
                                return (
                                    <Column
                                        key={senderIndiceFieldsDefs}
                                        fixed={senderFieldsDefs.fixed}
                                        visible={senderFieldsDefs.gridVisible === 1}
                                        // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                        defaultSortOrder={senderFieldsDefs.sortOrder}
                                        defaultSortIndex={senderFieldsDefs.sortIndex}
                                        showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                        dataField={senderFieldsDefs.fieldName}
                                        allowEditing={senderFieldsDefs.readOnly != 1}
                                        caption={senderFieldsDefs.fieldCaption}
                                        // format={formatFunction(senderFieldsDefs.fieldMask,  "column", senderFieldsDefs.fieldName, dadosDaEntidadeDetalheState.records, modeEditDataGrid?.edit)}
                                    // dataType={"datetime"}
                                    // editorOptions={editorOptionsColumns}

                                    // format={senderFieldsDefs.fieldMask}
                                    //width={senderFieldsDefs.FieldSize}
                                    >
                                        {/* <Lookup
                                                                dataSource ={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                                valueExpr={senderFieldsDefs.lookUpKeyField}
                                                                displayExpr ={senderFieldsDefs.lookUpResultField}
                                                            /> */}

                                    </Column>
                                )
                            }
                        }
                        )}
                        <MasterDetail 
                            enabled={true} 
                            render={renderDataGridLog} 
                            autoExpandAll={true}
                            // key={"_objectid"}
                            // keyFn={"id"}
                        />
                        <Summary calculateCustomSummary={calculateCustomSummary}>
                            <TotalItem
                                column={refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}
                                summaryType="count"
                                customizeText={(data) => `Qtd: ${data.value}`}
                            />
                            {/* <TotalItem
                                name={`{"tipo": "count", "columnName": "${refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}"}`}
                                summaryType="custom"
                                displayFormat="Sel.: {0}"
                                showInColumn={refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}
                            /> */}

                            {props.estrutura?.summary &&
                                props.estrutura?.summary.map((item, indice) => {
                                    if (item.fieldType === "money") {
                                        return (
                                            <TotalItem
                                                column={item.fieldName}
                                                summaryType={item.summarytype}
                                                fieldType={item.fieldType}
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                rowRenderingMode="standard"
                                                format={{
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                    precision: 2,
                                                }}
                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                            />
                                        )
                                    }
                                    else {
                                        return (
                                            <TotalItem
                                                column={item.fieldName}
                                                summaryType={item.summarytype}
                                                fieldType={item.fieldType}
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                rowRenderingMode="standard"
                                            />
                                        )
                                    }
                                })
                            }

                            {/* {props.estrutura?.summary &&
                                props.estrutura?.summary.map((item, indice) => {
                                    if (item.fieldType === "money") {
                                        return (
                                            <TotalItem
                                                name={`{"tipo": "value", "columnName": "${item.fieldName}"}`}
                                                summaryType="custom"
                                                valueFormat={{
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                    precision: 2,
                                                }}
                                                displayFormat="Sel.: {0}"
                                                showInColumn={item.fieldName}
                                            />
                                        )
                                    }
                                })
                            } */}

                            {props.estrutura?.summary &&
                                props.estrutura?.summary.map((item, indice) => {
                                    if (item.fieldType === "money") {
                                        return (
                                            <GroupItem
                                                column={item.fieldName}
                                                summaryType={item.summarytype}
                                                fieldType={item.fieldType}
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                rowRenderingMode="standard"
                                                format={{
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                    precision: 2,
                                                }}
                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                            />
                                        )
                                    }
                                    else {
                                        return (
                                            <GroupItem
                                                column={item.fieldName}
                                                summaryType={item.summarytype}
                                                fieldType={item.fieldType}
                                                showInGroupFooter={false}
                                                alignByColumn={true}
                                                rowRenderingMode="standard"
                                            />
                                        )
                                    }
                                })
                            }
                            
                        </Summary>
                    </DataGrid>
                )
                :
                (
                    <DataGridLog dataSourceState={dataSourceState}/>
                )
            }
        </>
    )
}

export default LogCustomForm