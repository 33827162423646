// import { Container } from './styles';
import { useCallback, memo } from 'react';
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useTabPanelItems } from '../../../Context/TabPanelItems';
import { TabPanel } from 'devextreme-react/tab-panel';
import { Button } from 'devextreme-react/button';
import Toolbar, {Item} from 'devextreme-react/toolbar';
import  { v4 } from 'uuid';
import "./style.css"

const TabPanelMain = () => {
    const {tabPanelItems, setTabPanelItems} = useTabPanelItems();
    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain();
    const closeButtonHandler = useCallback((item) => {
        const Handle = [...tabPanelItems];
        const index = Handle.indexOf(item);
    
        Handle.splice(index, 1);
        setTabPanelItems(Handle);

        if(tabPanelItems.length !== 0){
            setIndexTabPanelMain(tabPanelItems.length -2)
        }
      },[tabPanelItems])

    const titleTabPanel = useCallback((item) => {
        const closeHandler = () => {
            closeButtonHandler(item);
        }
        //o condicional está sendo feito pelo icone home, se outra tab 
        //usar deve ser alterado o condicional
        if(item.icon === "home") {
            return (
                <div className="tab-index">
                    <i
                        className={`dx-icon icon-tabtreeview dx-icon-${item.icon}`} 
                    />
                    <span className="span">
                        {item.title}
                    </span>
                </div>
            )
        }
        else {
            return (
                <div className="tab-index">
                    <i
                        className={`dx-icon icon-tabtreeview dx-icon-${item.icon}`} 
                    />
                    <span className="span-text">
                        {item.title}
                    </span>
                        <i 
                            className="dx-icon  icon-close dx-icon-close" 
                            onClick={closeHandler}
                        />
                </div>
            )
        }
    },[closeButtonHandler])

    const contentTab = useCallback((sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    },[])
    const optionChange = useCallback((sender)=>{
        if(sender.name === "selectedIndex"){
            setIndexTabPanelMain(sender.value)
        }
    }, [setIndexTabPanelMain])

    const hestiaOptions = {
        text: "Hestia"
        ,icon: "menu"
        ,hoverStateEnabled: true
      }

    // const openMenuContextFunction = (e) =>{
    //     console.log(e)
    //     // console.log("botao direito do mouse")
    // }
    
    return(
            <div className="tabpanel-main">
                <TabPanel
                    id="tabpanel-main"
                    items={tabPanelItems} 
                    deferRendering={false}
                    itemRender={contentTab}
                    selectedIndex={indexTabPanelMain}
                    onTitleRendered={(item) => {
                        setIndexTabPanelMain(tabPanelItems.length)
                        }
                    }
                    onSelectionChanged={(item) => {
                        setIndexTabPanelMain(tabPanelItems.indexOf(item.addedItems[0]))
                    }}
                    repaintChangesOnly={true}
                    animationEnabled={false}
                    showNavButtons={true}
                    scrollByContent={true}
                    itemTitleRender={(sender) => titleTabPanel(sender)}
                    onOptionChanged={optionChange}
                    // onItemContextMenu={
                    //         {
                    //             "text": "Fechar todas as abas",
                    //             "icon": "clear",
                    //             "onItemClick": ()=>{
                    //                 if(tabPanelItems.length > 1) {
                    //                   alert("Todas as abar serão fechadas! Deseja continuar?")
    
                    //                 //   setMessage("Todas as abar serão fechadas! Deseja continuar?")
                    //                 //   setVisible(true)
                    //                 }
                    //               }
                    //         } 
                    // }
                    // onTitleClick={openMenuContextFunction}
                />
            </div>
    );
}

export default memo(TabPanelMain);