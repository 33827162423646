import { httpRequest } from "../.."
import { applicationID, equipeId, relationId, ramal} from "../../../api/ApplicationID"

export const abrirFichaDiscador = (
    mensagem
    ,states
    ,setStates
    ,requestsApi
    ,carregarTelecobrancaForm
    ,setWidgetsTeleCob
    ,setGravouOcorrencia
    ,setDiscadorEmAtendimentoGlobalState
    ,backendJson
    ,setTabPanelItems
    ,tabPanelItems
    ,resolve
    //,chamouDepoisDeErro
    ) => {
    states.tabulacaoAcionamentoJson[0]["tituloId"] = mensagem.identificador

    //chamar o getDataTelecobranca para pegar o credorId
    if(mensagem.identificador.length > 0){
        // if(chamouDepoisDeErro){
        //     console.log("abrir ficha 2 tentativa")
        // }
        httpRequest("POST", requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
            applicationId: applicationID,
            tituloId: mensagem.identificador
        })
        .then((sender) => {
            const resultGetDataTelecobrancaJson = JSON.parse(sender)

            let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            const horarioInicio = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

            const _qtdoutroscontratoscliente = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._qtdoutroscontratoscliente
            const _qtdoutroscontratosclientecredoratual = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._qtdoutroscontratosclientecredoratual
            const clienteId = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._clienteid;
            const credorId = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid;


            const dataSourceFichaAtualMessage = {
                ...mensagem,
                _qtdoutroscontratoscliente,
                _qtdoutroscontratosclientecredoratual,
                _clienteId: clienteId,
                credorId 
            }

            sessionStorage.setItem("dataSourceFichaAtual", JSON.stringify(dataSourceFichaAtualMessage))
            sessionStorage.setItem("horarioInicio", horarioInicio.toLocaleString())

            //* resolve WebSocketFunction promise
            resolve();

            httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
                applicationId: applicationID,
                equipeId: equipeId,
                credorId: resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid
            })
            .then((sender) => {
                const getWidgetsResult = JSON.parse(sender)
                setWidgetsTeleCob(getWidgetsResult)
            })
            .catch((err) => {
                console.log(err)
                setStates({...states, 
                    isVisiblePopupErrorAbrirFicha: true,
                    messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                })
                // if(!chamouDepoisDeErro){
                //     abrirFichaDiscador(
                //         mensagem
                //         ,states
                //         ,setStates
                //         ,requestsApi
                //         ,carregarTelecobrancaForm
                //         ,setWidgetsTeleCob
                //         ,setGravouOcorrencia
                //         ,setDiscadorEmAtendimentoGlobalState
                //         ,backendJson
                //         ,setTabPanelItems
                //         ,tabPanelItems
                //         ,true
                //     )
                // }
            })
            
            setGravouOcorrencia(false)
            carregarTelecobrancaForm(resultGetDataTelecobrancaJson, mensagem.identificador, "abrirFichaDiscador")
            // if(discadorEmAtendimentoGlobalState){
            //     console.log("abrir pausa atendimento discador")
                //pausa atendimento discador
                // httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, {
                //     applicationId: applicationID,
                //     relationId: relationId,
                //     tipoPausaId: "06B6D084A2E94188A06376BF6D9517BF",
                //     tipoPausaCodigo: 0,
                //     ramal: ramal,
                //     host: backendJson.api_host,
                //     porta: backendJson.api_port  
                // })
                // .then((sender) => {
                //     let resultSolicitarPausa = JSON.parse(sender)
                // })
                // .catch((err) => {console.log(err)})
            // } 
        })
        .catch((err) => {
            setStates({...states, 
                isVisiblePopupErrorAbrirFicha: true,
                messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
            })
            // if(!chamouDepoisDeErro){
            //     abrirFichaDiscador(
            //         mensagem
            //         ,states
            //         ,setStates
            //         ,requestsApi
            //         ,carregarTelecobrancaForm
            //         ,setWidgetsTeleCob
            //         ,setGravouOcorrencia
            //         ,setDiscadorEmAtendimentoGlobalState
            //         ,backendJson
            //         ,setTabPanelItems
            //         ,tabPanelItems
            //         ,true
            //     )
            // }
        })
    }
}