export const getTokenLogin = (
    mensagemJson
    ,setPageVisible
    ,setReturnMessageUndefinedState
    ) => {

    if(mensagemJson?.token){
        sessionStorage.setItem("revolution-token", mensagemJson.token)
        setPageVisible(true)
    }
    else if(mensagemJson?.retorno){
        if(!sessionStorage.getItem("revolution-token")){
            setReturnMessageUndefinedState(mensagemJson.retorno)
        }
        else{
            console.log(mensagemJson.retorno)
        }
    }
    else{
        setReturnMessageUndefinedState("Ocorreu um erro! Tente novamente mais tarde.")
    }
   
}