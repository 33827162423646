import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import UpfPopupForm from "../UpfPopupForm";
import {useRef, useState, useEffect} from 'react';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import { useRequestsApi } from "../../Context/RequestsApi";
import { httpRequest } from '../../functions'
import WelcomeTab from "../../Components/DrawerMain/TabPanelMain/WelcomeTab"
import {applicationID} from "../../api/ApplicationID"
import { Toast } from 'devextreme-react/toast';
import $ from "jquery";
import { CommonSeriesSettingsHoverStyle } from 'devextreme-react/range-selector';

const UpfPopup = (props) => {
    //GERAL
    const closePopup = () => {
        props.popupVisibleFunctionChangeState(false)
        if(props.toolbarRef && props.clearTabRuleState !== 'clearAllTabs'){
            props.toolbarRef.current.instance.repaint()
        }
    }

    const cancelButtonOptions = {
        text: "Cancelar"
        ,icon: "close"
        ,onClick: closePopup
    };

    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain()
    const {tabPanelItems, setTabPanelItems} = useTabPanelItems();
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [states, setStates] = useState({
        toastVisible: false,
        toastType: "info",
        toastMessage: ""
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    //POPUP MENSAGEM
    const closeButtonPopup = {
        text: 'ok'
        ,icon: 'arrowright'
        ,onClick: closePopup
    };

    const _popupMessageRef = useRef(null)
    const _popupCloseTabsRef = useRef(null)
    const _popupPausaRef = useRef(null)

    //----------------------------

    //POPUP FECHAR ABAS

    //função para limpar abas
    const clearTabsFunction = (rule) => {
        //fechar todas as tabs
        if(rule === "clearAllTabs"){
            setTabPanelItems([
            { ...tabPanelItems,
                icon: "home" 
                ,badge: ""
                ,component: <WelcomeTab/>
                ,text: Math.random()
            }
            ])
            closePopup()
        }

        //fecha todas as abas a direita
        else if(rule === "clearTabsRight" && indexTabPanelMain < tabPanelItems.length - 1){
            setTabPanelItems(tabPanelItems.splice(0,indexTabPanelMain + 1))
            setIndexTabPanelMain(indexTabPanelMain)
            closePopup()
        }

        //fecha todas as abas menos a atual
        else if(rule === "keepThisTab"){
            setTabPanelItems([
            { ...tabPanelItems,
                icon: "home" 
                ,badge: ""
                ,component: <WelcomeTab/>
                ,text: Math.random()
            },
            tabPanelItems[indexTabPanelMain]
            ])
            setIndexTabPanelMain(0)
            setIndexTabPanelMain(1)
            closePopup()
        }
    }

    const clearTabsButton = {
        text: "Ok"
        // ,icon: 'arrowright'
        ,onClick: () => {
            clearTabsFunction(props.clearTabRuleState)
        }
    };

    //----------------------------

    const [intervaloTimer, setIntervaloTimer] = useState()
    const [timerCount, setTimerCount] = useState(null)
    
    
    
    const [timerState, setTimerState]  = useState({
        segundos: 0,
        minutos: 0,
        horas: 0
      })

    //estado especificop do cronometro
    const [cronometroTimeState, setCronometroTimeState] = useState({
        segundos: 0,
        minutos: 0,
        horas: 0
      })
    const [pararCronometro, setPararCronometro] = useState(false)
    
    let segundos = 0
    let minutos = 0
    let horas = 0
    let stop = false

    const zerarCronometro = () => {
        clearInterval(pararCronometro)
        stop = true
        segundos = 0
        minutos = 0
        horas = 0
        setCronometroTimeState({
            segundos: segundos,
            minutos: minutos,
            horas: horas
        })

        
        closePopup()
    }
    
   let dataInicial = null
   let dataAtual = null
  
   const cronometroTimerFunction = () => {
        if(!stop){
            dataAtual = new Date()

            let resultadoSegundos = parseInt((dataAtual.getTime() - dataInicial.getTime()) / 1000)

            horas = parseInt(resultadoSegundos / 3600);
            segundos = resultadoSegundos % 3600;

            minutos = parseInt(segundos / 60);
            segundos = parseInt(segundos % 60);

            setCronometroTimeState({
                segundos: segundos,
                minutos: minutos,
                horas: horas
            })
        }
   }


   const contagemTimerFunction = () => {
    let dataInicialComDuração = dataInicial.getTime() + props.timer * 60 * 1000

    dataAtual = new Date()

    let timer = parseInt((dataInicialComDuração - dataAtual.getTime()) / 1000)

    if(timer >= 0){
        horas = parseInt(timer / 3600);
        segundos = timer % 3600;

        minutos = parseInt(segundos / 60);
        segundos = parseInt(segundos % 60);
        setTimerState({
            segundos: segundos,
            minutos: minutos,
            horas: horas
        })
        setTimerCount(timer)
    } 
    else{
        clearInterval(intervaloTimer)
    }
   }

   const startCronometro = () => {
       dataInicial = new Date()
    setPararCronometro(setInterval(() => {
            cronometroTimerFunction()
        }, 1000))
    }

    const startContagemTimer = () => {
        dataInicial = new Date()
        setIntervaloTimer(
            setInterval(() => {
                contagemTimerFunction()
            }, 1000)
        )
    }

    useEffect(() => {
        if(props.popupVisibleState && props.typePopup === "pausa") {
            if(props.timer !== 0){
                startContagemTimer()
            }
            else if(props.timer === 0){
                setTimerCount(0)//condição do disabled false do botão voltar ao trabalho 
                startCronometro()
            }
        }
    }, [props.popupVisibleState])

    
    
    const voltarAoTrabalhoButtonPopup = {
        text: 'Retornar ao trabalho'
        ,icon: 'runner'
        ,disabled: timerCount === 0 ? false : true
        ,onClick: () => {
            httpRequest('POST', requestsApi.fecharPausa, requestsApi.basicAuth, {     
                "pausaId": props.pausaId,
                "applicationId": applicationID
            })
            .then((sender) => {
                const fecharPausaResultJson = JSON.parse(sender)
                toastConfiguration(true, "success", fecharPausaResultJson.mensagem)
                if(props.pausaEscolhidaDadosGlobalState){
                    props.setPausaEscolhidaDadosGlobalState(false)
                }
                zerarCronometro()
                props.popupVisibleFunctionChangeState(false)
            })
            .catch((error) => {
                toastConfiguration(true, "error", "Ocorreu um erro!")
            })
        }
    };

    return(
        <>
            {props.typePopup === "message" &&
                (
                    <Popup
                        ref={_popupMessageRef}
                        visible={props.popupVisibleState}
                        onHiding={closePopup}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={true}
                        showTitle={true}
                        title="Atenção"
                        width={600}
                        height={280}
                        resizeEnabled={true}
                        onShown={(e) => {
                            setTimeout(() => {
                                _popupMessageRef.current.instance.content().parentElement.addEventListener('keypress', (e) => {
                                            if (e.key === 'Enter') {
                                                if(_popupMessageRef.current){
                                                    _popupMessageRef.current.instance.content().parentElement.getElementsByClassName("dx-button-content")[0].click()
                                                }
                                            }
                                        })
                                }, 0)
                            }
                        }
                        >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={closeButtonPopup}
                        />
                        {props.message ?
                            (
                                <p>{props.message}</p>
                            )
                            :
                            (
                                <b>Ocorreu um erro! Tente novamente mais tarde!</b>
                            )
                        }
                    </Popup>
                )
            }

            {props.typePopup === "pausa" &&
                (
                    <Popup
                        ref={_popupPausaRef}
                        visible={props.popupVisibleState}
                        // onHiding={closePopup}
                        dragEnabled={false}
                        hideOnOutsideClick={false}
                        showCloseButton={false}
                        showTitle={true}
                        title={props.title}
                        width={600}
                        height={330}
                        resizeEnabled={true}
                        >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={voltarAoTrabalhoButtonPopup}
                        />
                        <div style={{textAlign: 'center'}}>
                            <div style={{fontSize: '100px'}}>
                                {props.timer === 0 ? 
                                    (
                                        <div>
                                            <span>{("00" + cronometroTimeState.horas).slice(-2)}</span>:<span>{("00" + cronometroTimeState.minutos).slice(-2)}</span>:<span>{("00" + cronometroTimeState.segundos).slice(-2)}</span>
                                        </div>
                                    ) 
                                    :
                                    (
                                        <div>
                                            <span>{("00" + timerState.horas).slice(-2)}</span>:<span>{("00" + timerState.minutos).slice(-2)}</span>:<span>{("00" + timerState.segundos).slice(-2)}</span>
                                        </div>
                                    )
                                }
                                <Toast
                                    visible={states.toastVisible}
                                    message={states.toastMessage}
                                    type={states.toastType}
                                    onHiding={onHidingToast}
                                    displayTime={1800}
                                    height={60}
                                />
                            </div>
                        </div>
                    </Popup>
                )
            }

            {props.typePopup === "templateHtml" &&
                (
                    <UpfPopupForm/>
                )
            }

            {props.typePopup === "closeTabs" &&
                (
                    <Popup
                        ref={_popupCloseTabsRef}
                        visible={props.popupVisibleState}
                        onHiding={closePopup}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={true}
                        showTitle={true}
                        title="Atenção"
                        width={600}
                        height={280}
                        resizeEnabled={true}
                        onShown={(e) => {
                            setTimeout(() => {
                                _popupCloseTabsRef.current.instance.content().parentElement.addEventListener('keypress', (e) => {
                                            if (e.key === 'Enter') {
                                                if(_popupCloseTabsRef.current){
                                                    _popupCloseTabsRef.current.instance.content().parentElement.getElementsByClassName("dx-button-content")[1].click()
                                                }
                                            }
                                        })
                                }, 0)
                            }
                        }
                    >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={clearTabsButton}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={cancelButtonOptions}
                        />
                        {props.message &&
                            <p>{props.message}</p>
                        }
                    </Popup>
                )
            }

            {/* {props.typePopup === "changeTheme" &&
                (
                    
                )
            } */}

        </>
    )
}

export default UpfPopup;