import AttachRecordForm from "../AttachRecordForm"
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { useState }from 'react'
import { applicationID } from "../../../api/ApplicationID"

const AttachRecordFormPopup = (props) => {

    const [configRecordFormPopup, setConfigRecordFormPopup] = useState({
        fullScreen: true,
        dragEnabled: false,
        icon: "unselectall"
    })

    const [selectedProcess, setSelectedProcess] = useState()


    return (
        <Popup
            visible={props.popupState}
            onHiding={() => {
                props.setPopupState(false)
            }}
            dragEnabled={configRecordFormPopup.dragEnabled}
            fullScreen={configRecordFormPopup.fullScreen}
            hideOnOutsideClick={false}
            animation={null}
            toolbarItems={
                [
                    {
                        widget: "dxButton",
                        location: "after",
                        options: {
                            icon: configRecordFormPopup.icon,
                            onClick: () => {
                                if (configRecordFormPopup.icon === "square") {
                                    setConfigRecordFormPopup(
                                        {
                                            fullScreen: true,
                                            dragEnabled: false,
                                            icon: "unselectall"
                                        }
                                    )
                                } else {
                                    setConfigRecordFormPopup(
                                        {
                                            fullScreen: false,
                                            dragEnabled: true,
                                            icon: "square"
                                        }
                                    )
                                }
                            }
                        }
                    }
                ]}
            showCloseButton={true}
            showTitle={true}
            title={props.caption}
            id="popupForm"
        >
            <ScrollView width='100%' height='100%' useNative={true}>
                <AttachRecordForm
                    dataEntity={props.dataEntity}
                    id={Math.random() + 1}
                    idValue={props.idValue}
                    entityName={props.entityName}
                    toastConfiguration={props.toastConfiguration}
                    dataLookup={props.dataLookup}
                    entityStructure={props.entityStructure}
                    isLoading={props.isLoading}
                    setIsLoading={props.setIsLoading}
                />

            </ScrollView>
        </Popup>
    )
}

export default AttachRecordFormPopup;