import {useState, useEffect} from 'react';
import './style.css'


const UpfPasswordComponent = (props) => {
    const [valueState, setValueState] = useState()

    useEffect(() => {
        console.log(props)
        if(props.isGrid){
            if(valueState === undefined && props.defaultValue){
                setValueState(props.defaultValue)
            }
            // else{
            //     props.dataEntity[`${props.entityStructure.fieldName}`] = valueState
            // }
        }
        else if(props.entityStructure["fieldName"]){
            if(valueState === undefined && props.defaultValue){
                props.dataEntity[`${props.entityStructure.fieldName}`] = props.defaultValue
                setValueState(props.defaultValue)
            }
            else{
                props.dataEntity[`${props.entityStructure.fieldName}`] = valueState
            }
        }
    }, [valueState])

    return(
        <div style={{
            "display": "flex",
            "flexDirection": "row"
            }}>
                {/* <label className='label-field dx-texteditor-input-container'> */}
                
            <div 
                className="dx-texteditor-input-container" 
            >
                {/* <span className='span-field'>{props.caption}</span> */}

                <input
                    className='dx-texteditor-input crypt-field'
                    style={{border: "solid 1px rgba(250, 250, 250, 0.15)"}}
                    // style={{color: "transparent", caretColor: "white"}}
                    // name="password"
                    // className="input-form"
                    // placeholder="Senha"
                    // readonly 
                    // onfocus="this.removeAttribute('readonly');"
                    type="text"
                    value={valueState}
                    onChange={(e) => {
                        const objAtual = props.dataEntityEditedFields ? props.dataEntityEditedFields : {}

                        if(e.target.value){                           
                            setValueState(e.target.value)
                            objAtual[`${props.entityStructure.fieldName}`] = e.target.value
                            // setValueStateSecret(convertTeste(e.target.value)) 
                        }
                        else{
                            setValueState(null)
                            objAtual[`${props.entityStructure.fieldName}`] = null
                            // setValueStateSecret(null) 
                        }
                        props.setDataEntityEditedFields(objAtual)
                        if(!props.modeEditForm){
                            props.modeEditOn()
                        }
                    }}

                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave={false}
                ></input>
                {/* <div
                    style={{position: "absolute", bottom: "5.5px", left: "8px"}}
                >
                    {valueStateSecret}
                </div> */}
            </div>
            {/* </label> */}


        {/* <Button
            style={{"marginLeft": "0.5em"}}
            hint={modeState === 'password' ? `Mostrar` : `Ocultar`}
            icon={modeState === 'password' ? `${eyeOff}` : `${eye}`}
            onClick={() => {
                if(modeState === 'password'){
                    setModeState('text')
                }
                else{
                    setModeState('password')
                }
                }}
        /> */}
        
        {/* <div style={{
            "display": "flex",
            "flexDirection": "row",
            "alignItems": "center"
            }}
        > */}
            {/* <div style={{
                "marginRight dx-texteditor-container": "1em"
            }}
        >
            Mostrar senha
        </div>
            <CheckBox
                onValueChanged={(item) => {
                    if(item.value){
                        setModeState('text')
                    }
                    else{
                        setModeState('password')
                    }
                }}
            />
        </div> */}
        </div>
    )
}
export default UpfPasswordComponent;