import {useState, useEffect, useRef, useCallback} from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import ScrollView from 'devextreme-react/scroll-view';
import Loading from "../../../CardComponents/Loading";
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID, relationId } from '../../../api/ApplicationID';
import { Toast } from 'devextreme-react/toast';
import HtmlFormPopup from "../../HtmlFormPopup";
import UpfPopupMessage from "../../../CardComponents/UpfPopupMessage"
import { fecharFormAtual } from "../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';

const Passo3 = (props) => {

    console.log(props)

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [liberarConclusaoState, setLiberarConclusaoState] = useState(false)
    const [liberarHistoricoState, setLiberarHistoricoState] = useState(true)
    const [liberarConclusaoNegociacaoState, setLiberarConclusaoNegociacaoState] = useState(true)

    const[codTipoOcorrenciaState, setCodTipoOcorrenciaState] = useState()

    const hideLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    console.log(props)

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const [gerarAcordoDisabledState, setGerarAcordoDisabledState] = useState(false)


     //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: "",
        onHidden: false
        })

    //Funções
    const onHidingToast = useCallback(() => {
        setToastConfig({
        ...toastConfig,
        isVisible: false
        });
    },[toastConfig])

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
          ...toastConfig,
          isVisible: visible,
          type: type,
          message: message
        });
      },[toastConfig])
//---------TOAST----------//

    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [gerouAcordo, setGerouAcordo] = useState(false)

    const [observacaoSolucaoState, setObservacaoSolucaoState] = useState("")
    const [observacaoSolucaoNegociacaoState, setObservacaoSolucaoNegociacaoState] = useState(`Valor R$ ${props.totalCobrancaState?.toFixed(2)} ${props?.descontoTotalCobrancaState ? ("desc " + props.descontoTotalCobrancaState + "%") : ("")} referente aos títulos ${props.arrayPendenciasProcessadasState}.`)

    const [states, setStates] = useState({
        selectedItem: null,
        dateBoxState: null
    })

    const [dateBoxState, setDateBoxState] = useState(null)
    const [selectedItem, setSelectedItem] = useState()

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState()

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };
    //---------------------------

    const [solucaoSelecionada, setSolucaoSelecionada] = useState()

    return(
        <div style={{height: "100%", padding: "0.5em"}}>
            {/* <ScrollView> */}
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />
            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Popup
                visible={visible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {message &&
                    <p>{message}</p>
                }
            </Popup>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
                // onHidden={() => {
                //     if(toastConfig.onHidden){
                //         // fecharFormAtual(tabPanelItems, indexTabPanelMain, setIndexTabPanelMain, props.hideUpfPopupForm)
                //         setToastConfig({...toastConfig, 
                //             onHidden: false
                //         })
                //     }
                // }}
            />

                {props.gerarAcordoDataSource?.tipoOcorrencia == 1 &&
                    <Form
                        colCount={4}
                    >
                        <GroupItem
                            cssClass={'group-item'}
                            colSpan={2}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                editorType="dxSelectBox"
                                caption="Solução"
                                colSpan={3}
                                // disabled={!liberarConclusaoState}
                                editorOptions={{
                                    items: props.listaSolucaoState,
                                    valueExpr: "cd_solucao",
                                    displayExpr: "ds_solucao",
                                    onSelectionChanged: (e) => {
                                        if(e.selectedItem){
                                            setSolucaoSelecionada(e.selectedItem)
                                        }
                                    }
                                }}
                            >
                                <Label 
                                    text="Solução"
                                />
                            </SimpleItem>

                            <ButtonItem
                                colSpan={1}
                                // disabled={!liberarConclusaoState}
                                buttonOptions={{
                                    text: "Conclusão",
                                    //adicionarConclusao
                                    onClick: () => {
                                        if(solucaoSelecionada){
                                            setLiberarConclusaoState(false)
                                            showLoadPanel()
                                            httpRequest('POST', requestsApi.adicionarConclusao, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                tituloId: props.tituloId,
                                                codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao,
                                                codSolucao: solucaoSelecionada?.cd_solucao,
                                                codTipoOcorrencia: props.gerarAcordoDataSource.tipoOcorrencia,
                                                obs: observacaoSolucaoState
                                            })
                                            .then((sender) => {
                                                console.log(sender)
                                                toastConfiguration(true, "success" , "Conclusão adicionada!")
                                                setLiberarConclusaoState(true)
                                                hideLoadPanel()
                                            })
                                            .catch((error) => {
                                                setLiberarConclusaoState(true)

                                                console.log(error)
                                                if(error?.data?.error){
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                }
                                                else{
                                                    setMessage("Falha ao adicionar conclusão")
                                                    setVisible(true)
                                                }
                                                hideLoadPanel()
                                            })
                                        }
                                    }

                                }}
                            />

                            {/* <SimpleItem
                                editorType="dxLookup"
                                caption="Causa"
                                colSpan={4}
                                editorOptions={{
                                    items: props.listaOcorrenciaState,
                                    valueExpr: "cd_tipo_ocorrencia",
                                    displayExpr: "nm_ocorrencia",
                                    onSelectionChanged: (e) => {
                                        setCodTipoOcorrenciaState(e?.selectedItem?.cd_tipo_ocorrencia)
                                    },
                                }}
                            >
                                <Label 
                                    text="Causa"
                                />
                            </SimpleItem> */}

                            <SimpleItem
                                editorType="dxTextArea"
                                caption="Observação"
                                colSpan={4}
                                editorOptions={{
                                    value: observacaoSolucaoState,
                                    onValueChanged: (e) => {
                                        if(e.value){
                                            setObservacaoSolucaoState(e.value)
                                        }
                                    }
                                }}
                            >
                                <Label 
                                    text="Observação"
                                />
                            </SimpleItem>

                        </GroupItem>

                        <GroupItem
                            cssClass={'group-item'}
                            colSpan={2}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                editorType="dxSelectBox"
                                caption="Fato"
                                colSpan={4}
                                editorOptions={{
                                    items: [{id: 1, descricao: "Dificuldade Financeira"}],
                                    valueExpr: "id",
                                    displayExpr: "descricao",
                                    value: 1
                                }}
                            >
                                <Label 
                                    text="Fato"
                                />
                            </SimpleItem>

                            <SimpleItem
                                editorType="dxTextArea"
                                caption="Observação"
                                colSpan={4}
                                editorOptions={{
                                    value: props.arrayPendenciasProcessadasState
                                }}
                            >
                                <Label 
                                    text="Observação"
                                />
                            </SimpleItem>

                            
                            <ButtonItem
                                colSpan={1}
                                // disabled={!liberarHistoricoState}
                                buttonOptions={{
                                    text: "Adicionar Histórico",
                                    //adicionarHistorico
                                    onClick: () => {
                                        setLiberarHistoricoState(false)
                                        showLoadPanel()
                                        httpRequest('POST', requestsApi.adicionarHistorico, requestsApi.basicAuth, {
                                            applicationId: applicationID,
                                            tituloId: props.tituloId,
                                            codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao,
                                            obs: props.arrayPendenciasProcessadasState
                                        })
                                        .then((sender) => {
                                            toastConfiguration(true, "success" , "Item adicionado ao histórico!")
                                            console.log(sender)

                                            httpRequest('POST', requestsApi.getListarSolucao, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                tituloId: props.tituloId,
                                                codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao
                                            })
                                            .then((sender) => {
                                                //SALVAR DADOS DA LOOKUP DE SOLUÇÃO
                                                const resultGetListarSolucaoJson = JSON.parse(sender)
                                                props.setListaSolucaoState(resultGetListarSolucaoJson)
                                                setLiberarHistoricoState(true)
                                                setLiberarConclusaoState(true)

                                                hideLoadPanel()

                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                if(error?.data?.error){
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                }
                                                else{
                                                    setMessage("Falha ao trazer lista de soluções")
                                                    setVisible(true)
                                                }
                                                // hideLoadPanel()
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            if(error?.data?.error){
                                                setMessage(error.data.error)
                                                setVisible(true)
                                            }
                                            else{
                                                setMessage("Falha ao adicionar histórico")
                                                setVisible(true)
                                            }
                                            hideLoadPanel()
                                        })
                                    }
                                }}
                            />
                        </GroupItem>
                    </Form>
                }

                {props.gerarAcordoDataSource?.tipoOcorrencia == 2 &&
                
                    <Form
                        colCount={4}
                    >
                        <GroupItem
                            cssClass={'group-item'}
                            colSpan={2}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                editorType="dxSelectBox"
                                caption="Solução"
                                colSpan={3}
                                editorOptions={{
                                    items: props.listaSolucaoState,
                                    valueExpr: "cd_solucao",
                                    displayExpr: "ds_solucao",
                                    onSelectionChanged: (e) => {
                                        if(e.selectedItem){
                                            setSolucaoSelecionada(e.selectedItem)
                                        }
                                    }
                                }}
                            >
                                <Label 
                                    text="Solução"
                                />
                            </SimpleItem>

                            <ButtonItem
                                colSpan={1}
                                // disabled={!liberarConclusaoNegociacaoState}
                                buttonOptions={{
                                    text: "Conclusão",
                                    onClick: () => {
                                        if(solucaoSelecionada){
                                            showLoadPanel()
                                            setLiberarConclusaoNegociacaoState(false)
                                            httpRequest('POST', requestsApi.adicionarConclusao, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                tituloId: props.tituloId,
                                                codSolicitacao: props.gerarAcordoDataSource?.codSolicitacao,
                                                codSolucao: solucaoSelecionada?.cd_solucao,
                                                codTipoOcorrencia: props.gerarAcordoDataSource.tipoOcorrencia,
                                                obs: observacaoSolucaoNegociacaoState
                                            })
                                            .then((sender) => {
                                                console.log(sender)
                                                toastConfiguration(true, "success" , "Conclusão adicionada!")
                                                setLiberarConclusaoNegociacaoState(true)
                                                hideLoadPanel()
                                            })
                                            .catch((error) => {
                                                setLiberarConclusaoNegociacaoState(true)
                                                console.log(error)
                                                if(error?.data?.error){
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                }
                                                else{
                                                    setMessage("Falha ao adicionar conclusão")
                                                    setVisible(true)
                                                }
                                                hideLoadPanel()
                                            })
                                        }
                                    }

                                }}
                            />

                            {/* <SimpleItem
                                editorType="dxLookup"
                                caption="Causa"
                                colSpan={4}
                                editorOptions={{
                                    items: props.listaOcorrenciaState,
                                    valueExpr: "cd_tipo_ocorrencia",
                                    displayExpr: "nm_ocorrencia",
                                    onSelectionChanged: (e) => {
                                        setCodTipoOcorrenciaState(e?.selectedItem?.cd_tipo_ocorrencia)
                                    },
                                }}
                            >
                                <Label 
                                    text="Causa"
                                />
                            </SimpleItem> */}

                            <SimpleItem
                                editorType="dxTextArea"
                                caption="Observação"
                                colSpan={4}
                                editorOptions={{
                                    value: observacaoSolucaoNegociacaoState,
                                    onValueChanged: (e) => {
                                        if(e.value){
                                            setObservacaoSolucaoNegociacaoState(e.value)
                                        }
                                    }
                                }}
                            >
                                <Label 
                                    text="Observação"
                                />
                            </SimpleItem>

                        </GroupItem>
                    </Form>
                }

                    <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                        {props.boletoState &&
                            <div style={{marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Button
                                    width={180}
                                    height={40}
                                    text="Boleto"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        
                                        props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                    }}
                                    // disabled={cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0}
                                />
                            </div>
                        }

                        <div>
                            <Button
                                width={130}
                                height={40}
                                text="Anterior"
                                type="default"
                                stylingMode="contained"
                                onClick={() => {
                                    // props.setGerarAcordoDataSource(null)
                                    //pegar todos os dados e enviar para a API 
                                    //...
                                    //ir para o proximo item
                                    
                                    props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                                }}
                            />
                        </div>
                    </div>
           
        </div>
                        
    )
}

export default Passo3;