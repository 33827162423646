export const fecharPausa = (
    discadorEmAtendimentoGlobalState
    ,fichaPesquisadaNaTelaGlobalState
    ,httpRequest
    ,requestsApi
    ,abrirPausaResultJsonGlobalState
    ,applicationID
    ,setDiscadorEmAtendimentoGlobalState
    ,setFichaPesquisadaNaTelaGlobalState
    ,tabPanelItems
    ,setTabPanelItems
    ,setIndexTabPanelMain
    ,pausaEscolhidaDadosState
    ,toastConfiguration
    ,setPopupErrorMessageState
    ,setPopupErrorVisibleState
    ,limparDadadosProximaFicha
    ,setAbrirPausaResultJsonGlobalState
    ,setDesabilitarBotaoGravarOcorrenciaState
    ,telecob
    ,tituloId
) => {
    const dataSourceFichaAtualTituloId = JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"))?.identificador ? JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"))?.identificador : JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"))?.tituloId
    console.log(dataSourceFichaAtualTituloId)
    console.log(dataSourceFichaAtualTituloId)
    console.log(tituloId)
    if (discadorEmAtendimentoGlobalState || fichaPesquisadaNaTelaGlobalState) {
        //quando gravar a ocorrencia vai tirar a pausa do discador
        if(abrirPausaResultJsonGlobalState?.pausaId){
            httpRequest('POST', requestsApi.fecharPausa, requestsApi.basicAuth, {     
                pausaId: abrirPausaResultJsonGlobalState.pausaId, //abrirPausaResultJsonGlobalState?.pausaId,
                applicationId: applicationID
            })
            .then((sender) => {
                const fecharPausaResultJson = JSON.parse(sender)
                //estado q mostra se o operador esta em atendimento pelo discador
                setDiscadorEmAtendimentoGlobalState(false)
    
                //estado q mostra se o operador esta em uma ficha pesquisada manualmente
                setFichaPesquisadaNaTelaGlobalState(false)
    
    
                //limpa as tabs apos gravar ocorrencia
                console.log(tabPanelItems)
                console.log(tabPanelItems.length)
    
                if(tituloId === dataSourceFichaAtualTituloId){
                    sessionStorage.removeItem("mensagemSocket")
                    sessionStorage.removeItem("dataSourceFichaAtual")
                    console.log(sessionStorage.getItem("dataSourceFichaAtual"))
    
                    if(pausaEscolhidaDadosState){
                        const tabPanelItemsTemp = tabPanelItems
                        tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)
            
                        setTabPanelItems(tabPanelItemsTemp)
                        setIndexTabPanelMain(0)
                        //chama a pausa se foi solicitado durante uma chamada
                        httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, pausaEscolhidaDadosState)
                        .then((sender) => {
                            let resultSolicitarPausa = JSON.parse(sender)
                            if(toastConfiguration){
                                toastConfiguration(true, "success", resultSolicitarPausa.mensagem)
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setPopupErrorMessageState(error?.data?.error)
                            setPopupErrorVisibleState(true)
                        })
                    }
                    else{
                        limparDadadosProximaFicha()
                        setAbrirPausaResultJsonGlobalState(null)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                if(setDesabilitarBotaoGravarOcorrenciaState){
                    setDesabilitarBotaoGravarOcorrenciaState(false)
                }
    
                //so mostrar erro da pausa se tiver pausaId
                if(abrirPausaResultJsonGlobalState?.pausaId){
                    console.log("pausaId")
                    console.log(abrirPausaResultJsonGlobalState?.pausaId)
                    setPopupErrorMessageState(error?.data?.error)
                    setPopupErrorVisibleState(true)
                }
    
                //-----fechar ficha------
    
                //estado q mostra se o operador esta em atendimento pelo discador
                setDiscadorEmAtendimentoGlobalState(false)
    
                //estado q mostra se o operador esta em uma ficha pesquisada manualmente
                setFichaPesquisadaNaTelaGlobalState(false)
    
    
                //limpa as tabs apos gravar ocorrencia
                console.log(tabPanelItems)
                console.log(tabPanelItems.length)
                if(tituloId === dataSourceFichaAtualTituloId){
    
                    sessionStorage.removeItem("mensagemSocket")
                    sessionStorage.removeItem("dataSourceFichaAtual")
                    console.log(sessionStorage.getItem("dataSourceFichaAtual"))
    
                    if(pausaEscolhidaDadosState){
                        const tabPanelItemsTemp = tabPanelItems
                        tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)
        
                        setTabPanelItems(tabPanelItemsTemp)
                        setIndexTabPanelMain(0)
                        //chama a pausa se foi solicitado durante uma chamada
                        httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, pausaEscolhidaDadosState)
                        .then((sender) => {
                            let resultSolicitarPausa = JSON.parse(sender)
                            if(toastConfiguration){
                                toastConfiguration(true, "success", resultSolicitarPausa.mensagem)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            setPopupErrorMessageState(err?.data?.err)
                            setPopupErrorVisibleState(true)
                        })
                    }
                    else{
                        limparDadadosProximaFicha()
                        setAbrirPausaResultJsonGlobalState(null)
                    }
                }
            })
        }
        else{
            if(setDesabilitarBotaoGravarOcorrenciaState){
                setDesabilitarBotaoGravarOcorrenciaState(false)
            }

            //-----fechar ficha------

            //estado q mostra se o operador esta em atendimento pelo discador
            setDiscadorEmAtendimentoGlobalState(false)

            //estado q mostra se o operador esta em uma ficha pesquisada manualmente
            setFichaPesquisadaNaTelaGlobalState(false)


            //limpa as tabs apos gravar ocorrencia
            console.log(tabPanelItems)
            console.log(tabPanelItems.length)
            if(tituloId === dataSourceFichaAtualTituloId){

                sessionStorage.removeItem("mensagemSocket")
                sessionStorage.removeItem("dataSourceFichaAtual")
                console.log(sessionStorage.getItem("dataSourceFichaAtual"))

                if(pausaEscolhidaDadosState){
                    const tabPanelItemsTemp = tabPanelItems
                    tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)
                    setTabPanelItems(tabPanelItemsTemp)
                    setIndexTabPanelMain(0)
                    //chama a pausa se foi solicitado durante uma chamada
                    httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, pausaEscolhidaDadosState)
                    .then((sender) => {
                        let resultSolicitarPausa = JSON.parse(sender)
                        if(toastConfiguration){
                            toastConfiguration(true, "success", resultSolicitarPausa.mensagem)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setPopupErrorMessageState(err?.data?.err)
                        setPopupErrorVisibleState(true)
                    })
                }
                else{
                    limparDadadosProximaFicha()
                    setAbrirPausaResultJsonGlobalState(null)
                }
            }
        }
    }
    //se vier do telecob e tiver sem pausa
    else if(telecob && tituloId === dataSourceFichaAtualTituloId){
        sessionStorage.removeItem("mensagemSocket")
        sessionStorage.removeItem("dataSourceFichaAtual")
        console.log(sessionStorage.getItem("dataSourceFichaAtual"))
        //limpa as tabs apos gravar ocorrencia
        const tabPanelItemsTemp = tabPanelItems
        tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)
        limparDadadosProximaFicha()

        // setTabPanelItems(tabPanelItemsTemp)
        // setIndexTabPanelMain(0)
    }
}