import SelectBox from 'devextreme-react/select-box';
import {TextBox} from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import {useEffect, useState, memo} from "react"
import React from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from "../../Context/RequestsApi"
import { applicationID } from '../../api/ApplicationID';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import QueryFormPopup from '../QueryForm/QueryFormPopup';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';
import { Toast } from 'devextreme-react/toast';

const EnviarEmailCustomField = (props) => {
    console.log(props)
    const [popupQueryFormOpen, setPopupQueryFormOpen] = useState(false)
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState()
    const [tituloQueryFormPopup, setTituloQueryFormPopup] = useState()
    const [queryFormPopupState, setQueryFormPopupState] = useState(false)

    const {requestsApi} = useRequestsApi()

    const [foreignFieldDataSource, setForeignFieldDataSource] = useState([])

    const [loadedDataState, setLoadedDataState] = useState(false)

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }


    const RenderQueryFormPopup = memo(() => {
        return (
            <QueryFormPopup
                id={Math.random()}
                setPopupQueryFormOpen={setPopupQueryFormOpen}
                popupQueryFormOpen={popupQueryFormOpen}
                entityName="_TemplateEmail"
                entityStructure={props.entityStructure}
                setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                setQueryFormPopupState={setQueryFormPopupState}
                queryFormPopupState={queryFormPopupState}
                modeEditOn={props.modeEditOn}
                tituloQueryFormPopup={tituloQueryFormPopup}

                trazerTodosRegistros={true}
                foreignFieldSelectedItem={templateEmailSelecionadoState}
                setForeignFieldSelectedItem={setTemplateEmailSelecionadoState}
                foreignFieldDataSource={foreignFieldDataSource}
            />
        );
    })

    const [templateEmailSelecionadoState, setTemplateEmailSelecionadoState] = useState(null)
    const [valorCampoState, setValorCampoState] = useState("")

    const [states, setStates] = useState({
        toastVisible: false,
        toastType: 'success',
        toastMessage: ""
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
        setStates({...states, toastVisible: false})
    }


    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    useEffect(() => {
        if(templateEmailSelecionadoState){
            console.log(templateEmailSelecionadoState)
            //enviar email
            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "procedure": "_enviarTemplateEmail",
                "json": {
                    "entityName": props.entityName,
                    "templateId": templateEmailSelecionadoState.id,
                    "email": [valorCampoState]
                },
                "idValue": [`${props.dataEntity.id}`]
            })
            .then((sender) => {
                const resultJsonDataEntity = JSON.parse(sender)
                console.log(resultJsonDataEntity)
                setLoadedDataState(true)
                //mostrar toast
                toastConfiguration(true, 'success', `E-mail enviado com sucesso!`)   
            }) 
            .catch((err) => {
                console.log(err)
                setPopupErrorMessageState(err?.data?.err)
                setPopupErrorVisibleState(true)
            })
        }
    }, [templateEmailSelecionadoState])

    useEffect(() => {
        if(props.dataEntity[`${props.dataField}`]){
            setValorCampoState(props.dataEntity[`${props.dataField}`])
        }
    }, [])

    return(
    <div style={{"display": "flex"}}>

        {popupQueryFormOpen === true ? <RenderQueryFormPopup /> : <></>}

        <UpfPopupMessage
            hidePopup={hidePopupError}
            visible={popupErrorVisibleState}
            message={popupErrorMessageState}
        />

        <Toast
            visible={states.toastVisible}
            message={states.toastMessage}
            type={states.toastType}
            // onHiding={onHidingToast}
            displayTime={1800}
            height={60}
        />

        <TextBox
            value={valorCampoState}
            stylingMode='underlined'
            labelMode='static'
            label={props.caption}
        />

        <Button
            style={{"marginLeft": "0.5em"}}
            icon="email"
            onClick={() => {
                setQueryFormPopupForeignFieldState(true)
                setPopupQueryFormOpen(true)
                setTituloQueryFormPopup(props.caption)
                setQueryFormPopupState(true)
            }}
        />
        {/* {!loadedDataState && props.foreignFieldSelectedItem && foreignFieldDataSource &&
            <LoadIndicator/>
        } */}
    </div>
    )
}

export default EnviarEmailCustomField;