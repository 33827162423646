import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    TotalItem
    } from 'devextreme-react/data-grid';
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID } from '../../../api/ApplicationID';
import { useState, useEffect, useRef } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import UpfPopupMessage from "../../../CardComponents/UpfPopupMessage"
import { convertDate } from '../../../functions/convertDate';
import { LoadPanel } from 'devextreme-react/load-panel';
import TextBox from 'devextreme-react/text-box';
import Loading from "../../../CardComponents/Loading";
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"

const Passo1 = (props) => {
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [disabledDataGridState, setDisabledDataGridState] = useState(false)
    const [dataLookup, setDataLookup] = useState()

    const setTipoOcorrencia = () => {
        if(props?.gerarAcordoDataSource?.tipoOcorrencia){
            return props?.gerarAcordoDataSource?.tipoOcorrencia
        }
        else{
            return 1
        }
    }
    const statesJson = {
        dataSource: null,
        tipoOcorrencia: setTipoOcorrencia(),
        dateBoxState: null,
        tipoNegociacaoState: null,
        opcaoNegociacaoState: null,
    }

    const [states, setStates] = useState(statesJson)

    const [formData, setFormData] = useState({"campanhaDesconto": null, "dataCampanha": null,"tipoOcorrencia": setTipoOcorrencia()})

    const _dataGridParcelasRef = useRef(null)
    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [cdPessoaState, setCdPessoaState] = useState()

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    

    const entityStructureFieldsDefs = [
        {
            fieldName: "empresa",
            fieldCaption: "Empresa",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "numero",
            fieldCaption: "Número",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "formapagamento",
            fieldCaption: "Forma Pagamento",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valortitulo",
            fieldCaption: "Vl. Título",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valordescontoliquidado",
            fieldCaption: "Vl. Descontado/Liquidado",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valoraberto",
            fieldCaption: "Vl. Aberto",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "emissao",
            fieldCaption: "Emissão",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "multa",
            fieldCaption: "Vl. Multa",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "juros",
            fieldCaption: "Juros",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valoratualizado",
            fieldCaption: "Vl. Atualizado",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "tipovencimento",
            fieldCaption: "Tipo/Vencimento",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
        },
        {
            fieldName: "placa",
            fieldCaption: "Placa",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "status",
            fieldCaption: "Status",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "serasa",
            fieldCaption: "Serasa",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "pendencia",
            fieldCaption: "Pendência",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            groupIndex: 0
        },



        {
            fieldName: "tipo",
            fieldCaption: "Tipo",
            editorType: null,
            format: null,
            visible: 0,
            showInColumnChooser: 1,
        },
        {
            fieldName: "servico",
            fieldCaption: "Serviço",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "pedido",
            fieldCaption: "Pedido",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "chassi",
            fieldCaption: "Chassi",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        
    ]

    const convertValuesStringToNumber = (estruturura, dados) => {
        for(let c = 0; c <= estruturura.length - 1; c++){
            if(estruturura[c]?.format === "money"){
                for(let i = 0; i <= dados.length - 1; i++){
                    if(dados[i]?.[estruturura[c].fieldName]){
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName]).toFixed(2)
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName])
                    }
                }
            }
            
        }
    }

    
    
    useEffect(() => {
        showLoadPanel()
        if(requestsApi && !sessionStorage.getItem("dataSourceIturanJson")) {
            const dataSourceIturanJson = {}

            httpRequest('POST', requestsApi.getCampanhaDesconto , requestsApi.basicAuth, {
                tituloId: props.tituloId,//proximaFichaDados.tituloId,
                applicationId: applicationID,
            })
            .then((sender) => {
                const resultGetCampanhaDescontoJson = JSON.parse(sender)

                const json = {
                    "campanhaDesconto": resultGetCampanhaDescontoJson?.[0]?.nm_ocorrencia ? `${resultGetCampanhaDescontoJson?.[0]?.nm_ocorrencia}%` : null,
                    "campanhaDescontoNumero": parseInt(resultGetCampanhaDescontoJson?.[0]?.nm_ocorrencia),
                    "dataCampanha": resultGetCampanhaDescontoJson?.[0]?.dt_cadastro ? resultGetCampanhaDescontoJson[0].dt_cadastro : null,
                    "tipoOcorrencia": 1,
                    "clienteemail": resultGetCampanhaDescontoJson?.[0]?.clienteemail ? resultGetCampanhaDescontoJson[0].clienteemail : null,
                    "clienteid": resultGetCampanhaDescontoJson?.[0]?.clienteid ? resultGetCampanhaDescontoJson[0].clienteid : null
                }

                setFormData(json)
                dataSourceIturanJson["getCampanhaDesconto"] = json

                httpRequest('POST', requestsApi.getPendencias, requestsApi.basicAuth, {
                    tituloId: props.tituloId,//proximaFichaDados.tituloId,
                    applicationId: applicationID,
                })
                .then((sender) => {
                    const resultSenderJson = JSON.parse(sender)
    
                    setCdPessoaState(resultSenderJson[0].cd_pessoa)
                    
                            
                    //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                    convertDate(resultSenderJson, entityStructureFieldsDefs)
                    //
    
                    convertValuesStringToNumber(entityStructureFieldsDefs, resultSenderJson)

                    dataSourceIturanJson["pendencias"] = resultSenderJson

                    sessionStorage.setItem("dataSourceIturanJson", JSON.stringify(dataSourceIturanJson))
    
                    setStates(
                        {...states,
                            dataSource: resultSenderJson
                        }
                    )
    
                    hideLoadPanel()
    
                })
                    
                .catch((error) => {
                    console.log(error)
                    if(error?.data?.error){
                        setMessage(error.data.error)
                        setVisible(true)
                    }
                    else{
                        setMessage("Falha ao carregar pendências")
                        setVisible(true)
                    }
                    
                    hideLoadPanel()
                })

            })
            .catch((error) => {
                console.log(error)
                setMessage("Falha ao carregar campanha desconto")
                setVisible(true)
                // if(error?.data?.error){
                //     setMessage(error.data.error)
                //     setVisible(true)
                // }
                // else{
                //     setMessage("Falha ao carregar campanha desconto")
                //     setVisible(true)
                // }
                hideLoadPanel()
            })
            
        }
        else if(JSON.parse(sessionStorage.getItem("dataSourceIturanJson"))){

            const dataSourceIturanJson = JSON.parse(sessionStorage.getItem("dataSourceIturanJson"))

            setFormData(dataSourceIturanJson?.getCampanhaDesconto)
    
            setStates(
                {...states,
                    dataSource: dataSourceIturanJson?.pendencias
                }
            )

            hideLoadPanel()
        }
    }, [])

    return(
        <div style={{height: "100%", padding: "0.5em"}}>
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            {states.dataSource ? 
                (
                    <>
                        <Form
                            colCount={4}
                            formData={formData}
                        >
                            <GroupItem
                                colSpan={4}
                                colCount={4}
                                // caption="teste"
                            >

                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxTextBox" 
                                    dataField="campanhaDesconto"
                                    caption="Campanha desconto"
                                    editorOptions=
                                    {{ 
                                        disabled: true,
                                        // value: getCampanhaDescontoState?.[0]?.nm_ocorrencia ? `${getCampanhaDescontoState?.[0]?.nm_ocorrencia}%` : null
                                    }}
                                >
                                    <Label 
                                        text="Campanha desconto"
                                    />
                                </SimpleItem>

                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxDateBox" 
                                    dataField="dataCampanha"
                                    caption="Data da campanha"
                                    editorOptions=
                                    {{ 
                                        disabled: true,
                                        // value: getCampanhaDescontoState?.[0]?.dt_cadastro
                                    }}
                                >
                                    <Label 
                                        text="Data da campanha"
                                    />
                                </SimpleItem>

                                <SimpleItem
                                    colSpan={4}
                                    editorType="dxSelectBox" 
                                    dataField="tipoOcorrencia"
                                    caption="Tipo Ocorrência"
                                    editorOptions=
                                    {{ 
                                        displayExpr:"descricao",
                                        valueExpr:"id",
                                        items: [{id: 1, descricao: "Unificada(taxas)"}, {id: 2, descricao: "Negociação"}],
                                        // defaultValue: 1,
                                        // visible: true
                                    }}
                                >
                                    <Label 
                                        text="Tipo Ocorrência"
                                    />
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                            
                        <div>
                            <div className="div-parcelas">
                                <div style={{ marginTop: '1em' }}>
                                    <Button
                                        icon="columnchooser"
                                        hint="Mostrar ou esconder colunas do grid"
                                        type="normal"
                                        onClick={() => {
                                            _dataGridParcelasRef.current.instance.showColumnChooser()
                                        }}
                                    />
                                </div>
                            </div>

                            <DataGrid
                                ref={_dataGridParcelasRef}
                                dataSource={states.dataSource ? (states.dataSource) : ([])}
                                columnAutoWidth={true}
                                showBorders={true}
                                cacheEnabled={false}
                                disabled={disabledDataGridState}
                            >

                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />

                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />

                                <Selection 
                                    mode="single"
                                    keyExpr='ID'
                                />
                                <Scrolling 
                                    rowRenderingMode='infinity'
                                    showScrollbar='always'
                                    useNative={true}
                                    >
                                </Scrolling>

                                <Paging defaultPageSize={8} />

                                <Sorting
                                    mode="none"
                                />
                                
                                <Pager
                                    visible={true}
                                    allowedPageSizes={8}
                                    // showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                
                                {/* <HeaderFilter
                                        allowSearch={true}
                                        visible={true}
                                    />
                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />
                                <FilterRow visible={true} /> */}
                                
                                <ColumnChooser 
                                    enabled={false} 
                                    width={400} 
                                    height={800} 
                                    mode={"select"} 
                                    allowSearch={true}
                                />

                                {entityStructureFieldsDefs.map((item) => {
                                    if(item.editorType === 'dxDateBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='date'
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.editorType === 'dxCheckBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='boolean'
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.format === 'money') {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                format="R$ #,##0.##00"
                                            />
                                        )
                                    }
                                    else if (item.format === 'percent') {
                                        return (
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                showInColumnChooser={item.showInColumnChooser}
                                                format="#0'%'"
                                            />
                                        )
                                    }
                                    else {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                showInColumnChooser={item.showInColumnChooser}
                                            />
                                        )
                                    }
                                    
                                })}
                                <Summary
                                    calculateCustomSummary={calculateCustomSummary}
                                >

                                    {entityStructureFieldsDefs.map((item) => {
                                        if(item.format === 'money') {
                                            return(
                                                <TotalItem
                                                    column={item.fieldName}
                                                    summaryType="sum"
                                                    format={{
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        precision: 2,
                                                    }}
                                                    customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                />
                                            )
                                        }
                                    })}

                                </Summary>
                            </DataGrid>
                            <div className="div-boleto-form-vencimento">
                            <Button
                                width={140}
                                height={40}
                                text="Próximo"
                                type="default"
                                disabled={disabledButtonState}
                                stylingMode="contained"
                                onClick={() => {
                                    if(formData?.tipoOcorrencia) {
                                        showLoadPanel()
                                        httpRequest('POST', requestsApi.getCodigoSolicitacao,requestsApi.basicAuth, {
                                            tituloId: props.tituloId,
                                            applicationId: applicationID,
                                            tipoOcorrencia: formData.tipoOcorrencia
                                        })
                                        .then((sender) => {
                                            const resultGetCodigoSolicitacaoJson = JSON.parse(sender)

                                            //TAXAS
                                            if(formData.tipoOcorrencia == 1){
                                                httpRequest('POST', requestsApi.getCobrancasPontuaisTaxas,requestsApi.basicAuth, {
                                                    tituloId: props.tituloId,
                                                    applicationId: applicationID,
                                                    codSolicitacao : resultGetCodigoSolicitacaoJson?.cod_solicitacao,
                                                })
                                                .then((sender) => {
                
                                                    const resultGetCobrancasPontuaisJson = JSON.parse(sender)
                                                    
                                                    httpRequest('POST', requestsApi.getFormasPagamento,requestsApi.basicAuth, {
                                                        codTipoCobranca : 18,
                                                        applicationId: applicationID,
                                                        codSolicitacao : resultGetCodigoSolicitacaoJson?.cod_solicitacao
                                                    })
                                                    .then((sender) => {
                                                        //grava o retorno em um estado e passar pra proxima pagina
                                                        const resultGetFormasPagamentoJson = JSON.parse(sender)
                                                        console.log(cdPessoaState)
                                                        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth,{
                                                                applicationId: applicationID,
                                                                entityName: "_cadastro",
                                                                fieldName: "id",
                                                                expressao: formData?.clienteid
                                                        })
                                                        .then((sender) => {
                                                            const resultGetDataEntityJson = JSON.parse(sender)
                                                            let telefones = []
                                                            if(resultGetDataEntityJson?.detailEntity?.length > 0){
                                                                if(resultGetDataEntityJson.detailEntity?._cadastro_contatos?.records){
                                                                    telefones = resultGetDataEntityJson.detailEntity._cadastro_contatos.records
                                                                }
                                                            }
                                                    
                                                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth,
                                                                {
                                                                    "lookUpEntities": [
                                                                        {
                                                                            "lookUpEntity": "_pontuacao_contato",
                                                                        },
                                                                        {
                                                                            "lookUpEntity": "_tipo_contato",
                                                                        }
                                                                    ]
                                                                }
                                                            )
                                                            .then((sender)=>{
                                                                const senderDataLookup = JSON.parse(sender)

                                                                props.setGerarAcordoDataSource({
                                                                    formasPagamento: resultGetFormasPagamentoJson,
                                                                    cobrancasPontuais: resultGetCobrancasPontuaisJson,
                                                                    codSolicitacao: resultGetCodigoSolicitacaoJson?.cod_solicitacao,
                                                                    cd_pessoa: cdPessoaState,
                                                                    descontoState: formData?.dataCampanha,
                                                                    tipoOcorrencia: formData?.tipoOcorrencia,
                                                                    clienteEmail: formData?.clienteemail,
                                                                    clienteId: formData?.clienteid,
                                                                    campanhaDesconto: formData.campanhaDescontoNumero,
                                                                    dataLookup: senderDataLookup,
                                                                    telefones: telefones
                                                                })
                    
                                                                hideLoadPanel()
                        
                                                                props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                            })
                                                            .catch((error) => {
                                                                console.log(error)
                                                            })
                                                        })
                                                        .catch((error) => {
                                                            console.log(error)
                                                        })
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                        if(error?.data?.error){
                                                            setMessage(error.data.error)
                                                            setVisible(true)
                                                        }
                                                        else{
                                                            setMessage("Falha ao carregar formas de pagamento")
                                                            setVisible(true)
                                                        }
                                                        hideLoadPanel()
                                                    })
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                    if(error?.data?.error){
                                                        setMessage(error.data.error)
                                                        setVisible(true)
                                                    }
                                                    else{
                                                        setMessage("Falha ao carregar formas de pagamento")
                                                        setVisible(true)
                                                    }
                                                    hideLoadPanel()
                                                })
                                            }
                                            //NEGOCIAÇÃO
                                            else if(formData.tipoOcorrencia == 2){
                                                httpRequest('POST', requestsApi.getCobrancasPontuais,requestsApi.basicAuth, {
                                                    tituloId: props.tituloId,
                                                    applicationId: applicationID,
                                                    codSolicitacao : resultGetCodigoSolicitacaoJson?.cod_solicitacao,
                                                })
                                                .then((sender) => {
                
                                                    const resultGetCobrancasPontuaisJson = JSON.parse(sender)
                                                    
                                                    httpRequest('POST', requestsApi.getFormasPagamento,requestsApi.basicAuth, {
                                                        codTipoCobranca : 18,
                                                        applicationId: applicationID,
                                                        codSolicitacao : resultGetCodigoSolicitacaoJson?.cod_solicitacao
                                                    })
                                                    .then((sender) => {
                                                        //grava o retorno em um estado e passar pra proxima pagina
                                                        const resultGetFormasPagamentoJson = JSON.parse(sender)
                                                        console.log(cdPessoaState)

                                                        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth,{
                                                            applicationId: applicationID,
                                                            entityName: "_cadastro",
                                                            fieldName: "id",
                                                            expressao: formData?.clienteid
                                                        })
                                                        .then((sender) => {
                                                            const resultGetDataEntityJson = JSON.parse(sender)
                                                            let telefones = []
                                                            if(resultGetDataEntityJson?.detailEntity?.length > 0){
                                                                if(resultGetDataEntityJson.detailEntity?._cadastro_contatos?.records){
                                                                    telefones = resultGetDataEntityJson.detailEntity._cadastro_contatos.records
                                                                }
                                                            }
                                                    
                                                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth,
                                                                {
                                                                    "lookUpEntities": [
                                                                        {
                                                                            "lookUpEntity": "_pontuacao_contato",
                                                                        },
                                                                        {
                                                                            "lookUpEntity": "_tipo_contato",
                                                                        }
                                                                    ]
                                                                }
                                                            )
                                                            .then((sender)=>{
                                                                const senderDataLookup = JSON.parse(sender)

                                                                props.setGerarAcordoDataSource({
                                                                    formasPagamento: resultGetFormasPagamentoJson,
                                                                    cobrancasPontuais: resultGetCobrancasPontuaisJson,
                                                                    codSolicitacao: resultGetCodigoSolicitacaoJson?.cod_solicitacao,
                                                                    cd_pessoa: cdPessoaState,
                                                                    descontoState: formData?.dataCampanha,
                                                                    tipoOcorrencia: formData?.tipoOcorrencia,
                                                                    clienteEmail: formData?.clienteemail,
                                                                    clienteId: formData?.clienteid,
                                                                    campanhaDesconto: formData.campanhaDescontoNumero,
                                                                    dataLookup: senderDataLookup,
                                                                    telefones: telefones
                                                                })
                        
                                                                hideLoadPanel()
                        
                                                                props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                            })
                                                            .catch((error) => {
                                                                console.log(error)
                                                            })
                                                        })
                                                        .catch((error) => {
                                                            console.log(error)
                                                        })
                                                    
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                        if(error?.data?.error){
                                                            setMessage(error.data.error)
                                                            setVisible(true)
                                                        }
                                                        else{
                                                            setMessage("Falha ao carregar formas de pagamento")
                                                            setVisible(true)
                                                        }
                                                        hideLoadPanel()
                                                    })
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                    if(error?.data?.error){
                                                        setMessage(error.data.error)
                                                        setVisible(true)
                                                    }
                                                    else{
                                                        setMessage("Falha ao carregar formas de pagamento")
                                                        setVisible(true)
                                                    }
                                                    hideLoadPanel()
                                                })
                                            }

                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            if(error?.data?.error){
                                                setMessage(error.data.error)
                                                setVisible(true)
                                            }
                                            else{
                                                setMessage("Falha ao carregar código solicitação")
                                                setVisible(true)
                                            }
                                            hideLoadPanel()
                                        })
                                    }
                                    else {
                                        setMessage("Selecione o tipo da ocorrência!")
                                        setVisible(true)
                                        hideLoadPanel()
                                    }
                                }}
                            ></Button>
                            </div>
                        </div>
                    </>
                ) 
                :
                (
                    <Loading/>
                )

            }

        </div>
    )
}

export default Passo1;