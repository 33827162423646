import TreeList, { Column, Selection, FilterRow, Scrolling, Paging, Pager } from 'devextreme-react/tree-list';
import { useState, useEffect, useCallback, useRef } from 'react'
import { httpRequest } from '../../../functions';
import { applicationID, relationId } from '../../../api/ApplicationID';
import { Button } from 'devextreme-react/button';
import { Toast } from 'devextreme-react/toast';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useRequestsApi } from '../../../Context/RequestsApi';
import UpfPopup from '../../../CardComponents/UpfPopup';
import LoadCircular from '../../../CardComponents/LoadCircular';
import "./style.css";

const GroupPermissionForm = (props) => {   
   const treeListRef = useRef(null);
   const { requestsApi } = useRequestsApi()

   const [messageError, setMessageError] = useState("")
   const [errorPopoupVisibleState, setErrorPopoupVisibleState] = useState(false)

   const [requestJson, setRequestJson] = useState();
   const [onChangeState, setOnChangeState] = useState(false)
   const [processandoState, setProcessandoState] = useState(false)

   const [toastConfig, setToastConfig] = useState({
      isVisible: false,      
      type: 'info',
      message: ""
   })

   const [nodesState, setNodesState] = useState();

   const onHiding = useCallback(() => {
      setToastConfig({
         ...toastConfig,
         isVisible: false
      });
   }, [toastConfig]);

   const toastConfiguration = useCallback((visible, type, message) => {
      setToastConfig({
         ...toastConfig,
         isVisible: visible,
         type: type,
         message: message
      });
   }, [toastConfig]);

   useEffect(() => {
      if (requestsApi) {
         httpRequest('POST', requestsApi[props.requestEndpoint], requestsApi.basicAuth, {
            applicationId: applicationID,
            securitygroupId: props.securitygroupId ? props.securitygroupId : ""
         })
         .then((sender) => {
            let getSecurityGroupPermissionResultJson = JSON.parse(sender);
            setRequestJson(getSecurityGroupPermissionResultJson);
            modifyJsonStructure(getSecurityGroupPermissionResultJson);
         })
         .catch((error) => {
            console.log(error)
            setMessageError(error.data.error)
            setErrorPopoupVisibleState(true)
         })
      }
   }, [])

   function updateNodesState(nodesUpdated) {
      nodesUpdated.forEach(node => {
         const nodeOutdated = nodesState.find(n => n.key === node.key);        
         nodeOutdated.data.selected = node[nodeOutdated.data.name];
      });
   }

   function modifyJsonStructure(json) { 
      const nodesOptions = ["contextMenu", "fields", "detalhes", "subMenuItems"];
      for (let i of json) {
         if (i.hasOwnProperty("_securitygrouppermissionId")) {
            Object.keys(i).forEach(key => {
               if (nodesOptions.includes(key) && i[key] !== null) {
                  i.items.push({
                     name: key,
                     items: [...i[key]]
                  });
                  modifyJsonStructure(i[key]);
               }
            });
         }
      }
   }

   /**
    * Percorre todos os nodes de um array com estrutura em árvore faz uma chamada recursiva para
    * achar nodes filhos na posição mais profunda da árvore, para fazer uma comparação e descobrir
    * se o node sofreu alterações e com isso atualizar a propriedade "selected" para um valor
    * boleano, pois por padrão estão com valores 0's e 1's (padrão do banco de dados).
    * @generator
    * @yields { object } Node object
    * @param { Array<object> } nodeArr - Array de nodes sem tratamento
    * @param { Array<object> } selectedArr - Array de nodes selecionados
   */

   function* forEachNodeGen(nodeArr, selectedNodeArr) {
      for (let i of nodeArr) {
         if (i.data.hasOwnProperty("_securitygrouppermissionId")) {            
            let tmpObj = {
               id: i.data._securitygrouppermissionId
            };
            let arrObj = [];
            
            for (let j of i.children) {
               if (!j.hasChildren) {
                  if (selectedNodeArr.includes(j.key)) {
                     const nodeOutdated = nodesState.find(obj => obj.key === j.key);
                     if (!Boolean(nodeOutdated.data.selected)) {                        
                        arrObj.push({
                           key: j.key,
                           [j.data.name]: true
                        });
                        tmpObj[j.data.name] = true;
                     }
                  } else {
                     const nodeOutdated = nodesState.find(obj => obj.key === j.key);
                     if (Boolean(nodeOutdated.data.selected)) {                        
                        arrObj.push({
                           key: j.key,
                           [j.data.name]: false
                        });
                        tmpObj[j.data.name] = false;
                     }
                  }
               } else {
                  yield * forEachNodeGen(j.children, selectedNodeArr);
               }
            }
            if (Object.keys(tmpObj).length > 1) {
               yield tmpObj;
               yield arrObj;
            }

         } else {
            yield * forEachNodeGen(i.children, selectedNodeArr)
         }
      }
   }

   /**
    * Marca como selecionado todos os nodes com a propriedade "selected" = true
    * & seta todos os nodes no estado "nodesState"
   */
   const selectRows_populateNodesState = () => {
      const treeListInstance = treeListRef.current.instance;      
      const nodesArr = [];
      const selectedKeysTmp = [];
      treeListInstance.forEachNode(node => {
         if (!node.hasChildren) {
            nodesArr.push(node);
         }                  
         if (node.data.hasOwnProperty("selected") && node.data.selected === 1) {
            selectedKeysTmp.push(node.key);
         }
      })
      setNodesState(nodesArr);
      treeListInstance.selectRows(selectedKeysTmp, true);
   }

   const handleSavePermissions = () => {   
      //constants and functions
      const treeListInstance = treeListRef.current.instance;
      const selectedRowsArr = treeListInstance.getSelectedRowsData("all").map(obj => obj.id);
      const treeListDataSource = treeListInstance.getDataSource()._items;
      const nodes = [...forEachNodeGen(treeListDataSource, selectedRowsArr)];
      const nodesForUpdate = nodes.filter(i => !Array.isArray(i)).map(node => {
         delete node.key;
         return node;
      });
      const nodesForUpdateState = nodes.filter(i => Array.isArray(i)).reduce((acc, cur) => {
         acc.push(...cur);
         return acc;
      }, []);      

      //request
      setProcessandoState(true);
      httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
         applicationId: applicationID,
         entityName: "_securitygrouppermission",
         relationId: relationId,
         json: nodesForUpdate
      })
      .then((sender) => {
         setProcessandoState(true);
         const senderUpdateRecordFromJson = JSON.parse(sender);
         setProcessandoState(false);
         toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem);
         
         /*
          * Percorre todos os nodes modificados/atualizados, remove as referências dos nodes antigos desatualizados 
          * e adiciona no estado de nodes todos os nodes atualizados
         */
         console.log("nodesForUpdateState: ", nodesForUpdateState);
         
         updateNodesState(nodesForUpdateState);
      })
      .catch((error) => {
         console.log(error);
         setProcessandoState(false);
      })
   }

   return (
      <div>
         <UpfPopup
            typePopup="message"
            popupVisibleState={errorPopoupVisibleState}
            popupVisibleFunctionChangeState={setErrorPopoupVisibleState}
            message={messageError}
         />

         <div className="btn-tabPanel">
            <div className="buttons-tabpanel">
               <Button            
                  icon="save"
                  hint={"Salvar permissões"}
                  type="normal"
                  disabled={!requestJson && !onChangeState}
                  onClick={handleSavePermissions}
               />
            </div>
            {processandoState &&
               <div style={{margin: "0", padding: "0", display: "flex", alignItems: "center"}}>
                 <b>Processando...</b><LoadCircular/>
                 {/* <LoadIndicator/> */}
               </div>
            }
         </div>

         <Toast
            visible={toastConfig.isVisible}
            message={toastConfig.message}
            type={toastConfig.type}
            onHiding={onHiding}
            displayTime={600}
            height={60}
         />

         <TreeList
            ref={treeListRef}
            id="treeList"
            dataSource={requestJson}
            itemsExpr="items"
            showBorders
            columnAutoWidth
            dataStructure="tree"
            noDataText="Carregando dados..."
            filterMode="fullBranch"
            height={600}      
            onNodesInitialized={selectRows_populateNodesState}
         >
            <Selection recursive mode="multiple" />
            <FilterRow visible />
            <Scrolling 
            //mode="standard" 
            //rowRenderingMode="virtual" 
            rowRenderingMode='infinity'
            showScrollbar='always'
            useNative={true}/>
            <Paging enabled defaultPageSize={50} />
            <Pager 
               visible            
               showPageSizeSelector
               allowedPageSizes={[25, 50, 100, 500]}
               showInfo
            />
            <Column dataField="name" caption="Nome" />
         </TreeList>
      </div>
    )
}

export default GroupPermissionForm;