import { httpRequest } from "../../functions"
import { useRequestsApi } from "../../Context/RequestsApi";
import {
    applicationID,
    relationId,
    permissions
} from '../../api/ApplicationID'
import TabPanel from 'devextreme-react/tab-panel';
// import Toolbar, { Item } from 'devextreme-react/toolbar';
import ExportingExcel from '../../CardComponents/ExportingExcel'
import ExportingPDF from '../../CardComponents/ExportingPDF';
import Form, {
    SimpleItem,
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
    ButtonItem
} from 'devextreme-react/form';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';
import DataGrid, { Column as DatagridColumn } from "devextreme-react/data-grid";
import "./style.css"
import $ from "jquery"
import React, { useCallback, useEffect, useState } from "react";
import UpfHtmlEditor from "../UpfComponents/UpfHtmlEditor"
import { Button } from 'devextreme-react/button';
import OcorrenciaCustomForm from "../OcorrenciaCustomForm"
import { TreeList, Column, SearchPanel } from 'devextreme-react/tree-list';
import Lookup from "devextreme-react/lookup"
import CheckBox from 'devextreme-react/check-box';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import {
    Popup,
    ToolbarItem,
} from 'devextreme-react/popup';

const TarefaHistoricoForm = (props) => {
    console.log(props)
    const { requestsApi } = useRequestsApi()

    const [dataLookup, setDataLookup] = useState()

    const [formDataJson, setFormDataJson] = useState({})

    const [itemSelected, setItemSelected] = useState([]);

    const [messageError, setMessageError] = useState();

    const [popup, setPopup] = useState({
        isVisible: false,
        message: 'Houve um erro',
    })

    const [states, setStates] = useState({
        _ocorrenciaid: '',
        _texto: '',
        _pontuacaocontatoid: '',
        _cadastrocontatoid: '',
        _horarioprogramado: '',
        _agendamento: null,
        _motivoinadimplenciaid: '',
        _equipeid: '',
        _discadorid: '',
        _processamentomailingid: '',
        _processamentomailingitemid: '',
        _fone: '',
        _horarioinicio: '',
        _horariofim: '',
        _motivoretornoid: '',
        _tempotabulando: '',
    })

    useEffect(() => {
        if (requestsApi) {
            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    },
                    {
                        "lookUpEntity": "_motivoRetorno"
                    }
                ]
            })
                .then((sender) => {

                    const resultGetLookupTableJson = JSON.parse(sender)

                    setDataLookup(resultGetLookupTableJson)

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [requestsApi])

    const incluirOcorrencia = () => {
        if (itemSelected.length > 0) {
            httpRequest('POST', requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tarefahistorico",
                relationId: relationId,
                json: {
                    _ocorrenciaTarefaId: states._ocorrenciaid,
                    _observacoes: states._texto,
                    _tarefaid: props.tituloId,
                    _funcionarioId: relationId
                }
                
            })

                .then((sender) => {
                    const resultIncluirOcorrenciaJson = JSON.parse(sender)
                    if (props.setGravouOcorrencia) {
                        props.setGravouOcorrencia(true)
                    }

                    props.toastConfiguration(true, "success", resultIncluirOcorrenciaJson.mensagem)
                    props.functionVisibleState(false)
                })
                .catch((error) => {
                    console.log(error)
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: 'Atenção!'
                    })
                })
        }
        else {

            setMessageError('Selecione uma ocorrência!')
            setPopup({
                ...popup
                , isVisible: true
                , message: 'Atenção!'
            })
        }

    }

    const hidePopup = () => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    }

    const closeButtonPopup = {
        text: 'Fechar'
        , icon: 'arrowright'
        , onClick: hidePopup
    };


    const buttonOptions = {
        text: 'Gravar',
        type: 'success',
        width: '150px',
        height: '50px',
        onClick: incluirOcorrencia
        // useSubmitBehavior: true,
    }
    return (
        <div className="detail-tabpanel-historico-custom-form">
            <div className='ocorrencia-custom-form-div'>
                <Popup
                    visible={popup.isVisible}
                    onHiding={hidePopup}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={false}
                    showTitle={true}
                    title={popup.message}
                    width={300}
                    height={280}
                    resizeEnabled={true}
                >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopup}
                    />
                    {messageError &&
                        <p>{messageError}</p>
                    }
                </Popup>
                <TabPanel>
                    <Item
                        title={"Ocorrência"}
                        key={1}
                    >
                        <ScrollView width="100%" height="100%">
                            <div className="div-tree-list">
                                <ScrollView width={"100%"} height={"100%"} useNative={true}>
                                    <TreeList
                                        id="ocorrencia"
                                        dataSource={props.ocorrencias}
                                        rootValue={-1}
                                        keyExpr="id"
                                        showRowLines={true}
                                        showBorders={true}
                                        parentIdExpr="_parentid"
                                        columnAutoWidth={true}
                                        focusedRowEnabled={false}
                                        width={"100%"}
                                        searchPanel={{

                                            highlightSearchText: true,
                                            placeholder: "Procurar...",
                                            searchVisibleColumnsOnly: true,
                                            visible: true,
                                        }}
                                        onRowClick={(item) => {

                                            if (item.event.target.nodeName === "SPAN") {
                                                if (item.component.isRowExpanded(item.key))
                                                    item.component.collapseRow(item.key)
                                                else
                                                    item.component.expandRow(item.key)
                                            } else {
                                                if (item.component.isRowExpanded(item.key))
                                                    item.component.collapseRow(item.key)
                                                else
                                                    item.component.expandRow(item.key)
                                            }
                                            item.node.children.length == 0 && setItemSelected([item.data])
                                            item.node.children.length == 0 && setStates({
                                                ...states,
                                                _ocorrenciaid: item.data.id
                                            })
                                            console.log(item.event.target.nodeName)
                                        }}
                                    // onRowDblClick={(item) => {
                                    //     item.node.children.length == 0 && setItemSelected([item.data])
                                    // }}
                                    >
                                        <SearchPanel visible={true} className={"search-panel"} />
                                        <Column dataField="_descricao" caption="Descrição" />
                                        <Column dataField="_codigo" />
                                    </TreeList>
                                </ScrollView>
                            </div>
                        </ScrollView>
                    </Item>
                </TabPanel>
            </div>
            <TabPanel>

                <Item
                    title={"Anotações"}
                    key={1}
                >
                    <Toolbar></Toolbar>
                    <div className="detail-record">

                        {
                            itemSelected.length > 0 && (
                                <div className="detail-record">

                                    {/* <DataGrid
                                        dataSource={itemSelected}
                                    >
                                        <Toolbar>
                                            <Item icon={"save"} />
                                        </Toolbar>
                                        <DatagridColumn dataField={"_Descricao"} caption={"Descrição"} />
                                        <DatagridColumn dataField={"_codigo"} />
                                    </DataGrid> */}
                                    <Lookup
                                        dataSource={[itemSelected[0]._descricao]}
                                        value={itemSelected[0]._descricao}
                                    ></Lookup>
                                </div>
                            )
                        }

                        {/* <div className="btn-tabPanel">


                        <div className="buttons-tabpanel">
                            <Button
                                icon="save"
                                hint="Gravar"
                                type="normal"
                                // onClick={refreshDetailEntity}
                            />
                        </div>

                    </div> */}


                        <Form
                            colCount={4}
                            formData={states}
                            // onFieldDataChanged={(item) => {
                            //     console.log(item)
                            // }}
                            // validationGroup={`groupName${props.id}`}
                            // labelLocation="top"
                            showColonAfterLabel={true}
                            // minColWidth={200}
                            alignItemLabelsInAllGroups={true}
                            alignItemLabels={true}
                        >
                            <GroupItem
                                // colCount={props.groupItemColSpan}
                                // colSpan={props.groupItemColCount}
                                colCount={4}
                                colSpan={4}
                                // cssClass={'group-item'}
                                // colCountByScreen={true}
                                itemType={'group'}
                                showClearButton={true}
                                icon={'folder'}
                                cssClass={'group-item'}
                            >


                                <SimpleItem

                                    colSpan={4}
                                    // caption={field.fieldCaption} 
                                    dataField={"_texto"}
                                    render={() => {
                                        return (
                                            <HtmlEditor
                                                onValueChanged={(item) => {
                                                    states._texto = item?.value
                                                }}
                                                height={300}
                                            >
                                                <MediaResizing enabled={true} />
                                                <Toolbar multiline={true}>
                                                    <Item name="undo" />
                                                    <Item name="redo" />
                                                    <Item name="separator" />
                                                    <Item
                                                        name="size"
                                                        acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']}
                                                        defaultValue={'12pt'}
                                                    />
                                                    <Item
                                                        name="font"
                                                        acceptedValues={['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']}
                                                        defaultValue={'Arial'}
                                                    />
                                                    <Item name="separator" />
                                                    <Item name="bold" />
                                                    <Item name="italic" />
                                                    <Item name="strike" />
                                                    <Item name="underline" />
                                                    <Item name="separator" />
                                                    <Item name="alignLeft" />
                                                    <Item name="alignCenter" />
                                                    <Item name="alignRight" />
                                                    <Item name="alignJustify" />
                                                    <Item name="separator" />
                                                    <Item name="orderedList" />
                                                    <Item name="bulletList" />
                                                    <Item name="separator" />
                                                    <Item
                                                        name="header"
                                                        acceptedValues={[false, 1, 2, 3, 4, 5]}
                                                    />
                                                    <Item name="separator" />
                                                    <Item name="color" />
                                                    <Item name="background" />
                                                    <Item name="separator" />
                                                    <Item name="link" />
                                                    <Item name="image" />
                                                    <Item name="separator" />
                                                    <Item name="clear" />
                                                    <Item name="codeBlock" />
                                                    <Item name="blockquote" />
                                                    <Item name="separator" />
                                                    <Item name="insertTable" />
                                                    <Item name="deleteTable" />
                                                    <Item name="insertRowAbove" />
                                                    <Item name="insertRowBelow" />
                                                    <Item name="deleteRow" />
                                                    <Item name="insertColumnLeft" />
                                                    <Item name="insertColumnRight" />
                                                    <Item name="deleteColumn" />
                                                </Toolbar>
                                            </HtmlEditor>
                                        )
                                    }}
                                    editorOptions={{
                                        valueChangeEvent: ["keyup", "paste"]
                                    }}
                                >
                                    <Label
                                        text={"Observações"}
                                        visible={false}
                                    // location="left"
                                    />
                                </SimpleItem>

                                    <ButtonItem
                                        horizontalAlignment="left"
                                        buttonOptions={buttonOptions}
                                    />

                                </GroupItem>
                        </Form>
                    </div>
                </Item>
            </TabPanel>
        </div>
    )
}

export default TarefaHistoricoForm;