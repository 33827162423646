import { useEffect, useState, useCallback } from "react";
import { applicationID, relationId } from "../../api/ApplicationID";
import { useRequestsApi } from "../../Context/RequestsApi"
import { httpRequest } from "../../functions";
import TreeList, { Editing, Column, HeaderFilter, Lookup, RowDragging, Selection, Sorting, Scrolling} from 'devextreme-react/tree-list';
import "./style.css"
import CheckBox from 'devextreme-react/check-box';
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ScrollView } from "devextreme-react";
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { LoadPanel } from 'devextreme-react/load-panel';
import Loading from "../../CardComponents/Loading"


export default function OcorrenciaCustomForm(props) {
    const { requestsApi } = useRequestsApi()
    const [change, setChange] = useState()
    const [data, setData] = useState({
        allowReordering: false
    })
    const [ocorrencia, setocorrencia] = useState([])
    const [lookupState, setLookupState] = useState()
    const [loadingState, setLoadingState] = useState(false);

    function UpdateData(value) {  
        const convertTable = {
            false: 0,
            true: 1
        }

        if (typeof value[0]?.data?._promessa === 'boolean') {
            value[0].data._promessa = convertTable[value[0].data._promessa] 
        }
        if (typeof value[0]?.data?._suspendercobranca === 'boolean') {
            value[0].data._suspendercobranca = convertTable[value[0].data._suspendercobranca];
        }
        
        httpRequest("POST",
            requestsApi.gravarTreeView,
            requestsApi.basicAuth,
            {
                "applicationId": applicationID,
                "relationId": relationId,
                "entityName": props.parameters,
                "changes": value
            })
            .then((sender) => {      
                const senderInsertRecordFromJson = JSON.parse(sender);
                notify(senderInsertRecordFromJson.mensagem, "success", 500);
                setocorrencia(senderInsertRecordFromJson.masterEntity[0].records)
            })
            .catch((error) => {
                console.log(error)
            }) 
    }

    useEffect(() => {
        setLoadingState(true);
        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
            "lookUpEntities": [
                {
                    "lookUpEntity": "_pontuacao_contato",
                },
                {
                    "lookUpEntity": "_etapaFunilCobranca", 
                },
                {
                    "lookUpEntity": "_statusTarefa", 
                },
                {
                    "lookUpEntity": "_tipoTarefa", 
                },
                {
                    "lookUpEntity": "_categoriaTarefa", 
                },
                {
                    "lookUpEntity": "_tipoOcorrencia",
                }
            ]
        })
        .then((sender) => {
            const resultGetLookupTableJson = JSON.parse(sender);
            console.log(resultGetLookupTableJson);
            setLookupState(resultGetLookupTableJson)

            httpRequest("POST", requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": props.parameters,
                "fieldName": "*",
                "expressao": "*",
                "applicationId": applicationID,
            })
            .then((sender) => {
                const senderInsertRecordFromJson = JSON.parse(sender);
                console.log(senderInsertRecordFromJson);
                for(let c = 0; c <= senderInsertRecordFromJson.masterEntity[0].records.length - 1; c++){
                    if(senderInsertRecordFromJson.masterEntity[0].records[c]?._promessa === null){
                        senderInsertRecordFromJson.masterEntity[0].records[c]._promessa = 0
                    }
                }
                setocorrencia(senderInsertRecordFromJson.masterEntity[0].records)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoadingState(false);
            })
        })
        .catch((error) => {
            console.log(error)
        })

    }, [])

    function onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        const sourceIndex = ocorrencia.indexOf(sourceData);
        let ocorrenciacontent = ocorrencia
        let ocorrenciadata
        setocorrencia([])

        if (e.dropInsideItem) {
            sourceData = { ...sourceData, _parentid: visibleRows[e.toIndex].key };
            ocorrenciadata = [
                ...ocorrenciacontent.slice(0, sourceIndex),
                sourceData,
                ...ocorrenciacontent.slice(sourceIndex + 1),
            ];
            // e.fromIndex > e.toIndex ? ocorrenciadata.splice(sourceIndex + 1, 1) : ocorrenciadata.splice(sourceIndex, 1)
            console.log(ocorrenciadata)
            UpdateData([{ data: sourceData, key: sourceData.id, type: "update" }])
            setocorrencia(ocorrenciadata)
        } else {
            const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
            if (targetData && e.component.isRowExpanded(targetData.id)) {
                sourceData = { ...sourceData, _parentid: targetData.id };
                targetData = null;
            } else {
                const headId = targetData ? targetData._parentid : -1;
                if (sourceData._parentid !== headId) {
                    sourceData = { ...sourceData, _parentid: headId };
                }
            }
            ocorrenciadata = [...ocorrenciacontent.slice(0, sourceIndex), ...ocorrenciacontent.slice(sourceIndex + 1)];

            const targetIndex = ocorrenciacontent.indexOf(targetData) + 1;
            ocorrenciadata = [...ocorrenciacontent.slice(0, targetIndex), sourceData, ...ocorrenciacontent.slice(targetIndex)];
            // const soIndex = e.fromIndex > e.toIndex ? ocorrenciacontent.indexOf(sourceData) - 1 : ocorrenciacontent.indexOf(sourceData);
            e.fromIndex > e.toIndex ? ocorrenciadata.splice(sourceIndex + 1, 1) : ocorrenciadata.splice(sourceIndex, 1)

            console.log(ocorrenciadata)
            setocorrencia(ocorrenciadata)
        }
    }
    function onAllowReorderingChanged(args) {
        setData({
            allowReordering: args.value,
        });
    }
    function onDragChange(e) {
        const visibleRows = e.component.getVisibleRows();
        const sourceNode = e.component.getNodeByKey(e.itemData.ID);
        let targetNode = visibleRows[e.toIndex].node;

        console.log(e)
        while (targetNode && targetNode?.data) {
            if (targetNode?.data?.ID === sourceNode?.data?.ID) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode?.parent;
        }
    }

    const learningOptions = {
        text: "salvar alterações",
        icon: "save",
        hoverStateEnabled: true,
        height: 35,
        stylingMode: "text",
        disabled: !!change,
        onClick: () => {

        }
    }

    function setChanges(change) {
        console.log(change)
        setChange(change)
    }

    const addButtonSettings = {
        name: "addRowButton",
        cssClass: "toolbar-item__add--left", 
    }

    const saveButtonSettings = {
        name: "saveButton",
        cssClass: "toolbar-item__save--left",        
    }

    const revertButtonSettings = {
        name: "revertButton",
        cssClass: "toolbar-item__revert--left",               
    }

    return (
        <div className="div-tree-list">
            {loadingState ? (
                <Loading />
            ) : (
                <ScrollView width={"100%"} height={"100%"} useNative={true}>
                <TreeList
                    className="tree-list-upf"
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    parentIdExpr="_parentid"
                    // autoExpandAll={true}
                    id="ocorrencia"
                    keyExpr="id"
                    dataSource={ocorrencia}
                    rootValue={-1}
                    focusedRowEnabled={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    columnHidingEnabled={true}
                    toolbar={{
                        items: [addButtonSettings, saveButtonSettings, revertButtonSettings]
                    }}
                    // onEditingChange={e=>{console.log(e); console.log(ocorrencia)}}
                    onSaved={e => UpdateData(e.changes)}
                    repaintChangesOnly={true}
                    onEditorPreparing={e => {
                        if (e.editorName === "dxCheckBox") {
                            e.editorOptions.value = e.value === null ? false : e.value
                        }
                    }}
                    onInitNewRow={(e) => {
                        e.data._promessa = false;
                        e.data._suspendercobranca = false;
                    }}
                    // columnHidingEnabled={true}
                    // columnMinWidth={50}
                    // contextMenuEnabled={true}
                    // columnResizingMode={"widget"}
                    // allowColumnReordering={true}
                    // allowColumnResizing={true}
                >
                    <HeaderFilter visible />

                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        mode="batch"
                        confirmDelete={true}
                        useIcons={true}
                    />

                    {/* <Selection mode="multiple" selectAllMode="allPages" /> */}

                    

                    {/* <Sorting
                        mode="multiple"
                    /> */}

                    <RowDragging
                        // onDragChange={onDragChange}
                        onReorder={(e) => { onReorder(e) }}
                        allowDropInsideItem={data.allowReordering}
                        allowReordering={data.allowReordering}
                        showDragIcons={data.allowReordering}
                    />
                    <Column dataField="_descricao" caption="Descrição" />
                    <Column dataField="_codigo" caption="Código" />

                    <Column
                        dataField="_tipoocorrenciaid"
                        caption="Tipo ocorrência"
                    >
                        <Lookup
                            dataSource={lookupState?._tipoOcorrencia}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>

                    <Column
                        dataField="_pontuacaocontatoid"
                        caption="Pontuação contato"
                    >
                        <Lookup
                            dataSource={lookupState?._pontuacao_contato}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>

                    <Column
                        dataField="_etapafunilcobrancaid"
                        caption="Etapa funil"
                    >
                        <Lookup
                            dataSource={lookupState?._etapaFunilCobranca}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>
                    
                    <Column dataField="_repique" dataType="date" caption="Repique"/>
                    <Column 
                        dataField="_promessa" 
                        dataType="boolean" 
                        caption="Promessa"
                        // setCellValue={(rowData, value) => {
                        //     var newValue = (value == true ? 1 : 0)
                        //     this.defaultSetCellValue(rowData, newValue)
                        // }}
                        setCellValue={(newData, value, currentRowData) => {
                            value ? newData._promessa = 1 : newData._promessa = 0;
                        }}
                    />
                    <Column 
                        dataField="_suspendercobranca"
                        dataType="boolean"
                        caption="Suspender cobrança"
                        //calculateCellValue={}
                        setCellValue={(newData, value, currentRowData) => {
                            value 
                            ? newData._suspendercobranca = 1 
                            : newData._suspendercobranca = 0;
                        }}
                    />
                    <Column
                        dataField="_statustarefaid"
                        caption="Status tarefa"
                    >
                        <Lookup
                            dataSource={lookupState?._statusTarefa}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>

                    <Column
                        dataField="_tipotarefaid"
                        caption="Tipo tarefa"
                    >
                        <Lookup
                            dataSource={lookupState?._tipoTarefa}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>

                    <Column
                        dataField="_categoriatarefaid"
                        caption="Categoria tarefa"
                    >
                        <Lookup
                            dataSource={lookupState?._categoriaTarefa}
                            valueExpr="id"
                            displayExpr="_descricao"
                        />               
                    </Column>
                </TreeList>
                <div className="check-box-allow">
                    <CheckBox
                        value={data.allowReordering}
                        text="Permitir reagrupar itens"
                        onValueChanged={onAllowReorderingChanged}
                    />
                </div>
            </ScrollView>
            )
            }
    </div>)
}