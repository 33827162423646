const { createContext, useContext, useState } = require("react");

const RequestsApi = createContext();

export const RequestsApiProvider = ({ children }) => {
  const [requestsApi, setRequestsApi] = useState(JSON.parse(sessionStorage.getItem("requestApiRevolution")))

  return (
    <RequestsApi.Provider value={{ requestsApi, setRequestsApi }}>
      {children}
    </RequestsApi.Provider>
  );
};

export const useRequestsApi = () => useContext(RequestsApi);