const { createContext, useContext, useState } = require("react");

const IndexMultiViewItemsGerarAcordo = createContext();

export const IndexMultiViewItemsGerarAcordoProvider = ({ children }) => {
  const [indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo] = useState(0);

  return (
    <IndexMultiViewItemsGerarAcordo.Provider value={{ indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo }}>
      {children}
    </IndexMultiViewItemsGerarAcordo.Provider>
  );
};

export const useIndexMultiViewItemsGerarAcordo = () => useContext(IndexMultiViewItemsGerarAcordo);