import 'devextreme-react/text-area';
import { httpRequest } from '../../../functions'
import {useState, useEffect, useRef, Suspense, useCallback, memo} from 'react'
import React from 'react'
import {
    applicationID
    ,relationId
} from "../../../api/ApplicationID"
import Loading from "../../../CardComponents/Loading"
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import RecordFormPopup from '../../RecordForm/RecordFormPopup';
import { Toast } from 'devextreme-react/toast';
import TabPanelDetailEntitys from '../../RecordForm/TabPanelDetailEntity';
import QueryFormPopup from "../../QueryForm/QueryFormPopup";
import './style.css';
import  { v4 as uuid } from 'uuid'
import ValidationSummary from 'devextreme-react/validation-summary';
import {Lookup, DropDownOptions} from "devextreme-react/lookup";
import DropDownBox from 'devextreme-react/drop-down-box';
import ScrollView from 'devextreme-react/scroll-view';
import TreeView from 'devextreme-react/tree-view';
import List from 'devextreme-react/list';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Template } from 'devextreme-react/core/template';
import GetFormComponents from "../../../functions/functionsMapForm/GetFormComponents"
import { useTabPanelItems } from '../../../Context/TabPanelItems'
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useRequestsApi } from "../../../Context/RequestsApi"
import {useMainMenuJson} from "../../../Context/MainMenuJson"
import { useProximaFichaDados } from '../../../Context/ProximaFichaDados';
import { convertDate } from '../../../functions/convertDate';
import { validatePermission } from '../../../functions/validatePermission'
import { orderGroups } from '../../../functions/orderGroups'
import UpfPopupForm from '../../../CardComponents/UpfPopupForm';
import UpfPopup from '../../../CardComponents/UpfPopup';
import HtmlFormPopup from '../../HtmlFormPopup';
import UpfPopupCustomForm from '../../../CardComponents/UpfPopupCustomForm';
import RecordForm from '../../RecordForm';
import UpfPopupConfirmContextMenu from '../../../CardComponents/UpfPopupConfirmContextMenu';

const TelecobrancaForm = (props) => {
    const {requestsApi} = useRequestsApi()
    const {mainMenuJson, setMainMenuJson} = useMainMenuJson();

    const [objectPermissionsState, setObjectPermissionsState] = useState()

    const [senderFieldNamesCheckBox, setSenderFieldNamesCheckBox] = useState([])

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState()

    const [dataEntityEditedFields, setDataEntityEditedFields] = useState({})

    const [clearTabRuleState, setClearTabRuleState] = useState()

    const _toolbarRef = useRef(null)

    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null,
        caption: null
    })

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }



    const popups = {
        isVisible: false,
        message: 'error',
    };
    
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const {setIndexTabPanelMain} = useIndexTabPanelMain()
    const {tabPanelItems, setTabPanelItems} = useTabPanelItems();
    const [dataEntity, setDataEntity] = useState()
    const [popup, setPopup] = useState(popups);
    const [modeEditForm, setModeEditForm] = useState(false)
    const [dataEntityNull, setDataEntityNull] = useState(false)
    const [insertIdValue, setInsertIdValue] = useState()
    const [dataLookup, setDataLookup] = useState()
    const [idValue, setIdValue] = useState()
    const [dataEntityDetailEntity, setDataEntityDetailEntity] = useState()
    const [formDataJson, setFormDataJson] = useState({})
    const [queryFormEntityName, setQueryFormEntityName] = useState()
    const [refreshRecord, setRefreshRecord] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState({isVisible: false, message: "Atenção"});
    const [foreignFieldSelectedItem, setForeignFieldSelectedItem] = useState()
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()

    const [condicionalVisibleState, setCondicionalVisibleState] = useState(true)

    const [loadedContentState, setLoadedContentState] = useState()

    
    const [IDValueAttachDetailsEntity, setIDValueAttachDetailsEntity] = useState();

    const [detailAttachEntityName, setDetailAttachEntityName] = useState()
    
    const [detailAttachCaptionName, setDetailAttachCaptionName] = useState()

    const [entityStructureState, setEntityStructureState] = useState()
    const [constextMenuMasterEntityPermission, setConstextMenuMasterEntityPermission] = useState("")

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()
    const [htmlFormPopupDataSourceState, setHtmlFormPopupDataSourceState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    const [states, setStates] = useState({
        loadedContentState: false,
        entityStructure: null,
        dataEntity: "",
        popup: "",
        dataEntityDetailEntity: null
    })

    // useEffect(() => {
    //     if(states.entityStructure){
    //         setEntityStructureState(states.entityStructure)
    //     }

    // }, [states.entityStructure])

    

    //pega os as ancoras dos grupos do structure e monta o menu lateral e colocar funçao dentro de função
    // const getMenuItemChildr = () => {} 

    // const getMenuItems = async () => {
    //     let newArrayMenu = []
    //     for(let c = 0; c <= entityStructureState.length - 1; c++) {
    //         console.log(entityStructureState[c].recordFormProperties[0].recordFormComponents)
    //         for(let i = 0; i <= entityStructureState[c].recordFormProperties[0].recordFormComponents.length - 1; i++) {
    //             console.log(entityStructureState[c].recordFormProperties[0].recordFormComponents[i])
    //             newArrayMenu.push(
    //                 {
    //                     "text": entityStructureState[c].recordFormProperties[0].recordFormComponents[i].caption,
    //                     "icon": "folder",
    //                     "href": entityStructureState[c].recordFormProperties[0].recordFormComponents[i].anchor
    //                 })
    //                 console.log(newArrayMenu)
    //         }
    //     }

    //     await
    //     setMainMenuJson(newArrayMenu)
    // }

    const getClienteId = (dataEntity) => {
        let clienteId = null
        console.log(dataEntity)
        for(let c = 0; c <= dataEntity.length - 1; c++) {
            if(dataEntity[c]?.masterEntity?.[0]?.records?.[0]?._clienteid) {
                clienteId = dataEntity[c].masterEntity[0].records[0]._clienteid
                setProximaFichaDados(
                    {...proximaFichaDados,
                    clienteId: clienteId
                })
            }
        }
    }

    const montarMenuDeContexto = (estrutura) => {
        //checa os items do contextMenu Opções da master

        const contextMenuAllowedItems = []

        for(let i = 0; i <= estrutura.length - 1; i++){
            const contextMenu = estrutura?.[i]?.recordFormProperties?.[0]?.contextMenu
            if(contextMenu){
                for(let c = 0; c <= contextMenu.length - 1; c++){
                    let permissoesItem = validatePermission(contextMenu[c]?.objectid)
                    contextMenu[c]["_execute"] = permissoesItem?._execute
                    contextMenu[c]["entityName"] = estrutura?.[i]?.masterEntity?.entityName
                    // if(permissoesItem?._execute === 0){
                    //     contextMenu[c]["disabled"] = true
                    // }
                    if(permissoesItem?._show === 1){
                        contextMenuAllowedItems.push(contextMenu[c])
                    }
                }
            }
        }

        setConstextMenuMasterEntityPermission(contextMenuAllowedItems)

        //-----------------------------------------------
    }

    const [arrayMasterEntitysIds, setArrayMasterEntitysIds] = useState()

    useEffect(() => {
        console.log(props)
        if(props.templateId !== sessionStorage.getItem("templateId")){
            sessionStorage.setItem("templateId", props.templateId)
            const mainMenuJsonTemp = mainMenuJson
            console.log(mainMenuJsonTemp)
            if(mainMenuJsonTemp?.length > 0){
                for(let c = 0; c <= mainMenuJsonTemp.length - 1; c++){
                    if(mainMenuJsonTemp[c]?.criadoNoGetTelecobrancaViews){
                        delete mainMenuJsonTemp[c]
                    }
                }
                console.log(mainMenuJsonTemp)
                setMainMenuJson(mainMenuJsonTemp)
            }
            httpRequest("POST", 
            requestsApi.getTelecobrancaViews, requestsApi.basicAuth, 
            {
                applicationId: applicationID,
                // relationid: relationId,
                templateTelecobrancaId: props.templateId,
            }
            ).then((sender)=>{
                const senderGetTelecobrancaViewsJson = JSON.parse(sender)
                const newItemsArray = []
                for(let c = 0; c <= senderGetTelecobrancaViewsJson.length - 1; c++){
                    const itemAtual = {
                        text : senderGetTelecobrancaViewsJson[c].caption,
                        path : senderGetTelecobrancaViewsJson[c].viewname,
                        icon : "detailslayout",
                        entityName: null,
                        badge : null,
                        parameters : senderGetTelecobrancaViewsJson[c].viewsId,
                        permissionName : null,
                        items : null,
                        criadoNoGetTelecobrancaViews: true
                    }
                    newItemsArray.push(itemAtual)
                }
                console.log(newItemsArray)
                console.log(mainMenuJson.concat(newItemsArray))
                setMainMenuJson(mainMenuJson.concat(newItemsArray))
                // setStates({...states, loadedContentState: true})
            })
            .catch((error)=>{
                console.log(error)
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
        }
                                       
    }, [props.templateId])


    useEffect(() => {
        if(props.IDValue) {
            setIdValue(props.IDValue)
            if(props.dataEntity && !arrayMasterEntitysIds){
                const arrayIds = []
                for(let c = 0; c <= props.dataEntity.length - 1; c++){
                    for(let i = 0; i <= props.dataEntity[c].detailEntity.length - 1; i++){
                        arrayIds.push({
                            masterEntityId: props.dataEntity[c].masterEntity[0].records[0].id,
                            entityName: props.dataEntity[c].masterEntity[0].entityName,
                            detailEntityName: props.dataEntity[c].detailEntity[i].entityName
                        })
                    }
                }
                setArrayMasterEntitysIds(arrayIds)
            }
        }

        else {
           if(dataEntity) {
                setIdValue(dataEntity.masterEntity[0].records[0].id)
           } 
        }


        // if(!props.templateId){
        //     setMessageError("Template telecobrança não informado na configuração do credor!")
        //     setPopup({
        //         ...popup
        //             ,isVisible: true
        //             ,message: "Atenção!" 
        //     })
        // }

        if(props.modeEditOn && !entityStructureState) {
            modeEditOn()
        }
        if( refreshRecord) {
            setRefreshRecord(false)
            onClickRefreshButton()
        }
        if(props.selectedItens) {
            modeEditOn()
        }
        // if (entityStructureState) {
        //     if(props.IDValue && dataEntity) {
        //         // setStates({...states, loadedContentState: true})
        //         getClienteId()
        //     }
        //     else {
        //         // setStates({...states, loadedContentState: true})
        //     }
        //     // chamando a função para montar o menu a partir do structure
        //     // getMenuItems()
        // } 
        if(!entityStructureState && !dataEntity){
            // if(props.entityName || props.templateId) {
                if(props.IDValue) {
                    if(!props.masterEntity){
                        if(props.templateId) {
                            httpRequest("POST", 
                                requestsApi.getTelecobrancaStructure, requestsApi.basicAuth,
                                {
                                    "templateTelecobrancaId": props.templateId,
                                    "applicationId": applicationID
                                }
                            ).then((sender) => {
                                const senderEntityStructure = JSON.parse(sender)

                                montarMenuDeContexto(senderEntityStructure)
                                let entidadesLookups = []
                                
                                // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                                if(senderEntityStructure?.length > 0){
                                    setEntityStructureState(orderGroups(senderEntityStructure))
        
                                    for(let c = 0; c <= senderEntityStructure.length - 1; c++){
                                        setObjectPermissionsState(validatePermission(senderEntityStructure?.[c]?.masterEntity?.objectid))
                                        if(senderEntityStructure[c].lookUpEntities) {
                                            for(let i = 0; i <= senderEntityStructure[c].lookUpEntities.length - 1; i++){
                                                entidadesLookups.push(senderEntityStructure[c].lookUpEntities[i])
                                            }
                                        }
                                    }
                                }

                                if(sessionStorage.getItem("lookupsEntities")){
                                    const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                                    let temLookupNova = false
                                    let lookupsNovas = []

                                    for(let c = 0; c <= entidadesLookups.length - 1; c++){
                                        if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                                            console.log(entidadesLookups[c])
                                            lookupsEntitiesJson.push(entidadesLookups[c])
                                            temLookupNova = true
                                            lookupsNovas.push(entidadesLookups[c])
                                        }
                                    }

                                    if(temLookupNova){
                                        sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                                        httpRequest("POST", 
                                        requestsApi.getLookupTable, requestsApi.basicAuth,
                                                    {
                                                        "lookUpEntities": lookupsNovas
                                                    }
                                        )
                                        .then((sender)=>{
                                            const senderDataLookup = JSON.parse(sender)

                                            if(JSON.parse(sessionStorage.getItem("dataLookups"))){
                                                const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))?.[0]

                                                const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                                                for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                                    dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                                                }

                                                sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))

                                                setDataLookup(dataLookupsJson)
                                            }
                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                            hideLoadPanel()
                                            setMessageError(`${error}`)
                                            setPopup({
                                                ...popup
                                                    ,isVisible: true
                                                    ,message: "Atenção!" 
                                            })
                                        }) 
                                    }
                                    else{
                                        setDataLookup(JSON.parse(sessionStorage.getItem("dataLookups"))?.[0])
                                    }
                                }
                                else{
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))

                                    httpRequest("POST", 
                                        requestsApi.getLookupTable, requestsApi.basicAuth,
                                                    {
                                                        "lookUpEntities": entidadesLookups
                                                    }
                                        ).then((sender)=>{
                                            const senderDataLookup = JSON.parse(sender)
                                            sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                                            sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
                                            setDataLookup(senderDataLookup)
                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                            hideLoadPanel()
                                            setMessageError(`${error}`)
                                            setPopup({
                                                ...popup
                                                    ,isVisible: true
                                                    ,message: "Atenção!" 
                                            })
                                        }) 
                                }
                                        
                            })
                            .catch((error)=>{
                                console.log(error)
                                hideLoadPanel()
                                if(props.abrirFichaDiscador){
                                    props.setStates({...states, 
                                        isVisiblePopupErrorAbrirFicha: true,
                                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                    })
                                }
                                else{
                                    setMessageError(`${error}`)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!" 
                                    })
                                } 
                            })
                        } 
                        else {
                            
                            //se n tiver templateId puxar estrutura do _titulo e _cadastro
                            httpRequest("POST", requestsApi.getEntityStructure, requestsApi.basicAuth, 
                                {
                                    "entityName": "_titulo",
                                    "applicationId": applicationID
                                }
                            )
                            .then((sender) => {
                                const structureTituloJson = JSON.parse(sender)

                                httpRequest("POST", requestsApi.getEntityStructure, requestsApi.basicAuth, 
                                {
                                    "entityName": "_cadastro",
                                    "applicationId": applicationID
                                }
                                )
                                .then((sender) => {
                                    const structureCadastroJson = JSON.parse(sender)
                                    const senderEntityStructure = [structureTituloJson[0], structureCadastroJson[0]]



                                    montarMenuDeContexto(senderEntityStructure)
                                    let entidadesLookups = []
                                    
                                    // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                                    if(senderEntityStructure?.length > 0){
                                        setEntityStructureState(orderGroups(senderEntityStructure))
            
                                        for(let c = 0; c <= senderEntityStructure.length - 1; c++){
                                            setObjectPermissionsState(validatePermission(senderEntityStructure?.[c]?.masterEntity?.objectid))
                                            if(senderEntityStructure[c].lookUpEntities) {
                                                for(let i = 0; i <= senderEntityStructure[c].lookUpEntities.length - 1; i++){
                                                    entidadesLookups.push(senderEntityStructure[c].lookUpEntities[i])
                                                }
                                            }
                                        }
                                    }

                                    if(sessionStorage.getItem("lookupsEntities")){
                                        const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                                        let temLookupNova = false
                                        let lookupsNovas = []

                                        for(let c = 0; c <= entidadesLookups.length - 1; c++){
                                            if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                                                console.log(entidadesLookups[c])
                                                lookupsEntitiesJson.push(entidadesLookups[c])
                                                temLookupNova = true
                                                lookupsNovas.push(entidadesLookups[c])
                                            }
                                        }

                                        if(temLookupNova){
                                            sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                                            httpRequest("POST", 
                                            requestsApi.getLookupTable, requestsApi.basicAuth,
                                                        {
                                                            "lookUpEntities": lookupsNovas
                                                        }
                                            )
                                            .then((sender)=>{
                                                const senderDataLookup = JSON.parse(sender)

                                                if(JSON.parse(sessionStorage.getItem("dataLookups"))){
                                                    const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))?.[0]

                                                    const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                                                    for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                                        dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                                                    }

                                                    sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))

                                                    setDataLookup(dataLookupsJson)
                                                }
                                            })
                                            .catch((error)=>{
                                                console.log(error)
                                                hideLoadPanel()
                                                setMessageError(`${error}`)
                                                setPopup({
                                                    ...popup
                                                        ,isVisible: true
                                                        ,message: "Atenção!" 
                                                })
                                            }) 
                                        }
                                        else{
                                            setDataLookup(JSON.parse(sessionStorage.getItem("dataLookups"))?.[0])
                                        }
                                    }
                                    else{
                                        sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))

                                        httpRequest("POST", 
                                            requestsApi.getLookupTable, requestsApi.basicAuth,
                                                        {
                                                            "lookUpEntities": entidadesLookups
                                                        }
                                            ).then((sender)=>{
                                                const senderDataLookup = JSON.parse(sender)
                                                sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                                                sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
                                                setDataLookup(senderDataLookup)
                                            })
                                            .catch((error)=>{
                                                console.log(error)
                                                hideLoadPanel()
                                                setMessageError(`${error}`)
                                                setPopup({
                                                    ...popup
                                                        ,isVisible: true
                                                        ,message: "Atenção!" 
                                                })
                                            }) 
                                    }

                                })
                                .catch((error)=>{
                                    hideLoadPanel()
                                    if(props.abrirFichaDiscador){
                                        props.setStates({...states, 
                                            isVisiblePopupErrorAbrirFicha: true,
                                            messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                        })
                                    }
                                    else{
                                        if(error.data.error) {
                                            setMessageError(error.data.error)
                                            setPopup({
                                                ...popup
                                                    ,isVisible: true
                                                    ,message: "Atenção!" 
                                            })
                                        }
                                        else {
                                            setMessageError(error.statusText)
                                            setPopup({
                                                ...popup
                                                    ,isVisible: true
                                                    ,message: "Atenção!" 
                                            })
                                        } 
                                    } 
                                })

                                             
                            })
                            .catch((error)=>{
                                hideLoadPanel()
                                if(props.abrirFichaDiscador){
                                    props.setStates({...states, 
                                        isVisiblePopupErrorAbrirFicha: true,
                                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                    })
                                }
                                else{
                                    if(error.data.error) {
                                        setMessageError(error.data.error)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    }
                                    else {
                                        setMessageError(error.statusText)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    } 
                                } 
                            })
                        }
                        //dataentity vindo por props
                        setDataEntityDetailEntity(props.dataEntity.detailEntity)
                        setDataEntity(props.dataEntity)
                        getClienteId(props.dataEntity)
                        if(resetFormData.current){
                            resetFormData.current.instance.repaint()
                            console.log(resetFormData)
                        }
                        setLoadedContentState(true)
                    } 
                    else if (props.masterEntity) {
                        httpRequest('POST', requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
                        {
                            "templateTelecobrancaId": props.templateId,
                            "applicationId": applicationID
                        })
                        .then(sender=>{
                            const resulEntityStructure = JSON.parse(sender);
                            montarMenuDeContexto(resulEntityStructure)
                            
                            // setStates({...states, entityStructure: orderGroups(resulEntityStructure)})
                            setEntityStructureState(orderGroups(resulEntityStructure))

                        
                            if(resulEntityStructure[0].lookUpEntities) {
                                console.log(resulEntityStructure[0].lookUpEntities)
                                httpRequest("POST", 
                                requestsApi.getLookupTable, requestsApi.basicAuth,
                                {
                                    "lookUpEntities": resulEntityStructure[0].lookUpEntities
                                }
                                ).then((sender)=>{
                                    const senderDataLookup = JSON.parse(sender)
                                    setDataLookup(senderDataLookup)
                                })
                                .catch((error)=>{
                                    if(error.data.error) {
                                        hideLoadPanel()
                                        setMessageError(error.data.error)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    }
                                    else {
                                        hideLoadPanel()
                                        setMessageError(error.statusText)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    } 
                                })
                            } 
                        })
                        .catch((error) => {
                            hideLoadPanel()
                                if(props.abrirFichaDiscador){
                                    props.setStates({...states, 
                                        isVisiblePopupErrorAbrirFicha: true,
                                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                    })
                                }
                                else{
                                    if(error.data.error) {
                                        setMessageError(error.data.error)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    }
                                    else {
                                        setMessageError(error.statusText)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    } 
                                } 
                        })
                        //dataentity vindo por pros
                        setDataEntityDetailEntity(props.dataEntity.detailEntity)
                        setDataEntity(props.dataEntity)
                        getClienteId(props.dataEntity)

                    }
                } 
                else if (!props.IDValue) {
                    if(!props.masterEntityId) {
                        httpRequest("POST", 
                        requestsApi.getTelecobrancaStructure, requestsApi.basicAuth,
                            {
                                "templateTelecobrancaId": props.templateId,
                                "applicationId": applicationID
                            })
                        .then((sender) => {
                            const senderEntityStructure = JSON.parse(sender)

                            montarMenuDeContexto(senderEntityStructure)
                            
                            // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                            setEntityStructureState(orderGroups(senderEntityStructure))


                            if(senderEntityStructure[0].lookUpEntities) {
                                httpRequest("POST", 
                                requestsApi.getLookupTable, requestsApi.basicAuth, 
                                {
                                    "lookUpEntities": senderEntityStructure[0].lookUpEntities
                                }
                                ).then((sender)=>{
                                    const senderDataLookup = JSON.parse(sender)
                                    setDataLookup(senderDataLookup)
                                    // setStates({...states, loadedContentState: true})
                                })
                                .catch((error)=>{
                                    if(error.data.error) {
                                        hideLoadPanel()
                                        setMessageError(error.data.error)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    }
                                    else {
                                        hideLoadPanel()
                                        setMessageError(error.statusText)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    } 
                                })
                            }
                            else {
                                // setStates({...states, loadedContentState: true})
                            }
                            hideLoadPanel()              
                        })
                        .catch((error)=>{
                            console.log(error)
                            hideLoadPanel()
                            if(props.abrirFichaDiscador){
                                props.setStates({...states, 
                                    isVisiblePopupErrorAbrirFicha: true,
                                    messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                })
                            }
                            else{
                                if(error.data["description"]) {
                                    setMessageError(error.data.error)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!" 
                                    })
                                }
                                else {
                                    hideLoadPanel()
                                    setMessageError(error.statusText)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!" 
                                    })
                                } 
                            } 
                        })
                    } 
                    else if (props.masterEntityId) {
                        httpRequest('POST', requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
                        {
                            "templateTelecobrancaId": props.templateId,
                            "applicationId": applicationID
                        })
                        .then(sender=>{
                            const resulEntityStructure = JSON.parse(sender);

                            montarMenuDeContexto(resulEntityStructure)
                            
                            // setStates({...states, entityStructure: orderGroups(resulEntityStructure)})
                            setEntityStructureState(orderGroups(resulEntityStructure))

                            if(resulEntityStructure[0].lookUpEntities) {
                                httpRequest("POST", 
                                requestsApi.getLookupTable, requestsApi.basicAuth, 
                                {
                                    "lookUpEntities": resulEntityStructure[0].lookUpEntities
                                }
                                ).then((sender)=>{
                                    const senderDataLookup = JSON.parse(sender)
                                    setDataLookup(senderDataLookup)
                                    // setStates({...states, loadedContentState: true})
                                })
                                .catch((error)=>{
                                    if(error.data.error) {
                                        hideLoadPanel()
                                        setMessageError(error.data.error)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    }
                                    else {
                                        hideLoadPanel()
                                        setMessageError(error.statusText)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!" 
                                        })
                                    } 
                                })
                            }
                            else {
                                // setStates({...states, loadedContentState: true})
                            }
                        })
                        .catch((error) => {
                            hideLoadPanel()
                            if(props.abrirFichaDiscador){
                                props.setStates({...states, 
                                    isVisiblePopupErrorAbrirFicha: true,
                                    messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                                })
                            }
                            else{
                                if(error.data.error) {
                                    setMessageError(error.data.error)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!" 
                                    })
                                }
                                else {
                                    setMessageError(error.statusText)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!" 
                                    })
                                } 
                            } 
                        })
                    }
                }

            // }
        }

    }, [])

   


    //-----------FORM---------------//
    const resetFormData = useRef(null);
    //Modo
    const modeEditOn = useCallback(() => {
        setModeEditForm(true)
    },[])

    const modeEditOff = useCallback(() => {
        setModeEditForm(false)
    },[])
    //Funções
    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        if(idValue) {
            httpRequest("POST", 
            requestsApi.updateRecordFromJson, requestsApi.basicAuth, 
                {
                "entityName": entityNameGrid,
                "json": jsonRow,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            .catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
        }
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
            httpRequest("POST", 
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            .catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
    }

    const recordInsertUpdateJson = (e) => {
        const dataEntityItensModificadosObject = dataEntityEditedFields

        dataEntityItensModificadosObject["id"] = dataEntity?.masterEntity?.[0]?.records?.[0]?.id

        e.validationGroup.validate()
        if(e.validationGroup._validationInfo.result.isValid === true) {
            modeEditOff()
            e.event.preventDefault()
            //condição para gravar alterações em um item, já existente, vindo do RecordForm principal
            if(idValue) {
                showLoadPanel()
                httpRequest("POST", 
                requestsApi.updateRecordFromJson, requestsApi.basicAuth, 
                    {
                    "entityName": props.entityName,
                    "json": dataEntityItensModificadosObject,
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    const senderUpdateRecordFromJson = JSON.parse(sender)
                    modeEditOff()  
                    toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
                })
                .catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
                .then(() => hideLoadPanel())
            }
            //condição para gravar um novo item, quando for chamado pelo botão novo no query ou no record
            else if (!props.masterEntityId) {
                modeEditOff()
                showLoadPanel()
                httpRequest("POST", 
                requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                    {
                    "entityName": props.entityName,
                    "json": formDataJson,
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    const senderInsertRecordFromJson = JSON.parse(sender)
                    setInsertIdValue(senderInsertRecordFromJson)// no lugar do consol.log colocar setInsertIdValue
                    modeEditOff()
                    if(props.selectedItens){
                        insertLoteMountJson(senderInsertRecordFromJson)
                        modeEditOff()
                    }
                    if(insertLoteJson.length > 0){
                        httpRequest("POST", 
                        requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                        {
                        "entityName": "_loteitem",
                        "json": insertLoteJson,
                        "applicationId": applicationID,
                        "relationId": relationId
                        }).catch((error)=>{
                            if(error.data.error) {
                                hideLoadPanel()
                                setMessageError(error.data.error)
                                setPopup({
                                    ...popup
                                        ,isVisible: true
                                        ,message: "Atenção!" 
                                })
                            }
                            else {
                                hideLoadPanel()
                                setMessageError(error.statusText)
                                setPopup({
                                    ...popup
                                        ,isVisible: true
                                        ,message: "Atenção!" 
                                })
                            } 
                        })
                    }
                    modeEditOff()
                    toastConfiguration(true, 'success', senderInsertRecordFromJson.mensagem)
                })
                .catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
                .then(() => {
                    setRefreshRecord(true)
                    hideLoadPanel()
                    modeEditOff()
                })
    
            } 
            //condição se o item vier da entidade detalhe e for novo
            else if (props.masterEntityId && !dataEntity) {
                // formDataJson["masterEntity"] = masterEntity
                formDataJson[`${props.keyFieldNameState}`] = props.masterEntityId
                showLoadPanel()
                httpRequest("POST", 
                    requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                             {
                                "entityName": props.entityName,
                                "json": formDataJson,
                                "applicationId": applicationID,
                                "relationId": relationId
    
                            })
                .then((sender) => {
                    const senderInsertRecordFromJson = JSON.parse(sender)
                    setInsertIdValue(senderInsertRecordFromJson)// no lugar do consol.log colocar setInsertIdValue
                    setIdValue(senderInsertRecordFromJson)
                    if(senderInsertRecordFromJson){
                        httpRequest('POST', requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
                            "applicationId": applicationID,
                            "tituloId": props.tituloId
                            // ,"entityName": props.entityName
                            // ,"fieldName": 'id'
                            // ,"expressao": senderInsertRecordFromJson.idValue
                        })
                        .then(senderGetDataEntity=>{
                            const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                            getClienteId(resultGetDataEntity)
                            setDataEntity(resultGetDataEntity)
                            modeEditOff()
                        })
                        .catch((error) => {console.log(error)})
                        .then(() => {
                            modeEditOff()
                        })
                    }
                    modeEditOff()
                    toastConfiguration(true, 'success', senderInsertRecordFromJson.mensagem)
                })
                .catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
                .then(() => hideLoadPanel())
            }
        }
        else {
            setMessageError(e.validationGroup._validationInfo.result.brokenRules[0].message)
            setErrorModalOpen(true)
            e.event.preventDefault()
        }

    }
    // ,[idValue, props.entityName, dataEntity, props.masterEntityId])

    const insertLoteJson = []

    const insertLoteMountJson = (sender) => {
        for(let c = 0; c < props.selectedItens.length; c++){
            // setInsertLoteJson(
            //     [...insertLoteJson, 
            //     {
            //      "loteid": insertIdValue,
            //      "tituloid": props.selectedItens[c].tituloid
            //     }])
            insertLoteJson.push({
                     "loteid": sender.idValue,
                     "tituloid": props.selectedItens[c].tituloid
                    })
        }
        return insertLoteJson
    }

    const recordDeleteJson = useCallback(() => {
        if(idValue){
            httpRequest('POST',
                requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "applicationId": applicationID, 
                "entityName": props.entityName,
                "fieldName": 'id',
                "expressao": idValue,
                "relationId": relationId
                }).then((response)=>{
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    //função para fechr ultima tab criada, precisa pega o item para fazer indeOf
                    //fechar o form atual
                    // closeButtonHandler(tabPanelItems[tabPanelItems.length-1])
                    hidePopupDelete()
                }).catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            }).then(() => {
                })
        }else if(props.masterEntityId){
            httpRequest('POST',
                requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "applicationId": applicationID, 
                "entityName": props.entityName,
                "fieldName": 'id',
                "expressao": idValue,
                "relationId": relationId
                }).then((response)=>{
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    hidePopupDelete()
                }).catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
        }else if(insertIdValue){
            httpRequest('POST',
                requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "applicationId": applicationID, 
                "entityName": props.entityName,
                "fieldName": 'id',
                "expressao": insertIdValue.idValue,
                "relationId": relationId
                }).then((response)=>{
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    hidePopupDelete()
                }).catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            })
        }
        hidePopupDelete()
    },[applicationID, props.entityName, idValue, props.masterEntityId, insertIdValue])

    const updateFormData = () => {
        //se vier de um recordformpopup
        if(props.popupRecordForm) {
            //abrir um novo
            setPopupRecordFormOpen(true)
            setPopupState(true)
            setStateModeEdit(true)
            setDetailEntityName(props.entityName)
            setPopupRecordFormAdd(props.masterEntityId)
        }
        //se vier de um recordform 
        else{
            setTabPanelItems([
                ...tabPanelItems, 
                {
                    'title': entityStructureState[0].masterEntity.caption 
                    ,'component': <TelecobrancaForm id={Math.random()} entityName={props.entityName} modeEditOn={true}/>
                    ,'text': Math.random()
                    ,'icon':'edit'
                }
            ])
            setIndexTabPanelMain(tabPanelItems.length)
        }

    }


    //Botões 

    const addButtonOptions = {
        icon: 'add',
        hint: 'Incluir um novo registro',
        onClick: updateFormData,
    }

    const recordButtonOptions = {
        icon: 'check',
        useSubmitBehavior: true,
        validationGroup : `groupName${props.id}`,
        onClick: recordInsertUpdateJson,
    }

    const deleteButtonOptions = {
        icon: 'close',
        onClick: () => setConfirmDelete({...confirmDelete, isVisible: true})
    }

    const revertButtonOptions = {
        icon: 'revert',
        hint: 'Desfazer alterações no registro',
        onClick: () => {
            setPopup({
                ...popup,
                    isVisible: true,
                    message: "Atenção",
            })
        }
    }

    const onClickRefreshButton = () =>{
        showLoadPanel()
        console.log(props)
        if(insertIdValue){
            modeEditOff()
            httpRequest("POST", 
            requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
            {
                "templateTelecobrancaId": props.templateId,
                "applicationId": applicationID
            })
            .then((sender) => {
                const senderEntityStructure = JSON.parse(sender)

                montarMenuDeContexto(senderEntityStructure)

                // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                setEntityStructureState(orderGroups(senderEntityStructure))

                if(senderEntityStructure[0].lookUpEntities) {
                    httpRequest("POST", 
                        requestsApi.getLookupTable, requestsApi.basicAuth, 
                                    {
                                        "lookUpEntities": senderEntityStructure[0].lookUpEntities
                                    }
                    ).then((sender)=>{
                        const senderDataLookup = JSON.parse(sender)
                        setDataLookup(senderDataLookup)
                        modeEditOff()
                    })
            }
            })
            .catch((error) => {
                hideLoadPanel()
                if(props.abrirFichaDiscador){
                    props.setStates({...states, 
                        isVisiblePopupErrorAbrirFicha: true,
                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                    })
                }
                else{
                    if(error.data.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    }
                    else {
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    } 
                } 
            })

            httpRequest('POST', 
                requestsApi.getDataTelecobranca, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "tituloId": props.tituloId
                        // ,"entityName": props.entityName
                        // ,"fieldName": 'id'
                        // ,"expressao": insertIdValue.idValue //insertIdValue.idValue  no insertIdValue ta vindo o id, insertIdValue.idValue ta undefined
                    })
                    .then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)
                    setDataEntity(resultGetDataEntity);
                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    modeEditOff()
                    })
                    .catch((error)=>{
                        if(error.data.error) {
                            hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else {
                            hideLoadPanel()
                            setMessageError(error.statusText)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        } 
                    })
                    .then(() => {
                        modeEditOff()
                        hideLoadPanel()
                    })
        }
        else if(!idValue && props.masterEntityId){
            httpRequest("POST", requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
                {
                    "templateTelecobrancaId": props.templateId,
                    "applicationId": applicationID
                }
            )
            .then((sender) => {
                const senderEntityStructure = JSON.parse(sender)

                montarMenuDeContexto(senderEntityStructure)

                // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                setEntityStructureState(orderGroups(senderEntityStructure))

                if(senderEntityStructure[0].lookUpEntities) {
                    httpRequest("POST", 
                        requestsApi.getLookupTable, requestsApi.basicAuth,
                                    {
                                        "lookUpEntities": senderEntityStructure[0].lookUpEntities
                                    }
                    ).then((sender)=>{
                        const senderDataLookup = JSON.parse(sender)
                        setDataLookup(senderDataLookup)
                        modeEditOff()
                    })
            }
            })
            .catch((error) => {
                hideLoadPanel()
                if(props.abrirFichaDiscador){
                    props.setStates({...states, 
                        isVisiblePopupErrorAbrirFicha: true,
                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                    })
                }
                else{
                    if(error.data.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    }
                    else {
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    } 
                } 
            })

            httpRequest('POST', 
                requestsApi.getDataTelecobranca, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID,
                        "tituloId": props.tituloId
                        // ,"entityName": props.entityName
                        // ,"fieldName": 'id'
                        // ,"expressao": props.masterEntityId
                    }
                    )
                    .then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)
                    setDataEntity(resultGetDataEntity)
                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    modeEditOff()
                    })
                    .catch((error)=>{
                        if(error.data.error) {
                            hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else {
                            hideLoadPanel()
                            setMessageError(error.statusText)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        } 
                    })  
                    .then(() => hideLoadPanel())
        }else if(props.IDValue){
            httpRequest("POST", requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
                {
                    "templateTelecobrancaId": props.templateId,
                    "applicationId": applicationID
                }
            )
            .then((sender) => {
                const senderEntityStructure = JSON.parse(sender)

                montarMenuDeContexto(senderEntityStructure)

                // setStates({...states, entityStructure: orderGroups(senderEntityStructure)})
                setEntityStructureState(orderGroups(senderEntityStructure))

                modeEditOff()
                if(senderEntityStructure[0].lookUpEntities) {
                    httpRequest("POST", 
                    requestsApi.getLookupTable, requestsApi.basicAuth, 
                                    {
                                        "lookUpEntities": senderEntityStructure[0].lookUpEntities
                                    }
                    ).then((sender)=>{
                        const senderDataLookup = JSON.parse(sender)
                        setDataLookup(senderDataLookup)
                        modeEditOff()
                    })
            }
            })
            .catch((error) => {
                hideLoadPanel()
                if(props.abrirFichaDiscador){
                    props.setStates({...states, 
                        isVisiblePopupErrorAbrirFicha: true,
                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                    })
                }
                else{
                    if(error.data.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    }
                    else {
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    } 
                } 
            })

            httpRequest('POST', 
                requestsApi.getDataTelecobranca, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID,
                        "tituloId": props.tituloId
                        // ,"entityName": props.entityName
                        // ,"fieldName": 'id'
                        // ,"expressao": props.IDValue
                    }
                    )
                    .then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)
                    setDataEntity(resultGetDataEntity)
                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    modeEditOff()
                    })
                    .catch((error)=>{
                        if(error.data.error) {
                            hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else {
                            hideLoadPanel()
                            setMessageError(error.statusText)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        } 
                    })
                    .then(() => hideLoadPanel())
        }
    }
    // ,[idValue, insertIdValue, props.IDValue, props.entityName, props.masterEntityId])

    const refreshButtonOptions = {
        icon: 'refresh',
        hint: 'Atualizar dados do servidor',
        onClick: onClickRefreshButton,
        }

    const configRecordFormStructure = () => {
        // setIDValueDetailEntity(null)
        httpRequest('POST', 
            requestsApi.getDataTelecobranca, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID,
                        "tituloId": props.tituloId
                        // ,"entityName": "entity"
                        // ,"fieldName": "entityname"
                        // ,"expressao": props.entityName
                    }
                    )
                    .then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)
                    setIDValueDetailEntity(resultGetDataEntity.masterEntity[0].records[0].id)
                    })
                    .catch((error)=>{
                        if(error.data.error) {
                            hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else {
                            hideLoadPanel()
                            setMessageError(error.statusText)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        } 
                    })
                    .then(() => {
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setStateModeEdit(true)
                        setDetailEntityName("entity")
                    })
    }

    const configTablesAndComponents = () => {
        //console.log("createTablesAndFieldsFromEntity "+dataEntity.masterEntity[0].records[0])
        httpRequest("POST", 
            requestsApi.createTablesAndFieldsFromEntity, requestsApi.basicAuth, 
                    {
                        "entityName": dataEntity[0].masterEntity[0].records[0].entityname,
                        "applicationId": applicationID
                    }
                    )
                    .then((sender) => {
                        const senderTablesAndComponents = JSON.parse(sender)
                        toastConfiguration(true, 'success', senderTablesAndComponents.mensagem)   
                    })
                    .catch((error)=>{
                        if(error.data.error) {
                            hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else {
                            hideLoadPanel()
                            setMessageError(error.statusText)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        } 
                    })
    }

    const configColumnsFromView = () => {
            httpRequest("POST", 
                requestsApi.addColumnsFromView, requestsApi.basicAuth,
                        {
                            "viewId":dataEntity.masterEntity[0].records[0].id,
                            "applicationId": applicationID
                        }
                        )
                        .then((sender) => {
                            const senderTablesAndComponents = JSON.parse(sender)
                            toastConfiguration(true, 'success', senderTablesAndComponents.mensagem)   
                        })
                        .catch((error)=>{
                            if(error.data.error) {
                                hideLoadPanel()
                                setMessageError(error.data.error)
                                setPopup({
                                    ...popup
                                        ,isVisible: true
                                        ,message: "Atenção!" 
                                })
                            }
                            else {
                                hideLoadPanel()
                                setMessageError(error.statusText)
                                setPopup({
                                    ...popup
                                        ,isVisible: true
                                        ,message: "Atenção!" 
                                })
                            } 
                        })
    }

    const mountTablesAndComponentsButtonOptions = {
        icon: "upload",
        onClick: configTablesAndComponents,
    }

    const addColumnsFromViewButtonOption = {
        icon: "insertcolumnleft",
        hint: "Adicionar todas as colunas/campos da view",
        onClick: configColumnsFromView,
    }

    //-----------FORM---------------//

    //-----------POPUP-------------//
      const hidePopup = () =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    };

    const [revertPopupVisible, setRevertPopupVisible] = useState(false)
    
    const popupConfirmChanges = useCallback(() => {
        resetFormData.current.instance.beginUpdate()
        // resetFormData.current.instance.updateData('formData', undefined)
        if(dataEntityNull) {
            showLoadPanel()
            setDataEntity(undefined)
            hidePopup()
            setRevertPopupVisible(false)
            hideLoadPanel()
        } 
        else if (!dataEntityNull) {
            // showLoadPanel()
            httpRequest('POST', requestsApi.getTelecobrancaStructure, requestsApi.basicAuth, 
            {
                "templateTelecobrancaId": props.templateId,
                "applicationId": applicationID
            })
            .then(senderGetEntityStructure=>{
                const resultGetEntityStrucuture = JSON.parse(senderGetEntityStructure)

                montarMenuDeContexto(resultGetEntityStrucuture)

                // orderGroups(resultGetEntityStrucuture)
                // setStates({...states, entityStructure: resultGetEntityStrucuture});
                setEntityStructureState(orderGroups(resultGetEntityStrucuture))
            })
            .catch((error)=>{
                hideLoadPanel()
                if(props.abrirFichaDiscador){
                    props.setStates({...states, 
                        isVisiblePopupErrorAbrirFicha: true,
                        messagePopupErrorAbrirFicha: "Ocorreu um erro ao abrir ficha! Tentar novamente?"
                    })
                }
                else{
                    if(error.data["description"]) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    }
                    else {
                        hideLoadPanel()
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    } 
                }
            })
            .then(() => hideLoadPanel())
            
            if(insertIdValue){
                httpRequest('POST', requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "tituloId": props.tituloId
                    // ,"entityName": props.entityName
                    // ,"fieldName": 'id'
                    // ,"expressao": insertIdValue
                }).then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)
                    setDataEntity(resultGetDataEntity);
                    hidePopup()
                    setRevertPopupVisible(false)
                    modeEditOff()
                    
                }).catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            }).then(() => hideLoadPanel())
            }else{
                httpRequest('POST', requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "tituloId": props.tituloId
                    // ,"entityName": props.entityName
                    // ,"fieldName": 'id'
                    // ,"expressao": props.IDValue
                }).then(senderGetDataEntity=>{
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    getClienteId(resultGetDataEntity)

                    setDataEntity(resultGetDataEntity);
                    hidePopup()
                    setRevertPopupVisible(false)
                    modeEditOff()
                    
                }).catch((error)=>{
                if(error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                } 
            }).then(() => hideLoadPanel())
            }
        }
        resetFormData.current.instance.endUpdate()
    },[props.entityName, idValue, props.IDValue, insertIdValue])

    const closeRevertPopup = () => {
        setRevertPopupVisible(false)
    }
    
    //Botões
    const closeButtonPopup = {
        text: 'Ok',
        onClick: hidePopup,
    };

    const confirmRevertButtonPopup = {
        text: 'Sim',
        onClick: popupConfirmChanges,
    };

    const cancelRevertButtonPopup = {
        text: 'Não',
        onClick: closeRevertPopup,
    }

    //Estados
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [messageError, setMessageError] = useState()

    const errorModalClose = () => {
        setErrorModalOpen(false)
    }

    const closeButtonPopupError = {
        text: 'OK',
        onClick: errorModalClose,
    };



    //-----------POPUP-------------//

    //---------LOADING------------//

    //Estados
    const loadingPanelOptions = { 
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    },[loadingPanelState])
    const showLoadPanel =useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    },[loadingPanelState])

    //---------LOADING------------//

    //---------TABPANEL----------//
    //Funções tabPanel

    // const itemTitleRender = (company) => {
    //     return (<span>{company.entityName}</span>)
    //   }

    // const onSelectionChanged = (args) => {
    //     if(args.name == 'selectedIndex') {
    //         setTabpanel({
    //         selectedIndex: args.value
    //       });
    //     }
    //   }


    
    //Estados
    // const {tabPanelItems, setTabPanelItems} = useTabPanelItems()
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState();
    const [detailEntityName, setDetailEntityName] = useState()
    const [isValueTrue, setIsValueTrue] = useState(true)
    
    // const createRecordForm = () => {
    //     console.log(IDValueDetailEntity)
    //     setTabPanelItems([
    //         ...tabPanelItems, 
    //         {
    //             'title': 'TESTE CONTATOS', 
    //             'component': <RecordForm id={Math.random()} IDValue={IDValueDetailEntity} entityName={'cadastro_contatos'}/>,
    //             'text': Math.random(),
    //             'icon':'edit'
    //         }
    //     ])
    // }

    const[popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const[popupQueryFormOpen, setPopupQueryFormOpen] = useState(false)
    const refreshDataGrid = useRef(null)

    // const setRefreshDataGrid = () => {
    //     refreshDataGrid.current.instance.beginUpdate()
    //     refreshDataGrid.current.instance.repaint()
    //     refreshDataGrid.current.instance.endUpdate()
    // }
    
    
    //---------TABPANEL----------//

    //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
                                                    isVisible: false,
                                                    type: 'info',
                                                    message: ""
                                                    })

    //Funções
    const onHiding = useCallback(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false
        });
      },[toastConfig])
    //---------TOAST----------//
    const [stateModeEdit, setStateModeEdit] = useState(false);
    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
          ...toastConfig,
          isVisible: visible,
          type: type,
          message: message
        });
      },[toastConfig])


    const [detailCaptionName, setDetailCaptionName] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [embeddedComponentsEntityStructureArrayState, setEmbeddedComponentsEntityStructureArrayState] = useState([])
    const [embeddedComponentsDataLookupArrayState, setEmbeddedComponentsDataLookupArrayState] = useState([])
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [entityDataEntityState, setEntityDataEntityState] = useState()

    const [popupDataSourceRecordFormContextMenuState, setPopupDataSourceRecordFormContextMenuState] = useState({
        visible: false
    })
 

    const RenderRecordFormPopup = memo(() => {
        // console.log(arrayMasterEntitysIds.find((e) => e.entityName === detailEntityName).id)
        console.log(arrayMasterEntitysIds)
        console.log(detailEntityName)

        return(
            <RecordFormPopup
                type={"recordForm"}
                refreshRecord={refreshRecord}
                setRefreshRecord={setRefreshRecord}
                id={Math.random()} 
                idValue={IDValueDetailEntity} 
                entityName={detailEntityName}
                keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                masterEntityId={arrayMasterEntitysIds.find((e) => e.detailEntityName === detailEntityName)?.masterEntityId}
                isValueTrue={isValueTrue}
                setIsValueTrue={setIsValueTrue}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordForm={true}
                modeEdit={stateModeEdit}
                // dataLookup={dataLookup}
                // entityStructure={entityStructureState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}

                caption={detailCaptionName}
    
                
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
                dataEntity={entityDataEntityState}
            />
        );
    })

    const RenderRecordFormContextMenu = memo(() => {
        return(
            <Popup
                visible={popupDataSourceRecordFormContextMenuState.visible}
                onHiding={() => {
                    setPopupDataSourceRecordFormContextMenuState({
                        ...popupDataSourceRecordFormContextMenuState,
                        visible: false
                    })
                }}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={popupDataSourceRecordFormContextMenuState.title}
                resizeEnabled={true}
                fullScreen={true}
            >
                <ScrollView useNative={true}>
                    <RecordForm
                        entityName={popupDataSourceRecordFormContextMenuState.entityname}
                        // idValue={idValue}
                        idValue={popupDataSourceRecordFormContextMenuState.id}
                        icon={popupDataSourceRecordFormContextMenuState.icon}
                        entityStructure={popupDataSourceRecordFormContextMenuState.structure}
                        dataLookup={popupDataSourceRecordFormContextMenuState.lookup}
                        id={Math.random()}
                        insertId={popupDataSourceRecordFormContextMenuState.insertid}
                        modeEditOn={popupDataSourceRecordFormContextMenuState.edit}
                    />
                </ScrollView>
            </Popup>
        )

    })


    const RenderQueryFormPopup = memo(() => {
        return(
            <QueryFormPopup
                id={Math.random()}
                setPopupQueryFormOpen={setPopupQueryFormOpen}
                popupQueryFormOpen={popupQueryFormOpen}
                entityName={queryFormEntityName}
                entityStructure={entityStructureState}
                foreignFieldSelectedItem={foreignFieldSelectedItem}
                setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                setQueryFormPopupState={setQueryFormPopupState}
                queryFormPopupState={queryFormPopupState}
                // idValue={}
            />
        );
    })
    const hidePopupDelete = () =>{
        setConfirmDelete({...confirmDelete, isVisible: false})
    }
    const closeDeleteRecord = {
        text: "Voltar"
        ,onClick: hidePopupDelete
    }
    const confirmDeleteRecord = {
        text: "Ok"
        ,onClick: recordDeleteJson
    }


    const [ popupState, setPopupState ] = useState(false)

    const dropDownBoxRenderFuncion = (data) => {
        let dropDownValue = ""
        return(
            <DropDownBox
                disabled={data.readOnly}
                hint={data.hint} 
                caption={data.fieldCaption} 
                // dataField={data.fieldName}
                value={data.fieldCaption}
                dataSource={dataLookup[data.lookUpListSource]}
                valueExpr="id"//{data.lookUpKeyField}
                displayExpr={`${data.lookUpResultField}`}
                placeholder="Selecione uma opção"
                visible={true}
                showClearButton={true}
                deferRendering={false}
                searchEnabled={true}
                searchMode="contains"
                // buttons={
                //     [
                //         {
                //             location:"after",
                //             name:undefined,
                //             options:undefined
                //         }
                //     ]
                // }
                //showDataBeforeSearch={ false}
                valueChangeEvent="keyup"
                contentRender={() => {return (
                        <div className="list-container">
                        <List
                            dataSource={dataLookup[data.lookUpListSource]}
                            // itemRender={(sender) => {
                            //     return <div>{sender[`${data.lookUpResultField}`]}</div>;
                            //     }}
                            displayExpr={`${data.lookUpResultField}`}
                            valueExpr="id"
                            searchEnabled={true}
                            onItemClick={(sender) => { 
                                dropDownValue = sender.itemData[`${data.lookUpResultField}`]
                            }}
                            selectionMode="single"
                            searchMode={"contains"}
                        />
                        </div>  
                )}}
            >

            </DropDownBox>
        )
    }
    //----------UpfPopupForm--------//
    const [statesUpfPopupForm, setStatesUpfPopupForm] = useState({
        visible: false,
        componentName: null,
        tituloId: null,
        credorId: null,
        clienteId: null,
        title: "",
        execute: false,
        parcelasIdArray: null
    })

    const hideUpfPopupForm = () => {
        //limpar estados do acordo e do boleto
        // setGerarAcordoDataSource(null)
        // setIndexMultiViewItemsGerarAcordo(0)

        // setGerarBoletoDataSource(null)
        // setIndexMultiViewItemsGerarBoleto(0)

        setStatesUpfPopupForm({
            ...statesUpfPopupForm,
            visible: false
        })
    }

    const showUpfPopupForm = (clienteId, credorId, tituloId, title, execute, parcelasIdArray) => {
        setStatesUpfPopupForm({
            visible: true,
            tituloId: tituloId,
            credorId: credorId,
            clienteId: clienteId,
            title: title,
            execute: execute,
            parcelasIdArray: parcelasIdArray
        })
    }

    //----------UpfPopupForm--------//

    //GroupPermissionForm
    const [statesGroupPermissionForm, setStatesGroupPermissionForm] = useState({
        visible: false
    })

    const showGroupPermissionForm = () => {
        setStatesGroupPermissionForm({
            visible: true,
        })
    }

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    //toda vez que for abrir um queryFormPopup tem q chamar a função pra true
    const[queryFormPopupState, setQueryFormPopupState] = useState(false)

    const onClickMenuContextItem = (item) => {
        if(!item?.askConfirm){
            if (item.componentName === "recordForm" && item.componentParameter) {
                showLoadPanel()

                const jsonParametros = JSON.parse(item.componentParameter)
                console.log(item)
                console.log(jsonParametros)

                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                    applicationId: applicationID
                })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)
                                showLoadPanel()

                                setPopupDataSourceRecordFormContextMenuState({
                                    visible: true,
                                    title: item.caption,
                                    entityname: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                                    id: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id,
                                    icon: item.icon,
                                    structure: resultGetEntityStructureJson,
                                    lookup: senderDataLookup,
                                    insertid: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null},
                                    edit: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true
                                })

                                hideLoadPanel()

                            })
                            .catch((error) => {
                                hideLoadPanel()
                                console.log(error)
                            })
                    }
                    else {
                        showLoadPanel()
                        setPopupDataSourceRecordFormContextMenuState({
                            visible: true,
                            title: item.caption,
                            entityname: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                            id: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id,
                            icon: item.icon,
                            structure: resultGetEntityStructureJson,
                            lookup: null,
                            insertid: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null},
                            edit: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true
                        })
                        hideLoadPanel()
                    }
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                })
            }

            else if (item.componentName === "gerarBoletoForm") {
                let dataBoletoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Boleto"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "gerarAcordoIturanCustomForm") {
                let dataAcordoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Gerar acordo ituran"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "mensagemCustomForm") {
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Enviar mensagem"
                )
            }

            else if (item.componentName === "baneseCardNegociacaoCustomForm") {
                let dataAcordoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
                console.log(dataAcordoForm)
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Banese card negociação"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "solNegociacaoCustomForm") {
                let dataAcordoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Sol negociação"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "gerarAcordoForm") {
                let dataAcordoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    proximaFichaDados?.clienteId,
                    dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
                    props.tituloId,//tituloId
                    "Acordo/Novação de dívida",
                    item._execute
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            // else if (item.componentName === "gerarAcordoGSPForm") {
            //     let dataAcordoForm = dataEntity?.[0]?.masterEntity[0]?.records[0]
            //     showUpfPopupForm(
            //         dataAcordoForm._clienteid,
            //         dataEntity?.[0]?.masterEntity[0]?.records[0]._credorid,
            //         props.tituloId,//tituloId
            //         "(GSP) Acordo/Novação de dívida"
            //     )
            // }

            else if (item.componentName === "groupPermissionForm") {
                showGroupPermissionForm()
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.methodName) {
                //no revolution porta 8080 n veio item.itemData
                //por isso foi criado o condicional (07/01/2022)
                console.log(item.componentName)
                const entidadeClicada = dataEntity.find((e) => e.masterEntity?.[0]?.entityName === item.entityName)
                const idValueEntidadeClicada = entidadeClicada?.masterEntity?.[0]?.records?.[0]?.id
                if(item.componentName === 'htmlForm'){
                    if (item.itemData) {
                        //Pegar template html 
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`,  requestsApi.basicAuth,{
                            "applicationId": applicationID,
                            "relationId": relationId,
                            "idValue": idValueEntidadeClicada,
                            "parameter": item.itemData.componentParameter,
                            "entityName": item.entityName
                        })
                        .then((sender) => {
                            const resultJsonTemplateHtml = JSON.parse(sender)
                            console.log(resultJsonTemplateHtml)
                            setHtmlTemplate(() => {
                                return {__html: resultJsonTemplateHtml.mensagem}
                            })
                            setHtmlPopupVisible(true)
                        })
                        .catch((error) => {
                            console.log(error)
                            setMessage(error.data.error)
                            setVisible(true)
                        })
                    }
                    else{
                        //Pegar template html 
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`,  requestsApi.basicAuth,{
                            "applicationId": applicationID,
                            "relationId": relationId,
                            "idValue": idValueEntidadeClicada,
                            "parameter": item.componentParameter,
                            "entityName": item.entityName
                        })
                        .then((sender) => {
                            const resultJsonTemplateHtml = JSON.parse(sender)
                            console.log(resultJsonTemplateHtml)
                            setHtmlTemplate(() => {
                                return {__html: resultJsonTemplateHtml.mensagem}
                            })
                            setHtmlPopupVisible(true)
                        })
                        .catch((error) => {
                            console.log(error)
                            setMessage(error.data.error)
                            setVisible(true)
                        })
                    }
                }
                else{
                    if (item.itemData) {
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`, requestsApi.basicAuth,
                            {
                                "applicationId": applicationID,
                                "relationId": relationId,
                                "idValue": idValueEntidadeClicada,
                                "parameter": item.itemData.componentParameter,
                                "entityName": item.entityName
                            })
                            .then((sender) => {
                                const response = JSON.parse(sender)
                                toastConfiguration(true, 'info', response.mensagem)
        
                            })
                            .catch((err) => console.log(err))
                    }
                    else {
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`, requestsApi.basicAuth,
                            {
                                "applicationId": applicationID,
                                "relationId": relationId,
                                "idValue": idValueEntidadeClicada,
                                "parameter": item.componentParameter,
                                "entityName": item.entityName
                            })
                            .then((sender) => {
                                const response = JSON.parse(sender)
                                toastConfiguration(true, 'info', response.mensagem)
        
                            })
                            .catch((err) => console.log(err))
                    }
                }
                
            }
            else {
                hideLoadPanel()
                setMessageError("Não foi possível abrir o menu de contexto")
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const recordInsertJsonDetail = (entityNameGrid, formData) => {
        delete formData.id;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            modeEditOff()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            hideLoadPanel()
            if (error?.data?.error) {
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
            else {
                setMessageError(error?.statusText)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        })
    }

    const createTarefaCustomForm = () => {
        let entidadeIncorporadaTarefa = null
        
        for(let i = 0; i <= entityStructureState.length - 1; i++){
            if(entityStructureState?.[i]?.masterEntity?.entityName === "_titulo"){
                entidadeIncorporadaTarefa = entityStructureState[i]
            }
        }

        console.log(entidadeIncorporadaTarefa)
        if (entidadeIncorporadaTarefa?.masterEntity["customRecordForm"]) {
            //tipos de customRecordForm
            if (entidadeIncorporadaTarefa?.masterEntity["customRecordForm"] === "tarefaCustomForm") {
                setUpfPopupCustomFormStates({
                    visible: true,
                    title: "Tarefa",
                    formName: "TarefaCustomForm",
                    entityName: props.entityName,
                    caption: entidadeIncorporadaTarefa?.[0].masterEntity.caption,
                    idValue: props.tituloId
                })
            }
        }
        else {
            // setIDValueDetailEntity(idValue)
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tarefa"
            })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)
                    setEmbebedIdValueState(props.tituloId)
                    setEmbebedEntityNameState("_titulo")//temporario

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)
                                setDataLookupRecordFormPopupState(senderDataLookup)

                                setDetailEntityName("_tarefa")
                                setDetailCaptionName("Tarefas")
                                setPopupRecordFormOpen(true)
                                setPopupState(true)
                                setIDValueDetailEntity(null)
                                setStateModeEdit(true)
                                
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                    else {
                        setDetailEntityName("_tarefa")
                        setDetailCaptionName("Tarefas")
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setIDValueDetailEntity(null)
                        setStateModeEdit(true)
                    }
                })


            // setKeyFieldNameState(senderDetailEntity.keyFieldName)
        }

    }

    const tasksButtonOptions = {
        icon: 'plus',
        text: "Nova tarefa",
        type: "default",
        onClick: createTarefaCustomForm,
    }

    const refreshStructure = (data) => {
        showLoadPanel()
        console.log(data)
        const temp = entityStructureState
        temp[0].recordFormProperties[0].recordFormComponents = data
        setEntityStructureState(temp)
        if(resetFormData){
            resetFormData.current.instance.repaint()
            console.log(resetFormData)
        }
        // setRefreshRecord(true)
        hideLoadPanel()
    }

    
    return (
        <div className="tab-items">
            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={popup.message}
                width={400}
                height={200}
                resizeEnabled={true}
                id="popupForm"
                >
                    {/* <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={confirmRevertButtonPopup}
                    /> */}
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopup}
                    />
                    {messageError &&
                        <p>{messageError}</p>
                    }  
            </Popup>

            {htmlPopupVisible && htmlTemplate &&
                <HtmlFormPopup
                    // UpfHtmlEditor={true}
                    setHtmlPopupVisible={setHtmlPopupVisible}
                    htmlPopupVisible={htmlPopupVisible}
                    htmlTemplate={htmlTemplate}
                    htmlFormPopupDataSourceState={htmlFormPopupDataSourceState}
                    id={idValue}
                />
            }

            <UpfPopupForm
                statesUpfPopupForm={statesUpfPopupForm}
                hideUpfPopupForm={hideUpfPopupForm}
            />

            {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    caption={upfPopupCustomFormStates.caption}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                />
            }

            <UpfPopup
                typePopup="closeTabs"
                popupVisibleState={visible}
                popupVisibleFunctionChangeState={setVisible}
                message={message}
                clearTabRuleState={clearTabRuleState}
                toolbarRef={_toolbarRef}
            />

            {popupRecordFormOpen === true ? <RenderRecordFormPopup/> : <></>}
            {popupQueryFormOpen === true ? <RenderQueryFormPopup/> : <></>}
            {popupDataSourceRecordFormContextMenuState.visible === true ? <RenderRecordFormContextMenu/> : <></>}
 
            {visibleConfirmContextMenuState &&
                <UpfPopupConfirmContextMenu
                    hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                    visibleConfirmContextMenu={visibleConfirmContextMenuState}
                    contextMenuFunction={onClickMenuContextItem}
                    contextMenuItemState={contextMenuItemState}
                />
            }

            {/* <Popup
                visible={popupDataSourceRecordFormContextMenuState.visible}
                onHiding={() => {
                    setPopupDataSourceRecordFormContextMenuState({
                        ...popupDataSourceRecordFormContextMenuState,
                        visible: false
                    })
                }}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={popupDataSourceRecordFormContextMenuState.title}
                resizeEnabled={true}
                fullScreen={true}
            >
                <ScrollView>
                    <RecordForm
                        entityName={popupDataSourceRecordFormContextMenuState.entityname}
                        // idValue={idValue}
                        idValue={popupDataSourceRecordFormContextMenuState.id}
                        icon={popupDataSourceRecordFormContextMenuState.icon}
                        entityStructure={popupDataSourceRecordFormContextMenuState.structure}
                        dataLookup={popupDataSourceRecordFormContextMenuState.lookup}
                        id={Math.random()}
                        insertId={popupDataSourceRecordFormContextMenuState.insertid}
                        modeEditOn={true}
                    />
                </ScrollView>
            </Popup> */}

            <LoadPanel
                    style={{zIndex: "-1 !important", border: "solid 1px red !important"}}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={loadingPanelState.loadPanelVisible}
                    // visible={true}
                    showIndicator={loadingPanelState.showIndicator}
                    shading={loadingPanelState.shading}
                    showPane={loadingPanelState.showPane}
                />
            {entityStructureState && dataLookup && condicionalVisibleState && loadedContentState ? (
                // <ScrollView width='100%' height='100%'>
                <div>
                    <div>
                        <Toolbar className={"div-toolbar"}
                            ref={_toolbarRef}
                            height={70}
                        >

                            {/* <Item 
                                location="before"
                                widget="dxButton"
                                options={recordButtonOptions}
                                disabled={!modeEditForm}
                            />
                        
                            <Item 
                                location="before"
                                widget="dxButton"
                                options={revertButtonOptions}
                                disabled={!modeEditForm}
                            /> */}

                            <Item
                                location="before"
                                widget="dxButton"
                                options={refreshButtonOptions}
                                disabled={true}

                            />

                            <Item 
                                location="before"
                            >
                                <DropDownButton
                                    disabled={!dataEntity}
                                    text="Opções"
                                    icon="menu"
                                    dropDownOptions={{width: "300"}}
                                    items={constextMenuMasterEntityPermission}
                                    displayExpr="caption"
                                    displayValue="idFieldName"
                                    onItemClick={(e) => {
                                        console.log(e.itemData)
                                        onClickMenuContextItem(e.itemData)
                                        }
                                    }
                                />
                            </Item>

                            <Item
                                location="before"
                                widget="dxButton"
                                options={tasksButtonOptions}
                            />

                        {/* <Item
                            location="before"
                            widget={'dxDropDownButton'}
                            options={{
                                text: "Tabelas Auxiliares",
                                icon:"menu",
                                width:180,
                            onSelectionChanged: (e) => {
                                setQueryFormEntityName(e.item.lookUpEntity)
                                setQueryFormPopupState(true)
                                setPopupQueryFormOpen(true)
                            },
                            displayExpr:"lookUpCaption",
                            displayValue:"lookUpEntity",
                            items: entityStructureState[0]["lookUpEntities"] || "",
                            }}
                            dropDownOptions={{width:"300",maxWidth:"500"}}
                            >
                        </Item>

                        <Item
                            location="before"
                            widget={'dxDropDownButton'}
                            options={{
                                text: "Menu de contexto",
                                icon:"menu",
                                width:180,
                            onSelectionChanged: (e) => {
                                onClickMenuContextItem(e.item)
                                // setQueryFormEntityName(e.item.lookUpEntity)
                                // setQueryFormPopupState(true)
                                // setPopupQueryFormOpen(true)
                            },
                            displayExpr:"caption",
                            displayValue:"idFieldName",
                            items: entityStructureState[0].recordFormProperties[0]["contextMenu"] || "",
                            }}
                            dropDownOptions={{width:"300",maxWidth:"500"}}
                            >
                        </Item> */}

                        </Toolbar>
                        {props.selectedItensCount &&
                                <div>
                                {props.selectedItensCount === 1 &&
                                <h3>{props.selectedItensCount} selecionado</h3>
                                }
                                {props.selectedItensCount > 1 &&
                                <h3>{props.selectedItensCount} selecionados</h3>
                                }
                                </div>
                            }
                    <div>
                    {/* <h2 className="style-h2"><b>{props.tituloId}</b></h2> */}
                </div>

                <div className={'dx-card'}>
                    <Popup
                        visible={confirmDelete.isVisible}
                        onHiding={hidePopupDelete}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={false}
                        showTitle={true}
                        title={confirmDelete.message}
                        width={400}
                        height={200}
                        resizeEnabled={true}
                        >
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                options={confirmDeleteRecord}
                            />
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                options={closeDeleteRecord}
                            />
                            <p>
                                Você realmente deseja apagar este registro?
                            </p>   
                    </Popup>

                    {/* POPUP DO REVERT */}
                    <Popup
                        visible={revertPopupVisible}
                        onHiding={closeRevertPopup}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={true}
                        showTitle={true}
                        title="Atenção!"
                        width={600}
                        height={280}
                        resizeEnabled={true}
                        id="popupForm"
                    >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={confirmRevertButtonPopup}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={cancelRevertButtonPopup}
                        />
                        <p>As alterações serão perdidas! Deseja cotinuar?</p>

                    </Popup>
                

                    {/* popup mostrar erros */}
                    <Popup
                        visible={errorModalOpen}
                        onHiding={errorModalClose}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showCloseButton={false}
                        showTitle={true}
                        title={popup.message}
                        width={300}
                        height={280}
                        resizeEnabled={true}
                    >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={closeButtonPopupError}
                        />
                        {messageError &&
                            <p>{messageError}</p>
                        }
                    </Popup>

                    <Toast
                        visible={toastConfig.isVisible}
                        message={toastConfig.message}
                        type={toastConfig.type}
                        onHiding={onHiding}
                        displayTime={600}
                        height={60}
                    />
            
                    {/* <ScrollView width='100%' height='100%'> */}
                    <div className="form-container">
                            {entityStructureState.map((item, indice) => {
                                console.log(item.masterEntity.entityName)
                                console.log(arrayMasterEntitysIds)
                                return (
                                    <div key={indice}>
                                        {/* <h3>{dataEntity[indice].masterEntity[0]}</h3> */}
                                        <GetFormComponents 
                                        // resetFormData={resetFormData}
                                        // masterDetailEntity={props.masterDetailEntity}
                                        // modeEditForm={modeEditForm}
                                            id={props.id} 
                                            dataEntity={dataEntity && item.recordFormProperties[0].recordFormComponents ? dataEntity.find((e) => e?.masterEntity[0]?.entityName === item?.masterEntity?.entityName)?.masterEntity[0]?.records[0] : formDataJson}
                                            // dataLookup={[]}
                                            dataLookup={dataLookup}
                                            entityStructure={[item]}
                                            formDataJson={formDataJson}
                                            colCount={item.recordFormProperties[0].colCount}
                                            modeEditOn={modeEditOn}
                                            senderFieldNamesCheckBox={senderFieldNamesCheckBox}
                                            recordInsertUpdateJson={recordInsertUpdateJson}
                                            formComponents={item.recordFormProperties[0].recordFormComponents ? item.recordFormProperties[0].recordFormComponents : []}
                                            setCondicionalVisibleState={setCondicionalVisibleState}
                                            resetFormData={resetFormData}
                                            
                                            detailEntity={dataEntity && dataEntity?.detailEntity}
                                            masterDetailEntity={props.masterDetailEntity}
                                
                                            modeEditForm={modeEditForm}
                                            
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={setModeEditForm}
                                            setFormDataJson={setFormDataJson}
                                            // setDataEntity={setDataEntity}
                                            // setPopupQueryFormOpen={setPopupQueryFormOpen}
                                            // setQueryFormEntityName={setQueryFormEntityName}
                                            // setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                                            // queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                                            // foreignFieldSelectedItem={foreignFieldSelectedItem}
                                            // setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                                            // setTituloQueryFormPopup={setTituloQueryFormPopup}
                                            // queryFormPopupState={queryFormPopupState}
                                            // setQueryFormPopupState={setQueryFormPopupState}
                                            objectPermissionsState={objectPermissionsState}
                                           
                                            // popupRecordFormOpen={props.popupRecordFormOpen}
                                            refreshStructure={refreshStructure}
                                            setStates={setStates}
                                            idValue={true}
                                            dataEntityEditedFields={dataEntityEditedFields}
                                            setDataEntityEditedFields={setDataEntityEditedFields}
                                        />

                                        {item.detailEntities[0].detailEntity && dataEntity ? (
                                            <TabPanelDetailEntitys
                                                idValue={arrayMasterEntitysIds.find((e) => e.entityName === item.masterEntity.entityName)?.masterEntityId}
                                                setIdValue={setIdValue}
                                                showLoadPanel={showLoadPanel}
                                                entityName={item.masterEntity.entityName}
                                                setKeyFieldNameState={setKeyFieldNameState}
                                                dataEntity={dataEntity && item.recordFormProperties[0].recordFormComponents &&
                                                    dataEntity.find((e) => e?.masterEntity[0]?.entityName === item?.masterEntity?.entityName)}
                                                modeEditOff={modeEditOff}
                                                setMessageError={setMessageError}
                                                setErrorModalOpen={setErrorModalOpen}
                                                hideLoadPanel={hideLoadPanel} 
                                                setDataEntityDetailEntity={setDataEntityDetailEntity}
                                                dataEntityDetailEntity={dataEntity && item.recordFormProperties[0].recordFormComponents &&
                                                    dataEntity.find((e) => e?.masterEntity[0]?.entityName === item?.masterEntity?.entityName).detailEntity}
                                                setDataEntity={setDataEntity}
                                                setDataEntityNull={setDataEntityNull}
                                                setPopup={setPopup}
                                                popup={popup}
                                                IDValueDetailEntity={IDValueDetailEntity}
                                                entityStructure={[item]}
                                                setIDValueDetailEntity={setIDValueDetailEntity}
                                                setDetailEntityName={setDetailEntityName}
                                                setPopupRecordFormOpen={setPopupRecordFormOpen}
                                                dataLookup={dataLookup}
                                                setStateModeEdit={setStateModeEdit}
                                                selectedProcess={props.selectedProcess}
                                                recordUpdateJsonDetail={recordUpdateJsonDetail}
                                                configRecordFormStructure={configRecordFormStructure}
                                                popupState={popupState}
                                                setPopupState={setPopupState}
                                                recordDeleteJsonDetail={recordDeleteJsonDetail}
                                                setPopupRecordFormAdd={setPopupRecordFormAdd}
                                                tituloId={props.tituloId}
                                                
                                                setEntityDataEntityState={setEntityDataEntityState}
                                                setEntityStructureRecordFormPopupState={setEntityStructureRecordFormPopupState}
                                                setDataLookupRecordFormPopupState={setDataLookupRecordFormPopupState}
                                                setDataLookup={setDataLookup}
                                                lookUpEntities={item.lookUpEntities && item.lookUpEntities}
                                                setDetailCaptionName={setDetailCaptionName}
                                                setDetailAttachCaptionName={setDetailAttachCaptionName}
                                                objectPermissionsState={objectPermissionsState}


                                                recordInsertJsonDetail={recordInsertJsonDetail}
                                                showUpfPopupForm={showUpfPopupForm}
                                                setStates={setStates}
                                            />): null
                                        }
                                    </div>
                                )
                            
                            })}
                        {/* <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}></div> */}
                        {/* <div id="Cliente">teste</div> */}
                        </div>
                        {/* </ScrollView> */}
                        <Toolbar>
                            <Button
                                width={120}
                                text="Contained"
                                type="normal"
                                stylingMode="contained"
                                />
                        </Toolbar>
                    </div>
                </div>
            </div>
            // </ScrollView>
            ) : (<Loading/>)
            }
        </div>
    )
}

export default TelecobrancaForm;