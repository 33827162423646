import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

import { exportDataGrid } from 'devextreme/excel_exporter';

const ExportingExcel = (dataGridRef, caption) => {
  const workbook = new ExcelJS.Workbook();
  const dataGrid = dataGridRef.current.instance;
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    worksheet: worksheet,
    component: dataGrid,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver(new Blob([buffer], { type: 'application/octet-stream' }), `${caption}.xlsx`);
    });
  });
}
export default ExportingExcel;