import { jsPDF } from 'jspdf'; 
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

const ExportingPDF = (dataGridRef, caption)=> {
  const doc = new jsPDF();
  const dataGrid = dataGridRef.current.instance;

  exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid
  }).then(() => {
    doc.save(`${caption}.pdf`);
  });
}
export default ExportingPDF;