const { createContext, useContext, useState } = require("react");

const IndexMultiViewItemsGerarBoleto = createContext();

export const IndexMultiViewItemsGerarBoletoProvider = ({ children }) => {
  const [indexMultiViewItemsGerarBoleto, setIndexMultiViewItemsGerarBoleto] = useState(0);

  return (
    <IndexMultiViewItemsGerarBoleto.Provider value={{ indexMultiViewItemsGerarBoleto, setIndexMultiViewItemsGerarBoleto }}>
      {children}
    </IndexMultiViewItemsGerarBoleto.Provider>
  );
};

export const useIndexMultiViewItemsGerarBoleto = () => useContext(IndexMultiViewItemsGerarBoleto);