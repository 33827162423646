import {useState, useEffect, useRef, useCallback, memo } from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import Loading from "../../../../CardComponents/Loading"
import { httpRequest } from '../../../../functions';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { useProximaFichaDados } from '../../../../Context/ProximaFichaDados';
import { applicationID, relationId, specialPermissions } from '../../../../api/ApplicationID';
import ScrollView from 'devextreme-react/scroll-view';
import { Toast } from 'devextreme-react/toast';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import Funnel, { Export } from 'devextreme-react/funnel';
import HtmlFormPopup from "../../../HtmlFormPopup";
import { fecharFormAtual } from "../../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Column, Editing } from 'devextreme-react/data-grid';
import { useUpfFloatPopupMessageVisibleGlobalState } from "../../../../Context/UpfFloatPopupMessageVisibleGlobalState";
import { executeActionUpfFloatPopupMessage } from "../../../../functions/executeActionUpfFloatPopupMessage";

let opcaoPagamentoItemSelecionado = 'Parcelamento';

const Passo2 = (props) => {
    console.log(props)
    const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState(props.gerarAcordoDataSource);
    const [valorFinalAcordo, setValorFinalAcordo] = useState(0);
    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()

    const [honorarioCalculadoValueState, setHonorarioCalculadoValueState] = useState(props?.gerarAcordoDataSource?.arrayTotais?.honorariovalor)

    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [gerouAcordo, setGerouAcordo] = useState(false)
    const [states, setStates] = useState({
        radioOpcaoPagamento: ["À vista", "Parcelamento"],       
        radioDesconto: ["Percentual", "Valor"],
        descontoItemSelecionado: "Percentual",
        descontoPrincipalEscolhido: 0,
        descontoJurosEscolhido: 0,
        descontoMultaEscolhido: 0,
        descontoHonorarioEscolhido: 0,
        descontoMoraEscolhido: 0,
        toastVisible: false,
        toastType: 'success',
        toastMessage: "",
        onHidden: false
    })

    const [statesPropsValues, setStatesPropsValues] = useState({
        valor: props?.gerarAcordoDataSource?.arrayTotais?.valor ? props.gerarAcordoDataSource.arrayTotais.valor : 0,
        jurosvalor:props?.gerarAcordoDataSource?.arrayTotais?.jurosvalor ? props.gerarAcordoDataSource.arrayTotais.jurosvalor : 0,
        multavalor: props?.gerarAcordoDataSource?.arrayTotais?.multavalor ? props.gerarAcordoDataSource.arrayTotais.multavalor : 0,
        honorariovalor: props?.gerarAcordoDataSource?.arrayTotais?.honorariovalor ? props.gerarAcordoDataSource.arrayTotais.honorariovalor : 0,
        moraValor: props?.gerarAcordoDataSource?.arrayTotais?.moraValor ? props.gerarAcordoDataSource.arrayTotais.moraValor : 0,
        cartorioValor: props?.gerarAcordoDataSource?.arrayTotais?.cartorioValor ? props.gerarAcordoDataSource.arrayTotais.cartorioValor : 0,
        outrasDespesasValor: props?.gerarAcordoDataSource?.arrayTotais?.outrasDespesasValor ? props.gerarAcordoDataSource.arrayTotais.outrasDespesasValor : 0,
        total : props?.gerarAcordoDataSource?.arrayTotais?.total ? props.gerarAcordoDataSource.arrayTotais.total : 0
    })

    useEffect(() => {
        console.log(statesPropsValues)
        console.log(props?.gerarAcordoDataSource?.arrayTotais)
        setStatesPropsValues({
            ...statesPropsValues,
            total: statesPropsValues.valor +
            statesPropsValues.jurosvalor +
            statesPropsValues.multavalor +
            statesPropsValues.honorariovalor +
            statesPropsValues.moraValor +
            statesPropsValues.cartorioValor +
            statesPropsValues.outrasDespesasValor
        })
    }, [
        statesPropsValues.valor,
        statesPropsValues.jurosvalor,
        statesPropsValues.multavalor,
        statesPropsValues.honorariovalor,
        statesPropsValues.moraValor,
        statesPropsValues.cartorioValor,
        statesPropsValues.outrasDespesasValor,
    ])

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const [gerarAcordoDisabledState, setGerarAcordoDisabledState] = useState(false)

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState(false)

    const onHidenPopupHtmlTemplate = () => {
        setHtmlPopupVisible(false)
    }

    const openPopupHtmlTemplate = () => {
        setHtmlPopupVisible(true)
    }

    const _descontoPrincipalEscolhidoRef = useRef(null)
    const _descontoJurosEscolhidoRef = useRef(null)
    const _descontoMultaEscolhidoRef = useRef(null)
    const _descontoHonorarioEscolhidoRef = useRef(null)
    const _descontoMoraEscolhidoRef = useRef(null)
    const _formGerarAcordo = useRef(null)


    const [descontoFinal, setDescontoFinal] = useState(0)  
    
    useEffect(() => {
        if(props.gerarAcordoDataSource && gerarAcordoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){                       
            let descontoMultaJurosValor = 0 
            let descontoValorPrincipal = 0 
            if(states.descontoItemSelecionado === "Percentual"){
                descontoValorPrincipal = states.descontoPrincipalEscolhido * (statesPropsValues.valor - props.gerarAcordoDataSource.valoresPrincipaisParcelasVincendasSemHonorario)
                descontoMultaJurosValor = states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                                    + states.descontoMultaEscolhido * statesPropsValues.multavalor
                                    + descontoValorPrincipal
                                    + states.descontoMoraEscolhido * statesPropsValues.moraValor 
            }
            else if(states.descontoItemSelecionado === "Valor"){
                descontoValorPrincipal = states.descontoPrincipalEscolhido - props.gerarAcordoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                descontoMultaJurosValor = states.descontoJurosEscolhido + states.descontoMultaEscolhido + descontoValorPrincipal + states.descontoMoraEscolhido
            }

            if(props.gerarAcordoDataSource.arrayTotais.honorariovalor > 0){
                if(props.gerarAcordoDataSource?.honorarioCalculadoApenasPeloValorPrincipal){
                    let honorarioCalculadoResult = (
                        (
                            statesPropsValues.valor 
                            - props.gerarAcordoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                            - descontoValorPrincipal
                        ) * (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._honorariotaxa / 100)
                    )
                    // setHonorarioCalculadoValueState(
                    //     parseFloat(honorarioCalculadoResult.toFixed(2))
                    // )

                    setStatesPropsValues({
                        ...statesPropsValues,
                        honorariovalor: parseFloat(honorarioCalculadoResult.toFixed(2))
                    })
                }
                else if(gerarAcordoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){
                    let honorarioCalculadoResult = (
                        (
                            statesPropsValues.valor - props.gerarAcordoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                            + statesPropsValues.jurosvalor 
                            + statesPropsValues.multavalor 
                            + statesPropsValues.moraValor 
                            + statesPropsValues.outrasDespesasValor
                            + statesPropsValues.cartorioValor
                            - descontoMultaJurosValor
                        ) * (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._honorariotaxa / 100)
                    )
                    setStatesPropsValues({
                        ...statesPropsValues,
                        honorariovalor: parseFloat(honorarioCalculadoResult.toFixed(2))
                    })
                }
            }

            

            console.log(props.gerarAcordoDataSource.arrayTotais.honorariovalor)

            // if(descontoMultaJurosValor === 0){
            //     setHonorarioCalculadoValueState(props.gerarAcordoDataSource.arrayTotais.honorariovalor)
            // }
            // else{
            //     setHonorarioCalculadoValueState(
            //         parseFloat(honorarioCalculadoResult.toFixed(2))
            //     )
            // }


            if(_descontoHonorarioEscolhidoRef?.current?.instance && states.descontoHonorarioEscolhido > 0){
                setStates({
                    ...states,
                    descontoHonorarioEscolhido: 0
                });
                _descontoHonorarioEscolhidoRef.current.instance.repaint()
            }

        }

    }, [
        states.descontoJurosEscolhido, 
        states.descontoMultaEscolhido, 
        states.descontoPrincipalEscolhido, 
        states.descontoMoraEscolhido,
        statesPropsValues.jurosvalor,
        statesPropsValues.multavalor,
        statesPropsValues.moraValor,
        statesPropsValues.valor,
    ])



    //props.gerarAcordoDataSource.resultJsonData usar para pegar os juros
    useEffect(() => {
        if(props.gerarAcordoDataSource){
            // const honorariovalor = gerarAcordoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1 ? honorarioCalculadoValueState : props.gerarAcordoDataSource.arrayTotais.honorariovalor
            setValorFinalAcordo(
                statesPropsValues.valor + 
                statesPropsValues.jurosvalor + 
                statesPropsValues.multavalor + 
                statesPropsValues.moraValor +
                statesPropsValues.outrasDespesasValor +
                statesPropsValues.cartorioValor +
                statesPropsValues.honorariovalor
            )
            setDescontoFinal(0)

            if(states.descontoItemSelecionado === "Percentual"){
                setDescontoFinal(
                    states.descontoPrincipalEscolhido * statesPropsValues.valor
                    + states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                    + states.descontoMultaEscolhido * statesPropsValues.multavalor
                    + states.descontoMoraEscolhido * statesPropsValues.moraValor
                    + states.descontoHonorarioEscolhido * statesPropsValues.honorariovalor
                    )
                setValorFinalAcordo(
                    (
                        statesPropsValues.valor + 
                        statesPropsValues.jurosvalor + 
                        statesPropsValues.multavalor + 
                        statesPropsValues.moraValor +
                        statesPropsValues.outrasDespesasValor +
                        statesPropsValues.cartorioValor +
                        statesPropsValues.honorariovalor
                    ) 
                    -
                    (
                        states.descontoPrincipalEscolhido * statesPropsValues.valor
                        + states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                        + states.descontoMultaEscolhido * statesPropsValues.multavalor
                        + states.descontoMoraEscolhido * statesPropsValues.moraValor
                        + states.descontoHonorarioEscolhido * statesPropsValues.honorariovalor 
                    )
                )
            }
            else if(states.descontoItemSelecionado === "Valor"){
                setDescontoFinal(
                    states.descontoPrincipalEscolhido
                    + states.descontoJurosEscolhido
                    + states.descontoMultaEscolhido
                    + states.descontoMoraEscolhido
                    + states.descontoHonorarioEscolhido
                    )
                setValorFinalAcordo(
                    (
                        statesPropsValues.valor + 
                        statesPropsValues.jurosvalor + 
                        statesPropsValues.multavalor + 
                        statesPropsValues.moraValor +
                        statesPropsValues.outrasDespesasValor +
                        statesPropsValues.cartorioValor +
                        statesPropsValues.honorariovalor
                    ) 
                    -
                    (
                        states.descontoPrincipalEscolhido
                        + states.descontoJurosEscolhido
                        + states.descontoMultaEscolhido
                        + states.descontoMoraEscolhido
                        + states.descontoHonorarioEscolhido  
                    )
                )
            }
        }

    }, [
        //props.gerarAcordoDataSource
        //,states.saldoAtualizado
        ,states.descontoItemSelecionado
        ,states.descontoPrincipalEscolhido
        ,states.descontoJurosEscolhido
        ,states.descontoMultaEscolhido
        ,states.descontoHonorarioEscolhido
        ,states.descontoMoraEscolhido
        // ,honorarioCalculadoValueState
        ,statesPropsValues
        ////,props        
    ])


    const [saldoAtualizadoState, setSaldoAtualizadoState] = useState()  

    useEffect(() => {
        if(descontoFinal){
            setSaldoAtualizadoState(descontoFinal + valorFinalAcordo)
        }
        else if(statesPropsValues.total){
            setSaldoAtualizadoState(statesPropsValues.total)
        }
    }, [descontoFinal, valorFinalAcordo, statesPropsValues.total])


    // useEffect(() => {
    //     if(descontoFinal){
    //         setSaldoAtualizadoState(descontoFinal + valorFinalAcordo)
    //     }
    //     else if(props.gerarAcordoDataSource?.arrayTotais?.total){
    //         setSaldoAtualizadoState(props.gerarAcordoDataSource.arrayTotais.total)
    //     }
    // }, [descontoFinal, valorFinalAcordo])


    const [descontosValidosState, setDescontosValidosState] = useState()
    const [jsonUpdate, setJsonUpdate] = useState({})

    const getDescontosValidos = () => {
        if (props.indexMultiViewItemsGerarAcordo === 1) {           
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": "_view_tituloCampanhaDescontosValidos",
                "fieldName": "_tituloid",
                "expressao": props.tituloId
            })            
            .then((sender) => {
                const resultDescontosValidosJson = JSON.parse(sender)
                setDescontosValidosState(resultDescontosValidosJson?.masterEntity?.[0]?.records)
                
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
    
    useEffect(() => {
        if(!descontosValidosState){
            getDescontosValidos()
        }
    }, [])

    const repaintForm = () => {
        _formGerarAcordo.current.instance.repaint()
    }

    const toastConfiguration = (visible, type, message, closeForm) => {
        console.log("entrou toastConfiguration")
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message,
            onHidden: closeForm
        })
    }


    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    const hidePopup = useCallback(() =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    },[popup]);

    const gerarBoleto = () => {
        //CODIGO ORIGINAL QUANDO PUDER GERAR BOLETO
        httpRequest('POST', requestsApi.gerarBoleto, requestsApi.basicAuth, {
            tituloId: props.tituloId,//proximaFichaDados.tituloId,
            applicationId: applicationID,
            vencimento: props.gerarAcordoDataSource.arrayTotais.vencimentoEntrada,
            parcelas: props.gerarAcordoDataSource.parcelasSelecionadasId,
            valorBoleto: valorFinalAcordo,
            contaCorrenteId: "",
            enderecoId: "",
            relationId: relationId
        })
        .then((sender) => {
            const resultSenderJson = JSON.parse(sender)
            toastConfiguration(true, 'success', `Boleto Gerado com sucesso!`)   

            // props.setBoletoBinarioId(resultSenderJson.mensagem)
            // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, boleto: resultSenderJson.mensagem})
   
            //para pegar o email do cliente
            httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": "cadastro",
                "fieldName": "id",
                "expressao": props.clienteId
            })
            .then((sender) => {
                const resultSenderJsonCliente = JSON.parse(sender) 
                // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, email : resultSenderJsonCliente.records[0].cryptemail})
                
                //para pegar os telefones
                httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": "cadastro_contatos",
                    "fieldName": "cadastro_id",
                    "expressao": props.clienteId
                })
                .then((sender) => {
                    const resultSenderJsonTelefones = JSON.parse(sender)
                    // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, telefones : resultSenderJsonTelefones.records})
                    props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, 
                        telefones : resultSenderJsonTelefones.records,
                        boleto: resultSenderJson.mensagem,
                        email : resultSenderJsonCliente.records[0].cryptemail
                    })
                    props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 2)
                })
                .catch((err) => {console.log(err)})
            })
            .catch((err) => {console.log(err)}) 

        })
        .catch((error) => {
            console.log(error)
            setMessageError(error.data.error)
            setPopup({
                ...popup
                    ,isVisible: true
                    ,message: "Atenção!"
            })
        })


        //CODIGO TESTE DE GERAR BOLETO SEM FAZER REQUISIÇÃO
        //para pegar o email do cliente
        // httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
        //     "applicationId": applicationID,
        //     "entityName": "cadastro",
        //     "fieldName": "id",
        //     "expressao": props.clienteId
        // })
        // .then((sender) => {
        //     const resultSenderJsonCliente = JSON.parse(sender) 
        //     console.log(resultSenderJsonCliente.records[0].cryptemail)
        //     // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, 
        //     //     email : resultSenderJsonCliente.records[0].cryptemail,
                
        //     // })

        //     //para pegar os telefones
        //     httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
        //         "applicationId": applicationID,
        //         "entityName": "cadastro_contatos",
        //         "fieldName": "cadastro_id",
        //         "expressao": props.clienteId
        //     })
        //     .then((sender) => {
        //         const resultSenderJsonTelefones = JSON.parse(sender)
        //         console.log(resultSenderJsonTelefones)
        //         props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, 
        //             telefones : resultSenderJsonTelefones.records,
        //             boleto: "32EAA062258E40C3A0EB2B591B8916D0",
        //             email : resultSenderJsonCliente.records[0].cryptemail
        //         })
        //         props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 2)
        //     })
        //     .catch((err) => {console.log(err)})
        // })
        // .catch((err) => {console.log(err)})  
    }

    function onOpcaoPagamentoChange(sender) {
        if (sender.value !== opcaoPagamentoItemSelecionado) {
            opcaoPagamentoItemSelecionado = sender.value;
            
            const opcoesParcelamento = {
                "À vista": 1,
                "Parcelamento": 2
            }

            // setStates({...states,                                                     
            //     descontoPrincipalEscolhido: 0,
            //     descontoJurosEscolhido: 0,
            //     descontoMultaEscolhido: 0,
            //     descontoHonorarioEscolhido: 0,
            // })

            let jsonStart = {
                ...props.jsonRequest,
                aVistaParcelado: opcoesParcelamento[sender.value]
            }

            httpRequest("POST", requestsApi.getFaseCalculadaTitulo, requestsApi.basicAuth, jsonStart)                                                
                .then(json => JSON.parse(json)[0])
                .then(data => {
                    console.log(data)
                    setGerarAcordoDataSource({
                        ...gerarAcordoDataSource,
                        resultgetFaseCalculadaTituloJsonData: {
                            ...gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData,
                            _descontoprincipal: data._descontoprincipal,
                            _descontodescricao: data._descontodescricao,
                            _descontohonorario: data._descontohonorario,
                            _descontojuros: data._descontojuros,
                            _descontomulta: data._descontomulta,
                            _isrecalcularhonorarioaplicandodesconto: data?._isrecalcularhonorarioaplicandodesconto
                        }
                    });                
                })
        }
    }

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const closeButtonPopupTemplateHtml = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: onHidenPopupHtmlTemplate
    }

    return(
        <div style={{padding: "0.5em"}}
        >
            {/* <ScrollView> */}
            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={popup.message}
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {messageError &&
                    <p>{messageError}</p>
                }
            </Popup>


            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
                onHidden={() => {
                    if(states.onHidden){
                        fecharFormAtual(tabPanelItems, indexTabPanelMain, setIndexTabPanelMain, props.hideUpfPopupForm)
                        setStates({...states, 
                            onHidden: false
                        })
                    }
                }}
            />
            {props.gerarAcordoDataSource ? 
            (
                <div>
                    <Form
                        colCount={4}
                        ref={_formGerarAcordo}
                        onFieldDataChanged={(item) => {
                            setJsonUpdate({
                                ...jsonUpdate
                                , id: item.value
                            })
                        }}
                    >
                        <GroupItem
                            colSpan={4}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxDateBox" 
                                dataField="Primeiro vencimento"
                                fieldName="_primeiroVencimento"
                                editorOptions=
                                {{ 
                                    value: gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._primeirovencimento, //props.gerarAcordoDataSource.arrayTotais.vencimento
                                    readOnly: true,
                                    dateSerializationFormat:"yyyy-MM-dd"
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox"  
                                dataField="Faixa de Atraso"
                                fieldName="_atraso" 
                                editorOptions=
                                {{ 
                                    value: gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._atraso,
                                    readOnly: true,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxTextBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Enquadrado na regra"
                                // dataSource={props.dataEntity}
                                fieldName="_descontodescricao"
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    value: gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontodescricao,
                                    readOnly: true,
                                }}
                            />

                            <GroupItem
                                colCount={2}
                                colSpan={2}
                            >

                                <SimpleItem
                                    colSpan={2} 
                                    deferRendering={false}
                                    render={() => {
                                        return(
                                            <div style={{"display": "flex"}}>
                                                <DropDownBox
                                                    width={"100%"}
                                                    value={jsonUpdate?.id}
                                                    valueExpr="id"
                                                    displayExpr="descricao"
                                                    visible={true}
                                                    dataSource={[jsonUpdate]}
                                                    deferRendering={true}
                                                    placeholder="Selecione uma opção"
                                                    contentRender={() => {
                                                        return (
                                                            <ScrollView width="100%" height="100%" useNative={true}>
                                                                <DataGrid
                                                                    // className="datagrid-view"
                                                                
                                                                    dataSource={descontosValidosState}
                                                                    contextMenuEnabled={true}
                                                                    // selectedRowKeys={`${jsonUpdate[senderSimpleItem.lookUpListSource]}` || []}
                                                                    keyExpr='id'
                                                                    onSelectionChanged={(sender) => {
                                                                        gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal = sender?.selectedRowsData?.[0]?._descontoprincipal 
                                                                        gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros = sender?.selectedRowsData?.[0]?._descontojuros
                                                                        gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta = sender?.selectedRowsData?.[0]?._descontomulta
                                                                        gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario = sender?.selectedRowsData?.[0]?._descontohonorario
                                                                        
                                                                        // sender?.selectedRowsData?.[0]?._validade
                                                                        setJsonUpdate({
                                                                            "id": sender?.selectedRowsData?.[0]?.id
                                                                            , "descricao": `Principal ${sender?.selectedRowsData?.[0]?._descontoprincipal}% Juros ${sender?.selectedRowsData?.[0]?._descontojuros}% Multa ${sender?.selectedRowsData?.[0]?._descontomulta}% Honorario ${sender?.selectedRowsData?.[0]?._descontohonorario}%`
                                                                        })
                                                                    }}

                                                                >

                                                                    <Column
                                                                        dataField={"_descontoprincipal"}
                                                                        caption={"Principal"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontohonorario"}
                                                                        caption={"Honorario"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontojuros"}
                                                                        caption={"Juros"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontomulta"}
                                                                        caption={"Multa"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_validade"}
                                                                        caption={"Validade"}
                                                                        dataType='date'
                                                                    />

                                                                    <Selection mode="single" keyExpr='id' />
                                                                    <Scrolling
                                                                        useNative={true}
                                                                        rowRenderingMode='infinity'
                                                                        showScrollbar='always'
                                                                    >
                                                                    </Scrolling>
                                                                    <Paging defaultPageSize={10} />
                                                                    <FilterRow visible={true} />
                                                                </DataGrid>
                                                            </ScrollView>
                                                        );
                                                    }}
                                                />

                                                <Button
                                                    style={{"marginLeft": "0.5em"}}
                                                    icon="refresh"
                                                    onClick={getDescontosValidos}
                                                />
                                            </div>
                                        )
                                    }}
                                >
                                    <Label 
                                        text="Campanha desconto"
                                        // location="left"
                                    />
                                </SimpleItem>
                            </GroupItem>

                            {props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 2 ?
                                (
                                    <SimpleItem
                                        colSpan={2}                                        
                                        editorType="dxRadioGroup" 
                                        // disabled={field.readOnly}
                                        // hint={field.hint}
                                        //caption="Primeiro vencimento:"
                                        dataField="Opção de pagamento"
                                        // value={states.radioOpcaoPagamento[0]}
                                        fieldName="opcaoDePagamento"
                                        // key={indice} 
                                        editorOptions=
                                        {{
                                            items: states.radioOpcaoPagamento,
                                            value: opcaoPagamentoItemSelecionado,
                                            layout: "horizontal",
                                            onValueChanged: onOpcaoPagamentoChange
                                        }}
                                    />
                                )
                                :
                                (
                                    <GroupItem
                                        colSpan={2}
                                        colCount={2}
                                    ></GroupItem>  
                                )
                            }

                            <SimpleItem
                                colSpan={2}
                                editorType="dxRadioGroup" 
                                dataField="Desconto"
                                fieldName="desconto"
                                editorOptions=
                                {{ 
                                    items: states.radioDesconto, 
                                    // defaultValue: "Percentual",
                                    value: states.descontoItemSelecionado,
                                    layout: "horizontal",
                                    onValueChanged: (sender) => {
                                        setStates({...states,
                                            descontoItemSelecionado: sender.value,
                                            descontoPrincipalEscolhido: 0,
                                            descontoJurosEscolhido: 0,
                                            descontoMultaEscolhido: 0,
                                            descontoHonorarioEscolhido: 0,
                                            descontoMoraEscolhido: 0
                                        }) 
                                        setDescontoFinal(0)
                                        setValorFinalAcordo(gerarAcordoDataSource.arrayTotais.total)
                                        repaintForm()
                                    }
                                }}
                            />
                        </GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        ></GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        ></GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        >

                            <SimpleItem
                                colSpan={1}
                            />

                            <SimpleItem
                                colSpan={1}
                            />

                            <SimpleItem
                                colSpan={1}
                                render={() => {
                                    return (
                                        <b>Máximo</b>
                                    )
                                }}
                            />

                            <SimpleItem
                                colSpan={1}
                                render={() => {
                                    return (
                                        <b>Escolhido</b>
                                    )
                                }}
                            />
                            
                        </GroupItem>

                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Valor Principal (+)"
                                // dataSource={props.dataEntity}
                                fieldName="valorPrincipal"
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                            return true 
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            valor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.valor,
                                    // value: props.gerarAcordoDataSource.arrayTotais.valor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Juros (+)"
                                fieldName="valorJuros"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            jurosvalor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.jurosvalor,
                                    // value: props.gerarAcordoDataSource.arrayTotais.jurosvalor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Mora (+)"
                                fieldName="moraValor"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            moraValor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.moraValor,
                                    // value:  props.gerarAcordoDataSource.arrayTotais.moraValor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Multa (+)"
                                fieldName="valorMulta"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            multavalor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.multavalor,
                                    // value: props.gerarAcordoDataSource.arrayTotais.multavalor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Honorário (+)"
                                fieldName="valorHonorario"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        // if(props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){
                                        //     setHonorarioCalculadoValueState(sender.value)
                                        // }
                                        // else{
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                honorariovalor: sender.value
                                            })
                                        // }
                                    },
                                    value: statesPropsValues.honorariovalor,
                                    // value: gerarAcordoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1 ? honorarioCalculadoValueState : props.gerarAcordoDataSource.arrayTotais.honorariovalor,
                                    min: 0
                                }}
                            />

                        </GroupItem>

                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        >
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox"  
                                dataField="Principal"
                                // dataSource={props.dataEntity}
                                fieldName="principalMaximo"
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" 
                                        ? gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                        : (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * props.gerarAcordoDataSource.arrayTotais.valor,
                                    readOnly: true,
                                    min: 0
                                }}  
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Juros"
                                fieldName="jurosMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                    : 
                                    (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                    props.gerarAcordoDataSource.arrayTotais.jurosvalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Mora"
                                fieldName="moraMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                    : 
                                    (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                    props.gerarAcordoDataSource.arrayTotais.moravalor,
                                    readOnly: true,
                                    min: 0,
                                    max: states.descontoItemSelecionado === "Percentual" ? 
                                    gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                    : 
                                    (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                    props.gerarAcordoDataSource.arrayTotais.moravalor
                                    
                                }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Multa"
                                fieldName="multaMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                    : 
                                    (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                    props.gerarAcordoDataSource.arrayTotais.multavalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Honorário"
                                fieldName="honorarioMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                    : 
                                    (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                    props.gerarAcordoDataSource.arrayTotais.honorariovalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />
                        </GroupItem>

                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        >

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Principal"
                                fieldName="principalEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoPrincipalEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {      
                                                setStates({
                                                    ...states,
                                                    descontoPrincipalEscolhido: sender.value
                                                })
                                            }}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                            : 
                                            (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * 
                                            props.gerarAcordoDataSource.arrayTotais.valor
                                            }
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({...states, 
                                //             descontoPrincipalEscolhido: sender.value})
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * 
                                //     props.gerarAcordoDataSource.arrayTotais.valor

                                // }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                fieldName="jurosEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoJurosEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {      
                                                setStates({
                                                    ...states,
                                                    descontoJurosEscolhido: sender.value
                                                })
                                            }}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                            : 
                                            (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                            props.gerarAcordoDataSource.arrayTotais.jurosvalor
                                            }
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({
                                //             ...states,
                                //             descontoJurosEscolhido: sender.value
                                //         })
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                //     props.gerarAcordoDataSource.arrayTotais.jurosvalor
                                // }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly} 
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Honorário"
                                fieldName="moraEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoMoraEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                setStates({
                                                    ...states, 
                                                    descontoMoraEscolhido: sender.value
                                                })
                                            }}
                                            value={states.descontoMoraEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                            : 
                                            (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                            props.gerarAcordoDataSource.arrayTotais.moravalor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({
                                //             ...states,
                                //             descontoHonorarioEscolhido: sender.value
                                //         });
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                //     props.gerarAcordoDataSource.arrayTotais.honorariovalor
                                // }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Multa"
                                fieldName="multaEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoMultaEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {      
                                                setStates({
                                                    ...states,
                                                    descontoMultaEscolhido: sender.value
                                                })
                                            }}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                            : 
                                            (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                            props.gerarAcordoDataSource.arrayTotais.multavalor
                                            }
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {      
                                //         setStates({
                                //             ...states,
                                //             descontoMultaEscolhido: sender.value
                                //         })
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                //     props.gerarAcordoDataSource.arrayTotais.multavalor
                                // }} 
                            />
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly} 
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Honorário"
                                fieldName="honorarioEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoHonorarioEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                setStates({
                                                    ...states, 
                                                    descontoHonorarioEscolhido: sender.value
                                                })
                                            }}
                                            value={states.descontoHonorarioEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                            : 
                                            (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                            props.gerarAcordoDataSource.arrayTotais.honorariovalor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({
                                //             ...states,
                                //             descontoHonorarioEscolhido: sender.value
                                //         });
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                //     props.gerarAcordoDataSource.arrayTotais.honorariovalor
                                // }} 
                            />
                        </GroupItem>

                        {/* <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            
                            

                            
                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            

                            
                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            

                            
                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={2}
                            colCount={2}
                        >

                            
                        </GroupItem> */}

                        <GroupItem

                            colSpan={2}
                            colCount={2}
                        >
                            {props.gerarAcordoDataSource.arrayTotais.cartorioValor > 0 &&
                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxNumberBox" 
                                    // disabled={field.readOnly}
                                    // hint={field.hint}
                                    //caption="Primeiro vencimento:" 
                                    dataField="Cartório (+)"
                                    fieldName="cartorioValor"
                                    // dataSource={props.dataEntity}
                                    // fieldName={field.fieldCaption}
                                    // key={indice} 
                                    editorOptions=
                                    {{ 
                                        format: "R$ #,##0.##;(R$ #,##0.##)",
                                        valueChangeEvent: "keyup",
                                        readOnly: !specialPermissions?.find((e) => {
                                            if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                                return true
                                            }
                                            else{
                                                return false
                                            }
                                        }),
                                        onValueChanged: (sender) => {
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                cartorioValor: sender.value
                                            })
                                        },
                                        value: statesPropsValues.cartorioValor,
                                        // value: props.gerarAcordoDataSource.arrayTotais.cartorioValor,
                                        min: 0
                                    }}
                                />
                            }

                            {props.gerarAcordoDataSource.arrayTotais.outrasDespesasValor > 0 &&
                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxNumberBox" 
                                    // disabled={field.readOnly}
                                    // hint={field.hint}
                                    //caption="Primeiro vencimento:" 
                                    dataField="Pré-jurídico (+)"
                                    fieldName="outrasDespesasValor"
                                    // dataSource={props.dataEntity}
                                    // fieldName={field.fieldCaption}
                                    // key={indice} 
                                    editorOptions=
                                    {{ 
                                        format: "R$ #,##0.##;(R$ #,##0.##)",
                                        valueChangeEvent: "keyup",
                                        readOnly: !specialPermissions?.find((e) => {
                                            if(e.PermissionName === "Acordo.ValorAvulso" && e._execute === 1){
                                                return true
                                            }
                                            else{
                                                return false
                                            }
                                        }),
                                        onValueChanged: (sender) => {
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                outrasDespesasValor: sender.value
                                            })
                                        },
                                        value: statesPropsValues.outrasDespesasValor,
                                        // value: props.gerarAcordoDataSource.arrayTotais.outrasDespesasValor,
                                        min: 0
                                    }}
                                />
                            }

                        </GroupItem>

                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Saldo Atualizado (=)"
                                fieldName="saldoAtualizado"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: saldoAtualizadoState ? saldoAtualizadoState : statesPropsValues.total,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Desconto (-)"
                                fieldName="valorDesconto"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: true,
                                    value: descontoFinal,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="VALOR A PAGAR"
                                fieldName="valorPagar"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: valorFinalAcordo,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />
                        </GroupItem>
                    </Form>
                    <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                        <div className="button-with-load-div" style={{marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            {opcaoPagamentoItemSelecionado === "À vista" ? 
                            (
                            <>
                                <Button
                                    width={130}
                                    height={40}
                                    text={props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 2 ? "Gerar Acordo": "Gerar Boleto"}
                                    type="default"
                                    stylingMode="contained"
                                    disabled={props.habilitarGerarAcordo === 0 ? true : gerarAcordoDisabledState}
                                    onClick={() => {
                                        //pegar todos os dados e enviar para a API 
                                        //...
                                        //ir para o proximo item

                                        //Gerar Acordo Á VISTA 
                                        if(props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 2) {
                                            if(!gerouAcordo){
                                                setGerarAcordoDisabledState(true)
                                                httpRequest('POST', requestsApi.getOpcoesParcelamentoCredor, requestsApi.basicAuth, {
                                                    // credorId: props.credorId,
                                                    tituloId: props.tituloId,
                                                    applicationId: applicationID,
                                                    filtroOperadorLotePortal: 1,
                                                    valorTotal: Number(+valorFinalAcordo.toFixed(2)),
                                                    valorEntrada: 0
                                                })
                                                .then((sender) => {                                              
                                                    //GERANDO ACORDO Á VISTA
                                                    httpRequest("POST", requestsApi.gerarAcordo, requestsApi.basicAuth, {
                                                        applicationId: applicationID,
                                                        tituloId: props.tituloId,//proximaFichaDados.tituloId,
                                                        vencimentoEntrada: props.gerarAcordoDataSource.arrayTotais.vencimentoEntrada,
                                                        vencimentoParcela: props.gerarAcordoDataSource.arrayTotais.vencimentoEntrada,
                                                        valorEntrada: valorFinalAcordo,
                                                        valorParcela: 0,
                                                        qtdParcela: 0,
                                                        parcelas: props.gerarAcordoDataSource.parcelasSelecionadasId,
                                                        relationId: relationId,
                                                        TipoDesconto: states.descontoItemSelecionado === "Percentual" ? 1 : 2, //1 percentual 2 valor
                                                        CalculadoPrincipal: statesPropsValues.valor,
                                                        CalculadoJuros: statesPropsValues.jurosvalor,
                                                        CalculadoMulta: statesPropsValues.multavalor,
                                                        CalculadoHonorario: statesPropsValues.honorariovalor,
                                                        
                                                        CalculadoMora: statesPropsValues.moraValor,
                                                        CalculadoCartorio: statesPropsValues.cartorioValor,
                                                        CalculadoOutrasDespesas:statesPropsValues.outrasDespesasValor,
                                                        DescontoMora: states.descontoMoraEscolhido,

                                                        DescontoPrincipal: states.descontoPrincipalEscolhido,
                                                        DescontoJuros: states.descontoJurosEscolhido,
                                                        DescontoMulta: states.descontoMultaEscolhido,
                                                        DescontoHonorario: states.descontoHonorarioEscolhido,
                                                        DescontoMora: states.descontoMoraEscolhido,
                                                        SaldoAtualizado: saldoAtualizadoState,
                                                        DescontoTotal: descontoFinal,
                                                        ValorTotalAPagar: valorFinalAcordo,
                                                    })
                                                    .then((sender) => {
                                                        const resultSenderJson = JSON.parse(sender)                                                        
                                                        setGerouAcordo(true)
                                                        toastConfiguration(true, 'success', `Acordo Gerado com sucesso!`, true)
                                                        // setUpfFloatPopupMessageVisibleGlobalState({
                                                        //     visible: false,
                                                        //     fixo: true,
                                                        //     mensagemId: null,
                                                        //     actionName: "acordo"
                                                        // })
                                                        executeActionUpfFloatPopupMessage(
                                                            "acordo"
                                                            ,null
                                                            ,requestsApi
                                                            ,applicationID
                                                            ,relationId
                                                            ,httpRequest
                                                        )

                                                        if(resultSenderJson?.idboleto && resultSenderJson?.arquivopdf){
                                                            props.getEmailTelefonesBoleto(resultSenderJson?.arquivopdf, resultSenderJson?.idboleto)
                                                        }
                                                        //Pegar template html 
                                                        // httpRequest('POST', requestsApi.execProcedureJson, requestsApi.basicAuth, {
                                                        //     "applicationId": applicationID,
                                                        //     "relationId": relationId,
                                                        //     "procedure": "_getAcordoConfissaoHtml",
                                                        //     "json": {},
                                                        //     "idValue": [
                                                        //         resultSenderJson.mensagem
                                                        //     ]
                                                        // })
                                                        // .then((sender) => {
                                                        //     const resultJsonTemplateHtml = JSON.parse(sender)
                                                        //     console.log(resultJsonTemplateHtml)
                                                        //     setHtmlTemplate(() => {
                                                        //         return {__html: resultJsonTemplateHtml.retorno}
                                                        //     })
                                                        //     openPopupHtmlTemplate()
                                                        // })
                                                        // .catch((err) => {console.log(err)})
                                                    })
                                                    .catch((err) => {
                                                        setGerarAcordoDisabledState(false)
                                                        setMessageError(err.data.error)
                                                        setPopup({
                                                            ...popup
                                                                ,isVisible: true
                                                                ,message: "Atenção!"
                                                        })
                                                })
                                                })
                                                .catch((err) => {
                                                    setGerarAcordoDisabledState(false)
                                                    setMessageError(err.data.error)
                                                    setPopup({
                                                        ...popup
                                                            ,isVisible: true
                                                            ,message: "Atenção!"
                                                    })
                                                })
                                            }
                                            else{
                                                //acordo já foi gerado
                                                setGerarAcordoDisabledState(false)
                                                setMessageError('Título em acordo')
                                                setPopup({
                                                    ...popup
                                                        ,isVisible: true
                                                        ,message: "Atenção!"
                                                })
                                            }

                                        }
                                        //Gerar boleto
                                        else if(props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 1) {
                                            gerarBoleto()
                                        }
                                    }}
                                />

                                {gerarAcordoDisabledState &&
                                    <LoadIndicator/>
                                }
                                
                            </>
                            ) 
                            : 
                            (
                            <>
                                <Button
                                    width={130}
                                    height={40}
                                    type="default"
                                    stylingMode="contained"
                                    disabled={gerarAcordoDisabledState}
                                    text={props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 2 ? "Próximo": "Gerar Boleto"}
                                    onClick={() => {                                        
                                        props.gerarAcordoDataSource.arrayTotais._total = valorFinalAcordo;
                                        
                                        //pegar todos os dados e enviar para a API 
                                        //...
                                        //ir para o proximo item

                                        //Gerar Acordo parcelado
                                        if(props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 2) {
                                            setGerarAcordoDisabledState(true)
                                            if(!gerouAcordo){
                                                httpRequest('POST', requestsApi.getOpcoesParcelamentoCredor, requestsApi.basicAuth, {
                                                    // credorId: props.credorId,
                                                    tituloId: props.tituloId,
                                                    applicationId: applicationID,
                                                    filtroOperadorLotePortal: 1,
                                                    valorTotal: Number(+valorFinalAcordo.toFixed(2)),
                                                    valorEntrada: 0
                                                })
                                                .then((sender) => {
                                                    const resultSenderJson = JSON.parse(sender)
                                                    if(Object.keys(resultSenderJson).length > 0){
                                                        resultSenderJson.forEach(obj => {
                                                            const entradaMaxima = obj._total - (obj._valorminimoparcela * obj._qtdparcela);
                                                            obj["_entrada"] = obj._entradaminima.toFixed(2);
                                                            obj["_entradamaxima"] = Number(entradaMaxima.toFixed(2));
                                                        }); 
                                                        props.setAcordoDataGridDataSource(resultSenderJson);
                                                        props.setDadosPagementoAcordoObjectState(
                                                            {
                                                                TipoDesconto: states.descontoItemSelecionado === "Percentual" ? 1 : 2, //1 percentual 2 valor
                                                                CalculadoPrincipal: statesPropsValues.valor,
                                                                CalculadoJuros: statesPropsValues.jurosvalor,
                                                                CalculadoMulta: statesPropsValues.multavalor,
                                                                CalculadoHonorario: statesPropsValues.honorariovalor,
                                                                
                                                                CalculadoMora: statesPropsValues.moraValor,
                                                                CalculadoCartorio: statesPropsValues.cartorioValor,
                                                                CalculadoOutrasDespesas: statesPropsValues.outrasDespesasValor,
                                                                DescontoMora: states.descontoMoraEscolhido,

                                                                DescontoPrincipal: states.descontoPrincipalEscolhido,
                                                                DescontoJuros: states.descontoJurosEscolhido,
                                                                DescontoMulta: states.descontoMultaEscolhido,
                                                                DescontoHonorario: states.descontoHonorarioEscolhido,
                                                                DescontoMora: states.descontoMoraEscolhido,
                                                                SaldoAtualizado: saldoAtualizadoState,
                                                                DescontoTotal: descontoFinal,
                                                                ValorTotalAPagar: valorFinalAcordo
                                                            }
                                                        )
                                                        props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                                    }
                                                    else{
                                                        setGerarAcordoDisabledState(false)
                                                        setMessageError('Não há opção de parcelamento configurada')
                                                        setPopup({
                                                            ...popup
                                                                ,isVisible: true
                                                                ,message: "Atenção!"
                                                        })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setGerarAcordoDisabledState(false)
                                                    setMessageError(err.data.error)
                                                    setPopup({
                                                        ...popup
                                                            ,isVisible: true
                                                            ,message: "Atenção!"
                                                    })
                                                })
                                            } else {
                                                //acordo já foi gerado
                                                setGerarAcordoDisabledState(false)
                                                setMessageError('Título em acordo')
                                                setPopup({
                                                    ...popup
                                                        ,isVisible: true
                                                        ,message: "Atenção!"
                                                })
                                            }
                                        }
                                
                                        //Gerar boleto
                                        else if(props.gerarAcordoDataSource.arrayTotais.tipoNegociacao.id === 1) {
                                            gerarBoleto()
                                        }
                                    }}
                                />

                                {gerarAcordoDisabledState &&
                                    <LoadIndicator/>
                                }
                            </>
                            )}
                            
                        </div>

                        <div>
                            <Button
                                width={130}
                                height={40}
                                text="Anterior"
                                type="default"
                                stylingMode="contained"
                                onClick={() => {
                                    props.setGerarAcordoDataSource(null)
                                    //pegar todos os dados e enviar para a API 
                                    //...
                                    //ir para o proximo item
                                    opcaoPagamentoItemSelecionado = 'Parcelamento';
                                    props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                                }}
                            />
                        </div>
                    </div>
                </div>
            )
            :
            (
                <Loading/>
            )
            }
            {/* <div >
                

            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "end", width: "50%"}}>
                    <b>Máximo</b>
                    <b>Escolhido</b>
                </div>
            </div>

            /> */}
            {/* </ScrollView> */}
        </div>
    )
}

export default memo(Passo2);