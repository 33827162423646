const { createContext, useContext, useState } = require("react");

const ProximaFichaDados = createContext();

export const ProximaFichaDadosProvider = ({ children }) => {
  const [proximaFichaDados, setProximaFichaDados]= useState({
    tituloId: null,
    templateTelecobrancaId: null,
    widgets: null,
    indexFichaAtual: 0,
    indexRegraAtual: 0,
    nomeFilaAtual: null, 
    nomeRegraAtual: null,
    credorId: "",
    clienteId: null,
    _qtdOutrosContratosClienteCredorAtual: 0,
    _qtdOutrosContratosCliente: 0
  });

  return (
    <ProximaFichaDados.Provider value={{ proximaFichaDados, setProximaFichaDados }}>
      {children}
    </ProximaFichaDados.Provider>
  );
};

export const useProximaFichaDados = () => useContext(ProximaFichaDados);