const { createContext, useContext, useState } = require("react");

const PausasDisponiveisGlobalState = createContext();

export const PausasDisponiveisGlobalStateProvider = ({ children }) => {
  const [pausasDisponiveisGlobalState, setPausasDisponiveisGlobalState] = useState(null)

  return (
    <PausasDisponiveisGlobalState.Provider value={{ pausasDisponiveisGlobalState, setPausasDisponiveisGlobalState }}>
      {children}
    </PausasDisponiveisGlobalState.Provider>
  );
};

export const usePausasDisponiveisGlobalState = () => useContext(PausasDisponiveisGlobalState);