import './style.css'
import {useState, useEffect} from 'react'
import TextBox from 'devextreme-react/text-box';

const UpfAudio = (props) => {
    console.log(props)

    const [valueState, setValueState] = useState()

  //   const insertUpdate = () => {
        
  //     const objetctData = {
  //         "id": props.dataEntity.id
  //     }
  //     objetctData[`${props.dataField}`] = props.dataEntity[props.dataField]
      
  //     if(props.dataEntity?.id){
  //         httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
  //             "applicationId": applicationID,
  //             "json": [objetctData],
  //             "entityName": props.entityName,
  //             "relationId": relationId,
  //         })
  //         .then((sender) => {
  //             console.log(sender)
  //         })
  //         .catch((err) => {
  //             // alert(err)
  //         })
  //     }
  //     else{
  //         httpRequest('POST', requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
  //             "applicationId": applicationID,
  //             "json": [props.dataEntity],
  //             "entityName": props.entityName,
  //             "relationId": relationId,
  //         })
  //         .then((sender) => {
  //             console.log(sender)
  //         })
  //         .catch((err) => {
  //             // alert(err)
  //         })
  //     }
  // }

    useEffect(() => {
      if(props.dataEntity){
        setValueState(props.dataEntity)
        // const objResult = {

        // }
        // props.setDadosDaEntidadeDetalheState({
        //   ...props.dadosDaEntidadeDetalheState
        //   ,props.dadosDaEntidadeDetalheState[`${props}`] : 
        // })
      }
    }, [])


    return (
        <div>
          {valueState &&
            // (
              <audio  src={`${valueState}`} controls />
            // ) 
            // : 
            // (
            //   <TextBox
            //     value={valueState}
            //     // disabled={true}
            //     onValueChange={(e) => {
            //       console.log(e)
            //       setValueState()
            //     }}
            //   />
            // )
          }
              {/* <audio  src="http://unnisoft.lh.com.br:8092/grv/v1/arquivo/audio/ligacao/6?callID=01002169418498517264" controls/> */}
        </div>
    )
}

export default UpfAudio;