import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import UpfCardKanban from "../UpfCardKanban"

const UpfListKanban = (props) => {

    return(
        <div className="list">
            {/* <div className="list-title dx-theme-text-color">{props.title}</div> */}
            <ScrollView
                className="scrollable-list"
                direction="vertical"
                showScrollbar="always"
                width='100%'
                useNative={true}
                 height='100%'
            >
            {/* <Sortable
                className="sortable-cards"
                group="cardsGroup"
                data={props.index}
                onDragStart={props.onTaskDragStart}
                onReorder={props.onTaskDrop}
                onAdd={props.onTaskDrop}
                width="100%"
                height="auto"
            >  */}
                {props.tasks.map((task, indiceTask) => {
                    return(
                        <UpfCardKanban
                            resultTarefasFuncionarioJsonState={props.resultTarefasFuncionarioJsonState}
                            key={task.id}
                            task={task}
                            indiceTask={indiceTask}
                            employeesMap={props.employeesMap}
                            funcionarios={props.funcionarios}
                            dataLookup={props.dataLookup}
                            refreshData={props.refreshData}
                            objectPermissionsState={props.objectPermissionsState}
                            updateData={props.updateData}
                        />
                    )
                })}
                
            {/* </Sortable> */}
            </ScrollView>
        </div>
    )
}

export default UpfListKanban;