import { GoUpload } from "react-icons/go"
import "./style.css"

const IconsToolBarButtonUPF = ({options}) => {
    return(
        <div className="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon" onClick={options.onClick}>
            <div className="IconsToolBarButtonUPF">
                <div className="dx-item-content dx-toolbar-item-content">
                    <div className="dx-button-content">
                        {options.icon === "GoCloudUpload" &&
                            <GoUpload  size={18}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconsToolBarButtonUPF;