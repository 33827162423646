const { createContext, useContext, useState } = require("react");

const BackendJson = createContext();

export const BackendJsonProvider = ({ children }) => {
  const [backendJson, setBackendJson]= useState();

  return (
    <BackendJson.Provider value={{ backendJson, setBackendJson }}>
      {children}
    </BackendJson.Provider>
  );
};

export const useBackendJson = () => useContext(BackendJson);