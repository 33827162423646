const { createContext, useContext, useState } = require("react");

const AbrirPausaResultJsonGlobalState = createContext();

export const AbrirPausaResultJsonGlobalStateProvider = ({ children }) => {
  const [abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState] = useState(false)

  return (
    <AbrirPausaResultJsonGlobalState.Provider value={{ abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState }}>
      {children}
    </AbrirPausaResultJsonGlobalState.Provider>
  );
};

export const useAbrirPausaResultJsonGlobalState = () => useContext(AbrirPausaResultJsonGlobalState);