import MultiView from 'devextreme-react/multi-view';
import {useState, useEffect, useCallback, memo} from "react"
import ScrollView from 'devextreme-react/scroll-view';
import Passo1 from "./EtapasAcordo/Passo1"
import Passo2 from "./EtapasAcordo/Passo2"
import Passo3 from "./EtapasAcordo/Passo3"
import Boleto from './EtapasAcordo/Boleto';
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import { LoadPanel } from 'devextreme-react/load-panel';
import Loading from '../../CardComponents/Loading';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import "./style.css"

const BaneseCardNegociacaoCustomForm = (props) => {
    console.log(props)
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [modeEdit, setModeEdit] = useState(true)

    const contentTab = (sender) => {
        if(sender.component){
            return(
                <>
                    {sender.component}
                </>
            )
        }
    }

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    //---------------------------


    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        loadPanelVisible: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)
    const [disabledButtonState, setDisabledButtonState] = useState(true)


    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()
    const [indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo] = useState(0)
    const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState()
    
    const[arrayPendenciasProcessadasState, setArrayPendenciasProcessadasState] = useState("")
    const[boletoState, setBoletoState] = useState()

    const [listaSolucaoState, setListaSolucaoState] = useState([])
    const [listaOcorrenciaState, setListaOcorrenciaState] = useState([])
    const [totalCobrancaState, setTotalCobrancaState] = useState()
    const [descontoTotalCobrancaState, setDescontoTotalCobrancaState] = useState()
    const [condicaoRenegociacaoDataSourceState, setCondicaoRenegociacaoDataSourceState] = useState({})

    const [states, setStates] = useState({
        dataSource: null
    })

    const [renegociacaoSimulacaoState, setRenegociacaoSimulacaoState] = useState(null)
    const [dataSourcePrimeiraTela, setDataSourcePrimeiraTela] = useState(null)
    const [renegociacaoConfirmacaoJsonState, setRenegociacaoConfirmacaoJsonState] = useState(null)
    

    const httpRequestBanese = (method, url, bodyRequest) =>{
        const isJson = (data) => {
            try {
                JSON.parse(data);
            } catch (e) {
                return false;
            }
        
            return true;
        }
    
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);        
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("token", "617C6FD67BB04EB78FCACF0EFF619109");//token temporario banese
           
            xhr.onload = function () {

            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            }
            else if(this.status > 400) {
                reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
                });
            }
            };
            xhr.onerror = function () {
            reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
            });
            };
            xhr.send(JSON.stringify(bodyRequest));
        })
    }

    const [dataSourceTelefonesState, setDataSourceTelefonesState] = useState([])

    const [dataSourceParceladoEntradaState, setDataSourceParceladoEntradaState] = useState()
    const [dataSourceParceladoState, setDataSourceParceladoState] = useState()
    const [dataSourceAVistaState, setDataSourceAVistaState] = useState()

    const [valorDividaRenegociada, setValorDividaRenegociada] = useState()
    const [tipoPagamentoSelecionadoIdState, setTipoPagamentoSelecionadoIdState] = useState(3)

    const [requestsBaneseState, setRequestsBaneseState] = useState()

    const multiViewItems = [
        {
            className: "tab-sheet"
            ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <Passo1 
                                requestsBaneseState={requestsBaneseState}
                                tituloId={props.tituloId}
                                userType={"operador"}
                                showTitle={props.showTitle}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                renegociacaoSimulacaoState={renegociacaoSimulacaoState}
                                dataSourcePrimeiraTela={dataSourcePrimeiraTela}
                            />
                        </ScrollView>
            ,text: Math.random()
        },
        {
            className: "tab-sheet"
            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                            <Passo2
                                requestsBaneseState={requestsBaneseState}
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                setArrayPendenciasProcessadasState={setArrayPendenciasProcessadasState}
                                arrayPendenciasProcessadasState={arrayPendenciasProcessadasState}
                                setListaSolucaoState={setListaSolucaoState}
                                setListaOcorrenciaState={setListaOcorrenciaState}
                                setBoletoState={setBoletoState}
                                setTotalCobrancaState={setTotalCobrancaState}
                                setDescontoTotalCobrancaState={setDescontoTotalCobrancaState}
                                descontoTotalCobrancaState={descontoTotalCobrancaState}
                                // setBoletoBinarioId={setBoletoBinarioId}
                                condicaoRenegociacaoDataSourceState={condicaoRenegociacaoDataSourceState}
                                setCondicaoRenegociacaoDataSourceState={setCondicaoRenegociacaoDataSourceState}
                                renegociacaoSimulacaoState={JSON.parse(sessionStorage.getItem("dataSourceBaneseJson")) ? JSON.parse(sessionStorage.getItem("dataSourceBaneseJson"))?.renegociacao : {}}
                                setRenegociacaoConfirmacaoJsonState={setRenegociacaoConfirmacaoJsonState}
                                setDataSourceAVistaState={setDataSourceAVistaState}
                                setDataSourceParceladoState={setDataSourceParceladoState}
                                setDataSourceParceladoEntradaState={setDataSourceParceladoEntradaState}
                                dataSourceAVistaState={dataSourceAVistaState}
                                dataSourceParceladoState={dataSourceParceladoState}
                                dataSourceParceladoEntradaState={dataSourceParceladoEntradaState}
                                setValorDividaRenegociada={setValorDividaRenegociada}
                                valorDividaRenegociada={valorDividaRenegociada}
                                tipoPagamentoSelecionadoIdState={tipoPagamentoSelecionadoIdState}
                                setTipoPagamentoSelecionadoIdState={setTipoPagamentoSelecionadoIdState}
                                modeEdit={modeEdit}
                                setModeEdit={setModeEdit}
                            />
                        </ScrollView>
            ,text: Math.random()
        },
        {
        className: "tab-sheet"
        ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                        <Passo3 
                            requestsBaneseState={requestsBaneseState}
                            tituloId={props.tituloId}
                            clienteId={props.clienteId}
                            setListaSolucaoState={setListaSolucaoState}
                            indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                            setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                            gerarAcordoDataSource={gerarAcordoDataSource}
                            setGerarAcordoDataSource={setGerarAcordoDataSource}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            setArrayPendenciasProcessadasState={setArrayPendenciasProcessadasState}
                            arrayPendenciasProcessadasState={arrayPendenciasProcessadasState}
                            listaSolucaoState={listaSolucaoState}
                            listaOcorrenciaState={listaOcorrenciaState}
                            boletoState={boletoState}
                            totalCobrancaState={totalCobrancaState}
                            setTotalCobrancaState={setTotalCobrancaState}
                            setDescontoTotalCobrancaState={setDescontoTotalCobrancaState}
                            descontoTotalCobrancaState={descontoTotalCobrancaState}
                            condicaoRenegociacaoDataSourceState={condicaoRenegociacaoDataSourceState}
                            setCondicaoRenegociacaoDataSourceState={setCondicaoRenegociacaoDataSourceState}
                            dataSourceTelefonesState={dataSourceTelefonesState}
                            renegociacaoConfirmacaoJsonState={renegociacaoConfirmacaoJsonState}
                            tipoPagamentoSelecionadoIdState={tipoPagamentoSelecionadoIdState}
                            renegociacaoSimulacaoState={JSON.parse(sessionStorage.getItem("dataSourceBaneseJson")) ? JSON.parse(sessionStorage.getItem("dataSourceBaneseJson"))?.dados : {}}
                        />
                    </ScrollView>
        ,text: Math.random()
        }
    ]

    useEffect(() => {
        if(!renegociacaoSimulacaoState){
            sessionStorage.setItem("dataSourceBaneseJson", JSON.stringify({}))

            const getView = `https://sicol.revolution.revolutioncrm.com.br:8082/revolutionService/getView?viewName=viewConfiguracaocredorfuncionario&filtros=_credorId='${props.credorId}'and _relationId='${relationId}'`
       
            httpRequestBanese('GET', getView)
            .then((sender) => {
                const resultGetViewJson = JSON.parse(sender)
                console.log(resultGetViewJson)
                const porta = resultGetViewJson?.[0]?._porta

                const requestsBanese = {
                    "renegociacaoSimulacao": `http://192.168.0.215:${porta}/baneseCardApi/renegociacaoSimulacao`,
                    "renegociacaoSimulacaoParcelamento": `http://192.168.0.215:${porta}/baneseCardApi/renegociacaoSimulacaoParcelamento`,
                    "renegociacaoConfirmacao": `http://192.168.0.215:${porta}/baneseCardApi/renegociacaoConfirmacao`,
                    "getSimulacaoDados": `http://192.168.0.215:${porta}/baneseCardApiV2/getSimulacaoDados`,
                    "prosseguirSimulacao": `http://192.168.0.215:${porta}/baneseCardApiV2/prosseguirSimulacao`,
                    
                }
                setRequestsBaneseState(requestsBanese)

                httpRequestBanese('POST', requestsBanese?.getSimulacaoDados, {
                    "relationId": relationId,
                    "tituloId": props.tituloId
                })
                .then((sender) => {
                    const resultRenegociacaoSimulacaoJson = JSON.parse(sender)
                    setDataSourcePrimeiraTela(resultRenegociacaoSimulacaoJson)
                })
                .catch((error) => {
                    console.log(error)
                    if(error?.data?.error){
                        setMessage(error.data.error)
                        setVisible(true)
                    }
                    else{
                        setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                        setVisible(true)
                    }
                })
    
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": "_cadastro",
                    "fieldName": "id",
                    "expressao": props.clienteId,
                })
                .then((sender) => {
    
                    const resultGetDataEntityJson = JSON.parse(sender)
    
                    const contatos = resultGetDataEntityJson?.detailEntity?.find((e) => e.entityName === "_cadastro_contatos")
                    if(contatos?.records?.length > 0){
                        setDataSourceTelefonesState(contatos?.records)
                    }
    
                })
                .catch((error) => {
                    if(error?.data?.error){
                        setMessage(error.data.error)
                        setVisible(true)
                    }
                    else{
                        setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                        setVisible(true)
                    }
                })

                httpRequestBanese('POST', requestsBanese?.prosseguirSimulacao, {
                    "relationId": relationId,
                    "tituloId": props.tituloId
                })
                .then((sender) => {
                    const resultRenegociacaoSimulacaoJson = JSON.parse(sender)
                    setRenegociacaoSimulacaoState(resultRenegociacaoSimulacaoJson)
                    sessionStorage.setItem("dataSourceBaneseJson", JSON.stringify(resultRenegociacaoSimulacaoJson))
                    setDataSourceAVistaState({
                        valorRebate: resultRenegociacaoSimulacaoJson?.renegociacao?.rebateMaximo, 
                        valorPagamento: (resultRenegociacaoSimulacaoJson?.renegociacao?.correcaoCheia - resultRenegociacaoSimulacaoJson?.renegociacao?.rebateMaximo),
                        vencimentoPagamento: new Date()
                    })
                })
                .catch((error) => {
                    console.log(error)
                    if(error?.data?.error){
                        setMessage(error.data.error)
                        setVisible(true)
                    }
                    else{
                        setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                        setVisible(true)
                    }
                })

            })
            .catch((error) => {
                if(error?.data?.error){
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else{
                    setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                    setVisible(true)
                }
            })
        }
    }, [renegociacaoSimulacaoState])

    const RenderMultiView = memo(() => {
        return (
            <MultiView
                dataSource={multiViewItems} 
                deferRendering={false}
                activeStateEnabled={false}
                itemRender={contentTab}
                selectedIndex={indexMultiViewItemsGerarAcordo}
                tabIndex={indexMultiViewItemsGerarAcordo}
                repaintChangesOnly={true}
                animationEnabled={false}
                swipeEnabled={false}
                showNavButtons={true}
                scrollByContent={true}
                loop={false}
                // selectedItem={multiViewItems[indexMultiViewItemsGerarAcordo]}
            />
        )
    })

    return(
        <div>
            <Popup
                visible={visible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {message &&
                    <p>{message}</p>
                }
            </Popup>

            {dataSourcePrimeiraTela ?
                (
                    <RenderMultiView/>
                )
                :
                (
                    <Loading/>
                )
            }
        </div>
    )
}

export default memo(BaneseCardNegociacaoCustomForm);