import {useState, useEffect} from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';


const DetailTemplate = (props) => {
  // const getItens = (key) => new DataSource({
  //   store: new ArrayStore({
  //     data: props.parcelamentosParcelas,
  //     key: 'id',
  //   }),
  //   filter: ['_cobransasacordosimularparcelamentosid', '=', key], 
  // });
  // console.log(props)

  const [dataSourceState, setDataSourceState] = useState([])

  const getDataSource = (key) => {
    const arrayResult = []
    for(let c = 0; c <= props.parcelamentosParcelas.length - 1; c++){
      if(props.parcelamentosParcelas[c]._cobransasacordosimularparcelamentosid === key){
        arrayResult.push(props.parcelamentosParcelas[c])
      }
    }
    setDataSourceState(arrayResult)
  }
  // const dataSource = getItens(props.data.key);

  useEffect(() => {
    if(props.data.key){
      getDataSource(props.data.key)
    }

  }, [])

  return (
    <>
      <div className="master-detail-caption">
        {`Parcelas:`}
      </div>
      <DataGrid
        dataSource={dataSourceState}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column
          dataField="numeroparcela"
          caption="Número parcela"
          dataType='number'
          // defaultSortOrder={item?.defaultSortOrder}
          // defaultSortIndex={item?.defaultSortIndex}                                          
        />
        <Column
          dataField="datavencimento"
          caption="Data vencimento"
          dataType='date'                                          
        />
        <Column
          dataField="valorprincipal"
          caption="Valor principal"
          format="R$ #,##0.##;(R$ #,##0.##)"
        />
        <Column
          dataField="valorjuros"
          caption="Valor juros"
          format="R$ #,##0.##;(R$ #,##0.##)"
        />
        <Column
          dataField="valortarifa"
          caption="Valor tarifa"
          format="R$ #,##0.##;(R$ #,##0.##)"
        />
        <Column
          dataField="valortarifaparcela"
          caption="Valor tarifa parcela"
          format="R$ #,##0.##;(R$ #,##0.##)"
        />
        <Column
          dataField="valortotal"
          caption="Valor total"
          format="R$ #,##0.##;(R$ #,##0.##)"
        />
      </DataGrid>
    </>
  );
};

export default DetailTemplate;