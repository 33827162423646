const { createContext, useContext, useState } = require("react");

const DataSourceLastMenssageSocketGlobalState = createContext();

export const DataSourceLastMenssageSocketGlobalStateProvider = ({ children }) => {
  const [dataSourceLastMenssageSocketGlobalState, setDataSourceLastMenssageSocketGlobalState]= useState({
    ativo: false
  });

  return (
    <DataSourceLastMenssageSocketGlobalState.Provider value={{ dataSourceLastMenssageSocketGlobalState, setDataSourceLastMenssageSocketGlobalState }}>
      {children}
    </DataSourceLastMenssageSocketGlobalState.Provider>
  );
};

export const useDataSourceLastMenssageSocketGlobalState = () => useContext(DataSourceLastMenssageSocketGlobalState);