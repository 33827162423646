import MultiView from 'devextreme-react/multi-view';
import { useState, useEffect } from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { httpRequest } from '../../../functions';
import { applicationID } from '../../../api/ApplicationID';
import { convertDate } from '../../../functions/convertDate';
import Passo1 from "./EtapasAcordo/Passo1"
import Passo2 from "./EtapasAcordo/Passo2"
import Passo3 from "./EtapasAcordo/Passo3"
import ConsultaBoletoForm from '../../ConsultaBoletoForm';
import "./style.css"

const GerarAcordoForm = (props) => {
    const {requestsApi} = useRequestsApi()

    console.log(props.habilitarGerarAcordo)

    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [dadosPagementoAcordoObjectState, setDadosPagementoAcordoObjectState] = useState()

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const entityStructureFieldsDefs = [
        {
            fieldName: "_parcela",
            fieldCaption: "Parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TipoParcela",
            fieldCaption: "Tipo parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_NumeroDocumento",
            fieldCaption: "Número Documento",
            editorType: null,
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_vencimento",
            fieldCaption: "Vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorAtualizacao",
            fieldCaption: "Mora Valor Atualização",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorParcial",
            fieldCaption: "Mora Valor Parcial",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorQuitacao",
            fieldCaption: "Mora Valor Quitação",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_Valor",
            fieldCaption: "Principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaAtualizacao",
            fieldCaption: "Taxa Mora Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaParcial",
            fieldCaption: "Taxa Mora Parcial",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaQuitacao",
            fieldCaption: "Taxa Mora Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoAtualizacao",
            fieldCaption: "Taxa Descrição Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaAtualizacao",
            fieldCaption: "Juros Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaAtualizacao",
            fieldCaption: "Multa Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaAtualizacao",
            fieldCaption: "Honorário Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoQuitacao",
            fieldCaption: "Taxa Descricao Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaQuitacao",
            fieldCaption: "Juros Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaQuitacao",
            fieldCaption: "Multa Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaQuitacao",
            fieldCaption: "Honorário Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_JurosValorQuitacao",
            fieldCaption: "Juros Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorQuitacao",
            fieldCaption: "Multa Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_JurosValorAtualizacao",
            fieldCaption: "Juros Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorAtualizacao",
            fieldCaption: "Multa Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobrePrincipal",
            fieldCaption: "Honorário sobre principal",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobreParcelasVincendas",
            fieldCaption: "Honorário Parcelas Vincendas",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorAtualizacao",
            fieldCaption: "Honorário Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorQuitacao",
            fieldCaption: "Honorário Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_DiasAtraso",
            fieldCaption: "Atraso",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorQuitacao",
            fieldCaption: "Valor a pagar (quitação)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorAtualizacao",
            fieldCaption: "Valor a pagar (atualização)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_NumeroAcordo",
            fieldCaption: "Número acordo",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_isacordo",
            fieldCaption: "Acordo",
            editorType: 'dxCheckBox',
            format: null,
            visible: 1,
            showInColumnChooser: 1
        }

    ]

    const senderFieldNamesCheckBox = ["_isacordo"]

    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])


    const [dateBoxState, setDateBoxState] = useState(null)
    const [arraySelecionadosState, setArraySelecionadosState] = useState([])



    const getParcelasVencidasVincendasFunction = (dataSelecionada) => {
        setArraySelecionadosState([])
        showLoadPanel()
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
            // entityName: "_titulo_parcela_vencidas_vincendas",
            // fieldName: "_tituloid",
            tituloId: props.tituloId,//proximaFichaDados.tituloId,
            applicationId: applicationID,
            parcelas: props.parcelasIdArray,
            tipoNegociacao: 2, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
            dataCalculo: dataSelecionada ? dataSelecionada : stringDateResult
        })
        .then((sender) => {
            if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                setMessage("Valor de getParcelasVencidasVincendas inválido!")
                setVisible(true)
            }
            else{
                const resultSenderJson = JSON.parse(sender)

                resultSenderJson.sort((a, b) => { return b?._DiasAtraso - a?._DiasAtraso})

                //pega os campos checkbox e altera de null pra 0
                for (let c = 0; c <= resultSenderJson.length - 1; c++) {
                    for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                        if (resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                            resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] = 0
                        }
                    }
                }
                let TotalValorAtualizacao = 0;
                let TotalValorQuitacao = 0;
                let TotalValor = 0;
                resultSenderJson.map(val => {
                    TotalValorAtualizacao += val._TotalValorAtualizacao
                    TotalValorQuitacao += val._TotalValorQuitacao
                    TotalValor += val._Valor
                })
                // setTotals({ _TotalValorAtualizacao: TotalValorAtualizacao, _TotalValorQuitacao: TotalValorQuitacao, _Valor: TotalValor })
                
                setDataSourceParcelasVencidasVincendasState(resultSenderJson)
                

                // props.setDataSourceParcelasVencidasVincendasState(resultSenderJson)



                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(resultSenderJson, entityStructureFieldsDefs)
                //

                hideLoadPanel()
            }
            
        })
        .catch((error) => {
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    const [dataLookup, setDataLookup] = useState()

    useEffect(() => {
        getParcelasVencidasVincendasFunction()
        if(!dataLookup){
            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "TipoNegociacao"
                    },
                    {
                        "lookUpEntity": "OpcaoNegociacao"
                    },
                    {
                        "lookUpEntity": "_tipo_contato"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    }
                ]
            })
                .then((sender) => {
                    const resultJson = JSON.parse(sender)
                    setDataLookup(resultJson)
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })
        }
    }, [])
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()
    const [indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo] = useState(0);
    const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState();
    const [acordoDataGridDataSource, setAcordoDataGridDataSource] = useState();
    const [jsonRequest, setJsonRequest] = useState({});  
    
    
    const getEmailTelefonesBoleto = (boletoPdf, boletoId) => {
        //para pegar o email do cliente
        httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro",
            "fieldName": "id",
            "expressao": props.clienteId
        })
        .then((sender) => {
            const resultSenderJsonCliente = JSON.parse(sender) 
            // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, email : resultSenderJsonCliente.records[0].cryptemail})
            
            //para pegar os telefones
            httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": "_cadastro_contatos",
                "fieldName": "_cadastro_id",
                "expressao": props.clienteId
            })
            .then((sender) => {
                const resultSenderJsonTelefones = JSON.parse(sender)
                setGerarAcordoDataSource({...gerarAcordoDataSource, 
                    telefones : resultSenderJsonTelefones.records,
                    boletoPdf: boletoPdf,
                    boletoId: boletoId,
                    email : resultSenderJsonCliente.records[0]._cryptemail
                })
                setIndexMultiViewItemsGerarAcordo(multiViewItems?.length - 1)
            })
            .catch((err) => {
            setGerarAcordoDataSource({...gerarAcordoDataSource, 
                boletoPdf: boletoPdf,
                boletoId: boletoId,
                email : resultSenderJsonCliente.records[0]._cryptemail
            })
            setIndexMultiViewItemsGerarAcordo(multiViewItems?.length - 1)})
        })
        .catch((err) => {console.log(err)}) 
    }

    const multiViewItems = [
        {
            //  title: itemClicado.itemData.text
            // ,icon: itemClicado.itemData.icon
            className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            ,component:
                <ScrollView  width='100%' height='100%' useNative={true}>
                    <Passo1
                        setJsonRequest={setJsonRequest} 
                        tituloId={props.tituloId}
                        userType={"operador"}
                        showTitle={props.showTitle}
                        indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                        setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                        gerarAcordoDataSource={gerarAcordoDataSource}
                        setGerarAcordoDataSource={setGerarAcordoDataSource}
                        hideUpfPopupForm={props.hideUpfPopupForm}

                        dateBoxState={dateBoxState}
                        setDateBoxState={setDateBoxState}
                        entityStructureFieldsDefs={entityStructureFieldsDefs}
                        getParcelasVencidasVincendasFunction={getParcelasVencidasVincendasFunction}
                        dataSourceParcelasVencidasVincendasState={dataSourceParcelasVencidasVincendasState}
                        dataLookup={dataLookup}
                        loadingPanelState={loadingPanelState}
                        disabledButtonState={disabledButtonState}
                        visible={visible}
                        setVisible={setVisible}
                        message={message}
                        setMessage={setMessage}
                        arraySelecionadosState={arraySelecionadosState}
                        setArraySelecionadosState={setArraySelecionadosState}
                        marcarTodasParcelas={props.parcelasIdArray ? true : false}
                    />
                </ScrollView>
            // ,text: Math.random()
            // ,type: itemClicado.itemData.path
        },
        {
        //     title: itemClicado.itemData.text
        //    ,icon: itemClicado.itemData.icon
           className: "tab-sheet"
           //,icon: '/images/favicon.ico'
           ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                           <Passo2
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                jsonRequest={jsonRequest}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                setAcordoDataGridDataSource={setAcordoDataGridDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                // setBoletoBinarioId={setBoletoBinarioId}
                                setDadosPagementoAcordoObjectState={setDadosPagementoAcordoObjectState}
                                habilitarGerarAcordo={props.habilitarGerarAcordo}
                                getEmailTelefonesBoleto={getEmailTelefonesBoleto}
                           />
                       </ScrollView>
           ,text: Math.random()
        //    ,type: itemClicado.itemData.path
        },
        {
        className: "tab-sheet"
        ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                        <Passo3 
                            tituloId={props.tituloId}
                            clienteId={props.clienteId}
                            credorId={props.credorId}
                            indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                            setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                            gerarAcordoDataSource={gerarAcordoDataSource}
                            acordoDataGridDataSource={acordoDataGridDataSource}
                            setGerarAcordoDataSource={setGerarAcordoDataSource}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            dadosPagementoAcordoObjectState={dadosPagementoAcordoObjectState}
                            habilitarGerarAcordo={props.habilitarGerarAcordo}
                            getEmailTelefonesBoleto={getEmailTelefonesBoleto}
                        />
                    </ScrollView>
        ,text: Math.random()
        },
        {
            className: "tab-sheet"
            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                            <ConsultaBoletoForm 
                                boletoId={gerarAcordoDataSource?.boletoId}
                                arquivopdf={gerarAcordoDataSource?.boletoPdf}
                                email={gerarAcordoDataSource?.email}
                                telefones={gerarAcordoDataSource?.telefones}
                                dataLookup={dataLookup}
                                masterEntityId={gerarAcordoDataSource?.masterEntityId}
                                dataSourceBoletoState={gerarAcordoDataSource}
                                setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarAcordo}
                                indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarAcordo}
                            />
                        </ScrollView>
            ,text: Math.random()
            }
    ]




    // useEffect(() => {
    //     console.log(indexMultiViewItemsGerarAcordo)
    //     console.log(gerarAcordoDataSource)
    // }, [indexMultiViewItemsGerarAcordo, gerarAcordoDataSource])

    return (
        <div className="tab-items-telecob">
            <MultiView
                items={multiViewItems} 
                deferRendering={false}
                itemRender={contentTab}
                selectedIndex={indexMultiViewItemsGerarAcordo}
                tabIndex={indexMultiViewItemsGerarAcordo}
                repaintChangesOnly={true}
                animationEnabled={false}
                swipeEnabled={false}
                showNavButtons={true}
                scrollByContent={true}
                selectedItem={multiViewItems[indexMultiViewItemsGerarAcordo]}
            />
        </div>
    )
}

export default GerarAcordoForm;