import {useState, useEffect, useRef, useCallback, memo} from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    Editing,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    TotalItem
    } from 'devextreme-react/data-grid';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import ScrollView from 'devextreme-react/scroll-view';
import Loading from "../../../CardComponents/Loading";
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID, relationId } from '../../../api/ApplicationID';
import { Toast } from 'devextreme-react/toast';
import HtmlFormPopup from "../../HtmlFormPopup";
import UpfPopupMessage from "../../../CardComponents/UpfPopupMessage"
import { fecharFormAtual } from "../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import ConsultaBoletoForm from '../../ConsultaBoletoForm'

const Passo3 = (props) => {

    console.log(props)

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)
    const [dataSourceBoletoState, setDataSourceBoletoState] = useState()
    const [consultaBoletoFormPopupState, setConsultaBoletoFormPopupState] = useState(false)

    const [debitoAutomaticoState, setDebitoAutomaticoState] = useState(false)

    useEffect(() => {
        if(props?.renegociacaoConfirmacaoJsonState?.debitoAutomatico){
            
            setDebitoAutomaticoState(props?.renegociacaoConfirmacaoJsonState?.debitoAutomatico)
        }
        else{
            setDebitoAutomaticoState(false)
        }
    }, [props.renegociacaoConfirmacaoJsonState])

    const [liberarConclusaoState, setLiberarConclusaoState] = useState(false)
    const [liberarHistoricoState, setLiberarHistoricoState] = useState(true)
    const [liberarConclusaoNegociacaoState, setLiberarConclusaoNegociacaoState] = useState(true)

    const [modeEditDataGrid, setModeEditDataGrid] = useState(false)


    const[codTipoOcorrenciaState, setCodTipoOcorrenciaState] = useState()

    const hideLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const httpRequestBanese = (method, url, bodyRequest) =>{
        const isJson = (data) => {
            try {
                JSON.parse(data);
            } catch (e) {
                return false;
            }
        
            return true;
        }
    
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);        
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("token", "617C6FD67BB04EB78FCACF0EFF619109");//token temporario banese
           
            xhr.onload = function () {

            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            }
            else if(this.status > 400) {
                reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
                });
            }
            };
            xhr.onerror = function () {
            reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
            });
            };
            xhr.send(JSON.stringify(bodyRequest));
        })
    }

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const [gerarAcordoDisabledState, setGerarAcordoDisabledState] = useState(false)


     //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: "",
        onHidden: false
        })

    //Funções
    const onHidingToast = useCallback(() => {
        setToastConfig({
        ...toastConfig,
        isVisible: false
        });
    },[toastConfig])

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
          ...toastConfig,
          isVisible: visible,
          type: type,
          message: message
        });
      },[toastConfig])
//---------TOAST----------//

    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [telefoneSelecionado, setTelefoneSelecionado] = useState()

    const [observacaoSolucaoState, setObservacaoSolucaoState] = useState("")
    const [observacaoSolucaoNegociacaoState, setObservacaoSolucaoNegociacaoState] = useState(`Valor R$ ${props.totalCobrancaState?.toFixed(2)} ${props?.descontoTotalCobrancaState ? ("desc " + props.descontoTotalCobrancaState + "%") : ("")} referente aos títulos ${props.arrayPendenciasProcessadasState}.`)

    const [states, setStates] = useState({
        dataEntityCadastro: {}
      })

    const [dateBoxState, setDateBoxState] = useState(null)
    const [selectedItem, setSelectedItem] = useState()

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState()

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };
    //---------------------------

    const [solucaoSelecionada, setSolucaoSelecionada] = useState()

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const recordUpdateJson = () => {
        console.log(states.dataEntityCadastro)
        httpRequest("POST",
        requestsApi.updateRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": "_cadastro",
            "json": {
              _cryptemail: states.dataEntityCadastro._cryptemail,
              _cryptportalverificationcode: states.dataEntityCadastro._cryptportalverificationcode,
              id: props.clienteId
            },
            "applicationId": applicationID,
        })
        .then((sender) => {
            const senderUpdateRecordFromJson = JSON.parse(sender)
            // modeEditOff()
            toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
        })
        .catch((error) => {
            hideLoadPanel()
            if(error?.data?.error){
                setMessage(error.data.error)
                setVisible(true)
            }
            else{
                setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                setVisible(true)
            }
        })
    }

    const [telefonesAtualizadosState, setTelefonesAtualizadosState] = useState()


    const recordUpdateJsonTelefones = (jsonRow) => {
        // if(idValue) {
        // jsonRow._id = jsonRow.id
        // delete jsonRow.id
        httpRequest("POST",
            requestsApi.updateRecordFromJson,
            requestsApi.basicAuth,
            {
                "entityName": "_cadastro_contatos",
                "json": jsonRow,
                "applicationId": applicationID
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                setTelefonesAtualizadosState(senderUpdateRecordFromJson.masterEntity[0].records)
                // modeEditOff()  
                toastConfiguration(true, 'success', "Campo editado com sucesso!")
            })
            .catch((error) => {
                hideLoadPanel()
                if(error?.data?.error){
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else{
                    setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                    setVisible(true)
                }
            })
        // }
      }
    
      const recordDeleteJson = (jsonRowId) => {
        httpRequest("POST",
            requestsApi.deleteRecordFromJson,
            requestsApi.basicAuth,
            {
              "entityName": "_cadastro_contatos",
                "applicationId": applicationID,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // setMasterEntity(senderUpdateRecordFromJson.masterEntity[0].records)
                // modeEditOff()  
                toastConfiguration(true, 'success', "Campo removido com sucesso!")
            })
            .catch((error) => {
                hideLoadPanel()
                if(error?.data?.error){
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else{
                    setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                    setVisible(true)
                }
            })
      }
    
      const recordInsertJson = (jsonRow) => {
        // if(idValue) {
            let idfake = jsonRow.id
        delete jsonRow.id
        httpRequest("POST",
            requestsApi.insertRecordFromJson,
            requestsApi.basicAuth,
            {
                "entityName": "_cadastro_contatos",
                "json": jsonRow,
                "applicationId": applicationID
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()  
                // setMasterEntity(senderUpdateRecordFromJson?.masterEntity[0].records)
                jsonRow.id = senderUpdateRecordFromJson.idValue;
                toastConfiguration(true, 'success', "Campo adicionado com sucesso!")
            })
            .catch((error) => {
                hideLoadPanel()
                if(error?.data?.error){
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else{
                    setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                    setVisible(true)
                }
            })
        // }
      }

    return(
        <div style={{height: "100%", padding: "0.5em"}}>
            {/* <ScrollView> */}
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />
            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Popup
                visible={visible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {message &&
                    <p>{message}</p>
                }
            </Popup>

            {dataSourceBoletoState &&
                <Popup
                    visible={consultaBoletoFormPopupState}
                    onHiding={() => {
                        setConsultaBoletoFormPopupState(false)
                    }}
                    dragEnabled={false}
                    fullScreen={true}
                    hideOnOutsideClick={false}
                    animation={null}
                    // deferRendering={false}
                    // title={props.entityStructure[0].masterEntity.caption}
                    showCloseButton={true}
                    showTitle={true}
                    title='Boleto'
                    id="popupForm"
                >

                    <ScrollView width='100%' height='100%' useNative={true}>
                        <ConsultaBoletoForm
                            boletoId={dataSourceBoletoState?.boleto}
                            email={dataSourceBoletoState?.email}
                            telefones={dataSourceBoletoState?.telefones}
                            dataLookup={dataSourceBoletoState?.dataLookup}
                            masterEntityId={dataSourceBoletoState?.masterEntityId}
                            dataSourceBoletoState={dataSourceBoletoState}
                            consultaBoletoFormPopupState={consultaBoletoFormPopupState}
                        />
                    </ScrollView>
                </Popup>
            }

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />
            <div>
                <h4>Condições da Renegociação</h4>

                {props.tipoPagamentoSelecionadoIdState === 1 &&
                    <Form 
                        colCount={3}
                        formData={props?.condicaoRenegociacaoDataSourceState?.[0] ? props.condicaoRenegociacaoDataSourceState[0] : {}}
                        showColonAfterLabel={true}
                        // minColWidth={200}
                        cssClass={'group-item'}
                        readOnly={true}
                        alignItemLabelsInAllGroups={true}
                        alignItemLabels={true}
                        // onInitialized={(e) => {
                        //     console.log(e)
                        //     e.component.option("value", Boolean(e.component.option("value")))
                        // }}
                    >
                        <GroupItem 
                            colCount={3}
                            colSpan={3}
                            cssClass={'group-item'}
                            itemType={'group'}
                            showClearButton={true}
                            icon={'folder'}
                        >

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDADIVIDA"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor da dívida"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDOREBATE"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do rebate"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDAENTRADA"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor da entrada"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"IOF"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do IOF"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"IOFAdicional"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do IOF adicional"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORRESSARCIMENTOSEGURO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor ressarcimento seguro"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORRENEGOCIADO"}
                                // dataField={"VALORDOPAGAMENTO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor renegociado"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"TAXADEJUROS"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Taxa de juros"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"JUROSRENEGOCIACAO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Juros Renegociação"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"NOVOLIMITEDECREDITO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Novo limite crédito"}/>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                }

                {props.tipoPagamentoSelecionadoIdState === 2 &&
                    <Form 
                        colCount={3}
                        formData={props?.condicaoRenegociacaoDataSourceState?.[0] ? props.condicaoRenegociacaoDataSourceState[0] : {}}
                        showColonAfterLabel={true}
                        // minColWidth={200}
                        cssClass={'group-item'}
                        readOnly={true}
                        alignItemLabelsInAllGroups={true}
                        alignItemLabels={true}
                        // onInitialized={(e) => {
                        //     console.log(e)
                        //     e.component.option("value", Boolean(e.component.option("value")))
                        // }}
                    >
                        <GroupItem 
                            colCount={3}
                            colSpan={3}
                            cssClass={'group-item'}
                            itemType={'group'}
                            showClearButton={true}
                            icon={'folder'}
                        >

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDADIVIDA"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor da dívida"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDOREBATE"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do rebate"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"IOF"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do IOF"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"IOFAdicional"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do IOF adicional"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORRESSARCIMENTOSEGURO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor ressarcimento seguro"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORRENEGOCIADO"}
                                // dataField={"VALORDOPAGAMENTO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor renegociado"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"TAXADEJUROS"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Taxa de juros"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"JUROSRENEGOCIACAO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Juros Renegociação"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"NOVOLIMITEDECREDITO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Novo limite crédito"}/>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                }

                {props.tipoPagamentoSelecionadoIdState === 3 &&
                    <Form 
                        colCount={3}
                        formData={props?.condicaoRenegociacaoDataSourceState?.[0] ? props.condicaoRenegociacaoDataSourceState[0] : {}}
                        showColonAfterLabel={true}
                        // minColWidth={200}
                        cssClass={'group-item'}
                        readOnly={true}
                        alignItemLabelsInAllGroups={true}
                        alignItemLabels={true}
                        // onInitialized={(e) => {
                        //     console.log(e)
                        //     e.component.option("value", Boolean(e.component.option("value")))
                        // }}
                    >
                        <GroupItem 
                            colCount={3}
                            colSpan={3}
                            cssClass={'group-item'}
                            itemType={'group'}
                            showClearButton={true}
                            icon={'folder'}
                        >

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDADIVIDA"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor da dívida"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDOREBATE"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor do rebate"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORRESSARCIMENTOSEGURO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor ressarcimento seguro"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"VALORDOPAGAMENTO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Valor pagamento (à vista)"}/>
                            </SimpleItem>

                            <SimpleItem
                                colSpan={1}
                                editorType="dxTextBox"
                                dataField={"NOVOLIMITEDECREDITO"}
                                editorOptions={{
                                    readOnly: true
                                }}
                            >
                                <Label text={"Novo limite crédito"}/>
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                }

                <h4>Cronograma de Prestações</h4>

                <DataGrid
                    // dataSource={[]}
                    dataSource={props?.condicaoRenegociacaoDataSourceState?.[1] ? props.condicaoRenegociacaoDataSourceState[1] : []}
                    columnAutoWidth={true}
                    showBorders={true}
                    cacheEnabled={false}
                >
                    <Scrolling 
                        rowRenderingMode='infinity'
                        showScrollbar='always'
                        useNative={true}
                        >
                    </Scrolling>

                    <Paging defaultPageSize={8} />

                    <Sorting
                        mode="none"
                    />
                    
                    <Pager
                        visible={true}
                        allowedPageSizes={36}
                        // showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />

                    
                    <ColumnChooser 
                        enabled={false} 
                        width={400} 
                        height={800} 
                        mode={"select"} 
                        allowSearch={true}
                    />

                    <Column
                        dataField="prestacao"
                        caption="Prestação"
                    />

                    <Column
                        dataField="vencimento"
                        caption="Vencimento"
                    />

                    <Column
                        dataField="capital"
                        caption="Capital"
                        format="R$ #,##0.##00"
                    />

                    <Column
                        dataField="juros"
                        caption="Juros"
                        format="R$ #,##0.##00"
                    />

                    <Column
                        dataField="valorprestacao"
                        caption="Valor Prestação"
                        format="R$ #,##0.##00"
                    />

                    <Summary
                        calculateCustomSummary={calculateCustomSummary}
                    >

                        <TotalItem
                            column="capital"
                            summaryType="sum"
                            format={{
                                style: 'currency',
                                currency: 'BRL',
                                precision: 2,
                            }}
                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                        />

                        <TotalItem
                            column="juros"
                            summaryType="sum"
                            format={{
                                style: 'currency',
                                currency: 'BRL',
                                precision: 2,
                            }}
                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                        />

                        <TotalItem
                            column="valorprestacao"
                            summaryType="sum"
                            format={{
                                style: 'currency',
                                currency: 'BRL',
                                precision: 2,
                            }}
                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                        />

                    </Summary>

                </DataGrid>

            </div>

            <h4>Forma de Pagamento da Renegociação</h4>

                <Form 
                    colCount={3}
                    showColonAfterLabel={true}
                    // formData={props?.condicaoRenegociacaoDataSourceState?.[0] ? props.condicaoRenegociacaoDataSourceState[0] : {}}
                    // minColWidth={200}
                    alignItemLabelsInAllGroups={true}
                    alignItemLabels={true}
                    // onInitialized={(e) => {
                    //     console.log(e)
                    //     e.component.option("value", Boolean(e.component.option("value")))
                    // }}
                >
                    <GroupItem 
                        colCount={3}
                        colSpan={3}
                        cssClass={'group-item'}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >

                        <SimpleItem
                            colSpan={1}
                            editorType="dxCheckBox"
                            // dataField={"VALORDADIVIDA"}
                            editorOptions={{
                                onValueChanged: (e) => {
                                    console.log(e)
                                    setDebitoAutomaticoState(e.value)
                                },
                                value: debitoAutomaticoState
                            }}
                        >
                            <Label text={"Débito automático"}/>
                        </SimpleItem>

                        <SimpleItem
                            colSpan={2}
                            editorType="dxTextBox"
                            dataField={"Conta"}
                            editorOptions={{
                                readOnly: true,
                                value: props.renegociacaoConfirmacaoJsonState?.conta
                            }}
                        >
                            <Label text={"Conta Corrente"}/>
                        </SimpleItem>
                    </GroupItem>
            </Form>

            <h3>Telefones</h3>

            <DataGrid
                keyExpr="id"
                className="datagrid-detail-entity"
                // repaintChangesOnly={true} (pode afetar a performance) comentado 17/05/2022, pois quando agrupava no grid e tentava editar e depois salvar quebrava o groupIndex
                renderAsync={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                // ref={refDataGrid}
                dataSource={telefonesAtualizadosState ? telefonesAtualizadosState : props?.dataSourceTelefonesState}
                // dataSource={[]}
                allowColumnReordering={true}
                showBorders={true}
                rowAlternationEnabled={true}
                focusedRowEnabled={true}
                focusedRowKey={0}
                columnMinWidth={50}
                onRowUpdated={(sender) => {
                    console.log(sender)
                    const json = sender.data
                    json["_cadastro_id"] = props.cadastro_id
                    // jsonRow
                    recordUpdateJsonTelefones(json)
                    
                    
                    // setModeEditDataGrid(false)
                }}
                onRowRemoved={(sender) => {
                    console.log(sender)
                    recordDeleteJson(sender.data.id)
                }}
                onRowInserted={(sender) => {
                    console.log(sender)
                    const json = sender.data
                    json["_cadastro_id"] = props.clienteId
                    recordInsertJson(json)
                }}
                onSelectionChanged={(sender) => {
                    if(sender?.selectedRowsData?.[0]){
                        setTelefoneSelecionado(sender.selectedRowsData[0]._cryptfone)
                    }
                    else{
                        setTelefoneSelecionado(null)
                    }
                    // if(!modeEditDataGrid.edit){
                    //     props.onSelectionChangedFunction(sender)
                    // }
                }}
            >
                <Selection
                    keyExpr="id"
                    mode="single"
                    selectAllMode="allPages"
                    showCheckBoxesMode="onClick"
                />
                <Scrolling
                    rowRenderingMode='virtual'
                    showScrollbar='always'
                    useNative={true}

                // rowRenderingMode="standard"
                // mode="virtual"
                >
                </Scrolling>

                <Paging defaultPageSize={10} />

                <Sorting
                    mode="multiple"
                />
                {/* <GroupPanel
                    visible={(props.senderDetailEntity.showGroupPanel === 1)}
                    // visible={true}
                    allowColumnDragging={true}
                /> */}
                <Pager
                    visible={true}
                    // allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <Editing
                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={false}
                    confirmDelete={false}
                    mode="batch"
                    useIcons={true}
                // selectTextOnEditStart={selectTextOnEditStart}
                // startEditAction={startEditAction}
                />
                {/* <Column
                    caption="Deletar"
                    visible={true}
                    type="buttons"
                    width={110}
                >
                    <Button
                        name="delete"
                    />
                </Column> */}
                <ColumnChooser
                    enabled={false}
                    width={400}
                    height={800}
                    mode={"select"}
                    allowSearch={true} />
                <HeaderFilter
                    allowSearch={true}
                    visible={true}
                />

                <Column
                    dataField="_cryptfone"
                    caption="Telefone"
                >
                
                </Column>
            </DataGrid>



            <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                <div style={{marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Button
                        width={200}
                        height={40}
                        text="Efetuar Renegociação"
                        type="default"
                        stylingMode="contained"
                        onClick={() => {
                            console.log(props.renegociacaoConfirmacaoJsonState)
                            console.log(telefoneSelecionado)
                            if(telefoneSelecionado){
                                showLoadPanel()
                                httpRequestBanese('POST', props.requestsBaneseState.renegociacaoConfirmacao, {
                                    "relationId": relationId,
                                    "rebate": props.renegociacaoConfirmacaoJsonState?.rebate,
                                    "tipoPagamento": props.renegociacaoConfirmacaoJsonState.tipoPagamento,
                                    "entrada": props.renegociacaoConfirmacaoJsonState?.entrada,
                                    "taxaJuros": props.renegociacaoConfirmacaoJsonState?.taxaJuros,
                                    "vencimentoParcela": props.renegociacaoConfirmacaoJsonState?.vencimentoParcela,
                                    "vencimentoEntrada": props.renegociacaoConfirmacaoJsonState?.vencimentoEntrada,
                                    "qtdParcelas": props.renegociacaoConfirmacaoJsonState?.qtdParcelas,
                                    "tituloId": props.tituloId,
                                    "telefone": telefoneSelecionado,
                                    "debitoAutomatico": debitoAutomaticoState
                                })
                                .then((sender) => {
                                    const resultreNegociacaoSimulacaoParcelamentoJson = JSON.parse(sender)
                                    if(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.idBoleto){
                                        toastConfiguration(true, 'success', "Boleto gerado com sucesso!")

                                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                            "lookUpEntities": [
                                                {
                                                    "lookUpEntity": "TipoNegociacao"
                                                },
                                                {
                                                    "lookUpEntity": "OpcaoNegociacao"
                                                },
                                                {
                                                    "lookUpEntity": "_tipo_contato"
                                                },
                                                {
                                                    "lookUpEntity": "_pontuacao_contato"
                                                }
                                            ]
                                        })
                                        .then((sender) => {
                                            hideLoadPanel()
                                            const resultGetLookupTableJson = JSON.parse(sender)

                                            setDataSourceBoletoState({
                                                ...dataSourceBoletoState,
                                                telefones: telefonesAtualizadosState ? telefonesAtualizadosState : props?.dataSourceTelefonesState,
                                                boleto: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.idBoleto,
                                                email: props.renegociacaoSimulacaoState?.email,
                                                dataLookup: resultGetLookupTableJson,
                                                masterEntityId: props.clienteId
                                            })
    
                                            setConsultaBoletoFormPopupState(true)
                                        })
                                        .catch((error => {
                                            hideLoadPanel()
                                            if(error?.data?.error){
                                                setMessage(error.data.error)
                                                setVisible(true)
                                            }
                                            else{
                                                setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                                                setVisible(true)
                                            }
                                        }))
                                    }
                                    else{
                                        setMessage("Não foi possível gerar o boleto, tente novamente mais tarde.")
                                        setVisible(true)
                                    }
                                
                                    console.log(resultreNegociacaoSimulacaoParcelamentoJson)
                                })
                                .catch((error) => {
                                    // console.log("entrou erro request banese")
                                    // console.log(error)
                                    hideLoadPanel()
                                    if(error?.data?.error){
                                        setMessage(error.data.error)
                                        setVisible(true)
                                    }
                                    else{
                                        setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                                        setVisible(true)
                                    }
                                })
                            }
                            else{
                                setMessage("Selecione um telefone para continuar")
                                setVisible(true)
                            }

                            
                            // props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                        }}
                        // disabled={cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0}
                    />
                </div>

                <div>
                    <Button
                        width={130}
                        height={40}
                        text="Anterior"
                        type="default"
                        stylingMode="contained"
                        onClick={() => {
                            // props.setGerarAcordoDataSource(null)
                            //pegar todos os dados e enviar para a API 
                            //...
                            //ir para o proximo item
                            
                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                        }}
                    />
                </div>
            </div>
           
        </div>
                        
    )
}

export default memo(Passo3);