import {useState, useEffect, useRef, useCallback, memo} from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label,
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import Loading from "../../../../CardComponents/Loading"
import { httpRequest } from '../../../../functions';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { useProximaFichaDados } from '../../../../Context/ProximaFichaDados';
import { applicationID, relationId, specialPermissions } from '../../../../api/ApplicationID';
import ScrollView from 'devextreme-react/scroll-view';
import { Toast } from 'devextreme-react/toast';
import DropDownBox from 'devextreme-react/drop-down-box';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Funnel, { Export } from 'devextreme-react/funnel';
import { useUpfFloatPopupMessageVisibleGlobalState } from "../../../../Context/UpfFloatPopupMessageVisibleGlobalState";
import { executeActionUpfFloatPopupMessage } from "../../../../functions/executeActionUpfFloatPopupMessage";
import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    TotalItem
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import UpfHtmlEditor from "../../../UpfComponents/UpfHtmlEditor";
import { validatePermission } from "../../../../functions/validatePermission";
import RecordFormPopup from "../../../RecordForm/RecordFormPopup";
import { LoadPanel } from 'devextreme-react/load-panel';

const Passo2 = (props) => {
    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()

    const fieldsPermissionUpdateArray = []
    
    const [states, setStates] = useState({
        radioOpcaoPagamento: ["À vista", "Parcelamento"],
        opcaoPagamentoItemSelecionado: "Parcelamento",
        radioDesconto: ["Percentual", "Valor"],
        descontoItemSelecionado: "Percentual",
        descontoPrincipalEscolhido: 0,
        descontoJurosEscolhido: 0,
        descontoMultaEscolhido: 0,
        descontoHonorarioEscolhido: 0,
        descontoMoraEscolhido: 0,
        toastVisible: false,
        toastType: 'info',
        toastMessage: ""
    })



    const [statesPropsValues, setStatesPropsValues] = useState({
        valor: props?.gerarBoletoDataSource?.arrayTotais?.valor ? props.gerarBoletoDataSource.arrayTotais.valor : 0,
        jurosvalor:props?.gerarBoletoDataSource?.arrayTotais?.jurosvalor ? props.gerarBoletoDataSource.arrayTotais.jurosvalor : 0,
        multavalor: props?.gerarBoletoDataSource?.arrayTotais?.multavalor ? props.gerarBoletoDataSource.arrayTotais.multavalor : 0,
        honorariovalor: props?.gerarBoletoDataSource?.arrayTotais?.honorariovalor ? props.gerarBoletoDataSource.arrayTotais.honorariovalor : 0,
        moraValor: props?.gerarBoletoDataSource?.arrayTotais?.moraValor ? props.gerarBoletoDataSource.arrayTotais.moraValor : 0,
        cartorioValor: props?.gerarBoletoDataSource?.arrayTotais?.cartorioValor ? props.gerarBoletoDataSource.arrayTotais.cartorioValor : 0,
        outrasDespesasValor: props?.gerarBoletoDataSource?.arrayTotais?.outrasDespesasValor ? props.gerarBoletoDataSource.arrayTotais.outrasDespesasValor : 0,
        _taxaboletovalor: props?.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._taxaboletovalor ? props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._taxaboletovalor : 0,
        total : props?.gerarBoletoDataSource?.arrayTotais?.total ? props.gerarBoletoDataSource.arrayTotais.total : 0
    })

    // const [honorarioCalculadoValueState, setHonorarioCalculadoValueState] = useState(statesPropsValues?.honorariovalor)


    useEffect(() => {
        // const honorarioValor = props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1 ? honorarioCalculadoValueState : statesPropsValues.honorariovalor

        console.log(statesPropsValues)
        console.log(props?.gerarBoletoDataSource?.arrayTotais)
        setStatesPropsValues({
            ...statesPropsValues,
            total: statesPropsValues.valor +
            statesPropsValues.jurosvalor +
            statesPropsValues.multavalor +
            statesPropsValues.honorariovalor +
            statesPropsValues.moraValor +
            statesPropsValues.cartorioValor +
            statesPropsValues.outrasDespesasValor +
            statesPropsValues._taxaboletovalor 
        })
    }, [
        statesPropsValues.valor,
        statesPropsValues.jurosvalor,
        statesPropsValues.multavalor,
        statesPropsValues.honorariovalor,
        statesPropsValues.moraValor,
        statesPropsValues.cartorioValor,
        statesPropsValues.outrasDespesasValor,
        statesPropsValues._taxaboletovalor
    ])

    const _recordFormPopupRef = useRef(null)


    const [modeEditDataGridEnderecosState, setModeEditDataGridEnderecosState] = useState({
        edit: false
    })


    const [popupHtmlTemplateVisible, setPopupHtmlTemplateVisible] = useState(false)
    const [htmlTemplate, setHtmlTemplate] = useState()

    const onHidenPopupHtmlTemplate = () => {
        setPopupHtmlTemplateVisible(false)
    }

    const openPopupHtmlTemplate = () => {
        setPopupHtmlTemplateVisible(true)
    }

    const _descontoPrincipalEscolhidoRef = useRef(null)
    const _descontoJurosEscolhidoRef = useRef(null)
    const _descontoMultaEscolhidoRef = useRef(null)
    const _descontoHonorarioEscolhidoRef = useRef(null)
    const _descontoMoraEscolhidoRef = useRef(null)
    const _formGerarAcordo = useRef(null)


    const [descontoFinal, setDescontoFinal] = useState(0)
    const refDataGridEderecos = useRef(null);


    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState()
    const [popupState, setPopupState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState()
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [IDValueAttachDetailsEntity, setIDValueAttachDetailsEntity] = useState();
    const [detailAttachEntityName, setDetailAttachEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [detailAttachCaptionName, setDetailAttachCaptionName] = useState()
    const [entityDataEntityState, setEntityDataEntityState] = useState()


    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                idValue={IDValueDetailEntity}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState}
                masterEntityId={props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id ? props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id : props.clienteId}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={props.dataEntity?.detailEntity?.length > 0 && props.dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
                dataEntity={entityDataEntityState}
                referenciaPopup={_recordFormPopupRef}
            />
        );
    })



    useEffect(() => {
        if(props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){
            let descontoMultaJuros = 0 
            let descontoValorPrincipal = 0 
            if(states.descontoItemSelecionado === "Percentual"){
                descontoValorPrincipal = states.descontoPrincipalEscolhido * (statesPropsValues.valor - props.gerarBoletoDataSource.valoresPrincipaisParcelasVincendasSemHonorario)
                descontoMultaJuros = states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                                    + states.descontoMultaEscolhido * statesPropsValues.multavalor
                                    + descontoValorPrincipal 
                                    + states.descontoMoraEscolhido * statesPropsValues.moraValor 
            }
            else if(states.descontoItemSelecionado === "Valor"){
                descontoValorPrincipal = states.descontoPrincipalEscolhido - props.gerarBoletoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                descontoMultaJuros = states.descontoJurosEscolhido + states.descontoMultaEscolhido + descontoValorPrincipal + states.descontoMoraEscolhido
            }

            console.log(props.gerarBoletoDataSource.valoresPrincipaisParcelasVincendasSemHonorario)
            console.log(statesPropsValues.valor)
            console.log(statesPropsValues)
            console.log(statesPropsValues.honorariovalor)
            console.log(props?.gerarBoletoDataSource?.arrayTotais?.honorariovalor)
            console.log(props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._honorariotaxa)

            if(props?.gerarBoletoDataSource?.arrayTotais?.honorariovalor > 0){
                
                if(props.gerarBoletoDataSource?.honorarioCalculadoApenasPeloValorPrincipal){
                    let honorarioCalculadoResult = (
                        (
                            statesPropsValues.valor 
                            - props.gerarBoletoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                            - descontoValorPrincipal
                        ) * (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._honorariotaxa / 100)
                    )
    
                    setStatesPropsValues({
                        ...statesPropsValues,
                        honorariovalor: parseFloat(honorarioCalculadoResult.toFixed(2))
                    })
                }
                else if(props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){
                    let honorarioCalculadoResult = (
                        (
                            statesPropsValues.valor - props.gerarBoletoDataSource.valoresPrincipaisParcelasVincendasSemHonorario
                            + statesPropsValues.jurosvalor 
                            + statesPropsValues.multavalor
                            + statesPropsValues.moraValor  
                            + statesPropsValues.outrasDespesasValor  
                            + statesPropsValues.cartorioValor  
                            - descontoMultaJuros
                        ) * (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._honorariotaxa / 100)
                    )
                    console.log(parseFloat(honorarioCalculadoResult.toFixed(2)))
                    setStatesPropsValues({
                        ...statesPropsValues,
                        honorariovalor: parseFloat(honorarioCalculadoResult.toFixed(2))
                    })
                }
            }

            if(_descontoHonorarioEscolhidoRef?.current?.instance && states.descontoHonorarioEscolhido > 0){
                setStates({
                    ...states,
                    descontoHonorarioEscolhido: 0
                });
                _descontoHonorarioEscolhidoRef.current.instance.repaint()
            }

        }

    }, [
        states.descontoJurosEscolhido, 
        states.descontoMultaEscolhido, 
        states.descontoPrincipalEscolhido, 
        states.descontoMoraEscolhido,
        statesPropsValues.jurosvalor,
        statesPropsValues.multavalor,
        statesPropsValues.moraValor,
        statesPropsValues.valor,
    ])


    const [valorFinal, setValorFinal] = useState(0)

    const [gerarBoletoDisabledState, setGerarBoletoDisabledState] = useState(false)

    //props.gerarBoletoDataSource.resultJsonData usar para pegar os juros
    useEffect(() => {
        if(props.gerarBoletoDataSource){
            // const honorarioValor = props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1 ? honorarioCalculadoValueState : statesPropsValues.honorariovalor

            setValorFinal(
                statesPropsValues.valor + 
                statesPropsValues.jurosvalor + 
                statesPropsValues.multavalor + 
                statesPropsValues.honorariovalor +
                statesPropsValues.moraValor +
                statesPropsValues.outrasDespesasValor +
                statesPropsValues.cartorioValor +
                statesPropsValues._taxaboletovalor 
            )
            setDescontoFinal(0)


            if(states.descontoItemSelecionado === "Percentual"){
                setDescontoFinal(
                    states.descontoPrincipalEscolhido * statesPropsValues.valor
                    + states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                    + states.descontoMultaEscolhido * statesPropsValues.multavalor
                    + states.descontoMoraEscolhido * statesPropsValues.moraValor
                    + states.descontoHonorarioEscolhido * statesPropsValues.honorariovalor
                    )
                setValorFinal(
                    (
                        statesPropsValues.valor + 
                        statesPropsValues.jurosvalor + 
                        statesPropsValues.multavalor + 
                        statesPropsValues.honorariovalor +
                        statesPropsValues.moraValor +
                        statesPropsValues.outrasDespesasValor +
                        statesPropsValues.cartorioValor +
                        statesPropsValues._taxaboletovalor
                    ) 
                    -
                    (
                        states.descontoPrincipalEscolhido * statesPropsValues.valor
                        + states.descontoJurosEscolhido * statesPropsValues.jurosvalor
                        + states.descontoMultaEscolhido * statesPropsValues.multavalor
                        + states.descontoMoraEscolhido * statesPropsValues.moraValor 
                        + states.descontoHonorarioEscolhido * statesPropsValues.honorariovalor 
                    )
                )
            }
            else if(states.descontoItemSelecionado === "Valor"){
                setDescontoFinal(
                    states.descontoPrincipalEscolhido
                    + states.descontoJurosEscolhido
                    + states.descontoMultaEscolhido
                    + states.descontoMoraEscolhido 
                    + states.descontoHonorarioEscolhido
                    )
                setValorFinal(
                    (
                        statesPropsValues.valor + 
                        statesPropsValues.jurosvalor + 
                        statesPropsValues.multavalor + 
                        statesPropsValues.honorariovalor +
                        statesPropsValues.moraValor +
                        statesPropsValues.outrasDespesasValor +
                        statesPropsValues.cartorioValor +
                        statesPropsValues._taxaboletovalor
                    ) 
                    -
                    (
                        states.descontoPrincipalEscolhido
                        + states.descontoJurosEscolhido
                        + states.descontoMultaEscolhido
                        + states.descontoMoraEscolhido 
                        + states.descontoHonorarioEscolhido  
                    )
                )
            }
        }

    }, [
        props.gerarBoletoDataSource
        ,states.saldoAtualizado
        ,states.descontoItemSelecionado
        ,states.descontoPrincipalEscolhido
        ,states.descontoJurosEscolhido
        ,states.descontoMultaEscolhido
        ,states.descontoMoraEscolhido 
        ,states.descontoHonorarioEscolhido
        // ,honorarioCalculadoValueState
        ,statesPropsValues
    ]) 

    const [saldoAtualizadoState, setSaldoAtualizadoState] = useState()  

    useEffect(() => {
        if(descontoFinal){
            setSaldoAtualizadoState(descontoFinal + valorFinal)
        }
        else if(statesPropsValues.total){
            setSaldoAtualizadoState(statesPropsValues.total)
        }
    }, [descontoFinal, valorFinal, statesPropsValues.total])

    const [descontosValidosState, setDescontosValidosState] = useState()
    const [jsonUpdate, setJsonUpdate] = useState({})

    const getDescontosValidos = () => {
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_view_tituloCampanhaDescontosValidos",
            "fieldName": "_tituloid",
            "expressao": props.tituloId
        })
        .then((sender) => {
            const resultDescontosValidosJson = JSON.parse(sender)
            setDescontosValidosState(resultDescontosValidosJson?.masterEntity?.[0]?.records)
            
        })
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(!descontosValidosState && props.indexMultiViewItemsGerarBoleto === 1){
            getDescontosValidos()
        }
    }, [])

    const repaintForm = () => {
        _formGerarAcordo.current.instance.repaint()
    }

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }


    const [fecharPopupDepoisDeGerarBoletoState, setFecharPopupDepoisDeGerarBoletoState] = useState(false)


    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
        if(props.hideUpfPopupForm && fecharPopupDepoisDeGerarBoletoState){
            props.hideUpfPopupForm()
        }
    }

    const hidePopup = useCallback(() =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    },[popup]);

    const refreshEnderecos = () => {
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro_enderecos",
            "fieldName": "_cadastro_id",
            "expressao": props.clienteId
        })
        .then((sender) => {
            const resultDataEntityJsonRecords = JSON.parse(sender)?.masterEntity?.[0]?.records

            for(let c = 0; c <= resultDataEntityJsonRecords.length - 1; c++){
                if(resultDataEntityJsonRecords[c]?._ispadrao === null){
                    resultDataEntityJsonRecords[c]["_ispadrao"] = 0
                }
            }

            console.log(resultDataEntityJsonRecords)
            props.setDataSourceEnderecosState(resultDataEntityJsonRecords)
            
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const [enderecoIdSelecionadoState, setEnderecoIdSelecionadoState] = useState()

    const gerarBoleto = () => {
        if(enderecoIdSelecionadoState || props.dataSourceEnderecosState?.[0]?.id){
            console.log(enderecoIdSelecionadoState)
            console.log(props.dataSourceEnderecosState)
            setGerarBoletoDisabledState(true)
            //CODIGO ORIGINAL QUANDO PUDER GERAR BOLETO
            httpRequest('POST', requestsApi.gerarBoleto, requestsApi.basicAuth, {
                tituloId: props.tituloId,//proximaFichaDados.tituloId,
                applicationId: applicationID,
                vencimento: props.gerarBoletoDataSource.arrayTotais.vencimentoEntrada,
                parcelas: props.gerarBoletoDataSource.parcelasSelecionadasId,
                valorBoleto: parseFloat(valorFinal.toFixed(2)),
                TipoDesconto: states.descontoItemSelecionado === "Percentual" ? 1 : 2,
                CalculadoPrincipal: statesPropsValues.valor,
                CalculadoJuros: statesPropsValues.jurosvalor,
                CalculadoMora: statesPropsValues.moraValor,
                CalculadoMulta: statesPropsValues.multavalor,
                CalculadoHonorario: statesPropsValues.honorariovalor,
                CalculadoCartorio: statesPropsValues.cartorioValor,
                CalculadoOutrasDespesas: statesPropsValues.outrasDespesasValor,
                SaldoAtualizado: saldoAtualizadoState,
                DescontoPrincipal: states.descontoPrincipalEscolhido,
                DescontoJuros: states.descontoJurosEscolhido,
                DescontoMora: states.descontoMoraEscolhido,
                DescontoMulta: states.descontoMultaEscolhido,
                DescontoHonorario: states.descontoHonorarioEscolhido,
                DescontoTotal: parseFloat(descontoFinal.toFixed(2)),
                taxaValor: statesPropsValues._taxaboletovalor ? parseFloat(statesPropsValues._taxaboletovalor.toFixed(2)) : 0, 
                contaCorrenteId: "",
                enderecoId: enderecoIdSelecionadoState ? enderecoIdSelecionadoState : props.dataSourceEnderecosState?.[0]?.id,
                relationId: relationId
            })
            .then((sender) => {
                const resultSenderJson = JSON.parse(sender)
                toastConfiguration(true, 'success', `Boleto Gerado com sucesso!`)  
                // setUpfFloatPopupMessageVisibleGlobalState({
                //     visible: false,
                //     fixo: true,
                //     mensagemId: null,
                //     actionName: "boleto"
                // }) 
    
                executeActionUpfFloatPopupMessage(
                    "boleto"
                    ,null
                    ,requestsApi
                    ,applicationID
                    ,relationId
                    ,httpRequest
                )
    
                if(resultSenderJson?.arquivopdf){
        
                    // props.setBoletoBinarioId(resultSenderJson.mensagem)
                    // props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, boleto: resultSenderJson.mensagem})
           
                    //para pegar o email do cliente
                    httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                        "applicationId": applicationID,
                        "entityName": "_cadastro",
                        "fieldName": "id",
                        "expressao": props.clienteId
                    })
                    .then((sender) => {
                        const resultSenderJsonCliente = JSON.parse(sender) 
                        // props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, email : resultSenderJsonCliente.records[0].cryptemail})
                        
                        //para pegar os telefones
                        httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                            "applicationId": applicationID,
                            "entityName": "_cadastro_contatos",
                            "fieldName": "_cadastro_id",
                            "expressao": props.clienteId
                        })
                        .then((sender) => {
                            const resultSenderJsonTelefones = JSON.parse(sender)
                            // props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, telefones : resultSenderJsonTelefones.records})
                            props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, 
                                telefones : resultSenderJsonTelefones?.records,
                                boleto: resultSenderJson?.idvalue,
                                email : resultSenderJsonCliente?.records?.[0]?._cryptemail,
                                masterEntityId: props.clienteId
                            })
                            props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto + 1)
                            setGerarBoletoDisabledState(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            setGerarBoletoDisabledState(false)
                            //gerou boleto porem n tem contatos
                            props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, 
                                telefones : [],
                                boleto: resultSenderJson?.idvalue,
                                email : resultSenderJsonCliente?.records?.[0]?._cryptemail,
                                masterEntityId: props.clienteId
                            })
                            props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto + 1)
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                        setGerarBoletoDisabledState(false)
                        //gerou boleto porem n tem contatos
                        props.setGerarBoletoDataSource({...props.gerarBoletoDataSource, 
                            telefones : [],
                            boleto: resultSenderJson?.idvalue,
                            email : "",
                            masterEntityId: props.clienteId
                        })
                        props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto + 1)
                    }) 
                }
                else{
                    setFecharPopupDepoisDeGerarBoletoState(true)
                    setGerarBoletoDisabledState(false)
                }
    
            })
            .catch((error) => {
                setGerarBoletoDisabledState(false)
                console.log(error)
                setMessageError(error?.data?.error)
                setPopup({
                    ...popup
                        ,isVisible: true
                        ,message: "Atenção!"
                })
            })
        }
        else{
            setMessageError("Adicione um endereço para continuar.")
            setPopup({
                ...popup
                    ,isVisible: true
                    ,message: "Atenção!"
            })
        }
    } 

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const closeButtonPopupTemplateHtml = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: onHidenPopupHtmlTemplate
    }

    //Funções
    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        showLoadPanel()
        httpRequest("POST",
            requestsApi.updateRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "json": jsonRow,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
        showLoadPanel()
        httpRequest("POST",
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const recordInsertJsonDetail = (entityNameGrid, formData, refDataGrid) => {
        delete formData.id;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            // modeEditOff()
            refDataGrid.current.instance.refresh()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            hideLoadPanel()
            if (error?.data?.error) {
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
            else {
                setMessageError(error?.statusText)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        })
    }

    const addRow = useCallback(() => {
        refDataGridEderecos.current.instance.addRow();
        refDataGridEderecos.current.instance.deselectAll();
      }, [refDataGridEderecos]);
  
    const handleBatchSave = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.id;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props.clienteId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {
                const dataObjPermissionUpdate = {
                    "id": i.key
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.id;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };

                console.log(dataObjPermissionUpdate)
                console.log(Object.keys(dataObjPermissionUpdate))

                if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                    updateArr.push(dataObjPermissionUpdate);
                } 
                // else {
                //     refreshEnderecos();
                // }

                console.log()

                if(fieldsNotPermissionUpdateArray.length > 0){
                    let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                    for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                        mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                    }

                    mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."

                    setMessageError(mensagemErroCamposSemPermissao)
                    setPopup({
                        ...props.popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_enderecos", insertArr, refDataGridEderecos);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_enderecos", updateArr, refDataGridEderecos);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshEnderecos()
        }

        setModeEditDataGridEnderecosState({
            edit: false
        })

    };
  
    const onRowInserting = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            // notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }
  
    const onRowRemoved = (e) => {
        recordDeleteJsonDetail("_cadastro_enderecos", e.key)
        refreshEnderecos()
        setModeEditDataGridEnderecosState({
            edit: false
        })
    }
  
    const onEditorPreparing = (e) => {
        if(e.editorName === "dxCheckBox"){
            e.editorOptions.value = e.value === null ? false : e.value
        }
    }

    return(
        <div style={{padding: "0.5em"}}>

            <LoadPanel
                style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />
            {popupRecordFormOpen === true ? <RenderRecordFormPopup /> : <></>}
            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={popup.message}
                width={600}
                height={280}
                resizeEnabled={true}
                >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {messageError &&
                    <p>{messageError}</p>
                }
            </Popup>


            <Popup
                visible={popupHtmlTemplateVisible}
                onHiding={onHidenPopupHtmlTemplate}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title=""
                fullScreen={true}
                closeOnOutsideClick={false}
                resizeEnabled={true}
                >
                <ScrollView width='100%' height='100%' useNative={true}>
                    {/* <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopupTemplateHtml}
                    /> */}
                    {/* <Funnel>
                        <Export 
                            enabled={true}
                            printingEnabled={true}
                        />
                    </Funnel> */}
                    {htmlTemplate &&
                    <div>
                        <button onClick={() => {
                            var content = document.getElementById("divcontents");
                            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                            pri.document.open();
                            pri.document.write(content.innerHTML);
                            pri.document.close();
                            pri.focus();
                            pri.print();
                        }}>Imprimir</button>
                        <iframe id="ifmcontentstoprint" scrolling="no" style={{height: "200vh", width: "100%", position: "relative", zIndex: "-1"}}>
                            {/* {htmlTemplate && */}
                                <div id="divcontents" dangerouslySetInnerHTML={htmlTemplate}/>
                            {/* } */}
                        </iframe>
                    </div>
                    }
                </ScrollView>
            </Popup>


            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={3000}
                height={60}
            />
            {props.gerarBoletoDataSource ? 
            (
                <div>
                    <Form
                        colCount={4}
                        ref={_formGerarAcordo}
                        onFieldDataChanged={(item) => {
                            setJsonUpdate({
                                ...jsonUpdate
                                , id: item.value
                            })
                        }}
                    >
                        <GroupItem
                            colSpan={4}
                            colCount={4}
                            // caption="teste"
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxDateBox" 
                                dataField="Primeiro vencimento"
                                fieldName="_primeiroVencimento"
                                editorOptions=
                                {{ 
                                    value: props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._primeirovencimento,
                                    readOnly: true,
                                    dateSerializationFormat:"yyyy-MM-dd"
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox"  
                                dataField="Faixa de Atraso"
                                fieldName="_atraso" 
                                editorOptions=
                                {{ 
                                    value: props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._atraso,
                                    readOnly: true,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxTextBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Enquadrado na regra"
                                // dataSource={props.dataEntity}
                                fieldName="_descontodescricao"
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    value: props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontodescricao,
                                    readOnly: true,
                                }}
                            />

                            {/* <SimpleItem
                                colSpan={2}
                                editorType="dxDropDownBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Descontos validados"
                                // dataSource={props.dataEntity}
                                // fieldName="_descontodescricao"
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    // value: props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontodescricao,
                                }}
                            /> */}

                            <GroupItem
                                colCount={2}
                                colSpan={2}
                            >

                                <SimpleItem
                                    colSpan={2} 
                                    deferRendering={false}
                                    render={() => {
                                        return(
                                            <div style={{"display": "flex"}}>
                                                <DropDownBox
                                                    width={"100%"}
                                                    value={jsonUpdate?.id}
                                                    valueExpr="id"
                                                    displayExpr="descricao"
                                                    visible={true}
                                                    dataSource={[jsonUpdate]}
                                                    deferRendering={true}
                                                    placeholder="Selecione uma opção"
                                                    contentRender={() => {
                                                        return (
                                                            <ScrollView width="100%" height="100%" useNative={true}>
                                                                <DataGrid
                                                                    // className="datagrid-view"
                                                                    dataSource={descontosValidosState}
                                                                    contextMenuEnabled={true}
                                                                    // selectedRowKeys={`${jsonUpdate[senderSimpleItem.lookUpListSource]}` || []}
                                                                    keyExpr='id'
                                                                    onSelectionChanged={(sender) => {
                                                                        props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal = sender?.selectedRowsData?.[0]?._descontoprincipal 
                                                                        props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros = sender?.selectedRowsData?.[0]?._descontojuros
                                                                        props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta = sender?.selectedRowsData?.[0]?._descontomulta
                                                                        props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario = sender?.selectedRowsData?.[0]?._descontohonorario
                                                                        
                                                                        // sender?.selectedRowsData?.[0]?._validade
                                                                        setJsonUpdate({
                                                                            "id": sender?.selectedRowsData?.[0]?.id
                                                                            , "descricao": `Principal ${sender?.selectedRowsData?.[0]?._descontoprincipal}% Juros ${sender?.selectedRowsData?.[0]?._descontojuros}% Multa ${sender?.selectedRowsData?.[0]?._descontomulta}% Honorario ${sender?.selectedRowsData?.[0]?._descontohonorario}%`
                                                                        })
                                                                    }}

                                                                >

                                                                    <Column
                                                                        dataField={"_descontoprincipal"}
                                                                        caption={"Principal"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontohonorario"}
                                                                        caption={"Honorario"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontojuros"}
                                                                        caption={"Juros"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_descontomulta"}
                                                                        caption={"Multa"}
                                                                        format="#0'%'"
                                                                    />

                                                                    <Column
                                                                        dataField={"_validade"}
                                                                        caption={"Validade"}
                                                                        dataType='date'
                                                                    />

                                                                    <Selection mode="single" keyExpr='id' />
                                                                    <Scrolling
                                                                        useNative={true}
                                                                        rowRenderingMode='infinity'
                                                                        showScrollbar='always'
                                                                    >
                                                                    </Scrolling>
                                                                    <Paging defaultPageSize={10} />
                                                                    <FilterRow visible={true} />
                                                                </DataGrid>
                                                            </ScrollView>
                                                        );
                                                    }}
                                                />

                                                <Button
                                                    style={{"marginLeft": "0.5em"}}
                                                    icon="refresh"
                                                    onClick={getDescontosValidos}
                                                />
                                            </div>
                                        )
                                    }}
                                >
                                    <Label 
                                        text="Campanha desconto"
                                        // location="left"
                                    />
                                </SimpleItem>
                            </GroupItem>



                            {props.gerarBoletoDataSource.arrayTotais.tipoNegociacao.id === 2 ?
                                (
                                    <SimpleItem
                                        colSpan={2}
                                        editorType="dxRadioGroup" 
                                        // disabled={field.readOnly}
                                        // hint={field.hint}
                                        //caption="Primeiro vencimento:" 
                                        dataField="Opção de pagamento"
                                        // value={states.radioOpcaoPagamento[0]}
                                        fieldName="opcaoDePagamento"
                                        // key={indice} 
                                        editorOptions=
                                        {{
                                            items: states.radioOpcaoPagamento,
                                            value: states.opcaoPagamentoItemSelecionado,
                                            layout: "horizontal",
                                            onValueChanged: (sender) => {
                                                setStates({...states,
                                                    opcaoPagamentoItemSelecionado: sender.value,
                                                    descontoPrincipalEscolhido: 0,
                                                    descontoJurosEscolhido: 0,
                                                    descontoMultaEscolhido: 0,
                                                    descontoHonorarioEscolhido: 0
                                                })
                                            }
                                            // valueChangeEvent: "keyup"
                                        }}
                                    />
                                )
                                :
                                (
                                    <GroupItem
                                        colSpan={2}
                                        colCount={2}
                                    ></GroupItem>  
                                )
                            }

                            <SimpleItem
                                colSpan={2}
                                editorType="dxRadioGroup" 
                                dataField="Desconto"
                                fieldName="desconto"
                                editorOptions=
                                {{ 
                                    items: states.radioDesconto, 
                                    // defaultValue: "Percentual",
                                    value: states.descontoItemSelecionado,
                                    layout: "horizontal",
                                    onValueChanged: (sender) => {
                                        //voltar aqui
                                        setStates({...states,
                                            descontoItemSelecionado: sender.value,
                                            descontoPrincipalEscolhido: 0,
                                            descontoJurosEscolhido: 0,
                                            descontoMultaEscolhido: 0,
                                            descontoHonorarioEscolhido: 0
                                        })
                                        setDescontoFinal(0)
                                        setValorFinal(statesPropsValues.total)
                                        // setValorFinal(statesPropsValues.total + statesPropsValues._taxaboletovalor)
                                        repaintForm()
                                    }
                                }}
                            />
                        </GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        ></GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        ></GroupItem>

                        <GroupItem
                            colSpan={4}
                            colCount={4}
                        >

                            <SimpleItem
                                colSpan={1}
                            />

                            <SimpleItem
                                colSpan={1}
                            />

                            <SimpleItem
                                colSpan={1}
                                render={() => {
                                    return (
                                        <b>Máximo</b>
                                    )
                                }}
                            />

                            <SimpleItem
                                colSpan={1}
                                render={() => {
                                    return (
                                        <b>Escolhido</b>
                                    )
                                }}
                            />
                            
                        </GroupItem>

                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Valor Principal (+)"
                                // dataSource={props.dataEntity}
                                fieldName="valorPrincipal"
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            valor: sender.value
                                        })
                                    },
                                    // value: statesPropsValues.valor,
                                    value: statesPropsValues.valor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Juros (+)"
                                fieldName="valorJuros"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            jurosvalor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.jurosvalor,
                                    // value: statesPropsValues.jurosvalor,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Mora (+)"
                                fieldName="moraValor"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            moraValor: sender.value
                                        })
                                    },
                                    value:  statesPropsValues.moraValor,
                                    min: 0
                                }}
                            />
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Multa (+)"
                                fieldName="valorMulta"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            multavalor: sender.value
                                        })
                                    },
                                    value: statesPropsValues.multavalor,
                                    // value: statesPropsValues.multavalor,
                                    min: 0
                                }}
                            />
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Honorário (+)"
                                fieldName="valorHonorario"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    onValueChanged: (sender) => {
                                        // if(props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1){
                                        //     setHonorarioCalculadoValueState(sender.value)
                                        // }
                                        // else{
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                honorariovalor: sender.value
                                            })
                                        // }
                                    },
                                    value: statesPropsValues.honorariovalor,
                                    // value: props.gerarBoletoDataSource?.resultgetFaseCalculadaTituloJsonData?._isrecalcularhonorarioaplicandodesconto === 1 ? honorarioCalculadoValueState : statesPropsValues.honorariovalor,
                                    min: 0
                                }}
                            />
                        </GroupItem>

                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        >
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox"
                                dataField="Principal"
                                // dataSource={props.dataEntity}
                                fieldName="principalMaximo"
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * 
                                    statesPropsValues.valor,
                                    readOnly: true,
                                    min: 0
                                }}  
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Juros"
                                fieldName="jurosMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                    statesPropsValues.jurosvalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Mora"
                                fieldName="moraMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                    statesPropsValues.moraValor,
                                    readOnly: true,
                                    min: 0,
                                    max: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                    statesPropsValues.moraValor
                                    
                                }} 
                            />
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Multa"
                                fieldName="multaMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                    statesPropsValues.multavalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Honorário"
                                fieldName="honorarioMaximo"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: states.descontoItemSelecionado === "Percentual" ? 
                                    props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                    : 
                                    (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                    statesPropsValues.honorariovalor,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />
                            
                        </GroupItem>

                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        >
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Principal"
                                fieldName="principalEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoPrincipalEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                    setStates({
                                                        ...states, 
                                                        descontoPrincipalEscolhido: sender.value
                                                    })
                                            }}
                                            value={states.descontoPrincipalEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                            : 
                                            (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * 
                                            statesPropsValues.valor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({...states, 
                                //             descontoPrincipalEscolhido: sender.value})
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100 
                                //     : 
                                //     (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontoprincipal/100) * 
                                //     statesPropsValues.valor

                                // }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                fieldName="jurosEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice}
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoJurosEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                    setStates({
                                                        ...states, 
                                                        descontoJurosEscolhido: sender.value
                                                    })
                                            }}
                                            value={states.descontoJurosEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                            : 
                                            (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                            statesPropsValues.jurosvalor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //             setStates({...states, 
                                //                 descontoJurosEscolhido: sender.value})
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100 
                                //     : 
                                //     (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontojuros/100) * 
                                //     statesPropsValues.jurosvalor
                                // }} 
                            />

                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly} 
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Honorário"
                                fieldName="moraEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoMoraEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                setStates({
                                                    ...states, 
                                                    descontoMoraEscolhido: sender.value
                                                })
                                            }}
                                            value={states.descontoMoraEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100 
                                            : 
                                            (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomora/100) * 
                                            statesPropsValues.moraValor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //         setStates({
                                //             ...states,
                                //             descontoHonorarioEscolhido: sender.value
                                //         });
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                //     : 
                                //     (gerarAcordoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                //     statesPropsValues.honorariovalor
                                // }} 
                            />
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Multa"
                                fieldName="multaEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoMultaEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                    setStates({
                                                        ...states, 
                                                        descontoMultaEscolhido: sender.value
                                                    })
                                            }}
                                            value={states.descontoMultaEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                            : 
                                            (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                            statesPropsValues.multavalor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {      
                                //         setStates({...states, 
                                //             descontoMultaEscolhido: sender.value})
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100 
                                //     : 
                                //     (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontomulta/100) * 
                                //     statesPropsValues.multavalor
                                // }} 
                            />
                            <SimpleItem
                                colSpan={1}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                // dataField="Honorário"
                                fieldName="honorarioEscolhido"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                render={(item) => {
                                    return(
                                        <NumberBox 
                                            ref={_descontoHonorarioEscolhidoRef}
                                            format={states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)"}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(sender) => {
                                                    setStates({
                                                        ...states, 
                                                        descontoHonorarioEscolhido: sender.value
                                                    })
                                            }}
                                            value={states.descontoHonorarioEscolhido}
                                            min={0}
                                            max={states.descontoItemSelecionado === "Percentual" ? 
                                            props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                            : 
                                            (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                            statesPropsValues.honorariovalor}
                                        />
                                    )
                                }}
                                // editorOptions=
                                // {{ 
                                //     format: states.descontoItemSelecionado === "Percentual" ? "#0%" : "R$ #,##0.##;(R$ #,##0.##)",
                                //     valueChangeEvent: "keyup",
                                //     onValueChanged: (sender) => {
                                //             setStates({...states, 
                                //                 descontoHonorarioEscolhido: sender.value})
                                //     },
                                //     min: 0,
                                //     max: states.descontoItemSelecionado === "Percentual" ? 
                                //     props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100 
                                //     : 
                                //     (props.gerarBoletoDataSource.resultgetFaseCalculadaTituloJsonData._descontohonorario/100) * 
                                //     statesPropsValues.honorariovalor
                                // }} 
                            />
                        </GroupItem>

                        {/* <GroupItem
                            colSpan={4}
                            colCount={4}
                        >
                            

                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={4}
                            colCount={4}
                        >


                            
                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={4}
                            colCount={4}
                        >

                            
                        </GroupItem> */}

                        {/* <GroupItem
                            colSpan={4}
                            colCount={4}
                        >
                            

                           

                            
                        </GroupItem> */}

                        <GroupItem

                            colSpan={2}
                            colCount={2}
                        >
                            {statesPropsValues.cartorioValor > 0 &&
                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxNumberBox" 
                                    // disabled={field.readOnly}
                                    // hint={field.hint}
                                    //caption="Primeiro vencimento:" 
                                    dataField="Cartório (+)"
                                    fieldName="cartorioValor"
                                    // dataSource={props.dataEntity}
                                    // fieldName={field.fieldCaption}
                                    // key={indice} 
                                    editorOptions=
                                    {{ 
                                        format: "R$ #,##0.##;(R$ #,##0.##)",
                                        valueChangeEvent: "keyup",
                                        readOnly: !specialPermissions?.find((e) => {
                                            if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                                return true
                                            }
                                            else{
                                                return false
                                            }
                                        }),
                                        onValueChanged: (sender) => {
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                cartorioValor: sender.value
                                            })
                                        },
                                        value: statesPropsValues.cartorioValor,
                                        min: 0
                                    }}
                                />
                            }

                            {statesPropsValues.outrasDespesasValor > 0 &&
                                <SimpleItem
                                    colSpan={2}
                                    editorType="dxNumberBox" 
                                    // disabled={field.readOnly}
                                    // hint={field.hint}
                                    //caption="Primeiro vencimento:" 
                                    dataField="Pré-jurídico (+)"
                                    fieldName="outrasDespesasValor"
                                    // dataSource={props.dataEntity}
                                    // fieldName={field.fieldCaption}
                                    // key={indice} 
                                    editorOptions=
                                    {{ 
                                        format: "R$ #,##0.##;(R$ #,##0.##)",
                                        valueChangeEvent: "keyup",
                                        readOnly: !specialPermissions?.find((e) => {
                                            if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                                return true
                                            }
                                            else{
                                                return false
                                            }
                                        }),
                                        onValueChanged: (sender) => {
                                            setStatesPropsValues({
                                                ...statesPropsValues, 
                                                outrasDespesasValor: sender.value
                                            })
                                        },
                                        value: statesPropsValues.outrasDespesasValor,
                                        // value: statesPropsValues.outrasDespesasValor,
                                        min: 0
                                    }}
                                />
                            }

                        </GroupItem>

                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        ></GroupItem>


                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Saldo Atualizado (=)"
                                fieldName="saldoAtualizado"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: saldoAtualizadoState ? saldoAtualizadoState : statesPropsValues.total,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Taxa do Boleto (+)"
                                fieldName="_taxaboletovalor"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.00;(R$ #,##0.00)",
                                    valueChangeEvent: "keyup",
                                    onValueChanged: (sender) => {
                                        setStatesPropsValues({
                                            ...statesPropsValues, 
                                            _taxaboletovalor: sender.value
                                        })
                                    },
                                    value: statesPropsValues._taxaboletovalor,
                                    // value: statesPropsValues._taxaboletovalor,
                                    readOnly: !specialPermissions?.find((e) => {
                                        if(e.PermissionName === "Boleto.ValorAvulso" && e._execute === 1){
                                            return true
                                        }
                                        else{
                                            return false
                                        }
                                    }),
                                    min: 0
                                }} 
                            />
                        </GroupItem>

                        
                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="Desconto (-)"
                                fieldName="valorDesconto"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    readOnly: true,
                                    value: descontoFinal,
                                    min: 0
                                }}
                            />

                            <SimpleItem
                                colSpan={2}
                                editorType="dxNumberBox" 
                                // disabled={field.readOnly}
                                // hint={field.hint}
                                //caption="Primeiro vencimento:" 
                                dataField="VALOR A PAGAR"
                                fieldName="valorPagar"
                                // dataSource={props.dataEntity}
                                // fieldName={field.fieldCaption}
                                // key={indice} 
                                editorOptions=
                                {{ 
                                    format: "R$ #,##0.##;(R$ #,##0.##)",
                                    valueChangeEvent: "keyup",
                                    value: valorFinal,
                                    readOnly: true,
                                    min: 0
                                }} 
                            />
                        </GroupItem>
                        
                    </Form>

                    <div id="data-grid-enderecos-e-botoes-div">
                        <div id="data-grid-enderecos-div">
                            <div className={`btns-data-grid-enderecos-${modeEditDataGridEnderecosState.edit}`}>
                                <div className="buttons-tabpanel">
                                    <Button            
                                        icon="add"
                                        hint={`Incluir um novo registro`}
                                        // disabled={itemPermissions ? (!itemPermissions?._insert) : (true)}
                                        type="normal"
                                        onClick={() => {
                                            if(modeEditDataGridEnderecosState.edit){
                                                addRow()
                                            }
                                            else{
                                                showLoadPanel()

                                                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                                    entityName: '_cadastro_enderecos',
                                                    applicationId: applicationID,
                                                })
                                                .then((sender) => {
                                                    setEntityStructureRecordFormPopupState(JSON.parse(sender))
    
                                                    if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                        
                                                        httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                            "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                        })
                                                        .then((sender) => {
                                                            setDataLookupRecordFormPopupState(JSON.parse(sender))
    
                                                            setKeyFieldNameState('_cadastro_id')
                                                            setIDValueDetailEntity(null)
                                                            setDetailEntityName('_cadastro_enderecos')
                                                            // setDetailCaptionName(senderDetailEntity.caption)
                                                            setPopupRecordFormOpen(true)
                                                            setPopupState(true)
                                                            setStateModeEdit(true)
                                                            hideLoadPanel()
                                                        })
                                                        .catch((error) => {
                                                            console.log(error)
                                                            hideLoadPanel()
                                                            // setPopupStates({
                                                            //     visible: true,
                                                            //     message: error?.data?.error
                                                            // })
                                                        })
                                                    }
                                                    else{
                                                        setKeyFieldNameState('_cadastro_id')
                                                        setIDValueDetailEntity(null)
                                                        setDetailEntityName('_cadastro_enderecos')
                                                        // setDetailCaptionName(senderDetailEntity.caption)
                                                        setPopupRecordFormOpen(true)
                                                        setPopupState(true)
                                                        setStateModeEdit(true)
                                                        hideLoadPanel()
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                    hideLoadPanel()
                                                    // setPopupStates({
                                                    //     visible: true,
                                                    //     message: error?.data?.error
                                                    // })
                                                })
                                            }
                                            
                                        }}
                                    />
                                </div>

                                <div className="buttons-tabpanel">
                                    <Button
                                        icon="rowproperties"
                                        hint="Editar registros em lote no grid"
                                        type="normal"
                                        onClick={() => {
                                            setModeEditDataGridEnderecosState({
                                                edit: !modeEditDataGridEnderecosState.edit
                                            })
                                        }}
                                    />
                                </div>

                                <div className="buttons-tabpanel">
                                    <Button
                                        icon="refresh"
                                        hint="Atualizar endereços"
                                        type="normal"
                                        onClick={refreshEnderecos}
                                    />
                                </div>
                            </div>

                            <DataGrid
                                id={`data-grid-enderecos-gerar-boleto-${modeEditDataGridEnderecosState.edit}`}
                                //onEditingStart: event when click to edit or add new registry
                                ref={refDataGridEderecos}
                                keyExpr="id"
                                className="datagrid-detail-entity"
                                dataSource={props.dataSourceEnderecosState}
                                errorRowEnabled={false}
                                //repaintChangesOnly={true} (pode afetar a performance) comentado 17/05/2022, pois quando agrupava no grid e tentava editar e depois salvar quebrava o groupIndex                
                                renderAsync={true}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                columnAutoWidth={true}
                                columnHidingEnabled={true}
                                onRowInserting={onRowInserting}
                                onRowRemoved={onRowRemoved}
                                onSaving={handleBatchSave}                
                                onEditorPreparing={onEditorPreparing}
                                // onCellPrepared={onCellPrepared}
                                // onRowDblClick={onRowDblClick}
                                onSelectionChanged={(e) => {
                                    if(e?.selectedRowsData?.[0]?.id){
                                        setEnderecoIdSelecionadoState(e?.selectedRowsData?.[0]?.id)
                                    }
                                }}
                                allowColumnReordering={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                focusedRowEnabled={true}
                                focusedRowKey={0}
                                columnMinWidth={50}
                                // onContextMenuPreparing={openMenuContextFunction}
                                activeStateEnabled={false}
                                cacheEnabled={false}
                                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                                onEditCanceled={() => {
                                    setModeEditDataGridEnderecosState({
                                        edit: false
                                    })
                                }}
                            >
                                <Selection
                                    keyExpr="id"
                                    mode="single"
                                    selectAllMode="allPages"
                                    showCheckBoxesMode="onClick"
                                />
                                <Scrolling
                                    useNative={true}
                                    rowRenderingMode='virtual'
                                    showScrollbar='always'

                                // rowRenderingMode="standard"
                                // mode="virtual"
                                >
                                </Scrolling>

                                <Paging defaultPageSize={2} />

                                <Sorting
                                    mode="multiple"
                                />
                                <Pager
                                    visible={true}
                                    allowedPageSizes={2}
                                    showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                <Editing
                                    allowDeleting={modeEditDataGridEnderecosState.edit }
                                    allowUpdating={modeEditDataGridEnderecosState.edit }
                                    // allowAdding={modeEditDataGridEnderecosState.edit }
                                    confirmDelete={true}
                                    mode="batch"
                                    useIcons={true}
                                //selectTextOnEditStart={selectTextOnEditStart}
                                //startEditAction={startEditAction}
                                />
                                {/* <Column
                                    caption="Deletar"
                                    visible={modeEditDataGridEnderecosState.edit && props.itemPermissions?._delete}
                                    type="buttons"
                                    width={110}
                                >
                                    <Button
                                        name="delete"
                                    />
                                </Column> */}
                                <ColumnChooser
                                    enabled={false}
                                    width={400}
                                    height={800}
                                    mode={"select"}
                                    allowSearch={true} />
                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />
                                {/* <SearchPanel visible={true} /> */}
                                {props.structureDataGridEnderecosState && props.structureDataGridEnderecosState.map((senderFieldsDefs, senderIndiceFieldsDefs) => {
                                    /**
                                     * Verifica se o conditionalVisibleFieldName está preenchido,
                                     * se estiver, verifica se os dados da entidade são iguais ao valor
                                     * do conditionalVisibleFieldValue para determinar se o campo deve ser exibido ou não
                                     */

                                    const fieldPermissions = validatePermission(senderFieldsDefs.objectid)

                                    if(fieldPermissions?._edit === 1){
                                        fieldsPermissionUpdateArray.push(senderFieldsDefs.fieldName)
                                    }


                                    const records = props.dataSourceEnderecosState;  
                                    
                                    if (senderFieldsDefs.editorType === "dxCheckBox") {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                editorOptions={{
                                                    setCellValue: (rowData, value) => {
                                                        console.log(value)
                                                        console.log("dxCheckBox")
                                                        // let newValue = (value == true ? 1 : 0) ;
                                                        // this.defaultSetCellValue(rowData, newValue);
                                                    }
                                                }}
                                                dataType={"boolean"}
                                            >

                                            </Column>
                                        )
                                    }

                                    else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "datetime") {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                // editorOptions={editorOptionsColumns}
                                                dataType={senderFieldsDefs.fieldType}
                                                format="shortDateShortTime"
                                            >

                                            </Column>
                                        )
                                    }

                                    else if (senderFieldsDefs.editorType === "upfTime") {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                // editorOptions={editorOptionsColumns}
                                                dataType={"datetime"}
                                                format="shortTime"
                                            >

                                            </Column>
                                        )
                                    }

                                    else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "date") {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                // editorOptions={editorOptionsColumns}
                                                dataType={senderFieldsDefs.fieldType}
                                                format="shortDate"
                                            >

                                            </Column>
                                        )
                                    }

                                    else if (senderFieldsDefs.editorType === "dxLookupEdit") {
                                        if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup) {

                                            const newDataSource = new DataSource({
                                                store: {
                                                data: props.dataLookup[senderFieldsDefs.lookUpListSource],
                                                type: 'array',
                                                key: senderFieldsDefs.lookUpKeyField,
                                                },
                                            });


                                            return (
                                                <Column
                                                    key={senderIndiceFieldsDefs}
                                                    fixed={senderFieldsDefs.fixed}
                                                    visible={senderFieldsDefs.gridVisible === 1}
                                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                    dataField={senderFieldsDefs.fieldName}
                                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                                    caption={senderFieldsDefs.fieldCaption}
                                                    dataType={senderFieldsDefs.fieldType}
                                                    editorOptions={{
                                                        acceptCustomValue: true,
                                                        searchEnabled: true,
                                                        customItemCreateEvent: "focusout",
                                                        searchTimeout: 200,
                                                        minSearchLength: 0,
                                                        showDataBeforeSearch: false,
                                                        opened: true,
                                                        onCustomItemCreating: (args) => {
                                                            if (!args.text) {
                                                                args.customItem = null;
                                                                return;
                                                            }
                                                            else{
                                                                const newItem = {};

                                                                newItem[senderFieldsDefs.lookUpKeyField] = args.text
                                                                newItem[senderFieldsDefs.lookUpResultField] = args.text
                                                            
                                                                args.customItem = newDataSource.store().insert(newItem)
                                                                .then(() => newDataSource.load())
                                                                .then(() => newItem)
                                                                .catch((error) => {
                                                                throw error;
                                                                });
                                                            }
                                                        }    
                                                    }}
                                                >
                                                    <Lookup
                                                        // dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                        dataSource={newDataSource.store()._array}
                                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                                        allowClearing={true}
                                                        showClearButton={true}
                                                    />

                                                </Column>
                                            )
                                        }
                                    }

                                    else if (senderFieldsDefs.editorType === "dxLookup" ||
                                        senderFieldsDefs.editorType === "dxSelectBox") {
                                        if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup) {

                                            return (
                                                <Column
                                                    key={senderIndiceFieldsDefs}
                                                    fixed={senderFieldsDefs.fixed}
                                                    visible={senderFieldsDefs.gridVisible === 1}
                                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                    dataField={senderFieldsDefs.fieldName}
                                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                                    caption={senderFieldsDefs.fieldCaption}
                                                    dataType={senderFieldsDefs.fieldType}
                                                    filterType='include'
                                                // editCellRender={
                                                // format={senderFieldsDefs.fieldMask}
                                                //width={senderFieldsDefs.FieldSize}
                                                >
                                                    <Lookup
                                                        dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                                        allowClearing={true}
                                                        showClearButton={true}
                                                    />

                                                </Column>
                                            )
                                        }
                                    }

                                    else if (senderFieldsDefs.editorType === "upfLookupDetailEntity") {
                                        if (senderFieldsDefs.lookUpKeyField != null && props.dataEntity) {
                                            return (
                                                <Column
                                                    key={senderIndiceFieldsDefs}
                                                    fixed={senderFieldsDefs.fixed}
                                                    visible={senderFieldsDefs.gridVisible === 1}
                                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                    dataField={senderFieldsDefs.fieldName}
                                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                                    caption={senderFieldsDefs.fieldCaption}
                                                    dataType={senderFieldsDefs.fieldType}
                                                // editCellRender={
                                                // format={senderFieldsDefs.fieldMask}
                                                //width={senderFieldsDefs.FieldSize}
                                                >
                                                    <Lookup
                                                        dataSource={props.dataEntityDetailEntity.find(
                                                            element => element.entityName === senderFieldsDefs.lookUpListSource
                                                        )?.records}
                                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                                        allowClearing={true}
                                                        showClearButton={true}
                                                    />

                                                </Column>
                                            )
                                        }
                                    }

                                    

                                    else if (senderFieldsDefs.editorType === "upfHtmlViewer" || senderFieldsDefs.editorType === "upfHtmlBox") {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                groupIndex={senderFieldsDefs.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                dataType={senderFieldsDefs.fieldType} 
                                                cellRender={(cellData) => {
                                                    return(
                                                        <UpfHtmlEditor 
                                                            referencia={refDataGridEderecos?.current?.instance}
                                                            setFormDataJson={props.setFormDataJson}
                                                            setDataEntity={props.setDataEntity}
                                                            dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                                            entityStructure={senderFieldsDefs}
                                                            colSpan={senderFieldsDefs.colSpan} 
                                                            caption={senderFieldsDefs.fieldCaption} 
                                                            dataField={senderFieldsDefs.fieldName}
                                                            disabled={!modeEditDataGridEnderecosState.edit} 
                                                            hint={senderFieldsDefs.hint}
                                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                            showEditBar={false}
                                                            cellData={cellData}
                                                        />
                                                    )}
                                                }
                                            />
                                        )
                                    }

                                    else {
                                        return (
                                            <Column
                                                key={senderIndiceFieldsDefs}
                                                fixed={senderFieldsDefs.fixed}
                                                visible={senderFieldsDefs.gridVisible === 1}
                                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1}
                                                dataField={senderFieldsDefs.fieldName}
                                                allowEditing={senderFieldsDefs.readOnly != 1}
                                                caption={senderFieldsDefs.fieldCaption}
                                                // format={formatFunction(senderFieldsDefs.fieldMask,  "column", senderFieldsDefs.fieldName, dadosDaEntidadeDetalheState.records, modeEditDataGridEnderecosState?.edit)}
                                            // dataType={"datetime"}
                                            // editorOptions={editorOptionsColumns}

                                            // format={senderFieldsDefs.fieldMask}
                                            //width={senderFieldsDefs.FieldSize}
                                            >
                                                {/* <Lookup
                                                                        dataSource ={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                                                        displayExpr ={senderFieldsDefs.lookUpResultField}
                                                                    /> */}

                                            </Column>
                                        )
                                    }
                                }
                                )}
                            </DataGrid>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", marginTop: "0.5em", marginLeft: "0.5em", alignItems: "center", justifyContent: "center", width: "400px"}}>
                            <div style={{marginRight: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <Button
                                    width={130}
                                    height={40}
                                    text="Anterior"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        props.setGerarBoletoDataSource(null)
                                        //pegar todos os dados e enviar para a API 
                                        //...
                                        //ir para o proximo item
                                        setStates({...states,
                                            opcaoPagamentoItemSelecionado: "Parcelamento"
                                        })
                                        props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto - 1)
                                    }}
                                />
                            </div>

                            <div className="button-with-load-div">
                                <Button
                                    width={130}
                                    height={40}
                                    type="default"
                                    stylingMode="contained"
                                    text="Gerar Boleto"
                                    disabled={gerarBoletoDisabledState}
                                    onClick={() => {
                                        gerarBoleto()
                                    }}
                                />
                                {gerarBoletoDisabledState &&
                                    <LoadIndicator/>
                                }
                            </div>
                        </div>
                    </div>

                    
                </div>
            )
            :
            (
                <Loading/>
            )
            }
            {/* <div >
                

            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "end", width: "50%"}}>
                    <b>Máximo</b>
                    <b>Escolhido</b>
                </div>
            </div>

            /> */}
        </div>
    )
}

export default memo(Passo2);