const { createContext, useContext, useState } = require("react");

const WidgetsTeleCob = createContext();

export const WidgetsTeleCobProvider = ({ children }) => {
  const [widgetsTeleCob, setWidgetsTeleCob] = useState([])

  return (
    <WidgetsTeleCob.Provider value={{ widgetsTeleCob, setWidgetsTeleCob }}>
      {children}
    </WidgetsTeleCob.Provider>
  );
};

export const useWidgetsTeleCob = () => useContext(WidgetsTeleCob);