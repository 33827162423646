import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import TarefaCustomForm from '../../Components/TarefaCustomForm';
import ScrollView from 'devextreme-react/scroll-view';
import LogCustomForm from '../../Components/RecordForm/LogCustomForm'

const UpfPopupCustomForm = (props) => {

    return(

        <Popup
            visible={props.visibleState}
            onHiding={props.hideUpfPopupForm}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            showTitle={true}
            title={props.title}
            fullScreen={true}
            resizeEnabled={true}
        >

            {props.formName === "TarefaCustomForm" &&
                <ScrollView width='100%' height='100%'>
                    <TarefaCustomForm 
                        registroId={props.idValue}
                        entityName={props.entityName}
                        tarefaId={props.tarefaId}
                        caption={props.caption}
                        // entityStructure={props.entityStructure}
                    />
                </ScrollView>
            }

            {props.formName === "LogCustomForm" &&
                <ScrollView width='100%' height='100%'>
                    <LogCustomForm 
                        objectId={props.idValue}
                        entityName={props.entityName}
                        caption={props.caption}
                        dados={props.dados}
                        estrutura={props.estrutura}
                        // entityStructure={props.entityStructure}
                    />
                </ScrollView>
            }
        </Popup>
    )

}

export default UpfPopupCustomForm;