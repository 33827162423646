import {useState, useEffect, useRef, useCallback, memo} from "react"
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Loading from "../../CardComponents/Loading"
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import RecordForm from "../RecordForm";
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Lookup,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel
    } from 'devextreme-react/data-grid';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage"
import RecordFormPopup from "../RecordForm/RecordFormPopup";
import { Toast } from 'devextreme-react/toast';

const ConsultaBoletoForm = (props) => {
    console.log(props)
    const refDataGridConsultaBoletoForm = useRef(null)

    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [contatosDataSourceState, setContatosDataSourceState] = useState([])
    const [dataLookupState, setDataLookupState] = useState([])
    const [states, setStates] = useState({
        selectedItems: []
    })
    const [resultBinary, setResultBinary] = useState()
    const popups = {
        isVisible: false
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [desabilitarBotoesState, setDesabilitarBotoesState] = useState(true)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState(false);
    const [emailState, setEmailState] = useState()
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })
    const [entityStructureRecordFormBoleto, setEntityStructureRecordFormBoleto] = useState()


    const onHiding = () => {
        setEntityStructureRecordFormBoleto(null)

        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        });
    }, [toastConfig])

    const [dataLookupRecordFormBoleto, setDataLookupRecordFormBoleto] = useState()


    useEffect(() => {
        if(props.telefones){
            setContatosDataSourceState(props.telefones)
        }
        if(props.dataLookup){
            setDataLookupState(props.dataLookup)
        }
        if(props.email){
            setEmailState(props.email)
        }
        if((props.boletoId || props.arquivopdf) && !entityStructureRecordFormBoleto){
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "relationId": relationId,
                "entityName": "_titulo_boleto"
            })
            .then((sender) => {
                const resulGetEntityStructureJson = JSON.parse(sender)
                setEntityStructureRecordFormBoleto(resulGetEntityStructureJson)
                if (resulGetEntityStructureJson[0]["lookUpEntities"]?.length > 0) {
                    const sendLookupRequest = {
                        "lookUpEntities": resulGetEntityStructureJson[0].lookUpEntities
                    }
                    httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
                        .then(senderLookup => {
                            const resultLookup = JSON.parse(senderLookup)
                            setDataLookupRecordFormBoleto(resultLookup)
                        })
                        .catch((error) => {console.log(error)})
                }
            })
            .catch((error) => {console.log(error)})
        }
    },[props.consultaBoletoFormPopupState, props.email, props.telefones, props.boletoId, props.arquivopdf, entityStructureRecordFormBoleto])

    useEffect(() => {
        if(props.boletoId){
            httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "relationId": relationId,
                "tituloBoletoId": props.boletoId
            })
            .then((sender) => {
                const resultJson = JSON.parse(sender)
                console.log(resultJson._arquivo)
                // convertBinaryForPDF(resultJson.arquivo)
                if (resultJson._arquivo != null) {
                    var contentType = "application/pdf";
              
                    setResultBinary({
                      doc: "data:" + contentType + ";base64," + resultJson._arquivo
                    });
                    setDesabilitarBotoesState(false)
                } 
    
                else {
                    setDesabilitarBotoesState(true)
                    setResultBinary({
                      doc: ""
                    });
                }
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
        }

    }, [props.boletoId])

    const hidePopupBoleto = useCallback(() =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    },[popup]);

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const openPopup = () => {
        setPopup({
            ...popup
                ,isVisible: true
        })
    }

    const convertBinaryForPDF = () => {
        if(!desabilitarBotoesState){
            if(props?.arquivopdf){
                var contentType = "application/pdf";
            
                setResultBinary({
                    doc: "data:" + contentType + ";base64," + props.arquivopdf
                });
    
                openPopup()
               
            }
            else{
                openPopup()
                // httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
                //     "applicationId": applicationID,
                //     "relationId": relationId,
                //     "tituloBoletoId": props.boletoId
                // })
                // .then((sender) => {
                //     const resultJson = JSON.parse(sender)
                //     // convertBinaryForPDF(resultJson.arquivo)
                //     if (resultJson._arquivo != null) {
                //         var contentType = "application/pdf";
                  
                //         setResultBinary({
                //           doc: "data:" + contentType + ";base64," + resultJson._arquivo
                //         });
                //       } else {
                //         setResultBinary({
                //           doc: ""
                //         });
                //     }
                // })
                // .catch((error) => {
                //     setPopupStates({
                //         visible: true,
                //         message: error.data.error
                //     })
                // })
                // .then((sender) => {
                //     openPopup()
                // })
            }
        }
    }

        // if(props.gerarBoletoDataSource) {
        //     console.log(props.gerarBoletoDataSource.boleto)
        //     if(props.gerarBoletoDataSource.boleto){
        //         httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
        //             "applicationId": applicationID,
        //             "relationId": relationId,
        //             "tituloBoletoId": props.gerarBoletoDataSource.boleto
        //         })
        //         .then((sender) => {
        //             const resultJson = JSON.parse(sender)
        //             console.log("entrou no then do getBoleto")
        //             console.log(resultJson.arquivo)
        //             convertBinaryForPDF(resultJson.arquivo)
        //         })
        //         .catch((error) => {console.log(error)}) 
        //     }
        // }
        // useEffect(() => {
        //     if(props.gerarBoletoDataSource){
        //         console.log(props.gerarBoletoDataSource)
        //     }
        // }, [props.gerarBoletoDataSource])

        const RenderRecordFormPopup = memo(() => {
            return (
                <RecordFormPopup
                    type={"recordForm"}
                    id={Math.random()}
                    // idValue={props.masterEntityId}
                    entityName={detailEntityName}
                    caption={detailCaptionName}
                    keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                    masterEntityId={props.masterEntityId}
                    popupRecordForm={true}
                    entityStructure={entityStructureRecordFormPopupState}
                    dataLookup={dataLookupRecordFormPopupState}
                    popupState={popupState}
                    setPopupState={setPopupState}
                    popupRecordFormAdd={popupRecordFormAdd}
                    setPopupRecordFormOpen={setPopupRecordFormOpen}
                    popupRecordFormOpen={popupRecordFormOpen}
                    masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                    embebedIdValueState={embebedIdValueState}
                    embebedEntityNameState={embebedEntityNameState}
                />
            );
        })

    const refreshDetailEntity = () => {
        httpRequest("POST", 
                requestsApi.getLookupTable, requestsApi.basicAuth,
                {
                    "lookUpEntities": [
                        {
                            "lookUpEntity": "_pontuacao_contato",
                        },
                        {
                            "lookUpEntity": "_tipo_contato",
                        }
                    ]
                }
            ).then((sender)=>{
                const senderDataLookup = JSON.parse(sender)

                setDataLookupState(senderDataLookup)
               
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID
                        ,"entityName": '_cadastro'
                        ,"fieldName": "id"
                        ,"expressao": props.masterEntityId
                    }
                )
                .then(senderGetDataEntity=>{
                const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                // for(let c = 0; c <= resultGetDataEntity.detailEntity.length - 1; c++) {
                //     if(Array.isArray(resultGetDataEntity.detailEntity[c]["records"])) {
                //         for(let count = 0; count <= resultGetDataEntity.detailEntity[c]["records"].length - 1; count++) {
                //             for(let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                //                 if(resultGetDataEntity.detailEntity[c].records[count][`${senderFieldNamesCheckBox[i]}`] === null) {
                //                     resultGetDataEntity.detailEntity[c].records[count][`${senderFieldNamesCheckBox[i]}`] = false
                //                 }  
                //             }
                //         }
                //     }
                // }

                //muda todas as letra da entidade para minuscula
                // for(let c = 0; c <= resultGetDataEntity.detailEntity.length - 1; c++) {
                //     resultGetDataEntity.detailEntity[c].entityName = resultGetDataEntity.detailEntity[c].entityName.toLowerCase()
                // }

                // for(let c = 0; c <= detailEntitiesItensStructure.length - 1; c++) {
                //     convertDate(resultGetDataEntity.detailEntity.find(
                //         element => element.entityName.toLowerCase() === detailEntitiesItensStructure[c].entityName
                //                                 )?.records, detailEntitiesItensStructure[c].fieldsDefs)
                // }
                setContatosDataSourceState(resultGetDataEntity.detailEntity.find((item) => item.entityName === '_cadastro_contatos')?.records)
                
                })
                .catch((error) => {
                    setPopupStates({
                    visible: true,
                    message: error.data.error
                })
                })
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
}
    const [popupStates, setPopupStates] = useState({
        visible: false,
        message: null
    })

    const hidePopupMessage = () => {
        setPopupStates({
          ...popupStates,
          visible: false
        })
      }


    
    return(
        <div style={{height: "81vh", padding: "0.5em"}}>
            <UpfPopupMessage
                hidePopup={hidePopupMessage}
                visible={popupStates.visible}
                message={popupStates.message}
            />

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={600}
                height={60}
            />

            {popupRecordFormOpen && <RenderRecordFormPopup />}
            {/* <ScrollView  width='100%' height='100%'> */}
                <Popup
                    visible={popup.isVisible}
                    onHiding={hidePopupBoleto}
                    dragEnabled={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Boleto"
                    fullScreen={true}
                    hideOnOutsideClick={false}
                    resizeEnabled={true}
                    >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopup}
                    />
                    {resultBinary &&
                        <embed
                            src={resultBinary.doc}
                            id="displayFile"
                            alt="your image"
                            width="100%"
                            height="99%"
                            style={{ borderStyle: "solid" }}
                            type="application/pdf"
                        />
                    }
                </Popup>
                {props.dataSourceBoletoState ? 
                    <>
                        <div className="div-grupos-gerar-acordo">
                            <div className="div-grupos-gerar-acordo-email">
                                
                                
                                <div style={{width: '100%'}}>
                                E-mail:
                                    <TextBox
                                        value={emailState}
                                        onValueChanged={(item) => {
                                            console.log(item.value)
                                            setEmailState(item.value)
                                        }}
                                        // mask={@"\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*"}
                                        mode='email'
                                        // maskRules={/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i}
                                        // maskInvalidMessage='E-mail inválido!'
                                        // useMaskedValue={true}
                                        // validationMessageMode='always'
                                    />
                                </div>
                            </div>
                            
                            <div style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                alignItems: "center",
                                marginTop: "1em"
                            }}>
                                <Button
                                    width={150}
                                    height={40}
                                    text="Enviar e-mail"
                                    disabled={desabilitarBotoesState ? true : false}
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        //enviar boleto para o email
                                        httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                            applicationId: applicationID,
                                            relationId: relationId,
                                            procedure: "_enviarBoletoEmail",
                                            json: {
                                                email: [emailState]//email selecionado
                                            },
                                            idValue: [`${props.boletoId}`]//id do boleto
                                        })
                                        .then((sender) => {
                                            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                        })
                                        .catch((error) => {
                                            console.log(error?.data?.error)
                                            setPopupStates({
                                                visible: true,
                                                message: error?.data?.error ? error?.data?.error : 'E-mail inválido!'
                                            })
                                        })
                                    }}
                                />
                            </div>
                                    {/* </div> */}
                        </div>
                        <div className="div-grupos-gerar-acordo">                    
                            <div style={{marginBottom: "0.5em"}}>
                                Telefones:
                            </div>

                            <div className="btn-tabPanel">
                                <div className="buttons-tabpanel">
                                    <Button            
                                        icon="add"
                                        hint={`Incluir um novo registro`}
                                        // disabled={itemPermissions ? (!itemPermissions?._insert) : (true)}
                                        type="normal"
                                        onClick={() => {
                                            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                                entityName: '_cadastro_contatos',
                                                applicationId: applicationID,
                                            })
                                            .then((sender) => {
                                                setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                                if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                    
                                                    httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                        "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                    })
                                                    .then((sender) => {
                                                        setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                        setKeyFieldNameState('_cadastro_id')
                                                        setIDValueDetailEntity(null)
                                                        setDetailEntityName('_cadastro_contatos')
                                                        // setDetailCaptionName(senderDetailEntity.caption)
                                                        setPopupRecordFormOpen(true)
                                                        setPopupState(true)
                                                        setStateModeEdit(true)
                                                    })
                                                    .catch((error) => {
                                                        setPopupStates({
                                                            visible: true,
                                                            message: error.data.error
                                                        })
                                                    })
                                                }
                                                else{
                                                    setKeyFieldNameState('_cadastro_id')
                                                    setIDValueDetailEntity(null)
                                                    setDetailEntityName('_cadastro_contatos')
                                                    // setDetailCaptionName(senderDetailEntity.caption)
                                                    setPopupRecordFormOpen(true)
                                                    setPopupState(true)
                                                    setStateModeEdit(true)
                                                }
                                            })
                                            .catch((error) => {
                                                setPopupStates({
                                                    visible: true,
                                                    message: error.data.error
                                                })
                                            })
                                            }
                                        }
                                    />
                                </div>

                                <div className="buttons-tabpanel">
                                    <Button
                                        icon="refresh"
                                        hint="Atualizar dados do servidor"
                                        type="normal"
                                        onClick={refreshDetailEntity}
                                    />
                                </div>
                            </div>

                            <DataGrid
                                ref={refDataGridConsultaBoletoForm}
                                dataSource={contatosDataSourceState}
                                //dataSource={[{teste: 1}, {teste: 2}]}
                                columnAutoWidth={true}
                                showBorders={true}
                                onSelectionChanged={(sender) => {
                                    setStates({...states,
                                        selectedItems: sender.component.getSelectedRowsData()})

                                }}
                            >
                                <Selection 
                                    mode="multiple"
                                    keyExpr='ID'
                                />
                                <Scrolling 
                                    useNative={true}
                                    rowRenderingMode='infinity'
                                    showScrollbar='always'
                                >
                                </Scrolling>

                                <Paging defaultPageSize={6} />

                                <Sorting
                                    mode="single"
                                />
                                
                                <Pager
                                    visible={true}
                                    // allowedPageSizes={10}
                                    // showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                
                                {/* <HeaderFilter
                                        allowSearch={true}
                                        visible={true}
                                    />
                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />
                                <FilterRow visible={true} /> */}

                                <Column
                                    dataField="_cryptfone"
                                    caption="Telefone"
                                ></Column>

                                <Column
                                    dataField="_tipocontatoid"
                                    caption="Tipo"
                                >
                                    <Lookup
                                        dataSource={dataLookupState?._tipo_contato}
                                        valueExpr="id"
                                        displayExpr="_descricao"
                                    />               
                                </Column>

                                <Column
                                    dataField="_pontuacaocontatoid"
                                    caption="Pontuação"
                                >
                                    <Lookup
                                        dataSource={dataLookupState?._pontuacao_contato}
                                        valueExpr="id"
                                        displayExpr="_descricao"
                                    />    
                                </Column>

                            
                            </DataGrid>

                            <div className="div-boleto-form-vencimento">
                                <Button
                                    width={130}
                                    height={40}
                                    text="Enviar SMS"
                                    disabled={desabilitarBotoesState ? true : false}
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        if(states.selectedItems.length > 0) {
                                            let arrayTelefones = []

                                            for(let c = 0; c <= states.selectedItems.length - 1; c++){
                                                arrayTelefones.push(states.selectedItems[c]._cryptfone)
                                            }
                                            //enviar boleto por sms para selecionados
                                            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                relationId: relationId,
                                                procedure: "_enviarBoletoSms",
                                                json: {
                                                    telefones: arrayTelefones//telefones dos itens selecionados
                                                },
                                                idValue: [`${props.boletoId}`]//id do boleto
                                            })
                                            .then((sender) => {
                                                const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                                toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                            })
                                            .catch((error) => {
                                                setPopupStates({
                                                    visible: true,
                                                    message: error.data.error
                                                })
                                            })
                                        }
                                        else{
                                            setPopupStates({
                                                visible: true,
                                                message: 'Telefone não selecionado!'
                                            })
                                        }
                                    }}
                                />

                                <Button
                                    width={170}
                                    height={40}
                                    style={{marginRight: '5px'}}
                                    disabled={desabilitarBotoesState ? true : false}
                                    text="Enviar WhatsApp"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        if(states.selectedItems.length > 0) {
                                            let arrayTelefones = []

                                            for(let c = 0; c <= states.selectedItems.length - 1; c++){
                                                arrayTelefones.push(states.selectedItems[c]._cryptfone)
                                            }
                                            //enviar boleto por sms para selecionados
                                            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                relationId: relationId,
                                                procedure: "_enviarBoletoWhatsApp",
                                                json: {
                                                    telefones: arrayTelefones//telefones dos itens selecionados
                                                },
                                                idValue: [`${props.boletoId}`]//id do boleto
                                            })
                                            .then((sender) => {
                                                const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                                toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                            })
                                            .catch((error) => {
                                                setPopupStates({
                                                    visible: true,
                                                    message: error.data.error
                                                })
                                            })
                                        }
                                        else{
                                            setPopupStates({
                                                visible: true,
                                                message: 'Telefone não selecionado!'
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                                <button
                                    href="#"
                                    onClick={convertBinaryForPDF}
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                        background: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Button
                                    width={180}
                                    height={40}
                                    // href="#"
                                    disabled={desabilitarBotoesState ? true : false}
                                    text="Visualizar Boleto"
                                    type="success"
                                    stylingMode="contained"
                                    // onClick={() => {
                                    //         //visualizar boleto
                                    //         console.log(props.boletoId)
                                    //         convertBinaryForPDF(props.boletoId)
                                    //     }
                                    // }
                                />
                                </button>
                            </div>

                        {/* {props?.arquivopdf ?
                        (
                            <>
                                <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "0.5em", marginBottom: "1em"}}>
                                    <div>
                                        <button
                                            href="#"
                                            onClick={convertBinaryForPDF}
                                            style={{
                                                position: "relative",
                                                zIndex: 2,
                                                background: "transparent",
                                                border: "none"
                                            }}
                                        >
                                            <Button
                                            width={140}
                                            height={40}
                                            // href="#"
                                            text="Visualizar Boleto"
                                            type="success"
                                            stylingMode="contained"
                                            // onClick={() => {
                                            //         //visualizar boleto
                                            //         console.log(props.boletoId)
                                            //         convertBinaryForPDF(props.boletoId)
                                            //     }
                                            // }
                                        />
                                        </button>
                                    </div>
                                    
                                    
                                    
                                    <div>
                                        <Button
                                            width={130}
                                            height={40}
                                            text="Anterior"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => {
                                                
                                                props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto - 1)
                                            }}
                                        />
                                    </div>
                                </div>

                            </>
                        )
                        :
                        (
                            <div>
                                <button
                                    href="#"
                                    onClick={convertBinaryForPDF}
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                        background: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Button
                                    width={140}
                                    height={40}
                                    // href="#"
                                    text="Visualizar Boleto"
                                    type="success"
                                    stylingMode="contained"
                                    // onClick={() => {
                                    //         //visualizar boleto
                                    //         console.log(props.boletoId)
                                    //         convertBinaryForPDF(props.boletoId)
                                    //     }
                                    // }
                                />
                                </button>
                            </div>
                        )
                    } */}

                        {props.boletoId && entityStructureRecordFormBoleto && props.consultaBoletoFormPopupState &&
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <RecordForm
                                    id={Math.random()}
                                    idValue={props.boletoId}
                                    entityName={"_titulo_boleto"}
                                    dataLookup={dataLookupRecordFormBoleto}
                                    entityStructure={entityStructureRecordFormBoleto}
                                    embeddedComponents={true}
                                />
                            </ScrollView>
                        }
                    </>
                    : 
                    <Loading/>
                }
                {/* </ScrollView> */}
            
        </div>        
    )
}
export default ConsultaBoletoForm;