import TabPanel from 'devextreme-react/tab-panel';
import { Item } from 'devextreme-react/toolbar';
import GroupPermissionForm from '../GroupPermissionForm';
import "./style.css"

const requestEndpoints = [
    { caption: "Entidades", endpoint: "getSecurityGroupPermissionEntidades"}, 
    { caption: "Views", endpoint: "getSecurityGroupPermissionViews"}, 
    { caption: "Menu items", endpoint: "getSecurityGroupPermissionMenuItems"}, 
    { caption: "Permissões especiais", endpoint: "getSecurityGroupEspecialPermission"}
];

const TabpanelEmbebedItems = (props) => {
    return (
        <div className="detail-tabpanel">

            <TabPanel
                // ref={tabPanelRef}
                showNavButtons={true}
                //height={500}
                disabled={props.disabled}
                // onSelectionChanged={(sender) => {
                //     setEntityNameDetail(sender.addedItems[0].entityName)}}
                >
                    {requestEndpoints.map((item, index) => (
                        <Item
                            key={index} 
                            title={item.caption}
                            //icon={implements}
                        >
                            <div className="detail-record">
                                <GroupPermissionForm
                                    securitygroupId={props.securitygroupId}
                                    requestEndpoint={item.endpoint}
                                />
                            </div>
                        </Item>
                    ))}
            </TabPanel>
        </div>
    )
}

export default TabpanelEmbebedItems;