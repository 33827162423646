import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import GetFormFields from "./GetFormFields";
import { validatePermission } from '../validatePermission';

const GetGroupsComponents = (props) => {

    /**
     * Percorre de forma recursiva todas as propriedades recordFormComponents do 
     * do objeto raíz entityStructure e quando chega em um nó onde
     * o objeto não possua mais a propriedade recordFormComponents, verifica
     * se o valor do conditionalVisibleFieldValue do field corresponde ao valor
     * da propriedade equivalente ao conditionalVisibleFieldName no dataEntity, 
     * caso sim, muda a propriedade formVisible para 1, caso não, muda para 0.
     * @param {object} component - recordFormComponentsItem (cada objeto do recordFormComponents)
     * @param {object} tree - recordFormComponents do entityStructure
    */
    function updateRecordFormProperties(component, tree) {
        console.log(component)
        console.log(tree)
        const fieldName = component.conditionalVisibleFieldName;
        const fieldValue = component.conditionalVisibleFieldValue;
        let fieldValuesArr = []; 

        if (fieldValue) fieldValuesArr = fieldValue.split(",");

        for (let i of tree) {
            if (!i.hasOwnProperty("recordFormComponents")) {       
                if (i.caption === component.caption) {
                    if (fieldValuesArr.includes(props.dataEntity[fieldName])) {
                        i.formVisible = 1;
                    } else {
                        i.formVisible = 0;
                    }
                }
            } else {
                updateRecordFormProperties(component, i.recordFormComponents);
            }
        }
    }   

    /**
     * Compara o valor do conditionalVisibleFieldValue com o valor da propriedade
     * equivalente ao conditionalVisibleFieldName no dataEntity, caso correspondam, retorna
     * true (exibe o grupo), caso não, retorna false (oculta o grupo).
     * @param {object} component - recordFormComponentsItem (cada objeto do recordFormComponents)
    */
    function groupIsVisible(component) {
        // alert(component.caption)
        const fieldName = component?.conditionalVisibleFieldName;
        const fieldValue = component?.conditionalVisibleFieldValue;
        let fieldValuesArr = [];

        if (fieldValue) fieldValuesArr = fieldValue.split(",");

        console.log(fieldName)

        if (fieldName) {
            if (fieldValuesArr.includes(props.dataEntity[fieldName])) {
                // atualiza as propriedades formVisible do entityStructure
                updateRecordFormProperties(component, props.entityStructure[0].recordFormProperties[0].recordFormComponents);                               
                return true;
            } else {
                // atualiza as propriedades formVisible do entityStructure
                updateRecordFormProperties(component, props.entityStructure[0].recordFormProperties[0].recordFormComponents);
                return false;
            }
        } 
        else if(component?.fieldsDefs?.length > 0){
            let arrayVisiveis = []
            for(let c = 0; c <= component?.fieldsDefs.length - 1; c++){
                const fieldPermissions = validatePermission(component?.fieldsDefs[c].objectid)
                if(fieldPermissions?._show === 1 && component?.fieldsDefs[c].formVisible === 1){
                    arrayVisiveis.push(component?.fieldsDefs[c])
                } 
            }

            if(arrayVisiveis.length === 0){
                return false;    
            }
            else{
                return !!component.formVisible;
            }
        }
        else if(component?.recordFormComponents){
            return groupIsVisible(component?.recordFormComponents)
        }
    }

    if(props.formComponents){
        return(
            <form onSubimit={props.recordInsertUpdateJson}>
            
                <Form
                    ref={props.resetFormData}
                    colCount={props.colCount}
                    formData={props.dataEntity}
                    onFieldDataChanged={props.modeEditOn}
                    validationGroup={`groupName${props.id}`}
                >
                    {props.formComponents.map((recordFormComponentsItem, indice) => 
                    {
                        if(recordFormComponentsItem.componentType === "groupItem"){
                            return(
                                <GroupItem
                                    // visible={recordFormComponentsItem.formVisible} 
                                    visible={recordFormComponentsItem.formVisible ? groupIsVisible(recordFormComponentsItem) : false}                                   
                                    caption={recordFormComponentsItem.caption}
                                    //colCount={recordFormComponentsItem.colCount}
                                    colSpan={recordFormComponentsItem.colSpan}
                                    cssClass={'group-item'}
                                    // cssClass={'group-item'}
                                    itemType={'group'}
                                    showClearButton={true}
                                    icon={'folder'}
                                    key={indice}
                                >
                                    {recordFormComponentsItem.recordFormComponents && 
                                        <GetGroupsComponents
                                            entityStructure={props.entityStructure}
                                            resetFormData={props.resetFormData}
                                            formComponents={recordFormComponentsItem.recordFormComponents}
                                            dataLookup={props.dataLookup}
                                            dataEntity={props.dataEntity}
                                            modeEditOn={props.modeEditOn}
                                            recordInsertUpdateJson={props.recordInsertUpdateJson}
                                            colCount={recordFormComponentsItem.colCount}
                                            id={props.id}
                                            modeEditForm={props.modeEditForm}
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={props.setModeEditForm}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                            setQueryFormEntityName={props.setQueryFormEntityName}
                                            setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                            queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                            queryFormPopupState={props.queryFormPopupState}
                                            setQueryFormPopupState={props.setQueryFormPopupState}
                                            dataEntityDetailEntity={props.dataEntityDetailEntity}
                                            setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                            senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                            detailEntity={props.detailEntity}
                                            popupRecordFormOpen={props.popupRecordFormOpen}
                                            masterDetailEntity={props.masterDetailEntity}
                                            setCondicionalVisibleState={props.setCondicionalVisibleState}
                                            setStates={props.setStates}
                                            idValue={props.idValue}
                                            setDataEntityEditedFields={props.setDataEntityEditedFields}
                                            dataEntityEditedFields={props.dataEntityEditedFields}
                                        />
                                    }
                                    {recordFormComponentsItem.fieldsDefs !== null && 
                                    <>
                                            <GetFormFields
                                                entityStructure={props.entityStructure}
                                                resetFormData={props.resetFormData}
                                                id={props.id}
                                                fieldsDefs={recordFormComponentsItem.fieldsDefs}
                                                objectPermissionsState={props.objectPermissionsState}
                                                dataLookup={props.dataLookup}
                                                dataEntity={props.dataEntity} 
                                                modeEditOn={props.modeEditOn}
                                                recordInsertUpdateJson={props.recordInsertUpdateJson}
                                                colCount={recordFormComponentsItem.colCount}
                                                modeEditForm={props.modeEditForm}
                                                //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                                setModeEditForm={props.setModeEditForm}
                                                setFormDataJson={props.setFormDataJson}
                                                setDataEntity={props.setDataEntity}
                                                setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                setQueryFormEntityName={props.setQueryFormEntityName}
                                                setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                                queryFormPopupState={props.queryFormPopupState}
                                                setQueryFormPopupState={props.setQueryFormPopupState}
                                                dataEntityDetailEntity={props.dataEntityDetailEntity}
                                                setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                                detailEntity={props.detailEntity}
                                                popupRecordFormOpen={props.popupRecordFormOpen}
                                                masterDetailEntity={props.masterDetailEntity}
                                                setCondicionalVisibleState={props.setCondicionalVisibleState}
                                                setStates={props.setStates}
                                                idValue={props.idValue}
                                                setDataEntityEditedFields={props.setDataEntityEditedFields}
                                                dataEntityEditedFields={props.dataEntityEditedFields}
                                            />
                                    </>
                                    }
                                </GroupItem>
                            )
                        }
                        //PAINEL PRINCIPAL
                        else if(recordFormComponentsItem.componentType === "tab"){   
                            if(recordFormComponentsItem.recordFormComponents){
                                
                                return(
                                    <TabbedItem 
                                        caption={recordFormComponentsItem.caption} 
                                        colCount={recordFormComponentsItem.colCount}
                                        colSpan={recordFormComponentsItem.colSpan}
                                        // cssClass={'group-item'}
                                        // visible={recordFormComponentsItem.formVisible}
                                        visible={recordFormComponentsItem.formVisible ? groupIsVisible(recordFormComponentsItem) : false}
                                        key={indice}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                        >
                                        <TabPanelOptions deferRendering={true} />
                                        {recordFormComponentsItem.recordFormComponents !== null && 
                                            <div>
                                                <GetGroupsComponents
                                                    entityStructure={props.entityStructure}
                                                    resetFormData={props.resetFormData}
                                                    id={props.id}
                                                    formComponents={recordFormComponentsItem.recordFormComponents}
                                                    dataLookup={props.dataLookup}
                                                    dataEntity={props.dataEntity}
                                                    modeEditOn={props.modeEditOn}
                                                    recordInsertUpdateJson={props.recordInsertUpdateJson}
                                                    // colCount={props.colCount}
                                                    colCount={recordFormComponentsItem.colCount}
                                                    modeEditForm={props.modeEditForm}
                                                    //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                    setQueryFormEntityName={props.setQueryFormEntityName}
                                                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                                    queryFormPopupState={props.queryFormPopupState}
                                                    setQueryFormPopupState={props.setQueryFormPopupState}
                                                    dataEntityDetailEntity={props.dataEntityDetailEntity}
                                                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                    senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                                    detailEntity={props.detailEntity}
                                                    popupRecordFormOpen={props.popupRecordFormOpen}
                                                    masterDetailEntity={props.masterDetailEntity}
                                                    setCondicionalVisibleState={props.setCondicionalVisibleState}
                                                    setStates={props.setStates}
                                                    idValue={props.idValue}
                                                    setDataEntityEditedFields={props.setDataEntityEditedFields}
                                                    dataEntityEditedFields={props.dataEntityEditedFields}
                                                />
                                            </div>
                                        }
                                    </TabbedItem>
                                )
                            }

                            else {
                                return(
                                    <GroupItem
                                        visible={recordFormComponentsItem.formVisible ? groupIsVisible(recordFormComponentsItem) : false}
                                        caption="TabPanel criado sem tabs"
                                        colCount={props.colCount}
                                        colSpan={props.colCount}
                                        key={indice}
                                        // cssClass={'group-item'}
                                        // cssClass={'group-item'}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                    />
                                )
                            } 
                        }

                        
                        //FOLHAS DO PAINEL PRINCIPAL
                        else if(recordFormComponentsItem.componentType === "tabbedItem" && 
                        recordFormComponentsItem.parentId)
                        {
                            if(recordFormComponentsItem.recordFormComponents || 
                                recordFormComponentsItem.fieldsDefs)
                            {
                                return(
                                    <GroupItem                                        
                                        visible={recordFormComponentsItem.formVisible ? groupIsVisible(recordFormComponentsItem) : false}
                                        caption={recordFormComponentsItem.caption}
                                        colCount={recordFormComponentsItem.colCount}
                                        colSpan={recordFormComponentsItem.colSpan}
                                        key={indice}
                                        // cssClass={'group-item'}
                                        // cssClass={'group-item'}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                    >
                                        {/* {recordFormComponentsItem.recordFormComponents !== null && 
                                            <GetGroupsComponents
                                                formComponents={recordFormComponentsItem.recordFormComponents}                        
                                            />
                                        } */}
    
                                        {recordFormComponentsItem.fieldsDefs !== null && 
                                        <>
                                                <GetFormFields
                                                    entityStructure={props.entityStructure}
                                                    resetFormData={props.resetFormData}
                                                    id={props.id}
                                                    fieldsDefs={recordFormComponentsItem.fieldsDefs}
                                                    objectPermissionsState={props.objectPermissionsState}
                                                    dataLookup={props.dataLookup}
                                                    dataEntity={props.dataEntity}
                                                    modeEditOn={props.modeEditOn}
                                                    recordInsertUpdateJson={props.recordInsertUpdateJson}
                                                    colCount={recordFormComponentsItem.colCount}
                                                    modeEditForm={props.modeEditForm}
                                                    //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                                    setModeEditForm={props.setModeEditForm}
                                                    setFormDataJson={props.setFormDataJson}
                                                    setDataEntity={props.setDataEntity}
                                                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                                    setQueryFormEntityName={props.setQueryFormEntityName}
                                                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                                    queryFormPopupState={props.queryFormPopupState}
                                                    setQueryFormPopupState={props.setQueryFormPopupState}
                                                    dataEntityDetailEntity={props.dataEntityDetailEntity}
                                                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                                    senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                                    detailEntity={props.detailEntity}
                                                    popupRecordFormOpen={props.popupRecordFormOpen}
                                                    masterDetailEntity={props.masterDetailEntity}
                                                    setCondicionalVisibleState={props.setCondicionalVisibleState}
                                                    setStates={props.setStates}
                                                    idValue={props.idValue}
                                                    setDataEntityEditedFields={props.setDataEntityEditedFields}
                                                    dataEntityEditedFields={props.dataEntityEditedFields}
                                                />
                                        </>
                                        }
                                    </GroupItem>
                                )
                            }
                            else {
                                return(
                                    <GroupItem
                                        // visible={recordFormComponentsItem.formVisible} 
                                        visible={recordFormComponentsItem.formVisible ? groupIsVisible(recordFormComponentsItem) : false}
                                        caption="TabSheet criada sem itens"
                                        colCount={props.colCount}
                                        colSpan={props.colCount}
                                        key={indice}
                                        // cssClass={'group-item'}
                                        // cssClass={'group-item'}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                    />
                                )
                            }
                            
                        }
                        
                        else if(recordFormComponentsItem.fieldsDefs){
                            return(
                                <div
                                    key={indice}
                                >
                                    lugar dos campos
                                </div>
                            )
                        }  
                    }
            )}
                </Form>
            </form>
        )
    }
    else {
        return(
            <Form 
                colCount={props.colCount}
                formData={props.dataEntity}
                onFieldDataChanged={props.modeEditOn}
                validationGroup={`groupName${props.id}`}
            >
                <GroupItem
                    // visible={props.formVisible} 
                    caption="TabSheet criada sem itens"
                    colCount={props.colCount}
                    colSpan={props.colCount}
                    // cssClass={'group-item'}
                    // cssClass={'group-item'}
                    itemType={'group'}
                    showClearButton={true}
                    icon={'folder'}
                />
           </Form> 
        )
    }
}

export default GetGroupsComponents;