import { httpRequest } from "../../functions"
import { useRequestsApi } from "../../Context/RequestsApi";
import {
    applicationID,
    DiscadorAtivo,
    relationId,
    equipeId,
    isPausarDiscadorPesquisa,
    CobrarAgendamentoHorarioProgramado,
    permissions
} from '../../api/ApplicationID'
import TabPanel from 'devextreme-react/tab-panel';
// import Toolbar, { Item } from 'devextreme-react/toolbar';
import ExportingExcel from '../../CardComponents/ExportingExcel'
import ExportingPDF from '../../CardComponents/ExportingPDF';
import Form, {
    SimpleItem,
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem,
    TabPanelOptions,
    Tab,
    Label,
    ButtonItem
} from 'devextreme-react/form';
import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';
import DataGrid, { Column as DatagridColumn, HeaderFilter, Sorting, Paging, Pager, Selection, Scrolling, Lookup, TotalItem, Summary, ColumnChooser, Editing } from "devextreme-react/data-grid";
import "./style.css"
import $ from "jquery"
import RecordFormPopup from "../RecordForm/RecordFormPopup";
import { useCallback, useEffect, useState, memo, useRef } from "react";
import UpfHtmlEditor from "../UpfComponents/UpfHtmlEditor"
import { Button } from 'devextreme-react/button';
import OcorrenciaCustomForm from "../OcorrenciaCustomForm"
import { TreeList, Column, SearchPanel } from 'devextreme-react/tree-list';
import CheckBox from 'devextreme-react/check-box';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import { useDiscadorEmAtendimentoGlobalState } from "../../Context/DiscadorEmAtendimentoGlobalState";
import {
    Popup,
    ToolbarItem,
} from 'devextreme-react/popup';
import { useProximaFichaDados } from "../../Context/ProximaFichaDados";
import { useTabPanelItems } from "../../Context/TabPanelItems";
import { useAbrirPausaResultJsonGlobalState } from "../../Context/AbrirPausaResultJsonGlobalState";
import { useIndexTabPanelMain } from "../../Context/IndexTabPanelMain";
import { LoadPanel } from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage";
import { useUpfFloatPopupMessageVisibleGlobalState } from "../../Context/UpfFloatPopupMessageVisibleGlobalState";
import { fecharPausa }from "../../functions/fecharPausa"
import { Toast } from 'devextreme-react/toast';
import { convertDate } from "../../functions/convertDate";
import { executeActionUpfFloatPopupMessage } from "../../functions/executeActionUpfFloatPopupMessage";
 
const HistoricoCustomForm = (props) => {

    console.log(props)

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }
 
    const _recordFormPopupRef = useRef(null)

    const _dataGridTelefoneRef = useRef(null)
 
    const selecionarTelefone = (selecionados) => {
        _dataGridTelefoneRef.current.instance.beginUpdate()
        // _dataGridTelefoneRef.current.instance.refresh()
        _dataGridTelefoneRef.current.instance.selectRowsByIndexes(selecionados)
        _dataGridTelefoneRef.current.instance.endUpdate()
    }

    const _dataGridParcelasRef = useRef(null)
 
    const selecionarParcela = (selecionados) => {
        _dataGridParcelasRef.current.instance.beginUpdate()
        // _dataGridParcelasRef.current.instance.refresh()
        _dataGridParcelasRef.current.instance.selectRowsByIndexes(selecionados)
        _dataGridParcelasRef.current.instance.endUpdate()
    }
   

    const { requestsApi } = useRequestsApi()
    const {discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState} = useDiscadorEmAtendimentoGlobalState()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()

    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const { abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState } = useAbrirPausaResultJsonGlobalState()

    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()

    const [contatosDataSourceState, setContatosDataSourceState] = useState([])

    const [dataLookup, setDataLookup] = useState()

    const [formDataJson, setFormDataJson] = useState({})

    const [itemSelected, setItemSelected] = useState();

    const [messageError, setMessageError] = useState();

    const [desabilitarBotaoGravarOcorrenciaState, setDesabilitarBotaoGravarOcorrenciaState] = useState(false)

    const [popup, setPopup] = useState({
        isVisible: false,
        message: 'Houve um erro',
    })

    const [pontuacaoContatoId, setPontuacaoContatoId] = useState("");
    const [etapaFunilCobrancaId, setEtapaFunilCobrancaId] = useState("")

    const [states, setStates] = useState({
        _texto: '',
        _pontuacaocontatoid: '',
        _cadastrocontatoid: '',
        _horarioprogramado: null,
        _agendamento: null,
        _motivoinadimplenciaid: '',
        _equipeid: '',
        _discadorid: '',
        _processamentomailingid: '',
        _processamentomailingitemid: '',
        _fone: '',
        _horarioinicio: '',
        _horariofim: '',
        _motivoretornoid: '',
        _tempotabulando: '',
        _tituloParcelaId: '',
        toastVisible: false,
        toastType: 'info',
        toastMessage: ""
    })

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    const fieldsPermissionUpdateArray = []

    const senderFieldNamesCheckBox = ["_isacordo"]

    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])

    const [arraySelecionadosState, setArraySelecionadosState] = useState([])

    const entityStructureFieldsDefs = [
        {
            fieldName: "_cryptnumerocontrato",
            fieldCaption: "Número Contrato",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            sortIndex: 1,
            sortOrder: "asc"
        },

        {
            fieldName: "_parcela",
            fieldCaption: "Parcela",
            editorType: null,   
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TipoParcela",
            fieldCaption: "Tipo parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_NumeroDocumento",
            fieldCaption: "Número Documento",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_vencimento",
            fieldCaption: "Vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            sortIndex: 2,
            sortOrder: "asc"
        },

        {
            fieldName: "_Valor",
            fieldCaption: "Principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoAtualizacao",
            fieldCaption: "Taxa Descrição Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaAtualizacao",
            fieldCaption: "Juros Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaAtualizacao",
            fieldCaption: "Multa Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaAtualizacao",
            fieldCaption: "Honorário Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoQuitacao",
            fieldCaption: "Taxa Descricao Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaQuitacao",
            fieldCaption: "Juros Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaQuitacao",
            fieldCaption: "Multa Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaQuitacao",
            fieldCaption: "Honorário Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_JurosValorQuitacao",
            fieldCaption: "Juros Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorQuitacao",
            fieldCaption: "Multa Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_JurosValorAtualizacao",
            fieldCaption: "Juros Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorAtualizacao",
            fieldCaption: "Multa Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobrePrincipal",
            fieldCaption: "Honorário sobre principal",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobreParcelasVincendas",
            fieldCaption: "Honorário Parcelas Vincendas",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorAtualizacao",
            fieldCaption: "Honorário Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorQuitacao",
            fieldCaption: "Honorário Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_DiasAtraso",
            fieldCaption: "Atraso",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorQuitacao",
            fieldCaption: "Valor a pagar (quitação)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorAtualizacao",
            fieldCaption: "Valor a pagar (atualização)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_NumeroAcordo",
            fieldCaption: "Número acordo",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_isacordo",
            fieldCaption: "Acordo",
            editorType: 'dxCheckBox',
            format: null,
            visible: 1,
            showInColumnChooser: 1
        }

    ]

    const getParcelasVencidasVincendasFunction = async () => {
        setArraySelecionadosState([])
        showLoadPanel()
        let TotalValorAtualizacao = 0;
        let TotalValorQuitacao = 0;
        let TotalValor = 0;
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')
        let arrayDataSource = []
        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        console.log(props)
        if(props.arrayDataSourceParcelasVencidas?.length > 0){
            
            //pega os campos do tipo data e converte para não pegar o fuso horario do browser
            convertDate(props.arrayDataSourceParcelasVencidas, entityStructureFieldsDefs)
            setDataSourceParcelasVencidasVincendasState(props.arrayDataSourceParcelasVencidas)

            //

            hideLoadPanel()
        }
        else if(props.tituloId){
            httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
                // entityName: "_titulo_parcela_vencidas_vincendas",
                // fieldName: "_tituloid",
                tituloId: props.tituloId,//proximaFichaDados.tituloId,
                applicationId: applicationID,
                tipoNegociacao: 2, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
                dataCalculo: stringDateResult
            })
            .then((sender) => {
                if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                    setMessageError("Valor de getParcelasVencidasVincendas inválido!")
                    setPopup({
                        ...popup
                            ,isVisible: true
                            ,message: "Atenção!" 
                    })
                }
                else{
                    const resultSenderJson = JSON.parse(sender)
    
                    resultSenderJson.sort((a, b) => { return b?._DiasAtraso - a?._DiasAtraso})
    
                    //pega os campos checkbox e altera de null pra 0
                    for (let c = 0; c <= resultSenderJson.length - 1; c++) {
                        for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                            if (resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                                resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] = 0
                            }
                        }
                    }
                    resultSenderJson.map(val => {
                        TotalValorAtualizacao += val._TotalValorAtualizacao
                        TotalValorQuitacao += val._TotalValorQuitacao
                        TotalValor += val._Valor
                    })
                    // setTotals({ _TotalValorAtualizacao: TotalValorAtualizacao, _TotalValorQuitacao: TotalValorQuitacao, _Valor: TotalValor })
                    
                    
    
                    // props.setDataSourceParcelasVencidasVincendasState(resultSenderJson)
    
    
    
                    //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                    convertDate(resultSenderJson, entityStructureFieldsDefs)
    
                    setDataSourceParcelasVencidasVincendasState(resultSenderJson)
    
                    //
    
                    hideLoadPanel()
                }
                
            })
            .catch((error) => {
                console.log(error)
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                        ,isVisible: true
                        ,message: "Atenção!" 
                })
            })
        }
        else{
            hideLoadPanel()
        }
    }

    const refreshTelefones = () => {
        showLoadPanel()
        httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
            "lookUpEntities": [
                {
                    "lookUpEntity": "_pontuacao_contato"
                },
                {
                    "lookUpEntity": "_motivoRetorno"
                },
                {
                    "lookUpEntity": "_tipo_contato"
                },
                {
                    "lookUpEntity": "_etapaFunilCobranca"
                }    
            ]
        })
        .then((sender) => {

            const resultGetLookupTableJson = JSON.parse(sender)

            setDataLookup(resultGetLookupTableJson)

            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": "_cadastro_contatos",
                "fieldName": "_cadastro_id",
                "expressao": props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id ? props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id : props.clienteId,
            })
            .then((sender) => {
                const resultGetDataEntityJson = JSON.parse(sender)
                setContatosDataSourceState(resultGetDataEntityJson?.masterEntity?.[0]?.records)

                hideLoadPanel()
            })
            .catch((error) => {
                console.log(error)
                hideLoadPanel()
            })

        })
        .catch((error) => {
            console.log(error)
            hideLoadPanel()
        })
 
    }

    const [modeEditDataGridEnderecosState, setModeEditDataGridEnderecosState] = useState({
        edit: false
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }


    useEffect(() => {
        if (requestsApi) {
            getParcelasVencidasVincendasFunction()
            if(!dataLookup){
                const entidadesLookups = [
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    },
                    {
                        "lookUpEntity": "_motivoRetorno"
                    },
                    {
                        "lookUpEntity": "_tipo_contato"
                    },
                    {
                        "lookUpEntity": "_etapaFunilCobranca"
                    }      
                ]

                if(sessionStorage.getItem("lookupsEntities")){
                    const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                    let temLookupNova = false
                    let lookupsNovas = []

                    for(let c = 0; c <= entidadesLookups.length - 1; c++){
                        if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                            console.log(entidadesLookups[c])
                            lookupsEntitiesJson.push(entidadesLookups[c])
                            temLookupNova = true
                            lookupsNovas.push(entidadesLookups[c])
                        }
                    }

                    if(temLookupNova){
                        sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                        httpRequest("POST", 
                        requestsApi.getLookupTable, requestsApi.basicAuth,
                                    {
                                        "lookUpEntities": lookupsNovas
                                    }
                        ).then((sender)=>{
                            const senderDataLookup = JSON.parse(sender)
                            const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))[0]

                            const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                            for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                            }

                            sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))

                            setDataLookup(dataLookupsJson)
                        })
                        .catch((error)=>{
                            console.log(error)
                            hideLoadPanel()
                            setMessageError(`${error}`)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }) 
                    }
                    else{
                        setDataLookup(JSON.parse(sessionStorage.getItem("dataLookups"))[0])
                    }
                }
                else{
                    sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))

                    httpRequest("POST", 
                        requestsApi.getLookupTable, requestsApi.basicAuth,
                                    {
                                        "lookUpEntities": entidadesLookups
                                    }
                        ).then((sender)=>{
                            const senderDataLookup = JSON.parse(sender)
                            sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                            sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
                            setDataLookup(senderDataLookup)
                        })
                        .catch((error)=>{
                            console.log(error)
                            hideLoadPanel()
                            setMessageError(`${error}`)
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }) 
                }
            }
            if(props.arrayDataSourceContatos){
                setContatosDataSourceState(props.arrayDataSourceContatos)
            }

            else if((props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id || props.clienteId) && dataLookup){
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": "_cadastro",
                    "fieldName": "id",
                    "expressao": props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id ? props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id : props.clienteId,
                })
                .then((sender) => {
    
                    const resultGetDataEntityJson = JSON.parse(sender)
                    setContatosDataSourceState(resultGetDataEntityJson?.detailEntity?.find((e) => e?.entityName === "_cadastro_contatos")?.records)
    
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }, [requestsApi, dataLookup])

    const refreshDetailEntity = () => {
        showLoadPanel()
        httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
            "lookUpEntities": [
                {
                    "lookUpEntity": "_pontuacao_contato"
                },
                {
                    "lookUpEntity": "_motivoRetorno"
                },
                {
                    "lookUpEntity": "_tipo_contato"
                },
                ,
                {
                    "lookUpEntity": "_etapaFunilCobranca"
                }     
            ]
        })
        .then((sender) => {

            const resultGetLookupTableJson = JSON.parse(sender)

            setDataLookup(resultGetLookupTableJson)
        })
        .catch((error) => {
            console.log(error)
            hideLoadPanel()
        })

        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro",
            "fieldName": "id",
            "expressao": props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id ? props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id : props.clienteId,
        })
        .then((sender) => {
            const resultGetDataEntityJson = JSON.parse(sender)
            setContatosDataSourceState(resultGetDataEntityJson?.detailEntity?.find((e) => e?.entityName === "_cadastro_contatos")?.records)

            hideLoadPanel()
        })
        .catch((error) => {
            console.log(error)
            hideLoadPanel()
        })
 
    }


    //Funções
    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        showLoadPanel()
        httpRequest("POST",
            requestsApi.updateRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "json": jsonRow,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
        showLoadPanel()
        httpRequest("POST",
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const recordInsertJsonDetail = (entityNameGrid, formData, refDataGrid) => {
        // delete formData.id;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            // modeEditOff()
            refDataGrid.current.instance.refresh()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            hideLoadPanel()
            if (error?.data?.error) {
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
            else {
                setMessageError(error?.statusText)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        })
    }

    const addRow = useCallback(() => {
        _dataGridTelefoneRef.current.instance.addRow();
        _dataGridTelefoneRef.current.instance.deselectAll();
      }, [_dataGridTelefoneRef]);
  
    const handleBatchSave = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.id;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props.clienteId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {
                const dataObjPermissionUpdate = {
                    "id": i.key
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.id;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };

                if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                    updateArr.push(dataObjPermissionUpdate);
                } 

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_contatos", insertArr, _dataGridTelefoneRef);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_contatos", updateArr, _dataGridTelefoneRef);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshTelefones()
        }

        setModeEditDataGridEnderecosState({
            edit: false
        })

    };
  
    const onRowInserting = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            // notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }
  
    const onRowRemoved = (e) => {
        recordDeleteJsonDetail("_cadastro_contatos", e.key)
        refreshTelefones()
        setModeEditDataGridEnderecosState({
            edit: false
        })
    }
  
    const onEditorPreparing = (e) => {
        if(e.editorName === "dxCheckBox"){
            e.editorOptions.value = e.value === null ? false : e.value
        }
    }






    const [telefoneSelecionadoState, setTelefoneSelecionadoState] = useState()
    const [telefonesSelecionadoArrayState, setTelefonesSelecionadoArrayState] = useState([])
    const [titulosParcelasIdSelecionadoArrayState, setTitulosParcelasIdSelecionadoArrayState] = useState([])

    const dataSourceFichaAtual = JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"))

    const limparDadadosProximaFicha = () => {
        if(proximaFichaDados?.tituloId && (dataSourceFichaAtual?.identificador === props.tituloId || dataSourceFichaAtual?.tituloId === props.tituloId)){
            const tabPanelItemsTemp = tabPanelItems
            tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)

            console.log(tabPanelItemsTemp)
            setTabPanelItems(tabPanelItemsTemp)
            setIndexTabPanelMain(0)
            
            setProximaFichaDados({...proximaFichaDados,
                tituloId: null,
                templateTelecobrancaId: null,
                widgets: null,
                nomeFilaAtual: null, 
                nomeRegraAtual: null,
                credorId: "",
                clienteId: null,
                _qtdOutrosContratosClienteCredorAtual: 0,
                _qtdOutrosContratosCliente: 0
            })


        }
    } 

    useEffect(() => {
        const mensagemSocket = JSON.parse(sessionStorage.getItem("mensagemSocket")) ? JSON.parse(sessionStorage.getItem("mensagemSocket")) : null

        if(mensagemSocket?.discagem && !telefoneSelecionadoState){
            setTelefoneSelecionadoState(mensagemSocket?.discagem)
        }

    }, [])

    const incluirAcionamento = async () => {
        setDesabilitarBotaoGravarOcorrenciaState(true) 

        const mensagemSocket = JSON.parse(sessionStorage.getItem("mensagemSocket")) ? JSON.parse(sessionStorage.getItem("mensagemSocket")) : null


        if (itemSelected?.length > 0) {
            // let stringDate = new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).replace(/\s/g, '')
            // let newStringDate = new Date().toISOString().slice(0, 19)
            // const horarioFim = newStringDate.slice(0, 11).concat(stringDate.slice(11, 19))

            let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            const horarioFim = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

            const horarioInicio = sessionStorage.getItem("horarioInicio")

            if(itemSelected[0]?._promessa === 1 && states._agendamento === null && states._horarioprogramado === null){
                setMessageError("Data de agendamento obrigatória para esta ocorrência.")
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: 'Atenção!'
                })
                setDesabilitarBotaoGravarOcorrenciaState(false)
            }
            else if(props.arrayDataSourceParcelasVencidas?.length > 0 && props.arrayDataSourceNumeroContratos?.length > 0 ){
                const titulosParcelasIdSelecionadoArray = _dataGridParcelasRef.current.instance.getSelectedRowsData()
                console.log(props.arrayDataSourceNumeroContratos)
                for(let c = 0; c <= props.arrayDataSourceNumeroContratos.length - 1; c++){
                    const arrayParcelasAtual = titulosParcelasIdSelecionadoArray.filter((e) => e._cryptnumerocontrato === props.arrayDataSourceNumeroContratos[c]._cryptnumerocontrato)
                    const arrayParcelasAtualIds = []
                    for(let i = 0; i <= arrayParcelasAtual.length - 1; i++){
                        arrayParcelasAtualIds.push(arrayParcelasAtual[i].ID)
                    }
                    await httpRequest('POST', requestsApi.incluirAcionamento, requestsApi.basicAuth, {
                        applicationId: applicationID,
                        relationId: relationId,
                        tituloId: props.arrayDataSourceNumeroContratos[c]._tituloid,
                        // ocorrenciaId: states._ocorrenciaid,
                        ocorrenciaId: itemSelected[0]?.ID,
                        texto: states._texto,
                        pontuacaoContatoId: pontuacaoContatoId,
                        cadastroContatoId: states._cadastrocontatoid,
                        horarioProgramado: states._horarioprogramado ? states._horarioprogramado : "",
                        agendamento: states._agendamento ? states._agendamento : '',
                        motivoInadimplenciaId: states._motivoinadimplenciaid,
                        equipeId: equipeId,
                        discadorId: states._discadorid,
                        processamentoMailingId: states._processamentomailingid,
                        processamentoMailingItemId: props.paramsProcessamentoMensagemSocket?.mensagem?.idMaillingItem ? props.paramsProcessamentoMensagemSocket?.mensagem?.idMaillingItem : "",
                        horarioInicio: horarioInicio ? horarioInicio : "",
                        horarioFim: horarioFim,
                        motivoRetornoId: states._motivoretornoid,
                        tempoTabulando: states._tempotabulando,
                        etapaFunilCobrancaId: etapaFunilCobrancaId,
                        callId: mensagemSocket?.callId,
                        discador: mensagemSocket?.discador,
                        protocolo: mensagemSocket?.protocolo,
                        fone: telefoneSelecionadoState,
                        tituloParcelaId: arrayParcelasAtualIds
                    })
        
                    .then((sender) => {
                        const resultIncluirAcionamentoJson = JSON.parse(sender)
        
                        // sessionStorage.removeItem("dataSourceFichaAtual")
                        // console.log(sessionStorage.getItem("dataSourceFichaAtual"))
        
                        if(props.setGravouOcorrencia){
                            props.setGravouOcorrencia(true)
                        }
        
                        toastConfiguration(true, "success", "Ocorrência gravada com sucesso!")
                        setDesabilitarBotaoGravarOcorrenciaState(false)
                    })
                    .catch((error) => {
                        setDesabilitarBotaoGravarOcorrenciaState(false)
                        console.log(error)
                        setMessageError(error?.data?.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: 'Atenção!'
                        })
                    })
                }
            }
            else{
                httpRequest('POST', requestsApi.incluirAcionamento, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    relationId: relationId,
                    tituloId: props.tituloId,
                    // ocorrenciaId: states._ocorrenciaid,
                    ocorrenciaId: itemSelected[0]?.ID,
                    texto: states._texto,
                    pontuacaoContatoId: pontuacaoContatoId,
                    cadastroContatoId: states._cadastrocontatoid,
                    horarioProgramado: states._horarioprogramado ? states._horarioprogramado : "",
                    agendamento: states._agendamento ? states._agendamento : '',
                    motivoInadimplenciaId: states._motivoinadimplenciaid,
                    equipeId: equipeId,
                    discadorId: states._discadorid,
                    processamentoMailingId: states._processamentomailingid,
                    processamentoMailingItemId: props.paramsProcessamentoMensagemSocket?.mensagem?.idMaillingItem ? props.paramsProcessamentoMensagemSocket?.mensagem?.idMaillingItem : "",
                    fone: dataSourceFichaAtual?.telefone ? dataSourceFichaAtual.telefone : telefoneSelecionadoState,
                    horarioInicio: horarioInicio ? horarioInicio : "",
                    horarioFim: horarioFim,
                    motivoRetornoId: states._motivoretornoid,
                    tempoTabulando: states._tempotabulando,
                    etapaFunilCobrancaId: etapaFunilCobrancaId,
                    callId: mensagemSocket?.callId,
                    discador: mensagemSocket?.discador,
                    protocolo: mensagemSocket?.protocolo,
                    tituloParcelaId: states._tituloParcelaId
                })
    
                .then((sender) => {
                    const resultIncluirAcionamentoJson = JSON.parse(sender)
    
                    // sessionStorage.removeItem("dataSourceFichaAtual")
                    // console.log(sessionStorage.getItem("dataSourceFichaAtual"))
    
                    if(props.setGravouOcorrencia){
                        props.setGravouOcorrencia(true)
                    }
    
                    props.toastConfiguration(true, "success", "Ocorrência gravada com sucesso!")
                    // sessionStorage.removeItem("mensagemSocket")
                    props.functionVisibleState(false)
                    setDesabilitarBotaoGravarOcorrenciaState(false)

                    if(CobrarAgendamentoHorarioProgramado === 1){
                        if(props.telecob){
                            //limpa as tabs apos gravar ocorrencia
                            const tabPanelItemsTemp = tabPanelItems
                            tabPanelItemsTemp.splice(1, tabPanelItemsTemp.length)
                
                            setTabPanelItems(tabPanelItemsTemp)
                            setIndexTabPanelMain(0)
                        }
                        //consultar se tem ficha com agendamento pra aquele dia ou horario programado para aquela data e hora
                        httpRequest('POST', requestsApi.getProximoAgendamentoHorarioProgramado, requestsApi.basicAuth, {  
                            applicationId: applicationID
                            ,equipeId: equipeId
                        })
                        .then((sender) => {
                            //se tiver limpar ficha atual e depois chamar ficha agendada ou com horario programado
                            const resultGetProximoAgendamentoHorarioProgramadoJson = JSON.parse(sender)

                            if(sessionStorage.getItem("abriuTelaResposta") === "true"){
                                // setUpfFloatPopupMessageVisibleGlobalState({
                                //     visible: true,
                                //     fixo: true,
                                //     mensagemId: null,
                                //     actionName: "fecharTelaResposta"
                                // })
                                sessionStorage.setItem("abriuTelaResposta", "false")
                            }
                            
                            if(resultGetProximoAgendamentoHorarioProgramadoJson?.tituloId){
                                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                                    "entityName": "_titulo"
                                    , "fieldName": 'id'
                                    , "expressao": resultGetProximoAgendamentoHorarioProgramadoJson.tituloId
                                    , "applicationId": applicationID
                                })
                                .then((sender) => {
                                    const resultGetDataEntityJson = JSON.parse(sender)
                                    const resultGetDataEntityJsonRecords = resultGetDataEntityJson.masterEntity[0].records[0]

                                    httpRequest("POST", requestsApi.prendeFicha, requestsApi.basicAuth, {
                                        applicationId: applicationID,
                                        tituloId: resultGetProximoAgendamentoHorarioProgramadoJson.tituloId,
                                    })
                                    .then((sender) => {
                                        console.log(sender)
                                        props.abrirFicha({
                                            tituloId: resultGetProximoAgendamentoHorarioProgramadoJson.tituloId,
                                            credorId: resultGetDataEntityJsonRecords?._credorid,
                                            _clienteId: resultGetDataEntityJsonRecords?._clienteid,
                                            nomeFilaAtual: null,
                                            nomeRegraAtual: null,
                                            _qtdoutroscontratoscliente: resultGetDataEntityJsonRecords?._qtdoutroscontratoscliente,
                                            _qtdoutroscontratosclientecredoratual: resultGetDataEntityJsonRecords?._qtdoutroscontratosclientecredoratual
                                        })
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })

                                })
                                .catch((error) => {
                                    console.log(error)
                                    setDesabilitarBotaoGravarOcorrenciaState(false)
                                    console.log(error)
                                    setMessageError(error?.data?.error)
                                    setPopup({
                                        ...popup
                                        , isVisible: true
                                        , message: 'Atenção!'
                                    })
                
                                })
                            }
                            else{
                                fecharPausa(
                                    discadorEmAtendimentoGlobalState
                                    ,props.fichaPesquisadaNaTelaGlobalState
                                    ,httpRequest
                                    ,requestsApi
                                    ,abrirPausaResultJsonGlobalState
                                    ,applicationID
                                    ,setDiscadorEmAtendimentoGlobalState
                                    ,props.setFichaPesquisadaNaTelaGlobalState
                                    ,tabPanelItems
                                    ,setTabPanelItems
                                    ,setIndexTabPanelMain
                                    ,props.pausaEscolhidaDadosState
                                    ,props.toastConfiguration
                                    ,setPopupErrorMessageState
                                    ,setPopupErrorVisibleState
                                    ,limparDadadosProximaFicha
                                    ,setAbrirPausaResultJsonGlobalState
                                    ,setDesabilitarBotaoGravarOcorrenciaState
                                    ,props.telecob
                                    ,props.tituloId
                                )
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setDesabilitarBotaoGravarOcorrenciaState(false)
                            console.log(error)
                            setMessageError(error?.data?.error)
                            setPopup({
                                ...popup
                                , isVisible: true
                                , message: 'Atenção!'
                            })
        
                        })
                    }
                    else{
                        fecharPausa(
                            discadorEmAtendimentoGlobalState
                            ,props.fichaPesquisadaNaTelaGlobalState
                            ,httpRequest
                            ,requestsApi
                            ,abrirPausaResultJsonGlobalState
                            ,applicationID
                            ,setDiscadorEmAtendimentoGlobalState
                            ,props.setFichaPesquisadaNaTelaGlobalState
                            ,tabPanelItems
                            ,setTabPanelItems
                            ,setIndexTabPanelMain
                            ,props.pausaEscolhidaDadosState
                            ,props.toastConfiguration
                            ,setPopupErrorMessageState
                            ,setPopupErrorVisibleState
                            ,limparDadadosProximaFicha
                            ,setAbrirPausaResultJsonGlobalState
                            ,setDesabilitarBotaoGravarOcorrenciaState
                            ,props.telecob
                            ,props.tituloId
                        )
                    }
                })
                .catch((error) => {
                    setDesabilitarBotaoGravarOcorrenciaState(false)
                    console.log(error)
                    setMessageError(error?.data?.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: 'Atenção!'
                    })
                })
            }

        }
        else {
            setDesabilitarBotaoGravarOcorrenciaState(false)
            setMessageError('Selecione uma ocorrência!')
            setPopup({
                ...popup
                , isVisible: true
                , message: 'Atenção!'
            })
        }

    }

    const hidePopup = () => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    }

    const closeButtonPopup = {
        text: 'Fechar'
        , icon: 'arrowright'
        , onClick: hidePopup
    };


    const buttonOptions = {
        text: 'Gravar',
        type: 'success',
        width: '150px',
        height: '40px',
        onClick: incluirAcionamento
        // useSubmitBehavior: true,
    }

    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState()
    const [popupState, setPopupState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState()
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [IDValueAttachDetailsEntity, setIDValueAttachDetailsEntity] = useState();
    const [detailAttachEntityName, setDetailAttachEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [detailAttachCaptionName, setDetailAttachCaptionName] = useState()
    const [entityDataEntityState, setEntityDataEntityState] = useState()

    const renderHtmlEditor = useCallback((e) => {
        console.log("render event:",e)
        return (
            <HtmlEditor
                onValueChanged={(item) => {
                    states._texto = item?.value                                                  
                }}                                                                                                
                height={240}
                defaultValue={states._texto}
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={true}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']}
                        defaultValue={'12pt'}
                    />
                    <Item
                        name="font"
                        acceptedValues={['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']}
                        defaultValue={'Arial'}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item
                        name="header"
                        acceptedValues={[false, 1, 2, 3, 4, 5]}
                    />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                </Toolbar>
            </HtmlEditor>
        )
    }, [states._texto])

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                idValue={IDValueDetailEntity}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState}
                masterEntityId={props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id ? props.dataEntity?.masterEntity?.[0]?.records?.[0]?.id : props.clienteId}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={props.dataEntity?.detailEntity?.length > 0 && props.dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
                dataEntity={entityDataEntityState}
                referenciaPopup={_recordFormPopupRef}
            />
        );
    })

     //---------LOADING------------//

    //Estados
    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    //---------LOADING------------//

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
        if(props.hideUpfPopupForm){
            props.hideUpfPopupForm()
        }
    }

    return (
        <div className="detail-tabpanel-historico-custom-form">
            <LoadPanel
                style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={3000}
                height={60}
            />
            {popupRecordFormOpen === true ? <RenderRecordFormPopup /> : <></>}
                <Popup
                    visible={popup.isVisible}
                    onHiding={hidePopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={false}
                    showTitle={true}
                    title={popup.message}
                    width={300}
                    height={280}
                    resizeEnabled={true}
                >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopup}
                    />
                    {messageError &&
                        <p>{messageError}</p>
                    }
                </Popup>

                <UpfPopupMessage
                    hidePopup={hidePopupError}
                    visible={popupErrorVisibleState}
                    message={popupErrorMessageState}
                />

                <TabPanel
                    className="tabpanel-ocorrencia"
                >
                    <Item
                        title={"Ocorrência"}
                        key={1}
                    >
                        {/* <ScrollView width="100%" height="100%"> */}
                            <div className="div-tree-list" style={{margin: "0", padding: "0.5em"}}>
                                <ScrollView width={"100%"} height={"100%"}>                                    
                                    <TreeList
                                        height="100%"
                                        className="tree-list-upf"
                                        id="ocorrencia"
                                        dataSource={props.ocorrencias}
                                        rootValue={-1}
                                        keyExpr="ID"
                                        showRowLines={true}
                                        showBorders={true}
                                        parentIdExpr="_parentId"
                                        columnAutoWidth={true}
                                        focusedRowEnabled={false}
                                        width={"100%"}
                                        searchPanel={{
                                            highlightSearchText: true,
                                            placeholder: "Procurar...",
                                            searchVisibleColumnsOnly: true,
                                            visible: true,
                                            width: "100%"
                                        }}
                                        onRowClick={(item) => {

                                            // if (item.event.target.nodeName === "SPAN") {
                                            //     if (item.component.isRowExpanded(item.key))
                                            //         item.component.collapseRow(item.key)
                                            //     else
                                            //         item.component.expandRow(item.key)
                                            // } else {
                                            //     if (item.component.isRowExpanded(item.key))
                                            //         item.component.collapseRow(item.key)
                                            //     else
                                            //         item.component.expandRow(item.key)
                                            // }
                                            if(item.event.target.nodeName === "DIV"
                                                && item.node.children.length === 0){
                                                    console.log(item)
                                                    console.log(item.data.ID)
                                                    // setUpfFloatPopupMessageVisibleGlobalState({
                                                    //     visible: true,
                                                    //     fixo: true,
                                                    //     mensagemId: item.data._templateMensagemId,
                                                    //     actionName: "ocorrenica"
                                                    // })

                                                    if(item.data?._TextoPadrao && states._texto.length === 0){
                                                        setStates({...states, _texto: item.data._TextoPadrao})
                                                    }
                                                    if (item.data?.hasOwnProperty("_PontuacaoContatoId")){
                                                        setPontuacaoContatoId(item.data?._PontuacaoContatoId);            
                                                    }
                                                    if(item.data?.hasOwnProperty("_EtapaFunilCobrancaId")){ 
                                                        setEtapaFunilCobrancaId(item.data?._EtapaFunilCobrancaId)
                                                    }

                                                    setItemSelected([item.data])

                                                    // executeActionUpfFloatPopupMessage(
                                                    // "ocorrencia"
                                                    // ,item.data.ID
                                                    // ,requestsApi
                                                    // ,applicationID
                                                    // ,relationId
                                                    // ,httpRequest
                                                    // )
                                                                                        
                                            }
                                        }}
                                    // onRowDblClick={(item) => {
                                    //     item.node.children.length == 0 && setItemSelected([item.data])
                                    // }}
                                    >
                                        <SearchPanel 
                                            visible={true} 
                                            className={"search-panel"} 
                                            width="100%"
                                        />
                                        <Column dataField="_Descricao" caption="Descrição" />
                                        {/* <Column dataField="_codigo" caption="Código" /> */}
                                    </TreeList>
                                </ScrollView>
                            </div>
                        {/* </ScrollView> */}
                    </Item>
                </TabPanel>
                <TabPanel
                    className="tabpanel-anotacoes-telefones"
                >
                    <Item
                        title={"Informações"}
                        key={1}
                    >
                        <div className="tabpanel-anotacoes-telefones">
                            <ScrollView width={"100%"} height={"100%"}>

                                <TabPanel
                                    className="tabpanel-anotacoes-telefones"
                                    style={{padding: "0.5em"}}
                                >
                                    <Item
                                        title={"Anotações"}
                                        key={1}
                                    >
                                        <Toolbar></Toolbar>
                                        <div className="detail-record">
                                            <Form
                                                colCount={4}
                                                formData={states}
                                                // onFieldDataChanged={(item) => {
                                                //     console.log(item)
                                                // }}
                                                // validationGroup={`groupName${props.id}`}
                                                // labelLocation="top"
                                                showColonAfterLabel={true}
                                                // minColWidth={200}
                                                alignItemLabelsInAllGroups={true}
                                                alignItemLabels={true}
                                            >
                                                <GroupItem
                                                    // colCount={props.groupItemColSpan}
                                                    // colSpan={props.groupItemColCount}
                                                    colCount={4}
                                                    colSpan={4}
                                                    // cssClass={'group-item'}
                                                    // colCountByScreen={true}
                                                    itemType={'group'}
                                                    showClearButton={true}
                                                    icon={'folder'}
                                                    cssClass={'group-item'}
                                                >


                                                    <SimpleItem

                                                        colSpan={4}
                                                        // caption={field.fieldCaption} 
                                                        dataField={"_texto"}
                                                        render={renderHtmlEditor}
                                                        editorOptions={{
                                                            valueChangeEvent: ["keyup", "paste"]
                                                        }}
                                                    >
                                                        <Label
                                                            text={"Observações"}
                                                            visible={false}
                                                        // location="left"
                                                        />
                                                    </SimpleItem>
                                                </GroupItem>

                                            </Form>
                                        </div>
                                    </Item>
                                    {!dataSourceFichaAtual?.telefone &&
                                        <Item
                                            title={"Telefones"}
                                            key={2}
                                        >
                                            <div className="div-grupos-gerar-acordo">                    
                                                <div style={{marginBottom: "0.5em"}}>
                                                    Telefones:
                                                </div>

                                                <div className="btn-tabPanel">
                                                    <div className="buttons-tabpanel">
                                                        <Button            
                                                            icon="add"
                                                            hint={`Incluir um novo registro`}
                                                            // disabled={itemPermissions ? (!itemPermissions?._insert) : (true)}
                                                            type="normal"
                                                            onClick={() => {
                                                                if(modeEditDataGridEnderecosState.edit){
                                                                    addRow()
                                                                }
                                                                else{
                                                                    showLoadPanel()

                                                                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                                                        entityName: '_cadastro_contatos',
                                                                        applicationId: applicationID,
                                                                    })
                                                                    .then((sender) => {
                                                                        setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                                                        if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                                            
                                                                            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                                                "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                                            })
                                                                            .then((sender) => {
                                                                                setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                                                setKeyFieldNameState('_cadastro_id')
                                                                                setIDValueDetailEntity(null)
                                                                                setDetailEntityName('_cadastro_contatos')
                                                                                // setDetailCaptionName(senderDetailEntity.caption)
                                                                                setPopupRecordFormOpen(true)
                                                                                setPopupState(true)
                                                                                setStateModeEdit(true)
                                                                                hideLoadPanel()
                                                                            })
                                                                            .catch((error) => {
                                                                                console.log(error)
                                                                                hideLoadPanel()
                                                                                // setPopupStates({
                                                                                //     visible: true,
                                                                                //     message: error?.data?.error
                                                                                // })
                                                                            })
                                                                        }
                                                                        else{
                                                                            setKeyFieldNameState('_cadastro_id')
                                                                            setIDValueDetailEntity(null)
                                                                            setDetailEntityName('_cadastro_contatos')
                                                                            // setDetailCaptionName(senderDetailEntity.caption)
                                                                            setPopupRecordFormOpen(true)
                                                                            setPopupState(true)
                                                                            setStateModeEdit(true)
                                                                            hideLoadPanel()
                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error)
                                                                        hideLoadPanel()
                                                                        // setPopupStates({
                                                                        //     visible: true,
                                                                        //     message: error?.data?.error
                                                                        // })
                                                                    })
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                    <div className="buttons-tabpanel">
                                                        <Button
                                                            icon="rowproperties"
                                                            hint="Editar registros em lote no grid"
                                                            type="normal"
                                                            onClick={() => {
                                                                setModeEditDataGridEnderecosState({
                                                                    edit: !modeEditDataGridEnderecosState.edit
                                                                })
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="buttons-tabpanel">
                                                        <Button
                                                            icon="refresh"
                                                            hint="Atualizar dados do servidor"
                                                            type="normal"
                                                            onClick={refreshDetailEntity}
                                                        />
                                                    </div>
                                                </div>

                                                {dataLookup &&

                                                <DataGrid
                                                    ref={_dataGridTelefoneRef}
                                                    dataSource={contatosDataSourceState}
                                                    errorRowEnabled={false}
                                                    //dataSource={[]}
                                                    //dataSource={[{teste: 1}, {teste: 2}]}
                                                    //columnAutoWidth={true} 
                                                    keyExpr="id"                               
                                                    showBorders={true}
                                                    allowColumnResizing={true}
                                                    columnResizingMode={"widget"}
                                                    columnAutoWidth={true}
                                                    columnHidingEnabled={true}
                                                    onRowInserting={onRowInserting}
                                                    onRowRemoved={onRowRemoved}
                                                    onSaving={handleBatchSave}                
                                                    onEditorPreparing={onEditorPreparing}
                                                    allowColumnReordering={true}
                                                    rowAlternationEnabled={true}
                                                    activeStateEnabled={false}
                                                    cacheEnabled={false}
                                                    // focusedRowEnabled={true}
                                                    // focusedRowKey={0}
                                                    columnMinWidth={50}
                                                    renderAsync={false}
                                                    dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                                                    onEditCanceled={() => {
                                                        setModeEditDataGridEnderecosState({
                                                            edit: false
                                                        })
                                                    }}



                                                    onSelectionChanged={(sender) => {
                                                        // if(sender.currentSelectedRowKeys?.length > 1){
                                                        //     selecionarTelefone([contatosDataSourceState.indexOf(sender.currentSelectedRowKeys[0])])
                                                        // }

                                                        if(sender.component.getSelectedRowsData()?.[0]?._cryptfone){
                                                            setTelefoneSelecionadoState(sender.component.getSelectedRowsData()?.[0]?._cryptfone)
                                                        }
                                                        else{
                                                            setTelefoneSelecionadoState("")
                                                        }

                                                    }}
                                                >
                                                    <Selection 
                                                        mode="multiple"
                                                        keyExpr='ID'
                                                    />
                                                    <Scrolling 
                                                        rowRenderingMode='infinity'
                                                        showScrollbar='always'
                                                        useNative={true}
                                                        >
                                                    </Scrolling>

                                                    <Paging defaultPageSize={6} />

                                                    <Sorting
                                                        mode="single"
                                                    />

                                                    <Editing
                                                        allowDeleting={modeEditDataGridEnderecosState.edit }
                                                        allowUpdating={modeEditDataGridEnderecosState.edit }
                                                        // allowAdding={modeEditDataGridEnderecosState.edit }
                                                        confirmDelete={true}
                                                        mode="batch"
                                                        useIcons={true}
                                                    //selectTextOnEditStart={selectTextOnEditStart}
                                                    //startEditAction={startEditAction}
                                                    />
                                                    
                                                    <Pager
                                                        visible={true}
                                                        // allowedPageSizes={10}
                                                        // showPageSizeSelector={true}
                                                        showInfo={true}
                                                        showNavigationButtons={true} 
                                                    />
                                                

                                                    <Column
                                                        dataField="_cryptfone"
                                                        caption="Telefone"
                                                    ></Column>

                                                    <Column
                                                        dataField="_tipocontatoid"
                                                        caption="Tipo"
                                                    >
                                                        <Lookup

                                                            dataSource={dataLookup?._tipo_contato}
                                                            valueExpr="id"
                                                            displayExpr="_descricao"
                                                        />               
                                                    </Column>

                                                    <Column
                                                        dataField="_pontuacaocontatoid"
                                                        caption="Pontuação"
                                                    >
                                                        <Lookup
                                                            dataSource={dataLookup?._pontuacao_contato}
                                                            valueExpr="id"
                                                            displayExpr="_descricao"
                                                        />    
                                                    </Column>

                                                
                                                </DataGrid>
                                                }
                                            </div>
                                        </Item>
                                    }

                                    <Item
                                        title={"Parcelas"}
                                        key={3}
                                    >
                                        <div className="div-grupos-gerar-acordo">                    
                                            <div style={{marginBottom: "0.5em"}}>
                                                Parcelas:
                                            </div>

                                            {dataLookup &&

                                            <DataGrid
                                                ref={_dataGridParcelasRef}
                                                dataSource={dataSourceParcelasVencidasVincendasState}
                                                className="datagrid-queryform"
                                                //dataSource={[]}
                                                //dataSource={[{teste: 1}, {teste: 2}]}
                                                //columnAutoWidth={true}                                
                                                showBorders={true}
                                                columnMinWidth={50}
                                                width={"100%"}
                                                // allowColumnResizing={true}
                                                // columnResizingMode={"widget"}
                                                columnAutoWidth={true}
                                                // allowColumnReordering={true}
                                                // rowAlternationEnabled={true}
                                                // columnHidingEnabled={true}
                                                onSelectionChanged={(sender) => {
                                                    // if(sender.currentSelectedRowKeys?.length > 0){
                                                    //     selecionarParcela([dataSourceParcelasVencidasVincendasState.indexOf(sender.currentSelectedRowKeys[0])])
                                                    // }
                                                    if(!props.arrayDataSourceParcelasVencidas?.length > 0 && !props.arrayDataSourceNumeroContratos?.length > 0 ){
                                                        if(sender.component.getSelectedRowsData()?.[0]?.id){
                                                            setStates({...states, 
                                                                _tituloParcelaId: sender.component.getSelectedRowsData()[0].id
                                                            })
                                                        }
                                                        else if(sender.component.getSelectedRowsData()?.[0]?.ID){
                                                            setStates({...states, 
                                                                _tituloParcelaId: sender.component.getSelectedRowsData()[0].ID
                                                            })
                                                        }
                                                        else{
                                                            setStates({...states, 
                                                                _tituloParcelaId: ""
                                                            })
                                                        }
                                                    }

                                                }}
                                            >
                                                <HeaderFilter
                                                    allowSearch={true}
                                                    visible={true}
                                                />
                                                <Selection 
                                                    mode="multiple"
                                                    keyExpr='ID'
                                                />
                                                <Scrolling 
                                                    columnRenderingMode="virtual"
                                                    rowRenderingMode='infinity'
                                                    showScrollbar='always'
                                                    useNative={true} 
                                                >
                                                </Scrolling>

                                                <Paging defaultPageSize={6} />

                                                <Sorting
                                                    mode="single"
                                                />
                                                
                                                <Pager
                                                    visible={true}
                                                    // allowedPageSizes={10}
                                                    // showPageSizeSelector={true}
                                                    showInfo={true}
                                                    showNavigationButtons={true} 
                                                />

                                                <ColumnChooser 
                                                    enabled={false} 
                                                    width={400} 
                                                    height={800} 
                                                    mode={"select"} 
                                                    allowSearch={true}
                                                />
                                                

                                                {entityStructureFieldsDefs.map((item, index) => {
                                                        if(item.editorType === 'dxDateBox'){
                                                            return(
                                                                <Column
                                                                    key={index}
                                                                    dataField={item.fieldName}
                                                                    caption={item.fieldCaption}
                                                                    dataType='date'
                                                                    visible={item.visible}                                            
                                                                />
                                                            )
                                                        }
                                                        else if(item.editorType === 'dxCheckBox'){
                                                            return(
                                                                <Column
                                                                    key={index}
                                                                    dataField={item.fieldName}
                                                                    caption={item.fieldCaption}
                                                                    dataType='boolean'
                                                                    visible={item.visible}
                                                                />
                                                            )
                                                        }
                                                        else if(item.format === 'money') {
                                                            return(
                                                                <Column
                                                                    key={index}
                                                                    dataField={item.fieldName}
                                                                    caption={item.fieldCaption}
                                                                    visible={item.visible}
                                                                    format="R$ #,##0.##;(R$ #,##0.##)"
                                                                />
                                                            )
                                                        }
                                                        else if (item.format === 'percent') {
                                                            return (
                                                                <Column
                                                                    key={index}
                                                                    dataField={item.fieldName}
                                                                    caption={item.fieldCaption}
                                                                    visible={item.visible}
                                                                    showInColumnChooser={item.showInColumnChooser}
                                                                    format="#0'%'"
                                                                />
                                                            )
                                                        }
                                                        else {
                                                            return(
                                                                <Column
                                                                    key={index}
                                                                    dataField={item.fieldName}
                                                                    caption={item.fieldCaption}
                                                                    visible={item.visible}
                                                                    showInColumnChooser={item.showInColumnChooser}
                                                                    defaultSortOrder={item.sortOrder}
                                                                    defaultSortIndex={item.sortIndex}
                                                                />
                                                            )
                                                        }
                                                        
                                                    })}
                                                    <Summary
                                                            calculateCustomSummary={calculateCustomSummary}
                                                        >

                                                            <TotalItem
                                                                column={'_Valor'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_TotalValorAtualizacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_TotalValorQuitacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_ValorPago'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_MultaValorQuitacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_MultaValorAtualizacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_JurosValorQuitacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_JurosValorAtualizacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_HonorarioValorQuitacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />
                                                            <TotalItem
                                                                column={'_HonorarioValorAtualizacao'}
                                                                summaryType="sum"
                                                                format={{
                                                                    style: 'currency',
                                                                    currency: 'BRL',
                                                                    precision: 2,
                                                                }}
                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                            />

                                                        </Summary>
                                            </DataGrid>
                                            }

                                        </div>
                                    </Item>
                                </TabPanel>

                                <Form
                                    colCount={4}
                                    formData={states}
                                    // onFieldDataChanged={(item) => {
                                    //     console.log(item)
                                    // }}
                                    // validationGroup={`groupName${props.id}`}
                                    // labelLocation="top"
                                    showColonAfterLabel={true}
                                    // minColWidth={200}
                                    alignItemLabelsInAllGroups={true}
                                    alignItemLabels={true}
                                >
                                    <GroupItem
                                        // colCount={props.groupItemColSpan}
                                        // colSpan={props.groupItemColCount}
                                        colCount={4}
                                        colSpan={4}
                                        // cssClass={'group-item'}
                                        // colCountByScreen={true}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                        cssClass={'group-item'}
                                    >

                                        <GroupItem
                                            colCount={2}
                                            colSpan={2}
                                            itemType={'group'}
                                            showClearButton={true}
                                            icon={'folder'}
                                        // cssClass={'group-item'}
                                        >
                                            <SimpleItem
                                                editorType='dxSelectBox'
                                                colSpan={1}
                                                caption={"Motivo retorno"}
                                                dataField={"_motivoretornoid"}
                                                editorOptions=
                                                {{
                                                    items: dataLookup && dataLookup._motivoRetorno,
                                                    valueExpr: "id",
                                                    displayExpr: "_descricao",
                                                    // hint: field.hint,
                                                    placeholder: "Selecione uma opção",
                                                    visible: true,
                                                    showClearButton: false,
                                                    deferRendering: true,
                                                    searchEnabled: true,
                                                    wrapItemText: false,
                                                    searchMode: "contains",
                                                    showDataBeforeSearch: true,
                                                    onValueChanged: (e) => {
                                                        if(dataLookup?._motivoRetorno){
                                                        const objectMotivoRetornoSelecionado = dataLookup._motivoRetorno.find((item) => item.id === e.value)
                                                        //    setUpfFloatPopupMessageVisibleGlobalState({
                                                        //        visible: true,
                                                        //        fixo: true,
                                                        //        mensagemId: objectMotivoRetornoSelecionado?._templatemensagemid,
                                                        //        actionName: "motivoRetorno"
                                                        //    })
                                                        }
                                                    },
                                                    valueChangeEvent: ["keyup", "paste"],
                                                    stylingMode: 'underlined',
                                                    showTitle: true
                                                }}
                                            >

                                                <Label 
                                                    text={"Motivo retorno"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            <SimpleItem
                                                editorType='dxSelectBox'
                                                colSpan={1}
                                                caption={"Pontuação contato"}
                                                dataField={"_pontuacaocontatoid"}
                                                editorOptions=
                                                {{
                                                    items: dataLookup && dataLookup._pontuacao_contato,
                                                    item: [],
                                                    valueExpr: "id",
                                                    displayExpr: "_descricao",
                                                    // hint: field.hint,
                                                    placeholder: "Selecione uma opção",
                                                    visible: true,
                                                    showClearButton: false,
                                                    deferRendering: true,
                                                    searchEnabled: true,
                                                    wrapItemText: false,
                                                    searchMode: "contains",
                                                    showDataBeforeSearch: true,
                                                    valueChangeEvent: ["keyup", "paste"],
                                                    showTitle: true,
                                                    onValueChanged: (e) => {
                                                        setPontuacaoContatoId(e.value);
                                                    },
                                                    stylingMode: 'underlined',
                                                    value: pontuacaoContatoId
                                                }}
                                            >

                                                <Label 
                                                    text={"Pontuação contato"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            <SimpleItem
                                                editorType='dxSelectBox'
                                                colSpan={1}
                                                caption={"Etapa funil"}
                                                dataField={"_pontuacaocontatoid"}
                                                editorOptions=
                                                {{
                                                    items: dataLookup && dataLookup._etapaFunilCobranca,
                                                    item: [],
                                                    stylingMode: 'underlined',
                                                    valueExpr: "id",
                                                    displayExpr: "_descricao",
                                                    // hint: field.hint,
                                                    placeholder: "Selecione uma opção",
                                                    visible: true,
                                                    showClearButton: false,
                                                    deferRendering: true,
                                                    searchEnabled: true,
                                                    wrapItemText: false,
                                                    searchMode: "contains",
                                                    showDataBeforeSearch: true,
                                                    valueChangeEvent: ["keyup", "paste"],
                                                    showTitle: true,
                                                    onValueChanged: (e) => {
                                                        setEtapaFunilCobrancaId(e.value);
                                                    },
                                                    value: etapaFunilCobrancaId
                                                }}
                                            >

                                                <Label 
                                                    text={"Etapa funil"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            <SimpleItem
                                                editorType={"dxDateBox"}
                                                caption={"Programar horário"}
                                                dataField={"_horarioprogramado"}
                                                // dataSource={states}
                                                colSpan={1}
                                                fieldName={"_horarioprogramado"}
                                                editorOptions={{
                                                    // valueChangeEvent: ["keyup", "paste"],
                                                    stylingMode: 'underlined',
                                                    // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                                    dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss",
                                                    type:"datetime"
                                                }}
                                            >
                                                <Label 
                                                    text={"Programar horário"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            <SimpleItem
                                                editorType={"dxTextBox"}
                                                caption={"Telefone"}
                                                dataField={"telefone"}
                                                // dataSource={states}
                                                colSpan={1}
                                                fieldName={"telefone"}
                                                editorOptions={{
                                                    // valueChangeEvent: ["keyup", "paste"],
                                                    stylingMode: 'underlined',
                                                    readOnly: true,
                                                    value: dataSourceFichaAtual?.telefone ? dataSourceFichaAtual.telefone : telefoneSelecionadoState
                                                }}
                                            >
                                                <Label 
                                                    text={"Telefone"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            
                                            <SimpleItem
                                                // editorType={"dxSelectBox"}
                                                editorType={"dxTextBox"}
                                                caption={"Ocorrência"}
                                                dataField={"ocorrencia"}
                                                // dataSource={states}
                                                colSpan={1}
                                                fieldName={"ocorrencia"}
                                                editorOptions={{
                                                    // valueChangeEvent: ["keyup", "paste"],
                                                    stylingMode: 'underlined',
                                                    readOnly: true,
                                                    // dataSource: [itemSelected?.[0]?._Descricao], 
                                                    value: itemSelected?.[0]?._Descricao
                                                }}
                                            >
                                                <Label 
                                                    text={"Ocorrência"} 
                                                    location="top"
                                                    showColon={false}
                                                    labelMode='static'
                                                />
                                            </SimpleItem>

                                            <ButtonItem
                                                disabled={desabilitarBotaoGravarOcorrenciaState}
                                                horizontalAlignment="left"
                                                buttonOptions={buttonOptions} 
                                            />
                                            {/* {desabilitarBotaoGravarOcorrenciaState &&
                                                <LoadIndicator/>
                                            } */}
                                        </GroupItem>

                                        <GroupItem
                                            colCount={1}
                                            colSpan={1}
                                            itemType={'group'}
                                            showClearButton={true}
                                            icon={'folder'}
                                        // cssClass={'group-item'}
                                        >
                                            <SimpleItem
                                                editorType={"dxCalendar"}
                                                caption={"Data agendamento"}
                                                dataField={"_agendamento"}
                                                colSpan={1}
                                                fieldName={"_agendamento"}
                                                editorOptions={{
                                                    valueChangeEvent: ["keyup", "paste"],
                                                    stylingMode: 'underlined',
                                                    // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                                    min: new Date().setDate(new Date().getDate() + 1),
                                                    dateSerializationFormat:"yyyy-MM-dd",
                                                    type: "date"
                                                }}
                                            >
                                                <Label
                                                    text={"Data agendamento"}
                                                    location={"top"}
                                                    showColon={false}
                                                    labelMode='static'
                                                />

                                            </SimpleItem>
                                        </GroupItem>

                                    </GroupItem>

                                </Form>
                            </ScrollView>
                        </div>
                    </Item>
                </TabPanel>
        </div>
    )
}

export default HistoricoCustomForm;