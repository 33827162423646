import { FileUploader, Popup, Toolbar } from "devextreme-react";
import { useState } from "react"
import { useRequestsApi } from "../../../Context/RequestsApi";
import { applicationID, relationId } from "../../../api/ApplicationID";
import { httpRequest } from "../../../functions";

const UpfFileSelect = (props) => {
    const [upfFileSelectValueState, setUpfFileSelectValueState ] = useState()
    const {requestsApi, setRequestsApi} = useRequestsApi()

    const getValueFile = (e) => {
        console.log("getValueFile")
        console.log(e)
    }

    // updateRecordFromJson
    // insertRecordFromJson

    // {
    //     "entityName": "entityfields",
    //     "json": [
    //         {
    //             "id": "A52D1EADD06E4B56B4CB0A3897DB7736",
    //             "fixed": false
    //         }
    //     ],
    //     "applicationId": "1K3K4H5N6BV69084J3UEYS63485091KL",
    //     "relationId": "A284CAE135554DB084C58FE2F68C1238"
    // }
    const insertUpdate = () => {
        
        const objetctData = {
            "id": props.dataEntity.id
        }
        objetctData[`${props.dataField}`] = props.dataEntity[props.dataField]
        
        if(props.dataEntity?.id){
            httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "json": [objetctData],
                "entityName": props.entityName,
                "relationId": relationId,
            })
            .then((sender) => {
                console.log(sender)
            })
            .catch((err) => {
                // alert(err)
            })
        }
        else{
            httpRequest('POST', requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "json": [props.dataEntity],
                "entityName": props.entityName,
                "relationId": relationId,
            })
            .then((sender) => {
                console.log(sender)
            })
            .catch((err) => {
                // alert(err)
            })
        }
    }

    // const uploadHeaders = {
    //     "Authorization": "Basic " + btoa(`${requestsApi.basicAuth}`),        
    //     "applicationId": applicationID,
    //     "json": [props.dataEntity],
    //     "entityName": props.entityName,
    //     "relationId": relationId,
    // }

    // const insertHeaders = {
    //     "Authorization": "Basic " + btoa(`${requestsApi.basicAuth}`),        
    //     "applicationId": applicationID,
    //     "json": [],
    //     "entityName": props.entityName,
    //     "relationId": relationId,
    // }

    return(
        <div  style={{display: "flex", flexDirection: "column", height: "120px"}}>
        <FileUploader
            uploadMode="instantly"
            selectButtonText={"Selecionar arquivo"}
            disabled={props.disabled}
            labelText=""
            uploadButtonText={"Enviar"}
            multiple={false}
            elementAttr={{
                class: "uploader"
            }}
            onFilesUploaded={getValueFile}
            showFileList={true}
            // onDropZoneEnter={onDropZoneEnter}
            // onDropZoneLeave={onDropZoneLeave}
            onBeforeSend={(e) => {
                console.log(e)
            }}                            
            onValueChanged={e => {
                console.log(e);
                console.log(e.value);
                console.log(e.value[0].name);
                // console.log(setDadosDaEntidadeDetalheState)
                console.log(props.dadosDaEntidadeDetalheState)
                const dataEntityJson = props.dataEntity
                dataEntityJson[props.dataField] = e.value
                // props.dataEntity[props.dataField] = e.value
                console.log(props.dataEntity[props.dataField])
                // props.setDataEntity(dataEntityJson)
                console.log(props)
                console.log(props.referencia.getDataSource())
                insertUpdate()
                setUpfFileSelectValueState(e.value);                                
            }}
            // uploadHeaders={props.dataEntity?.id ? uploadHeaders : insertHeaders}
            // uploadMethod={"POST"}
            // uploadUrl={props.dataEntity?.id ? requestsApi.updateRecordFromJson : requestsApi.insertRecordFromJson}
            height={"100%"}
            width={"100%"}
        />
        </div>
    )
}

export default UpfFileSelect;