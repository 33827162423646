import { LoadIndicator } from 'devextreme-react/load-indicator';
import LoadCircular from '../LoadCircular';

const LoadingData = (props) => {
    return(
        <div style={{display: "flex", alignItems: "center", width: "160px"}}>
            {/* <LoadIndicator/> */}
            <LoadCircular/>
            {props.text ? 
                (
                    <div style={{marginLeft: "0.5em"}}>{props.text}</div>
                ) 
                : 
                (
                    <div style={{marginLeft: "0.5em"}}>Carregando dados...</div>
                )
            }
        </div>
    )
}

export default LoadingData;