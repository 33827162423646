export const orderGroups = (jsonStructure) => {
    // if(!grupos){
        const arrayGroupsAndEntities = []
        const arrayEntities = []
        const newJsonStructure = []
        //varre as entidades do telecob structure
        if(jsonStructure?.length > 0){
            for(let c = 0; c <= jsonStructure?.length - 1; c++){
                //varre os grupos e adiciona em 1 array
                for(let i = 0; i <= jsonStructure[c].recordFormProperties[0].recordFormComponents?.length - 1; i++){
                    let itemAtual = jsonStructure[c].recordFormProperties[0].recordFormComponents[i]
                    if(itemAtual?.componentType){
                        arrayGroupsAndEntities.push({itemAtual: itemAtual, entidadeAtual: jsonStructure[c].masterEntity.entityName})
                    }
                }
            }
            //coloca os grupos em ordem crescente pelo tabOrder
            arrayGroupsAndEntities.sort((a, b) => { return a.itemAtual?.tabOrder - b.itemAtual?.tabOrder})

            //pega as entidades na nova ordem e exclui os nomes repetidos
            for(let c = 0; c <= arrayGroupsAndEntities?.length -1 ; c++){
                if(!arrayEntities.find((e) => e === arrayGroupsAndEntities[c].entidadeAtual)){
                    arrayEntities.push(arrayGroupsAndEntities[c].entidadeAtual)
                    console.log(arrayEntities)
                }
            }

            for(let c = 0; c <= arrayEntities?.length - 1; c++){
                jsonStructure.find((e) => e.masterEntity.entityName === arrayEntities[c])
                for(let i = 0; i <= jsonStructure?.length - 1; i++){
                    if(jsonStructure[i].masterEntity.entityName === arrayEntities[c]){
                        newJsonStructure.push(jsonStructure[i])
                    }
                }
            }
        }

        return newJsonStructure

        //chama a propia função para remodelar o jsonStructure
        // orderGroups(jsonStructure, jsonData, arrayGroupsAndEntities)
    // }
    // else if(grupos){
    //     let groupIndex = 0
    //     for(let c = 0; c <= jsonStructure.length - 1; c++){
    //         //varre os grupos e modifica usando a nova ordem
    //         for(let i = 0; i <= jsonStructure[c].recordFormProperties[0].recordFormComponents.length - 1; i++){
    //             if(jsonStructure[c].recordFormProperties[0].recordFormComponents[i]?.componentType){
    //                 jsonStructure[c].recordFormProperties[0].recordFormComponents[i] = grupos[groupIndex]
    //                 groupIndex++
    //             }
    //         }
    //     }
    //     return jsonStructure
    // }
}