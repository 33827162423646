import './style.css'
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Lookup,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel
    } from 'devextreme-react/data-grid';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage"
import RecordFormPopup from "../RecordForm/RecordFormPopup";
import { Toast } from 'devextreme-react/toast';
import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState, useCallback } from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { Button } from 'devextreme-react';
import { applicationID, relationId } from '../../api/ApplicationID';
import { LoadPanel } from 'devextreme-react/load-panel';

const MensagemCustomForm = (props) => {

    const {requestsApi, setRequestsApi} = useRequestsApi()

    const [contatosArrayState, setContatosArrayState] = useState([])
    const [dataLookupState, setDataLookupState] = useState()
    const [contatosSelecionadosState, setContatosSelecionadosState] = useState()

    const [templateSelecionadoEmailState, setTemplateSelecionadoEmailState] = useState()
    const [templateSelecionadoSmsState, setTemplateSelecionadoSmsState] = useState()
    const [templateSelecionadoWhatsappState, setTemplateSelecionadoWhatsappState] = useState()

    const [logMensagensState, setLogMensagensState] = useState([])

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])

    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const [states, setStates] = useState({
        toastVisible: false,
        toastType: "info",
        toastMessage: ""
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    const refreshData = () => {
        // httpRequest('GET', `${requestsApi.getContatosTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
        // .then((sender) => {
        //     const resultGetViewJson = JSON.parse(sender)
        //     setContatosArrayState(resultGetViewJson[0]?.telefones)
        //     console.log(sender)
        // })
        // .catch((error) => {
        //     console.log(error)
        //     setMessage(error.data.error)
        //     setVisible(true)
        // })

        // const sendLookupRequest = {
        //     "lookUpEntities": [
        //         {
        //             "lookUpEntity": "_templateemail"
        //         },
        //         {
        //             "lookUpEntity": "_templatesms"
        //         },
        //         {
        //             "lookUpEntity": "_templatewhatsapp"
        //         },
        //         {
        //             "lookUpEntity": "_cargo"
        //         },
        //         {
        //             "lookUpEntity": "_pontuacao_contato",
        //         },
        //         {
        //             "lookUpEntity": "_tipo_contato",
        //         }
        //     ]
        // }

        // httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
        // .then(senderLookup => {
        //     const resultLookup = JSON.parse(senderLookup)
        //     setDataLookupState(resultLookup)
        // })
        // .catch((error) => {
        //     console.log(error)
        //     setMessage(error.data.error)
        //     setVisible(true)
        // })

        httpRequest('GET', `${requestsApi.getMensagensTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
            .then((sender) => {
                const resultGetMensagensTituloJson = JSON.parse(sender)
                setLogMensagensState(resultGetMensagensTituloJson)
                // console.log(sender)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
    }

    const enviarMensagem = (procedure, templateSelecionado) => {
        if(contatosSelecionadosState?.length > 0){
            showLoadPanel()
            const arrayIdsSelecionados = []

            for(let c = 0; c <= contatosSelecionadosState.length - 1; c++){
                arrayIdsSelecionados.push(contatosSelecionadosState[c].id)
            }

            const objectEnviarMensagem = {
                "applicationId": applicationID,
                "relationId": relationId,
                "procedure": procedure,
                "json": {
                    tituloId: props.tituloId,
                    "templateId": templateSelecionado,
                },
                "idValue": arrayIdsSelecionados
            }
    
            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, objectEnviarMensagem)
            .then((sender) => {
                const resultGetViewJson = JSON.parse(sender)
                refreshData()
                hideLoadPanel()
                toastConfiguration(true, "success", resultGetViewJson.mensagem)
                // console.log(sender)
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        }
        else{
            setMessage("Nenhum item foi selecionado.")
            setVisible(true)
        }
    }

    useEffect(() => {
        if(requestsApi){
            httpRequest('GET', `${requestsApi.getContatosTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
            .then((sender) => {
                const resultGetContatosTituloJson = JSON.parse(sender)
                setContatosArrayState(resultGetContatosTituloJson[0]?.telefones)
                // console.log(sender)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })

            httpRequest('GET', `${requestsApi.getMensagensTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
            .then((sender) => {
                const resultGetMensagensTituloJson = JSON.parse(sender)
                setLogMensagensState(resultGetMensagensTituloJson)
                // console.log(sender)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })


            const sendLookupRequest = {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "_templateemail"
                    },
                    {
                        "lookUpEntity": "_templatesms"
                    },
                    {
                        "lookUpEntity": "_templatewhatsapp"
                    },
                    {
                        "lookUpEntity": "_cargo"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato",
                    },
                    {
                        "lookUpEntity": "_tipo_contato",
                    }
                ]
            }

            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
            .then(senderLookup => {
                const resultLookup = JSON.parse(senderLookup)
                setDataLookupState(resultLookup)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        }
    }, [requestsApi])

    const hidePopup = () => {
        setVisible(false)
    }

    return (
        <>
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            {/* <div className="btn-tabPanel"> */}
                {/* <div className="buttons-tabpanel">
                    <Button            
                        icon="add"
                        hint={`Incluir um novo registro`}
                        // disabled={itemPermissions ? (!itemPermissions?._insert) : (true)}
                        type="normal"
                        onClick={() => {
                            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                entityName: '_cadastro_contatos',
                                applicationId: applicationID,
                            })
                            .then((sender) => {
                                setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                    
                                    httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                        "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                    })
                                    .then((sender) => {
                                        setDataLookupRecordFormPopupState(JSON.parse(sender))

                                        setKeyFieldNameState('_cadastro_id')
                                        setIDValueDetailEntity(null)
                                        setDetailEntityName('_cadastro_contatos')
                                        // setDetailCaptionName(senderDetailEntity.caption)
                                        setPopupRecordFormOpen(true)
                                        setPopupState(true)
                                        setStateModeEdit(true)
                                    })
                                    .catch((error) => {
                                        setPopupStates({
                                            visible: true,
                                            message: error.data.error
                                        })
                                    })
                                }
                                else{
                                    setKeyFieldNameState('_cadastro_id')
                                    setIDValueDetailEntity(null)
                                    setDetailEntityName('_cadastro_contatos')
                                    // setDetailCaptionName(senderDetailEntity.caption)
                                    setPopupRecordFormOpen(true)
                                    setPopupState(true)
                                    setStateModeEdit(true)
                                }
                            })
                            .catch((error) => {
                                setPopupStates({
                                    visible: true,
                                    message: error.data.error
                                })
                            })
                            }
                        }
                    />
                </div> */}

                {/* <div className="buttons-tabpanel">
                    <Button
                        icon="refresh"
                        hint="Atualizar dados"
                        type="normal" 
                        onClick={refreshData}
                    />
                </div> */}
            {/* </div> */}
            <h4 style={{marginTop: "0", marginBottom: "0.5em"}}>Contatos</h4>

            <DataGrid
                dataSource={contatosArrayState}
                columnAutoWidth={true}
                showBorders={true}
                onSelectionChanged={(sender) => {
                    // console.log(sender.component.getSelectedRowsData())
                    setContatosSelecionadosState(sender.component.getSelectedRowsData())
                }}
            >
                <Selection 
                    mode="multiple"
                    keyExpr='ID'
                />
                <Scrolling 
                    rowRenderingMode='infinity'
                    showScrollbar='always'
                    useNative={true}
                    >
                </Scrolling>

                <Paging defaultPageSize={6} />

                <Sorting
                    mode="single"
                />
                
                <Pager
                    visible={true}
                    // allowedPageSizes={10}
                    // showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} 
                />

                <HeaderFilter
                    allowSearch={true}
                    visible={true}
                />

                <Column
                    dataField="Nome"
                    caption="Nome"
                ></Column>

                <Column
                    dataField="Email"
                    caption="E-mail"
                ></Column>

                <Column
                    dataField="_CargoId"
                    caption="Cargo"
                >
                    <Lookup
                        dataSource={dataLookupState?._cargo}
                        valueExpr="id"
                        displayExpr="_descricao"
                    />  
                </Column>

                <Column
                    dataField="Fone"
                    caption="Telefone"
                ></Column>

                <Column
                    dataField="_TipoContatoId"
                    caption="Tipo"
                >
                    <Lookup
                        dataSource={dataLookupState?._tipo_contato}
                        valueExpr="id"
                        displayExpr="_descricao"
                    />               
                </Column>

                <Column
                    dataField="_PontuacaoContatoId"
                    caption="Pontuação"
                >
                    <Lookup
                        dataSource={dataLookupState?._pontuacao_contato}
                        valueExpr="id"
                        displayExpr="_descricao"
                    />    
                </Column>
            </DataGrid>



            <TabPanel>
                <TabItem title="Sms">
                    <div className="mensagem-custom-form-div-enviar">
                        <SelectBox
                            dataSource={dataLookupState?._templatesms}
                            displayExpr={"_descricao"}
                            valueExpr={"id"}
                            width={500}
                            onValueChanged = {(sender) => {
                                setTemplateSelecionadoSmsState(sender.value)
                            }}
                        />
                        <Button
                            style={{marginLeft: "1em"}}
                            text="Enviar"
                            type="default"
                            stylingMode="contained"
                            onClick={() => {
                                enviarMensagem("_enviarTemplateSmsContato", templateSelecionadoSmsState)
                            }}
                        />
                    </div>
                </TabItem>


                <TabItem title="E-mail">
                    <div className="mensagem-custom-form-div-enviar">
                        <SelectBox
                            dataSource={dataLookupState?._templateemail}
                            displayExpr={"_descricao"}
                            valueExpr={"id"}
                            width={500}
                            onValueChanged = {(sender) => {
                                setTemplateSelecionadoEmailState(sender.value)
                            }}
                        />

                        <Button
                            style={{marginLeft: "1em"}}
                            text="Enviar"
                            type="default"
                            stylingMode="contained"
                            onClick={() => {
                                enviarMensagem("_enviarTemplateEmailContato", templateSelecionadoEmailState)
                            }}
                        />
                    </div>
                </TabItem>


                <TabItem title="WhatsApp">
                    <div className="mensagem-custom-form-div-enviar">
                        <SelectBox
                            dataSource={dataLookupState?._templatewhatsapp}
                            displayExpr={"_descricao"}
                            valueExpr={"id"}
                            width={500}
                            onValueChanged = {(sender) => {
                                setTemplateSelecionadoWhatsappState(sender.value)
                            }}
                        />

                        <Button
                            style={{marginLeft: "1em"}}
                            text="Enviar"
                            type="default"
                            stylingMode="contained"
                            onClick={() => {
                                enviarMensagem("_enviarTemplateWhatsappContato", templateSelecionadoWhatsappState)
                            }}
                        />
                    </div>
                </TabItem>
            </TabPanel>

            <h4 style={{marginBottom: "0.5em"}}> Últimas mensagens</h4>
            <DataGrid
                // style={{marginTop: "3em"}}
                dataSource={logMensagensState}
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
            >

                <Selection 
                    mode="single"
                    keyExpr='ID'
                />

                <Scrolling 
                    rowRenderingMode='infinity'
                    showScrollbar='always'
                    useNative={true}
                >
                </Scrolling>

                <Paging defaultPageSize={6} />

                <Sorting
                    mode="single"
                />
                
                <Pager
                    visible={true}
                    // allowedPageSizes={10}
                    // showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} 
                />

                <HeaderFilter
                    allowSearch={true}
                    visible={true}
                />

                <Column
                    dataField="data"
                    caption="Data"
                    dataType={"date"}
                    format="shortDateShortTime"
                ></Column>

                <Column
                    width={500}
                    dataField="texto"
                    caption="Texto"
                ></Column>

                <Column
                    dataField="fone"
                    caption="Telefone"
                ></Column>

                <Column
                    dataField="ocorrencia"
                    caption="Ocorrência"
                ></Column>

                <Column
                    dataField="funcionario"
                    caption="Funcionário"
                ></Column>

            </DataGrid>
        </>
    )
}

export default MensagemCustomForm;