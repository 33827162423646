export const convertCheckBox = (fieldNamesCheckBox, dataEntity) => {
    if(dataEntity){
        //pega os campos checkbox e altera de null pra 0
        for(let c = 0; c <= dataEntity.length - 1; c++) {
            for(let i = 0; i <= fieldNamesCheckBox.length - 1; i++) {
                if(dataEntity[c][`${fieldNamesCheckBox[i]}`] === null) {
                    dataEntity[c][`${fieldNamesCheckBox[i]}`] = 0
                    console.log(dataEntity[c][`${fieldNamesCheckBox[i]}`])
                }
            }
        }
    }
    return
}