import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import UpfListKanban from "../../../../CardComponents/UpfListKanban"
import { useState, useEffect, useCallback, memo } from "react"
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { httpRequest } from '../../../../functions';
import { applicationID, relationId } from "../../../../api/ApplicationID"
// import Toolbar, { Item } from 'devextreme-react/toolbar';
import UpfPopupMessage from '../../../../CardComponents/UpfPopupMessage';
import { Toast } from 'devextreme-react/toast';
import "./style.css"
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import RecordFormPopup from '../../../RecordForm/RecordFormPopup';
import { ItemView } from 'devextreme-react/file-manager';
import { Button } from 'devextreme-react';
import { validatePermission } from '../../../../functions/validatePermission'; 
import TabPanel, {Item} from "devextreme-react/tab-panel";


const Kanban = (props) => {
    const { requestsApi } = useRequestsApi()
    const [loadingState, setLoadingState] = useState(true)
    const [resultTarefasFuncionarioJsonState, setResultTarefasFuncionarioJsonState] = useState()
    const statuses = [];
    const funcionariosMap = {};
    const lists = [];
    const [states, setStates] = useState({
        lists: [],
        funcionarios: [],
        status: [],
        tarefas: []
    })
    const [updateRecordFromJsonDataState, setUpdateRecordFromJsonDataState] = useState([])
    const [dataLookup, setDataLookup] = useState()
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })
    const [popupStates, setPopupStates] = useState({
        visible: false,
        message: null
    })

    const hidePopup = () => {
        setPopupStates({
            ...popupStates,
            visible: false
        })
    }

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        });
    }, [toastConfig])

    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [objectPermissionsState, setObjectPermissionsState] = useState()


    useEffect(() => {
        if (requestsApi && loadingState) {
            httpRequest('POST', requestsApi.getTarefasFuncionario, requestsApi.basicAuth, {
                applicationId: applicationID,
                relationId: relationId
            })
                .then((sender) => {
                    const resultTarefasFuncionarioJson = JSON.parse(sender)

                    if (resultTarefasFuncionarioJson[0].tarefas.dataset === null) {
                        resultTarefasFuncionarioJson[0].tarefas.dataset = []
                    }

                    // resultTarefasFuncionarioJson[0].tarefas.dataset.sort((a, b) => {
                    //     return a._indice < b._indice ? -1 : a._indice > b._indice ? 1 : 0
                    // })

                    resultTarefasFuncionarioJson[0].tarefas.statustarefa.sort((a, b) => {
                        return a._indice < b._indice ? -1 : a._indice > b._indice ? 1 : 0
                    })

                    for (let c = 0; c <= resultTarefasFuncionarioJson[0].tarefas.statustarefa.length - 1; c++) {
                        statuses.push(resultTarefasFuncionarioJson[0].tarefas.statustarefa[c]._descricao)
                    }

                    resultTarefasFuncionarioJson[0].tarefas.statustarefa.forEach((status) => {
                        lists.push(resultTarefasFuncionarioJson[0]?.tarefas?.dataset.filter((task) => task._statustarefaid === status.id));
                    });

                    setResultTarefasFuncionarioJsonState(resultTarefasFuncionarioJson)

                    setStates({
                        ...states,
                        lists: lists,
                        status: statuses,
                        tarefas: resultTarefasFuncionarioJson[0]?.tarefas?.dataset,
                        funcionarios: resultTarefasFuncionarioJson[0]?.tarefas?.funcionarios
                    })

                    // httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                    //     "lookUpEntities": [
                    //         {
                    //             "lookUpEntity": "_projeto"
                    //         },
                    //         {
                    //             "lookUpEntity": "_statusTarefa"
                    //         },
                    //         {
                    //             "lookUpEntity": "_categoriaTarefa"
                    //         },
                    //         {
                    //             "lookUpEntity": "_tipoTarefa"
                    //         }
                    //     ]
                    // })
                    // .then((sender) => {
                    //     const resultGetLookupTableJson = JSON.parse(sender)
                    //     console.log(resultGetLookupTableJson)
                    //     setDataLookup(resultGetLookupTableJson)
                    // })
                    // .catch((error) => {
                    //     setPopupStates({
                    //         visible: true,
                    //         message: error.data.error
                    //     })
                    // })

                    setLoadingState(false)
                })
                .catch((error) => {
                    setPopupStates({
                        visible: true,
                        message: error.data.error
                    })
                })

                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    entityName: "_tarefa"
                })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)
                    setObjectPermissionsState(validatePermission(resultGetEntityStructureJson?.[0]?.masterEntity?.objectid))
                    
                })
                .catch((error) => {console.log(error)})
        }

    }, [requestsApi, loadingState])




    // const reorder = (items, item, fromIndex, toIndex) => {
    //     let result = items;
    //     if (fromIndex >= 0) {

    //         result = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];

    //     }

    //     if (toIndex >= 0) {

    //         result = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
    //     }

    //     return result;

    // }

    // const updateTask = (listIndex, itemData, fromIndex, toIndex) => {

    //     states.lists[listIndex] = reorder(states.lists[listIndex], itemData, fromIndex, toIndex);

    //     setStates({
    //         ...states,
    //         lists: states.lists
    //     });

    // }

    // const onListReorder = (e) => {
    //     const value1 = reorder(states.lists, states.lists[e.fromIndex], e.fromIndex, e.toIndex)

    //     const value2 = reorder(states.status, states.status[e.fromIndex], e.fromIndex, e.toIndex)



    //     setStates({
    //         ...states,
    //         lists: value1,
    //         status: value2,
    //     });
    // }

    // const onTaskDragStart = (e) => {

    //     e.itemData = states.lists[e.fromData][e.fromIndex];
    // }

    // const onTaskDrop = async (e) => {
    //     console.log(e)
    //     updateTask(e.fromData, e.itemData, e.fromIndex, -1);
    //     updateTask(e.toData, e.itemData, -1, e.toIndex);

    //     // depois q arrastou, tem q atualizar item alterado
    //     if(e?.itemData?.id && ((e.toData !== e.fromData) || (e.toIndex !== e.fromIndex))){
    //         updateData(e.itemData.id)
    //     }
    // }

    const updateData = (itemAlteradoId, statustarefaId) => {
        const arrayTemp = []
        //grupos
        for (let c = 0; c <= states.lists.length - 1; c++) {
            //itens
            for (let i = 0; i <= states.lists[c].length - 1; i++) {
                states.lists[c][i]["_indice"] = i
                if(statustarefaId){
                    states.lists[c][i]._statustarefaid = statustarefaId
                }
                else{
                    states.lists[c][i]._statustarefaid = resultTarefasFuncionarioJsonState[0].tarefas.statustarefa[c].id
                }
                arrayTemp.push(
                    {
                        "_statustarefaid": states.lists[c][i]._statustarefaid,
                        "_indice": states.lists[c][i]._indice,
                        "id": states.lists[c][i].id
                    }
                )
            }
        }

        httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
            "entityName": "_tarefa",
            "json": arrayTemp.find(e => e.id === itemAlteradoId),
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            const resultUpdateRecordFromJson = JSON.parse(sender)
            toastConfiguration(true, 'success', resultUpdateRecordFromJson.mensagem)
            //refresh
            refreshData()

        })
        .catch((error) => {
            setPopupStates({
                visible: true,
                message: error.data.error
            })
        })
    }

    const refreshData = () => {
        setStates({
            lists: [],
            funcionarios: [],
            status: [],
            tarefas: []
        })
        setLoadingState(true)
    }

    // const createNewTask = () => {
    //     httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
    //         applicationId: applicationID,
    //         entityName: "_tarefa"
    //     })
    //     .then((sender) => { 
    //         const resultGetEntityStructureJson = JSON.parse(sender)

    //         setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

    //         if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
    //             httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
    //                 "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
    //             })
    //             .then((sender) => {
    //                 const senderDataLookup = JSON.parse(sender)
    //                 setDataLookupRecordFormPopupState(senderDataLookup)

    //                 setDetailEntityName("_tarefa")
    //                 setDetailCaptionName("Tarefas")
    //                 setPopupState(true)
    //                 setPopupRecordFormOpen(true)
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //         }
    //         else{
    //             setDetailEntityName("_tarefa")
    //             setDetailCaptionName("Tarefas")
    //             setPopupState(true) 
    //             setPopupRecordFormOpen(true)    
    //         }
    //     })
    // }

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
            />
        );
    })

    return (
        <div className="tab-items">
            {/* <ScrollView
                className="scrollable"
                direction="vertical"
                showScrollbar="always"
                height='100%'
            > */}
            {/* {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                    tarefaId={upfPopupCustomFormStates.tarefaId}
                    // entityStructure={entityStructureRecordFormPopupState}
                />
            } */}

            {popupRecordFormOpen && <RenderRecordFormPopup />}

                {/* <Toolbar
                    className={"div-toolbar-kanban"}
                    height={50}
                >
                </Toolbar> */}
                

                <UpfPopupMessage
                    visible={popupStates.visible}
                    hidePopup={hidePopup}
                    message={popupStates.message}
                />

                <Toast
                    visible={toastConfig.isVisible}
                    message={toastConfig.message}
                    type={toastConfig.type}
                    onHiding={onHiding}
                    displayTime={600}
                    height={60}
                />

                {/* {popupRecordFormOpen && <RenderRecordFormPopup />} */}

                <div style={{display: "flex", alignItems: "center", marginLeft: "0.5em"}}>
                    <h2>Tickets</h2>

                    <Button
                        // className='refresh-button'
                        style={{marginLeft: "1em",}}
                        location="before"
                        icon={'refresh'}
                        hint={"recarregar"}
                        onClick={refreshData}
                    />
                </div>

                <TabPanel
                    // className="right-tabpanel"
                    id='tabpanel-tickets'
                >
                    {!loadingState && states.lists.map((tasks, listIndex) => {
                        const status = states.status[listIndex];
                        return (
                            <Item 
                                title={`${status} ${tasks?.length ? tasks.length : ""}`}
                                // icon=
                            >
                                <ScrollView  width='100%' height='100%' useNative={true}>
                                    <Sortable
                                        className="sortable-lists"
                                        itemOrientation="vertical"
                                        handle=".list-title"
                                        width="auto"
                                        height="auto"
                                        useNative={true}
                                    // onReorder={onListReorder}
                                    >
                                        <UpfListKanban
                                            key={status}
                                            title={status}
                                            index={listIndex}
                                            tasks={tasks}
                                            employeesMap={funcionariosMap}
                                            funcionarios={states.funcionarios}
                                            // onTaskDragStart={onTaskDragStart}
                                            // onTaskDrop={onTaskDrop}
                                            dataLookup={dataLookup}
                                            refreshData={refreshData}
                                            objectPermissionsState={objectPermissionsState}
                                            resultTarefasFuncionarioJsonState={resultTarefasFuncionarioJsonState?.[0]?.tarefas?.statustarefa}
                                            updateData={updateData}
                                        />
                                    </Sortable>
                                </ScrollView>
                            </Item>
                        )
                    })}
                </TabPanel>
            {/* </ScrollView> */}
        </div>
    );
}

export default Kanban;