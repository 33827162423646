import { useEffect, useCallback, useState, useRef } from "react";
import DataGridViewForm from './DataGridViewForm';
import PivotGridViewForm from './PivotGridViewForm';
import { v4 } from 'uuid';
import Form, { SimpleItem, GroupItem, Label, ButtonItem } from 'devextreme-react/form';
import DropDownBox from 'devextreme-react/drop-down-box';
import { applicationID, relationId } from "../../api/ApplicationID";
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Column, Editing } from 'devextreme-react/data-grid';
import './style.css';
import ScrollView from 'devextreme-react/scroll-view';
import { httpRequest } from "../../functions"
import {
    Popup,
    ToolbarItem,
} from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import pivot from '../../assets/pivot.png'
import datagrid from '../../assets/datagrid.png'
import { Toast } from 'devextreme-react/toast';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useRequestsApi } from "../../Context/RequestsApi";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import WelcomeTab from '../DrawerMain/TabPanelMain/WelcomeTab';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import UpfPopup from "../../CardComponents/UpfPopup";
import { SpeedDialAction } from "devextreme-react";
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage";
import Loading from "../../CardComponents/Loading";
import { useConnectionErrorOccurredGlobalState } from '../../Context/ConnectionErrorOccurredGlobalState'
import LoadCircular from "../../CardComponents/LoadCircular";
import { object } from "yup/lib/locale";

const ViewForm = (props) => {
    const initialValue = [new Date().setDate(new Date().getDate() - 10), new Date().setDate(new Date().getDate())];
    const { requestsApi } = useRequestsApi()
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const { connectionErrorOccurredGlobalState,  setConnectionErrorOccurredGlobalState} = useConnectionErrorOccurredGlobalState(false)
    const _toolbarRef = useRef(null)

    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [dataSourceGrid, setDataSourceGrid] = useState()
    const [inputQueryStructure, setInputQueryStructure] = useState();
    const [inputQueryData, setInputQueryData] = useState();
    const [jsonUpdate, setJsonUpdate] = useState({
        "viewId": props.id,
        "applicationId": applicationID,
        "relationid": relationId
    })
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);
    const [viewLoadingState, setViewLoadingState] = useState(false)
    const [states, setStates] = useState({
        toastVisible: false,
        toastType: 'info',
        toastMessage: "",
        getViewStructure: null
    })

    const [ downloadTimeState, setDownloadTimeState ] = useState({
        segundos: 0,
        minutos: 0,
        horas: 0
    })

    const [ processamentoTimeState, setProcessamentoTimeState ] = useState({
        segundos: 0,
        minutos: 0,
        horas: 0
    })

    //estado especificop do cronometro
    const [cronometroTimeState, setCronometroTimeState] = useState({
        segundos: 0,
        minutos: 0,
        horas: 0
      })
    const [pararCronometro, setPararCronometro] = useState(false)
    
    
    let stop = false

    const cronometroTimerFunction = (segundos, minutos, horas) => {
        if(!stop){
            dataAtual = new Date()

            let resultadoSegundos = parseInt((dataAtual.getTime() - dataInicial.getTime()) / 1000)

            horas = parseInt(resultadoSegundos / 3600);
            segundos = resultadoSegundos % 3600;

            minutos = parseInt(segundos / 60);
            segundos = parseInt(segundos % 60);

            const obj = {
                segundos: segundos,
                minutos: minutos,
                horas: horas
            }

            cronometroTimeState.segundos = segundos
            cronometroTimeState.minutos = minutos
            cronometroTimeState.horas = horas
        }
   }


    const startCronometro = () => {
        dataInicial = new Date()
        let date = new Date();

        // const offset = date.getTimezoneOffset();
        // date = date.getTime() - (offset*60*1000);
        date = date.getTime();

        setPararCronometro(setInterval(() => {
             cronometroTimerFunction(cronometroTimeState.segundos, cronometroTimeState.minutos, cronometroTimeState.horas)
         }, 1000))
     }

    const pararCronometroRequest = (dataTimeEndRequest) => {
        clearInterval(pararCronometro)
        stop = true
        
        setProcessamentoTimeState({
            segundos: dataTimeEndRequest.segundos,
            minutos: dataTimeEndRequest.minutos,
            horas: dataTimeEndRequest.horas
        })


        let cronometroTimeS = cronometroTimeState.segundos
        let cronometroTimeM = cronometroTimeState.minutos
        let cronometroTimeH = cronometroTimeState.horas


        // if(cronometroTimeS < dataTimeEndRequest.segundos && cronometroTimeS > 0){
        //     cronometroTimeM  = cronometroTimeM  - 1
        //     cronometroTimeS = cronometroTimeS + 60
        // }
        // if(cronometroTimeM  < dataTimeEndRequest.minutos){
        //     cronometroTimeH = cronometroTimeH - 1
        //     cronometroTimeM  = cronometroTimeM  + 60
        // }

        let downloadTimeObj = {
            segundos: cronometroTimeS - dataTimeEndRequest.segundos,
            minutos: cronometroTimeM  - dataTimeEndRequest.minutos,
            horas: cronometroTimeH - dataTimeEndRequest.horas
        }

        if(cronometroTimeS - dataTimeEndRequest.segundos < 0){
            downloadTimeObj.segundos = 0
            // downloadTimeObj.segundos = (cronometroTimeS - dataTimeEndRequest.segundos) * (-1)
        }
        if(cronometroTimeM  - dataTimeEndRequest.minutos < 0){
            downloadTimeObj.minutos = 0
            // downloadTimeObj.minutos = (cronometroTimeM - dataTimeEndRequest.minutos) * (-1)
        }
        if(cronometroTimeH  - dataTimeEndRequest.horas < 0){
            downloadTimeObj.horas = 0
            // downloadTimeObj.horas = (cronometroTimeH - dataTimeEndRequest.horas) * (-1)
        }



        setDownloadTimeState(downloadTimeObj)
    }

    const zerarCronometroRequest = () => {
       
        setCronometroTimeState({
            segundos: 0,
            minutos: 0,
            horas: 0
        })
    }
    
   let dataInicial = null
   let dataAtual = null


    const toastConfiguration = (visible, type, message) => {
        setStates({
            ...states,
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({ ...states, toastVisible: false })
    }

    const autoClickFunction = (sender) => {
        if (sender[0].formProperties[0].view.autoclicksearchbutton === 1) {
            searchButtonClick()
        }

    }
    const [selectItems, setSelectItems] = useState(0)

    useEffect(() => {
        if (inputQueryStructure && states.getViewStructure) {
            autoClickFunction(inputQueryStructure)
        }
        const sendRequest = {
            "viewId": props.id
            , "applicationId": applicationID
        }
        if (!states.getViewStructure) {
            httpRequest('POST', requestsApi.getViewStructure, requestsApi.basicAuth, { "viewId": props.id, "applicationId": applicationID })
                .then(sentGetViewStructore => {
                    const responsegetViewStructure = JSON.parse(sentGetViewStructore)
                    setStates({ ...states, getViewStructure: responsegetViewStructure })

                    if (!inputQueryStructure) {
                        httpRequest('POST', requestsApi.getInputQueryStructure, requestsApi.basicAuth, sendRequest)
                            .then(senderGetInputQueryStructure => {
                                if (senderGetInputQueryStructure === "<HTML><BODY><B>200 OK</B></BODY></HTML>" ||
                                    Object.values(senderGetInputQueryStructure).length === 0) {
                                }
                                else {
                                    const resultGetInputQueryStructure = JSON.parse(senderGetInputQueryStructure);
                                    setInputQueryStructure(resultGetInputQueryStructure)
                                }

                            }).catch((error) => {
                                // if(error.data.error) {
                                //     hideLoadPanel()
                                //     setMessageError(error.data.error)
                                //     setPopup({
                                //         ...popup
                                //             ,isVisible: true
                                //             ,message: `Erro: ${error.status} ` + `(${error.statusText})` 
                                //     })
                                // }
                                // else {
                                hideLoadPanel()
                                setMessageError(error.data)
                                setPopup({
                                    ...popup
                                    , isVisible: true
                                    , message: `Atenção!`
                                })
                                // } 
                            })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    hideLoadPanel()
                    setMessageError(error.data)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                })
        }

        httpRequest('POST', requestsApi.getInputQueryData, requestsApi.basicAuth, sendRequest)
        .then(senderGetInputQueryData => {
            if (senderGetInputQueryData === "<HTML><BODY><B>200 OK</B></BODY></HTML>" ||
                Object.values(senderGetInputQueryData).length === 0) {
            }
            else {
                const resultGetInputQueryData = JSON.parse(senderGetInputQueryData)
                if (props.viewFieldId) {
                    let obj = {}
                    obj[props.viewFieldName] = props.viewFieldId
                    setInputQueryData(obj)
                    jsonUpdate[props.viewFieldName] = props.viewFieldId
                }
                else{
                    setInputQueryData(resultGetInputQueryData)
                }
            }
        }).catch((error) => {
            if (error?.data?.error) {
                hideLoadPanel()
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: `Erro: ${error.status} ` + `(${error.statusText})`
                })
            }
            else {
                hideLoadPanel()
                setMessageError(error.data)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: `Erro: ${error.status} ` + `(${error.statusText})`
                })
            }
        })
    }, [props.id, inputQueryStructure])

    const hidePopup = useCallback(() => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    }, [popup]);

    const closeButtonPopup = {
        text: 'Fechar'
        , icon: 'arrowright'
        , onClick: hidePopup
    };

    useEffect(() => {
        console.log("mudou connectionErrorOccurredGlobalState")
        if(connectionErrorOccurredGlobalState){
            setMessageError("Ocorreu um erro de conexão. Por favor, verifique sua conexão e tente novamente.")
            setPopup({
                ...popup
                , isVisible: true
                , message: `Atenção!`
            })
            setViewLoadingState(false)
            setConnectionErrorOccurredGlobalState(false)
        }
    }, [connectionErrorOccurredGlobalState])

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPanel: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    const [resultViewDataSet, setResultViewDataSet] = useState();

    const getFieldsNamesDateRangeBox = (grupos) => {
        const fieldsNamesDateRangeBoxArray = []

        for(let c = 0; c <= grupos?.length - 1; c++){
            if(grupos[c]?.groupFields?.length > 0){
                for(let i = 0; i <= grupos[c]?.groupFields?.length - 1; i++){
                    if(grupos[c]?.groupFields[i]?.editorType === "dxDateRangeBox"){
                        fieldsNamesDateRangeBoxArray.push(grupos[c]?.groupFields[i].fieldName)
                    }
                }
            }
        }

        return fieldsNamesDateRangeBoxArray
    }

    const searchButtonClick = () => {
        zerarCronometroRequest()
        // setDataSourceGrid("")
        //limpar estados
        setResultViewDataSet(null)
        setLoadingPanelState(true)
        setDataSourceGrid([])
        if (!inputQueryStructure[0].formProperties[0].formComponents && !props.viewFieldId) {
            let dataSourceGrid = {
                "viewId": props.id
                , "applicationId": applicationID
                ,"relationid": relationId
            }



            setViewLoadingState(true)
            //inicia a contagem da request
            startCronometro()
            httpRequest('POST', requestsApi.getViewDataSet, requestsApi.basicAuth, dataSourceGrid, pararCronometroRequest, setConnectionErrorOccurredGlobalState)
                .then(sentGetViewDataSet => {
                    const responseGetViewDataSet = JSON.parse(sentGetViewDataSet)
                    if (props.selectedItemsId) {
                        //array filtrado pelo id dos lote selecionados
                        const newResponseGetViewDataSet = []
                        for (let c = 0; c <= responseGetViewDataSet.length - 1; c++) {
                            for (let i = 0; i <= props.selectedItemsId.length - 1; i++) {

                                if (responseGetViewDataSet[c][`${props.viewFieldName}`] === props.selectedItemsId[i]) {
                                    newResponseGetViewDataSet.push(responseGetViewDataSet[c])
                                }
                            }
                        }
                        setResultViewDataSet(newResponseGetViewDataSet)
                        setViewLoadingState(false)
                        toastConfiguration(true, 'success', `Dados da ${props.caption} carregados!`)
                    }
                    else {
                        setResultViewDataSet(responseGetViewDataSet)
                        setViewLoadingState(false)
                        toastConfiguration(true, 'success', `Dados da ${props.caption} carregados!`)
                    }
                })
                .catch((error) => {
                    // pararCronometroRequest()
                    if (error.data?.error) {
                        setViewLoadingState(false)
                        hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: `Erro: ${error.status} ` + `(${error.statusText})`
                        })
                    }
                    else {
                        hideLoadPanel()
                        setViewLoadingState(false)
                        setMessageError(error.data)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: `Erro: ${error.status} ` + `(${error.statusText})`
                        })
                    }
                })
                .then(() => {
                    hideLoadPanel()
                    setViewLoadingState(false)

                })
        }
        else {
            setViewLoadingState(true)
            //inicia a contagem da request
            startCronometro()
            
            //remove chaves do json com valor null

            Object.keys(jsonUpdate).forEach(prop => {
                if (jsonUpdate[prop] === null) delete jsonUpdate[prop];
            })

            let resultJsonUpdate = jsonUpdate
            const fieldsNamesDateRangeBoxArray = getFieldsNamesDateRangeBox(inputQueryStructure[0].formProperties[0].formComponents)

            if(fieldsNamesDateRangeBoxArray?.length > 0 && Object.keys(jsonUpdate)){
                for(let c = 0; c <= Object.keys(jsonUpdate)?.length - 1; c++){
                    if(fieldsNamesDateRangeBoxArray.filter((e) => e === Object.keys(jsonUpdate)[c])?.length > 0){
                        const item = resultJsonUpdate[`${Object.keys(jsonUpdate)[c]}`]
                        resultJsonUpdate[`${Object.keys(jsonUpdate)[c]}`] = [new Date(item[0]).toISOString().slice(0, 10), new Date(item[1]).toISOString().slice(0, 10)]
                    }
                }

            }

            resultJsonUpdate["relationid"] = relationId

            console.log(resultJsonUpdate)

            httpRequest('POST', requestsApi.getViewDataSet, requestsApi.basicAuth, resultJsonUpdate, pararCronometroRequest, setConnectionErrorOccurredGlobalState)
            .then(sentGetViewDataSet => {
                const responseGetViewDataSet = JSON.parse(sentGetViewDataSet)
                setResultViewDataSet(responseGetViewDataSet)
                setViewLoadingState(false)
                toastConfiguration(true, 'success', `Dados da ${props.caption} carregados!`)
            })
            .catch((error) => {
                // pararCronometroRequest()
                if (error?.data?.error) {
                    hideLoadPanel()
                    setViewLoadingState(false)
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: `Erro: ${error.status} ` + `(${error.statusText})`
                    })
                }
                else {
                    hideLoadPanel()
                    setViewLoadingState(false)
                    setMessageError(error.data)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: `Erro: ${error.status} ` + `(${error.statusText})`
                    })
                }
            })
            .then(() => {
                hideLoadPanel()
                setViewLoadingState(false)
            })
        }
    }

    const sendFormData = {
        text: 'Pesquisar'
        , icon: 'find'
        , location: 'before'
        , type: "default"
        , onClick: searchButtonClick
    };
    const [switchGridType, setSwitchGridType] = useState()

    const [clearTabRuleState, setClearTabRuleState] = useState()
    //estado e função do fecharAbasPopup
    const [visiblePopupCloseTabs, setVisiblePopupCloseTabs] = useState(false)
    const [messagePopupCloseTabs, setMessagePopupCloseTabs] = useState("")

    const hideFecharAbas = () => {
        setVisiblePopupCloseTabs(false)
    }
    //---------------------------

    //configuração das opçoes de abas
    const clearAllTabs = {
        text: "Fechar todas as abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessagePopupCloseTabs("Todas as abas serão fechadas! Deseja continuar?")
                setVisiblePopupCloseTabs(true)
                setClearTabRuleState("clearAllTabs")
            }
        }
    }

    const clearTabsRight = {
        text: "Fechar abas a direita"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessagePopupCloseTabs("Todas as abas a direita da atual serão fechadas! Deseja continuar?")
                setVisiblePopupCloseTabs(true)
                setClearTabRuleState("clearTabsRight")
            }
        }
    }

    const keepThisTab = {
        text: "Fechar outras abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessagePopupCloseTabs("Todas as abas, com excessão da atual, serão fechadas! Deseja continuar?")
                setVisiblePopupCloseTabs(true)
                setClearTabRuleState("keepThisTab")
            }
        }
    }
    //

    //função paralimpar abas
    const clearTabsFunction = (rule) => {
        //fechar todas as tabs
        if (rule === "clearAllTabs") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                }
            ])
            hideFecharAbas()
        }

        //fecha todas as abas a direita
        else if (rule === "clearTabsRight" && indexTabPanelMain < tabPanelItems.length - 1) {
            setTabPanelItems(tabPanelItems.splice(0, indexTabPanelMain + 1))
            setIndexTabPanelMain(indexTabPanelMain)
            hideFecharAbas()
        }

        //fecha todas as abas menos a atual
        else if (rule === "keepThisTab") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                },
                tabPanelItems[indexTabPanelMain]
            ])
            setIndexTabPanelMain(0)
            setIndexTabPanelMain(1)
            hideFecharAbas()
        }
    }

    //opções dos botões do popup de confirmação do fechar abas
    const clearTabsButton = {
        text: "Ok"
        // ,icon: 'arrowright'
        , onClick: () => {
            clearTabsFunction(clearTabRuleState)
        }
    };

    const cancelButtonFecharAbas = {
        text: "Cancelar"
        , icon: "close"
        , onClick: hideFecharAbas
    };

    const onCurrentValueChangeForm = useCallback((item) => {
        setJsonUpdate({
            ...jsonUpdate,
            [item.dataField]: item.value
        })
    },[setJsonUpdate],);
    return (
        <ScrollView
            repaintChangesOnly={true}
            useNative={true}
            width="100%"
            height="100%"
        >
            <div className="tab-items">
                <Toast
                    visible={states.toastVisible}
                    message={states.toastMessage}
                    type={states.toastType}
                    onHiding={onHidingToast}
                    displayTime={1800}
                    height={60}
                />
                <div>
                    {/*<h3 className={'h2-pesquisaInput'}><b>{props.caption}</b></h3>*/}
                    <div className={'main-inputQuery'}>
                        
                        {/* <SpeedDialAction
                            icon="trash"
                            label="Delete row"
                            index={2}
                            onClick={console.log("addrow")} />
                        <SpeedDialAction
                            icon="edit"
                            label="Edit row"
                            index={3}
                            onClick={console.log("addrow")} /> */}

                        <UpfPopupMessage
                            visible={popup.isVisible}
                            hidePopup={hidePopup}
                            message={messageError}
                        />

                        <UpfPopup
                            typePopup="closeTabs"
                            popupVisibleState={visiblePopupCloseTabs}
                            popupVisibleFunctionChangeState={setVisiblePopupCloseTabs}
                            message={messagePopupCloseTabs}
                            clearTabRuleState={clearTabRuleState}
                            toolbarRef={_toolbarRef}
                        />

                        <Toolbar
                            className={"div-toolbar"}
                            height={70}
                            ref={_toolbarRef}
                        >
                            {/* OPÇÕES DAS ABAS */}
                            {!props.popupRecordForm &&
                                <Item
                                    location="after"
                                    locateInMenu="always"
                                    widget="dxButton"
                                    options={clearAllTabs}
                                />
                            }

                            {!props.popupRecordForm &&
                                <Item
                                    location="after"
                                    locateInMenu="always"
                                    widget="dxButton"
                                    options={clearTabsRight}
                                    disabled={tabPanelItems.length === indexTabPanelMain + 1}
                                />
                            }

                            {!props.popupRecordForm &&
                                <Item
                                    location="after"
                                    locateInMenu="always"
                                    widget="dxButton"
                                    options={keepThisTab}
                                />
                            }
                        </Toolbar>

                        <Form
                            // formData={inputQueryData}
                            onFieldDataChanged={(item) => {
                                // console.log(inputQueryData)
                                setJsonUpdate({
                                    ...jsonUpdate,
                                    [item.dataField]: item.value
                                })
                            }}
                            // onFieldDataChanged={onCurrentValueChangeForm}
                        >
                            {inputQueryStructure && inputQueryStructure[0].formProperties[0].formComponents !== null &&
                                inputQueryStructure[0].formProperties[0].formComponents.map((senderGroupItem, index) => {
                                    return (

                                        <GroupItem
                                            key={index}
                                            colCount={senderGroupItem.colCount}
                                            caption={senderGroupItem.caption}
                                            colSpan={senderGroupItem.colSpan}
                                            cssClass={"group-item"}
                                            visible={props.viewFieldId ? false : true}
                                        >
                                            {inputQueryData &&
                                                senderGroupItem.groupFields.map(
                                                    (senderSimpleItem, secondIndex) => {
                                                        if (senderSimpleItem.editorType === "dxDropDownBox") {

                                                            return (
                                                                <GroupItem
                                                                    colCount={senderGroupItem.colCount}
                                                                    colSpan={senderGroupItem.colCount}
                                                                >
                                                                    <DropDownBox
                                                                        value={jsonUpdate[senderSimpleItem.fieldName]}
                                                                        valueExpr="id"
                                                                        displayExpr={`${senderSimpleItem.lookUpResultField}`}
                                                                        visible={true}
                                                                        dataSource={inputQueryData[senderSimpleItem.lookUpListSource]}
                                                                        deferRendering={false}
                                                                        placeholder="Selecione uma opção"
                                                                        contentRender={() => {
                                                                            return (
                                                                                <ScrollView width="100%" height="100%" useNative={true}>
                                                                                    <DataGrid
                                                                                        // className="datagrid-view"
                                                                                        dataSource={inputQueryData[senderSimpleItem.lookUpListSource]}
                                                                                        contextMenuEnabled={true}
                                                                                        // selectedRowKeys={`${jsonUpdate[senderSimpleItem.lookUpListSource]}` || []}
                                                                                        keyExpr='id'
                                                                                        onSelectionChanged={(sender) => {
                                                                                            setJsonUpdate({
                                                                                                ...jsonUpdate
                                                                                                , [senderSimpleItem.fieldName]: sender.selectedRowKeys
                                                                                            })
                                                                                        }}

                                                                                    >
                                                                                        <Column
                                                                                            dataField={senderSimpleItem.lookUpResultField}
                                                                                            caption={senderSimpleItem.fieldCaption}
                                                                                        />
                                                                                        <Selection mode="multiple" keyExpr='id' />
                                                                                        <Scrolling
                                                                                            rowRenderingMode='infinity'
                                                                                            showScrollbar='always'
                                                                                            useNative={true}
                                                                                        >
                                                                                        </Scrolling>
                                                                                        <Paging defaultPageSize={10} />
                                                                                        <FilterRow visible={true} />
                                                                                    </DataGrid>
                                                                                </ScrollView>
                                                                            );
                                                                        }}
                                                                    />
                                                                </GroupItem>
                                                            )
                                                        }
                                                        else if (senderSimpleItem.editorType === 'dxLookup' || senderSimpleItem.editorType === 'dxSelectBox') {
                                                            return (
                                                                <SimpleItem
                                                                    key={secondIndex}
                                                                    editorType={senderSimpleItem.editorType}
                                                                    colSpan={senderSimpleItem.colSpan}
                                                                    caption={senderSimpleItem.fieldCaption}
                                                                    dataField={senderSimpleItem.fieldName}
                                                                    fieldName={senderSimpleItem.fieldCaption}
                                                                    editorOptions=
                                                                    {{
                                                                        items: inputQueryData[senderSimpleItem.lookUpListSource],
                                                                        valueExpr: senderSimpleItem.lookUpKeyField,
                                                                        displayExpr: senderSimpleItem.lookUpResultField,
                                                                    }}
                                                                >
                                                                    <Label text={senderSimpleItem.fieldCaption} />
                                                                </SimpleItem>
                                                                   
                                                            )
                                                        }

                                                        else if (senderSimpleItem.editorType === 'dxDateRangeBox') {
                                                            return (
                                                                <SimpleItem
                                                                    key={secondIndex}
                                                                    editorType={senderSimpleItem.editorType}
                                                                    colSpan={senderSimpleItem.colSpan}
                                                                    caption={senderSimpleItem.fieldCaption}
                                                                    dataField={senderSimpleItem.fieldName}
                                                                    fieldName={senderSimpleItem.fieldCaption}
                                                                    editorOptions={{
                                                                        multiView: false,
                                                                        showClearButton: true,
                                                                        defaultValue: initialValue
                                                                    }}
                                                                >
                                                                    <Label text={senderSimpleItem.fieldCaption} />
                                                                </SimpleItem>
                                                                   
                                                            )
                                                        }
                                                        
                                                        else if (senderSimpleItem.editorType === "dxDateBox" || senderSimpleItem.editorType === "dxCalendar") {
                                                            if(senderSimpleItem.fieldType === "datetime"){
                                                                return (
                                                                    <SimpleItem
                                                                        key={secondIndex}
                                                                        editorType={senderSimpleItem.editorType}
                                                                        colSpan={senderSimpleItem.colSpan}
                                                                        caption={senderSimpleItem.fieldCaption}
                                                                        dataField={senderSimpleItem.fieldName}
                                                                        fieldName={senderSimpleItem.fieldCaption}
                                                                        editorOptions=
                                                                        {{
                                                                            dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                                                        }}
                                                                    >
                                                                        <Label text={senderSimpleItem.fieldCaption} />
                                                                    </SimpleItem>
                                                                )
                                                            }
                                                            else{
                                                                return (
                                                                    <SimpleItem
                                                                        key={secondIndex}
                                                                        editorType={senderSimpleItem.editorType}
                                                                        colSpan={senderSimpleItem.colSpan}
                                                                        caption={senderSimpleItem.fieldCaption}
                                                                        dataField={senderSimpleItem.fieldName}
                                                                        fieldName={senderSimpleItem.fieldCaption}
                                                                        editorOptions=
                                                                        {{
                                                                            dateSerializationFormat: "yyyy-MM-dd"
                                                                        }}
                                                                    >
                                                                        <Label text={senderSimpleItem.fieldCaption} />
                                                                    </SimpleItem>
                                                                )
                                                            }
                                                        }
                                                        else if (senderSimpleItem.editorType === "upfTime") {
                                                                return(
                                                                    <SimpleItem
                                                                        key={secondIndex}
                                                                        editorType="dxDateBox"
                                                                        colSpan={senderSimpleItem.colSpan}
                                                                        caption={senderSimpleItem.fieldCaption}
                                                                        dataField={senderSimpleItem.fieldName}
                                                                        fieldName={senderSimpleItem.fieldCaption}
                                                                        editorOptions=
                                                                        {{
                                                                            dateSerializationFormat:"HH:mm",
                                                                            pickerType:"native",
                                                                            type:"time"
                                                                        }}
                                                                    >
                                                                    <Label text={senderSimpleItem.fieldCaption} />
                                                                </SimpleItem>
                                                                )
                                                        }
                                                        else if(senderSimpleItem.editorType === "dxNumberBox"){
                                                            return (
                                                                //     <GroupItem
                                                                //     colCount={senderSimpleItem.colSpan}
                                                                //     colSpan={senderGroupItem.colCount}
                                                                // >
                                                                <SimpleItem
                                                                    key={secondIndex}
                                                                    editorType={senderSimpleItem.editorType}
                                                                    colSpan={senderSimpleItem.colSpan}
                                                                    caption={senderSimpleItem.fieldCaption}
                                                                    dataField={senderSimpleItem.fieldName}
                                                                    fieldName={senderSimpleItem.fieldCaption}
                                                                    value={props.viewFieldId && props.viewFieldId}
                                                                    visible={props.viewFieldId ? false : true}
                                                                    editorOptions={{
                                                                        onValueChanged:(sender) => {
                                                                            jsonUpdate[`${senderSimpleItem.fieldName}`] = sender.value
                                                                        },
                                                                        onContentReady:(sender) => {
                                                                            jsonUpdate[`${senderSimpleItem.fieldName}`] = null
                                                                        },
                                                                        value: jsonUpdate[`${senderSimpleItem.fieldName}`],
                                                                        // format: "0#",
                                                                        // min: 0
                                                                    }}

                                                                >

                                                                    <Label text={senderSimpleItem.fieldCaption} />
                                                                </SimpleItem>
                                                                // </GroupItem>
                                                            );
                                                        }

                                                        else {
                                                            return (
                                                                //     <GroupItem
                                                                //     colCount={senderSimpleItem.colSpan}
                                                                //     colSpan={senderGroupItem.colCount}
                                                                // >
                                                                <SimpleItem
                                                                    key={secondIndex}
                                                                    editorType={senderSimpleItem.editorType}
                                                                    colSpan={senderSimpleItem.colSpan}
                                                                    caption={senderSimpleItem.fieldCaption}
                                                                    dataField={senderSimpleItem.fieldName}
                                                                    fieldName={senderSimpleItem.fieldCaption}
                                                                    value={props.viewFieldId && props.viewFieldId}
                                                                    visible={props.viewFieldId ? false : true}
                                                                    editorOptions={{
                                                                        onValueChanged:
                                                                            (sender) => {
                                                                                jsonUpdate[`${senderSimpleItem.fieldName}`] = sender.value
                                                                            },
                                                                    }}

                                                                >

                                                                    <Label text={senderSimpleItem.fieldCaption} />
                                                                </SimpleItem>
                                                                // </GroupItem>
                                                            );
                                                        }
                                                    })}
                                        </GroupItem>
                                    );
                                })}

                        </Form>
                        {inputQueryStructure &&
                            <>
                                {inputQueryStructure[0].formProperties[0].view.hidesearchbutton === 0 &&
                                    <div className="btn">
                                        <Button
                                            // buttonOptions={sendFormData}
                                            disabled={viewLoadingState}
                                            icon="find"
                                            visible={inputQueryStructure[0].formProperties[0].view.hidesearchbutton === 0}
                                            text="Pesquisar"
                                            location="before"
                                            type="default"
                                            onClick={searchButtonClick}
                                        />

                                        {/* <LoadIndicator
                                            className="button-indicator"
                                            visible={viewLoadingState}
                                        /> */}

                                        {viewLoadingState &&
                                            <LoadCircular/>
                                        }


                                        {resultViewDataSet &&
                                            <>
                                                <div style={{textAlign: 'center', marginLeft: "1em"}}>
                                                    Processamento
                                                    <div style={{fontSize: '20px'}}>
                                                        <div>
                                                            <span>{("00" + processamentoTimeState.horas).slice(-2)}</span>:<span>{("00" + processamentoTimeState.minutos).slice(-2)}</span>:<span>{("00" + processamentoTimeState.segundos).slice(-2)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{textAlign: 'center', marginLeft: "1em"}}>
                                                    Download
                                                    <div style={{fontSize: '20px'}}>
                                                        <div>
                                                            <span>{("00" + downloadTimeState.horas).slice(-2)}</span>:<span>{("00" + downloadTimeState.minutos).slice(-2)}</span>:<span>{("00" + downloadTimeState.segundos).slice(-2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </>
                        }
                    </div>

                    {states.getViewStructure && resultViewDataSet ?
                        (
                            <>

                                {
                                    states.getViewStructure[0].view.dataGridProperties.showDataGrid === 1 &&
                                    states.getViewStructure[0].view.dataGridProperties.showPivotGrid === 1
                                    ?
                                        (<div className="group-item">
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "left", marginLeft: "0.5em" }}>
                                                <div style={{ display: "flex", width: "50%", justifyContent: "left", marginLeft: "0.5em", marginBottom: "0.5em" }}>
                                                    <div className="buttonsSwitchGridDiv">
                                                        <button
                                                            className="buttonSwitchGrid"
                                                            onClick={() => setSwitchGridType("dataGrid")}
                                                        >
                                                            <img alt="" src={datagrid} width={100} />
                                                        </button>
                                                    </div>

                                                    <div className="buttonsSwitchGridDiv">
                                                        <button
                                                            className="buttonSwitchGrid"
                                                            onClick={() => setSwitchGridType("pivotGrid")}
                                                        >
                                                            <img alt="" src={pivot} width={100} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                        :
                                        (
                                            <>
                                                {states.getViewStructure[0].view.dataGridProperties.showDataGrid === 1 &&
                                                <div>
                                                    <DataGridViewForm
                                                            contextMenuEnabled={true}
                                                            dataSourceGrid={dataSourceGrid}
                                                            selectedItems={setSelectItems}
                                                            selectItems={selectItems}
                                                            states={states}
                                                            id={props.id}
                                                            caption={props.caption}
                                                            resultViewDataSet={resultViewDataSet}
                                                            loadingPanelState={loadingPanelState}
                                                            telecob={props.telecob}
                                                            abrirFicha={props.abrirFicha}
                                                        />
                                                </div>
                                                }

                                                {states.getViewStructure[0].view.dataGridProperties.showPivotGrid === 1 &&
                                                    <div>
                                                        <PivotGridViewForm
                                                            dataSourceGrid={dataSourceGrid}
                                                            id={props.id}
                                                            caption={props.caption}
                                                            getViewStructure={states.getViewStructure[0].view?.dataGridProperties}
                                                            resultViewDataSet={resultViewDataSet}
                                                            loadingPanelState={loadingPanelState}
                                                            telecob={props.telecob}
                                                        />
                                                    </div>
                                                }

                                                {states.getViewStructure[0].view.dataGridProperties.showDataGrid === 0 &&
                                                states.getViewStructure[0].view.dataGridProperties.showPivotGrid === 0 &&
                                                <div>
                                                    <DataGridViewForm
                                                            contextMenuEnabled={true}
                                                            dataSourceGrid={dataSourceGrid}
                                                            selectedItems={setSelectItems}
                                                            selectItems={selectItems}
                                                            states={states}
                                                            id={props.id}
                                                            caption={props.caption}
                                                            resultViewDataSet={resultViewDataSet}
                                                            loadingPanelState={loadingPanelState}
                                                            telecob={props.telecob}
                                                            abrirFicha={props.abrirFicha}
                                                        />
                                                </div>
                                                }
                                            </>
                                        )
                                }
                            </>
                        )
                        :
                        (
                            <>
                                {inputQueryStructure?.[0]?.formProperties?.[0]?.view?.autoclicksearchbutton === 1 &&
                                    <Loading />
                                }
                            </>
                        )
                    }

                    {resultViewDataSet && switchGridType === "dataGrid" &&
                        <div>
                            <DataGridViewForm
                                contextMenuEnabled={true}
                                dataSourceGrid={dataSourceGrid}
                                selectedItems={setSelectItems}
                                selectItems={selectItems}
                                states={states}
                                id={props.id}
                                caption={props.caption}
                                resultViewDataSet={resultViewDataSet}
                                loadingPanelState={loadingPanelState}
                                telecob={props.telecob}
                                abrirFicha={props.abrirFicha}

                            />
                        </div>
                    }
                    {resultViewDataSet && switchGridType === "pivotGrid" &&

                        <div>
                            <PivotGridViewForm
                                dataSourceGrid={dataSourceGrid}
                                id={props.id}
                                caption={props.caption}
                                getViewStructure={states.getViewStructure[0].view?.dataGridProperties}
                                resultViewDataSet={resultViewDataSet}
                                loadingPanelState={loadingPanelState}
                                telecob={props.telecob}
                            />
                        </div>
                    }
                </div>
            </div>
        </ScrollView >
    );

}
export default ViewForm;