const { createContext, useContext, useState } = require("react");

const StatePopupTabPanelContext = createContext();

export const StatePopupTabPanelProvider = ({ children }) => {
  const [statePopup, setStatePopup] = useState({
    view: false
    ,recordForm: false
  });
  return (
    <StatePopupTabPanelContext.Provider value={{ statePopup, setStatePopup }}>
      {children}
    </StatePopupTabPanelContext.Provider>
  );
};

export const useStatePopup = () => useContext(StatePopupTabPanelContext);