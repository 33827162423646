import { loginName, permissions } from "../api/ApplicationID"

export const validatePermission = (objectId) => {
    if(loginName === "admin") {
        return {
            "_objectid": objectId,
            "_execute": 1,
            "_insert": 1,
            "_delete": 1,
            "_edit": 1,
            "_show": 1,
            "_configure": 1
        }
    }
    else{
        return(permissions.find((item) => {return item._objectid === objectId}))
    }
}