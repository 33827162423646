import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';

const PopupDesconto = (props) => {
    console.log(props)

    const hidePopup = () => {
        props.setPopupDescontoVisibleState(false)
    }

    const confirmDesconto = {
        text: 'Confirmar'
        // ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    return(
        <Popup
            visible={props.popupDescontoVisibleState}
            onHiding={hidePopup}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title="Adicionar Desconto"
            width={600}
            height={380}
            resizeEnabled={true}
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={confirmDesconto}
            />
            {/* {message &&
                <p>{message}</p>
            } */}

                <div className="dx-field">
                    <div className="dx-field-label">
                        Valor :
                    </div>
                    <div className="dx-field-value">
                        <NumberBox
                            mode="number"
                            format="R$ #,##0.##00"
                            value={props?.valorSemDescontoState}
                            disabled={true}
                        />
                    </div>
                </div>
                            
                <div className="dx-field">
                    <div className="dx-field-label">
                        Desconto Máximo :
                    </div>
                    <div className="dx-field-value">
                        <TextBox
                            value={`${props?.descontoMaximo}%`}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">
                        Valor Mínimo :
                    </div>
                    <div className="dx-field-value">
                        <NumberBox
                            mode="number"
                            format="R$ #,##0.##00"
                            value={parseFloat(parseFloat(props?.valorSemDescontoState - (props?.descontoMaximo/100) * props?.valorSemDescontoState).toFixed(2))}
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="dx-field">
                    <div className="dx-field-label">
                        Valor :
                    </div>
                    <div className="dx-field-value">
                        <NumberBox
                            value={props.valorComDescontoState}
                            mode="number"
                            max={parseFloat(parseFloat(props?.valorSemDescontoState?.toFixed(2)))}
                            min={parseFloat(parseFloat(props?.valorSemDescontoState - (props?.descontoMaximo/100) * props?.valorSemDescontoState).toFixed(2))}
                            format="R$ #,##0.##00"
                            onValueChanged={(e) => {
                                props.setValorComDescontoState(e.value)
                            }}
                        />
                    </div>
                </div>
        </Popup>
    )
}

export default PopupDesconto