import { Popup } from 'devextreme-react/popup';
import { memo } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import HistoricoCustomForm from '../../HistoricoCustomForm';
import { useState } from 'react'
import { Toast } from 'devextreme-react/toast';

const HistoricoCustomFormPopup = (props) => { 
    // const [states, setStates] = useState({
    //     toastVisible: false,
    //     toastType: "",
    //     toastMessage: "",
    // })
    // const toastConfiguration = (visible, type, message) => {
    //     setStates({
    //         ...states,
    //         toastVisible: visible,
    //         toastType: type,
    //         toastMessage: message
    //     })
    // }
    // const onHidingToast = () => {
    //     setStates({ ...states, toastVisible: false })
    // }
    return(
        <Popup
            visible={props.visibleState}
            onHiding={() => {
                props.functionVisibleState(false)
            }}
            dragEnabled={false}
            fullScreen={true}
            // hideOnOutsideClick={false}
            animation={null}
            showCloseButton={true}
            showTitle={true}
            title={"Incluir ocorrência"}
            id="popupForm"
        >
            <ScrollView width='100%' height='100%' useNative={true}>

                {/* <Toast
                    visible={states.toastVisible}
                    message={states.toastMessage}
                    type={states.toastType}
                    onHiding={onHidingToast}
                    displayTime={1800}
                    height={60}
                /> */}

                <HistoricoCustomForm 
                    ocorrencias={props.ocorrencias}
                    arrayDataSourceParcelasVencidas={props.arrayDataSourceParcelasVencidas}
                    arrayDataSourceContatos={props.arrayDataSourceContatos}
                    tituloId={props.tituloId}
                    clienteId={props.clienteId}
                    functionVisibleState={props.functionVisibleState}
                    toastConfiguration={props.toastConfiguration}
                    setGravouOcorrencia={props.setGravouOcorrencia}
                    pausaEscolhidaDadosState={props.pausaEscolhidaDadosState}
                    setDiscadorEmAtendimentoGlobalState={props.setDiscadorEmAtendimentoGlobalState}
                    abrirPausaResultJsonGlobalState={props.abrirPausaResultJsonGlobalState}
                    setFichaPesquisadaNaTelaState={props.setFichaPesquisadaNaTelaState}
                    fichaPesquisadaNaTelaState={props.fichaPesquisadaNaTelaState}
                    telecob={props.telecob}
                    dataEntity={props.dataEntity}
                    paramsProcessamentoMensagemSocket={props.paramsProcessamentoMensagemSocket}
                    abrirFicha={props.abrirFicha}
                    arrayDataSourceNumeroContratos={props.arrayDataSourceNumeroContratos}
                    setFichaPesquisadaNaTelaGlobalState={props.setFichaPesquisadaNaTelaGlobalState}
                    fichaPesquisadaNaTelaGlobalState={props.fichaPesquisadaNaTelaGlobalState}
                    // upfFloatPopupMessageVisibleLocalState={upfFloatPopupMessageVisibleLocalState}
                    // setUpfFloatPopupMessageVisibleLocalState={setUpfFloatPopupMessageVisibleLocalState}
                />

            </ScrollView>
        </Popup>
    )
}

export default memo(HistoricoCustomFormPopup);