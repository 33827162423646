import { useRef, useCallback, memo, useState, useEffect} from 'react';
import Toolbar from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import TabPanel, {Item} from "devextreme-react/tab-panel";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {applicationID, equipeId, relationId, ramal, DiscadorAtivo, isPausarDiscadorPesquisa, URLLogin, URLLoginAbrirPopup, equipeNome, discadorId} from "../../../api/ApplicationID"
import {httpRequest} from "../../../functions/index"
import ScrollView from 'devextreme-react/scroll-view';
import ContextMenu from "devextreme/ui/context_menu";
import "./style.css"
import { useRequestsApi } from "../../../Context/RequestsApi"
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import ProximaFicha from "./Widgets/ProximaFicha"
import TabulacaoAcionamento from "./Widgets/TabulacaoAcionamento"
import {useTabPanelItems} from "../../../Context/TabPanelItems"
import {useIndexTabPanelMain} from "../../../Context/IndexTabPanelMain"
import TelecobrancaForm from "../TelecobrancaForm"
import {useMainMenuJson} from "../../../Context/MainMenuJson" 
import {useProximaFichaDados} from "../../../Context/ProximaFichaDados"
import { Toast } from 'devextreme-react/toast';
import {useIndexMultiViewItemsGerarAcordo} from '../../../Context/IndexMultiViewItemsGerarAcordo'
import {useGerarAcordoDataSource} from '../../../Context/GerarAcordoDataSource';
import {useGerarBoletoDataSource} from "../../../Context/GerarBoletoDataSource"
import {useIndexMultiViewItemsGerarBoleto} from "../../../Context/IndexMultiViewItemsGerarBoleto"
import {useWidgetsTeleCob} from "../../../Context/WidgetsTeleCob"
import useWebSocket from 'react-use-websocket';
import UpfPopup from '../../../CardComponents/UpfPopup';
import {useBackendJson} from "../../../Context/BackendJson"
import { processamentoMensagemSocket } from '../../../functions/processamentoMensagemSocket/processamentoMensagemSocket';
import {usePausasDisponiveisGlobalState} from "../../../Context/PausasDisponiveisGlobalState"
import { usePausaEscolhidaDadosGlobalState } from "../../../Context/PausaEscolhidaDadosGlobalState";
import { useDiscadorEmAtendimentoGlobalState } from "../../../Context/DiscadorEmAtendimentoGlobalState";
import { useAbrirPausaResultJsonGlobalState } from '../../../Context/AbrirPausaResultJsonGlobalState';
import HistoricoCustomFormPopup from '../../RecordForm/HistoricoCustomFormPopup';
import PesquisaFicha from './Widgets/PesquisaFicha';
import QueryFormPopup from '../../QueryForm/QueryFormPopup';
import RecordFormPopup from '../../RecordForm/RecordFormPopup';
import UpfPopupCustomForm from '../../../CardComponents/UpfPopupCustomForm';
import WelcomeTab from '../../DrawerMain/TabPanelMain/WelcomeTab'
import { WebSocketFunction } from '../../../webSocket/WebSocketFunction';
import { useLoadPanelVisibleGlobalState } from '../../../Context/LoadPanelVisibleGlobalState';
import { LoadPanel } from 'devextreme-react/load-panel';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import { abrirFichaDiscador } from '../../../functions/processamentoMensagemSocket/functions/abrirFicha'
import UpfFloatPopupMessage from '../../../CardComponents/UpfFloatPopupMessage';
import { useUpfFloatPopupMessageVisibleGlobalState } from '../../../Context/UpfFloatPopupMessageVisibleGlobalState';
import { useStatusDiscadorGlobalState } from '../../../Context/StatusDiscadorGlobalState';
import { useFichaPesquisadaNaTelaGlobalState } from '../../../Context/FichaPesquisadaNaTelaGlobalState';
import { useTelecobIndexTabPanelGlobalState } from '../../../Context/TelecobIndexTabPanelGlobalState';
import TextBox from 'devextreme-react/text-box';
import { RiDeleteBack2Fill  } from "react-icons/ri"

const RightTabPanelTeleCob = (props) => {
    const {requestsApi} = useRequestsApi()

    const {telecobIndexTabPanelGlobalState, setTelecobIndexTabPanelGlobalState} = useTelecobIndexTabPanelGlobalState()


    const {statusDiscadorGlobalState, setStatusDiscadorGlobalState} = useStatusDiscadorGlobalState()


    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()

    const {tabPanelItems, setTabPanelItems} = useTabPanelItems();
    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain();
    const {widgetsTeleCob, setWidgetsTeleCob} = useWidgetsTeleCob()

    const {backendJson, setBackendJson} = useBackendJson()
    const {mainMenuJson, setMainMenuJson} = useMainMenuJson()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const {gerarAcordoDataSource, setGerarAcordoDataSource} = useGerarAcordoDataSource()
    const {indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo} = useIndexMultiViewItemsGerarAcordo()
    const {gerarBoletoDataSource, setGerarBoletoDataSource} = useGerarBoletoDataSource()
    const {indexMultiViewItemsGerarBoleto, setIndexMultiViewItemsGerarBoleto} = useIndexMultiViewItemsGerarBoleto()
    const {pausasDisponiveisGlobalState, setPausasDisponiveisGlobalState} = usePausasDisponiveisGlobalState()
    const {abrirPausaResultJsonGlobalState, setAbrirPausaResultJsonGlobalState} = useAbrirPausaResultJsonGlobalState()
    const {discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState} = useDiscadorEmAtendimentoGlobalState()
    const {loadPanelVisibleGlobalState, setLoadPanelVisibleGlobalState} = useLoadPanelVisibleGlobalState()

    const [popupPausaVisibleState, setPopupPausaVisibleState] = useState(false)

    const [historicoCustomFormPopupVisibleState, setHistoricoCustomFormPopupVisibleState] = useState(false)

    const [ocorrenciasState, setOcorrenciasState] = useState()

    const {pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState} = usePausaEscolhidaDadosGlobalState()

    const [proximaFichaBotaoVisibleState, setProximaFichaBotaoVisibleState] = useState(false)
    const [pesquisarFichaBotaoVisibleState, setPesquisarFichaBotaoVisibleState] = useState(false)
    const [incluirOcorrenciaBotaoVisibleState, setIncluirOcorrenciaBotaoVisibleState] = useState(false)



    const [popupQueryFormOpen, setPopupQueryFormOpen] = useState(false)
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState()
    const [tituloQueryFormPopup, setTituloQueryFormPopup] = useState()
    const [queryFormPopupState, setQueryFormPopupState] = useState(false)
    const [foreignFieldSelectedItem, setForeignFieldSelectedItem] = useState()


    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState(false);

    const [entityStructureState, setEntityStructureState] = useState()

    const [states, setStates] = useState({
        isVisiblePopupError: false,
        messagePopupError: "",
        isVisiblePopupErrorAbrirFicha: false,
        messagePopupErrorAbrirFicha: "",
        slectedItem: "",
        tabulacaoAcionamentoJson: [{}],
        tabulacaoAcionamentoEntityName: "titulo_historico",
        toastVisible: false,
        toastType: "info",
        toastMessage: "",
        mesmoCredor: false
    })

    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null,
        caption: null
    })

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }

    const [fichaPesquisadaNaTelaState, setFichaPesquisadaNaTelaState] = useState(false)
    // const {fichaPesquisadaNaTelaGlobalState, setFichaPesquisadaNaTelaGlobalState} = useFichaPesquisadaNaTelaGlobalState()

    
    const RenderQueryFormPopup = useCallback(() => {
        return (
            <QueryFormPopup
                id={Math.random()}
                setPopupQueryFormOpen={setPopupQueryFormOpen}
                popupQueryFormOpen={popupQueryFormOpen}
                entityName={'_titulo'}
                entityStructure={entityStructureState}
                foreignFieldSelectedItem={foreignFieldSelectedItem}
                setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                setQueryFormPopupState={setQueryFormPopupState}
                queryFormPopupState={queryFormPopupState}
                tituloQueryFormPopup={tituloQueryFormPopup}
                abrirFicha={abrirFicha}
                telecob={true}
                fichaPesquisadaNaTelaState={fichaPesquisadaNaTelaState}
            />
        );
    }, 
    [
        popupQueryFormOpen
        ,entityStructureState
        ,foreignFieldSelectedItem
        ,queryFormPopupForeignFieldState
        ,queryFormPopupState
        ,tituloQueryFormPopup
        ,fichaPesquisadaNaTelaState
    ])

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                // idValue={proximaFichaDados.tituloId}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState}
                // masterEntityId={proximaFichaDados.tituloId}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
            />
        );
    })

    const reconectarDiscador = () => {
        if(URLLogin){
            console.log(URLLogin)
            if(URLLoginAbrirPopup === 1){
                window.open(`${URLLogin}`, "nome do popup", "width=340,height=600,left=10000,top=100")
            }
            else{
                httpRequest('GET', `${URLLogin}`, requestsApi.basicAuth)
                .then((sender) => {
                    // const resultGetMensagensTituloJson = JSON.parse(sender)
                    // setLogMensagensState(resultGetMensagensTituloJson)
                    console.log(sender)
                })
                .catch((error) => {
                    if(error?.data?.error){
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: error.data.error
                        })
                    }
                    else{
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: `${error}`
                        })
                    }
                })
            }
        }
    }


    const pesquisarContratoFunction = () => {
        if(parseInt(sessionStorage.getItem("idTabSelecionada")) === 0){
            //solicitar pausa codigo 0
            if(DiscadorAtivo && isPausarDiscadorPesquisa == 1){

                console.log("abrir pausa atendimento discador")
                //pausa atendimento discador
                httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    relationId: relationId,
                    tipoPausaId: "06B6D084A2E94188A06376BF6D9517BF",
                    tipoPausaCodigo: 0,
                    ramal: ramal,
                    host: backendJson.api_host,
                    porta: backendJson.api_port  
                })
                .then((sender) => {
                    let resultSolicitarPausa = JSON.parse(sender)
                    
                    //abre o popup de pesquisa
                    setQueryFormPopupForeignFieldState(true)
                    setPopupQueryFormOpen(true)
                    setTituloQueryFormPopup('Título')
                    setQueryFormPopupState(true)
                    //-------------------------
                })
                .catch((error) => {
                    console.log(error)
                    if(error?.data?.error){
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: error.data.error
                        })
                    }
                    else{
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: "Ocorreu um erro ao solicitar pausa!"
                        })
                    }
                    
                })
            } 
            else{
                //abre o popup de pesquisa
                setQueryFormPopupForeignFieldState(true)
                setPopupQueryFormOpen(true)
                setTituloQueryFormPopup('Título')
                setQueryFormPopupState(true)
                //-------------------------
            }
        }
    }

    const abrirFicha = (getProximaFichaResult) => {
        if(isPausarDiscadorPesquisa == 1){
            setFichaPesquisadaNaTelaState(true)
        }

        if(!states.tabulacaoAcionamentoJson?.[0]){
            states["tabulacaoAcionamentoJson"] = [{tituloId : getProximaFichaResult.tituloId}]
        }
        else{
            states.tabulacaoAcionamentoJson[0]["tituloId"] = getProximaFichaResult.tituloId
        }

        // let stringDate = new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).replace(/\s/g, '')
        // let newStringDate = new Date().toISOString().slice(0, 19)
        // const horarioInicio = newStringDate.slice(0, 11).concat(stringDate.slice(11, 19))

        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const horarioInicio = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
        const exibirOutrosContratos = JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].exibirOutrosContratos;

        sessionStorage.setItem("dataSourceFichaAtual", JSON.stringify(getProximaFichaResult))
        sessionStorage.setItem("horarioInicio", horarioInicio.toLocaleString());

        const outrosContratosMenu = mainMenuJson.filter(menu => menu.text.toLowerCase() !== "outros contratos");
        if ((exibirOutrosContratos === 2 && getProximaFichaResult._qtdoutroscontratoscliente > 0) || (exibirOutrosContratos === 1 && getProximaFichaResult._qtdoutroscontratosclientecredoratual > 0)) {
            setMainMenuJson([
                ...outrosContratosMenu,
                {
                    text: "Outros contratos",
                    path: "outrosContratosForm",
                    icon: "folder",
                    entityName: "_titulo",
                    badge: null,
                    parameters: null,
                    permissionName: null,
                    items: null
                }
            ]);
        } else {            
            setMainMenuJson(outrosContratosMenu);
        }

        setProximaFichaDados({...proximaFichaDados,
            tituloId: getProximaFichaResult.tituloId,
            credorId: getProximaFichaResult.credorId,
            clienteId: getProximaFichaResult._clienteId,
            indexFichaAtual: getProximaFichaResult?.indexFichaAtual ? getProximaFichaResult?.indexFichaAtual : 0,
            indexRegraAtual: getProximaFichaResult?.indexRegraAtual ? getProximaFichaResult?.indexRegraAtual : 0,
            _qtdoutroscontratoscliente: getProximaFichaResult._qtdoutroscontratoscliente,
            _qtdoutroscontratosclientecredoratual: getProximaFichaResult._qtdoutroscontratosclientecredoratual
        })

        //chamar o getDataTelecobranca para pegar o credorId
        if(getProximaFichaResult.tituloId.length > 0){
            httpRequest("POST", requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
                applicationId: applicationID,
                tituloId: getProximaFichaResult.tituloId
            })
            .then((sender) => {
                const resultGetDataTelecobrancaJson = JSON.parse(sender)

                //carregar mensagens
                httpRequest("POST", requestsApi.getTemplateMensagemTitulo, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    tituloId: getProximaFichaResult.tituloId
                })
                .then((sender) => {
                    sessionStorage.setItem("arrayTemplateMensagemTitulo", sender)
                    // setUpfFloatPopupMessageVisibleGlobalState({
                    //     visible: true,
                    //     fixo: true,
                    //     mensagemId: null,
                    //     actionName: "abrirFicha"
                    // })
                })
                .catch((error) => {
                    setLoadPanelVisibleGlobalState(false)
                    console.log(error) 
                    if(error?.data?.error){
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: error.data.error
                        })
                    }
                    else{
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: "Ocorreu um erro no getDataTelecobranca!"
                        })
                    }
                })
                
                if(getProximaFichaResult.credorId){
    
                    httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
                        applicationId: applicationID,
                        equipeId: equipeId,
                        credorId: getProximaFichaResult.credorId
                    })
                    .then((sender) => {
                        const getWidgetsResult = JSON.parse(sender)
                        setWidgetsTeleCob(getWidgetsResult)
                        setGravouOcorrencia(false)
                    })
                    .catch((error) => {
                        setGravouOcorrencia(false)
                        if(error?.data?.error){
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: error.data.error
                            })
                        }
                        else{
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: "Ocorreu um erro no getWidget!"
                            })
                        }
                    })
                }
                carregarTelecobrancaForm(resultGetDataTelecobrancaJson, getProximaFichaResult)
            })
            .catch((error) => {
                setLoadPanelVisibleGlobalState(false)
                console.log(error) 
                if(error?.data?.error){
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: error.data.error
                    })
                }
                else{
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: "Ocorreu um erro no getDataTelecobranca!"
                    })
                }
            })
        }
        else if(getProximaFichaResult.nomeFilaAtual === "Sem fichas"){
            setStates({...states, 
                isVisiblePopupError: true,
                messagePopupError: "Sem fichas."
            })
        }
    }

    const RenderHistoricoCustomFormPopup = useCallback(() => {
        if(!ocorrenciasState){
            for(let c = 0; c <= widgetsTeleCob.length - 1; c++){
                if(widgetsTeleCob[c].widget === "tabulacaoAcionamento"){
                    setOcorrenciasState(widgetsTeleCob[c]?.ocorrencias)
                    console.log(widgetsTeleCob[c]?.ocorrencias)
                }
    
            }
        }



        return (
            <HistoricoCustomFormPopup
                visibleState={historicoCustomFormPopupVisibleState}
                functionVisibleState={setHistoricoCustomFormPopupVisibleState}
                ocorrencias={ocorrenciasState}
                tituloId={proximaFichaDados.tituloId}
                toastConfiguration={toastConfiguration}
                setGravouOcorrencia={setGravouOcorrencia}
                pausaEscolhidaDadosState={pausaEscolhidaDadosGlobalState}
                setDiscadorEmAtendimentoGlobalState={setDiscadorEmAtendimentoGlobalState}
                abrirPausaResultJsonGlobalState={abrirPausaResultJsonGlobalState}
                setFichaPesquisadaNaTelaGlobalState={setFichaPesquisadaNaTelaState}
                fichaPesquisadaNaTelaGlobalState={fichaPesquisadaNaTelaState}
                telecob={true}
                dataEntity={proximaFichaDados?.dataEntityCadastro}
                paramsProcessamentoMensagemSocket={props.paramsProcessamentoMensagemSocket}
                abrirFicha={abrirFicha}
                clienteId={proximaFichaDados?.clienteId}
            />
        );
    }, [
        historicoCustomFormPopupVisibleState
        ,ocorrenciasState
        ,proximaFichaDados
        ,pausaEscolhidaDadosGlobalState
        ,abrirPausaResultJsonGlobalState
        ,fichaPesquisadaNaTelaState
        // ,fichaPesquisadaNaTelaGlobalState
    ])

    const isVisibleItem = () => {
        if(discadorEmAtendimentoGlobalState || fichaPesquisadaNaTelaState || proximaFichaDados.tituloId && !gravouOcorrencia){
            return true
        }
        else{ 
            return false   
        }
    }
    
  //TESTE DE REQUISIÇÃO SOCKET
  
  let webSocketProtocol = 'ws:'
  if (window.location.protocol == 'https:'){
    webSocketProtocol = 'wss:'
  } 

//   const { lastJsonMessage, sendMessage } = useWebSocket(`${webSocketProtocol}//${backendJson.hostName}:${backendJson.api_socket_port}`, {
//     fromSocketIO: true,
//   onOpen: () => {
//       console.log(`conectou com sucesso`)
//       enviarMensagem()
//     },
//   onMessage: (sender) => {
//     console.log(sender)
//     console.log(sender.data)
//     let mensagemJson = JSON.parse(sender.data)
//     processamentoMensagemSocket(
//         mensagemJson, 
//         states,
//         setStates, 
//         requestsApi, 
//         carregarTelecobrancaForm,
//         setWidgetsTeleCob,
//         setGravouOcorrencia,
//         discadorEmAtendimentoGlobalState,
//         setDiscadorEmAtendimentoGlobalState,
//         setPopupPausaVisibleState,
//         popupPausaVisibleState,
//         setChamouProximaFicha,
//         setAbrirPausaResultJsonGlobalState,
//         backendJson
//     )
//   },
//   //queryParams: {"acao": "LOGIN"},
//   onError: (event) => { console.error(event); },
//   });
  
//   const enviarMensagem = () => {
//       let jsonEnvio = {
//         "acao":"LOGIN",
//         "ramal": ramal,
//         "relationId": relationId
//       }
//     sendMessage(JSON.stringify(jsonEnvio))
//   }

    const finalizarLigacaoFunction = () => {
        httpRequest('POST', requestsApi.execProcedure, requestsApi.basicAuth, {
            applicationId: applicationID,
            relationId: relationId,
            entityName: "_discador",
            parameter: "_EndCall",
            idValue: discadorId
        })
        .then((sender) => {
            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
        })
        .catch((error) => {
            if(error?.data?.error){
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: error.data.error
                })
            }
            else{
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: `${error}`
                })
            }
        })
    }

    const modoManualFunction = () => {
        httpRequest('POST', requestsApi.execProcedure, requestsApi.basicAuth, {
            applicationId: applicationID,
            relationId: relationId,
            entityName: "_discador",
            idValue: discadorId,
            parameter: "_discadorModoManual"
        })
        .then((sender) => {
            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
        })
        .catch((error) => {
            if(error?.data?.error){
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: error.data.error
                })
            }
            else{
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: `${error}`
                })
            }
        })
    }

    const modoDiscadorFunction = () => {
        httpRequest('POST', requestsApi.execProcedure, requestsApi.basicAuth, {
            applicationId: applicationID,
            relationId: relationId,
            entityName: "_discador",
            parameter: "_discadorModoDiscador",
            idValue: discadorId
        })
        .then((sender) => {
            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
        })
        .catch((error) => {
            if(error?.data?.error){
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: error.data.error
                })
            }
            else{
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: `${error}`
                })
            }
        })
    }

    const [abrirPopupTransferirRamalState, setAbrirPopupTransferirRamalState] = useState()
    const [ramalTranferenciaState, setRamalTranferenciaState] = useState(false)

    const hidePopupTransferirRamalState = () => {
        setAbrirPopupTransferirRamalState(false)
    }

    const abrirPopupTransferirRamalFunction = () => {
        setAbrirPopupTransferirRamalState(true)
    }


    const [abrirPopupTelefoneDiscarState, setAbrirPopupTelefoneDiscarState] = useState()
    const [telefoneDiscarState, setTelefoneDiscarState] = useState("")

    const hidePopupTelefoneDiscarState = () => {
        setAbrirPopupTelefoneDiscarState(false)
    }

    const abrirPopupTelefoneDiscarFunction = () => {
        setAbrirPopupTelefoneDiscarState(true)
    }

    const transferirRamalFunction = () => {
        httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
            applicationId: applicationID,
            relationId: relationId,
            procedure: "_TransferCall",
            json: {
                discadorId: discadorId,
                ramal: ramalTranferenciaState
            },
            idValue: []
        })
        .then((sender) => {
            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
            hidePopupTransferirRamalState()
            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
        })
        .catch((error) => {
            if(error?.data?.error){
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: error.data.error
                })
            }
            else{
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: `${error}`
                })
            }
        })
    }

    const discadorClickToCallNumberFunction = () => {
        httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
            applicationId: applicationID,
            relationId: relationId,
            procedure: "_discadorClickToCallNumber",
            json: {
                discadorId: discadorId,
                numero: telefoneDiscarState
            },
            idValue: []
        })
        .then((sender) => {
            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
            hidePopupTransferirRamalState()
            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
        })
        .catch((error) => {
            if(error?.data?.error){
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: error.data.error
                })
            }
            else{
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: `${error}`
                })
            }
        })
    }

    const transferirRamal = {
        text: "Transferir ramal"
        ,icon: "arrowright"
        ,onClick: abrirPopupTransferirRamalFunction
    }

    const telefoneDiscar = {
        text: "Discagem manual"
        ,icon: "tel"
        ,onClick: abrirPopupTelefoneDiscarFunction
    }

    const modoManual = {
        text: "Modo manual"
        ,icon: "arrowright"
        ,onClick: modoManualFunction
    }

    const modoDiscador = {
        text: "Modo discador"
        ,icon: "arrowright"
        ,onClick: modoDiscadorFunction
    }

    const finalizarLigacao = {
        text: "Finalizar Ligação"
        ,icon: "arrowright"
        ,onClick: finalizarLigacaoFunction
    }

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const liberarFicha = async () => {
        try {

            let sender = await httpRequest("POST", requestsApi.deleteRecordFromJson, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tituloemtelecobranca",
                fieldName: "_funcionarioid", 
                expressao: relationId
            })
            return true
        }

        catch (error){
            setStates({...states, 
                isVisiblePopupError: true,
                messagePopupError: error
            })
            return false
        }
        // .then((sender) => {
        //     console.log(sender)
        //     return(true)
        // })
        // .catch((error) => {
        //     setStates({...states, 
        //         isVisiblePopupError: true,
        //         messagePopupError: error
        //     })
        //     return(false)
        // })
    }
    const carregarTelecobrancaForm = (resultGetDataTelecobrancaJson, getProximaFichaResult, abrirFichaDiscador) => {
        //recebe o array de estruturas
        //pega valores do getDataTelecobranca do _configuracaoCredor
        const tituloId = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0].id
        const templateTelecobrancaId = resultGetDataTelecobrancaJson?.[0]?.masterEntity?.[0]?.records?.[0]?.["_templatetelecobrancaid"]
        const dataEntityCadastro = resultGetDataTelecobrancaJson.find(e => e?.masterEntity?.[0]?.entityName === "_cadastro")

        console.log(dataEntityCadastro)

        // if(tabPanelItems.length === 1){
        //     console.log(indexTabPanelMain)
        //     setIndexTabPanelMain(tabPanelItems.length + 1)
        // }
        // else{
        //     console.log(indexTabPanelMain)
        //     setIndexTabPanelMain(tabPanelItems.length)
        // }

        setIndexTabPanelMain(tabPanelItems.length)

        if(getProximaFichaResult?.tituloId){
            setTabPanelItems([
                ...tabPanelItems,
                {
                    className: "tab-sheet"
                    //,icon: '/images/favicon.ico'
                    ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <TelecobrancaForm 
                                id={Math.random()} 
                                dataEntity={resultGetDataTelecobrancaJson}
                                templateId={templateTelecobrancaId}
                                tituloId={getProximaFichaResult.tituloId}
                                IDValue={tituloId}
                                abrirFichaDiscador={abrirFichaDiscador}
                                setStates={setStates}
                                // entityName="titulo"
                                // modeEditOn={false} 
                            />
                        </ScrollView>
                    ,text: Math.random()
                    ,type: "telecobrancaForm"
                }
            ])

            console.log(dataEntityCadastro)
          
            setProximaFichaDados({...proximaFichaDados,
                tituloId: getProximaFichaResult.tituloId,
                credorId: resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid,
                clienteId: dataEntityCadastro?.masterEntity?.[0]?.records?.[0]?.id,
                indexFichaAtual: getProximaFichaResult?.indexFichaAtual ? getProximaFichaResult?.indexFichaAtual : 0,
                indexRegraAtual: getProximaFichaResult?.indexRegraAtual ? getProximaFichaResult?.indexRegraAtual : 0,
                dataEntityCadastro: dataEntityCadastro,
                nomeFilaAtual: getProximaFichaResult.nomeFilaAtual,
                nomeRegraAtual: getProximaFichaResult.nomeRegraAtual
            })

            setLoadPanelVisibleGlobalState(false)
        }
        else if(getProximaFichaResult){
            setTabPanelItems([
                ...tabPanelItems,
                {
                    className: "tab-sheet"
                    //,icon: '/images/favicon.ico'
                    ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <TelecobrancaForm 
                                id={Math.random()} 
                                dataEntity={resultGetDataTelecobrancaJson}
                                templateId={templateTelecobrancaId}
                                tituloId={getProximaFichaResult}
                                IDValue={getProximaFichaResult}
                                abrirFichaDiscador={abrirFichaDiscador}
                                setStates={setStates}
                                // entityName="titulo"
                                // modeEditOn={false} 
                            />
                        </ScrollView>
                    ,text: Math.random()
                    ,type: "telecobrancaForm"
                }
            ])
            setProximaFichaDados({...proximaFichaDados,
                tituloId: getProximaFichaResult,
                credorId: resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid,
                clienteId: dataEntityCadastro?.masterEntity?.[0]?.records?.[0]?.id,
                indexFichaAtual: getProximaFichaResult?.indexFichaAtual ? getProximaFichaResult?.indexFichaAtual : 0,
                indexRegraAtual: getProximaFichaResult?.indexRegraAtual ? getProximaFichaResult?.indexRegraAtual : 0,
                dataEntityCadastro: dataEntityCadastro,
                nomeFilaAtual: null,
                nomeRegraAtual: null
            })

            setLoadPanelVisibleGlobalState(false)
        }
    }

    const [gravouOcorrencia, setGravouOcorrencia] = useState(true)
    const [chamouProximaFicha, setChamouProximaFicha] = useState(false)

    const getProximaFichaFunction = useCallback(() => {
        console.log(proximaFichaDados)
            httpRequest("POST", requestsApi.getProximaFicha, requestsApi.basicAuth, {
                applicationId: applicationID,
                equipeid: equipeId,
                relationId: relationId,
                indexFichaAtual: proximaFichaDados.indexFichaAtual,
                indexRegraAtual: proximaFichaDados.indexFichaAtual
            })
            .then((sender) => {
                const getProximaFichaResult = JSON.parse(sender)

                setProximaFichaDados({...proximaFichaDados,
                    indexFichaAtual: getProximaFichaResult.indexFichaAtual,
                    indexRegraAtual: getProximaFichaResult.indexRegraAtual,
                    nomeFilaAtual: getProximaFichaResult.nomeFilaAtual,
                    nomeRegraAtual: getProximaFichaResult.nomeRegraAtual,
                    //pegar do getDataTelecobranca
                    // templateTelecobrancaId: getProximaFichaResult["_templateTelecobrancaId"],
                    credorId: getProximaFichaResult.credorId,
                    tituloId: getProximaFichaResult.tituloId,
                })

                httpRequest("POST", requestsApi.prendeFicha, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    tituloId: getProximaFichaResult.tituloId,
                })
                .then((sender) => {
                    console.log(sender)
                    abrirFicha(getProximaFichaResult)
                })
                .catch((err) => {
                    console.log(err)
                })
                
            })
            .catch((error)=>{
                console.log(error)
                if(error?.data?.error){
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: error.data.error
                    })
                }
                else{
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: "Ocorreu um erro ao chamar próxima ficha!"
                    })
                }
            })
            // .then((sender) => {
            //     console.log(proximaFichaDados)
            //     carregarTelecobrancaForm(proximaFichaDados)
            // })
    }, [proximaFichaDados, tabPanelItems, widgetsTeleCob, indexTabPanelMain, fichaPesquisadaNaTelaState])

    const proximaFichaButtonClick = () => {
        if(parseInt(sessionStorage.getItem("idTabSelecionada")) === 0){
            if(proximaFichaDados.tituloId && !gravouOcorrencia) {
                setStates({...states, 
                    isVisiblePopupError: true,
                    messagePopupError: "Ficha não trabalhada"
                })
            }        
            else 
            { 

                if(tabPanelItems.length > 1 && proximaFichaDados.tituloId){
                    setTabPanelItems([
                        {
                            icon: "home"
                            , badge: ""
                            , component: <WelcomeTab telecob={true} />
                            , text: Math.random()
                        }
                    ])
                    setIndexTabPanelMain(0)
                }
                

                //limpar estados do acordo e do boleto
                // setGerarAcordoDataSource(null)
                // setIndexMultiViewItemsGerarAcordo(0)

                // setGerarBoletoDataSource(null)
                // setIndexMultiViewItemsGerarBoleto(0)

                //chamar proxíma ficha
                if(tabPanelItems.length === 1 || tabPanelItems.length === 2){
                    if(chamouProximaFicha){
                        liberarFicha()
                        setGerarAcordoDataSource(null)
                        setGerarBoletoDataSource(null)
                        getProximaFichaFunction()
                    }
                    else {
                        getProximaFichaFunction()

                    }
                }
            }
        }
    }

    const gravarOcorrenciaButtonClick = () => {
        if(states.tabulacaoAcionamentoJson[0]["ocorrenciaId"] 
        && states.tabulacaoAcionamentoJson[0]["tituloId"]) 
        {
            httpRequest( "POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
                "entityName": states.tabulacaoAcionamentoEntityName,
                "json": states.tabulacaoAcionamentoJson[0],
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                setGravouOcorrencia(true)
                toastConfiguration(true, "success", "Ocorrência gravada com sucesso!")
            })
            .catch((error) => {
                if(error?.data?.error){
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: error.data.error
                    })
                }
                else{
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: "Ocorreu um erro no insertRecordFromJson!"
                    })
                }
            }) 
        }
    }

    const incluirOcorrencia = () => {
        setHistoricoCustomFormPopupVisibleState(true)
        console.log(historicoCustomFormPopupVisibleState)
    }

    const createTarefaCustomForm = () => {
        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            applicationId: applicationID,
            entityName: "_tarefa"
        })
        .then((sender) => {
            const resultGetEntityStructureJson = JSON.parse(sender)

            setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

            if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                    "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                })
                    .then((sender) => {
                        const senderDataLookup = JSON.parse(sender)
                        setDataLookupRecordFormPopupState(senderDataLookup)

                        setDetailEntityName("_tarefa")
                        setDetailCaptionName("Tarefas")
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setIDValueDetailEntity(null)
                        setStateModeEdit(true)
                        setEmbebedIdValueState(proximaFichaDados.tituloId)
                        setEmbebedEntityNameState('_titulo')
                    })
                    .catch((error) => {
                        console.log(error)
                        if(error?.data?.error){
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: error.data.error
                            })
                        }
                        else{
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: "Ocorreu um erro no getLookupTable!"
                            })
                        }
                    })
            }
            else {
                setDetailEntityName("_tarefa")
                setDetailCaptionName("Tarefas")
                setPopupRecordFormOpen(true)
                setPopupState(true)
                setIDValueDetailEntity(null)
                setStateModeEdit(true)
                setEmbebedIdValueState(proximaFichaDados.tituloId)
                setEmbebedEntityNameState('_titulo')
            }
        })

    }

    useEffect(() => {
        console.log(proximaFichaDados)
        console.log(widgetsTeleCob)
        if(widgetsTeleCob.find((e) => e.widget === "pesquisaFicha")){
            setPesquisarFichaBotaoVisibleState(true)
        }
        if(widgetsTeleCob.find((e) => e.widget === "tabulacaoAcionamento") && proximaFichaDados?.tituloId){
            console.log(proximaFichaDados?.tituloId)
            setIncluirOcorrenciaBotaoVisibleState(true)
        }
        else{
            setIncluirOcorrenciaBotaoVisibleState(false)
        }

        if(widgetsTeleCob.find((e) => e.widget === "proximaFicha")){
        // if(!proximaFichaDados?.tituloId && widgetsTeleCob.find((e) => e.widget === "proximaFicha")){
           
            // if(tabPanelItems.length === 1 || tabPanelItems.length === 2){
            //     if(chamouProximaFicha && proximaFichaDados?.nomeFilaAtual !== "Sem fichas"){
            //         liberarFicha()
            //         setGerarAcordoDataSource(null)
            //         setGerarBoletoDataSource(null)
            //         getProximaFichaFunction()
            //     }
            //     else if(proximaFichaDados?.nomeFilaAtual !== "Sem fichas") {
            //         getProximaFichaFunction()
            //     }
            // }
            setProximaFichaBotaoVisibleState(true)
        }
        else{
            setProximaFichaBotaoVisibleState(false)
        }
    }, [proximaFichaDados, widgetsTeleCob])

    useEffect(() => {
        if(!entityStructureState){
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: '_titulo'
            })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)
                setEntityStructureState(resultGetEntityStructureJson)
                props.setParamsProcessamentoMensagemSocketState({
                    states: states,
                    setStates: setStates, 
                    requestsApi: requestsApi, 
                    carregarTelecobrancaForm: carregarTelecobrancaForm,
                    setWidgetsTeleCob: setWidgetsTeleCob,
                    setGravouOcorrencia: setGravouOcorrencia,
                    discadorEmAtendimentoGlobalState: discadorEmAtendimentoGlobalState,
                    setDiscadorEmAtendimentoGlobalState: setDiscadorEmAtendimentoGlobalState,
                    setPopupPausaVisibleState: setPopupPausaVisibleState,
                    popupPausaVisibleState: popupPausaVisibleState,
                    setChamouProximaFicha: setChamouProximaFicha,
                    setAbrirPausaResultJsonGlobalState: setAbrirPausaResultJsonGlobalState,
                    backendJson: backendJson
                })
            })
            .catch((error) => {
                console.log(error)
                if(error?.data?.error){
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: error.data.error
                    })
                }
                else{
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: "Ocorreu um erro no getEntityStructure!"
                    })
                }
            })
        }
        
        //chamar proxíma ficha
        if(tabPanelItems.length === 1 || tabPanelItems.length === 2){
            if(chamouProximaFicha){
                setGerarAcordoDataSource(null)
                setGerarBoletoDataSource(null)
                getProximaFichaFunction()
            }
            else {
                httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    equipeId: equipeId,
                    credorId: proximaFichaDados.credorId
                })
                .then((sender) => {
                    const getWidgetsResult = JSON.parse(sender)
                    setProximaFichaDados({...proximaFichaDados, widgets: getWidgetsResult})
                    setWidgetsTeleCob(getWidgetsResult)
                   
                    // for(let c = 0; c <= getWidgetsResult.length - 1; c++){
                    //     if(getWidgetsResult[c].widget === "tabulacaoAcionamento"){
                    //         setOcorrenciasState(getWidgetsResult[c]?.ocorrencias)
                    //     }
                    // }
                })
                .catch((error) => {
                    if(error?.data?.error){
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: error.data.error
                        })
                    }
                    else{
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: "Ocorreu um erro no getWidget!"
                        })
                    }
                })
            }
        }

            
    }, [])
    // , [tabPanelItems, chamouProximaFicha, entityStructureState])

    const hidePopupError = () => {
        setStates({...states, isVisiblePopupError: false})
    }

    const closeButtonOptions = {
        text: "Ok",
        onClick: hidePopupError
    };

    const tranferirRamalButtonOptions = {
        disabled: !ramalTranferenciaState,
        text: "Transferir",
        onClick: transferirRamalFunction
    };

    const telefoneDiscarButtonOptions = {
        disabled: !telefoneDiscarState,
        text: "Discar",
        icon: "tel",
        onClick: discadorClickToCallNumberFunction
    };

    //abrir ficha popup erro
    const hidePopupAbrirFichaError = () => {
        setStates({...states, isVisiblePopupErrorAbrirFicha: false})
    }

    const noButtonOptions = {
        text: "Não",
        onClick: hidePopupAbrirFichaError
    };

    const yesButtonOptions = {
        text: "Sim",
        onClick: () => {
            abrirFichaDiscador(
                props.paramsProcessamentoMensagemSocket.mensagem, 
                props.paramsProcessamentoMensagemSocket.states, 
                props.paramsProcessamentoMensagemSocket.setStates, 
                props.paramsProcessamentoMensagemSocket.requestsApi, 
                props.paramsProcessamentoMensagemSocket.carregarTelecobrancaForm, 
                props.paramsProcessamentoMensagemSocket.setWidgetsTeleCob, 
                props.paramsProcessamentoMensagemSocket.setGravouOcorrencia,
                props.paramsProcessamentoMensagemSocket.setDiscadorEmAtendimentoGlobalState,
                props.paramsProcessamentoMensagemSocket.backendJson,
                props.paramsProcessamentoMensagemSocket.setTabPanelItems,
                props.paramsProcessamentoMensagemSocket.tabPanelItems
            )

            hidePopupAbrirFichaError()
        }

    };

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }
      
    return(
        <div>
            {historicoCustomFormPopupVisibleState === true ? <RenderHistoricoCustomFormPopup /> : <></>}

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadPanelVisibleGlobalState}
            />

            {popupQueryFormOpen === true ? <RenderQueryFormPopup /> : <></>}

            {/* <RenderQueryFormPopup /> */}

            {popupRecordFormOpen === true ? <RenderRecordFormPopup /> : <></>}

            {/* {upfFloatPopupMessageVisibleGlobalState.visible === true ? <UpfFloatPopupMessage fixo={upfFloatPopupMessageVisibleGlobalState.fixo} mensagemId={upfFloatPopupMessageVisibleGlobalState.mensagemId} actionName={upfFloatPopupMessageVisibleGlobalState.actionName}/> : <></>} */}

            

            {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    caption={upfPopupCustomFormStates.caption}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                />
            }

            <Popup
               visible={abrirPopupTransferirRamalState}
               onHiding={hidePopupTransferirRamalState}
               dragEnabled={false}
               hideOnOutsideClick={true}
               showCloseButton={true}
               showTitle={true}
               title="Transferir ramal"
               width={400}
               height={250}
               resizeEnabled={true} 
            >
                <div className="dx-field">
                    <div className="dx-field-label">
                        Ramal :
                    </div>
                    <div className="dx-field-value">
                        <TextBox
                            valueChangeEvent="input"
                            onValueChange={(e) => {
                                setRamalTranferenciaState(e)
                            }}
                        />
                    </div>
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={tranferirRamalButtonOptions}
                />
            </Popup>

            <Popup
               visible={abrirPopupTelefoneDiscarState}
               onHiding={hidePopupTelefoneDiscarState}
               dragEnabled={true}
               hideOnOutsideClick={true}
               showCloseButton={true}
               showTitle={true}
               title="Discagem manual"
               width={300}
               height={550}
               resizeEnabled={false} 
            >
                {/* <div className="dx-field">
                    <div className="dx-field-label">
                        Ramal :
                    </div>
                    <div className="dx-field-value">
                        <TextBox
                            valueChangeEvent="input"
                            onValueChange={(e) => {
                                setRamalTranferenciaState(e)
                            }}
                        />
                    </div>
                </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={tranferirRamalButtonOptions}
                /> */}
                <>
                    <TextBox
                        id="main-telefone-input-numbers"
                        valueChangeEvent="input"
                        value={telefoneDiscarState}
                        onValueChange={(e) => {
                            setTelefoneDiscarState(e)
                        }}
                    />
                    {/* <div id="main-telefone-input-numbers">
                        {telefoneDiscarState}
                    </div> */}
                    <div id="main-telefone-discar">
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "1")}}>1</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "2")}}>2</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "3")}}>3</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "4")}}>4</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "5")}}>5</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "6")}}>6</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "7")}}>7</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "8")}}>8</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "9")}}>9</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "+")}}>+</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "0")}}>0</div>
                        <div className="numero-telefone-discar" onClick={() => {setTelefoneDiscarState(telefoneDiscarState + "#")}}>#</div>
                        <div className="numero-telefone-discar telefone-finalizar" onClick={() => {finalizarLigacaoFunction()}}>
                            <i 
                                className="dx-icon  icon-tel dx-icon-tel" 
                                // onClick={closeHandler}
                            />
                        </div>
                        <div className="numero-telefone-discar telefone-discar" onClick={() => {discadorClickToCallNumberFunction()}}>
                            <i 
                                className="dx-icon  icon-tel dx-icon-tel" 
                                // onClick={closeHandler}
                            />
                        </div>
                        <div className="numero-telefone-discar" onClick={() => {
                            setTelefoneDiscarState(telefoneDiscarState.substring(0, telefoneDiscarState.length - 1))
                        }}>
                            <RiDeleteBack2Fill/>
                        </div>
                    </div>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={telefoneDiscarButtonOptions}
                    />
                </>
            </Popup>

            <Popup
               visible={states.isVisiblePopupError}
               onHiding={hidePopupError}
               dragEnabled={false}
               closeOnOutsideClick={true}
               showCloseButton={true}
               showTitle={true}
               title="Atenção!"
               width={400}
               height={300}
               resizeEnabled={true} 
            >
                {states.messagePopupError}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonOptions}
                />
            </Popup>


            {/* Ocorreu erro ao abrir ficha */}
            <Popup
               visible={states.isVisiblePopupErrorAbrirFicha}
               onHiding={hidePopupAbrirFichaError}
               dragEnabled={false}
               closeOnOutsideClick={true}
               showCloseButton={true}
               showTitle={true}
               title="Atenção!"
               width={400}
               height={300}
               resizeEnabled={true} 
            >
                {states.messagePopupErrorAbrirFicha}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={yesButtonOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={noButtonOptions}
                />
            </Popup>

            {abrirPausaResultJsonGlobalState &&
                <UpfPopup 
                    typePopup="pausa"
                    popupVisibleFunctionChangeState={setPopupPausaVisibleState}
                    popupVisibleState={popupPausaVisibleState}
                    timer={abrirPausaResultJsonGlobalState?._duracao}
                    title={abrirPausaResultJsonGlobalState?._descricao}
                    pausaId={abrirPausaResultJsonGlobalState?.pausaId}
                    pausaEscolhidaDadosGlobalState={pausaEscolhidaDadosGlobalState}
                    setPausaEscolhidaDadosGlobalState={setPausaEscolhidaDadosGlobalState}
                />
            }


            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />

            <div id="div-toolbar-discador" className="dx-theme-accent-as-text-color dx-theme-background-color">
                <div id="div-toolbar-discador-status" className="dx-theme-accent-as-border-color">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {statusDiscadorGlobalState?.ativo === true ? 
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div 
                                    id="div-status-discador-on"
                                    title={"Conectar"}
                                    onClick={reconectarDiscador}
                                ></div>
                                <div style={{marginLeft: "3px"}}><b>Status: </b>{statusDiscadorGlobalState?.texto}</div>
                            </div>
                            :
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div 
                                    title={"Conectar"}
                                    id="div-status-discador-off" 
                                    onClick={() => {
                                        //colocar pra chamar send de login
                                        console.log(statusDiscadorGlobalState?.getWebSocket)
                                        console.log(statusDiscadorGlobalState?.loginMsg)
                                        if(statusDiscadorGlobalState?.getWebSocket && statusDiscadorGlobalState?.loginMsg){
                                            console.log(statusDiscadorGlobalState?.loginMsg)

                                            statusDiscadorGlobalState?.getWebSocket().send(statusDiscadorGlobalState?.loginMsg)
                                        }
                                    }}
                                ></div>
                                <div style={{marginLeft: "3px"}}> <b>Status: </b> Offline</div>
                            </div>
                        }
                        <div style={{marginLeft: "3px"}}> <b>Equipe: </b> {equipeNome}</div>
                        <div style={{marginLeft: "3px"}}> <b>Ramal: </b> {ramal}</div>
                    </div>

                    <Toolbar style={{width: "40px"}}>
                        <Item location="after"
                            locateInMenu="always"
                            widget="dxButton" 
                            options={transferirRamal}
                        />
                        <Item location="after"
                            locateInMenu="always"
                            widget="dxButton" 
                            options={telefoneDiscar}
                        />
                        <Item location="after"
                            locateInMenu="always"
                            widget="dxButton"
                            options={modoManual}
                        />
                        <Item location="after"
                            locateInMenu="always"
                            widget="dxButton"
                            options={modoDiscador}
                        />
                        <Item location="after"
                            locateInMenu="always"
                            widget="dxButton"
                            options={finalizarLigacao}
                        />
                    </Toolbar>
                </div>

                <div id="div-toolbar-discador-dados-ficha" className="dx-theme-accent-as-border-color">
                    <div id="div-mensagemSocket-telecob">
                    {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.descricaoMailling !== undefined &&
                        JSON.parse(sessionStorage.getItem("mensagemSocket"))?.telefone !== undefined &&
                        JSON.parse(sessionStorage.getItem("mensagemSocket"))?.nome !== undefined ?
                        
                        (
                        <>
                        
                            <b>Nome do cliente:</b> {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.nome}
                            <br></br>
                            <b>Telefone:</b> {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.telefone} {"   "} 
                            {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.protocolo && 
                                <>
                                    <b>Protocolo:</b> {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.protocolo}
                                </>
                            }
                            <br></br>
                            {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.discagem &&
                                <>
                                    <b>Discagem:</b> {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.discagem}
                                    <br></br>
                                </>
                            }
                            <b>Descriação mailling:</b> {JSON.parse(sessionStorage.getItem("mensagemSocket"))?.descricaoMailling?.length > 0 ? JSON.parse(sessionStorage.getItem("mensagemSocket")).descricaoMailling  : "Receptivo"}
                        </>
                        )
                        :
                        (
                            <>
                            {proximaFichaDados?.nomeFilaAtual !== undefined &&
                                proximaFichaDados?.nomeRegraAtual !== undefined &&
                                
                                <>
                                    <b>Fila atual:</b> {proximaFichaDados?.nomeFilaAtual}
                                    <br></br>
                                    <b>Regra atual:</b> {proximaFichaDados?.nomeRegraAtual}
                                </>
                            }
                            </>
                        )
                    }

                    </div> 
                </div>

                <div id="div-toolbar-discador-botoes">

                    {incluirOcorrenciaBotaoVisibleState &&
                        <Button 
                            id='upf-float-incluir-ocorrencia-button'
                            // style={{position: "absolute", top: "50%"}}
                            // className='dx-overlay-content' 
                            type={"default"}
                            text="Incluir ocorrência"
                            // icon="add"
                            onClick={incluirOcorrencia}
                        >
                        </Button>
                    }

                    {pesquisarFichaBotaoVisibleState &&
                        <Button 
                            id='upf-float-pesquisar-ficha-button'
                            // style={{position: "absolute", top: "50%"}}
                            // className='dx-overlay-content' 
                            type={"default"}
                            text="Pesquisar Ficha"
                            // icon="add"
                            disabled={telecobIndexTabPanelGlobalState !== 0}
                            onClick={pesquisarContratoFunction}
                        >
                        </Button>
                    }

                    {proximaFichaBotaoVisibleState &&
                        <Button 
                            id='upf-float-proxima-ficha-button'
                            // style={{position: "absolute", top: "50%"}}
                            // className='dx-overlay-content' 
                            disabled={telecobIndexTabPanelGlobalState !== 0}
                            type={"default"}
                            text="Próxima Ficha"
                            // icon="add"
                            onClick={proximaFichaButtonClick}
                        >
                        </Button>
                    }

                    
                </div>
            </div>
        </div>
    );
}

export default memo(RightTabPanelTeleCob);