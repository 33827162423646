import "./style.css"
import { useEffect, useState } from "react"

const ProgressBar = (props) => {
    console.log(props)

    const progress = document.getElementById("progress")
    const circles = document.querySelectorAll(".circle")

    const [indexMultiViewItems, setIndexMultiViewItems] = useState(1);


    const updateProgressCircles = () => {
        if(indexMultiViewItems == 1){
            circles[0].classList.add("active")
            circles[1].classList.remove("active")
            circles[2].classList.remove("active")
        }

        else if(indexMultiViewItems == 2){
            circles[1].classList.add("active")
            circles[2].classList.remove("active")
        }

        else if(indexMultiViewItems == 3){
            circles[2].classList.add("active")
        }

        const actives = document.querySelectorAll(".active")

        progress.style.width = ((actives.length - 1) / (circles.length)) * 90 + "%"
        
    }

    useEffect(() => {
        if(circles[1]?.classList){
            console.log(circles)
            updateProgressCircles()
        }

    }, [indexMultiViewItems])




    return(
        <div >
            {/* <img className="main-header-logo" src={`${process.env.PUBLIC_URL}/images/logo_empresa.png`} alt="" />   */}         
            <div className="flex-bar-header" style={{background: props.coresProgressBarState.corFundo}}>
                <div className="container">
                    <div className="progress-container">
                        <div className="progress" id="progress" ></div>
                        <div className="div-main-circles-text"  >

                            <div className="div-text-circle">
                                <div className="circle active">
                                    <button 
                                        className="progress-button"
                                        // style={{color: props.coresProgressBarState.corNumeroConcluido}}
                                        onClick={() => {
                                        setIndexMultiViewItems(1)
                                        console.log(document.querySelectorAll(".circle"))
                                    }}
                                    >
                                        1
                                    </button>
                                </div>
                                <div style={{color: props.coresProgressBarState.corTexto}}>
                                    Introdução
                                </div>
                            </div>

                            <div className="div-text-circle">
                                <div className="circle">
                                    <button 
                                        className="progress-button"
                                        // style={{color: props.coresProgressBarState.corNumeroConcluido}}
                                        onClick={() => {
                                        setIndexMultiViewItems(2)
                                        }}
                                    >
                                        2
                                    </button>
                                </div>

                                <div style={{color: props.coresProgressBarState.corTexto}}>
                                    Contratos
                                </div>
                            </div>

                            <div className="div-text-circle">
                                <div className="circle">
                                    <button 
                                        className="progress-button"
                                        // style={{color: props.coresProgressBarState.corNumeroConcluido}}
                                        onClick={() => {
                                            setIndexMultiViewItems(3)
                                        }}
                                    >
                                        3
                                    </button>
                                </div>

                                <div style={{color: props.coresProgressBarState.corTexto}}>
                                    Parcelas
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar;