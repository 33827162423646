import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import {useRef, useState, useCallback, useEffect} from 'react'
import TextBox from 'devextreme-react/text-box';
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import { Toast } from 'devextreme-react/toast';
import UpfHtmlEditor from '../UpfComponents/UpfHtmlEditor';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';

const HtmlFormPopup = (props) => {
    console.log(props)
    const _htmlPopupRef = useRef(null)
    const [emailState, setEmailState] = useState()
    const {requestsApi} = useRequestsApi()

    const [editandoHtml, setEditandoHtml] = useState(false)

    const [htmlContent, setHtmlContent] = useState(false)

    useEffect(() => {
        if(props.htmlTemplate){
            if(!htmlContent){
                setHtmlContent(props.htmlTemplate)
                // htmlPopupFunction()
            }
            else{
                _htmlPopupRef.current.instance.repaint()
            }
        }

    }, [props.htmlTemplate, editandoHtml])

    useEffect(() => {
        console.log(props)
        console.log(props.htmlTemplate)
        console.log(htmlContent)

    }, [htmlContent])

    const [popupStates, setPopupStates] = useState({
        visible: false,
        message: null
    })

    const hidePopupMessage = () => {
        setPopupStates({
          ...popupStates,
          visible: false
        })
    }

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })

    const onHiding = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        })
        props.setHtmlPopupVisible(false)

    }

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        });
    }, [toastConfig])


    const htmlPopupFunction = () => {
        _htmlPopupRef.current.instance.beginUpdate()
        let content = document.getElementById("divcontents");
        let pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        _htmlPopupRef.current.instance.endUpdate()
    }

    const onHidenPopupHtmlTemplate = () => {
        props.setHtmlPopupVisible(false)
    }

    const mostrarHtml = () => {
        if(editandoHtml){
            let content = htmlContent?.__html;
            let pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content);
            pri.document.close();
            pri.focus();
            pri.print();
        }
        else{
            let content = document.getElementById("divcontents");
            let pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
        }
        // let content = document.getElementById("divcontents");
        //     let pri = document.getElementById("ifmcontentstoprint").contentWindow;
        //     pri.document.open();
        //     pri.document.write(content.innerHTML);
        //     pri.document.close();
        //     pri.focus();
        //     pri.print();
        
    }


    const renderHtmlFunction = () => {
        if(htmlContent){
            return htmlContent
        }
        else{
            return props.htmlTemplate
        }
    }

    const HtmlComponent = () => {
        return <div id="divcontents"  dangerouslySetInnerHTML={renderHtmlFunction()} />;
    }

    

    return(
        <Popup
            ref={_htmlPopupRef}
            visible={props.htmlPopupVisible && htmlContent}
            onHiding={onHidenPopupHtmlTemplate}
            dragEnabled={false}
            showCloseButton={true}
            showTitle={true}
            title=""
            fullScreen={true}
            hideOnOutsideClick={false}
            resizeEnabled={true}
            deferRendering={true}
            // onShown={mostrarHtml}
            // onShown={htmlPopupFunction}
        >
            <ScrollView width='100%' height='100%' useNative={true}>

                    <UpfPopupMessage
                        hidePopup={hidePopupMessage}
                        visible={popupStates.visible}
                        message={popupStates.message}
                    />

                    <Toast
                        visible={toastConfig.isVisible}
                        message={toastConfig.message}
                        type={toastConfig.type}
                        onHiding={onHiding}
                        displayTime={1800}
                        height={60}
                    />

                    <div>
                            <div style={{width: '100%', display: "flex", alignItems: "end", marginBottom: "1em"}}>
                                <Button
                                    text="Imprimir"
                                    onClick={mostrarHtml}
                                    type="default"
                                    icon="print"
                                />

                                {!editandoHtml &&
                                    <Button
                                        text="Editar"
                                        onClick={() => {
                                            setEditandoHtml(true)
                                        }}
                                        type="default"
                                        icon="edit"
                                        style={{marginLeft: '1em'}}
                                    />
                                }
                                <div style={{marginLeft: '1em', width: '60%'}}>
                                    E-mail:
                                    <TextBox
                                        value={emailState}
                                        // style={{width: '60%'}}
                                        onValueChanged={(item) => {
                                            console.log(item.value)
                                            setEmailState(item.value)
                                        }}
                                        // mask={@"\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*"}
                                        mode='email'
                                        // maskRules={/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i}
                                        // maskInvalidMessage='E-mail inválido!'
                                        // useMaskedValue={true}
                                        // validationMessageMode='always'
                                    />
                                </div>

                                <Button
                                    text="Enviar"
                                    // disabled={desabilitarBotoesState ? true : false}
                                    style={{marginLeft: '1em'}}
                                    icon="message"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        //enviar boleto para o email
                                        httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                            applicationId: applicationID,
                                            relationId: relationId,
                                            procedure: "_enviarTemplateEmail",
                                            json: {
                                                "entityName":"_titulo",
                                                "codigoTemplate": props.htmlFormPopupDataSourceState,
                                                "email": emailState
                                            },
                                            idValue: [`${props.id}`]
                                        })
                                        .then((sender) => {
                                            const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                            toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                        })
                                        .catch((error) => {
                                            console.log(error?.data?.error)
                                            setPopupStates({
                                                visible: true,
                                                message: error?.data?.error ? error?.data?.error : 'E-mail inválido!'
                                            })
                                        })
                                    }}
                                />
                            </div>

                        {!editandoHtml &&
                            <div style={{backgroundColor: "white", color: "black"}}>
                                <HtmlComponent/>
                                <iframe 
                                    id="ifmcontentstoprint"
                                    title="ifmcontentstoprint"
                                    scrolling="no"
                                    style={{
                                        height: "200vh",
                                        display: "none",
                                        width: "100%", 
                                        position: "relative",
                                        backgroundColor: "white",
                                        zIndex: -1
                                    }}
                                />
                            </div>
                        }


                        {editandoHtml && 
                            <>
                                <UpfHtmlEditor
                                    dataEntity={htmlContent && htmlContent?.__html}
                                    dataEntityState={htmlContent}
                                    setDataEntity={setHtmlContent}
                                    showEditBar={true}
                                    editandoHtml={editandoHtml}
                                />
                                <iframe 
                                    id="ifmcontentstoprint"
                                    title="ifmcontentstoprint"
                                    scrolling="no"
                                    style={{
                                        height: "200vh",
                                        display: "none",
                                        width: "100%", 
                                        position: "relative",
                                        backgroundColor: "white",
                                        zIndex: -1
                                    }}
                                />
                            </>
                        }
                    </div>
                </ScrollView>
            </Popup>
    )
}

export default HtmlFormPopup;