import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Providers from "./Context/index"
import ptMessages from "../node_modules/devextreme/localization/messages/pt.json"
import { locale, loadMessages } from "../node_modules/devextreme/localization"
import config from "devextreme/core/config";
import { HashRouter } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

config({
  forceIsoDateParsing: true,
  uiCulture:"pt",
  culture:"pt"
})
const ThemeGreenDark = React.lazy(() => import('./Themes/GreenDark'))
const ThemeDarkBlue = React.lazy(() => import('./Themes/DarkBlue'))
const ThemeSoftBlue = React.lazy(() => import('./Themes/SoftBlue'))
const ThemeDarkViolet = React.lazy(() => import('./Themes/DarkViolet'))
const ThemeDarkMoon = React.lazy(() => import('./Themes/DarkMoon'))
const ThemeCarmine = React.lazy(() => import('./Themes/Carmine'))
const ThemeGreenMist = React.lazy(() => import('./Themes/GreenMist'))
// const ThemeTealDark = React.lazy(() => import('./Themes/TealDark'))

// const ThemeDarkViolet = React.lazy(() => import('./Themes/DarkViolet'))
const ThemeSelector = ({ children }) => {
    loadMessages('pt')
    loadMessages('pt')
    locale('pt')
    
    const chosenTheme = window.localStorage.getItem('dx-theme') || "generic.dark";
    locale(navigator.language)
    // window.sessionStorage.setItem('teste', ptMessages)
    // const teste = sessionStorage.getItem('locale')
    loadMessages(ptMessages)

    return (
      <>
        <React.Suspense fallback={<></>}>
          {chosenTheme === "darkblue" && <ThemeDarkBlue />}
          {chosenTheme === "softblue" && <ThemeSoftBlue />}
          {chosenTheme === "greendark" && <ThemeGreenDark />}
          {chosenTheme === "greenmist" && <ThemeGreenMist />}
          {chosenTheme === "darkviolet" && <ThemeDarkViolet />}
          {chosenTheme === "carmine" && <ThemeCarmine />}
          {chosenTheme === "darkmoon" && <ThemeDarkMoon />}
        </React.Suspense>
        {children}
      </>
    )
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  ReactDOM.render(
    <React.StrictMode>
      <ThemeSelector>
        <HashRouter>
          <QueryClientProvider client={queryClient}>
            <Providers>
              <App />
            </Providers>
          </QueryClientProvider>
        </HashRouter>
      </ThemeSelector>
    </React.StrictMode>,
    document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();