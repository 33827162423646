import React, { useState } from "react";
import { DashboardControl } from 'devexpress-dashboard-react';
import { ResourceManager } from 'devexpress-dashboard';
import { locale } from "devextreme/localization";
import { useRequestsApi } from "../../../Context/RequestsApi"

const DashboardDesignerForm = () => {
    const {requestsApi} = useRequestsApi()
    const [lang] = useState("pt-BR");

    ResourceManager.setLocalizationMessages(require(`../DevExpressLocalizedResources_2021.2_${lang}/json resources/dx-dashboard.${lang}.json`));
    ResourceManager.setLocalizationMessages(require(`../DevExpressLocalizedResources_2021.2_${lang}/json resources/dx-analytics-core.${lang}.json`));
    locale(lang);

    return (
        <div className="tab-items">
            <div style={{ position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px' }}>
                <DashboardControl style={{ height: '100%' }}
                    endpoint={requestsApi.dashBoardEndpoint}
                    workingMode="Designer"
                >
                </DashboardControl>
            </div>
        </div>
    )
}

export default DashboardDesignerForm;