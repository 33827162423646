import { useRequestsApi } from '../../Context/RequestsApi';
import { httpRequest } from '../../functions';
import { useState, useEffect, useCallback, useRef } from 'react';
import { applicationID, relationId } from '../../api/ApplicationID';
import TreeList, { HeaderFilter, Scrolling, 
    Column, Pager, Editing } from 'devextreme-react/tree-list';
import ScrollView from 'devextreme-react/scroll-view';
import Form, { GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import UpfPopup from '../../CardComponents/UpfPopup';
import { getColumnProperties } from "./getColumnProperties";
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import { LoadPanel } from 'devextreme-react/load-panel';
import notify from 'devextreme/ui/notify';
import './styles.css';

const initialMasterEntityData = {};

const PerfilOcorrenciaCustomForm = (props) => {
    const formRef = useRef(null);

    const { requestsApi } = useRequestsApi();
    const [masterEntityData, setMasterEntityData] = useState([]);
    const [detailEntityData, setDetailEntityData] = useState([]);
    const [detailEntityStructure, setDetailEntityStructure] = useState();
    const [masterEntityComponent, setMasterEntityComponent] = useState();
    const [modeEditForm, setModeEditForm] = useState(false);
    const [loadPanelIsVisible, setLoadPanelIsVisible] = useState(false);
    const [idValue, setIdValue] = useState();
    const [confirmDelete, setConfirmDelete] = useState({ isVisible: false, message: "Tem certeza que deseja deletar este registro?" });
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [clearTabRuleState, setClearTabRuleState] = useState()
    const [detailEntityName, setDetailEntityName] = useState();
    const [detailEntityKeyFieldName, setDetailEntityKeyFieldName] = useState();
    const [gridEditMode, setGridEditMode] = useState(false);
    const [revertPopupVisible, setRevertPopupVisible] = useState(false);    
    const [popup, setPopup] = useState({
        isVisible: false,
        message: "error",
    });    

    // Custom hooks
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain();

    // useEffects
    useEffect(() => {
        setDetailEntityStructure(props.entityStructure[0].detailEntities[0].detailEntity[0].fieldsDefs);
        setDetailEntityKeyFieldName(props.entityStructure[0].detailEntities[0].detailEntity[0].keyFieldName);
        setMasterEntityComponent(props.entityStructure[0].masterEntity);
    }, [props.entityStructure])    

    useEffect(() => {        
        if (props.idValue || idValue) {
            getDataEntity();
        }
    }, [props.idValue, idValue])

    const closeButtonHandler = useCallback(() => {
        const handle = [...tabPanelItems];

        handle.splice(indexTabPanelMain, 1);
        setTabPanelItems(handle);

        if (tabPanelItems.length !== 0) {
            setIndexTabPanelMain(tabPanelItems.length - 2);
        }
    }, [tabPanelItems])

    const onHiding = () => {
        setLoadPanelIsVisible(false);
        setModeEditForm(false);        
    }

    // Functions
    const createForm = () => {     
        setTabPanelItems([
            ...tabPanelItems,
            {
                'title': props.entityStructure?.[0].masterEntity.caption, 
                'component':
                    <ScrollView useNative={true}>
                        <PerfilOcorrenciaCustomForm                            
                            dataLookup={props.dataLookup}
                            masterEntity={props.masterEntity}
                            id={Math.random()}                            
                            entityName={props.entityName}      
                            entityStructure={props.entityStructure}
                            componentType="addRecord"
                        />
                    </ScrollView>,
                'text': Math.random(),
                'icon': 'edit'
            }
        ]);
        setIndexTabPanelMain(tabPanelItems.length);
    }

    const onClickRefreshButton = (refreshType = null, idValueParam = null) => {
        setLoadPanelIsVisible(true);
        if (props.idValue || idValue || idValueParam) {            
            setModeEditForm(false);
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": props.entityName,
                "fieldName": "id",
                "expressao": props.idValue || idValue || idValueParam
            })
            .then(json => {
                const resGetDataEntityJson = JSON.parse(json);
                if (refreshType === "master") {
                    setMasterEntityData(resGetDataEntityJson.masterEntity[0].records[0]);
                    Object.assign(initialMasterEntityData, resGetDataEntityJson.masterEntity[0].records[0])
                }
                setDetailEntityData(resGetDataEntityJson.detailEntity[0].records);
                onHiding();
            })
            .catch((error) => {
                if (error.data.error) {                   
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.data.error
                    })
                } else {                    
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.statusText
                    })
                }                
            })            
        }       
    }

    const recordInsertUpdateJson = () => {        
        if (props.idValue || idValue) {            
            httpRequest("POST", requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
                "entityName": props.entityName,
                "json": {
                    id: masterEntityData.id,
                    _descricao: masterEntityData._descricao
                },
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                notify('Registro atualizado com sucesso!', 'success', 2000);
            })
            .catch((error) => {                
                if (error.data.error) {                    
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.data.error
                    })
                } else {                    
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.statusText
                    })
                }
            })
        } else {                        
            const descricaoValue = formRef.current.instance.option("formData._descricao");
            
            httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
                "entityName": props.entityName,
                "json": {
                    _descricao: descricaoValue
                },
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const resInsertRecordFromJson = JSON.parse(sender);

                setIdValue(resInsertRecordFromJson.idValue);
                execProcedure(resInsertRecordFromJson.idValue);                
                onClickRefreshButton(null, resInsertRecordFromJson.idValue);
                notify('Registro inserido com sucesso!', 'success', 2000);                
            })
            .catch((error) => {                
                if (error.data.error) {                    
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.data.error
                    })
                }
                else {                    
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.statusText
                    })
                }
            })
        }

    }

    const recordDeleteJson = () => {
        if (props.idValue || idValue) {
            httpRequest('POST', requestsApi.deleteRecordFromJson, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": props.entityName,
                "fieldName": 'id',
                "expressao": props.idValue ? props.idValue : idValue,
                "relationId": relationId
            })
            .then((response) => {
                const resultDelete = JSON.parse(response);
                closeButtonHandler();
                hidePopupDelete();
                notify(resultDelete.mensagem, 'success', 2000);                
            })
            .catch((error) => {
                if (error.data.error) {
                    hidePopupDelete();
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.data.error
                    })
                } else {              
                    hidePopupDelete();
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: error.statusText
                    })
                }
            })
        }         
    }

    const recordUpdateJsonBatch = (data) => {
        httpRequest("POST", requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
            "entityName": detailEntityName,
            "json": data,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            notify('Registro atualizado com sucesso!', 'success', 2000);            
        })
        .catch((error) => {            
            if (error.data.error) {                
                setPopup({
                    ...popup,
                    isVisible: true,
                    message: error.data.error
                })
            } else {                
                setPopup({
                    ...popup,
                    isVisible: true,
                    message: error.statusText
                })
            }
        })
    }

    function getDataEntity() {        
        if (props.id) {
            setLoadPanelIsVisible(true);            
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": props.entityName,
                "fieldName": "id",
                "expressao": props.idValue || idValue,
                "applicationId": applicationID
            })
            .then(response => {                
                const json = JSON.parse(response);
                if (json.masterEntity[0].records)
                    setMasterEntityData(json.masterEntity[0].records[0]);
                    Object.assign(initialMasterEntityData, json.masterEntity[0].records[0]);
                if (json.detailEntity[0].records) {
                    setDetailEntityData(json.detailEntity[0].records);
                    setDetailEntityName(json.detailEntity[0].entityName);
                }
                setLoadPanelIsVisible(false);
            })
            .catch((error) => {
                console.log('Error: ', error);
                setLoadPanelIsVisible(false);
            })
        }
    }   

    const execProcedure = (idValue) => {
        httpRequest('POST', requestsApi.execProcedure, requestsApi.basicAuth, {
            applicationId: applicationID,
            entityName: props.entityName,
            relationId: relationId,
            idValue: idValue,
            parameter: "_contextEntity_importarOcorrencias"
        })
        .then((sender) => {
            const resExecProcedure = JSON.parse(sender);
            notify(resExecProcedure.mensagem, 'success', 2000);            
        })
        .catch((error) => {
            console.log("Error:", error);
        })
    }

    const updateDataTreeList = (data) => {
        const dataForUpdate = [];

        data.forEach(i => {                                
            if (i.type === "update") {
                dataForUpdate.push({
                    ...i.data,
                    [detailEntityKeyFieldName]: masterEntityData.id
                });
            }
        });
        if (dataForUpdate.length > 0) recordUpdateJsonBatch(dataForUpdate);
    }

    // Button settings
    const addButtonOptions = {
        icon: 'add',
        hint: 'Incluir um novo registro',
        onClick: createForm
    }
    
    const recordButtonOptions = {
        icon: 'save',
        hint: 'Salvar',
        useSubmitBehavior: true,
        validationGroup: `groupName${props.id}`,
        onClick: recordInsertUpdateJson
    }

    const revertChangesButtonSettings = {
        icon: 'revert',
        hint: 'Desfazer alterações no registro',
        onClick: () => setRevertPopupVisible(true)
    }

    const refreshButtonOptions = {
        icon: 'refresh',
        hint: 'Atualizar dados do servidor',
        onClick: () => onClickRefreshButton("master"),
    }

    const deleteButtonOptions = {
        icon: 'close',
        hint: 'Excluir registro',
        onClick: () => setConfirmDelete({ ...confirmDelete, isVisible: true })
    }

    const saveButtonSettings = {
        name: "saveButton",
        cssClass: "toolbar-item__save--right",        
    }

    const revertButtonSettings = {
        name: "revertButton",
        cssClass: "toolbar-item__revert--right"   
    }

    const confirmRevertButtonPopup = {
        text: "Sim",
        onClick: () => {
            ["addRecord", "addQuery"].includes(props.componentType)
            ? formRef.current.instance.resetValues()
            : formRef.current.instance.option("formData._descricao", initialMasterEntityData._descricao)                      
            
            setRevertPopupVisible(false);
        },
    }

    const cancelRevertButtonPopup = {
        text: "Não",
        onClick: () => setRevertPopupVisible(false)
    }

    //Other settings
    const clearTabsRight = {
        text: "Fechar abas a direita",
        icon: "clear",
        onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas a direita da atual serão fechadas! Deseja continuar?");
                setVisible(true);
                setClearTabRuleState("clearTabsRight");
            }
        }
    }

    const keepThisTab = {
        text: "Fechar outras abas",
        icon: "clear",
        onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas, com excessão da atual, serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("keepThisTab")
            }
        }
    }

    const clearAllTabs = {
        text: "Fechar todas as abas", 
        icon: "clear",
        onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas serão fechadas! Deseja continuar?")
                setVisible(true);
                setClearTabRuleState("clearAllTabs");
            }
        }
    }

    const hidePopupDelete = () => {
        setConfirmDelete({ ...confirmDelete, isVisible: false });
    }

    const confirmDeleteRecord = {
        text: "Ok",
        onClick: recordDeleteJson
    }

    const closeDeleteRecord = {
        text: "Cancelar",
        onClick: hidePopupDelete
    }

    const renderContentPopup = (msg) => {
        return (
            <p>{msg}</p>
        );
    }

    return (
        // ! PROBLEM: Google tradutor extension is appending element in HTML for translate, but it is causing error. For bypass it i'm using div element instead React.Fragment        
        <div className="tab-items">
            {/* Popups */}
            <LoadPanel
                style={{ zIndex: "999 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadPanelIsVisible}
                showIndicator={true}
                shading={false}
                showPane={true}
            />

            <Popup
                onHiding={hidePopupDelete}
                title={"Atenção!"}
                visible={confirmDelete.isVisible}                
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                width={400}
                height={250}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={confirmDeleteRecord}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeDeleteRecord}
            />
                <p>
                    Você realmente deseja apagar este registro?
                </p>
            </Popup>
            
            <Popup
                id="popupForm"
                title="Atenção!"
                visible={revertPopupVisible}
                onHiding={() => setRevertPopupVisible(false)}
                dragEnabled={false}
                hideOnOutsideClick
                showCloseButton
                showTitle
                width={600}
                height={280}
                resizeEnabled
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={confirmRevertButtonPopup}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelRevertButtonPopup}
                />
                <p>As alterações serão perdidas! Deseja cotinuar?</p>
            </Popup>
            
            <Popup                     
                title={"Atenção!"}
                contentRender={() => renderContentPopup(popup.message)}
                onHiding={() => setPopup((prev) => prev.isVisible = false)}
                visible={popup.isVisible}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                width={600}
                height={280}
                resizeEnabled={true}
            />

            <UpfPopup
                typePopup="closeTabs"
                popupVisibleState={visible}
                popupVisibleFunctionChangeState={setVisible}
                message={message}
                clearTabRuleState={clearTabRuleState}                
            />
            
            {/* Form master entity */}
            <Form
                formData={masterEntityData && masterEntityData}
                ref={formRef}
            >
                <GroupItem>
                    <h3 className="main-group__title">
                        {masterEntityComponent ? masterEntityComponent.caption : ""}
                    </h3>
                    <Toolbar
                        className="div-toolbar"
                        height={70}
                    >
                        {props.componentType === "editQuery" &&
                            <Item
                                location="before"
                                widget="dxButton"
                                options={addButtonOptions}
                            />
                        }

                        {(props.componentType === "editQuery" || idValue) &&
                            <Item
                                location="before"
                                widget="dxButton"
                                options={deleteButtonOptions}
                            />  
                        }
                        
                        {(props.componentType === "editQuery" || idValue) &&
                            <Item
                                location="before"
                                widget="dxButton"
                                options={refreshButtonOptions}
                            />
                        }
                        
                        {((props.componentType === "editQuery" && modeEditForm) || props.componentType !== "editQuery") && 
                            <Item
                                location="before"
                                widget="dxButton"
                                options={recordButtonOptions}                                        
                            />
                        }
                        {((props.componentType === "editQuery" && modeEditForm) || props.componentType !== "editQuery") && 
                            <Item                             
                                location="before"
                                widget="dxButton"
                                options={revertChangesButtonSettings}
                            />
                        }
                        
                        {!props.popupRecordForm && !props.teleCobranca &&
                            <Item
                                location="after"
                                locateInMenu="always"
                                widget="dxButton"
                                options={clearAllTabs}
                            />
                        }

                        {!props.popupRecordForm && !props.teleCobranca &&
                            <Item
                                location="after"
                                locateInMenu="always"
                                widget="dxButton"
                                options={clearTabsRight}
                                disabled={tabPanelItems.length === indexTabPanelMain + 1}
                            />
                        }

                        {!props.popupRecordForm && !props.teleCobranca &&
                            <Item
                                location="after"
                                locateInMenu="always"
                                widget="dxButton"
                                options={keepThisTab}
                            />
                        }
                    </Toolbar>
                </GroupItem>

                <GroupItem
                    caption="Dados principais"
                    cssClass="group-item"
                    itemType="group"
                >
                    <SimpleItem 
                        editorType="dxNumberBox"
                        dataField="_codigo"
                        editorOptions={{
                            placeholder: "Código",
                            readOnly: true,                            
                        }}
                    >
                        <Label text="Código" />
                    </SimpleItem>
                    <SimpleItem
                        editorType="dxTextBox"
                        dataField="_descricao"
                        editorOptions={{
                            placeholder: "Descrição",
                            onInput: (e) => {                              
                                if (!modeEditForm) setModeEditForm(true);
                            },
                            onPaste: () => setModeEditForm(true)
                        }}
                    >
                        <Label text="Descrição" />
                    </SimpleItem>
                </GroupItem>
            </Form>

            {/* TreeList */}
            <TabPanel>
                <TabItem title="Ocorrências">
                    <div className="div-tree-list detail-record">
                        <ScrollView width="100%" height="100%" useNative={true}>
                            <Toolbar className="treelist__toolbar">
                                <Item
                                    widget="dxButton"
                                    location="before"
                                    options={{
                                        icon: "rowproperties",
                                        hint: "Editar registros em lote no grid",
                                        onClick: () => setGridEditMode((prev) => !prev)
                                    }}
                                />
                                <Item
                                    widget="dxButton"
                                    location="before"
                                    options={{
                                        icon: "refresh",
                                        hint: "Atualizar dados",
                                        onClick: onClickRefreshButton
                                    }}
                                />
                            </Toolbar>
                            <TreeList
                                dataSource={detailEntityData}
                                dataStructure="plain"
                                keyExpr="_ocorrenciaid"
                                parentIdExpr="_parentid"
                                className="tree-list-upf"
                                rootValue={-1}
                                focusedRowEnabled
                                showBorders
                                columnAutoWidth
                                repaintChangesOnly                                                               
                                onSaved={e => {
                                    updateDataTreeList(e.changes);
                                }}
                                onEditorPreparing={e => {
                                    if (e.editorName === "dxCheckBox") {
                                        e.editorOptions.value = e.value === null ? false : e.value
                                    }
                                }}
                                toolbar={{
                                    items: [saveButtonSettings, revertButtonSettings],
                                    visible: gridEditMode
                                }}
                            >
                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />
                                <Scrolling 
                                //mode="standard" 
                                //rowRenderingMode="virtual" 
                                rowRenderingMode='infinity'
                                showScrollbar='always'
                                useNative={true}/>
                                <Pager 
                                    visible
                                    showPageSizeSelector
                                    allowedPageSizes={[25, 50, 100, 500]}
                                    showInfo
                                />
                                <Editing                            
                                    allowUpdating={gridEditMode}                            
                                    mode="batch"                            
                                    useIcons
                                />

                                {detailEntityStructure && detailEntityStructure.map((obj, i) => {
                                    const properties = getColumnProperties({
                                        obj: obj,
                                        dataLookup: props.dataLookup,
                                        setDataEntity: props.setDataEntity,
                                    });
                                    return <Column {...properties} />
                                })}
                            </TreeList>
                        </ScrollView>
                    </div>
                </TabItem>
            </TabPanel>
        </div>
    );
}

export default PerfilOcorrenciaCustomForm;