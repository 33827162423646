import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import {useState, useEffect, useRef} from 'react'
import JoditEditor from "jodit-react";
import "./style.css"

const UpfHtmlEditor = (props) => {
  const [chamouOnValueChanged, setChamouOnValueChanged] = useState(false)

  const _htmlEditorRef = useRef(null)

	const configShowEditBar = {
		readonly: props.disabled,
    language: "pt_br",
		buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,classSpan,lineHeight,superscript,subscript,file,image,video,spellcheck,cut",
	}

  const configHideEditBar = {
		readonly: props.disabled,
    language: "pt_br",
		toolbar: null,
	}

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

    return(
      <>
      {!props.showEditBar ?
        (
          <>
          {props.dataEntity?.[props.dataField] === "" || props.dataEntity?.[props.dataField] === null ? 
            (
              <HtmlEditor
                onValueChanged={(item) => {
                  if(props.modeEditOn && chamouOnValueChanged){
                    props.modeEditOn()
                  }
                  if(props.referencia){
                    props.referencia.beginUpdate()
                    props.referencia.cellValue(props.cellData.rowIndex, `${props.dataField}`, item?.value)
                    props.referencia.endUpdate()
                  }
                  else if(props.dataField){
                    props.dataEntity[`${props.dataField}`] = item?.value
                  }
                  setChamouOnValueChanged(true)
                }}
                readOnly={props.disabled}
                // disabled={}
                // height={!props.showEditBar && 300}
              >
                <MediaResizing enabled={true} />
              </HtmlEditor>
            ) 
            : 
            (
              <HtmlEditor
                onInitialized={e => {
                  const editor = e.component;
                  const Link = editor.get("formats/link")
                  
                  class ClickableLink extends Link {
                    static create(value) {
                      const node = super.create(value);                      
                      node.setAttribute("contenteditable", "false");
                      return node;
                    }
                  }

                  editor.register({"formats/link": ClickableLink })
                }}
                               
                defaultValue={`
                  <html>
                    <body>
                      ${props.dataEntity?.[props.dataField] 
                        ? decodeHtml(props.dataEntity?.[props.dataField])
                        : decodeHtml(props.dataEntity)}
                    </body>
                  </html
                `}                
                onValueChanged={(item) => {
                  if(props.modeEditOn && chamouOnValueChanged){
                    props.modeEditOn()
                  }

                  if(props.referencia){
                    props.referencia.beginUpdate()
                    props.referencia.cellValue(props.cellData.rowIndex, `${props.dataField}`, item?.value)
                    props.referencia.endUpdate()
                  }
                  else if(props.dataField){
                    props.dataEntity[`${props.dataField}`] = item?.value
                  }
                  // else{
                  //   // props.dataEntity = item?.value
                  //   // console.log(item?.value)
                  //   if(chamouOnValueChanged){
                  //     //  console.log(props.dataEntityState.__html)
                  //     console.log('essa linha aqui')
                  //     console.log(arrayTableTags)
                  //     if(arrayTableTags.length > 0){
                  //       addTagTableStyles(`<html>\n<body>\n\n${item?.value}</body></html>`)
                  //     }
                    
                  //     // props.dataEntityState.__html = `<html><body>${item?.value}</body></html>`
                  //     // props.dataEntityState.__html = 

                  //   }
                  

                  // }
                  setChamouOnValueChanged(true)
                }}
                readOnly={props.disabled}
                // disabled={}
                // height={!props.showEditBar && 300}
                >
                  <MediaResizing enabled={true} />
              </HtmlEditor>
            )}
          </>
        )
        :
        (
          <>
          {props.dataEntity?.[props.dataField] && props.dataField ? 
          (
            <div className="dx-theme-background-color">
              <JoditEditor                           
                ref={_htmlEditorRef}
                value={props.dataEntity?.[props.dataField]}
                config={props.showEditBar ? configShowEditBar : configHideEditBar}
                tabIndex={1} // tabIndex ou textArea
                onChange={e => {
                  if(props.modeEditOn && chamouOnValueChanged){
                    props.modeEditOn()
                  }
                  if(props.referencia){
                    props.referencia.beginUpdate()
                    props.referencia.cellValue(props.cellData.rowIndex, `${props.dataField}`, e)
                    props.referencia.endUpdate()
                  }
                  else if(props.dataField){
                    props.dataEntity[`${props.dataField}`] = e
                  }
                  else{ 
                    props.dataEntity.__html = `<html><body>${e}</body></html>`
                  }
                  setChamouOnValueChanged(true)
                }}
              />
            </div>
          )
          :
          (
            <div className="dx-theme-background-color">
              <JoditEditor              
                ref={_htmlEditorRef}
                value={props.dataEntityState ? props.dataEntityState?.__html : ""}
                config={props.showEditBar ? configShowEditBar : configHideEditBar}
                tabIndex={1} // tabIndex ou textArea
                onChange={e => {
                  if(props.modeEditOn){
                    props.modeEditOn()
                  }
                  if(props.referencia){
                    props.referencia.beginUpdate()
                    props.referencia.cellValue(props.cellData.rowIndex, `${props.dataField}`, e)
                    props.referencia.endUpdate()
                  }
                  else if(props.dataField){
                    props.dataEntity[`${props.dataField}`] = e
                  }
                  else{ 
                    props.dataEntityState.__html = `<html><body>${e}</body></html>`
                  }

                }}
              />
            </div>
          )
        }
          </>
        )
      }
      </>
    )
}

export default UpfHtmlEditor;