const { createContext, useContext, useState } = require("react");

const TelecobTabPanelItemsGlobalState = createContext();

export const TelecobTabPanelItemsGlobalStateProvider = ({ children }) => {
  const [telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState] = useState([]);

  return (
    <TelecobTabPanelItemsGlobalState.Provider value={{ telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState }}>
      {children}
    </TelecobTabPanelItemsGlobalState.Provider>
  );
};

export const useTelecobTabPanelItemsGlobalState = () => useContext(TelecobTabPanelItemsGlobalState);