import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import ForeignFieldEdit from '../../Components/ForeignFieldEdit';
import { useState, useEffect, memo } from 'react';
import GetGroupsComponents from "./GetGroupsComponents"
import GetFormFields from "./GetFormFields"
import { repaintJsonStructureConditionalRender } from '../repaintJsonStructureConditionalRender';
import { validatePermission } from '../validatePermission';

const GetFormComponents = (props) => {
    const [FormComponents, SetFormComponents] = useState()

    useEffect(() => {
        props.formComponents.sort((a, b) => { return a?.tabOrder - b?.tabOrder})
        SetFormComponents(false)
    }, [props.dataEntity, props.dataLookup, props.modeEditForm, props.setDataEntityEditedFields, props.dataEntityEditedFields])


    function groupIsTabVisible(component) {
        
        if(component?.fieldsDefs?.length > 0){
            let arrayVisiveis = []
            for(let c = 0; c <= component?.fieldsDefs.length - 1; c++){
                const fieldPermissions = validatePermission(component?.fieldsDefs[c].objectid)
                if(fieldPermissions?._show === 1 && component?.fieldsDefs[c].formVisible === 1){
                    arrayVisiveis.push(component?.fieldsDefs[c])
                } 
            }

            if(arrayVisiveis.length === 0){
                return false;    
            }
            else{
                return component.formVisible;
            }
        }
        else if(component?.recordFormComponents?.length > 0){
            return groupIsTabVisible(component?.recordFormComponents)
        }
    }


    
    const GetFunctionGroups = (recordFormComponentsItem) => {
        if(recordFormComponentsItem.recordFormComponents === null && recordFormComponentsItem.fieldsDefs) {

            return(
                <GetFormFields 
                    id={props.id}
                    fieldsDefs={recordFormComponentsItem.fieldsDefs}
                    resetFormData={props.resetFormData}
                    modeEditForm={props.modeEditForm}
                    entityStructure={props.entityStructure}
                    colCount={recordFormComponentsItem.colCount}
                    //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                    setModeEditForm={props.setModeEditForm}
                    setFormDataJson={props.setFormDataJson}
                    setDataEntity={props.setDataEntity}
                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                    setQueryFormEntityName={props.setQueryFormEntityName}
                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                    modeEditOn={props.modeEditOn}
                    queryFormPopupState={props.queryFormPopupState}
                    setQueryFormPopupState={props.setQueryFormPopupState}
                    dataEntityDetailEntity={props.dataEntityDetailEntity}
                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                    senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                    detailEntity={props.detailEntity}
                    popupRecordFormOpen={props.popupRecordFormOpen}
                    masterDetailEntity={props.masterDetailEntity}
                    setCondicionalVisibleState={props.setCondicionalVisibleState}
                    setStates={props.setStates}
                    idValue={props.idValue}
                    setDataEntityEditedFields={props.setDataEntityEditedFields}
                    dataEntityEditedFields={props.dataEntityEditedFields}
                />
            )
        }
        else {
            return (
                <GetGroupsComponents
                    resetFormData={props.resetFormData}
                    id={props.id}
                    formComponents={recordFormComponentsItem.recordFormComponents}
                    objectPermissionsState={props.objectPermissionsState}
                    dataLookup={props.dataLookup}
                    dataEntity={props.dataEntity}
                    modeEditOn={props.modeEditOn}
                    recordInsertUpdateJson={props.recordInsertUpdateJson}
                    colCount={recordFormComponentsItem.colCount}
                    entityStructure={props.entityStructure}
                    modeEditForm={props.modeEditForm}
                    //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                    setModeEditForm={props.setModeEditForm}
                    setFormDataJson={props.setFormDataJson}
                    setDataEntity={props.setDataEntity}
                    setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                    setQueryFormEntityName={props.setQueryFormEntityName}
                    setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                    queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                    foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                    queryFormPopupState={props.queryFormPopupState}
                    setQueryFormPopupState={props.setQueryFormPopupState}
                    dataEntityDetailEntity={props.dataEntityDetailEntity}
                    setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                    senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                    detailEntity={props.detailEntity}
                    popupRecordFormOpen={props.popupRecordFormOpen}
                    masterDetailEntity={props.masterDetailEntity}
                    setCondicionalVisibleState={props.setCondicionalVisibleState}
                    setStates={props.setStates}
                    idValue={props.idValue}
                    setDataEntityEditedFields={props.setDataEntityEditedFields}
                    dataEntityEditedFields={props.dataEntityEditedFields}
                />
            )
        }
    }

    if (!FormComponents){        
        if(props.entityStructure?.[0]?.recordFormProperties?.[0]?.recordFormComponents){
            // console.log(props.repaintState)
            // props.repaintState.teste = true
            repaintJsonStructureConditionalRender(props.entityStructure, props.setStates, props.setCondicionalVisibleState, props.dataEntity)
        }  
        SetFormComponents(
        // return ( 
            <form onSubimit={props.recordInsertUpdateJson}>
                <Form 
                    ref={props.resetFormData}
                    colCount={props.colCount}
                    formData={props.dataEntity}
                    onFieldDataChanged={props.modeEditOn}
                    validationGroup={`groupName${props.id}`}
                    // labelLocation="top"
                >
                    {props.formComponents.map((recordFormComponentsItem, indice) => 
                    {
                        if(recordFormComponentsItem.componentType === "groupItem"){
                            return(
                                <GroupItem 
                                    visible={recordFormComponentsItem.formVisible}
                                    caption={recordFormComponentsItem.caption}
                                    // colCount={recordFormComponentsItem.colCount}
                                    colSpan={recordFormComponentsItem.colSpan}
                                    // cssClass={'group-item'}
                                    cssClass={'group-item'}
                                    itemType={'group'}
                                    showClearButton={true}
                                    icon={'folder'}
                                    key={indice}
                                >
                                    {recordFormComponentsItem.recordFormComponents !== null && 
                                        <GetGroupsComponents
                                            resetFormData={props.resetFormData}
                                            id={props.id}
                                            entityStructure={props.entityStructure}
                                            formComponents={recordFormComponentsItem.recordFormComponents}
                                            objectPermissionsState={props.objectPermissionsState}
                                            dataLookup={props.dataLookup}
                                            dataEntity={props.dataEntity} 
                                            modeEditOn={props.modeEditOn}
                                            recordInsertUpdateJson={props.recordInsertUpdateJson}
                                            colCount={recordFormComponentsItem.colCount}
                                            // groupItemColSpan={recordFormComponentsItem.colSpan}
                                            // groupItemColCount={recordFormComponentsItem.colCount}
                                            modeEditForm={props.modeEditForm}
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={props.setModeEditForm}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                            setQueryFormEntityName={props.setQueryFormEntityName}
                                            setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                            queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                            queryFormPopupState={props.queryFormPopupState}
                                            setQueryFormPopupState={props.setQueryFormPopupState}
                                            dataEntityDetailEntity={props.dataEntityDetailEntity}
                                            setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                            senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                            detailEntity={props.detailEntity}
                                            popupRecordFormOpen={props.popupRecordFormOpen}
                                            masterDetailEntity={props.masterDetailEntity}
                                            setCondicionalVisibleState={props.setCondicionalVisibleState}
                                            setStates={props.setStates}
                                            idValue={props.idValue}
                                            setDataEntityEditedFields={props.setDataEntityEditedFields}
                                            dataEntityEditedFields={props.dataEntityEditedFields}
                                        />
                                    }

                                    {recordFormComponentsItem.fieldsDefs !== null &&
                                        <GetFormFields
                                            entityStructure={props.entityStructure}
                                            resetFormData={props.resetFormData}
                                            id={props.id}
                                            fieldsDefs={recordFormComponentsItem.fieldsDefs}
                                            dataLookup={props.dataLookup}
                                            dataEntity={props.dataEntity} 
                                            modeEditOn={props.modeEditOn}
                                            recordInsertUpdateJson={props.recordInsertUpdateJson}
                                            colCount={recordFormComponentsItem.colCount}
                                            // groupItemColSpan={recordFormComponentsItem.colSpan}
                                            // groupItemColCount={recordFormComponentsItem.colCount}
                                            modeEditForm={props.modeEditForm}
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={props.setModeEditForm}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                            setQueryFormEntityName={props.setQueryFormEntityName}
                                            setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                            queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                            queryFormPopupState={props.queryFormPopupState}
                                            setQueryFormPopupState={props.setQueryFormPopupState}
                                            dataEntityDetailEntity={props.dataEntityDetailEntity}
                                            setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                            senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                            detailEntity={props.detailEntity}
                                            popupRecordFormOpen={props.popupRecordFormOpen}
                                            masterDetailEntity={props.masterDetailEntity}
                                            setCondicionalVisibleState={props.setCondicionalVisibleState}
                                            setStates={props.setStates}
                                            idValue={props.idValue}
                                            setDataEntityEditedFields={props.setDataEntityEditedFields}
                                            dataEntityEditedFields={props.dataEntityEditedFields}
                                    />
                                    }
                                </GroupItem>
                            )
                        }
                        //PAINEL PRINCIPAL
                        else if(recordFormComponentsItem.componentType === "tab")
                        {
                            if(recordFormComponentsItem.recordFormComponents){
                                return(
                                    <TabbedItem 
                                        key={indice}
                                        caption={recordFormComponentsItem.caption} 
                                        colCount={recordFormComponentsItem.colCount}
                                        colSpan={recordFormComponentsItem.colSpan}
                                        cssClass={'group-item'}
                                        // itemType={'group'}
                                        showClearButton={true}
                                        // icon={'folder'}
                                        >
                                        {/* <TabPanelOptions deferRendering={false} /> */}
                                        {recordFormComponentsItem.recordFormComponents.map((tabItem) => {
                                            const gruposVisiveisArray = []

                                            for(let c = 0; c <= tabItem.recordFormComponents?.length - 1; c++){
                                                if(groupIsTabVisible(tabItem?.recordFormComponents[c])){
                                                    gruposVisiveisArray.push(tabItem?.recordFormComponents[c])
                                                }
                                            }
                                            if(gruposVisiveisArray.length > 0){
                                                return(
                                                    <Tab title={tabItem.caption}>
                                                        {GetFunctionGroups(tabItem)}
                                                    </Tab>
                                                )
                                            }
                                        })}

                                    {recordFormComponentsItem.fieldsDefs !== null &&
                                        <GetFormFields
                                            resetFormData={props.resetFormData}
                                            id={props.id}
                                            entityStructure={props.entityStructure}
                                            fieldsDefs={recordFormComponentsItem.fieldsDefs}
                                            dataLookup={props.dataLookup}
                                            dataEntity={props.dataEntity}
                                            modeEditOn={props.modeEditOn}
                                            recordInsertUpdateJson={props.recordInsertUpdateJson}
                                            colCount={recordFormComponentsItem.colCount}
                                            // groupItemColSpan={recordFormComponentsItem.colSpan}
                                            // groupItemColCount={recordFormComponentsItem.colCount}
                                            modeEditForm={props.modeEditForm}
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={props.setModeEditForm}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            setPopupQueryFormOpen={props.setPopupQueryFormOpen}
                                            setQueryFormEntityName={props.setQueryFormEntityName}
                                            setQueryFormPopupForeignFieldState={props.setQueryFormPopupForeignFieldState}
                                            queryFormPopupForeignFieldState={props.queryFormPopupForeignFieldState}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            foreignFieldSelectedItem={props.foreignFieldSelectedItem}
                                            queryFormPopupState={props.queryFormPopupState}
                                            setQueryFormPopupState={props.setQueryFormPopupState}
                                            dataEntityDetailEntity={props.dataEntityDetailEntity}
                                            setForeignFieldSelectedItem={props.setForeignFieldSelectedItem}
                                            senderFieldNamesCheckBox={props.senderFieldNamesCheckBox}
                                            detailEntity={props.detailEntity}
                                            popupRecordFormOpen={props.popupRecordFormOpen}
                                            masterDetailEntity={props.masterDetailEntity}
                                            setCondicionalVisibleState={props.setCondicionalVisibleState}
                                            setStates={props.setStates}
                                            idValue={props.idValue}
                                            setDataEntityEditedFields={props.setDataEntityEditedFields}
                                            dataEntityEditedFields={props.dataEntityEditedFields}
                                    />
                                    }
                                    </TabbedItem>
                                )
                            }
                            else {
                                return(
                                    <GroupItem 
                                        visible={recordFormComponentsItem.formVisible}
                                        caption="TabPanel criado sem tabs"
                                        colCount={recordFormComponentsItem.colCount}
                                        colSpan={props.colCount}
                                        // cssClass={'group-item'}
                                        // cssClass={'group-item'}
                                        itemType={'group'}
                                        showClearButton={true}
                                        icon={'folder'}
                                        key={indice}
                                    />
                                )
                            } 
                        }

                        
                        else {
                            return(
                                <div>entrou no else</div>
                            )
                        }   
                    }
            )}
                </Form>
        </form>
        )
    }
    return FormComponents
}

export default GetFormComponents;