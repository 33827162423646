export const httpRequest = (method, url, authState, bodyRequest, pararCronometroRequest, setConnectionErrorOccurredGlobalState, indice)=>{
    let relationId = JSON.parse(sessionStorage.getItem("MenuDataSource"))
    const isJson = (data) => {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
    
        return true;
    }

    const tentarNovamente = async (xhr, reject, response, resolve) => {
        let resposta = isJson(response) ? JSON.parse(response) : response

        if((!indice || indice <= 8) && !resposta){

            await httpRequest(
                method
                ,url
                ,authState
                ,bodyRequest
                ,pararCronometroRequest
                ,setConnectionErrorOccurredGlobalState
                ,!indice ? 1 : indice + 1
            )
            .then((e) => {
                resposta = isJson(e) ? JSON.parse(e) : e
                if(resposta && !resposta?.error){
                    resolve(JSON.stringify(resposta))
                }
                else{
                    indice++
                }

            })
            .catch((err) => {
                indice++

                reject({
                    statusText: err?.data ? err.data : xhr.statusText
                    ,data: err.data
                })
            })
            // }
        }
        else{
            if(resposta && !resposta?.error){
                resolve(JSON.stringify(resposta))
            }
            else if((!resposta || resposta?.error)){
                reject({
                    statusText: resposta?.error ? resposta.error : resposta
                    ,data: resposta
                })
                if(setConnectionErrorOccurredGlobalState && !resposta?.error){
                    //falha na conexão
                    setConnectionErrorOccurredGlobalState(true)
                }
            }
        }
    }

    if(relationId?.[0]?.relationId.length > 0 && bodyRequest instanceof Object && !bodyRequest?.relationId){
        bodyRequest["relationId"] = relationId?.[0]?.relationId
    }
    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200 && pararCronometroRequest) {
            
            var RequestTime = this.getResponseHeader("RequestTime");
            var ResponseTime = this.getResponseHeader("ResponseTime");
            

            RequestTime = RequestTime.substring(11)

            RequestTime = RequestTime.slice(0, 8)



            ResponseTime = ResponseTime.substring(11)

            ResponseTime = ResponseTime.slice(0, 8)

            let ResponseH = parseInt(ResponseTime.slice(0, 2))
            let ResponseM = parseInt(ResponseTime.slice(3, 5))
            let ResponseS = parseInt(ResponseTime.slice(6, 8))

            let RequestH = parseInt(RequestTime.slice(0, 2))
            let RequestM = parseInt(RequestTime.slice(3, 5))
            let RequestS = parseInt(RequestTime.slice(6, 8))



            if(ResponseS < RequestS){
                ResponseM = ResponseM - 1
                ResponseS = ResponseS + 60
            }
            if(ResponseM < RequestM){
                ResponseH = ResponseH - 1
                ResponseM = ResponseM + 60
            }

           

            const objDate = {
                segundos: ResponseS - RequestS,
                minutos: ResponseM - RequestM,
                horas: ResponseH - RequestH
            }


            pararCronometroRequest(objDate)

          }
        };


        xhr.open(method, url);
        xhr.timeout = 600000;
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-Type", "application/json");
        if(sessionStorage.getItem("token")){
            const token = sessionStorage.getItem("token")
            xhr.setRequestHeader("token", token);
        }
        //xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
        if(authState){
            xhr.setRequestHeader("Authorization", "Basic " + btoa(`${authState}`));
        }
        xhr.onload = function () {

        if(this.status == 204){
            this["length"] = 0
            if(xhr.response){
                resolve(xhr.response);
            }
            console.log(this)
            console.log(xhr?.response)
        }
        else if (this.status >= 200 && this.status < 300) {
            if(!xhr.response){
                console.log("this.status >= 200 && this.status < 300")
                tentarNovamente(xhr, reject, this.response, resolve)
            }
            else{
                resolve(xhr.response);
            }
        }
        //status diferente de 200
        else{
            console.log("status diferente de 200")
            tentarNovamente(xhr, reject, this.response, resolve)
        }
        };
        xhr.onerror = function () {
            console.log("onerror")
            tentarNovamente(xhr, reject, this.response, resolve)
        };
        xhr.onabort = function () {
            console.log("onabort")
            tentarNovamente(xhr, reject, this.response, resolve)
        }
        xhr.ontimeout = function  () {
            console.log("ontimeout")
            tentarNovamente(xhr, reject, this.response, resolve)
        }

        xhr.send(JSON.stringify(bodyRequest));

    });
}
