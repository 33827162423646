import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useRef } from 'react'
import $ from "jquery"

const UpfPopupMessage = (props) => {
    const closeButtonPopup = {
        text: 'ok'
        , icon: 'arrowright'
        , onClick: props.hidePopup
    };

    const _testeRef = useRef(null)

    return (
        <Popup
            ref={_testeRef}
            visible={props.visible}
            onHiding={props.hidePopup}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={props.title ? props.title : "Atenção!"}
            width={600}
            height={310}
            resizeEnabled={true}
            // wrapperAttr={popupAttributes}
            // onInitialized={(e) => {
            //     $(e.element).addClass('UpfPopupMessage')
            // }}
            onShown={(e) => {
                setTimeout(() => {
                    _testeRef.current.instance.content().parentElement.addEventListener('keypress', (e) => {
                        if (e.key === 'Enter') {
                            _testeRef.current.instance.content().parentElement.getElementsByClassName("dx-button-content")[0].click()
                            // teste123.click() dx-item dx-toolbar-item dx-toolbar-button
                        }
                    })
                }, 0)
            }
            }
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                className="teste234234234"
                options={closeButtonPopup}
            />
            <>
            {props.message || props.messageDashboard ?
                (
                    <>
                        {props.message &&
                            <p>{props.message}</p>
                        }
                        {
                            props.messageDashboard && ( props.messageDashboard )
                        }
                    </>
                )
                :
                (
                    <b>Ocorreu um erro! Tente novamente mais tarde!</b>
                )
            }
            </>
            
        </Popup>
    )
}

export default UpfPopupMessage;