import { 
    Popup, 
    ToolbarItem,
  } from 'devextreme-react/popup';
import { GoDesktopDownload } from 'react-icons/go';
import Button from 'devextreme-react/button';
import { revolutionVersion } from '../../api/ApplicationID';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Toast } from 'devextreme-react/toast';
import { useBackendJson } from '../../Context/BackendJson';
import './style.css'

const UpfPopupAtualizacoes = (props) => {
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const {backendJson, setBackendJson} = useBackendJson()

    const [ultimaVersaoBackEndState, setUltimaVersaoBackEndState] = useState()
    const [ultimaVersaoFrontEndState, setUltimaVersaoFrontEndState] = useState()

    const [atualizarVersaoBackEndDisabledButtonState, setAtualizarVersaoBackEndDisabledButtonState] = useState(false)
    const [atualizarVersaoFrontEndDisabledButtonState, setAtualizarVersaoFrontEndDisabledButtonState] = useState(false)
    const [atualizarVersaBancoFrontEndDisabledButtonState, setAtualizarVersaoBancoDisabledButtonState] = useState(false)

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    useEffect(() => {

        httpRequest("GET", `https://root.hotfysolutions.com.br:12101/rootService/newVersion/api`)
        .then((sender) => {
            const ultimaVersaoJson = JSON.parse(sender)
            setUltimaVersaoBackEndState(ultimaVersaoJson?.version)
        })
        .catch((error) => {
            console.log(error)
        })

        httpRequest("GET", "https://root.hotfysolutions.com.br:12101/rootService/newVersion/front")
        .then((sender) => {
            const ultimaVersaoJson = JSON.parse(sender)
            setUltimaVersaoFrontEndState(ultimaVersaoJson?.version)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])

    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])

    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })

    //Funções
    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        })
    }, [toastConfig])

    const [popupConfig, setPopupConfig] = useState({
        isVisible: false
        , type: ""
        , message: ""
    })

    const onHiding = useCallback(() => {
        setPopupConfig({
            ...popupConfig,
            isVisible: false
        });
    }, [popupConfig])

    const closeButtonOptions = {
        text: "Ok",
        onClick: onHiding
    };

    const atualizarVersaoBanco = () => {
        setAtualizarVersaoFrontEndDisabledButtonState(true)
        showLoadPanel()
        console.log("atualizandoVersaoBanco")
        httpRequest("GET", requestsApi.updateVersionDatabase, requestsApi.basicAuth)
        .then((sender) => {
            const senderUpdateVersionFromJson = JSON.parse(sender)
            toastConfiguration(true, 'success', senderUpdateVersionFromJson.mensagem)
            hideLoadPanel()
            setAtualizarVersaoBancoDisabledButtonState(false)
        })
        .catch((error) => {
            if(error?.data?.error){
                setPopupConfig({
                    isVisible: true
                    , message: error?.data?.error
                    , type: "Atenção!"
                })
            }
            else{
                setPopupConfig({
                    isVisible: true
                    , message: "Ocorreu um erro! Tente novamente mais tarde!"
                    , type: "Atenção!"
                })
            }
            hideLoadPanel()
            setAtualizarVersaoFrontEndDisabledButtonState(false)
        })
    }

    const atualizarVersaoFrontEnd = () => {
        setAtualizarVersaoFrontEndDisabledButtonState(true)
        showLoadPanel()
        console.log("atualizandoVersaoFrontEnd")
        httpRequest("GET", requestsApi.updateVersionFrontend, requestsApi.basicAuth)
        .then((sender) => {
            const senderUpdateVersionFromJson = JSON.parse(sender) 
            toastConfiguration(true, 'success', senderUpdateVersionFromJson.mensagem)
            hideLoadPanel()
            setAtualizarVersaoFrontEndDisabledButtonState(false)
        })
        .catch((error) => {
            if(error?.data?.error){
                setPopupConfig({
                    isVisible: true
                    , message: error?.data?.error
                    , type: "Atenção!"
                })
            }
            else{
                setPopupConfig({
                    isVisible: true
                    , message: "Ocorreu um erro! Tente novamente mais tarde!"
                    , type: "Atenção!"
                })
            }
            hideLoadPanel()
            setAtualizarVersaoFrontEndDisabledButtonState(false)
        })
    }

    const atualizarVersaoBackEnd = () => {
        setAtualizarVersaoBackEndDisabledButtonState(true)
        showLoadPanel()
        console.log("atualizandoVersaoBackEnd")
        httpRequest("GET", requestsApi.updateVersionBackend, requestsApi.basicAuth)
        .then((sender) => {
            const senderUpdateVersionFromJson = JSON.parse(sender)
            toastConfiguration(true, 'success', senderUpdateVersionFromJson.mensagem)
            hideLoadPanel()
            setAtualizarVersaoBackEndDisabledButtonState(false)

        })
        .catch((error) => {
            if(error?.data?.error){
                setPopupConfig({
                    isVisible: true
                    , message: error?.data?.error
                    , type: "Atenção!"
                })
            }
            else{
                setPopupConfig({
                    isVisible: true
                    , message: "Ocorreu um erro! Tente novamente mais tarde!"
                    , type: "Atenção!"
                })
            }
            hideLoadPanel()
            setAtualizarVersaoBackEndDisabledButtonState(false)
        })
    }


    return(
        <Popup
              visible={props.visible}
              onHiding={()=>{props.setVisible(false)}}
              dragEnabled={false}
              hideOnOutsideClick={true}
              showCloseButton={true}
              showTitle={true}
              title={"Informações"}
              width={600}
              height={310}
            //   fullScreen
              resizeEnabled={true}
            //   id="popupForm"
              >     
                {/* <ToolbarItem
                  widget="dxButton"
                  toolbar="bottom"
                  location="after"                  
                  options={confirmButtonSelectedTheme}
                /> */}
                <LoadPanel
                    style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={loadingPanelState.loadPanelVisible}
                    // visible={true}
                    showIndicator={loadingPanelState.showIndicator}
                    shading={loadingPanelState.shading}
                    showPane={loadingPanelState.showPane}
                />

                <Toast
                    visible={toastConfig.isVisible}
                    message={toastConfig.message}
                    type={toastConfig.type}
                    onHiding={onHidingToast}
                    displayTime={3000}
                    height={60}
                />
                <Popup
                    visible={popupConfig.isVisible}
                    title={popupConfig.type}
                    width={300}
                    height={300}
                    onHiding={onHiding}
                >
                    <h5>{popupConfig.message}</h5>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonOptions}
                    />
                </Popup>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "2em"}}>
                    <div className="applicationInfos">

                        <div className="applicationInfosDisplay">
                            <div>Banco de Dados: </div>
                        </div>
                        <div className="applicationInfosDisplay">
                            <div>Versão API:</div> 
                        </div>
                        <div className="applicationInfosDisplay">
                            <div>Versão Techfy:</div> 
                        </div>
                        {/* <div className="applicationInfosDisplay">
                            <div>Versão Dashboard:</div> 
                        </div> */}
                    </div>

                    <div className="applicationInfos">

                        <div className="applicationInfosDisplay">{props.dataSourceLogin?.dataBase}</div>

                        <div className="applicationInfosDisplay">
                            <div style={{width: "70px"}}>{props.dataSourceLogin?.version}</div>
                        </div>

                        <div className="applicationInfosDisplay">
                            <div style={{width: "70px"}}>{revolutionVersion}</div>
                        </div>

                        {/* <div className="applicationInfosDisplay">{props.dataSourceLoginDashboard?.version}</div> */}
                    </div>

					<div className="applicationInfos">
						<div className="applicationInfosDisplay">
                            {window.location.hostname != "upf.crm.revolutioncrm.com.br" &&
                                <Button
                                    disabled={atualizarVersaBancoFrontEndDisabledButtonState}
                                    style={{height: "30px", width: "30px"}}
                                    hint="Atualizar Techfy"
                                    className="button-update-techfy"
                                    onClick={atualizarVersaoBanco}
                                >
                                    <div className="button-update-techfy-icon">
                                        <GoDesktopDownload/>
                                    </div>
                                </Button>
                            }
                        </div>
											
                        <div className="applicationInfosDisplay">
                            {ultimaVersaoBackEndState != props.dataSourceLogin?.version &&
                                <>
                                    <Button
                                        disabled={atualizarVersaoBackEndDisabledButtonState}
                                        style={{height: "30px", width: "30px"}}
                                        className="button-update-techfy"
                                        hint="Atualizar API"
                                        onClick={atualizarVersaoBackEnd}
                                    >
                                        <div className="button-update-techfy-icon">
                                            <GoDesktopDownload/> 
                                        </div>
                                    </Button>
                                    {ultimaVersaoBackEndState}
                                </>
                            }
                        </div>

                        <div className="applicationInfosDisplay">
                            {(ultimaVersaoFrontEndState != revolutionVersion) &&
                                <>
                                    <Button
                                        disabled={atualizarVersaoFrontEndDisabledButtonState}
                                        style={{height: "30px", width: "30px"}}
                                        hint="Atualizar Techfy"
                                        className="button-update-techfy"
                                        onClick={atualizarVersaoFrontEnd}
                                    >
                                        <div className="button-update-techfy-icon">
                                            <GoDesktopDownload/> 
                                        </div>
                                    </Button>
                                    {ultimaVersaoFrontEndState}
                                </>
                            }

                        </div>
					</div>
                </div>
    
          </Popup>
    )
}

export default UpfPopupAtualizacoes;