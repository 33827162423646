import {useEffect, useState, useCallback, memo} from 'react'
import {httpRequest} from "../../../functions"
import {useRequestsApi} from "../../../Context/RequestsApi"
import { applicationID, relationId} from "../../../api/ApplicationID"
import DataGrid, { 
    Column, 
    Selection,
    Editing,
    Summary,
    GroupItem,
    HeaderFilter,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    TotalItem,
    SearchPanel,
    FilterRow   
} from 'devextreme-react/data-grid';
import "./style.css"
import UpfPopupCustomForm from '../../../CardComponents/UpfPopupCustomForm'
import {convertDate} from "../../../functions/convertDate"

const TarefasGrid = (props) => {
    const {requestsApi} = useRequestsApi()
    const [entityStructureState, setEntityStructureState] = useState()
    const [dataEntityState, setDataEntityState] = useState([])
    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null
    })
    const [dataLookup, setDataLookup] = useState();
    const senderFieldNamesCheckBox = []

    useEffect(() => {        
        if(props.records?.length > 0 && !props.entityStructure && !props.idValueMaster) {
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                "entityName": "_tarefa",
                "applicationId": applicationID
            })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)

                setEntityStructureState(resultGetEntityStructureJson[0].queryFormProperties[0].fieldsDefs)

                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(props.records, resultGetEntityStructureJson[0].queryFormProperties[0].fieldsDefs)
                //
                if(resultGetEntityStructureJson[0]["lookUpEntities"]){
                    
                    const sendLookupRequest = {
                        "lookUpEntities": resultGetEntityStructureJson[0].lookUpEntities
                    }

                    httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
                    .then(senderLookup => {
                        const resultLookup = JSON.parse(senderLookup)
                        setDataLookup(resultLookup)
                    })
                    .catch((error)=>{
                        console.log(error)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }

        if(props.entityStructure && props.idValueMaster){
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": "_tarefa",
                "fieldName": "_objectid",
                "expressao": props.idValueMaster,
                "applicationId": applicationID
            })
            .then((sender) => {
                const resultGetDataEntityJson = JSON.parse(sender)
                console.log('recordsconsultados')
                console.log(resultGetDataEntityJson.masterEntity[0].records)    
                console.log('records props')
                console.log(props.records)
                setDataEntityState(resultGetDataEntityJson.masterEntity[0].records)

                if(resultGetDataEntityJson.masterEntity[0].records.length > 0) {
                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                        "entityName": "_tarefa",
                        "applicationId": applicationID
                })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)
        
                    setEntityStructureState(resultGetEntityStructureJson[0].queryFormProperties[0].fieldsDefs)

                    //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                    convertDate(resultGetDataEntityJson.masterEntity[0].records, resultGetEntityStructureJson[0].queryFormProperties[0].fieldsDefs)
                    //

                    if(resultGetEntityStructureJson[0]["lookUpEntities"]){
                        
                        const sendLookupRequest = {
                            "lookUpEntities": resultGetEntityStructureJson[0].lookUpEntities
                        }

                        httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
                        .then(senderLookup => {
                            const resultLookup = JSON.parse(senderLookup)
                            setDataLookup(resultLookup)
                        })
                        .catch((error)=>{
                            console.log(error)
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }, [])

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }

    const createTarefaCustomForm = (idRegistro) => {
        setUpfPopupCustomFormStates({
            visible: true,
            title: "Tarefa",
            formName: "TarefaCustomForm",
            entityName: props.entityName,
            idValue: idRegistro
        })
    }

    return(
        <div className="detail-tabpanel">
            {entityStructureState &&
                <h2>Tarefas</h2>
            }

            {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                />
            }
            {entityStructureState &&
                <DataGrid 
                    keyExpr="id"
                    // className="datagrid-detail-entity"
                    repaintChangesOnly={true}
                    renderAsync={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    dataSource={dataEntityState}
                    // dataSource={props.records}
                    allowColumnReordering={true}
                    showBorders={true}
                    rowAlternationEnabled={true} 
                    focusedRowEnabled={true}
                    onEditorPreparing={(e) => {
                        if(senderFieldNamesCheckBox.find((item) => item === e.dataField)) {
                          e.editorOptions.value = e.value === null ? false : e.value;
                        } 
                    }}
                    columnMinWidth={50}
                    onRowDblClick={(item)=>{
                        if(props.entityStructure[0].masterEntity["customRecordForm"]){
                            //tipos de customRecordForm
                            if(props.entityStructure[0].masterEntity["customRecordForm"] === "tarefaCustomForm"){
                                createTarefaCustomForm(item.data.id)
                            }
                        }
                        else{
                            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                applicationId: applicationID,
                                entityName: "_tarefa"
                            })
                            .then((sender) => {
                                const resultGetEntityStructureJson = JSON.parse(sender)
                
                                props.setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)
                
                                if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                                    })
                                    .then((sender) => {
                                        const senderDataLookup = JSON.parse(sender)
                                        props.setDataLookupRecordFormPopupState(senderDataLookup)
                
                                        props.setIDValueDetailEntity(item.data.id)
                                        props.setDetailEntityName("_tarefa")
                                        props.setDetailCaptionName("Tarefas")
                                        props.setPopupRecordFormOpen(true)
                                        props.setPopupState(true)
                                        props.setPopupRecordFormAdd(false)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                }
                                else{
                                    props.setIDValueDetailEntity(item.data.id)
                                    props.setDetailEntityName("_tarefa")
                                    props.setDetailCaptionName("Tarefas")
                                    props.setPopupRecordFormOpen(true)
                                    props.setPopupState(true)
                                    props.setPopupRecordFormAdd(false)

                                }
                            })
                        }
                    }}
                >
                    <Pager
                        visible={true}
                        allowedPageSizes={[10, 25, 50, 100]}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} 
                    />

                    <Selection 
                        keyExpr="id"
                        mode="multiple"
                    />

                    <HeaderFilter
                        allowSearch={true}
                        visible={true}
                    />

                    <Scrolling 
                        rowRenderingMode='infinity'
                        showScrollbar='always'
                        useNative={true}
                        >
                    </Scrolling>

                    <Paging defaultPageSize={100} />

                    <Sorting
                        mode="multiple"
                    />

                    {entityStructureState.map(
                        (sender, index) => {
                                if (sender.editorType === "dxLookup" ||
                                    sender.editorType === "dxSelectBox") {
                                    if(sender.lookUpKeyField != null && dataLookup){ 
                                        return (
                                                <Column
                                                    key={index}
                                                    fixed={sender.fixed}
                                                    visible={sender.gridVisible === 1}
                                                    groupIndex={sender.groupIndex}
                                                    defaultSortOrder={sender.sortOrder}
                                                    defaultSortIndex={sender.sortIndex}
                                                    showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                    dataField={sender.fieldName}
                                                    allowEditing={sender.readOnly != 1}
                                                    caption={sender.fieldCaption}
                                                    dataType={sender.fieldType}
                                                    // editCellRender={
                                                    // format={sender.fieldMask}
                                                    //width={sender.FieldSize}

                                                    // visible={sender.gridVisible === 1}
                                                    // showInColumnChooser={sender.visibleForCustomization===1}
                                                    // dataField={sender.fieldName}
                                                    // groupIndex={sender.groupIndex}
                                                    // sortOrder={sender.sortOrder}
                                                    // allowEditing={(sender.readOnly != 1)}
                                                    // caption={sender.fieldCaption}
                                                    // dataType={sender.fieldType}
                                                    // format={sender.fieldMask}
                                                    >
                                                        <Lookup
                                                            dataSource ={dataLookup[sender.lookUpListSource]}
                                                            valueExpr={sender.lookUpKeyField}
                                                            displayExpr ={sender.lookUpResultField}
                                                        />
                                                    
                                                </Column>
                                                )
                                        }
                                    }


                                else if(sender.editorType === "dxCheckBox") {
                                    senderFieldNamesCheckBox.push(sender.fieldName)
                                    return(
                                        <Column
                                            key={index}
                                            fixed={sender.fixed}
                                            visible={sender.gridVisible === 1}
                                            groupIndex={sender.groupIndex}
                                            defaultSortOrder={sender.sortOrder}
                                            defaultSortIndex={sender.sortIndex}
                                            showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                            dataField={sender.fieldName}
                                            allowEditing={sender.readOnly != 1}
                                            caption={sender.fieldCaption}
                                            dataType={"boolean"}
                                            // editorOptions={editorOptionsColumns}
                                            // calculateCellValue={calculateCellValue}
                                            >
                                            
                                        </Column>
                                    )
                                }

                                else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "datetime") {
                                    return(
                                        <Column
                                            key={index}
                                            fixed={sender.fixed}
                                            visible={sender.gridVisible === 1}
                                            groupIndex={sender.groupIndex}
                                            defaultSortOrder={sender.sortOrder}
                                            defaultSortIndex={sender.sortIndex}
                                            showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                            dataField={sender.fieldName}
                                            allowEditing={sender.readOnly !== 1}
                                            caption={sender.fieldCaption}
                                            dataType={sender.fieldType}
                                            format="shortDateShortTime"
                                        />
                                        // <UpfForeignColumn
                                        //     sender={sender}
                                        // />
                                    )
                                }
                                else if (sender.editorType === "upfTime") {
                                    return(
                                        <Column
                                            key={index}
                                            fixed={sender.fixed}
                                            visible={sender.gridVisible === 1}
                                            groupIndex={sender.groupIndex}
                                            defaultSortOrder={sender.sortOrder}
                                            defaultSortIndex={sender.sortIndex}
                                            showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                            dataField={sender.fieldName}
                                            allowEditing={sender.readOnly !== 1}
                                            caption={sender.fieldCaption}
                                            dataType={"datetime"}
                                            format="shortTime"
                                        />
                                        // <UpfForeignColumn
                                        //     sender={sender}
                                        // />
                                    )
                                }
                                else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "date") {
                                    return(
                                        <Column
                                            key={index}
                                            fixed={sender.fixed}
                                            visible={sender.gridVisible === 1}
                                            groupIndex={sender.groupIndex}
                                            defaultSortOrder={sender.sortOrder}
                                            defaultSortIndex={sender.sortIndex}
                                            showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                            dataField={sender.fieldName}
                                            allowEditing={sender.readOnly !== 1}
                                            caption={sender.fieldCaption}
                                            dataType={sender.fieldType}
                                            format="shortDate"
                                        />
                                        // <UpfForeignColumn
                                        //     sender={sender}
                                        // />
                                    )
                                }
                            // else if (sender.editorType === "dxColorBox") {  
                            //         return(
                            //             <Column
                            //             visible={sender.gridVisible === 1}
                            //             showInColumnChooser={sender.visibleForCustomization===1}
                            //             key={index}
                            // fixed={sender.fixed}
                            //             dataField={sender.fieldName}
                            //             groupIndex={sender.groupIndex}
                            //             sortOrder={sender.sortOrder}
                            //             allowEditing={(sender.readOnly != 1)}
                            //             caption={sender.fieldCaption}
                            //             dataType={sender.fieldType}
                            //             format={sender.fieldMask}
                            //                 >
                                            
                            //             </Column>
                            //                     )
                            // }
                            else if(sender.fieldType === "money"){
                                return(
                                    <Column
                                        visible={sender.gridVisible === 1}
                                        showInColumnChooser={sender.visibleForCustomization===1}
                                        dataField={sender.fieldName}
                                        groupIndex={sender.groupIndex}
                                        sortOrder={sender.sortOrder}
                                        allowEditing={(sender.readOnly != 1)}
                                        caption={sender.fieldCaption}
                                        // dataType={sender.fieldType}
                                        format={{style: 'currency', currency: 'BRL', useGrouping: true, minimumSignificantDigits: 2}}
                                    />
                                );
                            }

                            else{
                                return(
                                    <Column
                                        visible={sender.gridVisible === 1}
                                        showInColumnChooser={sender.visibleForCustomization===1}
                                        dataField={sender.fieldName}
                                        groupIndex={sender.groupIndex}
                                        sortOrder={sender.sortOrder}
                                        allowEditing={(sender.readOnly != 1)}
                                        caption={sender.fieldCaption}
                                        dataType={sender.fieldType}
                                        format={sender.fieldMask}
                                    />
                                );
                            }
                            // }
                        
                        }
                    )
                }

                </DataGrid>
            }
        </div>
    )
}

export default TarefasGrid;