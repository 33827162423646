import { TabPanelItemsProvider } from './TabPanelItems'; //componentes da Tab Panel principal
import { IndexTabPanelMainProvider } from './IndexTabPanelMain'; //indice da Tab Panel principal
import { StatePopupTabPanelProvider } from './StatePopupTabPanel';
import  { PopupViewProvider } from './PopupView';
import { PopupDragAndDropProvider } from './PopupDragAndDrop';
import {JsonHestiaLogProcessamentoProvider} from './JsonHestiaLogProcessamento'
import {RequestsApiProvider} from './RequestsApi'
import {BackendJsonProvider} from './BackendJson'
import {MainMenuJsonProvider} from './MainMenuJson'
import {ProximaFichaDadosProvider} from './ProximaFichaDados'
import {WidgetsTeleCobProvider} from  "./WidgetsTeleCob"
import {IndexMultiViewItemsGerarAcordoProvider} from "./IndexMultiViewItemsGerarAcordo"
import {IndexMultiViewItemsGerarBoletoProvider} from "./IndexMultiViewItemsGerarBoleto"
import {GerarAcordoDataSourceProvider} from "./GerarAcordoDataSource"
import {GerarBoletoDataSourceProvider} from "./GerarBoletoDataSource"
import {PausasDisponiveisGlobalStateProvider} from "./PausasDisponiveisGlobalState"
import {DiscadorEmAtendimentoGlobalStateProvider} from "./DiscadorEmAtendimentoGlobalState"
import { PausaEscolhidaDadosGlobalStateProvider } from './PausaEscolhidaDadosGlobalState';
import { AbrirPausaResultJsonGlobalStateProvider } from './AbrirPausaResultJsonGlobalState';
import { LoadPanelVisibleGlobalStateProvider } from "./LoadPanelVisibleGlobalState"
import { ConnectionErrorOccurredGlobalStateProvider } from './ConnectionErrorOccurredGlobalState';
import { UpfFloatPopupMessageVisibleGlobalStateProvider } from './UpfFloatPopupMessageVisibleGlobalState'
import { StatusDiscadorGlobalStateProvider } from './StatusDiscadorGlobalState';
import { DataSourceLastMenssageSocketGlobalStateProvider } from './DataSourceLastMenssageSocketGlobalState';
import { FichaPesquisadaNaTelaGlobalStateProvider } from './FichaPesquisadaNaTelaGlobalState';
import { TelecobTabPanelItemsGlobalStateProvider } from './TelecobTabPanelItemsGlobalState';
import { TelecobIndexTabPanelGlobalStateProvider } from './TelecobIndexTabPanelGlobalState';
import { WhatsAppChatUptadeGlobalStateProvider } from './WhatsAppChatUptadeGlobalState';

const Providers = ({children}) => {
    return(
        <> 
            <RequestsApiProvider>
                <ConnectionErrorOccurredGlobalStateProvider>
                    <BackendJsonProvider>
                        <JsonHestiaLogProcessamentoProvider>
                            <LoadPanelVisibleGlobalStateProvider>
                                <StatusDiscadorGlobalStateProvider>
                                    <DataSourceLastMenssageSocketGlobalStateProvider>
                                        <FichaPesquisadaNaTelaGlobalStateProvider>
                                            <UpfFloatPopupMessageVisibleGlobalStateProvider>
                                                <ProximaFichaDadosProvider>
                                                    <WidgetsTeleCobProvider>
                                                        <WhatsAppChatUptadeGlobalStateProvider>
                                                            <TelecobTabPanelItemsGlobalStateProvider>
                                                                <TelecobIndexTabPanelGlobalStateProvider>
                                                                    <TabPanelItemsProvider>
                                                                        <IndexTabPanelMainProvider>
                                                                            <MainMenuJsonProvider>
                                                                                <PausasDisponiveisGlobalStateProvider>
                                                                                    <DiscadorEmAtendimentoGlobalStateProvider>
                                                                                        <PausaEscolhidaDadosGlobalStateProvider>
                                                                                            <AbrirPausaResultJsonGlobalStateProvider>
                                                                                                <GerarAcordoDataSourceProvider>
                                                                                                    <IndexMultiViewItemsGerarAcordoProvider>
                                                                                                        <GerarBoletoDataSourceProvider>
                                                                                                            <IndexMultiViewItemsGerarBoletoProvider>
                                                                                                                <PopupDragAndDropProvider>
                                                                                                                    <StatePopupTabPanelProvider>
                                                                                                                        <PopupViewProvider>
                                                                                                                            {children}
                                                                                                                        </PopupViewProvider>
                                                                                                                    </StatePopupTabPanelProvider>
                                                                                                                </PopupDragAndDropProvider>
                                                                                                            </IndexMultiViewItemsGerarBoletoProvider>
                                                                                                        </GerarBoletoDataSourceProvider>
                                                                                                    </IndexMultiViewItemsGerarAcordoProvider>
                                                                                                </GerarAcordoDataSourceProvider>
                                                                                            </AbrirPausaResultJsonGlobalStateProvider>
                                                                                        </PausaEscolhidaDadosGlobalStateProvider>
                                                                                    </DiscadorEmAtendimentoGlobalStateProvider>
                                                                                </PausasDisponiveisGlobalStateProvider>
                                                                            </MainMenuJsonProvider>
                                                                        </IndexTabPanelMainProvider>
                                                                    </TabPanelItemsProvider>
                                                                </TelecobIndexTabPanelGlobalStateProvider>
                                                            </TelecobTabPanelItemsGlobalStateProvider>
                                                        </WhatsAppChatUptadeGlobalStateProvider>
                                                    </WidgetsTeleCobProvider>
                                                </ProximaFichaDadosProvider>
                                            </UpfFloatPopupMessageVisibleGlobalStateProvider>  
                                        </FichaPesquisadaNaTelaGlobalStateProvider>  
                                    </DataSourceLastMenssageSocketGlobalStateProvider>    
                                </StatusDiscadorGlobalStateProvider>                 
                            </LoadPanelVisibleGlobalStateProvider>
                        </JsonHestiaLogProcessamentoProvider>
                    </BackendJsonProvider>
                </ConnectionErrorOccurredGlobalStateProvider>
            </RequestsApiProvider>
        </>
    )
}

export default Providers;