import React, { useEffect, useRef, useState, useCallback } from "react";
import { FileUploader, Popup, Toolbar } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";
import DataGrid, { Lookup, Column, Selection } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/file-manager";
import { Toast } from 'devextreme-react/toast';
import ScrollView from 'devextreme-react/scroll-view';
import { httpRequest } from "../../../functions";
import { fileTypes } from "./mimeTypes";
import { useRequestsApi } from "../../../Context/RequestsApi";
import { applicationID, relationId } from "../../../api/ApplicationID";
import Loading from "../../../CardComponents/Loading";
import UpfPopupMessage from "../../../CardComponents/UpfPopupMessage";
import "./style.css"

const AttachRecordForm = (props) => {

    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const [modeDownload, setModeDownload] = useState(true);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const [uploadFile, setUploadFile] = useState([]);
    const [configRecordFormPopup, setConfigRecordFormPopup] = useState(
        {
            fullScreen: false,
            dragEnabled: true,
            icon: "square"
        }
    );
    const [popupContent, setPopupContent] = useState();
    const [popúpUpload, setPopupUpload] = useState();
    const [popupView, setPopupView] = useState(false)
    const [funcionarios, setFuncionarios] = useState(
        props.dataLookup?._funcionario
    )
    const [formDataJson, setFormDataJson] = useState();
    const [datasource, setDatasource] = useState([]);
    const [formData, setFormData] = useState([])
    const [details, setDetails] = useState([])
    const [fileName, setFileName] = useState([])
    const [value, setValue] = useState({});
    const { requestsApi } = useRequestsApi();
    const formElement = useRef();
    const [isDropZoneActive, setIsDropZoneActive] = useState(true)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        })
    }, [toastConfig])

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    useEffect(() => {
        if(props?.dataEntity?.masterEntity?.[0]?.entityAttachments && datasource.length <= 0){
            const dataEntityAttachments = props?.dataEntity?.masterEntity?.[0]?.entityAttachments
            for(let c = 0; c <= dataEntityAttachments.length - 1; c++){ 
                if(dataEntityAttachments[c].date_time_insert?.length === 23){
                    dataEntityAttachments[c].date_time_insert = dataEntityAttachments[c].date_time_insert.substring(0, dataEntityAttachments[c].date_time_insert?.length - 4)
                }
                else if(dataEntityAttachments[c].date_time_insert?.length > 19){
                    dataEntityAttachments[c].date_time_insert = dataEntityAttachments[c].date_time_insert.substring(0, dataEntityAttachments[c].date_time_insert?.length - 5)
                }
            }
            console.log(dataEntityAttachments)
            setDatasource(dataEntityAttachments)
        }
        // else{
        //     if(props.entityName){
        //         httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
        //             "applicationId": applicationID,
        //             "entityName": props.entityName,
        //             "fieldName": 'id',
        //             "expressao": props.idValue
        //         })
        //         .then((sender) => {
        //             const senderInsertRecordFromJson = JSON.parse(sender);
        //             setDatasource(senderInsertRecordFromJson?.masterEntity?.[0]?.entityAttachments)
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        //     }
    
        // }
    
    }, [])

    const makeUpload = async (file) => {
        console.log(file)
        const lare = new FormData(file.target);
        const fileslist = [...lare][0][1]

        console.log(fileslist)
    }

    const addIdParameter = (e) => {
        setFormDataJson(e);
    }

    const makeDownload = () => {
        httpRequest('POST',
            requestsApi.getEntityAttachment, requestsApi.basicAuth,
            {
                "applicationId": applicationID,
                "id": popupContent.id
            }
        )
        .then((sender) => {
            const resultGetEntityAttachmentJson = JSON.parse(sender)
            
            console.log(resultGetEntityAttachmentJson)
            var a = document.createElement("a");
            a.href = 'data:application/octet-stream;base64,' + resultGetEntityAttachmentJson?.[0]?._arquivo;
            a.download = popupContent._nomearquivo;
            a.click();
            setSelectedItemKeys()
        })
        .catch((err) => {
            console.log(err)
            setPopupErrorMessageState(err?.data?.err)
            setPopupErrorVisibleState(true)
        })
        
    }

    const handleDelete = () => {
        setPopupConfirmation(false);
        httpRequest("POST",
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
            {
                "applicationId": applicationID,
                "entityName": "_entityAttachment",
                "fieldName": 'id',
                "expressao": popupContent.id,
                "relationId": relationId
            })
            .then((sender) => {
                refreshList()
            }).catch(error => {
                console.log(error)
            })
    }

    const makeDelete = () => {
        setPopupConfirmation(true)
    }

    const openPopupUpload = () => {
        setPopupUpload(true)
    }

    const hidePopupDelete = () => {
        setPopupConfirmation(false);
    }

    const setNewName = (event) => {

        let fileNameList = []
        let detailsList = []
        uploadFile.map((value, index) => {
            console.log(value.name)
            let name = value.name.split(".")[0]
            let extension = value.name.split(".")[1]
            console.log(formData[name] ? formData[name][extension] : "")
            console.log(formData["Observação " + name] ? formData["Observação " + name][extension] : "")
            fileNameList.push(formData[name] ? formData[name][extension] : "")
            detailsList.push(formData["Observação " + name] ? formData["Observação " + name][extension] : "")
        })
        setDetails(detailsList)
        setFileName(fileNameList)
    }

    const refreshList = () => {
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": props.entityName,
            "fieldName": 'id',
            "expressao": props.idValue
        })
        .then((sender) => {
            const senderInsertRecordFromJson = JSON.parse(sender);

            const dataEntityAttachments = senderInsertRecordFromJson?.masterEntity?.[0]?.entityAttachments
            for(let c = 0; c <= dataEntityAttachments.length - 1; c++){ 
                if(dataEntityAttachments[c].date_time_insert?.length === 23){
                    dataEntityAttachments[c].date_time_insert = dataEntityAttachments[c].date_time_insert.substring(0, dataEntityAttachments[c].date_time_insert?.length - 4)
                }
                else if(dataEntityAttachments[c].date_time_insert?.length > 19){
                    dataEntityAttachments[c].date_time_insert = dataEntityAttachments[c].date_time_insert.substring(0, dataEntityAttachments[c].date_time_insert?.length - 5)
                }
            }
            console.log(dataEntityAttachments)
            setDatasource(dataEntityAttachments)
            
            toastConfiguration(true, 'success', "Dados atualizados com sucesso!")
        })
        .catch((error) => {
            console.log(error)
        })
    }

    function reactcomp(item) {
        console.log(item)
        // FAZER REQUISIÇÃO PARA PEGAR O ARQUIVO PELO ITEM.ID
        httpRequest('POST',
            requestsApi.getEntityAttachment, requestsApi.basicAuth,
            {
                "applicationId": applicationID
                , "id": item.id
            }
        )
        .then((sender) => {
            const resultGetEntityAttachmentJson = JSON.parse(sender)
            let data = resultGetEntityAttachmentJson?.[0]?._arquivo
            const type = item._extensaoarquivo == ".PDF"
                || item._extensaoarquivo == ".pdf" ?
                "application/pdf"
                :
                item._extensaoarquivo == ".TXT"
                    || item._extensaoarquivo == ".txt" ?
                    "text"
                    :
                    item._extensaoarquivo == ".JSON"
                        || item._extensaoarquivo == ".json" ?
                        "application/json"
                        :
                        item._extensaoarquivo == ".MPEG"
                            || item._extensaoarquivo == ".mpeg" ?
                            "audio/mpeg"
                            :
                            item._extensaoarquivo == ".OGG"
                                || item._extensaoarquivo == ".ogg" ?
                                "audio/ogg"
                                :
                                item._extensaoarquivo == ".JPEG"
                                    || item._extensaoarquivo == ".jpeg"
                                    || item._extensaoarquivo == ".JPG"
                                    || item._extensaoarquivo == ".jpg"
                                    || item._extensaoarquivo == ".JFIF"
                                    || item._extensaoarquivo == ".jfif"
                                    || item._extensaoarquivo == ".PJPEG"
                                    || item._extensaoarquivo == ".pjpeg"
                                    || item._extensaoarquivo == ".PJP"
                                    || item._extensaoarquivo == ".pjp" ?
                                    "image/jpeg"
                                    :
                                    item._extensaoarquivo == ".AVIF"
                                        || item._extensaoarquivo == ".avif" ?
                                        "image/avif"
                                        :
                                        item._extensaoarquivo == ".PNG"
                                            || item._extensaoarquivo == ".png" ?
                                            "image/png"
                                            :
                                            item._extensaoarquivo == ".SVG"
                                                || item._extensaoarquivo == ".svg" ?
                                                "image/svg+xml"
                                                :
                                                item._extensaoarquivo == ".CSS"
                                                    || item._extensaoarquivo == ".css" ?
                                                    "text/css"
                                                    :
                                                    item._extensaoarquivo == ".CSV"
                                                        || item._extensaoarquivo == ".csv" ?
                                                        "text/csv"
                                                        :
                                                        item._extensaoarquivo == ".HTML"
                                                            || item._extensaoarquivo == ".html" ?
                                                            "text/html"
                                                            :
                                                            item._extensaoarquivo == ".XML"
                                                                || item._extensaoarquivo == ".xml" ?
                                                                "text/xml"
                                                                :
                                                                item._extensaoarquivo == ".XLSX"
                                                                || item._extensaoarquivo == ".xlsx" ?
                                                                "text/xmlx"
                                                                :
                                                                ""

            const dataurl =
                item._extensaoarquivo == ".PDF"
                    || item._extensaoarquivo == ".pdf" ?
                    "data:application/pdf;base64," + data
                    :
                    item._extensaoarquivo == ".TXT"
                        || item._extensaoarquivo == ".txt" ?
                        "data:text;base64," + data
                        :
                        item._extensaoarquivo == ".JSON"
                            || item._extensaoarquivo == ".json" ?
                            "data:application/json;base64," + data
                            :
                            item._extensaoarquivo == ".MPEG"
                                || item._extensaoarquivo == ".mpeg" ?
                                "data:audio/mpeg;base64," + data
                                :
                                item._extensaoarquivo == ".OGG"
                                    || item._extensaoarquivo == ".ogg" ?
                                    "data:audio/ogg;base64," + data
                                    :
                                    item._extensaoarquivo == ".JPEG"
                                        || item._extensaoarquivo == ".jpeg"
                                        || item._extensaoarquivo == ".JPG"
                                        || item._extensaoarquivo == ".jpg"
                                        || item._extensaoarquivo == ".JFIF"
                                        || item._extensaoarquivo == ".jfif"
                                        || item._extensaoarquivo == ".PJPEG"
                                        || item._extensaoarquivo == ".pjpeg"
                                        || item._extensaoarquivo == ".PJP"
                                        || item._extensaoarquivo == ".pjp" ?
                                        "data:image/jpeg;base64," + data
                                        :
                                        item._extensaoarquivo == ".AVIF"
                                            || item._extensaoarquivo == ".avif" ?
                                            "data:image/avif;base64," + data
                                            :
                                            item._extensaoarquivo == ".PNG"
                                                || item._extensaoarquivo == ".png" ?
                                                "data:image/png;base64," + data
                                                :
                                                item._extensaoarquivo == ".SVG"
                                                    || item._extensaoarquivo == ".svg" ?
                                                    "data:image/svg+xml;base64," + data
                                                    :
                                                    item._extensaoarquivo == ".CSS"
                                                        || item._extensaoarquivo == ".css" ?
                                                        "data:text/css;base64," + data
                                                        :
                                                        item._extensaoarquivo == ".CSV"
                                                            || item._extensaoarquivo == ".csv" ?
                                                            "data:text/csv;base64," + data
                                                            :
                                                            item._extensaoarquivo == ".HTML"
                                                                || item._extensaoarquivo == ".html" ?
                                                                "data:text/html;base64," + data
                                                                :
                                                                item._extensaoarquivo == ".XML"
                                                                    || item._extensaoarquivo == ".xml" ?
                                                                    "data:text/xml;base64," + data
                                                                    :
                                                                    item._extensaoarquivo == ".XLSX"
                                                                    || item._extensaoarquivo == ".xlsx" ?
                                                                    "data:text/xmlx;base64," + data
                                                                    :
                                                                    ""

            return (<object
                width="400px"
                height="100%"
                type={type}
                data={dataurl}
                scrolling="yes"
                style={{
                    boxSizing: "border-box",
                    maxHeight: "100%",
                    width: "100%",
                    backgroundColor: "white",
                }}
            >
                <embed
                    id="ifmcontentstoprint"
                    title="ifmcontentstoprint"
                    scrolling={"true"}
                    style={{
                        maxHeight: "100%",
                        width: "100%",
                        backgroundColor: "white",
                    }}
                    type={type}
                    src={dataurl}
                    height="100%"
                >
                    {/* <div
                id="divcontents"
                dangerouslySetInnerHTML={reactcomp(popupContent)}
            /> */}
                </embed>

            </object>)
            
        })
        .catch((err) => {
            console.log(err)
            setPopupErrorMessageState(err?.data?.err)
            setPopupErrorVisibleState(true)
        })
        
    }

    function onDropZoneEnter(e) {
        if (e.dropZoneElement.id === 'dropzone-external') {
            setIsDropZoneActive(true);
        }
    }

    function onDropZoneLeave(e) {
        if (e.dropZoneElement.id === 'dropzone-external') {
            setIsDropZoneActive(false);
        }
    }


    const refreshButtonOptions = {
        icon: 'refresh',
        hint: 'Atualizar dados do servidor',
        onClick: refreshList,
    }

    const makeUploadButtonOptions = {
        icon: 'arrowup',
        text: 'Adicionar Arquivos',
        type:"default",
        onClick: openPopupUpload,
    }

    const downloadButtonOptions = {
        icon: 'arrowdown',
        text: 'Fazer download',
        disabled: modeDownload,
        type:"default",
        onClick: makeDownload,
    }

    const deleteButtonOptions = {
        icon: 'trash',
        text: 'Remover arquivo',
        type:"default",
        onClick: makeDelete,
    }

    const uploadHeaders = {
        "Authorization": "Basic " + btoa(`${requestsApi.basicAuth}`),        
        "applicationId": applicationID,
        "objectId": props.idValue,
        "relationId": relationId,
    }

    const closeDeleteRecord = {
        text: "Cancelar"
        , onClick: hidePopupDelete
    }

    const confirmDeleteRecord = {
        text: "Ok"
        , onClick: handleDelete
    }

    const [mediaObj, setMediaObj] = useState({
        type: "",
        mimeType: "",
        dataUrl: "",
        fileExtension: ""
    });

    const [text, setText] = useState("");

    function getFile(fileId) {
        httpRequest('POST',
            requestsApi.getEntityAttachment, requestsApi.basicAuth,
            {
                "applicationId": applicationID,
				"id": fileId
            }
        )
        .then(response => JSON.parse(response))
        .then(json => {
            const fileExtension = json[0]?._extensaoarquivo.replace(".", "");
            console.log(json)
            
            if (fileTypes.hasOwnProperty(fileExtension)) {
                const mimeType = fileTypes[fileExtension];
                const fileType = mimeType.split("/")[0];
                const dataUrl = `data:${mimeType};base64,` + json[0]._arquivo;
                
                if (fileType === "text") {
                    setText(atob(json[0]._arquivo));
                }
                
                setMediaObj({
                    fileType,
                    mimeType,
                    dataUrl,
                    fileExtension,
                });
        }
        })
        .catch(err => console.log("Error: ", err))
    }

    return (
        <div className="detail-attachment">

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={600}
                height={60}
            />

            <UpfPopupMessage
                hidePopup={hidePopupError}
                visible={popupErrorVisibleState}
                message={popupErrorMessageState}
            />

            {props.isLoading ? <Loading style={{ height: "100%" }} />
                :
                <div>
                    <Popup

                        visible={popupConfirmation}
                        onHiding={hidePopupDelete}
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showCloseButton={false}
                        showTitle={true}
                        title={"Confirmação"}
                        width={400}
                        height={250}
                        resizeEnabled={false}
                    >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={confirmDeleteRecord}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={closeDeleteRecord}
                        />
                        <p>
                            Você realmente deseja apagar este registro?
                        </p>
                    </Popup>

                    {popupContent &&
                        <Popup
                            visible={popupView}
                            onHiding={() => {
                                setPopupView(false)
                            }}
                            showCloseButton={true}
                            fullScreen={configRecordFormPopup.fullScreen}
                            dragEnabled={configRecordFormPopup.dragEnabled}                       
                            toolbarItems={
                                [
                                    {
                                        widget: "dxButton",
                                        location: "after",
                                        options: {
                                            icon: configRecordFormPopup.icon,
                                            onClick: () => {
                                                if (configRecordFormPopup.icon === "square") {
                                                    setConfigRecordFormPopup(
                                                        {
                                                            fullScreen: true,
                                                            dragEnabled: false,
                                                            icon: "unselectall"
                                                        }
                                                    )
                                                } else {
                                                    setConfigRecordFormPopup(
                                                        {
                                                            fullScreen: false,
                                                            dragEnabled: true,
                                                            icon: "square"
                                                        }
                                                    )
                                                }
                                            }
                                        }
                                    }
                                ]}
                            >
                            <>
                                {mediaObj.fileType === "image" && (
                                    <img src={mediaObj.dataUrl} alt="" />
                                )}
                                {mediaObj.fileType === "text" && mediaObj.fileExtension !== "html" && (
                                    <div className="bg-text">
                                        <p>{text}</p>
                                    </div>
                                )}
                                {mediaObj.fileExtension === "html" && (
                                    <iframe src={mediaObj.dataUrl} title="" width="100%" height="100%"></iframe>
                                )}
                                {mediaObj.fileType === "application" && (
                                    <embed src={mediaObj.dataUrl} type={mediaObj.mimeType} width="100%" height="100%" />
                                )}
                                {mediaObj.fileType === "video" && (
                                    <video controls className="container-video">
                                        <source src={mediaObj.dataUrl} type={mediaObj.mimeType} />
                                        Seu navegador não suporta a abertura de vídeos.
                                    </video>
                                )}
                                {mediaObj.fileType === "audio" && (
                                    <audio controls className="container-audio">
                                        <source src={mediaObj.dataUrl} type={mediaObj.mimeType} />
                                        Seu navegador não suporta a abertura de áudios.
                                    </audio>
                                )}
                            </>
                        </Popup>
                    }

                    {popúpUpload && 
                    
                        <Popup
                            visible={popúpUpload}
                            onHiding={() => {
                                setPopupUpload(false)
                                setUploadFile([])
                            }}
                            showCloseButton={true}
                        >
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <FileUploader
                                    uploadMode="useButtons"
                                    selectButtonText={"Selecionar arquivo"}
                                    dialogTrigger="#dropzone-external"
                                    dropZone="#dropzone-external"
                                    uploadButtonText={"Enviar"}
                                    multiple={true}
                                    elementAttr={{
                                        class: "uploader"
                                    }}
                                    onFilesUploaded={refreshList}
                                    onDropZoneEnter={onDropZoneEnter}
                                    onDropZoneLeave={onDropZoneLeave}                            
                                    onValueChanged={e => {
                                        console.log(e);
                                        console.log(e.value);
                                        console.log(e.value[0].name);
                                        setUploadFile(e.value);                                
                                        setFileName([])
                                        setDetails([])
                                        setFormData([])
                                    }}
                                    uploadHeaders={uploadHeaders}
                                    uploadMethod={"POST"}
                                    uploadUrl={requestsApi.entityAttachmentUpload}
                                    height={"100%"}
                                    width={"100%"}
                                />
                            </ScrollView>

                        </Popup>
                    }

                    <Toolbar
                        className={"div-toolbar-attach"}
                        height={70}
                    >

                        <Item
                            location="before"
                            widget="dxButton"
                            options={makeUploadButtonOptions}
                        />
                        <Item
                            location="before"
                            widget="dxButton"
                            options={downloadButtonOptions}

                        />
                        <Item
                            location="before"
                            widget="dxButton"
                            options={deleteButtonOptions}
                            disabled={modeDownload}
                        />
                        <Item
                            location="before"
                            widget="dxButton"
                            options={refreshButtonOptions}
                        />
                    </Toolbar>



                    <DataGrid
                        ref={formElement}
                        dataSource={datasource}
                        showSelectionControls={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        selectionMode={"single"}
                        allowColumnReordering={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        columnMinWidth={50}
                        onSelectedRowKeysChange={(e) => {
                            console.log(e)
                            setPopupContent(e[0])
                            setSelectedItemKeys(e)
                            setModeDownload(false)
                        }}
                        onCellDblClick={(e) => {
                            if (fileTypes.hasOwnProperty(e.data._extensaoarquivo.replace(".", ""))) {
                                //clear
                                setMediaObj({
                                    type: "",
                                    mimeType: "",
                                    dataUrl: ""
                                });
                                
                                setSelectedItemKeys(e.data)
                                setPopupContent(e.data)
                                setPopupView(true)
                                getFile(e.data.id)//getFile(popupContent.id)
                            } else if (selectedItemKeys[0]) {
                                makeDownload()
                            }
                        }}
                        selection={{
                            allowSelectAll: true,
                            deferred: false,
                            mode: "single",
                            selectAllMode: "allPages",
                            showCheckBoxesMode: "onClick"
                        }}
                    >
                        <Selection
                            keyExpr="id"
                            mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="onClick"
                        />
                        <Column
                            dataField={"_nomearquivo"}
                            caption={"Nome do arquivo"}
                        />
                        <Column
                            dataField={"_observacao"}
                            caption={"Observação"}
                        />
                        <Column
                            dataType={"datetime"}
                            dataField={"date_time_insert"}
                            caption={"Data da inclusão"}
                            format="shortDateShortTime"
                        />


                        <Column
                            dataField={"_funcionarioid"}
                            caption={"Funcionário"}
                        >
                            <Lookup
                                dataSource={funcionarios}
                                valueExpr="id"
                                displayExpr="_cryptnome" />
                        </Column>
                        <Column
                            dataField={"_extensaoarquivo"}
                            caption={"Extensão do arquivo"}
                        />
                    </DataGrid>
                </div >
            }
        </div >
    )
}

export default AttachRecordForm;