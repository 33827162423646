const { createContext, useContext, useState } = require("react");

const GerarBoletoDataSource = createContext();

export const GerarBoletoDataSourceProvider = ({ children }) => {
  const [gerarBoletoDataSource, setGerarBoletoDataSource] = useState(null);

  return (
    <GerarBoletoDataSource.Provider value={{ gerarBoletoDataSource, setGerarBoletoDataSource }}>
      {children}
    </GerarBoletoDataSource.Provider>
  );
};

export const useGerarBoletoDataSource = () => useContext(GerarBoletoDataSource);