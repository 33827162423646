export const repaintJsonStructureConditionalRender = (json, setJsonState, condicionalVisibleState, data) => {
    const jsonResult = json[0].recordFormProperties[0].recordFormComponents

    let mudouEstruturaDoJson = false

    //checa apos todo o processo se no grupo nao tem outros grupos visiveis
    const groupVisibleConfirm = (json) => {
        for(let c = 0; c <= json.length - 1; c++){
 
            if(json[c].recordFormComponents?.length > 0 && json[c].fieldsDefs?.length > 0){
                if(!json[c].recordFormComponents?.find((e) => {return e.formVisible === 1}) && !json[c].fieldsDefs?.find((e) => {return e.formVisible === 1})){
                    json[c].formVisible = 0
                }
                else{
                    json[c].formVisible = 1
                }
            }
            else if(json[c].recordFormComponents?.length > 0){
                if(!json[c].recordFormComponents?.find((e) => {return e.formVisible === 1})){
                    json[c].formVisible = 0
                }
                else{
                    json[c].formVisible = 1
                }
            }
            else if(json[c].fieldsDefs?.length > 0){
                if(!json[c].fieldsDefs?.find((e) => {return e.formVisible === 1})){
                    json[c].formVisible = 0
                }
                else{
                    json[c].formVisible = 1
                }
            }
            else if(json[c]?.recordFormComponents === null && json[c]?.fieldsDefs === null){
                json[c].formVisible = 0
            }
        }

    }

     //checar se os grupos estão com pelo menos 1 field visivel
     const chageGroupVisible = (json) => {
        for(let c = 0; c <= json.length - 1; c++){
 
            if(json[c].recordFormComponents?.length > 0){
                chageGroupVisible(json[c].recordFormComponents)
            }

            else if(json[c].fieldsDefs?.length > 0){
                if(!json[c].fieldsDefs.find((e) => {return e.formVisible === 1})){
                    json[c].formVisible = 0
                }
                else{
                    json[c].formVisible = 1
                }
            }
            // else if (!json[c].fieldsDefs?.length > 0){
            //     json[c].formVisible = 0
            // }
        }

        groupVisibleConfirm(json)

    }

    const changeFieldVisible = (jsonResult) => {
        for(let c = 0; c <= jsonResult.length - 1; c++){

            if(!!jsonResult[c].conditionalVisibleFieldName && !!jsonResult[c].conditionalVisibleFieldValue){
                const arrayconditionalVisibleFieldValue = jsonResult[c]?.conditionalVisibleFieldValue.split(",")
                if(arrayconditionalVisibleFieldValue.find((e) => {return String(e) === String(data[`${jsonResult[c]?.conditionalVisibleFieldName}`])}) !== undefined){
                    jsonResult[c].formVisible = 1
                }
                else{
                    jsonResult[c].formVisible = 0
                }
                mudouEstruturaDoJson = true
            }
            //varre os grupos
            if(jsonResult[c]?.recordFormComponents?.length > 0){
                changeFieldVisible(jsonResult[c]?.recordFormComponents)
    
            }
            //varre os campos
            if(jsonResult[c]?.fieldsDefs?.length > 0){
                changeFieldVisible(jsonResult[c]?.fieldsDefs)
    
            }
        }
        if(mudouEstruturaDoJson){
            chageGroupVisible(jsonResult)
        }
    }

    changeFieldVisible(jsonResult)

   

    // if(mudouEstruturaDoJson){
    //     
    // }

    if(mudouEstruturaDoJson){
        condicionalVisibleState(false)

        json[0].recordFormProperties[0].recordFormComponents = jsonResult

        setJsonState({entityStructure: json})

        condicionalVisibleState(true)
    }
}