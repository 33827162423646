import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    GroupItem,
    HeaderFilter,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    TotalItem,
    SearchPanel,
    FilterRow
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useState, useCallback, useRef, useEffect, memo } from 'react'
import axios from 'axios'
import {
    applicationID,
    relationId,
    permissions
} from '../../../api/ApplicationID'
// import { Lookup } from 'devextreme-react/lookup';
import TabPanel from 'devextreme-react/tab-panel';
import { httpRequest } from '../../../functions'
import { useStatePopup } from '../../../Context/StatePopupTabPanel';
import './style.css'
import { CheckBox } from 'devextreme-react';
import { Toast } from 'devextreme-react/toast';
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useTabPanelItems } from '../../../Context/TabPanelItems'
import RecordForm from '../../RecordForm';
import { useRequestsApi } from "../../../Context/RequestsApi"
import ScrollView from 'devextreme-react/scroll-view';
import HtmlFormPopup from '../../HtmlFormPopup';
import { convertDate } from '../../../functions/convertDate';
import { validatePermission } from '../../../functions/validatePermission'
import HistoricoCustomFormPopup from "../HistoricoCustomFormPopup"
import TarefaHistoricoFormPopup from '../../TarefaHistoricoFormPopup';
import ConsultaBoletoForm from '../../ConsultaBoletoForm'
import { Popup } from 'devextreme-react/popup';
import UpfHtmlEditor from '../../UpfComponents/UpfHtmlEditor'
import DropDownBox from 'devextreme-react/drop-down-box';
import TabPanelDetailEntityItem from './TabPanelDetailEntityItem';
import UpfPopupMessage from '../../../CardComponents/UpfPopupMessage';
import MultiView from 'devextreme-react/multi-view';
import UpfPopupConfirmContextMenu from '../../../CardComponents/UpfPopupConfirmContextMenu';

const TabPanelDetailEntitys = (props) => {
    //ESTADOS
    const { requestsApi } = useRequestsApi()
    const { setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const [dataSourceBoletoState, setDataSourceBoletoState] = useState()

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const [states, setStates] = useState({
        entityNameDetail: "",
        startEditAction: "click",
        modeEditDataGrid: {
            edit: false
        },
        resultViewStructure: "",
        toastVisible: false,
        toastType: "",
        toastMessage: "",
        selectedItens: "",
        selectedItensCount: "",
        dataDetailEntity: false,
        dataGridDataSourceItems: null,
        dataLookup: null,
        entityStructure: null,
        htmlPopupVisible: false,
        htmlResult: null
    })

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    const toastConfiguration = (visible, type, message) => {
        setStates({
            ...states,
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({ ...states, toastVisible: false })
    }

    const [entityNameDetail, setEntityNameDetail] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()


    const resetFormData = useRef(null);
    const refDataGrid = useRef(null)

    const [focusFristTabPanel, setfocusFristTabPanel] = useState(true)
    const tabPanelRef = useRef(null);
    const focusTabPanel = () => {
        setEntityNameDetail(props.entityStructure[0].detailEntities[0].detailEntity?.[0]?.entityName)
        setfocusFristTabPanel(false)
    }

    const [converteuData, setConverteuData] = useState(false)
    const senderFieldNamesCheckBox = []

    const [historicoCustomFormPopupVisibleState, setHistoricoCustomFormPopupVisibleState] = useState()
    const [tarefaHistoricoFormPopupVisibleState, setTarefaHistoricoFormPopupVisibleState] = useState()

    const [consultaBoletoFormPopupState, setConsultaBoletoFormPopupState] = useState(false)

    const [ TabPanelDetailEntitysState, SetTabPanelDetailEntitysState ] = useState()

    const [entityNameDetailEntityMenuContextState, setEntityNameDetailEntityMenuContextState] = useState()


    useEffect(() => {
        SetTabPanelDetailEntitysState(false)
        
        if (props.dataEntityDetailEntity && props.entityStructure) {
            // setStates({...states, dataLookup: props.dataLookup})

            let newDataEntityDetailEntity = props.dataEntityDetailEntity

            let detailEntitiesItens = props.entityStructure[0].detailEntities[0].detailEntity

            // //montando o array de nomes de colunas checkbox (da estrutura)
            // for (let c = 0; c <= detailEntitiesItens.length - 1; c++) {
            //     if (Array.isArray(detailEntitiesItens[c].fieldsDefs)) {
            //         for (let count = 0; count <= detailEntitiesItens[c].fieldsDefs.length - 1; count++) {
            //             if (detailEntitiesItens[c].fieldsDefs[count].editorType === "dxCheckBox") {
            //                 senderFieldNamesCheckBox.push(detailEntitiesItens[c].fieldsDefs[count].fieldName)
            //             }
            //         }
            //     }
            // }

            // //mudando o valor dos dados que vão entrar nas colunas checkbox de null pra false
            // for(let c = 0; c <= newDataEntityDetailEntity.length - 1; c++) {
            //     if(Array.isArray(newDataEntityDetailEntity[c]["records"])) {
            //         for(let count = 0; count <= newDataEntityDetailEntity[c]["records"].length - 1; count++) {
            //             for(let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
            //                 if(newDataEntityDetailEntity[c].records[count][`${senderFieldNamesCheckBox[i]}`] === null) {
            //                     newDataEntityDetailEntity[c].records[count][`${senderFieldNamesCheckBox[i]}`] = false
            //                 }  
            //             }
            //         }
            //     }
            // }

            for (let c = 0; c < detailEntitiesItens.length; c++) {
                if (detailEntitiesItens[c].formVisible === 1) {
                    detailEntitiesItens[c].formVisible = true
                }
                // for(let i = 0; i <= detailEntitiesItens[c].fieldsDefs.length - 1; i++) {
                //     if(newDataEntityDetailEntity[c][`${detailEntitiesItens[c].fieldsDefs[i]}`] === null) {
                //         newDataEntityDetailEntity[c][`${detailEntitiesItens[c].fieldsDefs[i]}`] = false
                //     }
                // }
                // if(detailEntitiesItens[c].fieldsDefs)
            }

            console.log(detailEntitiesItens)


            setStates({ ...states, entityStructure: detailEntitiesItens })
            // setStates((prevState, props) => {
            //     console.log(prevState)
            //     return ({
            //         ...prevState,
            //         entityStructure: detailEntitiesItens 
            //     })
            //   }
            // )
            if(!converteuData){
                for (let c = 0; c <= detailEntitiesItens.length - 1; c++) {
                    convertDate(newDataEntityDetailEntity.find(
                        element => element.entityName.toLowerCase() === detailEntitiesItens[c].entityName.toLowerCase()
                    )?.records, detailEntitiesItens[c].fieldsDefs)
                }
                setConverteuData(true)
            }
        }

    }, [entityNameDetail, states.dataDetailEntity, props.dataEntityDetailEntity])

    const [htmlTemplate, setHtmlTemplate] = useState()
    const [htmlPopupVisible, setHtmlPopupVisible] = useState()
    const [htmlFormPopupDataSourceState, setHtmlFormPopupDataSourceState] = useState()
    const [htmlFormPopupIdState, setHtmlFormPopupIdState] = useState()
    const [htmlPopupEntityNameState, setHtmlPopupEntityNameState] = useState()


    useEffect(() => {

        if (focusFristTabPanel && props.entityStructure?.[0]?.detailEntities?.[0]?.detailEntity && tabPanelRef) {
            console.log("useEffect focus")
            setEntityNameDetail(props.entityStructure[0].detailEntities[0].detailEntity?.[0]?.entityName)
            setfocusFristTabPanel(false)
        }
    }, [tabPanelRef, states.dataDetailEntity, props.entityStructure ])

    const [reloadTabPanelDetailState, setReloadTabPanelDetailState] = useState()
    useEffect(() => {
        SetTabPanelDetailEntitysState(false)

    }, [
        ,dataSourceBoletoState
        ,consultaBoletoFormPopupState
        ,tarefaHistoricoFormPopupVisibleState
        ,historicoCustomFormPopupVisibleState
        ,states.toastVisible
        ,reloadTabPanelDetailState
        ,htmlTemplate
        ,htmlPopupVisible
        ,props.dataLookup
    ])


    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true)
    const [startEditAction, setStartEditAction] = useState("click")

    const onSelectTextOnEditStartChanged = (args) => {
        setSelectTextOnEditStart(args.value)
    }

    const onStartEditActionChanged = (args) => {
        setStartEditAction(args.value)
    }


    const allowedPageSizes = [10, 25, 50, 100];

    const getOcorrencias = () => {
        const dataSouce = props.dataEntity?.masterEntity?.[0]?.records?.[0]

        httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
            applicationId: applicationID,
            equipeId: dataSouce._equipeid,
            credorId: dataSouce._credorid
        })
        .then((sender) => {
            const getWidgetsResult = JSON.parse(sender)
            for(let c = 0; c <= getWidgetsResult.length - 1; c++){
                if(getWidgetsResult[c]?.widget === "tabulacaoAcionamento"){
                    setOcorrenciasStates(getWidgetsResult[c]?.ocorrencias)
                    //codigo para abrir popup do ocorrencias
                    setHistoricoCustomFormPopupVisibleState(true)
                }
            }
            // setGravouOcorrencia(false)
        })
        .catch((err) => {
            console.log(err)
            setPopupErrorMessageState(err?.data?.err)
            setPopupErrorVisibleState(true)
        })
    }

    const getOcorrenciasTarefa = () => {
        httpRequest("POST", requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": "_ocorrenciaTarefa",
                "fieldName": "*",
                "expressao": "*",
                "applicationId": applicationID,
        })
        .then((sender) => {
            const resultGetDataEntityJson = JSON.parse(sender)
            console.log(resultGetDataEntityJson)
            setOcorrenciasStates(resultGetDataEntityJson?.masterEntity?.[0]?.records)
            //codigo para abrir popup do ocorrencias
            setTarefaHistoricoFormPopupVisibleState(true)
        })
        .catch((err) => {
            console.log(err)
            setPopupErrorMessageState(err?.data?.err)
            setPopupErrorVisibleState(true)
        })
    }


    const [ocorrenciasState, setOcorrenciasStates] = useState()

    const RenderHistoricoCustomFormPopup = memo(() => {
        return (
            <HistoricoCustomFormPopup
                visibleState={historicoCustomFormPopupVisibleState}
                functionVisibleState={setHistoricoCustomFormPopupVisibleState}
                tituloId={props.tituloId ? props.tituloId : props.idValue}
                ocorrencias={ocorrenciasState}
                toastConfiguration={toastConfiguration}
                clienteId={props.dataEntity?.masterEntity?.[0]?.records?.[0]?._clienteid}
            />
        ); 
    })

    const RenderTarefaHistoricoFormPopup = memo(() => {
        return (
            <TarefaHistoricoFormPopup
                visibleState={tarefaHistoricoFormPopupVisibleState}
                functionVisibleState={setTarefaHistoricoFormPopupVisibleState}
                tituloId={props.tituloId ? props.tituloId : props.idValue}
                ocorrencias={ocorrenciasState}
                toastConfiguration={toastConfiguration} 
            />
        ); 
    })


    const onClickMenuContextItem = (item) => {
        if(!item.itemData?.askConfirm){

            SetTabPanelDetailEntitysState(false)

            console.log(item)
            if (item.itemData.componentName === "recordForm") {
                props.showLoadPanel()
                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: item.itemData.componentParameter,
                    applicationId: applicationID
                })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)

                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                            if(props.objectLookUpEntityViewsTipoProcessamento){
                                const lookUpEntityViewsTipoProcessamento = resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpEntity === "viewstipoprocessamentolookup")
                                
                                if(lookUpEntityViewsTipoProcessamento){
                                    resultGetEntityStructureJson[0]?.lookUpEntities.splice(resultGetEntityStructureJson[0]?.lookUpEntities.indexOf(lookUpEntityViewsTipoProcessamento), 1, props.objectLookUpEntityViewsTipoProcessamento)
                                }
                                else{
                                    resultGetEntityStructureJson[0]?.lookUpEntities?.push(props.objectLookUpEntityViewsTipoProcessamento)
                                }
                            }
                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)
                                    props.hideLoadPanel()
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                            title: `${item.itemData.componentParameter}`
                                            // ,icon: itemClicado.itemData.icon
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <RecordForm
                                                        entityName={item.itemData.componentParameter}
                                                        idValue={states.selectedItens[0].id}
                                                        dataLookup={senderDataLookup}
                                                        entityStructure={resultGetEntityStructureJson}
                                                    //IDValue={states.selectedItens[0][`${sender.itemData.viewFieldName}`]}
                                                    // IDValue={props.loteId}
                                                    />
                                                </ScrollView>
                                            , text: Math.random()
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)

                                })
                                .catch((error) => { 
                                    console.log(error)
                                    props.hideLoadPanel()
                                })
                        }
                        else {
                            props.hideLoadPanel()
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    title: `${item.itemData.componentParameter}`
                                    // ,icon: itemClicado.itemData.icon
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                entityName={item.itemData.componentParameter}
                                                idValue={states.selectedItens[0].id}
                                                entityStructure={resultGetEntityStructureJson}
                                            //IDValue={states.selectedItens[0][`${sender.itemData.viewFieldName}`]}
                                            // IDValue={props.loteId}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        }
                    })
                    .catch((error) => { 
                        console.log(error)
                        props.hideLoadPanel()
                    })
            }


            //CODIGO PARA QUANDO CLICAR COM O BOTÃO DIREITO ABRA O GERAR ACORDO
            // else if(sender.itemData.componentName === "gerarAcordo") {
            //     setTabPanelItems([
            //         ...tabPanelItems,
            //         {
            //             title: `${sender.itemData.componentParameter}`
            //             // ,icon: itemClicado.itemData.icon
            //             ,component: 
            //                 <ScrollView  width='100%' height='100%'>
            //                         <GerarAcordoForm 
            //                            
            //                         />
            //                 </ScrollView>
            //             ,text: Math.random()
            //         }
            //     ])
            //     setIndexTabPanelMain(tabPanelItems.length)
            // }
            else if (item.itemData.componentName === "htmlForm") {
                let idValueJson = []
                // for(let a = 0; a <= states.selectedItensCount-1; a++){
                //     idValueJson.push(states.selectedItens[a][`${item.itemData.viewFieldName}`])
                // }
                for (let a = 0; a <= states.selectedItensCount - 1; a++) {
                    idValueJson.push(states.selectedItens[a].id)
                }

                props.showLoadPanel()
                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson,
                        "parameter": item.itemData.componentParameter,
                        "entityName": entityNameDetail
                    })
                    .then((sender) => {
                        const resultJsonTemplateHtml = JSON.parse(sender)
                        toastConfiguration(true, 'info', "Arquivo gerado com sucesso!")
                        setHtmlTemplate(() => {
                            return { __html: resultJsonTemplateHtml.mensagem }
                        })
                        setHtmlPopupVisible(true)
                        props.hideLoadPanel()
                    })
                    .catch((error) => {
                        console.log(error)
                        props.hideLoadPanel()
                        if (error?.data?.error) {
                            props.hideLoadPanel()
                            props.setMessageError(error.data.error)
                            props.setPopup({
                                ...props.popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }

            else if (item.itemData.methodName) {
                console.log(item.itemData.methodName)
                console.log(item.itemData.componentParameter)

                let idValueJson = []
                // for(let a = 0; a <= states.selectedItensCount-1; a++){
                //     idValueJson.push(states.selectedItens[a][`${item.itemData.viewFieldName}`])
                // }
                for (let a = 0; a <= states.selectedItensCount - 1; a++) {
                    idValueJson.push(states.selectedItens[a].id)
                }

                props.showLoadPanel()
                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson[0],
                        "parameter": item.itemData.componentParameter,
                        "entityName": entityNameDetail
                    })
                    .then((sender) => {
                        props.hideLoadPanel()
                        const response = JSON.parse(sender)
                        toastConfiguration(true, 'info', response.mensagem)

                    })
                    .catch((error) => {
                        props.hideLoadPanel()
                        if (error?.data?.error) {
                            props.hideLoadPanel()
                            props.setMessageError(error.data.error)
                            props.setPopup({
                                ...props.popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const openMenuContextFunction = (e) => {
        let entityNameDetailEntity = props.entityStructure[0]?.detailEntities[0]?.detailEntity.find(element => element.entityName === entityNameDetail)

       console.log(e?.row?.data)
        if (entityNameDetailEntity?.contextMenu !== null && e?.row?.data) {
            let menuContextArray = []
            for (let c = 0; c <= entityNameDetailEntity?.contextMenu?.length - 1; c++) {
                let contextMenuItem = {
                    "text": entityNameDetailEntity.contextMenu[c].caption,
                    "icon": entityNameDetailEntity.contextMenu[c].icon,
                    "onItemClick": onClickMenuContextItem,
                    "componentName": entityNameDetailEntity.contextMenu[c].componentName,
                    "componentParameter": entityNameDetailEntity.contextMenu[c].componentParameter,
                    "idFieldName": entityNameDetailEntity.contextMenu[c].idFieldName,
                    "methodName": entityNameDetailEntity.contextMenu[c].methodName,
                    "askConfirm": entityNameDetailEntity.contextMenu[c].askConfirm
                }
                menuContextArray.push(contextMenuItem)
            }

            if (e.target === "content" && states.selectedItensCount > 0) {
                if (e.row) {
                    if (e.row.rowType === "data") {
                        console.log(e.items)
                        console.log("states.selectedItensCount")
                        e.items = menuContextArray
                        console.log(e.items)

                    }
                }
            }
        }
    } 


    const configRecordFormStructureDetail = (keyFieldName, entityNameDetailCurrent) => {
        console.log(keyFieldName)
        console.log(entityNameDetailCurrent)
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth,
            {
                "applicationId": applicationID
                , "entityName": "entity"
                , "fieldName": "entityname"
                , "expressao": entityNameDetailCurrent
            }
        )
            .then(senderGetDataEntity => {
                const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                props.setEntityDataEntityState(resultGetDataEntity)
                // props.setIdValueDetailEntity(resultGetDataEntity.masterEntity[0].records[0].id)

                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: "entity",
                    applicationId: applicationID,
                })
                    .then((sender) => {
                        props.setEntityStructureRecordFormPopupState(JSON.parse(sender))

                        if (JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0) {

                            if(props.objectLookUpEntityViewsTipoProcessamento){
                                const lookUpEntityViewsTipoProcessamento = JSON.parse(sender)[0]?.lookUpEntities.find(e => e.lookUpEntity === "viewstipoprocessamentolookup")
                                
                                if(lookUpEntityViewsTipoProcessamento){
                                    JSON.parse(sender)[0]?.lookUpEntities.splice(JSON.parse(sender)[0]?.lookUpEntities.indexOf(lookUpEntityViewsTipoProcessamento), 1, props.objectLookUpEntityViewsTipoProcessamento)
                                }
                                else{
                                    JSON.parse(sender)[0]?.lookUpEntities?.push(props.objectLookUpEntityViewsTipoProcessamento)
                                }
                            }

                            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    props.setDataLookupRecordFormPopupState(JSON.parse(sender))

                                    props.setPopupRecordFormOpen(true)
                                    props.setPopupState(true)
                                    props.setStateModeEdit(true)
                                    props.setDetailEntityName("entity")
                                    props.setDetailCaptionName("Entity")
                                    props.setKeyFieldNameState(keyFieldName)
                                    props.setPopupRecordFormAdd(false)
                                    props.setIDValueDetailEntity(resultGetDataEntity.masterEntity[0].records[0].id)

                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        else {
                            props.setPopupRecordFormOpen(true)
                            props.setPopupState(true)
                            props.setStateModeEdit(true)
                            props.setDetailEntityName("entity")
                            props.setDetailCaptionName("Entity")
                            props.setKeyFieldNameState(keyFieldName)
                            props.setPopupRecordFormAdd(false)
                            props.setIDValueDetailEntity(resultGetDataEntity.masterEntity[0].records[0].id)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                if (error?.data?.error) {
                    props.hideLoadPanel()
                    props.setMessageError(error.data.error)
                    props.setPopup({
                        ...props.popup
                        , isVisible: true
                        , message: `Erro: ${error?.status} ` + `(${error?.statusText})`
                    })
                }
                else {
                    props.hideLoadPanel()
                    props.setMessageError(error?.statusText)
                    props.setPopup({
                        ...props.popup
                        , isVisible: true
                        , message: `Erro: ${error?.status} ` + `(${error?.statusText})`
                    })
                }
            })
    }

    

    const onSelectionChangedFunction = (sender) => {
        setStates((prevState, props) => {
            return ({
                ...prevState,
                selectedItens: sender.component.getSelectedRowsData(),
                selectedItensCount: sender.component.getSelectedRowsData().length 
            })
          }
        )
        // setStates({
        //     ...states,
        //     selectedItens: sender.component.getSelectedRowsData(),
        //     selectedItensCount: sender.component.getSelectedRowsData().length
        // })
    }


    // return (
        if (!TabPanelDetailEntitysState){
            SetTabPanelDetailEntitysState(
            <div className="detail-tabpanel">
                <Toast
                    visible={states.toastVisible}
                    message={states.toastMessage}
                    type={states.toastType}
                    onHiding={onHidingToast}
                    displayTime={1800}
                    height={60}
                />

                {historicoCustomFormPopupVisibleState === true ? <RenderHistoricoCustomFormPopup /> : <></>}

                {tarefaHistoricoFormPopupVisibleState === true ? <RenderTarefaHistoricoFormPopup /> : <></>}

                <HtmlFormPopup
                    setHtmlPopupVisible={setHtmlPopupVisible}
                    htmlPopupVisible={htmlPopupVisible}
                    htmlTemplate={htmlTemplate}
                />

                <UpfPopupMessage
                    hidePopup={hidePopupError}
                    visible={popupErrorVisibleState}
                    message={popupErrorMessageState}
                />

                {visibleConfirmContextMenuState &&
                    <UpfPopupConfirmContextMenu
                        hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                        visibleConfirmContextMenu={visibleConfirmContextMenuState}
                        contextMenuFunction={onClickMenuContextItem}
                        contextMenuItemState={contextMenuItemState}
                    />
                }

                {dataSourceBoletoState &&
                    <Popup
                        visible={consultaBoletoFormPopupState}
                        onHiding={() => {
                            setConsultaBoletoFormPopupState(false)
                        }}
                        dragEnabled={false}
                        fullScreen={true}
                        hideOnOutsideClick={false}
                        animation={null}
                        // deferRendering={false}
                        // title={props.entityStructure[0].masterEntity.caption}
                        showCloseButton={true}
                        showTitle={true}
                        title='Boleto'
                        id="popupForm"
                    >

                        <ScrollView width='100%' height='100%' useNative={true}>
                            <ConsultaBoletoForm
                                boletoId={dataSourceBoletoState?.boleto}
                                email={dataSourceBoletoState?.email}
                                telefones={dataSourceBoletoState?.telefones}
                                dataLookup={dataSourceBoletoState?.dataLookup}
                                masterEntityId={dataSourceBoletoState?.masterEntityId}
                                dataSourceBoletoState={dataSourceBoletoState}
                                consultaBoletoFormPopupState={consultaBoletoFormPopupState}
                            />
                        </ScrollView>
                    </Popup>
                }

                <TabPanel
                    ref={tabPanelRef}
                    showNavButtons={true}
                    //height={500}
                    disabled={props.disabled}
                    deferRendering={false}//colocado para resolver o scroll quando clicava pela primeira vez em uma tab no tabpanel
                    onSelectionChanged={(sender) => {
                        if(sender?.addedItems?.[0]?.entityName){
                            setEntityNameDetail(sender.addedItems[0]?.entityName)
                            setDetailCaptionName(sender.addedItems[0]?.caption)
                            props.setIDValueDetailEntity(sender.addedItems[0]?.senderDetailEntity?.objectid)

                        }
                    }}
                    onContentReady={() => {
                        const primeiraAba = props.entityStructure[0].detailEntities[0].detailEntity[0]
                        console.log(entityNameDetail)
                        console.log(detailCaptionName)
                        if(!entityNameDetail && !detailCaptionName){
                            setEntityNameDetail(primeiraAba?.entityName)
                            setDetailCaptionName(primeiraAba?.caption)
                            props.setIDValueDetailEntity(primeiraAba?.senderDetailEntity?.objectid)
                        }
                    }}
                    // onTitleClick={openMenuContextFunction}
                    // repaintChangesOnly={false}
                >
                    {props.entityStructure && props.dataEntityDetailEntity?.length > 0 &&
                        props.entityStructure[0].detailEntities[0].detailEntity.map(
                            (senderDetailEntity, senderIndiceDetailEntity) => {
                                const itemPermissions = validatePermission(senderDetailEntity.objectid)
                                // console.log(`${senderDetailEntity.caption}`)
                                // console.log(JSON.stringify(itemPermissions))
                                // const newArray = []

                                // for(let c = 0; c <= senderDetailEntity?.fieldsDefs.length - 1; c++){
                                //     if(!newArray.find((e) => e?.fieldName === senderDetailEntity?.fieldsDefs[c].fieldName)){
                                //         newArray.push(senderDetailEntity?.fieldsDefs[c])
                                //     }    
                                // }
                                // senderDetailEntity.fieldsDefs = newArray


                                if(itemPermissions?._show === 1){
                                    return (
                                        <Item
                                            title={senderDetailEntity.caption}
                                            key={senderIndiceDetailEntity}
                                            entityName={senderDetailEntity.entityName}
                                            keyFieldName={senderDetailEntity.keyFieldName}
                                            senderDetailEntity={senderDetailEntity}
                                        >

                                            <TabPanelDetailEntityItem
                                                itemPermissions={itemPermissions}
                                                entityNameDetail={senderDetailEntity.entityName}
                                                senderDetailEntity={senderDetailEntity}
                                                masterEntityName={props.entityName}
                                                masterEntityStructure={props.entityStructure}
                                                // senderDetailEntity={entityStrucutureSemDuplicatas}
                                                getOcorrencias={getOcorrencias}
                                                getOcorrenciasTarefa={getOcorrenciasTarefa}
                                                dataEntity={props.dataEntity}
                                                showUpfPopupForm={props.showUpfPopupForm}
                                                setEntityStructureRecordFormPopupState={props.setEntityStructureRecordFormPopupState}
                                                setDataLookupRecordFormPopupState={props.setDataLookupRecordFormPopupState}
                                                setKeyFieldNameState={props.setKeyFieldNameState}
                                                setIDValueDetailEntity={props.setIDValueDetailEntity}
                                                setDetailEntityName={props.setDetailEntityName}
                                                setDetailCaptionName={props.setDetailCaptionName}
                                                setPopupRecordFormOpen={props.setPopupRecordFormOpen}
                                                setPopupState={props.setPopupState}
                                                setStateModeEdit={props.setStateModeEdit}
                                                configRecordFormStructureDetail={configRecordFormStructureDetail}
                                                requestsApi={requestsApi}
                                                setDataSourceBoletoState={setDataSourceBoletoState}
                                                dataSourceBoletoState={dataSourceBoletoState}
                                                setConsultaBoletoFormPopupState={setConsultaBoletoFormPopupState}
                                                setHistoricoCustomFormPopupVisibleState={setHistoricoCustomFormPopupVisibleState}
                                                openMenuContextFunction={openMenuContextFunction}
                                                onSelectionChangedFunction={onSelectionChangedFunction}
                                                states={states}
                                                setStates={setStates}
                                                dataEntityDetailEntity={props.dataEntityDetailEntity}
                                                setDataEntityDetailEntity={props.setDataEntityDetailEntity}
                                                dataLookup={props.dataLookup}
                                                recordUpdateJsonDetail={props.recordUpdateJsonDetail}
                                                recordDeleteJsonDetail={props.recordDeleteJsonDetail}
                                                recordInsertJsonDetail={props.recordInsertJsonDetail}
                                                tabPanelRef={tabPanelRef}
                                                setReloadTabPanelDetailState={setReloadTabPanelDetailState}
                                                reloadTabPanelDetailState={reloadTabPanelDetailState}
                                                entityStructure={props.entityStructure}
                                                hideLoadPanel={props.hideLoadPanel}
                                                setMessageError={props.setMessageError}
                                                setPopup={props.setPopup}
                                                toastConfiguration={toastConfiguration}
                                                setHtmlTemplate={setHtmlTemplate}
                                                setHtmlPopupVisible={setHtmlPopupVisible}
                                                entityName={senderDetailEntity.entityName}
                                                idValue={props.tituloId ? props.tituloId : props.idValue}
                                                showLoadPanel={props.showLoadPanel}
                                                caption={senderDetailEntity.caption}
                                                objectLookUpEntityViewsTipoProcessamento={props.objectLookUpEntityViewsTipoProcessamento}
                                                openLogCustomForm={props.openLogCustomForm}
                                                setHtmlFormPopupDataSourceState={setHtmlFormPopupDataSourceState}
                                                setHtmlFormPopupIdState={setHtmlFormPopupIdState}
                                                setHtmlPopupEntityNameState={setHtmlPopupEntityNameState}
                                            />
                                        </Item>

                                    )
                                }
                            }
                        )
                    }
                </TabPanel>
            </div>
            )
        }
    // )
    return(
        TabPanelDetailEntitysState
    )
}

export default memo(TabPanelDetailEntitys);