import React, { useState } from "react"
import FileManager, { Permissions, ContextMenu, Item, Toolbar, FileSelectionItem } from 'devextreme-react/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import { Popup } from 'devextreme-react/popup';
import { locale } from "devextreme/localization";
import { useRequestsApi } from "../../Context/RequestsApi";
import { ResourceManager } from "devexpress-dashboard";
import notify from "devextreme/ui/notify";

function FileManagerForm(props) {
    const { requestsApi } = useRequestsApi();
    
    const [currentPath, setCurrentPath] = useState("Widescreen")
    const [popupVisible, setPopupVisible] = useState(false);
    const [imageItemToDisplay, setImageItemToDisplay] = useState();
    const remoteProvider = new RemoteFileSystemProvider({
        endpointUrl: requestsApi.fileManagerEndpoint + '?path=' + props.path,
    });

    ResourceManager.setLocalizationMessages(require(`../DashBoardsComponents/DevExpressLocalizedResources_2021.2_pt-BR/json resources/dx-dashboard.pt-BR.json`));
    ResourceManager.setLocalizationMessages(require(`../DashBoardsComponents/DevExpressLocalizedResources_2021.2_pt-BR/json resources/dx-analytics-core.pt-BR.json`));
    locale("pt-BR");

    function displayImagePopup(e) {
        setPopupVisible(true);
        setImageItemToDisplay({
            name: e.file.name,
            url: e.file.dataItem.url
        });
    }

    function hideImagePopup() {
        setPopupVisible(false);
    }

    function onCurrentDirectoryChanged(e) {
        setCurrentPath(e.component.option('currentPath'));        
    }

    function copyPath() {
        const currentPathWithBackslashs = currentPath.replace(/\//g, '\\');
        navigator.clipboard.writeText(`${props.path}\\${currentPathWithBackslashs}`);
        notify("Caminho copiado", "success", 5000);
    }

    const copyPathButtonOptions = {
        text: "Copiar caminho",
        icon: "copy",
        onClick: copyPath
    }

    return (
        <div>
            <FileManager
                currentPath={currentPath}                
                onContentReady={(s)=>{s.component.instance().refresh();}}
                fileSystemProvider={remoteProvider}
                onSelectedFileOpened={displayImagePopup}
                rootFolderName={props.path}
                caption={props.caption}
                onCurrentDirectoryChanged={onCurrentDirectoryChanged}
            >
                <Permissions
                    create={true}
                    copy={true}
                    move={true}
                    delete={true}
                    rename={true}
                    upload={true}
                    download={true}>
                </Permissions>
                <Toolbar>
                    <Item name="create" text="Novo diretório" />
                    <Item name="upload" text="Upload arquivo" />
                    <Item name="switchView" />
                    <Item name="refresh" text="Atualizar" />
                    <Item widget="dxButton" options={copyPathButtonOptions} />

                    <FileSelectionItem 
                        name="download"
                        text="Download"
                    />
                    <FileSelectionItem 
                        name="move"
                        text="Mover para"
                    />
                    <FileSelectionItem 
                        name="copy"
                        text="Copiar para"
                    />
                    <FileSelectionItem 
                        name="rename"
                        text="Renomear"
                    />
                    <FileSelectionItem 
                        name="delete"
                        text="Deletar"
                    />
                    
                    <FileSelectionItem 
                        name="clearSelection"
                        text="Limpar seleção"
                    />
                </Toolbar>
                <ContextMenu>
                    <Item name="download" />
                    <Item name="create" text="Novo diretório" />
                    <Item name="rename" text="Renomear" />
                    <Item name="delete" text="Excluir" />
                    <Item name="refresh" text="atualizar" />
                </ContextMenu>
            </FileManager>

            <Popup
                maxHeight={600}
                hideOnOutsideClick={true}
                title={imageItemToDisplay?.name}
                visible={popupVisible}
                onHiding={hideImagePopup}
                className="photo-popup-content">
                <img src={imageItemToDisplay?.url} className="photo-popup-image" alt={imageItemToDisplay?.name} />
            </Popup>
        </div>
    );
}

export default FileManagerForm;