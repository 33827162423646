const ItemTemplateLookup = (props) => {
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <img
            alt=""
            src={props.lookUpResultIcon ? props.data[props.lookUpResultIcon] : props.data?._icon}
            style={{height: "40px", marginRight: "1em"}}
        />
        <div>{props.data.descricao}</div>
        </div>
    );
}

export default ItemTemplateLookup;