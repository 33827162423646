import SelectBox from 'devextreme-react/select-box';
import {TextBox} from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import {useEffect, useState, memo} from "react"
import React from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from "../../Context/RequestsApi"
import { applicationID } from '../../api/ApplicationID';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import QueryFormPopup from '../QueryForm/QueryFormPopup';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';

const ForeignFieldEdit = (props) => {
    console.log(props)
    const [popupQueryFormOpen, setPopupQueryFormOpen] = useState(false)
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState()
    const [tituloQueryFormPopup, setTituloQueryFormPopup] = useState()
    const [queryFormPopupState, setQueryFormPopupState] = useState(false)
    const [foreignFieldSelectedItem, setForeignFieldSelectedItem] = useState()

    const {requestsApi} = useRequestsApi()

    const [states, setStates] = useState({
        foreignFieldDataSource: [],
    })

    const [foreignFieldDataSource, setForeignFieldDataSource] = useState([])

    const [loadedDataState, setLoadedDataState] = useState(false)

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }


    const RenderQueryFormPopup = memo(() => {
        return (
            <QueryFormPopup
                id={Math.random()}
                setPopupQueryFormOpen={setPopupQueryFormOpen}
                popupQueryFormOpen={popupQueryFormOpen}
                entityName={props.entityName}
                entityStructure={props.entityStructure}
                foreignFieldSelectedItem={foreignFieldSelectedItem}
                setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                setQueryFormPopupState={setQueryFormPopupState}
                queryFormPopupState={queryFormPopupState}
                modeEditOn={props.modeEditOn}
                tituloQueryFormPopup={tituloQueryFormPopup}
                foreignFieldDataSource={foreignFieldDataSource}
            // idValue={}
            />
        );
    })

    useEffect(() => {
        // if(!foreignFieldDataSource){
            if(props.entityStructureField["fieldName"]){
                if(foreignFieldSelectedItem){
                    // props.dataEntity[`${props.entityStructureField.fieldName}`] = foreignFieldSelectedItem.id
                    httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                        "applicationId": applicationID,
                        "entityName": props.entityStructureField.lookUpListSource,//ta retornando cadastro 14/12/2021
                        "fieldName": props.entityStructureField.lookUpKeyField,
                        "expressao": foreignFieldSelectedItem?.id ? foreignFieldSelectedItem.id : foreignFieldSelectedItem
                    })
                    .then((sender) => {
                        const resultJsonDataEntity = JSON.parse(sender)
                        console.log(resultJsonDataEntity)
                        setForeignFieldDataSource(resultJsonDataEntity.masterEntity[0].records)
                        props.resetFormData.current.instance.updateData(`${props.entityStructureField.fieldName}`, foreignFieldSelectedItem?.id ? foreignFieldSelectedItem.id : foreignFieldSelectedItem)
                        setLoadedDataState(true)
                    }) 
                    .catch((err) => {
                        console.log(err)
                        setPopupErrorMessageState(err?.data?.err)
                        setPopupErrorVisibleState(true)
                    })
                }
                else if(props.dataEntity[`${props.entityStructureField.fieldName}`]){
                    httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                        "applicationId": applicationID,
                        "entityName": props.entityStructureField.lookUpListSource,//ta retornando cadastro 14/12/2021
                        "fieldName": props.entityStructureField.lookUpKeyField,
                        "expressao": props.dataEntity[`${props.entityStructureField.fieldName}`]
                    })
                    .then((sender) => {
                        const resultJsonDataEntity = JSON.parse(sender)
                        console.log(resultJsonDataEntity)
                        setForeignFieldDataSource(resultJsonDataEntity.masterEntity[0].records)
                        setLoadedDataState(true)
                    }) 
                    .catch((err) => {
                        console.log(err)
                        setPopupErrorMessageState(err?.data?.err)
                        setPopupErrorVisibleState(true)
                    })
                }
            // } 
        }
    }, 
    [props.setFormDataJson, props.setDataEntity, foreignFieldSelectedItem])

    console.log(props.dataEntity)
    console.log(foreignFieldDataSource)

    return(
    <div style={{"display": "flex"}}>

        {popupQueryFormOpen === true ? <RenderQueryFormPopup /> : <></>}

        <UpfPopupMessage
            hidePopup={hidePopupError}
            visible={popupErrorVisibleState}
            message={popupErrorMessageState}
        />

        {/* {foreignFieldSelectedItem ? 
            (
                <SelectBox
                    dataSource={[foreignFieldSelectedItem]}
                    value={foreignFieldSelectedItem ? foreignFieldSelectedItem.id : null}
                    displayExpr={props.entityStructureField.lookUpResultField}
                    valueExpr={props.entityStructureField.lookUpKeyField}
                />
            ) 
            :
            (
                <SelectBox
                    dataSource={foreignFieldDataSource}
                    //defaultValue={foreignFieldDataSource.length > 0 && foreignFieldDataSource.id}
                    value={foreignFieldDataSource.length > 0 ? foreignFieldDataSource[0].id : null}
                    displayExpr={props.entityStructureField.lookUpResultField}
                    valueExpr={props.entityStructureField.lookUpKeyField}
                />
            )
        } */}

        <SelectBox
            dataSource={foreignFieldDataSource}
            //defaultValue={foreignFieldDataSource.length > 0 && foreignFieldDataSource.id}
            value={foreignFieldDataSource.length > 0 ? foreignFieldDataSource[0].id : null}
            displayExpr={props.entityStructureField.lookUpResultField}
            valueExpr={props.entityStructureField.lookUpKeyField}
            stylingMode='underlined'
            labelMode='static'
            // label={props.caption}
        />

        <Button
            style={{"marginLeft": "0.5em"}}
            icon="search"
            disabled={props.disabled}
            onClick={() => {
                if(foreignFieldDataSource.length > 0){
                    setForeignFieldSelectedItem(foreignFieldDataSource[0].id)
                }
                setQueryFormPopupForeignFieldState(true)
                setPopupQueryFormOpen(true)
                setTituloQueryFormPopup(props.caption)
                setQueryFormPopupState(true)
                }}
        />
        {/* {!loadedDataState && props.foreignFieldSelectedItem && foreignFieldDataSource &&
            <LoadIndicator/>
        } */}
    </div>
    )
}

export default ForeignFieldEdit;