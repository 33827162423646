export const calculateCustomSummary = (options) => {
    try{
        const objOptions = JSON.parse(options.name)


        if (objOptions?.tipo === 'value') {
        //   if (options.summaryProcess === 'start') {
        //     options.totalValue = 0;
        //   }
          if (!options?.totalValue){
            options["totalValue"] = 0;
          }
          let isRowSelected = null
          if(objOptions?.key){
            isRowSelected = options.component.isRowSelected(options.value[`${objOptions?.key}`])
          }
          else{
            isRowSelected = options.component.isRowSelected(options.value?.ID) ? options.component.isRowSelected(options.value?.ID) : options.component.isRowSelected(options.value?.id);
          }
          if (options.summaryProcess === 'calculate' && isRowSelected) {
            options.totalValue += options.value[objOptions.columnName];
          }
        }
        else if(objOptions?.tipo === "count"){
            // if (options.summaryProcess === 'start') {
            //     options.totalValue = 0;
            // }
            if (!options?.totalValue){
                options["totalValue"] = 0;
            }

            let isRowSelected = null
            if(objOptions?.key){
                isRowSelected = options.component.isRowSelected(options.value[`${objOptions?.key}`])
            }
            else{
                isRowSelected = options.component.isRowSelected(options.value?.ID) ? options.component.isRowSelected(options.value?.ID) : options.component.isRowSelected(options.value?.id);
            }

            if (options.summaryProcess === 'calculate' && isRowSelected) {
                options["totalValue"] = options.totalValue + 1;
            }
        }
    }
    catch{
        
    }
};