import TabPanelMain from './TabPanelMain';
import "./style.css"
import { useRequestsApi } from "../../Context/RequestsApi"
import DrawerVariant from './DrawerVariant';
import { useState, useCallback, memo, useEffect } from 'react';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
// import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { changeTabIndice } from '../../redux/tabPanelIndexGlobalState';

sessionStorage.setItem("tabPanelMainIndex", 0)

const DrawerMain = (props) => {

  const {tabPanelItems, setTabPanelItems} = useTabPanelItems()
  const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain()


  const optionChange = (sender, setIndexTabPanelMainL)=>{
    if(sender?.name){
      if(sender.name === "selectedIndex"){
      sessionStorage.setItem("tabPanelMainIndex", sender.value)
      setIndexTabPanelMain(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
      }
    }
    else if(sender) {
      sessionStorage.setItem("tabPanelMainIndex", sender)
      console.log(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
      setIndexTabPanelMain(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
    }
  }

  window.onunload = function () {
    sessionStorage.clear();
  }

  // useEffect(() => {
  //   //mudou global dos componentes, logo vai mudar local do indice"
  //   optionChange(tabPanelComponentGlobalState.length - 1, setIndexTabPanelMainL) 

  // }, [tabPanelComponentGlobalState])

  // useEffect(() => {
  //   //mudou indice, logo vai mudar global do indice depois da troca de abas"
  //   dispatch(changeTabIndice(indexTabPanelMainL))

  // }, [indexTabPanelMainL])

  const {requestsApi, setRequestsApi} = useRequestsApi()

    return (
      <div>
        {requestsApi && 
          <DrawerVariant
            // component={<TabPanelMainVariant />}
            component={<TabPanelMain optionChange={optionChange} setIndexTabPanelMainL={setIndexTabPanelMain} indexTabPanelMainL={indexTabPanelMain} />}
            optionChange={optionChange} 
            setIndexTabPanelMainL={setIndexTabPanelMain}
            getServerInfoState={props.dataSourceLogin}
            getDashboardInfo={props.dataSourceLoginDashboard}
          />
        }
      
    </div>
)
}

export default memo(DrawerMain);