import { Popup } from 'devextreme-react/popup';
import ViewForm from "../index";
import { useState} from 'react'
import ScrollView from 'devextreme-react/scroll-view';
import { memo } from 'react';

const ViewFormPopup = (props) => {
    const [configRecordFormPopup, setConfigRecordFormPopup] = useState({
                                                                        fullScreen: true,
                                                                        dragEnabled: false,
                                                                        icon: "unselectall"
                                                                      })

    return(
        <Popup
            visible={props.popupState}
            onHiding={()=>{
                props.setPopupState(false)
            }}
            dragEnabled={configRecordFormPopup.dragEnabled}
            fullScreen={configRecordFormPopup.fullScreen}
            hideOnOutsideClick={false}
            animation={null}
            toolbarItems={
                [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                            icon: configRecordFormPopup.icon, 
                            onClick: () => {
                                if(configRecordFormPopup.icon === "square") {
                                    setConfigRecordFormPopup(
                                        {
                                        fullScreen: true,
                                        dragEnabled: false,
                                        icon: "unselectall"
                                        }
                                    ) 
                                } else {
                                    setConfigRecordFormPopup(
                                        {
                                        fullScreen: false,
                                        dragEnabled: true,
                                        icon: "square"
                                        }
                                    )
                                }
                            }
                    }
                }
                ]}
            showCloseButton={true}
            showTitle={true}
            // title="teste"
            id="popupForm"
        >
                <ScrollView width='100%' height='100%' useNative={true}>
                    <ViewForm
                        id={props.masterViewId}
                        caption={props.caption}
                        viewFieldId={props.viewFieldId}
                        viewFieldName={props.viewFieldName}
                        selectedItemsId={props.selectedItemsId}
                        telecob={props.telecob}
                    />
                </ScrollView>
        </Popup>
    )
}

export default memo(ViewFormPopup);