import MultiView from 'devextreme-react/multi-view';
import {useState, useEffect, useCallback} from "react"
import ScrollView from 'devextreme-react/scroll-view';
import Passo1 from "./EtapasAcordo/Passo1"
import Passo2 from "./EtapasAcordo/Passo2"
import Passo3 from "./EtapasAcordo/Passo3"
import Boleto from './EtapasAcordo/Boleto';
import "./style.css"

const GerarAcordoIturanCustomForm = (props) => {
    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()
    const [indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo] = useState(0)
    const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState()
    
    const[arrayPendenciasProcessadasState, setArrayPendenciasProcessadasState] = useState("")
    const[boletoState, setBoletoState] = useState()

    const [listaSolucaoState, setListaSolucaoState] = useState([])
    const [listaOcorrenciaState, setListaOcorrenciaState] = useState([])
    const [totalCobrancaState, setTotalCobrancaState] = useState()
    const [descontoTotalCobrancaState, setDescontoTotalCobrancaState] = useState()
    // const[arrayState,setArrayState] = useState()

    const [states, setStates] = useState({
        dataSource: null
    })
    const multiViewItems = [
        {
            //  title: itemClicado.itemData.text
            // ,icon: itemClicado.itemData.icon
            className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <Passo1 
                                tituloId={props.tituloId}
                                userType={"operador"}
                                showTitle={props.showTitle}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                            />
                        </ScrollView>
            // ,text: Math.random()
            // ,type: itemClicado.itemData.path
        },
        {
        //     title: itemClicado.itemData.text
        //    ,icon: itemClicado.itemData.icon
           className: "tab-sheet"
           //,icon: '/images/favicon.ico'
           ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                           <Passo2
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                setArrayPendenciasProcessadasState={setArrayPendenciasProcessadasState}
                                arrayPendenciasProcessadasState={arrayPendenciasProcessadasState}
                                setListaSolucaoState={setListaSolucaoState}
                                setListaOcorrenciaState={setListaOcorrenciaState}
                                setBoletoState={setBoletoState}
                                setTotalCobrancaState={setTotalCobrancaState}
                                setDescontoTotalCobrancaState={setDescontoTotalCobrancaState}
                                descontoTotalCobrancaState={descontoTotalCobrancaState}
                                // setBoletoBinarioId={setBoletoBinarioId}
                           />
                       </ScrollView>
           ,text: Math.random()
        //    ,type: itemClicado.itemData.path
        },
        {
        className: "tab-sheet"
        ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                        <Passo3 
                            tituloId={props.tituloId}
                            clienteId={props.clienteId}
                            setListaSolucaoState={setListaSolucaoState}
                            indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                            setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                            gerarAcordoDataSource={gerarAcordoDataSource}
                            setGerarAcordoDataSource={setGerarAcordoDataSource}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            setArrayPendenciasProcessadasState={setArrayPendenciasProcessadasState}
                            arrayPendenciasProcessadasState={arrayPendenciasProcessadasState}
                            listaSolucaoState={listaSolucaoState}
                            listaOcorrenciaState={listaOcorrenciaState}
                            boletoState={boletoState}
                            totalCobrancaState={totalCobrancaState}
                            setTotalCobrancaState={setTotalCobrancaState}
                            setDescontoTotalCobrancaState={setDescontoTotalCobrancaState}
                            descontoTotalCobrancaState={descontoTotalCobrancaState}

                        />
                    </ScrollView>
        ,text: Math.random()
        },
        {
            className: "tab-sheet"
            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                            <Boleto 
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                boletoState={boletoState}
                            />
                        </ScrollView>
            ,text: Math.random()
            }
    ]

    // useEffect(() => {
    //     console.log(indexMultiViewItemsGerarAcordo)
    //     console.log(gerarAcordoDataSource)
    // }, [indexMultiViewItemsGerarAcordo, gerarAcordoDataSource])

    return(
        <div>
            <MultiView
                items={multiViewItems} 
                deferRendering={false}
                itemRender={contentTab}
                selectedIndex={indexMultiViewItemsGerarAcordo}
                tabIndex={indexMultiViewItemsGerarAcordo}
                repaintChangesOnly={true}
                animationEnabled={false}
                swipeEnabled={false}
                showNavButtons={true}
                scrollByContent={true}
                selectedItem={multiViewItems[indexMultiViewItemsGerarAcordo]}
            />
        </div>
    )
}

export default GerarAcordoIturanCustomForm;