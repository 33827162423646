import { useCallback, useEffect, useRef, useState } from 'react'
import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    GroupItem,
    HeaderFilter,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    FilterRow,
    TotalItem
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { httpRequest } from '../../../../functions';
import { applicationID, relationId } from '../../../../api/ApplicationID';
import UpfHtmlEditor from '../../../UpfComponents/UpfHtmlEditor';
import { formatFunction } from '../../../../functions/formatFunction';
import notify from 'devextreme/ui/notify';
import { validatePermission } from '../../../../functions/validatePermission';
import { changeDataRowColor } from '../../../../functions/changeDataRowColor';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { useIndexTabPanelMain } from '../../../../Context/IndexTabPanelMain';
import { useTabPanelItems } from '../../../../Context/TabPanelItems';
import RecordForm from '../..';
import { ScrollView } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import UpfPasswordComponent from '../../../UpfComponents/UpfPasswordComponent';
import { convertDate } from '../../../../functions/convertDate';
import {useQuery} from '@tanstack/react-query'
import UpfAudio from '../../../UpfComponents/UpfAudio';
import ExportingExcel from '../../../../CardComponents/ExportingExcel';
import ExportingPDF from '../../../../CardComponents/ExportingPDF';
import UpfPopupConfirmContextMenu from '../../../../CardComponents/UpfPopupConfirmContextMenu';
import { calculateCustomSummary } from '../../../../functions/calculateCustomSummary';
import $ from "jquery"

const TabPanelDetailEntityItem = (props) => {
    const refDataGrid = useRef(null);
    const allowedPageSizes = [10, 25, 50, 100];
    const { requestsApi } = useRequestsApi()
    const { setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();

    const [modeEditDataGrid, setModeEditDataGrid] = useState({
        edit: false
    })
     

    const [dataSource, setDataSource] = useState();  
    const [ TabPanelDetailEntityItemState, SetTabPanelDetailEntityItemState ] = useState()


    const [selectedItensCount, setSelectedItensCount] = useState()
    const [selectedItens, setSelectedItens] = useState()

    const [dadosDaEntidadeDetalheState, setDadosDaEntidadeDetalheState] = useState()
    const [estruturaDaEntidadeDetalheState, setEstruturaDaEntidadeDetalheState] = useState()

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    const refreshDetailEntity = async (entityName, keyFieldName) => {
        props.showLoadPanel()

        //VAI TRAZER TODAS AS ESTRUTURAS DAS DETALHES DA MASTER 
        let detailEntitiesItensStructure = props.entityStructure[0].detailEntities[0].detailEntity

        //VAI PROCURAR A ESTRUTURA DA DETALHE Q CHAMOU O REFRESH
        const entityStructureCurrentDetail = detailEntitiesItensStructure.find((e) => e.entityName === entityName)

        const arrayLookupsCurrentDetailEntity = []

        //separa so as lookups da entidade atual para fazer a request
        for(let c = 0; c <= entityStructureCurrentDetail?.fieldsDefs.length - 1; c++){
            if(entityStructureCurrentDetail?.fieldsDefs?.[c]?.lookUpListSource){
                //checar nos fields da entidade detalhe se existe algum com lookupListSource, se tiver chama getLookupTable 
                if(props?.lookUpEntities?.find((e) => e.lookUpEntity === entityStructureCurrentDetail?.fieldsDefs?.[c]?.lookUpListSource)){
                    arrayLookupsCurrentDetailEntity.push(props.lookUpEntities.find((e) => e.lookUpEntity === entityStructureCurrentDetail?.fieldsDefs?.[c]?.lookUpListSource))
                }
            }
        }

        if (arrayLookupsCurrentDetailEntity.length > 0) {
            await httpRequest("POST",
                requestsApi.getLookupTable, requestsApi.basicAuth,
                {
                    "lookUpEntities": arrayLookupsCurrentDetailEntity
                }
            )
            .then((sender) => {
                const senderDataLookup = JSON.parse(sender)
                const lookupsObj = {...props.dataLookup, ...senderDataLookup}
                props.setDataLookup(lookupsObj)

                httpRequest('POST',
                    requestsApi.getDataEntity, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID
                        , "entityName": entityName
                        , "fieldName": keyFieldName
                        , "expressao": props.dataEntity.masterEntity[0]?.records?.[0]?.id
                    }
                )
                .then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity); 

                    setDadosDaEntidadeDetalheState(resultGetDataEntity.masterEntity[0]?.records)
                    convertDate(resultGetDataEntity.masterEntity[0]?.records, entityStructureCurrentDetail?.fieldsDefs)

                    props.hideLoadPanel()
                })
                .catch((error) => {
                    setDadosDaEntidadeDetalheState([])

                    // console.log(refDataGrid.current.instance.getDataSource())

                    // var res = refDataGrid.current.instance.getDataSource()
                    // // res.filter(["ID", "=", "0"], "AND", ["Cond", "=", ""]);
                    // refDataGrid.current.instance.option('dataSource', []);

                    // res.reload();
                    // console.log(refDataGrid.current.instance.getDataSource())

                    props.hideLoadPanel()
                    console.log(error)
                    // if(!tentarNovamente){
                    //     refreshDetailEntity(entityName, keyFieldName, true)
                    // }

                })

                
            })
            .catch((error) => {
                props.hideLoadPanel()
                console.log(error)
            })
        }
        else{
            await httpRequest('POST',
                    requestsApi.getDataEntity, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID
                        , "entityName": entityName
                        , "fieldName": keyFieldName
                        , "expressao": props.dataEntity.masterEntity[0]?.records?.[0]?.id
                    }
                )
                .then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity); 

                    setDadosDaEntidadeDetalheState(resultGetDataEntity.masterEntity[0]?.records)

                    const tempDataEntityDetailEntity = props.dataEntityDetailEntity

                    let indiceDataEntityDetalheModificada = null 

                    for(let c = 0; c <= tempDataEntityDetailEntity.length - 1; c++){
                        if(tempDataEntityDetailEntity?.[c].entityName === resultGetDataEntity.masterEntity?.[0]?.entityName){
                            indiceDataEntityDetalheModificada = c
                        }
                    }
                    tempDataEntityDetailEntity[indiceDataEntityDetalheModificada] = resultGetDataEntity.masterEntity[0]
                    props.setDataEntityDetailEntity(tempDataEntityDetailEntity)

                    convertDate(resultGetDataEntity.masterEntity[0]?.records, entityStructureCurrentDetail?.fieldsDefs)
                    refDataGrid.current.instance.refresh()

                    props.hideLoadPanel()
                })
                .catch((error) => {
                    setDadosDaEntidadeDetalheState([])
                    // console.log(refDataGrid.current.instance.getDataSource())
                    // var res = refDataGrid.current.instance.getDataSource()
                    // res.filter(["ID", "=", "0"], "AND", ["Cond", "=", ""])
                    // res.reload();
                    // refDataGrid.current.instance.refresh()
                    // console.log(refDataGrid.current.instance.getDataSource())
                    props.hideLoadPanel()
                    console.log(error)
                    // if(!tentarNovamente){
                    //     refreshDetailEntity(entityName, keyFieldName, true)
                    // }
                })
        }


    }


    useEffect(() => {
        // SetTabPanelDetailEntityItemState(false)
        // refDataGrid.current.instance.repaint()
        // refDataGrid.current.instance.refresh()

        let entity = [];

        const nomeEntidadeAtual = props.entityNameDetail


        setEstruturaDaEntidadeDetalheState(props.senderDetailEntity)

        
        if(props.dataEntityDetailEntity.length > 0){
            const arrayDataEntityDetails = props.dataEntityDetailEntity 

            if(arrayDataEntityDetails.find((el) => el.entityName.toLowerCase() === nomeEntidadeAtual)?.records){
                entity = arrayDataEntityDetails.find(el => el.entityName.toLowerCase() === nomeEntidadeAtual).records
            }   
        }

        console.log(entity)

        const entityDataSource = entity;
        if (entity.length === 0) {            
            entityDataSource.push({
                id: ""
            });
            entityDataSource.pop();
        }
        setDadosDaEntidadeDetalheState(entityDataSource)

    }, []);

    useEffect(() => {
        if(dadosDaEntidadeDetalheState){
            SetTabPanelDetailEntityItemState(false)
        }
    }, [
        estruturaDaEntidadeDetalheState,
        ,dadosDaEntidadeDetalheState
        ,props.dataEntityDetailEntity
        ,props.senderDetailEntity.entityName
        ,props.entityNameDetail
        ,props.reloadTabPanelDetailState
        ,props.entityStructure
        ,props.dataLookup
        ,visibleConfirmContextMenuState
        ,contextMenuItemState
        ,modeEditDataGrid
        ,selectedItens
        ,selectedItensCount
    ]);

    const actionExportPDF = useCallback(() => {
        if (refDataGrid) {
            ExportingPDF(refDataGrid, props.caption)
        }
    }, [])
    const actionExportExcel = useCallback(() => {
        if (refDataGrid) {
            ExportingExcel(refDataGrid, props.caption);
        }
    }, [])

    const savePDFButtonOptions = {
        text: 'Exportar para PDF'
        , icon: 'exportpdf'
        , onClick: actionExportPDF
    };
    const saveExcelButtonOptions = {
        text: 'Exportar para Excel'
        , icon: 'exportxlsx'
        , onClick: actionExportExcel
    };

    const [arrayLookupDataSourceEntityFields, setArrayLookupDataSourceEntityFieldsState] = useState([])

    const getEntityFieldsFunction = async () => {
        if(props.dataEntity.masterEntity?.[0]?.records?.[0]?.masterentity?.length > 0){
            await httpRequest('POST', requestsApi.getEntityFields, requestsApi.basicAuth,
            {
                "applicationId": applicationID
                ,"entityName": props.dataEntity.masterEntity?.[0]?.records?.[0]?.masterentity 
            }
            )
            .then((sender) => {
                const resultgetEntityFieldsJson = JSON.parse(sender)
                setArrayLookupDataSourceEntityFieldsState(resultgetEntityFieldsJson)
            })
            .catch((error) => {
                props.hideLoadPanel()
                console.log(error)
            })
        }
    }

    const arrayLookupDataSourceEntityFieldsQuery = useQuery({ queryFn: getEntityFieldsFunction })

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    function onCellPreparedFunctionColor(e, item) {
        // console.log(item)
        // console.log(e)
        changeDataRowColor(e, item?.rowColor)
    }

    const onClickMenuContextItem = (item) => {
        if(!item.itemData?.askConfirm){
            // props.setReloadTabPanelDetailState(!props.reloadTabPanelDetailState)
            // SetTabPanelDetailEntityItemState(false)
            const selectedRowsData = refDataGrid.current.instance.getSelectedRowsData()

            setSelectedItens(selectedRowsData)
            setSelectedItensCount(selectedRowsData.length)


            console.log(item)
            if (item.itemData.componentName === "recordForm") {
                props.showLoadPanel()
                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: item.itemData.componentParameter,
                    applicationId: applicationID
                })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)

                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {

                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)
                                    props.hideLoadPanel()

                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                            title: `${item.itemData.componentParameter}`
                                            // ,icon: itemClicado.itemData.icon
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <RecordForm
                                                        entityName={item.itemData.componentParameter}
                                                        idValue={selectedRowsData[0].id}
                                                        dataLookup={senderDataLookup}
                                                        entityStructure={resultGetEntityStructureJson}
                                                    />
                                                </ScrollView>
                                            , text: Math.random()
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)

                                })
                                .catch((error) => { 
                                    console.log(error)
                                    props.hideLoadPanel()
                                })
                        }
                        else {
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    title: `${item.itemData.componentParameter}`
                                    // ,icon: itemClicado.itemData.icon
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                entityName={item.itemData.componentParameter}
                                                idValue={selectedRowsData[0].id}
                                                entityStructure={resultGetEntityStructureJson}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        }
                    })
                    .catch((error) => { 
                        console.log(error)
                        props.hideLoadPanel()
                    })
            }


            //CODIGO PARA QUANDO CLICAR COM O BOTÃO DIREITO ABRA O GERAR ACORDO
            // else if(sender.itemData.componentName === "gerarAcordo") {
            //     setTabPanelItems([
            //         ...tabPanelItems,
            //         {
            //             title: `${sender.itemData.componentParameter}`
            //             // ,icon: itemClicado.itemData.icon
            //             ,component: 
            //                 <ScrollView  width='100%' height='100%'>
            //                         <GerarAcordoForm 
            //                            
            //                         />
            //                 </ScrollView>
            //             ,text: Math.random()
            //         }
            //     ])
            //     setIndexTabPanelMain(tabPanelItems.length)
            // }
            else if (item.itemData.componentName === "gerarBoletoForm") {
                let parcelasIdArray = []

                for (let a = 0; a <= selectedRowsData?.length - 1; a++) {
                    parcelasIdArray.push(selectedRowsData[a].id)
                }
                let dataBoletoForm = props.dataEntity?.masterEntity?.[0]?.records?.[0]
                props.showUpfPopupForm(
                    dataBoletoForm._clienteid,
                    dataBoletoForm._credorid,
                    dataBoletoForm.id,//tituloId
                    "Boleto",
                    item._execute,
                    parcelasIdArray
                )
            }
            else if (item.itemData.componentName === "gerarAcordoForm") {
                let parcelasIdArray = []

                for (let a = 0; a <= selectedRowsData?.length - 1; a++) {
                    parcelasIdArray.push(selectedRowsData[a].id)
                } 

                let dataAcordoForm = props.dataEntity?.masterEntity?.[0]?.records?.[0]
                props.showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "Acordo/Novação de dívida",
                    item._execute,
                    parcelasIdArray
                )
            }
            else if (item.itemData.componentName === "htmlForm") {
                let idValueJson = []

                for (let a = 0; a <= selectedRowsData?.length - 1; a++) {
                    idValueJson.push(selectedRowsData[a].id)
                }
                props.showLoadPanel()
                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson[0],
                        "parameter": item.itemData.componentParameter,
                        "entityName": props.entityNameDetail
                    })
                    .then((sender) => {
                        const resultJsonTemplateHtml = JSON.parse(sender)
                        props.toastConfiguration(true, 'info', "Arquivo gerado com sucesso!")
                        props.hideLoadPanel()
                        props.setHtmlTemplate(() => {
                            return { __html: resultJsonTemplateHtml.mensagem }
                        })
                        props.setHtmlFormPopupDataSourceState(item.itemData.componentParameter)
                        props.setHtmlFormPopupIdState(idValueJson[0])
                        props.setHtmlPopupVisible(true)
                        props.setHtmlPopupEntityNameState(props.entityNameDetail)

                    })
                    .catch((error) => {
                        props.hideLoadPanel()
                        console.log(error)
                        if (error?.data?.error) {
                            props.hideLoadPanel()
                            props.setMessageError(error.data.error)
                            props.setPopup({
                                ...props.popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }

            else if (item.itemData.methodName) {
                console.log(item.itemData.methodName)
                console.log(item.itemData.componentParameter)

                let idValueJson = []
                
                for (let a = 0; a <= selectedRowsData?.length - 1; a++) {
                    idValueJson.push(selectedRowsData[a].id)
                }
                props.showLoadPanel()

                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson,
                        "parameter": item.itemData.componentParameter,
                        "entityName": props.entityNameDetail
                    })
                    .then((sender) => {
                        props.hideLoadPanel()
                        const response = JSON.parse(sender)
                        refreshDetailEntity(props.entityNameDetail, props.senderDetailEntity.keyFieldName)
                        props.toastConfiguration(true, 'info', response.mensagem)

                    })
                    .catch((error) => {
                        props.hideLoadPanel()
                        if (error?.data?.error) {
                            props.hideLoadPanel()
                            props.setMessageError(error.data.error)
                            props.setPopup({
                                ...props.popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const openMenuContextFunction = (e) => {
        
        let entityNameDetailEntity = props.entityStructure[0]?.detailEntities[0]?.detailEntity.find(element => element.entityName === props.entityNameDetail)

       console.log(e?.row?.data)
        if (entityNameDetailEntity?.contextMenu !== null && e.row.data) {
            let menuContextArray = []
            for (let c = 0; c <= entityNameDetailEntity?.contextMenu?.length - 1; c++) {
                let permissoesItem = validatePermission(entityNameDetailEntity?.["contextMenu"][c]?.objectid)
                let contextMenuItem = {
                    "text": entityNameDetailEntity.contextMenu[c].caption,         
                    "icon": entityNameDetailEntity.contextMenu[c].icon,
                    "onItemClick": onClickMenuContextItem,
                    "componentName": entityNameDetailEntity.contextMenu[c].componentName,
                    "componentParameter": entityNameDetailEntity.contextMenu[c].componentParameter,
                    "idFieldName": entityNameDetailEntity.contextMenu[c].idFieldName,
                    "methodName": entityNameDetailEntity.contextMenu[c].methodName,
                    "_execute": permissoesItem?._execute,
                    "askConfirm": entityNameDetailEntity.contextMenu[c].askConfirm
                }    
                

                if(permissoesItem?._show === 1){
                    if(entityNameDetailEntity?.contextMenu[c].conditionalVisibleFieldName && entityNameDetailEntity?.contextMenu[c].conditionalVisibleFieldValue){
                        const arrayFieldsData = props.dataEntityDetailEntity.find((e) => e.entityName === props.entityNameDetail)?.records
                       
                        for(let i = 0; i <= arrayFieldsData.length - 1; i++){
                            if(arrayFieldsData[i][`${entityNameDetailEntity?.contextMenu[c].conditionalVisibleFieldName.toLowerCase()}`] == entityNameDetailEntity?.contextMenu[c].conditionalVisibleFieldValue){
                                menuContextArray.push(contextMenuItem)
                            }
                        }
                    }
                    else{ 
                        menuContextArray.push(contextMenuItem)
                    }
                }

            }

            if (e.target === "content" && refDataGrid.current.instance.getSelectedRowsData()?.length > 0) {
                if (e.row) {
                    if (e.row.rowType === "data") {
                        console.log(e.items)
                        console.log("states.selectedItensCount")
                        e.items = menuContextArray
                        console.log(e.items)

                    }
                }
            }
        }
    }

    const fieldsPermissionUpdateArray = []

    const handleBatchSave = async (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const insertFormData = {
                    ...i.data,
                    [estruturaDaEntidadeDetalheState.keyFieldName]: props.dataEntity.masterEntity[0]?.records?.[0]?.id
                };
                insertArr.push(insertFormData);
            },
            update: i => {
                const dataObjPermissionUpdate = {
                    "id": i.key
                };

                const fieldsNotPermissionUpdateArray = []

                const dataKeysArray = Object.keys(i.data)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = i.data[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };

                if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                    updateArr.push(dataObjPermissionUpdate);
                } 
                // else {
                //     refreshDetailEntity(props.entityNameDetail, props.senderDetailEntity.keyFieldName);
                // }

                if(fieldsNotPermissionUpdateArray.length > 0){
                    let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                    for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                        mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                    }

                    mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."

                    props.setMessageError(mensagemErroCamposSemPermissao)
                    props.setPopup({
                        ...props.popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            await props.recordInsertJsonDetail(estruturaDaEntidadeDetalheState.entityName, insertArr, refDataGrid);

            // * Só faz o refresh se tiver algum insert
            refreshDetailEntity(props.entityNameDetail, props.senderDetailEntity.keyFieldName);
        }
        if (updateArr.length > 0) {
            props.recordUpdateJsonDetail(estruturaDaEntidadeDetalheState.entityName, updateArr, refDataGrid);
        }

        setModeEditDataGrid({
            edit: false
        })

    };

    const onRowInserting = (e) => {                                  
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }

    const onRowRemoved = (e) => {
        props.recordDeleteJsonDetail(estruturaDaEntidadeDetalheState.entityName, e.key)
        refreshDetailEntity(props.entityNameDetail, props.senderDetailEntity.keyFieldName)
        setModeEditDataGrid({
            edit: false
        })
    }

    const onEditorPreparing = (e) => {
        if(e.editorName === "dxCheckBox"){
            e.editorOptions.value = e.value === null ? false : e.value
        }
    }

    const onCellPrepared = (e) => {
        onCellPreparedFunctionColor(e, estruturaDaEntidadeDetalheState)
    }

    const onRowDblClick = (item) => {
        if (!modeEditDataGrid.edit && props.itemPermissions?._show === 1) {

            if (estruturaDaEntidadeDetalheState?.customRecordForm === 'boletoForm') {
                //codigo para chamar o ConsultaBoletoForm

                //pegar as lookups
                httpRequest("POST", props.requestsApi.getLookupTable, props.requestsApi.basicAuth, {
                    "lookUpEntities": [
                        {
                            "lookUpEntity": "TipoNegociacao"
                        },
                        {
                            "lookUpEntity": "OpcaoNegociacao"
                        },
                        {
                            "lookUpEntity": "_tipo_contato"
                        },
                        {
                            "lookUpEntity": "_pontuacao_contato"
                        }
                    ]
                })
                    .then((sender) => {
                        const resultGetLookupTableJson = JSON.parse(sender)

                        //para pegar o email do cliente
                        httpRequest('POST', props.requestsApi.getEntityRecords, props.requestsApi.basicAuth, {
                            "applicationId": applicationID,
                            "entityName": "_cadastro",
                            "fieldName": "id",
                            "expressao": props.dataEntity.masterEntity[0]?.records?.[0]?._clienteid
                        })
                            .then((sender) => {
                                const resultSenderJsonCliente = JSON.parse(sender)

                                console.log(resultSenderJsonCliente)

                                //para pegar os telefones
                                httpRequest('POST', props.requestsApi.getEntityRecords, props.requestsApi.basicAuth, {
                                    "applicationId": applicationID,
                                    "entityName": "_cadastro_contatos",
                                    "fieldName": "_cadastro_id",
                                    "expressao": props.dataEntity.masterEntity[0]?.records?.[0]?._clienteid
                                })
                                    .then((sender) => {
                                        const resultSenderJsonTelefones = JSON.parse(sender)

                                        props.setDataSourceBoletoState({
                                            ...props.dataSourceBoletoState,
                                            telefones: resultSenderJsonTelefones.records,
                                            boleto: item.data.id,
                                            email: resultSenderJsonCliente.records[0]._cryptemail,
                                            dataLookup: resultGetLookupTableJson,
                                            masterEntityId: props.dataEntity.masterEntity[0]?.records?.[0]?._clienteid
                                        })

                                        props.setConsultaBoletoFormPopupState(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        //gerou boleto porem n tem contatos
                                        props.setDataSourceBoletoState({
                                            ...props.dataSourceBoletoState,
                                            telefones: [],
                                            boleto: item.data.id,
                                            email: resultSenderJsonCliente.records[0]._cryptemail,
                                            dataLookup: resultGetLookupTableJson,
                                            masterEntityId: props.dataEntity.masterEntity[0]?.records?.[0]?._clienteid
                                        })

                                        props.setConsultaBoletoFormPopupState(true)
                                    })
                            })
                            .catch((err) => {
                                console.log(err)
                                //gerou boleto porem n tem contatos
                                props.setDataSourceBoletoState({
                                    ...props.dataSourceBoletoState,
                                    telefones: [],
                                    boleto: item.data.id,
                                    email: "",
                                    dataLookup: resultGetLookupTableJson,
                                    masterEntityId: props.dataEntity.masterEntity[0]?.records?.[0]?._clienteid
                                })

                                props.setConsultaBoletoFormPopupState(true)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        // setMessage(error.data.error)
                        // setVisible(true)
                    })
            }

            else if (estruturaDaEntidadeDetalheState?.customRecordForm === "historicoForm") {
                props.setHistoricoCustomFormPopupVisibleState(true)
            }

            else {
                httpRequest('POST', props.requestsApi.getEntityStructure, props.requestsApi.basicAuth, {
                    applicationId: applicationID,
                    entityName: estruturaDaEntidadeDetalheState.entityName
                })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)

                        props.setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                            if(props.objectLookUpEntityViewsTipoProcessamento){
                                const lookUpEntityViewsTipoProcessamento = resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpEntity === "viewstipoprocessamentolookup")
                                
                                if(lookUpEntityViewsTipoProcessamento){
                                    resultGetEntityStructureJson[0]?.lookUpEntities.splice(resultGetEntityStructureJson[0]?.lookUpEntities.indexOf(lookUpEntityViewsTipoProcessamento), 1, props.objectLookUpEntityViewsTipoProcessamento)
                                }
                                else{
                                    resultGetEntityStructureJson[0]?.lookUpEntities?.push(props.objectLookUpEntityViewsTipoProcessamento)
                                }
                            }
                            httpRequest("POST", props.requestsApi.getLookupTable, props.requestsApi.basicAuth, {
                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)
                                    props.setDataLookupRecordFormPopupState(senderDataLookup)

                                    props.setIDValueDetailEntity(item.data.id)
                                    props.setDetailEntityName(estruturaDaEntidadeDetalheState.entityName)
                                    props.setDetailCaptionName(estruturaDaEntidadeDetalheState.caption)
                                    props.setPopupRecordFormOpen(true)
                                    props.setPopupState(true)
                                    props.setPopupRecordFormAdd(false)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        else {

                            props.setIDValueDetailEntity(item.data.id)
                            props.setDetailEntityName(estruturaDaEntidadeDetalheState.entityName)
                            props.setDetailCaptionName(estruturaDaEntidadeDetalheState.caption)
                            props.setPopupRecordFormOpen(true)
                            props.setPopupState(true)
                            props.setPopupRecordFormAdd(false)
                        }
                    }) 
            }
        }
        else if(!modeEditDataGrid?.edit) {
            props.setMessageError("Usuário não tem permissão!")
            props.setPopup({
                ...props.popup
                , isVisible: true
                , message: "Atenção!"
            })
        }
    }

    const onSelectionChanged = (e) => e.component.refresh(true);

    const addRow = useCallback(() => {
        refDataGrid.current.instance.addRow();
        refDataGrid.current.instance.deselectAll();
      }, [refDataGrid]);

    if (!TabPanelDetailEntityItemState && estruturaDaEntidadeDetalheState){

        SetTabPanelDetailEntityItemState(
            <div className="detail-record">
            <div className="btn-tabPanel">
                <div className="buttons-tabpanel">
                    <Button
                        icon="add"
                        hint={`Incluir um novo registro em ${estruturaDaEntidadeDetalheState?.caption}`}
                        disabled={props.itemPermissions ? (!props.itemPermissions?._insert) : (true)}
                        type="normal"
                        onClick={() => {  
                            if(modeEditDataGrid.edit){
                                addRow()
                            }
                            else{                          
                                if (estruturaDaEntidadeDetalheState?.customInsertRecordForm === "gerarBoletoForm") {
                                    let dataBoletoForm = props.dataEntity?.masterEntity?.[0]?.records?.[0]
                                    props.showUpfPopupForm(
                                        dataBoletoForm?._clienteid,
                                        dataBoletoForm?._credorid,
                                        dataBoletoForm?.id, // tituloId
                                        "Boleto"
                                    )
                                }
                                if (estruturaDaEntidadeDetalheState?.customInsertRecordForm === "historicoForm") {
                                    props.getOcorrencias()
                                } else if (estruturaDaEntidadeDetalheState?.customInsertRecordForm === "tarefaHistoricoForm"){
                                    props.getOcorrenciasTarefa()
                                } else if (estruturaDaEntidadeDetalheState?.customInsertRecordForm === "gerarBoletoForm") {
                                    let dataBoletoForm = props.dataEntity?.masterEntity?.[0]?.records?.[0]
                                    props.showUpfPopupForm(
                                        dataBoletoForm?._clienteid,
                                        dataBoletoForm?._credorid,
                                        dataBoletoForm?.id,//tituloId
                                        "Boleto"
                                    )
                                } else {
                                    console.log("Incluir um novo registro detalhe else")

                                    httpRequest('POST', props.requestsApi.getEntityStructure, props.requestsApi.basicAuth, {
                                        entityName: props.entityNameDetail,
                                        applicationId: applicationID,
                                    })
                                    .then((sender) => {
                                        props.setEntityStructureRecordFormPopupState(JSON.parse(sender))
                                        const resultGetEntityStructureJson = JSON.parse(sender)
    
                                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {

                                            if(props.objectLookUpEntityViewsTipoProcessamento){
                                                const lookUpEntityViewsTipoProcessamento = resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpEntity === "viewstipoprocessamentolookup")
                                                
                                                if(lookUpEntityViewsTipoProcessamento){
                                                    resultGetEntityStructureJson[0]?.lookUpEntities.splice(resultGetEntityStructureJson[0]?.lookUpEntities.indexOf(lookUpEntityViewsTipoProcessamento), 1, props.objectLookUpEntityViewsTipoProcessamento)
                                                }
                                                else{
                                                    resultGetEntityStructureJson[0]?.lookUpEntities?.push(props.objectLookUpEntityViewsTipoProcessamento)
                                                }
                                            }

                                            httpRequest('POST', props.requestsApi.getLookupTable, props.requestsApi.basicAuth, {
                                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                                            })
                                            .then((sender) => {
                                                props.setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                props.setKeyFieldNameState(estruturaDaEntidadeDetalheState.keyFieldName)
                                                props.setIDValueDetailEntity(null)
                                                props.setDetailEntityName(props.entityNameDetail)
                                                props.setDetailCaptionName(estruturaDaEntidadeDetalheState.caption)
                                                props.setPopupRecordFormOpen(true)
                                                props.setPopupState(true)
                                                props.setStateModeEdit(true)
                                            })
                                            .catch((error) => {
                                                console.log(error)
                                            })
                                        }
                                        else {
                                            console.log("Incluir um novo registro detalhe request")
                                            console.log(props)
                                            props.setKeyFieldNameState(estruturaDaEntidadeDetalheState.keyFieldName)
                                            props.setIDValueDetailEntity(null)
                                            props.setDetailEntityName(props.entityNameDetail)
                                            props.setDetailCaptionName(estruturaDaEntidadeDetalheState.caption)
                                            props.setPopupRecordFormOpen(true)
                                            props.setPopupState(true)
                                            props.setStateModeEdit(true)
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                }
                            }
                        }}
                    />
                </div>

                <div className="buttons-tabpanel">
                    <Button
                        icon="rowproperties"
                        hint="Editar registros em lote no grid"
                        type="normal"
                        onClick={() => {
                            setModeEditDataGrid({
                                edit: !modeEditDataGrid.edit
                            })
                        }}
                    />
                </div>

                <div className="buttons-tabpanel">
                    <Button
                        icon="refresh"
                        hint="Atualizar dados do servidor"
                        type="normal"
                        onClick={() => {
                            refreshDetailEntity(props.entityNameDetail, props.senderDetailEntity.keyFieldName);
                            //refDataGrid.current.instance.refresh();
                        }}
                    />
                </div>

                <div className="buttons-tabpanel">
                    <Button
                        icon="columnchooser"
                        hint="Mostrar ou esconder colunas do grid"
                        type="normal"
                        onClick={() => {
                            refDataGrid.current.instance.showColumnChooser()
                        }}
                    />
                </div>

                {/* {estruturaDaEntidadeDetalheState.excelExport === 1 && */}
                <div className="buttons-tabpanel">
                    <Button
                        icon="exportxlsx"
                        hint={`Exportar para Excel`}
                        type="normal"
                        onClick={() => {
                            actionExportExcel()
                        }}
                    />
                </div>
                {/* } */}

                <div className="buttons-tabpanel">
                    <Button
                        icon="textdocument"
                        hint={`Log`}
                        type="normal"
                        onClick={() => {
                            props.openLogCustomForm(estruturaDaEntidadeDetalheState, dadosDaEntidadeDetalheState)
                        }}
                    />
                </div>

                {/* <div className="buttons-tabpanel">
                    <Button
                        icon="exportpdf"
                        hint={`Exportar para PDF`}
                        type="normal"
                        onClick={() => {
                            actionExportPDF()
                        }}
                    />
                </div> */}

                <div className="buttons-tabpanel">
                    <Button
                        icon="preferences"
                        disabled={props.itemPermissions ? (!props.itemPermissions?._configure) : (true)}
                        hint={`Configurar o formulário de ${estruturaDaEntidadeDetalheState.caption}`}
                        type="normal"
                        onClick={() => {
                            props.configRecordFormStructureDetail(estruturaDaEntidadeDetalheState.keyFieldName, props.entityNameDetail)
                        }}
                    />
                </div>

                {/* <div className="buttons-tabpanel">
                        <SelectBox
                            dataSource={['teste1', 'teste2']}
                            // displayExpr={"fieldCaption"}
                            // valueExpr={"fieldName"}
                        />
                    </div>

                    <div className="buttons-tabpanel">
                        <SelectBox
                            dataSource={['teste1', 'teste2']}
                            // displayExpr={"fieldCaption"}
                            // valueExpr={"fieldName"}
                        />
                    </div> */}
            </div>

            {visibleConfirmContextMenuState &&
                <UpfPopupConfirmContextMenu
                    hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                    visibleConfirmContextMenu={visibleConfirmContextMenuState}
                    contextMenuFunction={onClickMenuContextItem}
                    contextMenuItemState={contextMenuItemState}
                />
            }

            <DataGrid
                //onEditingStart: event when click to edit or add new registry
                ref={refDataGrid}
                keyExpr="id"
                className="datagrid-detail-entity"
                dataSource={dadosDaEntidadeDetalheState}
                errorRowEnabled={false}
                //repaintChangesOnly={true} (pode afetar a performance) comentado 17/05/2022, pois quando agrupava no grid e tentava editar e depois salvar quebrava o groupIndex                
                renderAsync={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onRowInserting={onRowInserting}
                onRowRemoved={onRowRemoved}
                onSaving={handleBatchSave}                
                onEditorPreparing={onEditorPreparing}
                onCellPrepared={onCellPrepared}
                onRowDblClick={onRowDblClick}
                allowColumnReordering={true}
                showBorders={true}
                rowAlternationEnabled={true}
                focusedRowEnabled={true}
                focusedRowKey={0}
                columnMinWidth={50}
                onContextMenuPreparing={openMenuContextFunction}
                onSelectionChanged={onSelectionChanged}
                activeStateEnabled={false}
                cacheEnabled={false}
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                onEditCanceled={() => {
                    setModeEditDataGrid({
                        edit: false
                    })
                }}
            >
                <Selection
                    keyExpr="id" 
                    mode="multiple"
                    selectAllMode="allPages"
                    showCheckBoxesMode="onClick"
                />
                <Scrolling
                    rowRenderingMode='infinity'
                    showScrollbar='always'                    
                    useNative={true}

                // rowRenderingMode="standard"
                // mode="virtual"
                >
                </Scrolling>

                <Paging defaultPageSize={10} />

                <FilterRow visible={estruturaDaEntidadeDetalheState.showFilterRow === 1} />

                <Sorting
                    mode="multiple"
                />
                <GroupPanel
                    visible={(estruturaDaEntidadeDetalheState.showGroupPanel === 1)}
                    //visible={true}
                    allowColumnDragging={true}
                />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <Editing
                    allowDeleting={modeEditDataGrid.edit && props.itemPermissions?._delete}
                    allowUpdating={modeEditDataGrid.edit && props.itemPermissions?._edit}
                    // allowAdding={modeEditDataGrid.edit && props.itemPermissions?._insert}
                    confirmDelete={true}
                    mode="batch"
                    useIcons={true}
                //selectTextOnEditStart={selectTextOnEditStart}
                //startEditAction={startEditAction}
                />
                <Column
                    caption="Deletar"
                    visible={modeEditDataGrid.edit && props.itemPermissions?._delete}
                    type="buttons"
                    width={110}
                >
                    <Button
                        name="delete"
                    />
                </Column>
                <ColumnChooser
                    enabled={false}
                    width={400}
                    height={800}
                    mode={"select"}
                    allowSearch={true} />
                <HeaderFilter
                    allowSearch={true}
                    visible={true}
                />
                {estruturaDaEntidadeDetalheState.fieldsDefs && estruturaDaEntidadeDetalheState.fieldsDefs.map((senderFieldsDefs, senderIndiceFieldsDefs) => {
                    /**
                     * Verifica se o conditionalVisibleFieldName está preenchido,
                     * se estiver, verifica se os dados da entidade são iguais ao valor
                     * do conditionalVisibleFieldValue para determinar se o campo deve ser exibido ou não
                     */

                    const fieldPermissions = validatePermission(senderFieldsDefs.objectid)

                    if(fieldPermissions?._edit === 1){
                        fieldsPermissionUpdateArray.push(senderFieldsDefs.fieldName)
                    }


                    const records = dadosDaEntidadeDetalheState;

                    if(senderFieldsDefs.conditionalVisibleFieldName){
                        const fieldValueArray = senderFieldsDefs.conditionalVisibleFieldValue.split(",");   

                        if (records && !fieldValueArray.includes(records[senderFieldsDefs.conditionalVisibleFieldName])) {                                             
                            senderFieldsDefs.gridVisible = 0;
                            senderFieldsDefs.formVisible = 0;
                        } else {
                            senderFieldsDefs.gridVisible = 1;
                            senderFieldsDefs.formVisible = 1;
                        }

                    }

                    if(senderFieldsDefs.conditionalVisibleMasterFieldName){
                        const dataCodicionalAtual = props.dataEntity?.masterEntity?.[0]?.records?.[0][`${senderFieldsDefs.conditionalVisibleMasterFieldName}`]
                        let condicionalValueMaster = senderFieldsDefs.conditionalVisibleMasterFieldValue; 
                        if(condicionalValueMaster.split(",").length > 1){
                            condicionalValueMaster = condicionalValueMaster.split(",")

                            console.log(condicionalValueMaster)
                            console.log(dataCodicionalAtual)


                            if (records && !condicionalValueMaster.includes(dataCodicionalAtual)) {                                             
                                senderFieldsDefs.gridVisible = 0;
                                senderFieldsDefs.formVisible = 0;
                            } else {
                                senderFieldsDefs.gridVisible = 1;
                                senderFieldsDefs.formVisible = 1;
                            }
                        }
                        else{

                            console.log(condicionalValueMaster)
                            console.log(dataCodicionalAtual)
                            
                            if (records && condicionalValueMaster !== dataCodicionalAtual) {                                             
                                senderFieldsDefs.gridVisible = 0;
                                senderFieldsDefs.formVisible = 0;
                            } else {
                                senderFieldsDefs.gridVisible = 1;
                                senderFieldsDefs.formVisible = 1;
                            }
                        }
  


                        
                    }        
                    
                    if (senderFieldsDefs.editorType === "dxCheckBox" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1} 
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // editorOptions={editorOptionsColumns}
                                dataType={"boolean"}
                            >

                            </Column>
                        )
                    }

                    else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "datetime" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // editorOptions={editorOptionsColumns}
                                dataType={senderFieldsDefs.fieldType}
                                format="shortDateShortTime"
                            >

                            </Column>
                        )
                    }

                    else if (senderFieldsDefs.editorType === "upfTime" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // editorOptions={editorOptionsColumns}
                                dataType={"datetime"}
                                format="shortTime"
                            >

                            </Column>
                        )
                    }

                    else if ((senderFieldsDefs.editorType === "dxDateBox" || senderFieldsDefs.editorType === "dxCalendar") && senderFieldsDefs.fieldType === "date" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // editorOptions={editorOptionsColumns}
                                dataType={senderFieldsDefs.fieldType}
                                format="shortDate"
                            >

                            </Column>
                        )
                    }

                    else if (senderFieldsDefs.editorType === "dxLookupEdit" && fieldPermissions?._show === 1) {
                        if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup) {

                            const newDataSource = new DataSource({
                                store: {
                                  data: props.dataLookup[senderFieldsDefs.lookUpListSource],
                                  type: 'array',
                                  key: senderFieldsDefs.lookUpKeyField,
                                },
                            });


                            return (
                                <Column
                                    key={senderIndiceFieldsDefs}
                                    fixed={senderFieldsDefs.fixed}
                                    visible={senderFieldsDefs.gridVisible === 1}
                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                    dataField={senderFieldsDefs.fieldName}
                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                    caption={senderFieldsDefs.fieldCaption}
                                    dataType={senderFieldsDefs.fieldType}
                                    editorOptions={{
                                        acceptCustomValue: true,
                                        searchEnabled: true,
                                        customItemCreateEvent: "focusout",
                                        searchTimeout: 200,
                                        minSearchLength: 0,
                                        showDataBeforeSearch: false,
                                        opened: true,
                                        onCustomItemCreating: (args) => {
                                            if (!args.text) {
                                                args.customItem = null;
                                                return;
                                            }
                                            else{
                                                const newItem = {};

                                                newItem[senderFieldsDefs.lookUpKeyField] = args.text
                                                newItem[senderFieldsDefs.lookUpResultField] = args.text
                                            
                                                args.customItem = newDataSource.store().insert(newItem)
                                                .then(() => newDataSource.load())
                                                .then(() => newItem)
                                                .catch((error) => {
                                                throw error;
                                                });
                                            }
                                        }    
                                    }}
                                >
                                    <Lookup
                                        // dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                        dataSource={newDataSource.store()._array}
                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                        allowClearing={true}
                                        showClearButton={true}
                                    />

                                </Column>
                            )
                        }
                    }

                    else if (senderFieldsDefs.editorType === "dxLookup" ||
                        senderFieldsDefs.editorType === "dxSelectBox") {
                        if (senderFieldsDefs.lookUpKeyField != null && props.dataLookup && fieldPermissions?._show === 1) {

                            return (
                                <Column
                                    key={senderIndiceFieldsDefs}
                                    fixed={senderFieldsDefs.fixed}
                                    visible={senderFieldsDefs.gridVisible === 1}
                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                    dataField={senderFieldsDefs.fieldName}
                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                    caption={senderFieldsDefs.fieldCaption}
                                    dataType={senderFieldsDefs.fieldType}
                                // editCellRender={
                                // format={senderFieldsDefs.fieldMask}
                                //width={senderFieldsDefs.FieldSize}
                                >
                                    <Lookup

                                        dataSource={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                        allowClearing={true}
                                        showClearButton={true}
                                    />

                                </Column>
                            )
                        }
                    }

                    else if (senderFieldsDefs.editorType === "upfLookupDetailEntity") {
                        if (senderFieldsDefs.lookUpKeyField != null && props.dataEntity && fieldPermissions?._show === 1) {
                            return (
                                <Column
                                    key={senderIndiceFieldsDefs}
                                    fixed={senderFieldsDefs.fixed}
                                    visible={senderFieldsDefs.gridVisible === 1}
                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                    dataField={senderFieldsDefs.fieldName}
                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                    caption={senderFieldsDefs.fieldCaption}
                                    dataType={senderFieldsDefs.fieldType}
                                // editCellRender={
                                // format={senderFieldsDefs.fieldMask}
                                //width={senderFieldsDefs.FieldSize}
                                >
                                    <Lookup
                                        dataSource={props.dataEntityDetailEntity.find(
                                            element => element.entityName === senderFieldsDefs.lookUpListSource
                                        )?.records}
                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                        allowClearing={true}
                                        showClearButton={true}
                                    />

                                </Column>
                            )
                        }
                    }

                    else if (senderFieldsDefs.editorType === "upfLookupMasterEntity" && fieldPermissions?._show === 1) {
                        if (senderFieldsDefs.lookUpKeyField != null && props.dataEntity) {

                            const resultQueryArrayLookupDataSourceEntityFieldsQuery = arrayLookupDataSourceEntityFieldsQuery
                            // alert("upfLookupMasterEntity")
                            // getEntityFieldsFunction()

                            return (
                                <Column
                                    key={senderIndiceFieldsDefs}
                                    fixed={senderFieldsDefs.fixed}
                                    visible={senderFieldsDefs.gridVisible === 1}
                                    // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                    defaultSortOrder={senderFieldsDefs.sortOrder}
                                    defaultSortIndex={senderFieldsDefs.sortIndex}
                                    showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                    dataField={senderFieldsDefs.fieldName}
                                    allowEditing={senderFieldsDefs.readOnly != 1}
                                    caption={senderFieldsDefs.fieldCaption}
                                    dataType={senderFieldsDefs.fieldType}
                                // editCellRender={
                                // format={senderFieldsDefs.fieldMask}
                                //width={senderFieldsDefs.FieldSize}
                                >
                                    <Lookup
                                        dataSource={arrayLookupDataSourceEntityFields}
                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                        displayExpr={senderFieldsDefs.lookUpResultField}
                                        allowClearing={true}
                                        showClearButton={true}
                                    />

                                </Column>
                            )
                        }
                    }

                    else if ((senderFieldsDefs.editorType === "upfHtmlViewer" || senderFieldsDefs.editorType === "upfHtmlBox") && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                groupIndex={senderFieldsDefs.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                dataType={senderFieldsDefs.fieldType} 
                                cellRender={(cellData) => {
                                    return(
                                        <UpfHtmlEditor 
                                            referencia={refDataGrid?.current?.instance}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                            entityStructure={senderFieldsDefs}
                                            colSpan={senderFieldsDefs.colSpan} 
                                            caption={senderFieldsDefs.fieldCaption} 
                                            dataField={senderFieldsDefs.fieldName}
                                            disabled={!modeEditDataGrid.edit} 
                                            hint={senderFieldsDefs.hint}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            showEditBar={false}
                                            cellData={cellData}
                                        />
                                    )}
                                }
                            />
                        )
                    }

                    else if (senderFieldsDefs.editorType === "upfAudio" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                groupIndex={senderFieldsDefs.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                dataType={senderFieldsDefs.fieldType} 
                                cellRender={(cellData) => {
                                    return(
                                        <UpfAudio 
                                            referencia={refDataGrid?.current?.instance}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            dataEntity={cellData.data?.[senderFieldsDefs.fieldName]}
                                            entityStructure={senderFieldsDefs}
                                            colSpan={senderFieldsDefs.colSpan} 
                                            caption={senderFieldsDefs.fieldCaption} 
                                            dataField={senderFieldsDefs.fieldName}
                                            disabled={!modeEditDataGrid.edit} 
                                            hint={senderFieldsDefs.hint}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            dadosDaEntidadeDetalheState={dadosDaEntidadeDetalheState}
                                            setDadosDaEntidadeDetalheState={setDadosDaEntidadeDetalheState}
                                            // showEditBar={false}
                                            cellData={cellData}
                                        />
                                    )}
                                }
                            />
                        )
                    }

                     else if(senderFieldsDefs.editorType === "upfPassword" && fieldPermissions?._show === 1){
                        console.log(senderFieldsDefs)
                        console.log("upfPassword")

                        return(
                            <Column 
                                hint={senderFieldsDefs.hint}
                                helpText={senderFieldsDefs.hintDocked ? senderFieldsDefs.hint : null}
                                // colSpan={colSpanFieldsFunction(senderFieldsDefs.colSpan, props.colCount)} 
                                caption={senderFieldsDefs.fieldCaption} 
                                dataField={senderFieldsDefs.fieldName} 
                                dataSource={props.dataEntity}
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                groupIndex={senderFieldsDefs.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                dataType={senderFieldsDefs.fieldType} 
                                editorOptions={{mode: "password"}}
                                cellRender={(cellData) => {
                                    return(
                                        <UpfPasswordComponent 
                                            defaultValue={cellData.value}
                                            // colSpan={colSpanFieldsFunction(senderFieldsDefs.colSpan, props.colCount)}
                                            modeEditOn={props.modeEditOn}
                                            entityStructure={senderFieldsDefs}
                                            setModeEditForm={props.setModeEditForm}
                                            setFormDataJson={props.setFormDataJson}
                                            setDataEntity={props.setDataEntity}
                                            // dataEntity={props.dataEntity}
                                            modeEditForm={props.modeEditForm}
                                            isGrid={true}
                                            referencia={refDataGrid?.current?.instance}
                                            dataEntity={cellData.data?.[senderFieldsDefs.fieldName] ? cellData.data?.[senderFieldsDefs.fieldName] : cellData.data}
                                            colSpan={senderFieldsDefs.colSpan} 
                                            caption={senderFieldsDefs.fieldCaption} 
                                            dataField={senderFieldsDefs.fieldName}
                                            disabled={!modeEditDataGrid.edit} 
                                            hint={senderFieldsDefs.hint}
                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                            showEditBar={false}
                                            cellData={cellData}
                                        />
                                    )}}
                            >
                                
                            
                            </Column>
                        )
                    }

                    else if (senderFieldsDefs.fieldType === "money" && fieldPermissions?._show === 1) {
                        return (
                            <Column
                                visible={senderFieldsDefs.gridVisible === 1}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                sortOrder={senderFieldsDefs.sortOrder}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // dataType={senderFieldsDefs.fieldType}
                                // format={{
                                //     style: 'currency',
                                //     currency: 'BRL',
                                //     precision: 2,
                                // }}
                                editorOptions={{
                                    editorType:"dxNumberBox",
                                    format: {
                                        type: "currency",
                                        // useCurrencyAccountingStyle: true ,
                                        currency: 'BRL',
                                        precision: 2,
                                    }
                                }}
                                format="R$ #,##0.##"
                                // format={formatFunction(senderFieldsDefs.fieldMask, "column", senderFieldsDefs.fieldName, dadosDaEntidadeDetalheState)}
                                
                            />
                        );
                    }

                    else if(fieldPermissions?._show === 1){
                        return (
                            <Column
                                key={senderIndiceFieldsDefs}
                                fixed={senderFieldsDefs.fixed}
                                visible={senderFieldsDefs.gridVisible === 1}
                                // groupIndex={!senderFieldsDefs?.groupIndex ? -1 : senderFieldsDefs?.groupIndex}
                                defaultSortOrder={senderFieldsDefs.sortOrder}
                                defaultSortIndex={senderFieldsDefs.sortIndex}
                                showInColumnChooser={senderFieldsDefs.gridVisibleForCustomization === 1 && fieldPermissions?._show === 1}
                                dataField={senderFieldsDefs.fieldName}
                                allowEditing={senderFieldsDefs.readOnly != 1}
                                caption={senderFieldsDefs.fieldCaption}
                                // format={formatFunction(senderFieldsDefs.fieldMask,  "column", senderFieldsDefs.fieldName, dadosDaEntidadeDetalheState.records, modeEditDataGrid?.edit)}
                            // dataType={"datetime"}
                            // editorOptions={editorOptionsColumns}

                            // format={senderFieldsDefs.fieldMask}
                            //width={senderFieldsDefs.FieldSize}
                            >
                                {/* <Lookup
                                                        dataSource ={props.dataLookup[senderFieldsDefs.lookUpListSource]}
                                                        valueExpr={senderFieldsDefs.lookUpKeyField}
                                                        displayExpr ={senderFieldsDefs.lookUpResultField}
                                                    /> */}

                            </Column>
                        )
                    }
                }
                )}
                {/* {refDataGrid && */}
                    <Summary 
                        calculateCustomSummary={calculateCustomSummary}
                    >
                        <TotalItem
                            column={refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}
                            summaryType="count"
                            customizeText={(data) => `Qtd: ${data.value}`}
                        />
                        <TotalItem
                            name={`{"tipo": "count", "columnName": "${refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}"}`}
                            summaryType="custom"
                            displayFormat="Sel.: {0}"
                            showInColumn={refDataGrid?.current?.instance?.getVisibleColumns()[1]?.dataField}
                        />
                        {estruturaDaEntidadeDetalheState?.summary &&
                            estruturaDaEntidadeDetalheState?.summary.map((item, indice) => {
                                if (item.fieldType === "money") {
                                    return (
                                        <TotalItem
                                            column={item.fieldName}
                                            summaryType={item.summarytype}
                                            fieldType={item.fieldType}
                                            showInGroupFooter={false}
                                            alignByColumn={true}
                                            rowRenderingMode="standard"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <TotalItem
                                            column={item.fieldName}
                                            summaryType={item.summarytype}
                                            fieldType={item.fieldType}
                                            showInGroupFooter={false}
                                            alignByColumn={true}
                                            rowRenderingMode="standard"
                                        />
                                    )
                                }
                            })
                        }

                        {estruturaDaEntidadeDetalheState?.summary &&
                            estruturaDaEntidadeDetalheState?.summary.map((item, indice) => {
                                if (item.fieldType === "money") {
                                    return (
                                        <TotalItem
                                            name={`{"tipo": "value", "columnName": "${item.fieldName}"}`}
                                            summaryType="custom"
                                            valueFormat={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            displayFormat="Sel.: {0}"
                                            showInColumn={item.fieldName}
                                        />
                                    )
                                }
                            })
                        }

                        {estruturaDaEntidadeDetalheState?.summary &&
                            estruturaDaEntidadeDetalheState?.summary.map((item, indice) => {
                                if (item.fieldType === "money") {
                                    return (
                                        <GroupItem
                                            column={item.fieldName}
                                            summaryType={item.summarytype}
                                            fieldType={item.fieldType}
                                            showInGroupFooter={false}
                                            alignByColumn={true}
                                            rowRenderingMode="standard"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <GroupItem
                                            column={item.fieldName}
                                            summaryType={item.summarytype}
                                            fieldType={item.fieldType}
                                            showInGroupFooter={false}
                                            alignByColumn={true}
                                            rowRenderingMode="standard"
                                        />
                                    )
                                }
                            })
                        }
                    </Summary>
                
                {/* } */}
                <SortByGroupSummaryInfo summaryItem="count" rowRenderingMode="standard" />
            </DataGrid>
        </div>
        )
    }

    if (!TabPanelDetailEntityItemState){
        return (
            <></>
        )
    }
    else{
        return (
            TabPanelDetailEntityItemState
        )
    }

}

export default TabPanelDetailEntityItem;