const { createContext, useContext, useState } = require("react");

const StatusDiscadorGlobalState = createContext();

export const StatusDiscadorGlobalStateProvider = ({ children }) => {
  const [statusDiscadorGlobalState, setStatusDiscadorGlobalState]= useState({
    ativo: false
  });

  return (
    <StatusDiscadorGlobalState.Provider value={{ statusDiscadorGlobalState, setStatusDiscadorGlobalState }}>
      {children}
    </StatusDiscadorGlobalState.Provider>
  );
};

export const useStatusDiscadorGlobalState = () => useContext(StatusDiscadorGlobalState);