import React, { useState } from "react";
import { useRequestsApi } from "../../../Context/RequestsApi"
import { DashboardControl } from 'devexpress-dashboard-react';
import { DashboardPanelExtension, ResourceManager, } from 'devexpress-dashboard';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { locale } from "devextreme/localization";
import Box from "devextreme-react/box";
import { BsHourglassSplit } from "react-icons/bs";
import "./styles.css";

let dashboard;
let extension;
let reloadInterval;
let timerDisplayInterval;
const DashboardViewerForm = (props) => {
    const { requestsApi } = useRequestsApi();
    const [lang] = useState("pt-BR");
    const [showDashboard, setShowDashboard] = useState(false);
    const [showInputTimer, setShowInputTimer] = useState(false);
    const [enableTimer, setEnableTimer] = useState(false);
    const [timerMinutesToReload, setTimerMinutesToReload] = useState(5);
    const [timerInterfaceDisplay, setTimerInterfaceDisplay] = useState();

    ResourceManager.setLocalizationMessages(require(`../DevExpressLocalizedResources_2021.2_${lang}/json resources/dx-dashboard.${lang}.json`));
    ResourceManager.setLocalizationMessages(require(`../DevExpressLocalizedResources_2021.2_${lang}/json resources/dx-analytics-core.${lang}.json`));
    locale(lang);

    function onBeforeRender(e) {
        dashboard = e.component;  
        extension = new DashboardPanelExtension(dashboard);
        extension.allowSwitchToDesigner(false);
        dashboard.registerExtension(extension);
    }

    function onDashboardBeginUpdate() {
        if (enableTimer) {
            clearInterval(timerDisplayInterval);
            clearInterval(reloadInterval);
        }
    }

    function onDashboardEndUpdate() {
        if (enableTimer) reloadDataTimer();
    }

    function timerInterface(minutes) {
        let min = minutes - 1;
        let seconds = 59;
        timerDisplayInterval = setInterval(() => {
            if (seconds < 0) {
                min--;
                seconds = 59;
            }
            if (min < 0) {
                min = minutes - 1;
            }
            setTimerInterfaceDisplay(`${String(min).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`);
            seconds--;
        }, 1000)
    }

    function reloadDataTimer() {
        setEnableTimer(true);
        timerInterface(timerMinutesToReload);
        reloadInterval = setInterval(() => {
            extension.updateDashboardsList();
            dashboard.reloadData();
        }, (1000 * 60) * timerMinutesToReload)
    }

    function handleHideDashboards() {
        extension.hidePanelAsync({}).done((e) => {
            dashboard.surfaceLeft(e.surfaceLeft); 
        }); 
    }

    function handleExpandDashboards() {
        extension.showPanelAsync({}).done((e) => {  
            dashboard.surfaceLeft(e.surfaceLeft);  
        });
    }

    function handleReloadDashboards() {
        extension.updateDashboardsList();    
        dashboard.reloadData();
    }

    function handleChangeDashboardShowMode() {
        showDashboard
        ? handleExpandDashboards()
        : handleHideDashboards()
        setShowDashboard(!showDashboard);
    }

    function handleClearDataTimerInterval() {
        setTimerInterfaceDisplay();
        clearInterval(timerDisplayInterval);
        clearInterval(reloadInterval);
        setEnableTimer(false);
        setShowInputTimer(false);
    }

    function handleTimerSubmit(e) {
        e.preventDefault();
        if (timerMinutesToReload < 1) {
            alert("ERRO: Não é possível criar um temporizador com o intervalo menor do que 1 minuto");
            return;
        }
        reloadDataTimer();
        setShowInputTimer(false);
    }

    //buttons
    const showPanelButtonOptions = {
        icon: showDashboard ? "showpanel" : "hidepanel",
        hint: showDashboard ? "Mostrar menu de dashboards" : "Esconder menu de dashboards",
        onClick() {
            handleChangeDashboardShowMode();
        },
        type: "normal"
    }

    const refreshDataButtonOptions = {
        icon: "refresh",
        hint: "Atualizar dados",
        onClick() {
            handleReloadDashboards();
        },
        type: "normal",
        className: "refresh-data"
    }

    const refreshTimerButtonOptions = {
        icon: "clock",
        hint: "Definir temporizador para atualização de dados",
        onClick() {
            setShowInputTimer(!showInputTimer);
        },
        type: "normal",
        className: "refresh-timer"
    }

    const stopTimerButtonOptions = {
        icon: "clear",
        hint: "Parar temporizador",
        onClick() {
            handleClearDataTimerInterval();
        },
        type: "normal",
        disabled: !enableTimer
    }

    return (
        <div className="tab-items">
            <div style={{ position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px' }}>
                <div className="dashboard-area">
                    <Toolbar>
                        <Item 
                            location="before"
                            widget="dxButton"
                            options={showPanelButtonOptions}
                        />
                        <Item 
                            location="before"
                            widget="dxButton"    
                            options={refreshDataButtonOptions}
                        />
                        <Item 
                            location="before"
                            widget="dxButton"    
                            options={refreshTimerButtonOptions}
                        />
                        <Item 
                            location="before"
                            widget="dxButton"    
                            options={stopTimerButtonOptions}
                        />
                        {showInputTimer && (
                            <form method="post" onSubmit={handleTimerSubmit} className="timer-form">
                                <input 
                                    type="number" 
                                    placeholder="Tempo (em minutos)" 
                                    id="inputTimer"
                                    onChange={e => setTimerMinutesToReload(e.target.value)}
                                    value={timerMinutesToReload}
                                    disabled={enableTimer}    
                                />
                                <button type="submit" className="timer-button" disabled={enableTimer}>Ok</button>
                            </form>
                        )}
                    </Toolbar>
                    {timerInterfaceDisplay && (
                        <Box>
                            <Item>
                                <div className="dashboard-timer">
                                    <BsHourglassSplit /> &nbsp;
                                    Dados atualizaram em:
                                    <strong> {timerInterfaceDisplay}</strong>
                                </div>
                            </Item>
                        </Box>
                    )}
                </div>
                <DashboardControl style={{ height: '100%' }}
                    endpoint={requestsApi.dashBoardEndpoint}
                    defaultWorkingMode="Viewer"
                    onBeforeRender={onBeforeRender}
                    onDashboardBeginUpdate={onDashboardBeginUpdate}
                    onDashboardEndUpdate={onDashboardEndUpdate}
                >
                </DashboardControl>
            </div>
        </div>
    );
}

export default DashboardViewerForm;