import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';

const Loading = () => {
  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "80vh", width: "100%"}}>
        <LoadIndicator id="large-indicator" height={150} width={150} />
        {/* <LoadPanel
          visible={true}
        //   position={position}
        //   onHiding={this.hideLoadPanel}
        //   visible={this.state.loadPanelVisible}
        //   showIndicator={this.state.showIndicator}
        //   shading={this.state.shading}
        //   showPane={this.state.showPane}
        //   hideOnOutsideClick={this.state.hideOnOutsideClick}
        /> */}
    </div>
    )
}

export default Loading;