export const executeActionUpfFloatPopupMessage = (
    actionName
    ,mensagemId
    ,requestsApi
    ,applicationID
    ,relationId
    ,httpRequest
    ) => {
    const arrayTemplateMensagemTituloJson = JSON.parse(sessionStorage.getItem("arrayTemplateMensagemTitulo"))

    const enviarMensagemDeRevolutionParaRelationId = (mensagem) => {
        if(mensagem?._html){
            httpRequest('POST', requestsApi.enviarMensagem, requestsApi.basicAuth, {
                remetenteId: applicationID,
                destinatarioId: relationId,
                html: `<div>${mensagem?._html}</div>`,
                attachmentId: ""
            })
            .then((sender) => {
                console.log(sender)
            })
            .catch((err) => {
                alert(err)
            })
        }
    }

    if(arrayTemplateMensagemTituloJson?.length > 0) {
        if(arrayTemplateMensagemTituloJson.find((e) => e.acao === mensagemId)){
            console.log(arrayTemplateMensagemTituloJson.find((e) => e.acao === mensagemId))
            enviarMensagemDeRevolutionParaRelationId(arrayTemplateMensagemTituloJson.find((e) => e.acao === mensagemId))
        }
        else if(arrayTemplateMensagemTituloJson.find((e) => e.acao === actionName)){
            console.log(arrayTemplateMensagemTituloJson.find((e) => e.acao === actionName))
            enviarMensagemDeRevolutionParaRelationId(arrayTemplateMensagemTituloJson.find((e) => e.acao === actionName))
        }
        else{
            return ""
        }
    }
}