import 'devextreme-react/text-area';
import { httpRequest } from '../../functions'
import { useState, useEffect, useRef, Suspense, useCallback, memo } from 'react'
import React from 'react'
import {
    applicationID
    , relationId
    , permissions
} from "../../api/ApplicationID"
import Loading from "../../CardComponents/Loading"
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import RecordFormPopup from '../RecordForm/RecordFormPopup';
import { Toast } from 'devextreme-react/toast';
import TabPanelDetailEntitys from "../RecordForm/TabPanelDetailEntity";
import QueryFormPopup from "../QueryForm/QueryFormPopup";
import ForeignFieldEdit from "../ForeignFieldEdit";
import './style.css';
import { v4 as uuid } from 'uuid'
import ConditionalSwitch from "../ConditionalSwitch"
import ValidationSummary from 'devextreme-react/validation-summary';
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import DropDownBox from 'devextreme-react/drop-down-box';
import UpfLookup from '../UpfComponents/UpfLookup';
import ScrollView from 'devextreme-react/scroll-view';
import TreeView from 'devextreme-react/tree-view';
import List from 'devextreme-react/list';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Template } from 'devextreme-react/core/template';
import GetFormComponents from "../../functions/functionsMapForm/GetFormComponents"
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import { useRequestsApi } from "../../Context/RequestsApi"
import UpfPopupForm from '../../CardComponents/UpfPopupForm';
import { convertDate } from '../../functions/convertDate';
import WelcomeTab from '../DrawerMain/TabPanelMain/WelcomeTab';
import UpfPopup from "../../CardComponents/UpfPopup";
import LoadingData from '../../CardComponents/LoadingData';
import UpfPopupCustomForm from '../../CardComponents/UpfPopupCustomForm';
import TabpanelEmbebedItems from './TabpanelEmbebedItems';
import { validatePermission } from "../../functions/validatePermission"
import config from 'devextreme/core/config';
import repaintFloatingActionButton from 'devextreme/ui/speed_dial_action/repaint_floating_action_button';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import { convertCheckBox } from '../../functions/convertCheckBox';
import { QueryForm } from '../QueryForm';
import ViewForm from '../ViewForm';
import { SelectBox } from 'devextreme-react';
import TarefaCustomForm from "../TarefaCustomForm" 
import TarefasGrid from "./TarefasGrid"
import AttachRecordForm from './AttachRecordForm';
import HtmlFormPopup from '../HtmlFormPopup'
import AttachRecordFormPopup from './AttachRecordFormPopup';
import { Callbacks } from 'jquery';
import IconsToolBarButtonUPF from '../../CardComponents/IconsToolBarButtonUPF';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';
import UpfPopupConfirmContextMenu from '../../CardComponents/UpfPopupConfirmContextMenu';

const RecordForm = (props) => {
    const { requestsApi } = useRequestsApi()
    const popups = {
        isVisible: false,
        message: 'error',
    };
    const [states, setStates] = useState({
        loadedContentState: false,
        entityStructure: null,
        dataEntity: "",
        popup: ""
    })

    const [condicionalVisibleState, setCondicionalVisibleState] = useState(true)

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const [dataEntity, setDataEntity] = useState()
    const [popup, setPopup] = useState(popups);
    const [modeEditForm, setModeEditForm] = useState(false)
    const [dataEntityNull, setDataEntityNull] = useState(false)
    const [insertIdValue, setInsertIdValue] = useState()
    const [dataLookup, setDataLookup] = useState([])
    const [idValue, setIdValue] = useState(false)
    const [dataEntityDetailEntity, setDataEntityDetailEntity] = useState()
    const [formDataJson, setFormDataJson] = useState({})
    const [queryFormEntityName, setQueryFormEntityName] = useState()
    const [refreshRecord, setRefreshRecord] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState({ isVisible: false, message: "Atenção" });
    const [foreignFieldSelectedItem, setForeignFieldSelectedItem] = useState()
    const [tituloQueryFormPopup, setTituloQueryFormPopup] = useState()
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [loginResultJson, setLoginResultJson] = useState(JSON.parse(sessionStorage.getItem("MenuDataSource")))
    const [selectedProcess, setSelectedProcess] = useState()
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [dataLoadedState, setDataLoadedState] = useState(false)
    const [objectPermissionsState, setObjectPermissionsState] = useState()
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [canClick, setCanClick] = useState(false);
    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null,
        caption: null,
        estrutura: null,
        dados: null
    })

    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState()
    const [htmlFormPopupDataSourceState, setHtmlFormPopupDataSourceState] = useState()
    const [dataEntityEditedFields, setDataEntityEditedFields] = useState({})


    const [popupState, setPopupState] = useState(false)

    const [attachRecordFormPopupStateVisible, setAttachRecordFormPopupStateVisible] = useState(false)

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    console.log(dataEntityDetailEntity)

    const closeButtonHandler = useCallback(() => {
        const Handle = [...tabPanelItems];
        // const index = Handle.indexOf(tabPanelItems);

        Handle.splice(indexTabPanelMain, 1);
        setTabPanelItems(Handle);

        if (tabPanelItems.length !== 0) {
            setIndexTabPanelMain(tabPanelItems.length - 2)
        }
    }, [tabPanelItems])

    const buttonDropDownOptions={width: "300"}


    const [calledConditionUseEffectState, setCalledConditionUseEffectState] = useState()


    const [senderFieldNamesCheckBox, setSenderFieldNamesCheckBox] = useState([])

    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()

    const [loadedContentState, setLoadedContentState] = useState()

    const [embeddedComponentsEntityStructureArrayState, setEmbeddedComponentsEntityStructureArrayState] = useState([])

    const [embeddedComponentsDataLookupArrayState, setEmbeddedComponentsDataLookupArrayState] = useState([])

    const [constextMenuMasterEntityPermission, setConstextMenuMasterEntityPermission] = useState("")

    const [attachPopupRecordFormOpen, setAttachPopupRecordFormOpen] = useState(false)

    const [refreshDetailEntityState, setRefreshDetailEntityState] = useState(false)


    const mountContextMenu = (dataEntity) => {
        if(props.entityStructure?.[0]?.recordFormProperties?.[0]["contextMenu"]){
            const contextMenuAllowedItems = []

            const contextMenu = props.entityStructure?.[0]?.recordFormProperties?.[0]["contextMenu"]

            for(let c = 0; c <= contextMenu.length - 1; c++){
                let permissoesItem = validatePermission(props.entityStructure?.[0]?.recordFormProperties?.[0]["contextMenu"][c]?.objectid)
                // if(permissoesItem?._execute === 0){
                //     // contextMenu[c]["disabled"] = true
                //     console.log(contextMenu[c])
                // }

                contextMenu[c]["_execute"] = permissoesItem?._execute

                if(permissoesItem?._show === 1){
                    if(contextMenu[c].conditionalVisibleFieldName || contextMenu[c].conditionalVisibleFieldValue){
                        const fieldsData = dataEntity?.masterEntity?.[0]?.records?.[0]
                        if(fieldsData[`${contextMenu[c].conditionalVisibleFieldName}`] === contextMenu[c].conditionalVisibleFieldValue){
                            contextMenuAllowedItems.push(contextMenu[c])
                        }
                    }
                    else{
                        contextMenuAllowedItems.push(contextMenu[c])
                    }
                }
                
            }
            setConstextMenuMasterEntityPermission(contextMenuAllowedItems)
        }
    }


    useEffect(() => {
        setIsLoading(true)
        if(props.dataEntity) {
            setDataEntityDetailEntity(props.dataEntity.detailEntity)
            setDataEntity(props.dataEntity)
        }

        // if (refreshRecord) {
        //     setRefreshRecord(false)
        //     console.log('chamou o refresh')
        //     onClickRefreshButton()
        // }

        //se não tiver idValue (novo registro)
        setDataLookup(props.dataLookup)

        if (props.modeEditOn && !props.insertId?.value) {
            modeEditOn()
        }
        else {
            modeEditOff()
        }

        setStates({
            ...states,
            entityStructure: props.entityStructure
        })

        setObjectPermissionsState(validatePermission(props.entityStructure?.[0]?.masterEntity?.objectid))
        //------------------------------------------

        //se vier com idValue (registro ja existente)
        if (props.idValue || idValue || props.insertId?.value) {
            if (props.idValue && !idValue) {
                setIdValue(props.idValue)
            }

            if(props.insertId?.value){
                setIdValue(props.insertId.value)
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": props.insertId.value
                })
                .then((sender) => {
                    const resultGetDataEntityJson = JSON.parse(sender)

                    mountContextMenu(resultGetDataEntityJson)

                    //codigo para pegar as estruturas dos formularios incorporados
                    if (props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents && embeddedComponentsEntityStructureArrayState.length === 0) {
                        for (let c = 0; c <= props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents?.length - 1; c++) {
                            if (props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter !== "_tarefa") {

                                let entityNameEmbebedComponent = null
            
                                if(props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter){
                                    entityNameEmbebedComponent = props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter
                                }
                                else if(resultGetDataEntityJson.masterEntity?.[0]?.records?.[0]["_entityname"]){
                                    entityNameEmbebedComponent = resultGetDataEntityJson?.masterEntity?.[0].records?.[0]["_entityname"]
                                }
                                //estava junto das verificacoes de componentparamter e entityname, criei um else só pra ele, mas nao vimos o uso em nenhum lugar.
                                else if(props.embebedEntityName){
                                    entityNameEmbebedComponent = props.embebedEntityName
                                }
            
                                if(entityNameEmbebedComponent){
                                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, { 
                                        applicationId: applicationID,
                                        entityName: entityNameEmbebedComponent
                                    })
                                    .then((sender) => {
                                        if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                                            setMessageError("Ocorreu um erro ao carregar a estrutura do componente incorporado.")
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                        else{
                                            let resultGetEntityStructureJson = JSON.parse(sender)
            
                                            setEmbeddedComponentsEntityStructureArrayState([...embeddedComponentsEntityStructureArrayState, resultGetEntityStructureJson])
                
                                            if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                                                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                    "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                                                })
                                                    .then((sender) => {
                                                        let senderDataLookup = JSON.parse(sender)
                                                        setEmbeddedComponentsDataLookupArrayState([...embeddedComponentsDataLookupArrayState, senderDataLookup])
                
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    })
                                            }
                                            else {
                                                setEmbeddedComponentsDataLookupArrayState([...embeddedComponentsDataLookupArrayState, []])
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        if (error?.data?.error) {
                                            setMessageError(error.data.error)
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                        else {
                                            setMessageError(error)
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                    })
                                }
                                else{
                                    setMessageError("Não foi possível abrir o formulário incorporado.")
                                    setPopup({
                                        ...popup
                                        , isVisible: true
                                        , message: "Atenção!"
                                    })
                                }
                            }
                        }
                    }

                    setDataEntityDetailEntity(resultGetDataEntityJson.detailEntity)
                    setDataEntity(resultGetDataEntityJson)
                    if(resetFormData.current){
                        resetFormData.current.instance.repaint()
                        console.log(resetFormData)
                    }
                    setLoadedContentState(true)
                })
                .catch((error) => {
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
            }
            else{
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": props.idValue ? props.idValue : idValue
                })
                .then((sender) => {
                    const resultGetDataEntityJson = JSON.parse(sender)

                    mountContextMenu(resultGetDataEntityJson)


                    //codigo para pegar as estruturas dos formularios incorporados
                    if (props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents && embeddedComponentsEntityStructureArrayState.length === 0) {
                        for (let c = 0; c <= props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents?.length - 1; c++) {
                            if (props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter !== "_tarefa") {

                                let entityNameEmbebedComponent = null
            
                                if(props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter){
                                    entityNameEmbebedComponent = props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents[c]?.componentParameter
                                }
                                else if(resultGetDataEntityJson.masterEntity?.[0]?.records?.[0]["_entityname"]){
                                    entityNameEmbebedComponent = resultGetDataEntityJson?.masterEntity?.[0].records?.[0]["_entityname"]
                                }
                                //estava junto das verificacoes de componentparamter e entityname, criei um else só pra ele, mas nao vimos o uso em nenhum lugar.
                                else if(props.embebedEntityName){
                                    entityNameEmbebedComponent = props.embebedEntityName
                                }
            
                                if(entityNameEmbebedComponent){
                                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, { 
                                        applicationId: applicationID,
                                        entityName: entityNameEmbebedComponent
                                    })
                                    .then((sender) => {
                                        if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                                            setMessageError("Ocorreu um erro ao carregar a estrutura do componente incorporado.")
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                        else{
                                            let resultGetEntityStructureJson = JSON.parse(sender)
            
                                            setEmbeddedComponentsEntityStructureArrayState([...embeddedComponentsEntityStructureArrayState, resultGetEntityStructureJson])
                
                                            if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                                                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                    "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                                                })
                                                    .then((sender) => {
                                                        let senderDataLookup = JSON.parse(sender)
                                                        setEmbeddedComponentsDataLookupArrayState([...embeddedComponentsDataLookupArrayState, senderDataLookup])
                
                                                    })
                                                    .catch((error) => {
                                                        console.log(error)
                                                    })
                                            }
                                            else {
                                                setEmbeddedComponentsDataLookupArrayState([...embeddedComponentsDataLookupArrayState, []])
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        if (error?.data?.error) {
                                            setMessageError(error.data.error)
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                        else {
                                            setMessageError(error)
                                            setPopup({
                                                ...popup
                                                , isVisible: true
                                                , message: "Atenção!"
                                            })
                                        }
                                    })
                                }
                                else{
                                    setMessageError("Não foi possível abrir o formulário incorporado.")
                                    setPopup({
                                        ...popup
                                        , isVisible: true
                                        , message: "Atenção!"
                                    })
                                }
                            }
                        }
                    }


                    setDataEntityDetailEntity(resultGetDataEntityJson.detailEntity)
                    setDataEntity(resultGetDataEntityJson)
                    if(resetFormData.current){
                        resetFormData.current.instance.repaint()
                        console.log(resetFormData)
                    }
                    setLoadedContentState(true)
                })
                .catch((error) => {
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
            }

            
        }
        else {
            setLoadedContentState(true)
        }

        // setLoadedContentState(true)

        //------------------------------------------

        setIsLoading(false)

        return () => {}
    }, [])

    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }

    //----------UpfPopupForm--------//
    const [statesUpfPopupForm, setStatesUpfPopupForm] = useState({
        visible: false,
        componentName: null,
        tituloId: null,
        credorId: null,
        clienteId: null,
        title: "",
        execute: false,
        parcelasIdArray: null
    })

    const hideUpfPopupForm = () => {
        //limpar estados do acordo e do boleto
        // setGerarAcordoDataSource(null)
        // setIndexMultiViewItemsGerarAcordo(0)

        // setGerarBoletoDataSource(null)
        // setIndexMultiViewItemsGerarBoleto(0)

        setStatesUpfPopupForm({
            ...statesUpfPopupForm,
            visible: false
        })
    }

    const showUpfPopupForm = (clienteId, credorId, tituloId, title, execute, parcelasIdArray) => {
        setStatesUpfPopupForm({
            visible: true,
            tituloId: tituloId,
            credorId: credorId,
            clienteId: clienteId,
            title: title,
            execute: execute,
            parcelasIdArray: parcelasIdArray
        })
    }

    //----------UpfPopupForm--------//

    const _toolbarRef = useRef(null)

    //-----------FORM---------------//
    const resetFormData = useRef(null);
    //Modo
    const modeEditOn = useCallback(() => {
        setModeEditForm(true)
    }, [])

    const modeEditOff = useCallback(() => {
        setModeEditForm(false)
    }, [])
    //Funções
    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        if (idValue) {
            showLoadPanel()
            httpRequest("POST",
                requestsApi.updateRecordFromJson, requestsApi.basicAuth,
                {
                    "entityName": entityNameGrid,
                    "json": jsonRow,
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    const senderUpdateRecordFromJson = JSON.parse(sender)
                    modeEditOff()
                    toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                    // setRefreshDetailEntityState(true)
                    hideLoadPanel()
                })
                .catch((error) => {
                    hideLoadPanel()
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
        }
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
        showLoadPanel()
        httpRequest("POST",
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                modeEditOff()
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                // setRefreshDetailEntityState(true)
                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const recordInsertJsonDetail = (entityNameGrid, formData, refDataGrid) => {
        delete formData.id;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            modeEditOff()
            refDataGrid.current.instance.refresh()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            hideLoadPanel()
            if (error?.data?.error) {
                setMessageError(error.data.error)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
            else {
                setMessageError(error?.statusText)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        })
    }

    //GroupPermissionForm
    const [statesGroupPermissionForm, setStatesGroupPermissionForm] = useState({
        visible: false
    })

    const showGroupPermissionForm = () => {
        setStatesGroupPermissionForm({
            visible: true,
        })
    }

    const recordInsertUpdateJson = useCallback(async (e) => {
        const dataEntityItensModificadosObject = dataEntityEditedFields

        dataEntityItensModificadosObject["id"] = dataEntity?.masterEntity?.[0]?.records?.[0]?.id

        console.log(dataEntityItensModificadosObject)
        //NÃO APAGAR
        console.log(e)
        console.log(e.validationGroup)
        console.log(e.validationGroup.validate())
        console.log(e.validationGroup._validationInfo.result.isValid)
        //----------
        // e.validationGroup.validate()
        // e.validationGroup.validate()
        showLoadPanel()
        // e.validationGroup.validate()

        if (e.validationGroup._validationInfo.result.isValid === true) {
            e.event.preventDefault()
            //condição para gravar alterações em um item, já existente, vindo do RecordForm principal
            if (idValue) {
                httpRequest("POST",
                    requestsApi.updateRecordFromJson, requestsApi.basicAuth,
                    {
                        "entityName": props.entityName,
                        "json": dataEntityItensModificadosObject,
                        "applicationId": applicationID,
                        "relationId": relationId
                    })
                    .then((sender) => {

                        if (foreignFieldSelectedItem) {
                            setForeignFieldSelectedItem(null)
                        }
                        const senderUpdateRecordFromJson = JSON.parse(sender)
                        modeEditOff()
                        toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)
                        // onClickRefreshButton()
                    })
                    .catch((error) => {
                        hideLoadPanel()
                        if (error?.data?.error) {
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                        else {
                            setMessageError(error?.statusText)
                            setPopup({
                                ...popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }
            //condição para gravar um novo item, quando for chamado pelo botão novo no query ou no record
            else if (!props.masterEntityId || !props.keyFieldNameState) {

                if (props.embebedEntityName && props.embebedIdValue) {
                    formDataJson["_objectid"] = props.embebedIdValue ? props.embebedIdValue : null
                    formDataJson["_entityname"] = props.embebedEntityName ? props.embebedEntityName : null
                    formDataJson["_indice"] = null
                }
                await httpRequest("POST",
                    requestsApi.insertRecordFromJson, requestsApi.basicAuth,
                    {
                        "entityName": props.entityName,
                        "json": formDataJson,
                        "applicationId": applicationID,
                        "relationId": relationId
                    })
                .then((sender) => {
                    const senderInsertRecordFromJson = JSON.parse(sender)
                    setInsertIdValue(senderInsertRecordFromJson.idValue)// no lugar do consol.log colocar setInsertIdValue
                    setIdValue(senderInsertRecordFromJson.idValue)
                    if(props.insertId?.value === null){
                        props.insertId.value = senderInsertRecordFromJson.idValue
                    }

                    onClickRefreshButton(senderInsertRecordFromJson.idValue)
                    
                    if (props.selectedItens) {
                        insertLoteMountJson(senderInsertRecordFromJson)
                        modeEditOff()
                    }
                    if (insertLoteJson.length > 0) {

                        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                            {
                                "entityName": "_loteitem",
                                "json": insertLoteJson,
                                "applicationId": applicationID,
                                "relationId": relationId
                            })
                            .catch((error) => {
                                hideLoadPanel()
                                console.log(error)

                                if (error?.data?.error) {
                                    setMessageError(error.data.error)
                                    setPopup({
                                        ...popup
                                        , isVisible: true
                                        , message: "Atenção!"
                                    })
                                }
                                else {
                                    setMessageError(error?.statusText)
                                    setPopup({
                                        ...popup
                                        , isVisible: true
                                        , message: "Atenção!"
                                    })
                                }
                            })
                    }
                    else{
                        // toastConfiguration(true, 'success', senderInsertRecordFromJson.mensagem)
                        toastConfiguration(true, 'success', 'Item salvo com sucesso!')
                    }
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
                .then(() => {
                    //local pra mexer no refresh depois de um insert
                    // onClickRefreshButton()
                    // hideLoadPanel()
                    modeEditOff()
                })

            }
            //condição se o item vier da entidade detalhe e for novo
            else if (props.masterEntityId && !dataEntity) {              
                // formDataJson["masterEntity"] = masterEntity
                formDataJson[`${props.keyFieldNameState}`] = props.masterEntityId
                // showLoadPanel()

                if (props.embebedEntityName && props.embebedIdValue) {
                    formDataJson["_objectid"] = props.embebedIdValue ? props.embebedIdValue : null
                    formDataJson["_entityname"] = props.embebedEntityName ? props.embebedEntityName : null
                    formDataJson["_indice"] = null
                }

                httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "json": formDataJson,
                    "applicationId": applicationID,
                    "relationId": relationId

                })                
                .then((sender) => {
                    const senderInsertRecordFromJson = JSON.parse(sender)
                    
                    setInsertIdValue(senderInsertRecordFromJson)
                    setIdValue(senderInsertRecordFromJson.idValue)
                    
                    if (props.insertId?.value === null){
                        props.insertId.value = senderInsertRecordFromJson.idValue
                    }

                    onClickRefreshButton(senderInsertRecordFromJson.idValue);                        
                    
                    if (senderInsertRecordFromJson) {                            
                        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                            "applicationId": applicationID,
                            "entityName": props.entityName,
                            "fieldName": 'id',
                            "expressao": senderInsertRecordFromJson.idValue
                        })
                        .then(senderGetDataEntity => {
                            const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                            mountContextMenu(resultGetDataEntity)
                            convertCheckBox(senderFieldNamesCheckBox, resultGetDataEntity.masterEntity[0]["records"])
                            setDataEntity(resultGetDataEntity)
                            modeEditOff()
                        })
                        .catch((error) => { 
                            if (error?.data?.error) {
                                setMessageError(error.data.error)
                                setPopup({
                                    ...popup,
                                    isVisible: true,
                                    message: "Atenção!"
                                })
                            }
                            else {
                                setMessageError(error?.statusText)
                                setPopup({
                                    ...popup,
                                    isVisible: true,
                                    message: "Atenção!"
                                })
                            }
                            })
                        .finally(() => {
                            modeEditOff()
                        })
                    }
                    modeEditOff()
                    toastConfiguration(true, 'success', senderInsertRecordFromJson.mensagem)
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)

                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })                                  
            }
        }
        else {
            setMessageError(e?.validationGroup?._validationInfo?.result?.brokenRules?.[0]?.message)
            setErrorModalOpen(true)
            e.event.preventDefault()
            hideLoadPanel()
        }

    }, [idValue, props.entityName, dataEntity, props.masterEntityId, props.entityStructure, props.dataLookup, dataEntityEditedFields])

    const insertLoteJson = []

    const insertLoteMountJson = (sender) => {
        for (let c = 0; c < props.selectedItens.length; c++) {
            // setInsertLoteJson(
            //     [...insertLoteJson, 
            //     {
            //      "loteid": insertIdValue,
            //      "tituloid": props.selectedItens[c].tituloid
            //     }])
            insertLoteJson.push({
                "_loteid": sender.idValue,
                "_tituloid": props.selectedItens[c].tituloid
            })
        }
        // return(console.log(insertLoteJson))
    }

    const recordDeleteJson = useCallback(() => {
        if (idValue && !props.masterEntityId && !props.popupRecordForm) {
            httpRequest('POST',
                requestsApi.deleteRecordFromJson,
                requestsApi.basicAuth,
                {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": idValue,
                    "relationId": relationId
                }).then((response) => {
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    closeButtonHandler()
                    //função para fechr ultima tab criada, precisa pega o item para fazer indeOf
                    //fechar o form atual
                    // closeButtonHandler(tabPanelItems[tabPanelItems.length-1])
                    hidePopupDelete()
                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                }).then(() => {
                })
        } else if (props.masterEntityId && !props.popupRecordForm) {
            httpRequest('POST',
                requestsApi.deleteRecordFromJson,
                requestsApi.basicAuth,
                {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": idValue,
                    "relationId": relationId
                }).then((response) => {
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    hidePopupDelete()
                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
        } else if (insertIdValue && !props.popupRecordForm) {
            httpRequest('POST',
                requestsApi.deleteRecordFromJson,
                requestsApi.basicAuth,
                {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": insertIdValue.idValue,
                    "relationId": relationId
                }).then((response) => {
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    closeButtonHandler()
                    hidePopupDelete()
                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
        }
        else if (props.popupRecordForm) {
            httpRequest('POST',
                requestsApi.deleteRecordFromJson,
                requestsApi.basicAuth,
                {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": idValue,
                    "relationId": relationId
                }).then((response) => {
                    const resultDelete = JSON.parse(response)
                    toastConfiguration(true, 'success', resultDelete.mensagem)
                    hidePopupDelete()
                    //fechar o formulario aberto no popup
                    props.setPopupRecordFormOpen(false)
                    props.setPopupState(false)
                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
        }
        hidePopupDelete()
    }, [applicationID, props.entityName, idValue, props.masterEntityId, insertIdValue, dataEntity, props.entityStructure, props.dataLookup])

    const updateFormData = () => {

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: props.entityName,
            applicationId: applicationID
        })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)

                if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                    })
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)

                            //se vier de um recordformpopup
                            if (props.popupRecordForm) {
                                //abrir um novo
                                setPopupRecordFormOpen(true)
                                setPopupState(true)
                                setStateModeEdit(true)
                                setDetailEntityName(props.entityName)
                                setPopupRecordFormAdd(props.masterEntityId)
                                setDataLookupRecordFormPopupState(senderDataLookup)
                                setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

                                //fechar popup anterior
                                // if(props.referenciaPopup){
                                //     setTimeout(() => {
                                //         props.referenciaPopup.current.instance.hide()
                                //     }, 5000);
                                    
                                // }
                            }
                            //se vier de um recordform 
                            else {
                                setTabPanelItems([
                                    ...tabPanelItems,
                                    {
                                        'title': states.entityStructure?.[0].masterEntity.caption
                                        , 'component':
                                            <ScrollView useNative={true}>
                                                <RecordForm
                                                    id={Math.random()}
                                                    entityName={props.entityName}
                                                    modeEditOn={true}
                                                    insertId={{value: null}}
                                                    dataLookup={senderDataLookup}
                                                    entityStructure={resultGetEntityStructureJson}
                                                />
                                            </ScrollView>
                                        , 'text': Math.random()
                                        , 'icon': 'edit'
                                    }
                                ])
                                setIndexTabPanelMain(tabPanelItems.length)
                            }
                        })
                        .catch((error) => console.log(error))
                }
                else {
                    //se vier de um recordformpopup
                    if (props.popupRecordForm) {
                        //abrir um novo
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setStateModeEdit(true)
                        setDetailEntityName(props.entityName)
                        setPopupRecordFormAdd(props.masterEntityId)
                        setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)
                    }
                    //se vier de um recordform 
                    else {
                        setTabPanelItems([
                            ...tabPanelItems,
                            {
                                'title': states.entityStructure?.[0].masterEntity.caption
                                , 'component':
                                    <ScrollView useNative={true}>
                                        <RecordForm
                                            id={Math.random()}
                                            entityName={props.entityName}
                                            modeEditOn={true}
                                            insertId={{value: null}}
                                            entityStructure={resultGetEntityStructureJson}
                                        />
                                    </ScrollView>
                                , 'text': Math.random()
                                , 'icon': 'edit'
                            }
                        ])
                        setIndexTabPanelMain(tabPanelItems.length)
                    }
                }
            })
            .catch((error) => console.log(error))
    }

    //Botões
    const addButtonOptions = {
        icon: 'add',
        hint: 'Incluir um novo registro',
        disabled: objectPermissionsState && !objectPermissionsState._insert,
        onClick: updateFormData,
    }

    const recordButtonOptions = {
        icon: 'save',
        useSubmitBehavior: true,
        validationGroup: `groupName${props.id}`,
        onClick: recordInsertUpdateJson,
    }

    const attachButtonOptions = {
        icon: 'attach',
        hint: 'Anexar registro',
        onClick: () => {
            configRecordFormStructureAll()
        }
    }

    const logButtonOptions = {
        icon: 'textdocument',
        hint: 'Log',
        onClick: () => {
            openLogCustomForm()
        }
    }

    const deleteButtonOptions = {
        icon: 'close',
        disabled: objectPermissionsState && !objectPermissionsState._delete,
        onClick: () => setConfirmDelete({ ...confirmDelete, isVisible: true })
    }

    const revertButtonOptions = {
        icon: 'revert',
        hint: 'Desfazer alterações no registro',
        onClick: () => {
            setRevertPopupVisible(true)
        }
    }

    const onClickRefreshButton = (insertIdValueParam) => {
        showLoadPanel()
      
        let detailEntitiesItensStructure = states?.entityStructure?.[0]?.detailEntities?.[0]?.detailEntity

        if (insertIdValueParam) {
            modeEditOff()
            httpRequest("POST",
                requestsApi.getEntityStructure, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "applicationId": applicationID
                })
                .then((sender) => {
                    const senderEntityStructure = JSON.parse(sender)
                    setObjectPermissionsState(validatePermission(senderEntityStructure[0].masterEntity.objectid))

                    setStates({ ...states, entityStructure: senderEntityStructure })
                    if (senderEntityStructure[0].lookUpEntities) {
                        // getLookupsDetail(senderEntityStructure[0]?.recordFormProperties?.[0]?.recordFormComponents, senderEntityStructure[0].lookUpEntities)
                        httpRequest("POST",
                            requestsApi.getLookupTable, requestsApi.basicAuth,
                            {
                                "lookUpEntities": senderEntityStructure[0].lookUpEntities
                            }
                        )
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)
                            setDataLookup(senderDataLookup)
                            modeEditOff()
                        })
                        .catch((error) => {
                            hideLoadPanel()
                            console.log(error)
                        })
                    }
                })
            httpRequest('POST',
                requestsApi.getDataEntity, requestsApi.basicAuth,
                {
                    "applicationId": applicationID
                    , "entityName": props.entityName
                    , "fieldName": 'id'
                    , "expressao": insertIdValueParam //insertIdValue.idValue  no insertIdValue ta vindo o id, insertIdValue.idValue ta undefined
                })
                .then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);

                    mountContextMenu(resultGetDataEntity)

                    //muda todas as letra da entidade para minuscula
                    for (let c = 0; c <= resultGetDataEntity.detailEntity.length - 1; c++) {
                        resultGetDataEntity.detailEntity[c].entityName = resultGetDataEntity.detailEntity[c].entityName.toLowerCase()
                    }

                    if(detailEntitiesItensStructure?.length > 0){
                        for (let c = 0; c <= detailEntitiesItensStructure.length - 1; c++) {
                            convertDate(resultGetDataEntity.detailEntity.find(
                                element => element.entityName.toLowerCase() === detailEntitiesItensStructure[c].entityName.toLowerCase()
                            )?.records, detailEntitiesItensStructure[c].fieldsDefs)
                        }
                    }

                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    setDataEntity(resultGetDataEntity)
                    modeEditOff()
                    hideLoadPanel()
                })
                .catch((error) => {
                    console.log(error)
                    hideLoadPanel()
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
                .then(() => {
                    modeEditOff()
                    hideLoadPanel()
                })
        }
        else if (!idValue && props.masterEntityId) {
            httpRequest("POST",
                requestsApi.getEntityStructure, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "applicationId": applicationID
                })
                .then((sender) => {
                    const senderEntityStructure = JSON.parse(sender)
                    setObjectPermissionsState(validatePermission(senderEntityStructure[0].masterEntity.objectid))

                    setStates({ ...states, entityStructure: senderEntityStructure })
                    if (senderEntityStructure[0].lookUpEntities) {
                        // getLookupsDetail(senderEntityStructure[0]?.recordFormProperties?.[0]?.recordFormComponents, senderEntityStructure[0].lookUpEntities)
                        httpRequest("POST",
                            requestsApi.getLookupTable, requestsApi.basicAuth,
                            {
                                "lookUpEntities": senderEntityStructure[0].lookUpEntities
                            }
                        )
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)
                            setDataLookup(senderDataLookup)
                            modeEditOff()
                        })
                        .catch((error) => {
                            hideLoadPanel()
                            console.log(error)
                        })
                    }
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                })
            httpRequest('POST',
                requestsApi.getDataEntity, requestsApi.basicAuth,
                {
                    "applicationId": applicationID
                    , "entityName": props.entityName
                    , "fieldName": 'id'
                    , "expressao": props.masterEntityId
                }
            )
                .then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);

                    mountContextMenu(resultGetDataEntity)

                    //muda todas as letra da entidade para minuscula
                    for (let c = 0; c <= resultGetDataEntity.detailEntity.length - 1; c++) {
                        resultGetDataEntity.detailEntity[c].entityName = resultGetDataEntity.detailEntity[c].entityName.toLowerCase()
                    }

                    for (let c = 0; c <= detailEntitiesItensStructure.length - 1; c++) {
                        convertDate(resultGetDataEntity.detailEntity.find(
                            element => element.entityName.toLowerCase() === detailEntitiesItensStructure[c].entityName.toLowerCase()
                        )?.records, detailEntitiesItensStructure[c].fieldsDefs)
                        console.log(dataEntity.masterEntity[0]?.records[0])
                    }

                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    setDataEntity(resultGetDataEntity)
                    modeEditOff()
                    hideLoadPanel()
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
                .then(() => hideLoadPanel())
        } else if (idValue) {
            httpRequest("POST",
                requestsApi.getEntityStructure, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "applicationId": applicationID
                })
                .then((sender) => {
                    const senderEntityStructure = JSON.parse(sender)
                    setObjectPermissionsState(validatePermission(senderEntityStructure[0].masterEntity.objectid))

                    setStates({ ...states, entityStructure: senderEntityStructure })
                    modeEditOff()
                    if (senderEntityStructure[0].lookUpEntities) {
                        // getLookupsDetail(senderEntityStructure[0]?.recordFormProperties?.[0]?.recordFormComponents, senderEntityStructure[0].lookUpEntities)
                        httpRequest("POST",
                            requestsApi.getLookupTable, requestsApi.basicAuth,
                            {
                                "lookUpEntities": senderEntityStructure[0].lookUpEntities
                            }
                        ).then((sender) => {
                            const senderDataLookup = JSON.parse(sender)
                            setDataLookup(senderDataLookup)
                            modeEditOff()
                        })
                        .catch((error) => {
                            hideLoadPanel()
                            console.log(error)
                        })
                    }
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                })
            httpRequest('POST',
                requestsApi.getDataEntity, requestsApi.basicAuth,
                {
                    "applicationId": applicationID
                    , "entityName": props.entityName
                    , "fieldName": 'id'
                    , "expressao": idValue
                }
            )
                .then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    mountContextMenu(resultGetDataEntity)

                    //muda todas as letra da entidade para minuscula
                    for (let c = 0; c <= resultGetDataEntity.detailEntity.length - 1; c++) {
                        resultGetDataEntity.detailEntity[c].entityName = resultGetDataEntity.detailEntity[c].entityName.toLowerCase()
                    }

                    if(detailEntitiesItensStructure?.length){
                        for (let c = 0; c <= detailEntitiesItensStructure?.length - 1; c++) {
                            convertDate(resultGetDataEntity.detailEntity.find(
                                element => element.entityName.toLowerCase() === detailEntitiesItensStructure[c].entityName.toLowerCase()
                            )?.records, detailEntitiesItensStructure[c].fieldsDefs)
                            console.log(dataEntity.masterEntity[0]?.records[0])
                        }
                    }

                    setDataEntityDetailEntity(resultGetDataEntity.detailEntity)
                    setDataEntity(resultGetDataEntity)
                    modeEditOff()
                    hideLoadPanel()
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
                .then(() => hideLoadPanel())
        }
        
    }

    const createTarefaCustomForm = () => {
        if (states.entityStructure?.[0].masterEntity["customRecordForm"]) {
            console.log('entrou no if tarefa customRecordForm')
            //tipos de customRecordForm
            if (states.entityStructure?.[0].masterEntity["customRecordForm"] === "tarefaCustomForm") {
                setUpfPopupCustomFormStates({
                    visible: true,
                    title: "Tarefa",
                    formName: "TarefaCustomForm",
                    entityName: props.entityName,
                    caption: states.entityStructure?.[0].masterEntity.caption,
                    idValue: idValue
                })
            }
        }
        else {
            // setIDValueDetailEntity(idValue)
            console.log('entrou no if tarefa ')
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tarefa"
            })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)
                    setEmbebedIdValueState(idValue)
                    setEmbebedEntityNameState(props.entityName)

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)
                                setDataLookupRecordFormPopupState(senderDataLookup)

                                setDetailEntityName("_tarefa")
                                setDetailCaptionName("Tarefas")
                                setPopupRecordFormOpen(true)
                                setPopupState(true)
                                setIDValueDetailEntity(null)
                                setStateModeEdit(true)
                                
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                    else {
                        setDetailEntityName("_tarefa")
                        setDetailCaptionName("Tarefas")
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setIDValueDetailEntity(null)
                        setStateModeEdit(true)
                    }
                })


            // setKeyFieldNameState(senderDetailEntity.keyFieldName)
        }

    }

    const tasksButtonOptions = {
        icon: 'plus',
        text: "Nova tarefa",
        type: "default",
        onClick: createTarefaCustomForm,
    }

    const refreshButtonOptions = {
        icon: 'refresh',
        hint: 'Atualizar dados do servidor',
        onClick: () => onClickRefreshButton(),
    }

    const configRecordFormStructure = () => {
        httpRequest('POST',
            requestsApi.getDataEntity, requestsApi.basicAuth,
            {
                "applicationId": applicationID,
                "entityName": "entity",
                "fieldName": "entityname",
                "expressao": props.entityName
            }
        )
        .then(senderGetDataEntity => {
            const resultGetDataEntity = JSON.parse(senderGetDataEntity);
            mountContextMenu(resultGetDataEntity)
            setIDValueDetailEntity(resultGetDataEntity.masterEntity[0]?.records[0].id)

            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: "entity",
                applicationId: applicationID
            })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)
                console.log('!!resultGetEntityStructureJson: ', resultGetEntityStructureJson)

                if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                    })
                    .then((sender) => {
                        const senderDataLookup = JSON.parse(sender)

                        setDataLookupRecordFormPopupState(senderDataLookup)
                        setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)
                        
                        //part 2
                        setAttachPopupRecordFormOpen(false)
                        
                        setPopupRecordFormOpen(true)
                        setPopupState(true)
                        setStateModeEdit(true)
                        setDetailEntityName("entity")
                    })
                    .catch((error) => console.log("Error: ", error))                    
                } else {                            
                    setDataLookupRecordFormPopupState([])
                    setEntityStructureRecordFormPopupState(resultGetEntityStructureJson)

                    setPopupRecordFormOpen(true)
                    setPopupState(true)
                    setStateModeEdit(true)
                    setDetailEntityName("entity")
                }
            })
            .catch((error) => {
                if (error?.data?.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup,
                        isVisible: true,
                        message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
        })
    }

    const configRecordFormStructureAll = () => {
        showLoadPanel()
        console.log(attachPopupRecordFormOpen)
        console.log(attachRecordFormPopupStateVisible)
        httpRequest('POST',
            requestsApi.getEntityAttachment, requestsApi.basicAuth,
            {
                "applicationId": applicationID
                , "objectId": idValue
            }
        )
            .then(senderGetDataEntity => {
                hideLoadPanel()
                const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                setIDValueAttachDetailsEntity(resultGetDataEntity)
            })
            .catch((error) => {
                if (error?.data?.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
            .then(() => {
                showLoadPanel()
                setAttachPopupRecordFormOpen(true)
                setAttachRecordFormPopupStateVisible(true)
                setStateModeEdit(true)
                setDetailAttachEntityName(props.entityName)
                hideLoadPanel()
            })
    }

    const openLogCustomForm = (entityStructureDetalhe, dataEntityDetalhe) => {
        showLoadPanel()
        if(entityStructureDetalhe && dataEntityDetalhe){
            setUpfPopupCustomFormStates({
                visible: true,
                title: `Log - ${entityStructureDetalhe.caption}`,
                formName: "LogCustomForm",
                entityName: entityStructureDetalhe.entityName,
                caption: entityStructureDetalhe.caption,
                idValue: idValue,
                estrutura: entityStructureDetalhe,
                dados: dataEntityDetalhe
            })
        }
        else{
            setUpfPopupCustomFormStates({
                visible: true,
                title: `Log - ${states.entityStructure?.[0].masterEntity.caption}`,
                formName: "LogCustomForm",
                entityName: props.entityName,
                caption: states.entityStructure?.[0].masterEntity.caption,
                idValue: idValue
            })
        }
        hideLoadPanel()
    }

    const configTablesAndComponents = () => {
        httpRequest("POST",
            requestsApi.createTablesAndFieldsFromEntity, requestsApi.basicAuth,
            {
                "entityName": dataEntity.masterEntity[0]?.records[0].entityname,
                "applicationId": applicationID
            }
        )
            .then((sender) => {
                const senderTablesAndComponents = JSON.parse(sender)
                toastConfiguration(true, 'success', senderTablesAndComponents.mensagem)
            })
            .catch((error) => {
                if (error?.data?.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const configColumnsFromView = () => {
        httpRequest("POST",
            requestsApi.addColumnsFromView, requestsApi.basicAuth,
            {
                "viewId": dataEntity.masterEntity[0]?.records[0].id,
                "applicationId": applicationID
            }
        )
            .then((sender) => {
                const senderTablesAndComponents = JSON.parse(sender)
                toastConfiguration(true, 'success', senderTablesAndComponents.mensagem)
            })
            .catch((error) => {
                if (error?.data?.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
    }

    const mountTablesAndComponentsButtonOptions = {
        icon:  "GoCloudUpload",
        widget: "dxButton",
        onClick: configTablesAndComponents,
    }

    const addColumnsFromViewButtonOption = {        
        icon: "GoCloudUpload",
        widget: "dxButton",
        hint: "Adicionar todas as colunas/campos da view",
        onClick: configColumnsFromView,
    }

    //-----------FORM---------------//

    //-----------POPUP-------------//
    const hidePopup = () => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    };
    const [revertPopupVisible, setRevertPopupVisible] = useState(false)
    const [attachPopupVisible, setAttachPopupVisible] = useState(false)

    const popupConfirmChanges = useCallback(() => {
        resetFormData.current.instance.beginUpdate()
        // resetFormData.current.instance.updateData('formData', undefined)
        if (dataEntityNull) {
            showLoadPanel()
            setDataEntity(undefined)
            hidePopup()
            setRevertPopupVisible(false)
            hideLoadPanel()
        }
        else if (!dataEntityNull) {
            // showLoadPanel()
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                "entityName": props.entityName
                , "applicationId": applicationID
            }).then(senderGetEntityStructure => {
                const resultGetEntityStrucuture = JSON.parse(senderGetEntityStructure)
                setObjectPermissionsState(validatePermission(resultGetEntityStrucuture[0].masterEntity.objectid))

                setStates({ ...states, entityStructure: resultGetEntityStrucuture });
            }).catch((error) => {
                if (error?.data?.error) {
                    hideLoadPanel()
                    setRevertPopupVisible(false)
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setRevertPopupVisible(false)
                    setMessageError(error?.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            }).then(() => {
                hideLoadPanel()
                setRevertPopupVisible(false)
            })

            if (insertIdValue) {
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID
                    , "entityName": props.entityName
                    , "fieldName": 'id'
                    , "expressao": insertIdValue.idValue
                }).then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    mountContextMenu(resultGetDataEntity)
                    convertCheckBox(senderFieldNamesCheckBox, resultGetDataEntity.masterEntity[0]["records"])
                    setDataEntity(resultGetDataEntity);
                    hidePopup()
                    setRevertPopupVisible(false)
                    modeEditOff()

                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setRevertPopupVisible(false)
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setRevertPopupVisible(false)
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                }).then(() => {
                    hideLoadPanel()
                    setRevertPopupVisible(false)
                })
            } else {
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID
                    , "entityName": props.entityName
                    , "fieldName": 'id'
                    , "expressao": props.idValue
                }).then(senderGetDataEntity => {
                    const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    mountContextMenu(resultGetDataEntity)
                    convertCheckBox(senderFieldNamesCheckBox, resultGetDataEntity.masterEntity[0]["records"])
                    setDataEntity(resultGetDataEntity);
                    hidePopup()
                    setRevertPopupVisible(false)
                    modeEditOff()

                }).catch((error) => {
                    if (error?.data?.error) {
                        hideLoadPanel()
                        setRevertPopupVisible(false)
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        hideLoadPanel()
                        setRevertPopupVisible(false)
                        setMessageError(error?.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                }).then(() => {
                    hideLoadPanel()
                    setRevertPopupVisible(false)
                })
            }
        }
        resetFormData.current.instance.endUpdate()
    }, [props.entityName, idValue, props.idValue, insertIdValue, props.entityStructure, props.dataLookup])

    const closeRevertPopup = () => {
        setRevertPopupVisible(false)
    }

    //Botões
    const closeButtonPopup = {
        text: 'Ok',
        onClick: hidePopup,
    };

    const confirmRevertButtonPopup = {
        text: 'Sim',
        onClick: popupConfirmChanges,
    };

    const cancelRevertButtonPopup = {
        text: 'Não',
        onClick: closeRevertPopup,
    }

    //Estados
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [messageError, setMessageError] = useState()

    const errorModalClose = () => {
        setErrorModalOpen(false)
    }

    const closeButtonPopupError = {
        text: 'OK',
        onClick: errorModalClose,
    };



    //-----------POPUP-------------//

    //---------LOADING------------//

    //Estados
    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    //---------LOADING------------//

    //---------TABPANEL----------//
    //Funções tabPanel

    // const itemTitleRender = (company) => {
    //     return (<span>{company.entityName}</span>)
    //   }

    // const onSelectionChanged = (args) => {
    //     if(args.name == 'selectedIndex') {
    //         setTabpanel({
    //         selectedIndex: args.value
    //       });
    //     }
    //   }



    //Estados
    // const {tabPanelItems, setTabPanelItems} = useTabPanelItems()
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState();
    const [IDValueAttachDetailsEntity, setIDValueAttachDetailsEntity] = useState();
    const [detailEntityName, setDetailEntityName] = useState("");
    const [detailAttachEntityName, setDetailAttachEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [detailAttachCaptionName, setDetailAttachCaptionName] = useState()
    const [entityDataEntityState, setEntityDataEntityState] = useState()

    const [isValueTrue, setIsValueTrue] = useState(true)

    // const createRecordForm = () => {
    //     console.log(IDValueDetailEntity)
    //     setTabPanelItems([
    //         ...tabPanelItems, 
    //         {
    //             'title': 'TESTE CONTATOS', 
    //             'component': <RecordForm id={Math.random()} IDValue={IDValueDetailEntity} entityName={'cadastro_contatos'}/>,
    //             'text': Math.random(),
    //             'icon':'edit'
    //         }
    //     ])
    // }

    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [popupQueryFormOpen, setPopupQueryFormOpen] = useState(false)
    const refreshDataGrid = useRef(null)

    // const setRefreshDataGrid = () => {
    //     refreshDataGrid.current.instance.beginUpdate()
    //     refreshDataGrid.current.instance.repaint()
    //     refreshDataGrid.current.instance.endUpdate()
    // }


    //---------TABPANEL----------//

    //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })

    //Funções
    const onHiding = useCallback(() => {
        hideLoadPanel()
        modeEditOff()
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])
    
    const [stateModeEdit, setStateModeEdit] = useState(false);
    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        })
    }, [toastConfig])

    //---------TOAST----------//

    const _recordFormPopupRef = useRef(null)

    const RenderRecordFormPopup = () => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                idValue={IDValueDetailEntity}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                masterEntityId={props.idValue ? props.idValue : idValue}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity?.detailEntity?.length > 0 ? dataEntity?.detailEntity : props.masterDetailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
                dataEntity={entityDataEntityState}
                referenciaPopup={_recordFormPopupRef}
            />
        );
    }

    const RenderRecordFormContextMenu = memo(() => {
        return(
            <Popup
                visible={popupDataSourceRecordFormContextMenuState.visible}
                onHiding={() => {
                    setPopupDataSourceRecordFormContextMenuState({
                        ...popupDataSourceRecordFormContextMenuState,
                        visible: false
                    })
                }}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={popupDataSourceRecordFormContextMenuState.title}
                resizeEnabled={true}
                fullScreen={true}
            >
                <ScrollView useNative={true}>
                    <RecordForm
                        entityName={popupDataSourceRecordFormContextMenuState.entityname}
                        // idValue={idValue}
                        idValue={popupDataSourceRecordFormContextMenuState.id}
                        icon={popupDataSourceRecordFormContextMenuState.icon}
                        entityStructure={popupDataSourceRecordFormContextMenuState.structure}
                        dataLookup={popupDataSourceRecordFormContextMenuState.lookup}
                        id={Math.random()}
                        insertId={popupDataSourceRecordFormContextMenuState.insertid}
                        modeEditOn={popupDataSourceRecordFormContextMenuState.edit}
                    />
                </ScrollView>
            </Popup>
        )

    })

    const RenderAllRecordFormPopup = memo(() => {
        return (
            <AttachRecordFormPopup
                id={Math.random()}
                dataEntity={dataEntity}
                hideLoadPanel={hideLoadPanel}
                popupState={attachRecordFormPopupStateVisible}
                setPopupState={setAttachRecordFormPopupStateVisible}
                idValue={props.idValue ? props.idValue : idValue}
                entityName={detailAttachEntityName}
                caption={detailAttachCaptionName}
                entityStructure={states.entityStructure}
                toastConfiguration={toastConfiguration}
                dataLookup={props.dataLookup}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setAttachPopupRecordFormOpen={setAttachPopupRecordFormOpen}
            />
        );
    })

    const RenderQueryFormPopup = memo(() => {
        return (
            <QueryFormPopup
                id={Math.random()}
                setPopupQueryFormOpen={setPopupQueryFormOpen}
                popupQueryFormOpen={popupQueryFormOpen}
                entityName={queryFormEntityName}
                entityStructure={states.entityStructure}
                foreignFieldSelectedItem={foreignFieldSelectedItem}
                setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                setQueryFormPopupState={setQueryFormPopupState}
                queryFormPopupState={queryFormPopupState}
                modeEditOn={modeEditOn}
                tituloQueryFormPopup={tituloQueryFormPopup}
            // idValue={}
            />
        );
    })

    const hidePopupDelete = () => {
        setConfirmDelete({ ...confirmDelete, isVisible: false })
    }
    const closeDeleteRecord = {
        text: "Cancelar"
        , onClick: hidePopupDelete
    }
    const confirmDeleteRecord = {
        text: "Ok"
        , onClick: recordDeleteJson
    }




    const dropDownBoxRenderFuncion = (data) => {
        let dropDownValue = ""
        return (
            <DropDownBox
                disabled={data.readOnly}
                hint={data.hint}
                caption={data.fieldCaption}
                // dataField={data.fieldName}
                value={data.fieldCaption}
                dataSource={dataLookup[data.lookUpListSource]}
                valueExpr="id"//{data.lookUpKeyField}
                displayExpr={`${data.lookUpResultField}`}
                placeholder="Selecione uma opção"
                visible={true}
                showClearButton={true}
                deferRendering={false}
                searchEnabled={true}
                searchMode="contains"
                // buttons={
                //     [
                //         {
                //             location:"after",
                //             name:undefined,
                //             options:undefined
                //         }
                //     ]
                // }
                //showDataBeforeSearch={ false}
                valueChangeEvent="keyup"
                contentRender={() => {
                    return (
                        <div className="list-container">
                            <List
                                dataSource={dataLookup[data.lookUpListSource]}
                                // itemRender={(sender) => {
                                //     return <div>{sender[`${data.lookUpResultField}`]}</div>;
                                //     }}
                                displayExpr={`${data.lookUpResultField}`}
                                valueExpr="id"
                                searchEnabled={true}
                                onItemClick={(sender) => {
                                    dropDownValue = sender.itemData[`${data.lookUpResultField}`]
                                }}
                                selectionMode="single"
                                searchMode={"contains"}
                            />
                        </div>
                    )
                }}
            >

            </DropDownBox>
        )
    }
    //toda vez que for abrir um queryFormPopup tem q chamar a função pra true
    const [queryFormPopupState, setQueryFormPopupState] = useState(false)


    const [popupDataSourceRecordFormContextMenuState, setPopupDataSourceRecordFormContextMenuState] = useState({
        visible: false
    })
 

    const onClickMenuContextItem = (item) => {
        console.log(item)
        if(!item?.askConfirm){
            if (item.componentName === "recordForm" && item.componentParameter) {
                showLoadPanel()
                const jsonParametros = JSON.parse(item.componentParameter)
                console.log(item)
                console.log(jsonParametros)

                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                    applicationId: applicationID
                })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    if(item?.entityRefresh === 1){
                        onClickRefreshButton()
                    }

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)
                                showLoadPanel()

                                setPopupDataSourceRecordFormContextMenuState({
                                    visible: true,
                                    title: item.caption,
                                    entityname: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                                    id: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id,
                                    icon: item.icon,
                                    structure: resultGetEntityStructureJson,
                                    lookup: senderDataLookup,
                                    insertid: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null},
                                    edit: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true
                                })

                                hideLoadPanel()
                            })
                            .catch((error) => {
                                hideLoadPanel()
                                console.log(error)
                            })
                    }
                    else {
                        showLoadPanel()
                        setPopupDataSourceRecordFormContextMenuState({
                            visible: true,
                            title: item.caption,
                            entityname: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
                            id: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id,
                            icon: item.icon,
                            structure: resultGetEntityStructureJson,
                            lookup: null,
                            insertid: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null},
                            edit: dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true
                        })
                    }
                })
                .catch((error) => {
                    hideLoadPanel()
                    console.log(error)
                })
            }

            // if (item.componentName === "recordForm" && item.componentParameter) {

            //     const jsonParametros = JSON.parse(item.componentParameter)
            //     console.log(item)
            //     console.log(jsonParametros)

            //     httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            //         entityName: jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter,
            //         applicationId: applicationID
            //     })
            //         .then((sender) => {
            //             const resultGetEntityStructureJson = JSON.parse(sender)

            //             if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
            //                 httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
            //                     "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
            //                 })
            //                     .then((sender) => {
            //                         const senderDataLookup = JSON.parse(sender)

            //                         setTabPanelItems([
            //                             ...tabPanelItems,
            //                             {
            //                                 title: `${item.caption}`
            //                                 , component:
            //                                     <ScrollView>
            //                                         <RecordForm
            //                                             entityName={jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter}
            //                                             // idValue={idValue}
            //                                             idValue={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id}
            //                                             icon={item.icon}
            //                                             entityStructure={resultGetEntityStructureJson}
            //                                             dataLookup={senderDataLookup}
            //                                             id={Math.random()}
            //                                             insertId={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null}}
            //                                             modeEditOn={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true}
            //                                         />
            //                                     </ScrollView>
            //                                 , text: Math.random()
            //                                 , icon: item.icon
            //                             }
            //                         ])
            //                         setIndexTabPanelMain(tabPanelItems.length)

            //                     })
            //                     .catch((error) => console.log(error))
            //             }
            //             else {
            //                 setTabPanelItems([
            //                     ...tabPanelItems,
            //                     {
            //                         title: `${item.caption}`
            //                         , component:
            //                             <ScrollView>
            //                                 <RecordForm
            //                                     entityName={jsonParametros?.entityname ? jsonParametros.entityname : item.componentParameter}
            //                                     idValue={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] ? dataEntity.masterEntity[0].records[0][`${item.idFieldName}`] : jsonParametros?.id}
            //                                     icon={item.icon}
            //                                     entityStructure={resultGetEntityStructureJson}
            //                                     id={Math.random()}
            //                                     insertId={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? undefined : {value: null}}
            //                                     modeEditOn={dataEntity?.masterEntity?.[0]?.records?.[0]?.[`${item.idFieldName}`] || jsonParametros?.id ? false : true}
            //                                 />
            //                             </ScrollView>
            //                         , text: Math.random()
            //                         , icon: item.icon
            //                     }
            //                 ])
            //                 setIndexTabPanelMain(tabPanelItems.length)
            //             }
            //         })
            //         .catch((error) => console.log(error))
            // }

            else if (item.componentName === "gerarBoletoForm") {
                let dataBoletoForm = dataEntity.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    dataBoletoForm._clienteid,
                    dataBoletoForm._credorid,
                    dataBoletoForm.id,//tituloId
                    "Boleto"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "gerarAcordoIturanCustomForm") {
                let dataAcordoForm = dataEntity.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "Gerar acordo ituran"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "baneseCardNegociacaoCustomForm") {
                let dataAcordoForm = dataEntity.masterEntity[0]?.records[0]
                console.log(dataAcordoForm)
                showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "Banese card negociação"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "solNegociacaoCustomForm") {
                let dataAcordoForm = dataEntity.masterEntity[0]?.records[0]
                console.log(dataAcordoForm)
                showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "Sol negociação"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "gerarAcordoForm") {
                let dataAcordoForm = dataEntity.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "Acordo/Novação de dívida",
                    item._execute
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "mensagemCustomForm") {
                let dataForm = dataEntity.masterEntity[0]?.records[0]
                console.log(dataForm)
                showUpfPopupForm(
                    dataForm._clienteid,
                    dataForm._credorid,
                    dataForm.id,//tituloId
                    "Enviar mensagem"
                )
            }

            else if (item.componentName === "gerarAcordoGSPForm") {
                let dataAcordoForm = dataEntity.masterEntity[0]?.records[0]
                showUpfPopupForm(
                    dataAcordoForm._clienteid,
                    dataAcordoForm._credorid,
                    dataAcordoForm.id,//tituloId
                    "(GSP) Acordo/Novação de dívida"
                )
                if(item?.entityRefresh === 1){
                    onClickRefreshButton()
                }
            }

            else if (item.componentName === "groupPermissionForm") {
                showGroupPermissionForm()
            }

            else if (item.methodName) {
                if(item.componentName === 'htmlForm'){
                    if (item.itemData) {
                        //Pegar template html 
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`,  requestsApi.basicAuth,{
                            "applicationId": applicationID,
                            "relationId": relationId,
                            "idValue": idValue,
                            "parameter": item.itemData.componentParameter,
                            "entityName": props.entityName
                        })
                        .then((sender) => {
                            const resultJsonTemplateHtml = JSON.parse(sender)
                            console.log(resultJsonTemplateHtml)
                            setHtmlTemplate(() => {
                                return {__html: resultJsonTemplateHtml.mensagem}
                            })
                            setHtmlPopupVisible(true)
                        })
                        .catch((error) => {
                            console.log(error)
                            setMessage(error.data.error)
                            setVisible(true)
                        })
                    }
                    else{
                        //Pegar template html 
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`,  requestsApi.basicAuth,{
                            "applicationId": applicationID,
                            "relationId": relationId,
                            "idValue": idValue,
                            "parameter": item.componentParameter,
                            "entityName": props.entityName
                        })
                        .then((sender) => {
                            const resultJsonTemplateHtml = JSON.parse(sender)
                            console.log(resultJsonTemplateHtml)
                            setHtmlTemplate(() => {
                                return {__html: resultJsonTemplateHtml.mensagem}
                            })
                            setHtmlPopupVisible(true)
                        })
                        .catch((error) => {
                            console.log(error)
                            setMessage(error.data.error)
                            setVisible(true)
                        })
                    }
                }
                else{
                    if (item.itemData) {
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`, requestsApi.basicAuth,
                            {
                                "applicationId": applicationID,
                                "relationId": relationId,
                                "idValue": idValue,
                                "parameter": item.itemData.componentParameter,
                                "entityName": props.entityName
                            })
                            .then((sender) => {
                                const response = JSON.parse(sender)
                                toastConfiguration(true, 'info', response.mensagem)
        
                            })
                            .catch((err) => {
                                console.log(err)
                                setPopupErrorMessageState(err?.data?.err)
                                setPopupErrorVisibleState(true)
                            })
                    }
                    else {
                        httpRequest('POST', `${requestsApi.customUrl}${item.methodName}`, requestsApi.basicAuth,
                            {
                                "applicationId": applicationID,
                                "relationId": relationId,
                                "idValue": idValue,
                                "parameter": item.componentParameter,
                                "entityName": props.entityName
                            })
                            .then((sender) => {
                                const response = JSON.parse(sender)
                                toastConfiguration(true, 'info', response.mensagem)
        
                            })
                            .catch((err) => {
                                console.log(err)
                                setPopupErrorMessageState(err?.data?.err)
                                setPopupErrorVisibleState(true)
                            })
                    }
                }
                
            }
            else {
                hideLoadPanel()
                setMessageError("Não foi possível abrir o menu de contexto")
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const [clearTabRuleState, setClearTabRuleState] = useState()
    //estado e função do fecharAbasPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hideFecharAbas = () => {
        setVisible(false)
    }
    //---------------------------

    //configuração das opçoes de abas
    const clearAllTabs = {
        text: "Fechar todas as abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("clearAllTabs")
            }
        }
    }

    const clearTabsRight = {
        text: "Fechar abas a direita"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas a direita da atual serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("clearTabsRight")
            }
        }
    }

    const keepThisTab = {
        text: "Fechar outras abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas, com excessão da atual, serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("keepThisTab")
            }
        }
    }
    //

    //função para limpar abas
    const clearTabsFunction = (rule) => {
        //fechar todas as tabs
        if (rule === "clearAllTabs") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                }
            ])
            hideFecharAbas()
        }

        //fecha todas as abas a direita
        else if (rule === "clearTabsRight" && indexTabPanelMain < tabPanelItems.length - 1) {
            setTabPanelItems(tabPanelItems.splice(0, indexTabPanelMain + 1))
            setIndexTabPanelMain(indexTabPanelMain)
            hideFecharAbas()
        }

        //fecha todas as abas menos a atual
        else if (rule === "keepThisTab") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                },
                tabPanelItems[indexTabPanelMain]
            ])
            setIndexTabPanelMain(0)
            setIndexTabPanelMain(1)
            hideFecharAbas()
        }
    }

    //opções dos botões do popup de confirmação do fechar abas
    const clearTabsButton = {
        text: "Ok"
        // ,icon: 'arrowright'
        , onClick: () => {
            clearTabsFunction(clearTabRuleState)
        }
    };

    const cancelButtonFecharAbas = {
        text: "Cancelar"
        , icon: "close"
        , onClick: hideFecharAbas
    };
    const optionDirections = ['auto', 'up', 'down'];

    const directions = {
        auto: {
            icon: 'rowfield',
            shading: true,
            position: {
                of: '#grid',
                my: 'right bottom',
                at: 'right bottom',
                offset: '-16 -16',
            },
        },
        up: {
            icon: 'rowfield',
            shading: true,
            direction: 'up',
            position: {
                of: '#grid',
                my: 'right bottom',
                at: 'right bottom',
                offset: '-16 -16',
            },
        },
        down: {
            icon: 'rowfield',
            shading: true,
            direction: 'down',
            position: {
                of: '.dx-datagrid-rowsview',
                my: 'right top',
                at: 'right top',
                offset: '-16 16',
            },
        },
    };

    function selectedChanged(e) {
        canClick && setSelectedRowIndex(e)
    }

    const refreshStructure = (data) => {
        showLoadPanel()
        console.log(data)
        const temp = states.entityStructure
        temp[0].recordFormProperties[0].recordFormComponents = data
        console.log("chamou refreshStructure")
        setStates({...states,
            entityStructure: temp
        })
        if(resetFormData){
            resetFormData.current.instance.repaint()
            console.log(resetFormData)
        }
        // setRefreshRecord(true)
        hideLoadPanel()
    }

    return (
        <div className="tab-items">
            {(popupRecordFormOpen === true && detailEntityName) ? <RenderRecordFormPopup /> : <></>}
            {attachPopupRecordFormOpen === true ? <RenderAllRecordFormPopup /> : <></>}
            {popupQueryFormOpen === true ? <RenderQueryFormPopup /> : <></>}
            {popupDataSourceRecordFormContextMenuState.visible === true ? <RenderRecordFormContextMenu/> : <></>}

            {htmlPopupVisible && htmlTemplate &&
                <HtmlFormPopup
                    // UpfHtmlEditor={true}
                    setHtmlPopupVisible={setHtmlPopupVisible}
                    htmlPopupVisible={htmlPopupVisible}
                    htmlTemplate={htmlTemplate}
                    htmlFormPopupDataSourceState={htmlFormPopupDataSourceState}
                    id={idValue}
                />
            }

            {upfPopupCustomFormStates.visible &&
                <UpfPopupCustomForm
                    entityName={upfPopupCustomFormStates.entityName}
                    caption={upfPopupCustomFormStates.caption}
                    idValue={upfPopupCustomFormStates.idValue}
                    hideUpfPopupForm={hideUpfPopupCustomForm}
                    visibleState={upfPopupCustomFormStates.visible}
                    title={upfPopupCustomFormStates.title}
                    formName={upfPopupCustomFormStates.formName}
                    estrutura={upfPopupCustomFormStates.estrutura}
                    dados={upfPopupCustomFormStates.dados}
                />
            }

            {/* popup mostrar erros */}
            <UpfPopup
                typePopup="message"
                popupVisibleState={errorModalOpen}
                popupVisibleFunctionChangeState={setErrorModalOpen}
                message={messageError}
            />

            
            <UpfPopupMessage
                hidePopup={hidePopupError}
                visible={popupErrorVisibleState}
                message={popupErrorMessageState}
            />


            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={popup.message}
                width={400}
                height={300}
                resizeEnabled={true}
                id="popupForm"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {messageError &&
                    <p>{messageError}</p>
                }
            </Popup>

            <LoadPanel
                style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />
            {loadedContentState && objectPermissionsState ? (

                // <ScrollView width='100%' height='100%'>
                <div>
                    <h3 className='h3-embedded'>{states.entityStructure?.[0]?.masterEntity?.caption}</h3>

                    <div>
                        {props.teleCobranca && !props.embeddedComponents ?
                            (
                                <div>
                                    <Toolbar
                                        className={"div-toolbar"}
                                        ref={_toolbarRef}
                                        height={70}
                                    >

                                        <Item
                                            location="before"
                                            widget="dxButton"
                                            options={recordButtonOptions}
                                            disabled={!modeEditForm}
                                        />

                                        <Item
                                            location="before"
                                            widget="dxButton"
                                            options={revertButtonOptions}
                                            disabled={!modeEditForm}
                                        />

                                        <Item
                                            location="before"
                                            widget="dxButton"
                                            options={refreshButtonOptions}
                                            disabled={modeEditForm}

                                        />
                                        <Item 
                                            location="before"
                                        >
                                            <DropDownButton
                                                disabled={!dataEntity}
                                                text="Opções"
                                                icon="menu"
                                                dropDownOptions={buttonDropDownOptions}
                                                items={constextMenuMasterEntityPermission}
                                                displayExpr="caption"
                                                displayValue="idFieldName"
                                                onItemClick={(e) => {
                                                    onClickMenuContextItem(e.itemData)
                                                    }
                                                }
                                            />
                                        </Item>

                                        <Item
                                            location="before"
                                            widget="dxButton"
                                            options={tasksButtonOptions}
                                        />

                                        {/* OPÇÕES DAS ABAS */}
                                        {!props.popupRecordForm && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearAllTabs}
                                            />
                                        }

                                        {!props.popupRecordForm && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearTabsRight}
                                                disabled={tabPanelItems.length === indexTabPanelMain + 1}
                                            />
                                        }

                                        {!props.popupRecordForm && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={keepThisTab}
                                            />
                                        }

                                    </Toolbar>
                                    {props.selectedItensCount &&
                                        <div>
                                            {props.selectedItensCount === 1 &&
                                                <h3>{props.selectedItensCount} selecionado</h3>
                                            }
                                            {props.selectedItensCount > 1 &&
                                                <h3>{props.selectedItensCount} selecionados</h3>
                                            }
                                        </div>
                                    }
                                </div>
                            )
                            :
                            (
                                <div>
                                    <Toolbar className={"div-toolbar"}
                                        height={70}
                                        ref={_toolbarRef}
                                    >

                                        {!modeEditForm && 
                                            !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={addButtonOptions}
                                            />
                                        }

                                        {modeEditForm && 
                                        // !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={recordButtonOptions}
                                            />
                                        }

                                        {!modeEditForm && 
                                            !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={deleteButtonOptions}
                                            />
                                        }

                                        {modeEditForm && 
                                        // !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={revertButtonOptions}
                                            />
                                        }

                                        {!modeEditForm && 
                                            !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={refreshButtonOptions}
                                            />
                                        }

                                        {!modeEditForm && 
                                            !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={attachButtonOptions}
                                            />
                                        }

                                        {!modeEditForm && 
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={logButtonOptions}
                                            />
                                        }

                                        {/* comentado 20/03/2024 por ninguem usar e presicar ver as permissões */}
                                        {/* {!props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget={'dxDropDownButton'}
                                                options={{
                                                    text: "Tabelas auxiliares",
                                                    icon: "menu",
                                                    width: 180,
                                                    onSelectionChanged: (e) => {
                                                        setQueryFormEntityName(e.item.lookUpEntity)
                                                        setQueryFormPopupState(true)
                                                        setPopupQueryFormOpen(true)
                                                    },
                                                    displayExpr: "lookUpCaption",
                                                    displayValue: "lookUpEntity",
                                                    items: states.entityStructure?.[0]?.["lookUpEntities"] || "",
                                                }}
                                                dropDownOptions={{ width: "300", maxWidth: "500" }}
                                            >
                                            </Item>
                                        } */}

                                        {/* {!props.embeddedComponents &&  */}
                                            <Item 
                                                location="before"
                                            >
                                                <DropDownButton
                                                    disabled={!dataEntity}
                                                    text="Opções"
                                                    icon="menu"
                                                    dropDownOptions={buttonDropDownOptions}
                                                    items={constextMenuMasterEntityPermission}
                                                    displayExpr="caption"
                                                    displayValue="idFieldName"
                                                    onItemClick={(e) => {
                                                        onClickMenuContextItem(e.itemData)
                                                        }
                                                    }
                                                />
                                            </Item>
                                        {/* } */}

                                        {!modeEditForm && !props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget="dxButton"
                                                options={tasksButtonOptions}
                                            />
                                        }

                                        {!props.embeddedComponents &&
                                            <Item
                                                location="after"
                                                widget="dxButton"
                                                options={{
                                                    icon: "preferences",
                                                    hint: "Configurar formulário",
                                                    disabled: objectPermissionsState && !objectPermissionsState._configure,
                                                    onClick: configRecordFormStructure,
                                                }}
                                            />
                                        }

                                        {/* OPÇÕES DAS ABAS */}
                                        {!props.popupRecordForm && !props.embeddedComponents && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearAllTabs}
                                            />
                                        }

                                        {!props.popupRecordForm && !props.embeddedComponents && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearTabsRight}
                                                disabled={tabPanelItems.length === indexTabPanelMain + 1}
                                            />
                                        }

                                        {!props.popupRecordForm && !props.embeddedComponents && !props.teleCobranca &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={keepThisTab}
                                            />
                                        }

                                        {!modeEditForm && props.entityName === 'entity' && !props.embeddedComponents &&
                                        
                                            <Item
                                                location="after"
                                                widget="dxButton"       
                                                options={mountTablesAndComponentsButtonOptions}
                                            >
                                                <IconsToolBarButtonUPF 
                                                    options={mountTablesAndComponentsButtonOptions}
                                                />
                                            </Item>


                                            // <ToolbarItem
                                            //     options={buttonOptions}
                                            //     widget="dxButton"
                                            //     location="after"
                                            // />
                                        }

                                        {!modeEditForm && props.entityName === 'views' && !props.embeddedComponents &&
                                            <Item
                                                location="after"
                                                widget="dxButton"
                                                options={addColumnsFromViewButtonOption}
                                            >
                                                <IconsToolBarButtonUPF 
                                                    options={addColumnsFromViewButtonOption}
                                                />
                                            </Item>
                                        }
                                        {/* item para para mostrar enquanto os dados não são carregados */}

                                        {/* {!dataLoadedState &&
                    <Item
                        location="before"
                    >
                        <LoadingData/>
                    </Item>
                } */}
                                    </Toolbar>
                                    {props.selectedItensCount &&
                                        <div>
                                            {props.selectedItensCount === 1 &&
                                                <h3>{props.selectedItensCount} selecionado</h3>
                                            }
                                            {props.selectedItensCount > 1 &&
                                                <h3>{props.selectedItensCount} selecionados</h3>
                                            }
                                        </div>
                                    }
                                </div>
                            )}

                        <div>
                            {/*<h2 className="style-h2"><b>{entityStructure[0].masterEntity.caption}</b></h2>*/}
                        </div>
                        <div className={'dx-card'}>
                            <Popup
                                visible={confirmDelete.isVisible}
                                onHiding={hidePopupDelete}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                                showCloseButton={false}
                                showTitle={true}
                                title={confirmDelete.message}
                                width={400}
                                height={250}
                                resizeEnabled={true}
                            >
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={confirmDeleteRecord}
                                />
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={closeDeleteRecord}
                                />
                                <p>
                                    Você realmente deseja apagar este registro?
                                </p>
                            </Popup>


                            {/* POPUP DO REVERT */}
                            <Popup
                                visible={revertPopupVisible}
                                onHiding={closeRevertPopup}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                                showCloseButton={true}
                                showTitle={true}
                                title="Atenção!"
                                width={600}
                                height={280}
                                resizeEnabled={true}
                                id="popupForm"
                            >
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={confirmRevertButtonPopup}
                                />
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={cancelRevertButtonPopup}
                                />
                                <p>As alterações serão perdidas! Deseja cotinuar?</p>

                            </Popup>

                            <UpfPopup
                                typePopup="closeTabs"
                                popupVisibleState={visible}
                                popupVisibleFunctionChangeState={setVisible}
                                message={message}
                                clearTabRuleState={clearTabRuleState}
                                toolbarRef={_toolbarRef}
                            />

                            <UpfPopupForm
                                statesUpfPopupForm={statesUpfPopupForm}
                                hideUpfPopupForm={hideUpfPopupForm}
                            />

                            {visibleConfirmContextMenuState &&
                                <UpfPopupConfirmContextMenu
                                    hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                                    visibleConfirmContextMenu={visibleConfirmContextMenuState}
                                    contextMenuFunction={onClickMenuContextItem}
                                    contextMenuItemState={contextMenuItemState}
                                />
                            }

                            <Toast
                                visible={toastConfig.isVisible}
                                message={toastConfig.message}
                                type={toastConfig.type}
                                onHiding={onHiding}
                                displayTime={600}
                                height={60}
                            />

                            {/* <ScrollView width='100%' height='100%'> */}
                            
                            <div className="form-container">
                                <div>
                                    {states.entityStructure?.length > 0 && condicionalVisibleState &&
                                        <GetFormComponents
                                            resetFormData={resetFormData}
                                            entityStructure={states.entityStructure}
                                            dataEntity={dataEntity && states.entityStructure?.[0].recordFormProperties[0].recordFormComponents ? dataEntity?.masterEntity?.[0]?.records?.[0] : formDataJson}
                                            detailEntity={dataEntity && dataEntity?.detailEntity}
                                            masterDetailEntity={props.masterDetailEntity}
                                            dataLookup={dataLookup}
                                            formDataJson={formDataJson}
                                            colCount={states.entityStructure?.[0].recordFormProperties[0].colCount}
                                            modeEditOn={modeEditOn}
                                            recordInsertUpdateJson={recordInsertUpdateJson}
                                            id={props.id}
                                            modeEditForm={modeEditForm}
                                            formComponents={states.entityStructure?.[0].recordFormProperties[0].recordFormComponents ?
                                                states.entityStructure?.[0].recordFormProperties[0].recordFormComponents : []}
                                            //Abaixo estão as props que serão usadas pelo campo upfForeignResultField
                                            setModeEditForm={setModeEditForm}
                                            setFormDataJson={setFormDataJson}
                                            setDataEntity={setDataEntity}
                                            setPopupQueryFormOpen={setPopupQueryFormOpen}
                                            setQueryFormEntityName={setQueryFormEntityName}
                                            setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                                            queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                                            foreignFieldSelectedItem={foreignFieldSelectedItem}
                                            setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                                            setTituloQueryFormPopup={setTituloQueryFormPopup}
                                            queryFormPopupState={queryFormPopupState}
                                            setQueryFormPopupState={setQueryFormPopupState}
                                            objectPermissionsState={objectPermissionsState}
                                            senderFieldNamesCheckBox={senderFieldNamesCheckBox}
                                            popupRecordFormOpen={props.popupRecordFormOpen}
                                            refreshStructure={refreshStructure}
                                            setCondicionalVisibleState={setCondicionalVisibleState}
                                            setStates={setStates}
                                            idValue={idValue}
                                            setDataEntityEditedFields={setDataEntityEditedFields}
                                            dataEntityEditedFields={dataEntityEditedFields}
                                        />
                                    }
                                    {/* {console.log(states.entityStructure?.[0]?.detailEntities?.[0]?.detailEntity)} */}
                                    {console.log(dataEntityDetailEntity)}

                                    {states.entityStructure?.[0].detailEntities[0].detailEntity ? (
                                        <TabPanelDetailEntitys
                                            idValue={idValue} // para retornar o id CADASTRO
                                            setIdValue={setIdValue}
                                            entityName={props.entityName} //para retornar o nome CADASTRO
                                            setKeyFieldNameState={setKeyFieldNameState}
                                            dataEntity={dataEntity}
                                            setEntityDataEntityState={setEntityDataEntityState}
                                            setMessageError={setMessageError}
                                            hideLoadPanel={hideLoadPanel}
                                            showLoadPanel={showLoadPanel}
                                            dataEntityDetailEntity={dataEntityDetailEntity ? dataEntityDetailEntity : []}
                                            setDataEntity={setDataEntity}
                                            setPopup={setPopup}
                                            popup={popup}
                                            setEntityStructureRecordFormPopupState={setEntityStructureRecordFormPopupState}
                                            setDataLookupRecordFormPopupState={setDataLookupRecordFormPopupState}
                                            IDValueDetailEntity={IDValueDetailEntity}
                                            entityStructure={states.entityStructure}
                                            setIDValueDetailEntity={setIDValueDetailEntity}
                                            setDetailEntityName={setDetailEntityName}
                                            setPopupRecordFormOpen={setPopupRecordFormOpen}
                                            dataLookup={dataLookup}
                                            setStateModeEdit={setStateModeEdit}
                                            recordUpdateJsonDetail={recordUpdateJsonDetail}
                                            recordInsertJsonDetail={recordInsertJsonDetail}
                                            setPopupState={setPopupState}
                                            recordDeleteJsonDetail={recordDeleteJsonDetail}
                                            setPopupRecordFormAdd={setPopupRecordFormAdd}
                                            setDataLookup={setDataLookup}
                                            setDataEntityDetailEntity={setDataEntityDetailEntity}
                                            lookUpEntities={states.entityStructure?.[0].lookUpEntities && states.entityStructure?.[0].lookUpEntities}
                                            objectPermissionsState={objectPermissionsState}
                                            setDetailCaptionName={setDetailCaptionName}
                                            setDetailAttachCaptionName={setDetailAttachCaptionName}
                                            showUpfPopupForm={showUpfPopupForm}
                                            setStates={setStates}
                                            refreshDetailEntityState={refreshDetailEntityState}
                                            objectLookUpEntityViewsTipoProcessamento={props.objectLookUpEntityViewsTipoProcessamento}
                                            openLogCustomForm={openLogCustomForm}
                                        />) : null
                                    }
                                    
                                    {
                                        states.entityStructure?.[0].detailEntities[0].detailEntity &&
                                        dataEntityDetailEntity &&
                                        states.entityStructure?.[0].recordFormProperties[0].attachmentsEnabled === 1 &&
                                        <div className="form-attach">
                                            <h3>Arquivos anexos</h3>
                                            <AttachRecordForm
                                                id={Math.random() + 1}
                                                idValue={idValue}
                                                dataEntity={dataEntity}
                                                entityName={props.entityName}
                                                toastConfiguration={props.toastConfiguration}
                                                dataLookup={props.dataLookup}
                                                entityStructure={props.entityStructure}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                            />
                                        </div>
                                    }

                                    {embeddedComponentsEntityStructureArrayState?.length > 0 && embeddedComponentsDataLookupArrayState?.length > 0 && idValue && (dataEntity || props.dataEntity) &&
                                        <>
                                            {props.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents.map((item, indice) => {
                                                let entityNameEmbebedComponent = null

                                                if(item.componentParameter){
                                                    entityNameEmbebedComponent = item.componentParameter
                                                }
                                                else if(dataEntity.masterEntity?.[0]?.records?.[0]["_entityname"]){
                                                    entityNameEmbebedComponent = dataEntity.masterEntity?.[0].records[0]["_entityname"]
                                                }
                                                //estava junto das verificacoes de componentparamter e entityname, criei um else só pra ele, mas nao vimos o uso em nenhum lugar.
                                                else if(props.embebedEntityName){
                                                    entityNameEmbebedComponent = props.embebedEntityName
                                                }

                                                if(entityNameEmbebedComponent){
                                                    if (item.componentName === "recordForm") {
                                                    
                                                        return (
                                                            <>
                                                                {/* <h3>{item.caption}</h3> */}
                                                                <div style={{ padding: "0", marginTop: "0em", border: "2.5px solid blue", width: "100%", height: "auto" }}>
                                                                    <ScrollView width='100%' height='100%' useNative={true}>
                                                                        <RecordForm
                                                                            id={Math.random()}
                                                                            idValue={props.embebedIdValue ? props.embebedIdValue : dataEntity.masterEntity[0].records[0][`${item.idFieldName.toLowerCase()}`]}
                                                                            entityName={entityNameEmbebedComponent}
                                                                            entityStructure={embeddedComponentsEntityStructureArrayState[indice]}
                                                                            dataLookup={embeddedComponentsDataLookupArrayState?.length >= indice + 1 ? embeddedComponentsDataLookupArrayState[indice] : embeddedComponentsDataLookupArrayState}
                                                                            embeddedComponents={true}
                                                                        />
                                                                    </ScrollView>
                                                                </div>
                                                            </>
                                                        )
    
                                                    }
                                                    else if (item.componentName === "queryForm") {
                                                        return (
                                                            <QueryForm
                                                                entityName={entityNameEmbebedComponent}
                                                                embeddedComponents={true}
                                                            />
                                                        )
                                                    }
                                                    else if (item.componentName === "viewForm") {
                                                        return (
                                                            <ViewForm
                                                                id={item.componentParameter}
                                                                caption={item.caption}
                                                                embeddedComponents={true}
                                                            />
                                                        )
                                                    }
    
                                                    else if(item.componentName === "groupPermissionForm") {
                                                        return (
                                                            <TabpanelEmbebedItems
                                                                entityStructure={states.entityStructure}
                                                                securitygroupId={idValue}
                                                            />
                                                        )
                                                    }
                                                }
                                                else{
                                                    setMessageError("Não foi possível abrir o formulário incorporado.")
                                                    setPopup({
                                                        ...popup
                                                        , isVisible: true
                                                        , message: "Atenção!"
                                                    })
                                                }

                                                
                                            })}
                                        </>
                                    }

                                    {/* <button onClick={() => {
                                        console.log(states.entityStructure?.[0].masterEntity.entityName)
                                        console.log(dataEntity?.masterEntity?.[0]?.tarefas?.length)

                                    }}>teste</button> */}


                                    {states.entityStructure?.[0].masterEntity.entityName !== "_tarefa" &&
                                        dataEntity?.masterEntity?.[0]?.tarefas?.length > 0 &&
                                        <TarefasGrid
                                            idValueMaster={idValue}
                                            setIDValueDetailEntity={setIDValueDetailEntity}
                                            setDetailEntityName={setDetailEntityName}
                                            setDetailCaptionName={setDetailCaptionName}
                                            setPopupRecordFormOpen={setPopupRecordFormOpen}
                                            setPopupState={setPopupState}
                                            setPopupRecordFormAdd={setPopupRecordFormAdd}
                                            entityStructure={states.entityStructure}
                                            records={dataEntity?.masterEntity?.[0]?.tarefas}
                                            setDataLookupRecordFormPopupState={setDataLookupRecordFormPopupState}
                                            setEntityStructureRecordFormPopupState={setEntityStructureRecordFormPopupState}
                                            />
                                    }
                                </div>
                                {
                                    statesGroupPermissionForm.visible &&
                                    states.entityStructure?.[0]?.recordFormProperties?.[0]?.embeddedComponents?.length === 0 &&
                                    <TabpanelEmbebedItems
                                        entityStructure={states.entityStructure}
                                        securitygroupId={idValue}
                                    />
                                }


                            </div>
                            {/* </ScrollView> */}
                            <Toolbar>
                                <Button
                                    width={120}
                                    text="Contained"
                                    type="normal"
                                    stylingMode="contained"
                                />
                            </Toolbar>
                        </div>
                    </div>
                </div>
                // </ScrollView>
            ) : (<Loading />)}
        </div>
    )
}

export default memo(RecordForm);