const { createContext, useContext, useState } = require("react");

const FichaPesquisadaNaTelaGlobalState = createContext();

export const FichaPesquisadaNaTelaGlobalStateProvider = ({ children }) => {
  const [fichaPesquisadaNaTelaGlobalState, setFichaPesquisadaNaTelaGlobalState] = useState(false);

  return (
    <FichaPesquisadaNaTelaGlobalState.Provider value={{ fichaPesquisadaNaTelaGlobalState, setFichaPesquisadaNaTelaGlobalState }}>
      {children}
    </FichaPesquisadaNaTelaGlobalState.Provider>
  );
};

export const useFichaPesquisadaNaTelaGlobalState = () => useContext(FichaPesquisadaNaTelaGlobalState);