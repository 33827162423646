import Toolbar, { Item } from 'devextreme-react/toolbar';
import UpfHtmlEditor from '../UpfComponents/UpfHtmlEditor';
import { useRequestsApi } from '../../Context/RequestsApi';
import { httpRequest } from '../../functions';
import { applicationID, relationId } from '../../api/ApplicationID';
import GetFormComponents from '../../functions/functionsMapForm/GetFormComponents';
import {useState, useEffect, useCallback} from 'react'
import Loading from '../../CardComponents/Loading';
import RecordForm from '../RecordForm';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import { Toast } from 'devextreme-react/toast';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';

const TarefaCustomForm = (props) => {
    const {requestsApi} = useRequestsApi()
    const [modeEditForm, setModeEditForm] = useState(false)
    const [formDataJson, setFormDataJson] = useState({})
    const [dataLookup, setDataLookup] = useState([])
    const [dataEntity, setDataEntity] = useState()
    const [entityStructure, setEntityStructure] = useState()
    const [idValueState, setIdValueState] = useState()
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })
    const [popupStates, setPopupStates] = useState({
        visible: false,
        message: null
      }) 
    
    const hidePopup = () => {
    setPopupStates({
        ...popupStates,
        visible: false
    })
    }

    const modeEditOn = () => {
        setModeEditForm(true)
    }

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        });
    }, [toastConfig])


    useEffect(() => {
        if(props.tarefaId){
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": "_tarefa",
                "fieldName": "id",
                "expressao": props.tarefaId,
                "applicationId": applicationID
            })
            .then((sender) => {
                const resultGetDataEntityJson = JSON.parse(sender)
                setDataEntity(resultGetDataEntityJson.masterEntity[0].records[0])
                console.log(resultGetDataEntityJson.masterEntity[0].records[0])
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
        }

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_tarefa"
        })
        .then((sender) => {
            const resultGetEntityStructureJson = JSON.parse(sender)
            setEntityStructure(resultGetEntityStructureJson[0]?.recordFormProperties[0]?.recordFormComponents)
            
            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "_projeto"
                    },
                    {
                        "lookUpEntity": "_statusTarefa"
                    },
                    {
                        "lookUpEntity": "_categoriaTarefa"
                    },
                    {
                        "lookUpEntity": "_tipoTarefa"
                    },
                    {
                        "lookUpEntity": "_funcionarios"
                    }
                ]
            })
            .then((sender) => {
                const resultGetLookupTableJson = JSON.parse(sender)
                setDataLookup(resultGetLookupTableJson)
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data
                })
            })
        })
        .catch((error) => {
            setPopupStates({
                visible: true,
                message: error.data
            })
        })
    }, [])

    const refreshData = () => {
        if(dataEntity) {
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "entityName": "_tarefa",
                "fieldName": "id",
                "expressao": props.tarefaId ,
                "applicationId": applicationID
            })
            .then((sender) => {
                const resultGetDataEntityJson = JSON.parse(sender)
                setDataEntity(resultGetDataEntityJson.masterEntity[0].records[0])
                setModeEditForm(false)

            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
        }
        else {
            setFormDataJson({})
            setModeEditForm(false)
        }
    }

    const recordButtonOptions = {
        icon: 'check',
        hint: "Gravar",
        onClick: () => {
            if(dataEntity){
                httpRequest('POST', requestsApi.updateRecordFromJson, requestsApi.basicAuth, {
                    "entityName": "_tarefa",
                    "json": dataEntity,
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    console.log(sender)
                    httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                        "entityName": "_tarefa",
                        "fieldName": "id",
                        "expressao": props.tarefaId,
                        "applicationId": applicationID
                    })
                    .then((sender) => {
                        const resultGetDataEntityJson = JSON.parse(sender)
                        setDataEntity(resultGetDataEntityJson.masterEntity[0].records[0])
                        setModeEditForm(false)
                        toastConfiguration(true, 'success', "Tarefa atualizada!")

        
                    })
                    .catch((error) => {
                        setPopupStates({
                            visible: true,
                            message: error.data.error
                        })
                    })
                })
                .catch((error) => {
                    setPopupStates({
                        visible: true,
                        message: error.data.error
                    })
                })
            }
            else {
                formDataJson["_objectid"] = props.registroId ? props.registroId : null
                formDataJson["_entityname"] = props.entityName ? props.entityName : null
                formDataJson["_indice"] = null

                httpRequest('POST', requestsApi.insertRecordFromJson, requestsApi.basicAuth, {
                    "entityName": "_tarefa",
                    "json": formDataJson,
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    const resultInsertRecordFromJson = JSON.parse(sender)

                    setIdValueState(resultInsertRecordFromJson.idValue)
                    toastConfiguration(true, 'success', "Nova tarefa criada!")

                    if(resultInsertRecordFromJson.idValue){
                        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                            "entityName": "_tarefa",
                            "fieldName": "id",
                            "expressao": resultInsertRecordFromJson.idValue,
                            "applicationId": applicationID
                        })
                        .then((sender) => {
                            const resultGetDataEntityJson = JSON.parse(sender)
                            setDataEntity(resultGetDataEntityJson.masterEntity[0].records[0])
                            setModeEditForm(false)
                        })
                        .catch((error) => {
                            setPopupStates({
                                visible: true,
                                message: error.data.error
                            })
                        })
                    }
                })
                .catch((error) => {
                    setPopupStates({
                        visible: true,
                        message: error.data.error
                    })
                })
            }
        },
    }
    const revertButtonOptions = {
        icon: 'revert',
        hint: "Reverter",
        onClick: refreshData,
    }

    return(
        <div className="tab-items">
            {entityStructure ?
                (
                    <>
                        <Toolbar
                            className={"div-toolbar"}
                            height={70}
                        >
                            <Item 
                                location="before"
                                widget="dxButton"
                                options={recordButtonOptions}
                                disabled={!modeEditForm}
                            />
                            <Item 
                                location="before"
                                widget="dxButton"
                                options={revertButtonOptions}
                                disabled={!modeEditForm}
                            />
                        </Toolbar>

                        <UpfPopupMessage
                            visible={popupStates.visible}
                            hidePopup={hidePopup}
                            message={popupStates.message}
                        />

                        <Toast
                            visible={toastConfig.isVisible}
                            message={toastConfig.message}
                            type={toastConfig.type}
                            onHiding={onHiding}
                            displayTime={600}
                            height={60}
                        />

                        <Form 
                            colCount={4}
                            formData={dataEntity ? dataEntity : formDataJson}
                            onFieldDataChanged={() => {
                                setModeEditForm(true)
                                console.log(formDataJson)
                            }}
                            // validationGroup={`groupName${props.id}`}
                            // labelLocation="top"
                            showColonAfterLabel={true}
                            // minColWidth={200}
                            alignItemLabelsInAllGroups={true}
                            alignItemLabels={true}
                        >
                            <GroupItem 
                                // colCount={props.groupItemColSpan}
                                // colSpan={props.groupItemColCount}
                                colCount={4}
                                colSpan={4}
                                // cssClass={'group-item'}
                                // colCountByScreen={true}
                                itemType={'group'}
                                showClearButton={true}
                                icon={'folder'}
                                cssClass={'group-item'}
                            >

                                {/* <SimpleItem 
                                    editorType={"dxDateBox"}
                                    caption={"Data"}
                                    dataField={"_data"} 
                                    colSpan={2} 
                                    fieldName={"_data"}
                                    editorOptions={{
                                        valueChangeEvent: ["keyup", "paste"],
                                        // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                    }}
                                >
                                    <Label text={"Data"}/>
                                        
                                </SimpleItem> */}

                                <SimpleItem 
                                    editorType='dxSelectBox'
                                    colSpan={4} 
                                    caption={"Projeto"} 
                                    dataField={"_projetoid"} 
                                    editorOptions=
                                    {{
                                        items: dataLookup && dataLookup._projeto,
                                        valueExpr: "id",
                                        displayExpr: "_descricao",
                                        // hint: field.hint,
                                        placeholder: "Selecione uma opção",
                                        visible: true,
                                        showClearButton: false,
                                        deferRendering: true,
                                        searchEnabled: true,
                                        wrapItemText: false,
                                        searchMode: "contains",
                                        showDataBeforeSearch: true,
                                        valueChangeEvent: ["keyup", "paste"],
                                        showTitle: true
                                    }}
                                >
                                    
                                    <Label text={"Projeto"}/>
                                </SimpleItem>

                                <SimpleItem 
                                    editorType={"dxTextBox"}
                                    caption={"Descrição"}
                                    dataField={"_descricao"} 
                                    colSpan={4} 
                                    fieldName={"_descricao"}
                                    editorOptions={{
                                        valueChangeEvent: ["keyup", "paste"],
                                        // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                        dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                    }}
                                >
                                    <Label text={"Descrição"}/>
                                        
                                </SimpleItem>

                                <SimpleItem
                                
                                    colSpan={4} 
                                    // caption={field.fieldCaption} 
                                    dataField={"_observacoes"} 
                                    render={() => {
                                        return(
                                            <UpfHtmlEditor 
                                                // setFormDataJson={props.setFormDataJson}
                                                // setDataEntity={props.setDataEntity}
                                                dataEntity={formDataJson}
                                                // entityStructor={field}
                                                colSpan={4} 
                                                caption={"Observações"}
                                                dataField={"_observacoes"}
                                                customForm={true}
                                                // disabled={fieldPermissions?._edit === 1 ? (field.readOnly) : (true)}
                                                // hint={field.hint}
                                                // setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                modeEditOn={() => {setModeEditForm(true)}}
                                            />
                                        )}}
                                    editorOptions={{ 
                                        valueChangeEvent: ["keyup", "paste"]
                                    }}
                                >
                                    <Label 
                                        text={"Observações"}
                                        visible={false}
                                        // location="left"
                                    />
                                </SimpleItem>

                                <SimpleItem 
                                    editorType='dxSelectBox'
                                    colSpan={2} 
                                    caption={"Status"} 
                                    dataField={"_statustarefaid"} 
                                    editorOptions=
                                    {{
                                        items: dataLookup && dataLookup._statusTarefa,
                                        valueExpr: "id",
                                        displayExpr: "_descricao",
                                        // hint: field.hint,
                                        placeholder: "Selecione uma opção",
                                        visible: true,
                                        showClearButton: false,
                                        deferRendering: true,
                                        searchEnabled: true,
                                        wrapItemText: false,
                                        searchMode: "contains",
                                        showDataBeforeSearch: true,
                                        valueChangeEvent: ["keyup", "paste"],
                                        showTitle: true
                                    }}
                                >
                                    
                                    <Label text={"Status"}/>
                                </SimpleItem>

                                <SimpleItem 
                                    editorType='dxSelectBox'
                                    colSpan={2} 
                                    caption={"Tipo da tarefa"} 
                                    dataField={"_tipotarefaid"} 
                                    editorOptions=
                                    {{
                                        items: dataLookup && dataLookup._tipoTarefa,
                                        valueExpr: "id",
                                        displayExpr: "_descricao",
                                        // hint: field.hint,
                                        placeholder: "Selecione uma opção",
                                        visible: true,
                                        showClearButton: false,
                                        deferRendering: true,
                                        searchEnabled: true,
                                        wrapItemText: false,
                                        searchMode: "contains",
                                        showDataBeforeSearch: true,
                                        valueChangeEvent: ["keyup", "paste"],
                                        showTitle: true
                                    }}
                                >
                                    
                                    <Label text={"Tipo da tarefa"}/>
                                </SimpleItem>

                                <SimpleItem 
                                    editorType={"dxDateBox"}
                                    caption={"Inicio"}
                                    dataField={"_datainicio"} 
                                    colSpan={2} 
                                    fieldName={"_datainicio"}
                                    editorOptions={{
                                        valueChangeEvent: ["keyup", "paste"],
                                        // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                        dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                    }}
                                >
                                    <Label text={"Inicio"}/>
                                        
                                </SimpleItem>

                                <SimpleItem 
                                    editorType={"dxDateBox"}
                                    caption={"Final"}
                                    dataField={"_datafim"} 
                                    colSpan={2} 
                                    fieldName={"_datafim"}
                                    editorOptions={{
                                        valueChangeEvent: ["keyup", "paste"],
                                        // dateSerializationFormat:"dd-MM-yy"ta quebrando no calendario
                                        dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                    }}
                                >
                                    <Label text={"Final"}/>
                                        
                                </SimpleItem>
                            </GroupItem>
                        </Form>

                        {props.entityName && props.registroId && (props.tarefaId || idValueState) &&
                            <>
                            <h1>{props.caption ? props.caption : props.entityName}</h1>
                                <RecordForm 
                                    id={Math.random()} 
                                    idValue={props.registroId} 
                                    entityName={props.entityName}
                                    dataLookup={props.dataLookup}
                                    entityStructure={entityStructure}
                                />
                            </>
                        }
                    </>
                ) 
                : 
                (
                    <Loading />
                )
            }
            
        </div>
    )
}

export default TarefaCustomForm;