const { createContext, useContext, useState } = require("react");

const JsonHestiaLogProcessamento = createContext();

export const JsonHestiaLogProcessamentoProvider = ({ children }) => {
  const [jsonHestiaLogProcessamento, setJsonHestiaLogProcessamento]= useState(false);

  return (
    <JsonHestiaLogProcessamento.Provider value={{ jsonHestiaLogProcessamento, setJsonHestiaLogProcessamento }}>
      {children}
    </JsonHestiaLogProcessamento.Provider>
  );
};

export const useJsonHestiaLogProcessamento = () => useContext(JsonHestiaLogProcessamento);