import {useState, useEffect, memo, useRef, useCallback} from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import { convertDate } from '../../functions/convertDate';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import { LoadPanel } from 'devextreme-react/load-panel';
import UpfPopupMessage from '../../CardComponents/UpfPopupMessage';
import TextBox from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    MasterDetail,
    TotalItem
    } from 'devextreme-react/data-grid';
import CheckBox from 'devextreme-react/check-box';
import { Toast } from 'devextreme-react/toast';
import { Popup } from 'devextreme-react/popup';
import ConsultaBoletoForm from '../ConsultaBoletoForm';
import {useQuery} from '@tanstack/react-query'
import DetailTemplate from './DetailTemplate';
import { ScrollView } from 'devextreme-react';
import Loading from '../../CardComponents/Loading';
import "./style.css"

const SolNegociacaoCustomForm = (props) => {
    const {requestsApi} = useRequestsApi()
    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])
    const [carregouGridState, setCarregouGridState] = useState(false)
    const [opcoesParcelamentoHavanState, setOpcoesParcelamentoHavanState] = useState([])

    const [popupBoletoVisibleState, setPopupBoletoVisibleState] = useState(false)
    const [idBoletoState, setIdBoletoState] = useState(false)


    // const visualizarBoletoFunction = () => {
    //     // setPopupBoletoVisibleState(true)
    //     return(true)
    // }

    // const visualizarBoletoQuery = useQuery({ queryFn: visualizarBoletoFunction })

    const hidePopupBoleto = () => {
        if(props.hideUpfPopupForm){
            props.hideUpfPopupForm()
        }
        setPopupBoletoVisibleState(false)
    }

    const senderFieldNamesCheckBox = ["_isacordo"]

    const _dataGridParcelasRef = useRef(null)
    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
    {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }
);

    const [states, setStates] = useState({
        dataSource: null,
        dataSourceMemo: "",
        tipoNegociacaoState: null,
        opcaoNegociacaoState: null,
        arraySelecionados: [],
        toastVisible: false,
        toastType: "info",
        toastMessage: ""
    })

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }


    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const [loadingPanelState, setLoadingPanelState] = useState(true)

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }

    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState(false)
    }, [loadingPanelState])

    const showLoadPanel = useCallback(() => {
        setLoadingPanelState(true)
    }, [loadingPanelState])

    const refreshDataGrid = (selecionados) => {
        _dataGridParcelasRef.current.instance.beginUpdate()
        if (selecionados?.length > 0) {
            console.log(selecionados)
            _dataGridParcelasRef.current.instance.refresh()
            _dataGridParcelasRef.current.instance.selectRows(selecionados, true)
        }
        else if(selecionados?.length === 0){
            _dataGridParcelasRef.current.instance.deselectAll()
        }
        else {
            _dataGridParcelasRef.current.instance.refresh()
            _dataGridParcelasRef.current.instance.selectAll()
        }
        _dataGridParcelasRef.current.instance.endUpdate()
    }

    const [email, setEmail] = useState()
    const [telefones, setTelefones] = useState()

    const [clienteIdState, setClienteIdState] = useState()

    const getTelefonesEmail = (clienteId) => {
        //para pegar o email do cliente
        httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro",
            "fieldName": "id",
            "expressao": clienteId
        })
        .then((sender) => {
            const resultSenderJsonCliente = JSON.parse(sender) 
           
                //para pegar os telefones
                httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": "_cadastro_contatos",
                    "fieldName": "_cadastro_id",
                    "expressao": clienteId
                })
                .then((sender) => {
                    const resultSenderJsonTelefones = JSON.parse(sender)
                    setTelefones(resultSenderJsonTelefones?.records)
                    setEmail(resultSenderJsonCliente?.records?.[0]?._cryptemail)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
        .catch((err) => {
            console.log(err)
        }) 

    }

    const getClienteId = () => {
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_titulo",
            "fieldName": "id",
            "expressao": props.tituloId
        })
        .then((sender) => {
            const resultGetDataEntityJson = JSON.parse(sender)
            setClienteIdState(resultGetDataEntityJson?.masterEntity?.[0]?.records?.[0]?._clienteid) 
            getTelefonesEmail(resultGetDataEntityJson?.masterEntity?.[0]?.records?.[0]?._clienteid)
        })
        .catch((err) => {
            console.log(err)
        })
    }


    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    const entityStructureFieldsDefs = [
        // {
        //     fieldName: "_parcela",
        //     fieldCaption: "Parcela",
        //     editorType: null,
        //     format: null,
        //     visible: 1,
        //     showInColumnChooser: 1
        // },

        {
            fieldName: "contrato",
            fieldCaption: "Contrato",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "nomeproduto",
            fieldCaption: "Nome Produto",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "parcela",
            fieldCaption: "Parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "diasatraso",
            fieldCaption: "Atraso",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "datavencimento",
            fieldCaption: "Vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "valortotal",
            fieldCaption: "Total",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "valorpermanencia",
            fieldCaption: "+ Perm.",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "valormora",
            fieldCaption: "+ Mora",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valormulta",
            fieldCaption: "+ Multa",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valoroutros",
            fieldCaption: "+ Outros",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valordesconto",
            fieldCaption: "- Desc.",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "valoratual",
            fieldCaption: "= Total a pagar",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        // {
        //     fieldName: "_faseTaxaDescricaoAtualizacao",
        //     fieldCaption: "Taxa Descrição Atualização",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseJurosTaxaAtualizacao",
        //     fieldCaption: "Juros Taxa Atualização",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseMultaTaxaAtualizacao",
        //     fieldCaption: "Multa Taxa Atualização",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseHonorarioTaxaAtualizacao",
        //     fieldCaption: "Honorario Taxa Atualização",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseTaxaDescricaoQuitacao",
        //     fieldCaption: "Taxa Descricao Quitação",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseJurosTaxaQuitacao",
        //     fieldCaption: "Juros Taxa Quitação",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseMultaTaxaQuitacao",
        //     fieldCaption: "Multa Taxa Quitação",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_faseHonorarioTaxaQuitacao",
        //     fieldCaption: "Honorario Taxa Quitação",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 1
        // },
        // {
        //     fieldName: "_JurosValorQuitacao",
        //     fieldCaption: "Juros Quitação",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_MultaValorQuitacao",
        //     fieldCaption: "Multa Quitação",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_JurosValorAtualizacao",
        //     fieldCaption: "Juros Atualização",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_MultaValorAtualizacao",
        //     fieldCaption: "Multa Atualização",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_HonorarioSobrePrincipal",
        //     fieldCaption: "Honorário",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_HonorarioSobreParcelasVincendas",
        //     fieldCaption: "Honorário Parcelas Vincendas",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_HonorarioValorAtualizacao",
        //     fieldCaption: "Honorário Atualização",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_HonorarioValorQuitacao",
        //     fieldCaption: "Honorário Quitação",
        //     editorType: null,
        //     format: 'money',
        //     visible: 0,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_TotalValorQuitacao",
        //     fieldCaption: "Valor a pagar (quitação)",
        //     editorType: null,
        //     format: 'money',
        //     visible: 1,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_TotalValorAtualizacao",
        //     fieldCaption: "Valor a pagar (atualização)",
        //     editorType: null,
        //     format: 'money',
        //     visible: 1,
        //     showInColumnChooser: 1
        // },

        // {
        //     fieldName: "_isacordo",
        //     fieldCaption: "Acordo",
        //     editorType: 'dxCheckBox',
        //     format: null,
        //     visible: 1,
        //     showInColumnChooser: 1
        // }

    ]

    const colunasOpcoesParcelamento = [
        {
            fieldName: "datavencimento",
            fieldCaption: "Data vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "dataultimovencimento",
            fieldCaption: "Data último vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1 
        },

        {
            fieldName: "numeroparcelas",
            fieldCaption: "Parcelas",
            editorType: 'dxNumberBox',
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: 0,
            defaultSortOrder: "asc",
        },
        {
            fieldName: "descontoprincipalmax",
            fieldCaption: "Desconto principal max",
            editorType: null,
            format: 'percent',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "descontomoramax",
            fieldCaption: "Desconto mora max",
            editorType: null,
            format: 'percent',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valorentrada",
            fieldCaption: "Valor entrada",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valorparcela",
            fieldCaption: "Valor parcela",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "taxaoperacao",
            fieldCaption: "Taxa operação",
            editorType: null,
            format: 'percent',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valorprincipal",
            fieldCaption: "Valor principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "valordivida",
            fieldCaption: "Valor dívida",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        // {
        //     fieldName: "_percentualsugestaoprimeiraparcela",
        //     fieldCaption: "Percentual sugestão primeira parcela",
        //     editorType: null,
        //     format: "percent",
        //     visible: 0,
        //     showInColumnChooser: 0
        // },
        {
            fieldName: "valorjuros",
            fieldCaption: "Valor juros",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        // {
        //     fieldName: "_percentualacrescimo",
        //     fieldCaption: "Percentual acréscimo",
        //     editorType: null,
        //     format: "percent",
        //     visible: 1,
        //     showInColumnChooser: 1
        // },
        {
            fieldName: "valortotal",
            fieldCaption: "Valor total",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        }
    ]

    const [dataLookup, setDataLookup] = useState()

    const [negociacoesState, setNegociacoesState] = useState([])
    const [dataSourceParcelasParcelamentosState, setdataSourceParcelasParcelamentosState] = useState()
    const [dataSourceParcelamentosState, setdataSourceParcelamentosState] = useState()
    const [dataSourceParcelasState, setDataSourceParcelasState] = useState()
    
    const [dataSourceSimulacaoState, setDataSourceSimulacaoState] = useState({
        negociacao: null,
        meioPagamento: null,
        descontoPrincipal: 0,
        descontoMora: 0,
        entradaValor: 0,
        entradaVencimento: ""
    })

    const [dataSourceSimulacaoStateMemo, setDataSourceSimulacaoStateMemo] = useState({
        negociacao: "",
        meioPagamento: "",
        descontoPrincipal: 0,
        descontoMora: 0,
        entradaValor: 0,
        entradaVencimento: ""
    })

    const acordoSimularFunction = (negociacaoSelecionada, ) => {
        showLoadPanel()
        console.log(loadingPanelState)
        
        httpRequest("POST", requestsApi.acordoSimular, requestsApi.basicAuth, {
            applicationId: applicationID,
            tituloId: props.tituloId,
            negociacao: dataSourceSimulacaoStateMemo.negociacao,
            meioPagamento: dataSourceSimulacaoStateMemo.meioPagamento,
            descontoPrincipal: dataSourceSimulacaoStateMemo.descontoPrincipal,
            descontoMora: dataSourceSimulacaoStateMemo.descontoMora,
            entradaValor: dataSourceSimulacaoStateMemo.entradaValor,
            entradaVencimento: dataSourceSimulacaoStateMemo.entradaVencimento
        })
        .then((sender) => {
            httpRequest("POST", requestsApi.acordoSimular, requestsApi.basicAuth, {
                applicationId: applicationID,
                tituloId: props.tituloId,
                negociacao: dataSourceSimulacaoState.negociacao,
                meioPagamento: dataSourceSimulacaoState.meioPagamento,
                descontoPrincipal: dataSourceSimulacaoState.descontoPrincipal,
                descontoMora: dataSourceSimulacaoState.descontoMora,
                entradaValor: dataSourceSimulacaoState.entradaValor,
                entradaVencimento: dataSourceSimulacaoState.entradaVencimento
            })
            .then((sender) => {
                const resultJson = JSON.parse(sender)

                hideLoadPanel()
    
                if(resultJson?.parcelas){
                    convertDate(resultJson.parcelas, entityStructureFieldsDefs)
                    resultJson.parcelas.sort((a,b) => {
                        return a.diasatraso < b.diasatraso ? -1 : a.diasatraso > b.diasatraso ? 1 : 0;
                    });
                    setDataSourceParcelasState(resultJson.parcelas)
                }
    
                if(resultJson?.parcelamentos){
                    convertDate(resultJson.parcelamentos, colunasOpcoesParcelamento)
                    // if(resultJson.parcelas?.length > 0){
                    //     calcularDescontosMax(resultJson.parcelamentos, resultJson.parcelas[0])
                    // }
                    setdataSourceParcelamentosState(resultJson.parcelamentos)
                }
    
                if(resultJson?.parcelamentos_parcelas){
                    for(let c = 0; c <= resultJson.parcelamentos_parcelas.length - 1; c++){
                        resultJson.parcelamentos_parcelas[c].datavencimento = resultJson.parcelamentos_parcelas[c].datavencimento.substring(0, resultJson.parcelamentos_parcelas[c].datavencimento?.length - 5)
                    }
                    setdataSourceParcelasParcelamentosState(resultJson.parcelamentos_parcelas)
                }
    
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })

        })
        .catch((error) => {
            hideLoadPanel()
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    useEffect(() => {
        httpRequest("POST", requestsApi.negociacoes, requestsApi.basicAuth, {
            applicationId: applicationID,
        })
        .then((sender) => {
            const resultJson = JSON.parse(sender)
            setDataSourceSimulacaoState({...dataSourceSimulacaoState, 
                negociacao: resultJson?.[0]?.idnegociacao,
                meioPagamento: resultJson?.[0]?.id
            })
            setDataSourceSimulacaoStateMemo(
                {...dataSourceSimulacaoStateMemo, 
                    negociacao: resultJson?.[0]?.idnegociacao,
                    meioPagamento: resultJson?.[0]?.id
                }
            )
            setNegociacoesState(resultJson)

            httpRequest("POST", requestsApi.acordoSimular, requestsApi.basicAuth, {
                applicationId: applicationID,
                tituloId: props.tituloId,
                negociacao: resultJson?.[0]?.idnegociacao,
                meioPagamento: resultJson?.[0]?.id,
                descontoPrincipal: dataSourceSimulacaoStateMemo.descontoPrincipal,
                descontoMora: dataSourceSimulacaoStateMemo.descontoMora,
                entradaValor: dataSourceSimulacaoStateMemo.entradaValor,
                entradaVencimento: dataSourceSimulacaoStateMemo.entradaVencimento
            })
            .then((sender) => {
                const resultSimularAcordoJson = JSON.parse(sender)
                if(resultSimularAcordoJson?.parcelas){
                    convertDate(resultSimularAcordoJson.parcelas, entityStructureFieldsDefs)
                    resultSimularAcordoJson.parcelas.sort((a,b) => {
                        return a.diasatraso < b.diasatraso ? -1 : a.diasatraso > b.diasatraso ? 1 : 0;
                    });
                    setDataSourceParcelasState(resultSimularAcordoJson.parcelas)
                }
    
                if(resultSimularAcordoJson?.parcelamentos){
                    convertDate(resultSimularAcordoJson.parcelamentos, colunasOpcoesParcelamento)
                    // if(resultSimularAcordoJson.parcelas?.length > 0){
                    //     calcularDescontosMax(resultSimularAcordoJson.parcelamentos, resultSimularAcordoJson.parcelas[0])
                    // }
                    setdataSourceParcelamentosState(resultSimularAcordoJson.parcelamentos)
                }
    
                if(resultSimularAcordoJson?.parcelamentos_parcelas){
                    for(let c = 0; c <= resultSimularAcordoJson.parcelamentos_parcelas.length - 1; c++){
                        resultSimularAcordoJson.parcelamentos_parcelas[c].datavencimento = resultSimularAcordoJson.parcelamentos_parcelas[c].datavencimento.substring(0, resultSimularAcordoJson.parcelamentos_parcelas[c].datavencimento?.length - 5)
                    }
                    setdataSourceParcelasParcelamentosState(resultSimularAcordoJson.parcelamentos_parcelas)
                }
                hideLoadPanel()

    
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        })
        .catch((error) => {
            hideLoadPanel()
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
        
    }, [])

    const [parcelamentoSelecionadoState, setParcelamentoSelecionadoState] = useState()

    const acordoEfetivar = () => {
        showLoadPanel()
        if(parcelamentoSelecionadoState){
            httpRequest('POST', requestsApi.acordoEfetivar, requestsApi.basicAuth, {
                applicationId: applicationID,
                relationId: relationId,
                tituloId: props.tituloId,
                negociacao: dataSourceSimulacaoState.negociacao,
                meioPagamento: dataSourceSimulacaoState.meioPagamento,
                parcelamento: parcelamentoSelecionadoState.id
                // parcelamento: {
                //     numeroParcelas: parcelamentoSelecionadoState.numeroparcelas,
                //     valorEntrada: parcelamentoSelecionadoState.valorentrada,
                //     dataEmissao: parcelamentoSelecionadoState.dataemissao,
                //     dataVencimento: parcelamentoSelecionadoState.datavencimento,
                //     descontoDivida: parcelamentoSelecionadoState.descontodivida,
                //     taxaOperacao: parcelamentoSelecionadoState.taxaoperacao,
                //     descontoTarifa: parcelamentoSelecionadoState.descontotarifa
                // }
            })
            .then((sender) => {
                const resultAcordoEfetivarJson = JSON.parse(sender)

                if(resultAcordoEfetivarJson?.message){
                    setMessage(resultAcordoEfetivarJson.message)
                    setVisible(true)
                }
                else if (resultAcordoEfetivarJson?.idBoleto?.length > 0){
                    toastConfiguration(true, "success", "Procedimento realizado com sucesso!")
                    setIdBoletoState(resultAcordoEfetivarJson?.idBoleto)
                    setPopupBoletoVisibleState(true)
                }
                else{
                    toastConfiguration(true, "success", "Procedimento realizado com sucesso!")
                    props.hideUpfPopupForm()
                }
                   
                
                hideLoadPanel()
                // setOpcoesParcelamentoHavanState(resultGetOpcoesParcelamentoHavanJson)
            })
            .catch((err) => {
                console.log(err)
                setMessage(err.data.error)
                setVisible(true)
                hideLoadPanel()
            })

            getClienteId()
        }
        else{
            setMessage("Selecione uma parcela para continuar.")
            setVisible(true)
            hideLoadPanel()
        }
    }


    return(
        <>
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />
            {negociacoesState && dataSourceParcelasState ?
                (
                    <div style={{height: "81vh", padding: "0.5em"}}>
                        {/* <ScrollView  width='100%' height='100%'> */}

                        <Toast
                            visible={states.toastVisible}
                            message={states.toastMessage}
                            type={states.toastType}
                            onHiding={onHidingToast}
                            displayTime={1800}
                            height={60}
                        />

                        <LoadPanel
                            id='load-panel-sol-negociacao-customForm'
                            shadingColor="rgba(0,0,0,0.4)"
                            visible={loadingPanelState}
                            // visible={true}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            hideOnOutsideClick={false}
                        />

                        {idBoletoState &&
                            <Popup
                                visible={popupBoletoVisibleState}
                                onHiding={() => {
                                    hidePopupBoleto()
                                }}
                                dragEnabled={false}
                                // hideOnOutsideClick={true}
                                showCloseButton={true}
                                showTitle={true}
                                title="Boleto"
                                fullScreen={true}
                                resizeEnabled={true}
                            >
                                <ScrollView  width='100%' height='100%' useNative={true}>
                                    <ConsultaBoletoForm 
                                        boletoId={idBoletoState}
                                        email={email}
                                        telefones={telefones}
                                        dataLookup={dataLookup}
                                        masterEntityId={props.gerarBoletoDataSource?.masterEntityId}
                                        hideUpfPopupForm={props.hideUpfPopupForm}
                                        dataSourceBoletoState={{
                                            clienteId: clienteIdState
                                        }}
                                        // setIndexMultiViewItemsGerarBoleto={props.setIndexMultiViewItemsGerarBoleto}
                                        // indexMultiViewItemsGerarBoleto={props.indexMultiViewItemsGerarBoleto}
                                    />
                                </ScrollView>
                            </Popup>
                        }


                            {props.showTitle &&
                                <h2 className="title-acordo">Acordo</h2>
                            }
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{border: "solid 1px grey", padding: "0.5em"}}>
                                    <h2>Parcelas originais</h2>
                                    <DataGrid
                                            ref={_dataGridParcelasRef}
                                            dataSource={dataSourceParcelasState}
                                            columnAutoWidth={true}
                                            showBorders={true}
                                            cacheEnabled={false}
                                            onContentReady={() => {
                                                if(dataSourceParcelasVencidasVincendasState?.length > 0 && !carregouGridState){
                                                    setCarregouGridState(true)
                                                    _dataGridParcelasRef.current.instance.selectAll()
                                                }

                                            }}
                                            onSelectionChanged={(sender) => {
                                                let itensSelecionados = sender.component.getSelectedRowsData()
                                                setStates({
                                                    ...states,
                                                    arraySelecionados: itensSelecionados
                                                })
                                            }}
                                        >

                                            <HeaderFilter
                                                allowSearch={true}
                                                visible={true}
                                            />

                                            {/* <GroupPanel
                                                visible={true}
                                                allowColumnDragging={true}
                                            /> */}

                                            <Selection 
                                                mode="multiple"
                                                keyExpr='id'
                                                showCheckBoxesMode="none"
                                            />

                                            <Sorting
                                                mode="multiple"
                                            />
                                            <Scrolling 
                                                rowRenderingMode='infinity'
                                                showScrollbar='always'
                                                useNative={true}
                                                >
                                            </Scrolling>

                                            <Paging defaultPageSize={6} />

                                            <Sorting
                                                mode="none"
                                            />
                                            
                                            <Pager
                                                visible={true}
                                                // allowedPageSizes={6}
                                                // showPageSizeSelector={true}
                                                showInfo={true}
                                                showNavigationButtons={true} />
                                            
                                            {/* <HeaderFilter
                                                    allowSearch={true}
                                                    visible={true}
                                                />
                                            <GroupPanel
                                                visible={true}
                                                allowColumnDragging={true}
                                            />
                                            <FilterRow visible={true} /> */}
                                            
                                            <ColumnChooser 
                                                enabled={false} 
                                                width={400} 
                                                height={800} 
                                                mode={"select"} 
                                                allowSearch={true}
                                            />

                                            {entityStructureFieldsDefs.map((item, index) => {
                                                if(item.editorType === 'dxDateBox'){
                                                    return(
                                                        <Column
                                                            key={index}
                                                            dataField={item.fieldName}
                                                            caption={item.fieldCaption}
                                                            dataType='date'
                                                            format="shortDate"
                                                            visible={item.visible}                                            
                                                        />
                                                    )
                                                }
                                                else if(item.editorType === 'dxCheckBox'){
                                                    return(
                                                        <Column
                                                            key={index}
                                                            dataField={item.fieldName}
                                                            caption={item.fieldCaption}
                                                            dataType='boolean'
                                                            visible={item.visible}
                                                        />
                                                    )
                                                }
                                                else if(item.format === 'money') {
                                                    return(
                                                        <Column
                                                            key={index}
                                                            dataField={item.fieldName}
                                                            caption={item.fieldCaption}
                                                            visible={item.visible}
                                                            format="R$ #,##0.##;(R$ #,##0.##)"
                                                        />
                                                    )
                                                }
                                                else if (item.format === 'percent') {
                                                    return (
                                                        <Column
                                                            key={index}
                                                            dataField={item.fieldName}
                                                            caption={item.fieldCaption}
                                                            visible={item.visible}
                                                            showInColumnChooser={item.showInColumnChooser}
                                                            format="#0.##'%'"
                                                        />
                                                    )
                                                }
                                                else {
                                                    return(
                                                        <Column
                                                            key={index}
                                                            dataField={item.fieldName}
                                                            caption={item.fieldCaption}
                                                            visible={item.visible}
                                                            showInColumnChooser={item.showInColumnChooser}
                                                        />
                                                    )
                                                }
                                                
                                            })}
                                            <Summary
                                                    calculateCustomSummary={calculateCustomSummary}
                                                >

                                                    <TotalItem
                                                        column={'_Valor'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_TotalValorAtualizacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_TotalValorQuitacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_ValorPago'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_MultaValorQuitacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_MultaValorAtualizacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_JurosValorQuitacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_JurosValorAtualizacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_HonorarioValorQuitacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />
                                                    <TotalItem
                                                        column={'_HonorarioValorAtualizacao'}
                                                        summaryType="sum"
                                                        format={{
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                            precision: 2,
                                                        }}
                                                        customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                    />

                                                </Summary>

                                        </DataGrid>
                                </div>
                            </div>

                            <div style={{display: "flex", minWidth: "300px", flexDirection: "column", border: "solid 1px grey", padding: "0.5em"}}>
                                    <div style={{display: "flex", flexDirection: "column", marginBottom: "1em"}}>
                                        <h2>Simulação</h2>

                                        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                                            <div>
                                                <div>Forma de pagamento:</div>

                                                <div>
                                                    {dataSourceSimulacaoState?.meioPagamento ? 
                                                    (
                                                        <SelectBox
                                                            items={negociacoesState}
                                                            valueExpr="id"
                                                            displayExpr="nome"
                                                            value={dataSourceSimulacaoState.meioPagamento}
                                                            onValueChange={(item) => {
                                                                console.log(item)

                                                                const negociacaoSelecionada = negociacoesState?.find((e) => e.id === item)
                                                                console.log(negociacaoSelecionada?.nome)
                                                                console.log(negociacaoSelecionada?.idnegociacao)
                                                                console.log(negociacaoSelecionada?.id)

                                                                setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                                    negociacao: negociacaoSelecionada?.idnegociacao,
                                                                    meioPagamento: negociacaoSelecionada?.id
                                                                })
                                                            }}
                                                        />

                                                    ) 
                                                    : 
                                                    (
                                                        <SelectBox
                                                            items={negociacoesState}
                                                            valueExpr="id"
                                                            displayExpr="nome"
                                                            onValueChange={(item) => {
                                                                console.log(item)

                                                                const negociacaoSelecionada = negociacoesState?.find((e) => e.id === item)
                                                                setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                                    negociacao: negociacaoSelecionada?.idnegociacao,
                                                                    meioPagamento: negociacaoSelecionada?.id
                                                                })
                                                            }}
                                                        />
                                                    )
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <div >Entrada Vencimento:</div>

                                                <DateBox
                                                    acceptCustomValue={true}
                                                    activeStateEnabled={true}
                                                    adaptivityEnabled={false}
                                                    pickerType={"calendar"}
                                                    type="date"
                                                    placeholder={""}
                                                    dateSerializationFormat="yyyy-MM-dd"
                                                    onValueChange={(item) => {
                                                        setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                            entradaVencimento: item
                                                        })
                                                    }}
                                                    // value={states.dateBoxState}
                                                    // onContentReady={() => {
                                                    //     if(states.dateBoxState === null){
                                                    //         let stringDate = new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit'})
                                                    //         let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')
                                                    
                                                    //         //junta o formato da data da segunda linha com a hora da primeira
                                                    //         let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
                                                    //         // setStates({...states,
                                                    //         //     dateBoxState: stringDateResult
                                                    //         // })
                                                    //         states.dateBoxState = stringDateResult
                                                    //     }
                                                    // }
                                                    // }
                                                    // onValueChanged={(sender) => {
                                                    //     setStates({...states,
                                                    //         dateBoxState: sender.value
                                                    //     })
                                                    //     //states.dateBoxState = sender.value.toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
                                                    // }}
                                                    showDropDownButton={true}
                                                    spellcheck={false}
                                                    // value={states.dateBoxState && states.dateBoxState}
                                                    visible={true}
                                                />
                                            </div>

                                            <div>
                                                <div >Entrada Valor:</div>

                                                <NumberBox
                                                    mode="number"
                                                    format="R$ #,##0.##00"
                                                    min={0}
                                                    value={dataSourceSimulacaoState.entradaValor}
                                                    onValueChange={(item) => {
                                                        setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                            entradaValor: item,
                                                        })
                                                    }}
                                                    // value={props?.valorSemDescontoState}
                                                    // disabled={true}
                                                />
                                            </div>

                                            <div>
                                                <div >Desconto Mora:</div>

                                                <NumberBox
                                                    mode="number"
                                                    format="#0.##'%'"
                                                    min={0}
                                                    max={100}
                                                    // max={dataSourceParcelasState[0].descontomoramax}
                                                    value={dataSourceSimulacaoState.descontoMora}
                                                    onValueChange={(item) => {
                                                        setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                            descontoMora: item
                                                        })
                                                    }}
                                                />

                                            </div>

                                            <div>
                                                <div >Desconto Principal:</div>

                                                <NumberBox
                                                    mode="number"
                                                    format="#0.##'%'"
                                                    // max={dataSourceParcelasState[0].descontoPrincipal}
                                                    min={0}
                                                    max={100}
                                                    value={dataSourceSimulacaoState.descontoPrincipal}
                                                    onValueChange={(item) => {
                                                        setDataSourceSimulacaoState({...dataSourceSimulacaoState,
                                                            descontoPrincipal: item
                                                        })
                                                    }}
                                                />

                                            </div>


                                            <Button
                                                style={{marginTop: "1em"}}
                                                disabled={loadingPanelState}
                                                width={160}
                                                height={40}
                                                text="Simular"
                                                type="default"
                                                stylingMode="contained"
                                                onClick={() => {
                                                    acordoSimularFunction()
                                                }}
                                            ></Button>

                                        </div>
                                    </div> 
                                </div>

                            {/* {states.opcaoNegociacaoState &&  */}

                            <div style={{border: "solid 1px grey", padding: "0.5em", marginTop: "1em"}}>
                                <h2>Opções de parcelamento</h2>

                                <DataGrid
                                    dataSource={dataSourceParcelamentosState}
                                    columnAutoWidth={true}
                                    allowColumnResizing={true}
                                    columnResizingMode="widget"
                                    keyExpr="id"
                                    showBorders={true}
                                    onSelectionChanged={(sender) => {
                                        if(sender.selectedRowsData[0]){
                                            setParcelamentoSelecionadoState(sender.selectedRowsData[0])
                                        }
                                    }}
                                >

                                    <HeaderFilter
                                        allowSearch={true}
                                        visible={true}
                                    />

                                    {/* <GroupPanel
                                        visible={true}
                                        allowColumnDragging={true}
                                    /> */}

                                    <Selection 
                                        mode="single"
                                        keyExpr='id'
                                    />

                                    <Sorting
                                        mode="multiple"
                                    />
                                    <Scrolling 
                                        rowRenderingMode='infinity'
                                        showScrollbar='always'
                                        useNative={true}
                                        >
                                    </Scrolling>

                                    <Paging defaultPageSize={10} />
                                    
                                    <Pager
                                        visible={true}
                                        // allowedPageSizes={6}
                                        // showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true} 
                                    />
                                    
                                    
                                    <ColumnChooser 
                                        enabled={false} 
                                        width={400} 
                                        height={800} 
                                        mode={"select"} 
                                        allowSearch={true}
                                    />

                                    {colunasOpcoesParcelamento.map((item, index) => {
                                        if(item.editorType === 'dxDateBox'){
                                            return(
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    dataType='date'
                                                    visible={item.visible}                                            
                                                />
                                            )
                                        }
                                        else if(item.editorType === 'dxCheckBox'){
                                            return(
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    dataType='boolean'
                                                    visible={item.visible}
                                                />
                                            )
                                        }
                                        else if(item.format === 'money') {
                                            return(
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    visible={item.visible}
                                                    format="R$ #,##0.##;(R$ #,##0.##)"
                                                />
                                            )
                                        }
                                        else if (item.format === 'percent') {
                                            return (
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    visible={item.visible}
                                                    showInColumnChooser={item.showInColumnChooser}
                                                    format="#0.##'%'"
                                                />
                                            )
                                        }
                                        else if(item.editorType === 'dxNumberBox'){
                                            return(
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    visible={item.visible}
                                                    dataType='number'
                                                    showInColumnChooser={item.showInColumnChooser}
                                                    groupIndex={item?.groupIndex}
                                                    defaultSortOrder={item?.defaultSortOrder}
                                                    defaultSortIndex={item?.defaultSortIndex}
                                                />
                                            )
                                        }
                                        else {
                                            return(
                                                <Column
                                                    key={index}
                                                    dataField={item.fieldName}
                                                    caption={item.fieldCaption}
                                                    visible={item.visible}
                                                    showInColumnChooser={item.showInColumnChooser}
                                                    groupIndex={item?.groupIndex}
                                                    defaultSortOrder={item?.defaultSortOrder}
                                                    defaultSortIndex={item?.defaultSortIndex}
                                                />
                                            )
                                        }  
                                    })}

                                    <MasterDetail 
                                        enabled={true}
                                        component={(data) => {
                                            return(
                                                <DetailTemplate
                                                    data={data.data}
                                                    parcelamentosParcelas={dataSourceParcelasParcelamentosState}
                                                />
                                            )
                                        }}
                                    />

                                    <Summary
                                        calculateCustomSummary={calculateCustomSummary}
                                    >

                                        <TotalItem
                                            column={'_Valor'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_TotalValorAtualizacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_TotalValorQuitacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_ValorPago'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_MultaValorQuitacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_MultaValorAtualizacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_JurosValorQuitacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_JurosValorAtualizacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_HonorarioValorQuitacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />
                                        <TotalItem
                                            column={'_HonorarioValorAtualizacao'}
                                            summaryType="sum"
                                            format={{
                                                style: 'currency',
                                                currency: 'BRL',
                                                precision: 2,
                                            }}
                                            customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                        />

                                    </Summary>
                                </DataGrid>

                            </div>

                            <div>
                                <div className="div-boleto-form-vencimento">
                                    <Button
                                        width={160}
                                        height={40}
                                        text="Efetivar acordo"
                                        type="default"
                                        disabled={loadingPanelState || !opcoesParcelamentoHavanState}
                                        stylingMode="contained"
                                        onClick={() => {
                                            acordoEfetivar()
                                        }}
                                    ></Button>

                                    {/* <CheckBox
                                        style={{marginRight: "1em"}}
                                        text="Gerar boleto"
                                        onValueChanged={(e) => {
                                            setGerarBoletoState(e.value)
                                        }}
                                    /> */}
                                </div>
                            </div>  
                    </div>
                )
                :
                (
                    <Loading/>
                )
            }
        </>
    )
}

export default memo(SolNegociacaoCustomForm);