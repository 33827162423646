import { relationId } from "../api/ApplicationID";

export const getDefaultValues = (field, data, insertState) => {
    if (insertState && field.defaultValue) {
        //Object with conditions
        const defaultValuesObj = {
            "{date}": () => {
                let date = new Date();
                date.setHours(0, 0, 0, 0);
    
                const offset = date.getTimezoneOffset();
                date = new Date(date.getTime() - (offset*60*1000));
    
                data[`${field.fieldName}`] = date.toISOString().split('T')[0];
            },
            "{dateTime}": () => data[`${field.fieldName}`] = new Date(),
            "{relationId}": () => data[`${field.fieldName}`] = relationId,            
            "1": () => {                
                field.editorType === "dxCheckBox"
                ? data[`${field.fieldName}`] = true
                : data[`${field.fieldName}`] = 1
            },
            "0": () => {                
                field.editorType === "dxCheckBox"
                ? data[`${field.fieldName}`] = false
                : data[`${field.fieldName}`] = 0
            },
            "default": () => data[`${field.fieldName}`] = field.defaultValue
        }

        //Exec
        defaultValuesObj[field.defaultValue]
        ? defaultValuesObj[String(field.defaultValue)]()
        : defaultValuesObj["default"]();
    }
}