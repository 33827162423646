const { createContext, useContext, useState } = require("react");

const MainMenuJson = createContext();

export const MainMenuJsonProvider = ({ children }) => {
  const [mainMenuJson, setMainMenuJson]= useState(
    [
      {
        text : "Principal",
        path : "telecobrancaForm",
        icon : "folder",
        entityName: null,
        badge : null,
        parameters : null,
        permissionName : null,
        items : null 
      },
      {
        text : "Tarefas",
        path : "tarefasForm",
        icon : "folder",
        entityName: null,
        badge : null,
        parameters : null,
        permissionName : null,
        items : null 
      },
      {
        text : "Consulta de Tarefas",
        path : "viewForm",
        icon : "detailslayout",
        entityName: null,
        badge : null,
        parameters : "973547E2933F4584A1606B380950AD23",
        permissionName : null,
        items : null
      },
      {
        text : "Detalhes do Cliente",
        path : "recordFormCliente",
        icon : "folder",
        entityName: "_cadastro",
        badge : null,
        parameters : null,
        permissionName : null,
        items : null
      },
      {
        text : "Detalhes do Contrato",
        path : "recordFormContrato",
        icon : "folder",
        entityName: "_titulo",
        badge : null,
        parameters : null,
        permissionName : null,
        items : null
      },
      {
        text : "Gerar Acordo",
        path : "gerarAcordoForm",
        icon : "folder",
        entityName: null,
        badge : null,
        parameters : null,
        permissionName : null,
        items : null
      }
      ,
      {
        text : "Gerar Boleto",
        path : "boletoForm",
        icon : "folder",
        entityName: null,
        badge : null,
        parameters : null,
        permissionName : null,
        items : null
      }
    ]);

  return (
    <MainMenuJson.Provider value={{ mainMenuJson, setMainMenuJson }}>
      {children}
    </MainMenuJson.Provider>
  );
};

export const useMainMenuJson = () => useContext(MainMenuJson);