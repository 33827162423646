import MultiView from 'devextreme-react/multi-view';
import {useState, useEffect, useCallback, memo} from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID } from '../../../api/ApplicationID';
import { convertDate } from '../../../functions/convertDate';
import Passo1 from "./EtapasBoleto/Passo1"
import Passo2 from "./EtapasBoleto/Passo2"
import Passo3 from "./EtapasBoleto/Passo3"
import "./style.css"
import HtmlFormPopup from "../../HtmlFormPopup"

const GerarBoletoForm = (props) => {
    const { requestsApi } = useRequestsApi()

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    //lembrar de tirar os estados globais que tem o valor do tituloid
    // dentro dos passos e colocar por props
    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const entityStructureFieldsDefs = [
        {
            fieldName: "_parcela",
            fieldCaption: "Parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TipoParcela",
            fieldCaption: "Tipo parcela",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_NumeroDocumento",
            fieldCaption: "Número Documento",
            editorType: null,
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_vencimento",
            fieldCaption: "Vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorAtualizacao",
            fieldCaption: "Mora Valor Atualização",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorParcial",
            fieldCaption: "Mora Valor Parcial",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_MoraValorQuitacao",
            fieldCaption: "Mora Valor Quitação",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_Valor",
            fieldCaption: "Principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaAtualizacao",
            fieldCaption: "Taxa Mora Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaParcial",
            fieldCaption: "Taxa Mora Parcial",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMoraTaxaQuitacao",
            fieldCaption: "Taxa Mora Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoAtualizacao",
            fieldCaption: "Taxa Descrição Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaAtualizacao",
            fieldCaption: "Juros Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaAtualizacao",
            fieldCaption: "Multa Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaAtualizacao",
            fieldCaption: "Honorário Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseTaxaDescricaoQuitacao",
            fieldCaption: "Taxa Descricao Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseJurosTaxaQuitacao",
            fieldCaption: "Juros Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseMultaTaxaQuitacao",
            fieldCaption: "Multa Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_faseHonorarioTaxaQuitacao",
            fieldCaption: "Honorário Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showInColumnChooser: 1
        },
        {
            fieldName: "_JurosValorQuitacao",
            fieldCaption: "Juros Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorQuitacao",
            fieldCaption: "Multa Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_JurosValorAtualizacao",
            fieldCaption: "Juros Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_MultaValorAtualizacao",
            fieldCaption: "Multa Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobrePrincipal",
            fieldCaption: "Honorário sobre principal",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioSobreParcelasVincendas",
            fieldCaption: "Honorário Parcelas Vincendas",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorAtualizacao",
            fieldCaption: "Honorário Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_HonorarioValorQuitacao",
            fieldCaption: "Honorário Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showInColumnChooser: 1
        },

        {
            fieldName: "_DiasAtraso",
            fieldCaption: "Atraso",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorQuitacao",
            fieldCaption: "Valor a pagar (quitação)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },

        {
            fieldName: "_TotalValorAtualizacao",
            fieldCaption: "Valor a pagar (atualização)",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_NumeroAcordo",
            fieldCaption: "Número Acordo",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "_isacordo",
            fieldCaption: "Acordo",
            editorType: 'dxCheckBox',
            format: null,
            visible: 1,
            showInColumnChooser: 1
        }

    ]

    // let dataSourceParcelasVencidasVincendas = []

    const senderFieldNamesCheckBox = ["_isacordo"]

    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])

    const [indexMultiViewItemsGerarBoleto, setIndexMultiViewItemsGerarBoleto] = useState(0)
    const [gerarBoletoDataSource, setGerarBoletoDataSource] = useState()

    const [dateBoxState, setDateBoxState] = useState(null)
    const [arraySelecionadosState, setArraySelecionadosState] = useState([])



    const getParcelasVencidasVincendasFunction = (dataSelecionada) => {
        setArraySelecionadosState([])
        showLoadPanel()
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
            // entityName: "_titulo_parcela_vencidas_vincendas",
            // fieldName: "_tituloid",
            tituloId: props.tituloId,//proximaFichaDados.tituloId,
            applicationId: applicationID,
            parcelas: props.parcelasIdArray,
            tipoNegociacao: 1, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
            dataCalculo: dataSelecionada ? dataSelecionada : stringDateResult
        })
        .then((sender) => {
            if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                setMessage("Valor de getParcelasVencidasVincendas inválido!")
                setVisible(true)
            }
            else{
                const resultSenderJson = JSON.parse(sender)

                resultSenderJson.sort((a, b) => { return b?._DiasAtraso - a?._DiasAtraso})

                //pega os campos checkbox e altera de null pra 0
                for (let c = 0; c <= resultSenderJson.length - 1; c++) {
                    for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                        if (resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                            resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] = 0
                        }
                    }
                }
                let TotalValorAtualizacao = 0;
                let TotalValorQuitacao = 0;
                let TotalValor = 0;
                resultSenderJson.map(val => {
                    TotalValorAtualizacao += val._TotalValorAtualizacao
                    TotalValorQuitacao += val._TotalValorQuitacao
                    TotalValor += val._Valor
                })
                // setTotals({ _TotalValorAtualizacao: TotalValorAtualizacao, _TotalValorQuitacao: TotalValorQuitacao, _Valor: TotalValor })
                
                setDataSourceParcelasVencidasVincendasState(resultSenderJson)
                

                // props.setDataSourceParcelasVencidasVincendasState(resultSenderJson)



                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(resultSenderJson, entityStructureFieldsDefs)
                //

                hideLoadPanel()
            }
            
        })
        .catch((error) => {
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    const [dataLookup, setDataLookup] = useState()

    const [structureDataGridEnderecosState, setStructureDataGridEnderecosState] = useState()

    const [dataSourceEnderecosState, setDataSourceEnderecosState] = useState([])

    useEffect(() => {
        getParcelasVencidasVincendasFunction()
        if(!dataLookup){
            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "TipoNegociacao"
                    },
                    {
                        "lookUpEntity": "OpcaoNegociacao"
                    },
                    {
                        "lookUpEntity": "_tipo_contato"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    }
                ]
            })
                .then((sender) => {
                    const resultJson = JSON.parse(sender)
                    setDataLookup(resultJson)
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })
        }

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro_enderecos"
        })
        .then((sender) => {
            const resultEntityStructureJsonRecords = JSON.parse(sender)

            setStructureDataGridEnderecosState(resultEntityStructureJsonRecords?.[0]?.queryFormProperties?.[0]?.fieldsDefs)
            
        })
        .catch((err) => {
            console.log(err)
        })


        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro_enderecos",
            "fieldName": "_cadastro_id",
            "expressao": props.clienteId
        })
        .then((sender) => {
            const resultDataEntityJsonRecords = JSON.parse(sender)?.masterEntity?.[0]?.records

            for(let c = 0; c <= resultDataEntityJsonRecords.length - 1; c++){
                if(resultDataEntityJsonRecords[c]?._ispadrao === null){
                    resultDataEntityJsonRecords[c]["_ispadrao"] = 0
                }
            }

            console.log(resultDataEntityJsonRecords)
            setDataSourceEnderecosState(resultDataEntityJsonRecords)
            
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()


    const multiViewItems = [
        {
            //  title: itemClicado.itemData.text
            // ,icon: itemClicado.itemData.icon
            className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <Passo1 
                                tituloId={props.tituloId}
                                userType={"operador"}
                                showTitle={props.showTitle}
                                setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                                indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                                setGerarBoletoDataSource={setGerarBoletoDataSource}
                                gerarBoletoDataSource={gerarBoletoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                dateBoxState={dateBoxState}
                                setDateBoxState={setDateBoxState}
                                entityStructureFieldsDefs={entityStructureFieldsDefs}
                                getParcelasVencidasVincendasFunction={getParcelasVencidasVincendasFunction}
                                dataSourceParcelasVencidasVincendasState={dataSourceParcelasVencidasVincendasState}
                                dataLookup={dataLookup}
                                loadingPanelState={loadingPanelState}
                                disabledButtonState={disabledButtonState}
                                visible={visible}
                                setVisible={setVisible}
                                message={message}
                                setMessage={setMessage}
                                arraySelecionadosState={arraySelecionadosState}
                                setArraySelecionadosState={setArraySelecionadosState}
                                marcarTodasParcelas={props.parcelasIdArray ? true : false}
                            />
                        </ScrollView>
            // ,text: Math.random()
            // ,type: itemClicado.itemData.path
        },
        {
        //     title: itemClicado.itemData.text
        //    ,icon: itemClicado.itemData.icon
           className: "tab-sheet"
           //,icon: '/images/favicon.ico'
           ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                           <Passo2
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                                indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                                setGerarBoletoDataSource={setGerarBoletoDataSource}
                                gerarBoletoDataSource={gerarBoletoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                dataSourceEnderecosState={dataSourceEnderecosState}
                                setDataSourceEnderecosState={setDataSourceEnderecosState}
                                structureDataGridEnderecosState={structureDataGridEnderecosState}
                                // setBoletoBinarioId={setBoletoBinarioId}
                           />
                       </ScrollView>
           ,text: Math.random()
        //    ,type: itemClicado.itemData.path
        },
        {
        className: "tab-sheet"
        ,component: 
                    <Passo3
                        tituloId={props.tituloId}
                        clienteId={props.clienteId}
                        setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                        indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                        setGerarBoletoDataSource={setGerarBoletoDataSource}
                        gerarBoletoDataSource={gerarBoletoDataSource}
                        hideUpfPopupForm={props.hideUpfPopupForm}
                        abertoMenuTelecob={props.abertoMenuTelecob}
                        // _boletoBinarioId={boletoBinarioId}
                    />
        ,text: Math.random()
        }
    ]

    // useEffect(() => {
    //     console.log(indexMultiViewItemsGerarBoleto)
    //     console.log(gerarBoletoDataSource)
    // }, [indexMultiViewItemsGerarBoleto, gerarBoletoDataSource])

    return(
        <div className="tab-items-telecob">
            <MultiView
                items={multiViewItems} 
                deferRendering={true}
                itemRender={contentTab}
                selectedIndex={indexMultiViewItemsGerarBoleto}
                tabIndex={indexMultiViewItemsGerarBoleto}
                repaintChangesOnly={false}
                animationEnabled={false}
                swipeEnabled={false}
                showNavButtons={true}
                scrollByContent={true}
                // selectedItem={multiViewItems[indexMultiViewItemsGerarBoleto]}
            />
        </div>
    )
}

export default GerarBoletoForm;