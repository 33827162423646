const { createContext, useContext, useState } = require("react");

const TelecobIndexTabPanelGlobalState = createContext();

export const TelecobIndexTabPanelGlobalStateProvider = ({ children }) => {
  const [telecobIndexTabPanelGlobalState, setTelecobIndexTabPanelGlobalState]= useState(0);

  return (
    <TelecobIndexTabPanelGlobalState.Provider value={{ telecobIndexTabPanelGlobalState, setTelecobIndexTabPanelGlobalState }}>
      {children}
    </TelecobIndexTabPanelGlobalState.Provider>
  );
};

export const useTelecobIndexTabPanelGlobalState = () => useContext(TelecobIndexTabPanelGlobalState);