import { Popup, ToolbarItem } from 'devextreme-react/popup';

const UpfPopupConfirmContextMenu = (props) => {

    console.log(props)
    const confirmButtonPopup = {
        text: 'Sim'
        , onClick: () => {

            const newContextMenuItemState = props.contextMenuItemState

            try{
                if(newContextMenuItemState?.itemData){
                    newContextMenuItemState.itemData.askConfirm = 0
                    
                }
                else{
                    newContextMenuItemState.askConfirm = 0
                }
                props.contextMenuFunction(newContextMenuItemState)
                props.hidePopupConfirmContextMenu()
            }
            catch{
                props.hidePopupConfirmContextMenu()
            }
        }
    };
    const cancelButtonPopup = {
        text: 'Não'
        , onClick: props.hidePopupConfirmContextMenu
    };
    return(
        <Popup
            visible={props.visibleConfirmContextMenu}
            onHiding={props.hidePopupConfirmContextMenu}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title="Atenção!"
            width={600}
            height={310}
            resizeEnabled={true}
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={confirmButtonPopup}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelButtonPopup}
            />
            {props?.contextMenuItemState?.itemData?.text ? 
            (
                <>
                    Confirma {props?.contextMenuItemState?.itemData?.text ? '"' + props?.contextMenuItemState?.itemData?.text + '"': "realizar o procedimento" }?
                </>
            ) 
            : 
            (
                <>
                    Confirma {props?.contextMenuItemState?.caption ? '"' + props?.contextMenuItemState?.caption + '"': "realizar o procedimento" }?
                </>
            )}
        </Popup>
    )
}

export default UpfPopupConfirmContextMenu;