import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import UpfListKanban from "../../../../CardComponents/UpfListKanban"
import {useState, useEffect, useRef} from "react"
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { httpRequest } from '../../../../functions';
import { applicationID, relationId } from "../../../../api/ApplicationID"
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomTitle from "./CustomTitle"
import CustomItem from "./CustomItem"
import Accordion from 'devextreme-react/accordion';
import Kanban from '../Kanban';

const WelcomeTab = (props) => {
    

    return (
            <div className="tab-items">
                {/* <Accordion
                    className='Accordion'
                    dataSource={[
                        {
                            "title": "Minhas Tarefas",
                            "component": <Kanban/>
                        }
                    ]}
                    collapsible={true}
                    multiple={true}
                    animationDuration={300}
                    // selectedItems={selectedItems}
                    // onSelectionChanged={this.selectionChanged}
                    itemTitleRender={CustomTitle}
                    itemRender={CustomItem}
                /> */}
                <ScrollView
                    // className="scrollable-board"
                    // direction="vertical"
                    // showScrollbar="always"
                    useNative={true}
                    width='100%' 
                    height='100%'
                >
                    <Kanban
                        telecob={props.telecob}
                    />
                </ScrollView>
            </div>
    );
}

export default WelcomeTab;