import {useState, useEffect, useRef, useCallback, memo }from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import ScrollView from 'devextreme-react/scroll-view';
import Loading from "../../../../CardComponents/Loading"
import { httpRequest } from '../../../../functions';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { applicationID, relationId } from '../../../../api/ApplicationID';
import { Toast } from 'devextreme-react/toast';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Editing,
    } from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import HtmlFormPopup from "../../../HtmlFormPopup";
import UpfPopupMessage from "../../../../CardComponents/UpfPopupMessage"
import { fecharFormAtual } from "../../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { RangeRule, CustomRule } from "devextreme-react/validator";
import { useUpfFloatPopupMessageVisibleGlobalState } from "../../../../Context/UpfFloatPopupMessageVisibleGlobalState";
// import LoadCircular from "../../../../CardComponents/LoadCircular";
import { executeActionUpfFloatPopupMessage } from "../../../../functions/executeActionUpfFloatPopupMessage";

const Passo3 = (props) => {
    const dataGridRef = useRef(null);
    const dateBoxref = useRef(null);    

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()
    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()

    const [gerarAcordoDisabledState, setGerarAcordoDisabledState] = useState(false)

     //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: "",
        onHidden: false
        })

    //Funções
    const onHidingToast = useCallback(() => {
        setToastConfig({
        ...toastConfig,
        isVisible: false
        });
    },[toastConfig])

    const toastConfiguration = useCallback((visible, type, message, onHidden) => {
        setToastConfig({
          ...toastConfig,
          isVisible: visible,
          type: type,
          message: message,
          onHidden: onHidden
        });
      },[toastConfig])
//---------TOAST----------//

    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [gerouAcordo, setGerouAcordo] = useState(false)

    const [dateBoxState, setDateBoxState] = useState();
    const [selectedItem, setSelectedItem] = useState()

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState()

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const [totalComtaxaParcelamento, setTotalComTaxaParcelamento] = useState();
    const [opcaoParcelamentoId, setOpcaoParcelamentoId] = useState();

    const hidePopup = () => {
        setVisible(false)
    }

    //---------------------------
    function getRowDataUpdated(cellId, entrada) {
        return new Promise((resolve, reject) => {
            httpRequest('POST', requestsApi.getOpcoesParcelamentoCredor, requestsApi.basicAuth, {
                credorId: props.credorId,
                tituloId: props.tituloId,
                applicationId: applicationID,
                filtroOperadorLotePortal: 1,
                valorTotal: Number(+props.gerarAcordoDataSource.arrayTotais._total.toFixed(2)),
                valorEntrada: Number(entrada)
            })
            .then((sender) => {
                const resultSenderJson = JSON.parse(sender)                
                const opcaoParcelamento = resultSenderJson.filter(item => item._id === cellId)[0];

                if (opcaoParcelamento) {
                    resolve({
                        _totalcomtaxaparcelamento: opcaoParcelamento._totalcomtaxaparcelamento,
                        _id: opcaoParcelamento._id,
                        _taxaparcelamentovalor: opcaoParcelamento._taxaparcelamentovalor
                    });
                }
            })
            .catch((err) => reject(err))
        })
    }

    function updateRowEditing(e) {
        if (e.newData._entrada && e.newData._entrada !== e.oldData._entrada) {                                
            if (e.oldData._taxajurosparcelamento > 0) {
                const instance = dataGridRef.current.instance;
                const rowIndex = instance.getRowIndexByKey(e.key);
                const totalTaxaParcelamentoColumnIndex = instance.getVisibleColumns().filter(item => item.name === '_totalcomtaxaparcelamento')[0].index;
                const taxaParcelamentoValorColumnIndex = instance.getVisibleColumns().filter(item => item.name === '_taxaparcelamentovalor')[0].index;

                getRowDataUpdated(e.key._id, e.newData._entrada)
                    .then((data) => { 
                        if (data._totalcomtaxaparcelamento) {
                            instance.cellValue(rowIndex, totalTaxaParcelamentoColumnIndex, data._totalcomtaxaparcelamento);
                        }
                        if (data._taxaparcelamentovalor) {
                            instance.cellValue(rowIndex, taxaParcelamentoValorColumnIndex, data._taxaparcelamentovalor);
                        }
                        
                    })
            }
        }
    }

    function calculateCellValue(rowData) {        
        const valorParcela = (rowData._totalcomtaxaparcelamento - Number(rowData._entrada)) / rowData._qtdparcela;
        return valorParcela;
    }

    const [minMaxValue, setMinMaxValue] = useState({
        min: 0,
        max: Infinity
    });

    return(
        <div style={{padding: "0.5em"}}>
            {/* <ScrollView> */}
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />
            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
                onHidden={() => {
                    if(toastConfig.onHidden){
                        fecharFormAtual(tabPanelItems, indexTabPanelMain, setIndexTabPanelMain, props.hideUpfPopupForm)
                        setToastConfig({...toastConfig, 
                            onHidden: false
                        })
                    }
                }}
            />
            <div className="dx-field">
                <div className="dx-field-label">Vencimento primeira parcela:</div>
                    <div className="dx-field-value">
                        <DateBox
                            ref={dateBoxref}
                            acceptCustomValue={true}
                            activeStateEnabled={true}
                            adaptivityEnabled={false}
                            pickerType={"calendar"}
                            type="date"
                            placeholder={""} 
                            //defaultValue={props.vencParcela}
                            value={dateBoxState}
                            onContentReady={() => {
                                if (!dateBoxState && props.gerarAcordoDataSource) {
                                    const date = new Date(props.gerarAcordoDataSource.arrayTotais.vencimentoEntrada);
                                    date.setMonth(date.getMonth() + 1);
                                    const stringDateResult = date.toISOString().split("T")[0];
                                    setDateBoxState(stringDateResult);
                                }
                            }
                            }
                            onValueChanged={(sender) => {
                                //console.log("dateBoxref instance 1:", dateBoxref.current.instance);
                                //dateBoxref.current.instance._changevalue
                                const valueFormated = sender.value.replace(/\//g, "-");
                                setDateBoxState(valueFormated)                                                                
                            }}
                            showDropDownButton={true}
                            spellcheck={false}
                            visible={true}
                        />
                    </div>
            </div>
        
            {props.acordoDataGridDataSource ?
            (
                <>
                    <div style={{marginBottom: "0.5em"}}>
                        Selecione uma opção de pagamento para gerar o acordo:
                    </div>
                    <DataGrid
                        ref={dataGridRef}
                        dataSource={props.acordoDataGridDataSource}
                        columnAutoWidth={true}
                        showBorders={true}
                        onSelectionChanged={(sender) => {
                            setSelectedItem(sender.component.getSelectedRowsData())
                        }}
                        onEditingStart={e => {
                            const instance = dataGridRef.current.instance;                                                       
                            instance.selectRows([e.key], false);

                            setMinMaxValue({
                                min: e.data._entradaminima,
                                max: e.data._entradamaxima
                            });
                        }}                        
                        onRowUpdating={updateRowEditing}
                    >
                        <Selection 
                            mode="single"
                            keyExpr="ID"
                        />
                        <Scrolling 
                            useNative={true}
                            rowRenderingMode='infinity'
                            showScrollbar='always'
                            >
                        </Scrolling>

                        <Paging defaultPageSize={15} />

                        <Sorting
                            mode="single"
                        />
                        
                        <Pager
                            visible={true}
                            showInfo={true}
                            showNavigationButtons={true} />
                        
                        <Editing
                            mode="cell"
                            allowUpdating
                        />
                        <Column
                            dataField="_entradaminima"
                            caption="Entrada mínima"                            
                            format={{ type: 'currency', currency: 'BRL', precision: 2 }}
                            allowEditing={false}
                        ></Column>
                        <Column
                            dataField="_entradamaxima"
                            caption="Entrada máxima"
                            //defaultSortOrder="asc"
                            format={{ style: 'currency', currency: 'BRL', useGrouping: true, minimumSignificantDigits: 2 }}
                            allowEditing={false}
                        ></Column>
                        <Column
                            dataField="_entrada"
                            dataType="number"
                            caption="Entrada (editável)"
                            // defaultSortOrder="asc"
                            format={{ type: 'currency', currency: 'BRL', precision: 2 }}
                        >
                            <RangeRule message="Valor inválido" min={minMaxValue.min} max={minMaxValue.max} />
                        </Column>
                        <Column
                            dataField="_qtdparcela"
                            caption="Quantidade de Parcelas"
                            defaultSortOrder="asc"
                            allowEditing={false}
                        ></Column>
                        <Column
                            dataField="_valorparcela"
                            caption="Valor da Parcela"
                            format={{ type: "currency", style: 'currency', currency: 'BRL', useGrouping: true, minimumSignificantDigits: 2, precision: 2 }}
                            allowEditing={false}
                            calculateCellValue={calculateCellValue}                            
                        ></Column>
                        <Column 
                            dataField="_taxajurosparcelamento"
                            caption="Taxa"
                            format={{ type: 'percent', precision: 2 }}
                            allowEditing={false}
                            calculateCellValue={(obj) => {
                                if (obj._taxajurosparcelamento) {
                                    return obj._taxajurosparcelamento / 100;
                                }
                                return obj._taxajurosparcelamento;
                            }}
                        />
                        <Column 
                            dataField="_taxaparcelamentovalor"
                            caption="Valor taxa"
                            format={{ type: 'currency', currency: 'BRL', precision: 2 }}
                            allowEditing={false}
                        />              
                        <Column 
                            dataField="_descontopercentual"
                            caption="Desconto"
                            format={{ type: 'percent', precision: 2 }}
                            allowEditing={false}
                            calculateCellValue={(obj) => {
                                if (obj._descontopercentual) {
                                    return obj._descontopercentual / 100;
                                }
                                return obj._descontopercentual;
                            }}
                        />  
                        <Column 
                            dataField="_descontovalor"
                            caption="Valor desconto"
                            format={{ type: 'currency', currency: 'BRL', precision: 2 }}
                            allowEditing={false}
                        />              
                        <Column
                            dataField="_totalcomtaxaparcelamento"                            
                            caption="Total"
                            format={{ type: 'currency', currency: 'BRL', precision: 2 }}
                            allowEditing={false}
                        ></Column>
                    </DataGrid>

                    <div className="div-boleto-form-vencimento">
                        <div className="button-with-load-div" style={{ marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Button
                                width={130}
                                height={40}
                                text="Gerar Acordo"
                                type="default"
                                stylingMode="contained"
                                disabled={props.habilitarGerarAcordo === 0}
                                //disabled={props.habilitarGerarAcordo === 0 ? true : gerarAcordoDisabledState}
                                onClick={() => {                                   
                                    const dataGridInstance = dataGridRef.current.instance;
                                    dataGridInstance.saveEditData();
                                    setSelectedItem(dataGridInstance.getSelectedRowsData());
                                    const [rowData] = dataGridInstance.getSelectedRowsData();                                   
                                    const valorParcela = Number(calculateCellValue(rowData).toFixed(2));
                                    if (!gerouAcordo) {
                                        if(selectedItem) {                                                        
                                            if (selectedItem[0]._entrada < selectedItem[0]._entradaminima ||
                                                    selectedItem[0]._entrada > selectedItem[0]._entradamaxima) 
                                            {
                                                setGerarAcordoDisabledState(false)
                                                setMessage("ERROR: O valor da entrada não pode estar fora do intervalo especificado.")
                                                setVisible(true)
                                                return;
                                            }
                                            setGerarAcordoDisabledState(true);

                                            httpRequest("POST", requestsApi.gerarAcordo, requestsApi.basicAuth,{
                                                applicationId: applicationID,
                                                tituloId: props.tituloId,
                                                vencimentoEntrada: props.gerarAcordoDataSource.arrayTotais.vencimentoEntrada,
                                                vencimentoParcela: dateBoxState.split("T")[0],
                                                valorEntrada: Number(selectedItem[0]._entrada),
                                                valorParcela: valorParcela,
                                                qtdParcela: selectedItem[0]._qtdparcela,
                                                parcelas: props.gerarAcordoDataSource.parcelasSelecionadasId,
                                                relationId: relationId,
                                                TipoDesconto: props.dadosPagementoAcordoObjectState.TipoDesconto,
                                                CalculadoPrincipal: props.dadosPagementoAcordoObjectState.CalculadoPrincipal,
                                                CalculadoJuros: props.dadosPagementoAcordoObjectState.CalculadoJuros,
                                                CalculadoMulta: props.dadosPagementoAcordoObjectState.CalculadoMulta,
                                                CalculadoHonorario: props.dadosPagementoAcordoObjectState.CalculadoHonorario,

                                                CalculadoMora: props.dadosPagementoAcordoObjectState.CalculadoMora,
                                                CalculadoCartorio: props.dadosPagementoAcordoObjectState.CalculadoCartorio,
                                                CalculadoOutrasDespesas:props.dadosPagementoAcordoObjectState.CalculadoOutrasDespesas,
                                                DescontoMora: props.dadosPagementoAcordoObjectState.DescontoMora,

                                                DescontoPrincipal: props.dadosPagementoAcordoObjectState.DescontoPrincipal,
                                                DescontoJuros: props.dadosPagementoAcordoObjectState.DescontoJuros,
                                                DescontoMulta: props.dadosPagementoAcordoObjectState.DescontoMulta,
                                                DescontoHonorario: props.dadosPagementoAcordoObjectState.DescontoHonorario,
                                                SaldoAtualizado: props.dadosPagementoAcordoObjectState.SaldoAtualizado,
                                                DescontoTotal: props.dadosPagementoAcordoObjectState.DescontoTotal,
                                                ValorTotalAPagar: props.dadosPagementoAcordoObjectState.ValorTotalAPagar
                                            })
                                            .then((sender) => {  
                                                const resultSenderJson = JSON.parse(sender)                                              
                                                toastConfiguration(true, 'success', 'Acordo gerado com sucesso!', true)
                                                // setUpfFloatPopupMessageVisibleGlobalState({
                                                //     visible: false,
                                                //     fixo: true,
                                                //     mensagemId: null,
                                                //     actionName: "acordo"
                                                // })
                                                executeActionUpfFloatPopupMessage(
                                                    "acordo"
                                                    ,null
                                                    ,requestsApi
                                                    ,applicationID
                                                    ,relationId
                                                    ,httpRequest
                                                )
                                                setGerouAcordo(true)
                                                if(resultSenderJson?.idboleto && resultSenderJson?.arquivopdf){
                                                    props.getEmailTelefonesBoleto(resultSenderJson?.arquivopdf, resultSenderJson?.idboleto)
                                                }
                                                //Pegar template html 
                                                // httpRequest('POST', requestsApi.execProcedureJson,  requestsApi.basicAuth,{
                                                //     "applicationId": applicationID,
                                                //     "relationId": relationId,
                                                //     "procedure": "getAcordoConfissaoHtml",
                                                //     "json": {},
                                                //     "idValue": [
                                                //         resultSenderJson.mensagem
                                                //     ]
                                                // })
                                                // .then((sender) => {
                                                //     const resultJsonTemplateHtml = JSON.parse(sender)
                                                //     console.log(resultJsonTemplateHtml)
                                                //     setHtmlTemplate(() => {
                                                //         return {__html: resultJsonTemplateHtml.retorno}
                                                //     })
                                                //     setHtmlPopupVisible(true)
                                                //     setIndexTabPanelMain(0)
                                                //     props.setIndexMultiViewItemsGerarAcordo(0)
                                                // })
                                                // .catch((error) => {
                                                //     console.log(error)
                                                //     setMessage(error.data.error)
                                                //     setVisible(true)
                                                // })
                                            })
                                            .catch((error) => {
                                                setGerarAcordoDisabledState(false)
                                                setMessage(error.data.error)
                                                setVisible(true)
                                            })
                                        }
                                        else {
                                            setGerarAcordoDisabledState(false)
                                            setMessage("Nenhum item foi selecionado!")
                                            setVisible(true)
                                        }
                                    }
                                    else{
                                        setGerarAcordoDisabledState(false)
                                        setMessage('Título em acordo')
                                        setVisible(true)  
                                    }
                                    
                                }}
                            />

                            {gerarAcordoDisabledState &&
                                <LoadIndicator/>
                            }
                        </div>
                        <Button
                            width={130}
                            height={40}
                            text="Anterior"
                            type="default"
                            stylingMode="contained"
                            onClick={() => {
                                props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                            }}
                        />
                    </div>
                </>

            )
            :
            (
                <div className="div-boleto-form-vencimento">
                    <Button
                        width={130}
                        height={40}
                        text="Anterior"
                        type="default"
                        stylingMode="contained"
                        onClick={() => {
                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                        }}
                    />
                </div>
            )
            }
            
        </div>
                        
    )
}

export default memo(Passo3);