import { Button } from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import ConsultaBoletoForm from '../../ConsultaBoletoForm';

const Boleto = (props) => {
    return(
        <>
            <ScrollView  width='100%' height='100%' useNative={true}>
                <ConsultaBoletoForm 
                    boletoId={props.boletoState?.idvalue}
                    arquivopdf={props.boletoState?.arquivopdf}
                    email={props.gerarAcordoDataSource?.clienteEmail}
                    telefones={props.gerarAcordoDataSource?.telefones}
                    dataLookup={props.gerarAcordoDataSource?.dataLookup}
                    dataSourceBoletoState={props.gerarAcordoDataSource}
                    masterEntityId={props.gerarAcordoDataSource?.clienteId}
                    setIndexMultiViewItemsGerarBoleto={props.setIndexMultiViewItemsGerarAcordo}
                    indexMultiViewItemsGerarBoleto={props.indexMultiViewItemsGerarAcordo}
                />

                {/* <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                    <div>
                        <Button
                            width={130}
                            height={40}
                            text="Voltar"
                            type="default"
                            stylingMode="contained"
                            onClick={() => {
                                
                                props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                            }}
                        />
                    </div>
                </div> */}
            </ScrollView>
        </>
        // <div style={{height: "100%", padding: "0.5em"}}>
        //     {props.boletoState?.doc &&
        //         <embed
        //             src={props.boletoState.doc}
        //             id="displayFile"
        //             alt="your image"
        //             width="100%"
        //             height="99%"
        //             style={{ borderStyle: "solid" }}
        //             type="application/pdf"
        //         />
        //     }
        //     <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>

        //         <div>
        //             <Button
        //                 width={130}
        //                 height={40}
        //                 text="Voltar"
        //                 type="default"
        //                 stylingMode="contained"
        //                 onClick={() => {
                            
        //                     props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
        //                 }}
        //             />
        //         </div>
        //     </div>
        // </div>
    )
}

export default Boleto;