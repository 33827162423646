export const colCountFunction = (fields, colContStart) => {
    let sumColSpansLine = 0
    for(let c = 0; c <= fields.length - 1; c++){
        if(fields[c].colSpan && sumColSpansLine < colContStart){
            sumColSpansLine = sumColSpansLine + parseInt(fields[c].colSpan)
        }
        
    }
    if(sumColSpansLine < colContStart){
        return sumColSpansLine
    }
    else{
        return colContStart
    }
}