import {useState, useEffect, useRef, useCallback, memo} from "react"
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"
import Loading from "../../../CardComponents/Loading";
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { useProximaFichaDados } from '../../../Context/ProximaFichaDados';
import { applicationID, relationId } from '../../../api/ApplicationID';
import ScrollView from 'devextreme-react/scroll-view';
import { Toast } from 'devextreme-react/toast';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import Funnel, { Export } from 'devextreme-react/funnel';
import HtmlFormPopup from "../../HtmlFormPopup";
import { fecharFormAtual } from "../../../functions/fecharFormAtual";
import { useIndexTabPanelMain } from "../../../Context/IndexTabPanelMain";
import { useTabPanelItems } from "../../../Context/TabPanelItems";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    TotalItem
    } from 'devextreme-react/data-grid';
import PopupDesconto from "./PopupDesconto";
import DateBox from 'devextreme-react/date-box';

const Passo2 = (props) => {
    const getDateAddDay = (day) => {
        console.log(day)

        let stringDate = new Date()
        var dataMaisDias = new Date();
        dataMaisDias.setDate(stringDate.getDate() + Number(day))
        // let dataMaisUmDiaFinal = dataMaisDias.toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit'})
        // let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        // //junta o formato da data da segunda linha com a hora da primeira
        // let stringDateResult = newStringDate.slice(0, 10).concat(dataMaisUmDiaFinal.slice(11, 19))

        console.log(dataMaisDias)

        return(
            dataMaisDias
        )
    }
    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [gerouAcordo, setGerouAcordo] = useState(false)

    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()

    const [formaPagamentoSelecionadaState, setFormaPagamentoSelecionadaState] = useState()
    const [parcelasState, setParcelasState] = useState([])
    const [qtdParcelasSelecionadaState, setQtdParcelasSelecionadaState] = useState([])
    const [emailState, setEmailState] = useState(props.gerarAcordoDataSource?.clienteEmail ? props.gerarAcordoDataSource.clienteEmail : null)

    const [cobrancasPontuaisSelecionadasDataSouceState, setCobrancasPontuaisSelecionadasDataSouceState] = useState({
        arrayCodCobranca: [],
        total: 0
    })


    const _formRef = useRef(null)
    const _refFormParceladoEntrada = useRef(null)
    
    const _formReadOnlyValues = useRef(null)

    const [htmlTemplate, setHtmlTemplate] = useState()

    const [htmlPopupVisible, setHtmlPopupVisible] = useState(false)

    const [states, setStates] = useState({
        toastVisible: false,
        toastType: 'info',
        toastMessage: ""
    })

    const [disabledProcessarState, setDisabledProcessarState] = useState(false)
    const [toastGerarCobrancaVisible, setToastGerarCobrancaVisible] = useState(false)

    const onHidingToastGerarCobranca = () => {
        setToastGerarCobrancaVisible(false)
    }

    const [minState, setMinState] = useState()
    const [maxState, setMaxState] = useState()

    const convertDateForJsonUpdate = (date) => {
        // const dataFormatISO = new Date(date).toISOString()
        // console.log(dataFormatISO)

        if(date.slice(0, 10)){
            const dataConvertida = date.slice(0, 10)

            const dia = dataConvertida.slice(8, 10)
            const mes = dataConvertida.slice(5, 7)
            const ano = dataConvertida.slice(0, 4)
    
            return `${dia}/${mes}/${ano}`
        }
        else{
            return date
        }

        
    }

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    // const renegociacaoSimulacao = JSON.parse(sessionStorage.getItem("dataSourceBaneseJson")) ? JSON.parse(sessionStorage.getItem("dataSourceBaneseJson"))?.renegociacao : {}


    // useEffect(() => {
    //     if(props.renegociacaoSimulacaoState && !props.dataSourceAVistaState){
    //         props.setDataSourceAVistaState({
    //             valorRebate: props.renegociacaoSimulacaoState?.rebateMaximo, 
    //             valorPagamento: (props.renegociacaoSimulacaoState?.correcaoCheia - props.renegociacaoSimulacaoState?.rebateMaximo),
    //             vencimentoPagamento: new Date()
    //         })
    //     }

    // }, [props.renegociacaoSimulacaoState])



    const toastConfiguration = (visible, type, message, closeForm) => {
        console.log("entrou toastConfiguration")
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message,
            onHidden: closeForm
        })
    }


    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }


    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const entityStructureFieldsDefs = [

        {
            fieldName: "diasAtraso",
            fieldCaption: "Dias atraso",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "primeiraFatura",
            fieldCaption: "Primeira fatura",
            editorType: null,
            format: 'date',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "situacao",
            fieldCaption: "Situação",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "saldoDevedor",
            fieldCaption: "Saldo devedor",
            editorType: null,
            format: "money",
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "correcaoCheia",
            fieldCaption: "Correção cheia",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },

        {
            fieldName: "vlPrincipal",
            fieldCaption: "Valor principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "rendaEfetiva",
            fieldCaption: "Renda efetiva",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "rendaApropriar",
            fieldCaption: "Renda apropriar",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "valorPagamentoMinimo",
            fieldCaption: "Pagamento mínimo",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        },
        {
            fieldName: "rebateMaximo",
            fieldCaption: "Rebate máximo",
            editorType: null,
            format: 'money',
            visible: 1,
            showInColumnChooser: 1,
            defaultSortIndex: null,
            defaultSortOrder: null
        }
    ]

    const estruturaDataGridNegociacao = [
        {
            fieldName: "emp_fil",
            format: null,
        },
        {
            fieldName: "vl_total",
            format: "money",
        },
        {
            fieldName: "dt_vencto",
            format: null,
        },
        {
            fieldName: "dt_emissao",
            format: null,
        },
        {
            fieldName: "vl_multa",
            format: "money",
        },
        {
            fieldName: "vl_juros",
            format: "money",
        },
        {
            fieldName: "valor",
            format: "money",
        },
        {
            fieldName: "dt_rec_ped",
            format: null,
        },
        {
            fieldName: "status",
            format: null,
        },
    ]

    const convertValuesStringToNumber = (estruturura, dados) => {
        for(let c = 0; c <= estruturura.length - 1; c++){
            if(estruturura[c]?.format === "money"){
                for(let i = 0; i <= dados.length - 1; i++){
                    if(dados[i]?.[estruturura[c].fieldName]){
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName])
                    }
                }
            }
            
        }
    }


    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel = () => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const [popupDescontoVisibleState, setPopupDescontoVisibleState] = useState(false)

    const [valorComDescontoState, setValorComDescontoState] = useState(0)
    // const [valorSemDescontoState, setValorSemDescontoState] = useState(0)


    const convertBinaryForPDF = (boleto) => {
        if (boleto != null) {
            var contentType = "application/pdf";
        
            props.setBoletoState({
                doc: "data:" + contentType + ";base64," + boleto
            });
            } else {
            props.setBoletoState({
                doc: ""
            });
        }
    }

    const httpRequestBanese = (method, url, bodyRequest) =>{
        const isJson = (data) => {
            try {
                JSON.parse(data);
            } catch (e) {
                return false;
            }
        
            return true;
        }
    
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url);        
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("token", "617C6FD67BB04EB78FCACF0EFF619109");//token temporario banese
           
            xhr.onload = function () {

            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            }
            else if(this.status > 400) {
                reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
                });
            }
            };
            xhr.onerror = function () {
            reject({
                status: this.status
                ,statusText: xhr.statusText
                ,data: isJson(this.response) ? JSON.parse(this.response) : this.response
            });
            };
            xhr.send(JSON.stringify(bodyRequest));
        })
    }

    const getRenegociacaoSimulacaoParcelamento = () => {
        console.log(props)
    
        showLoadPanel()

         if(props.tipoPagamentoSelecionadoIdState === 1){
            const vencimentoEntradaMin = new Date(new Date().getTime()).toISOString().slice(0, 10)
            const vencimentoEntradaMax = new Date(getDateAddDay(10).getTime()).toISOString().slice(0, 10) 
            const vencimentoEntradaAtual = new Date(props.dataSourceParceladoEntradaState?.vencimentoEntrada.getTime()).toISOString().slice(0, 10) 
            if(vencimentoEntradaMin <= vencimentoEntradaAtual && vencimentoEntradaAtual <= vencimentoEntradaMax){
                const vencimento1aPrestacaoMin = new Date(getDateAddDay(11).getTime()).toISOString().slice(0, 10) 
                const vencimento1aPrestacaoMax = new Date(getDateAddDay(50).getTime()).toISOString().slice(0, 10) 
                const vencimento1aPrestacaoAtual = new Date(props.dataSourceParceladoEntradaState?.vencimento1aPrestacao.getTime()).toISOString().slice(0, 10)
                if(vencimento1aPrestacaoMin <= vencimento1aPrestacaoAtual && vencimento1aPrestacaoAtual <= vencimento1aPrestacaoMax){
                    httpRequestBanese('POST', props.requestsBaneseState.renegociacaoSimulacaoParcelamento, {
                        "relationId": relationId,
                        "rebate": props.dataSourceParceladoEntradaState?.valorRebate,
                        "tipoPagamento": 1,
                        "entrada": props.dataSourceParceladoEntradaState?.valorEntrada,
                        "taxaJuros": props.dataSourceParceladoEntradaState?.taxaJuros,
                        "vencimentoParcela": convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                        "vencimentoEntrada": convertDateForJsonUpdate(vencimentoEntradaAtual),
                        "qtdParcelas": props.dataSourceParceladoEntradaState?.quantidadePrestacoes,
                        "tituloId": props.tituloId
                    })
                    .then((sender) => {
                        console.log("entrou then da request banese")
                        const resultreNegociacaoSimulacaoParcelamentoJson = JSON.parse(sender)
                        props.setRenegociacaoConfirmacaoJsonState({
                            rebate: props.dataSourceParceladoEntradaState?.valorRebate,
                            entrada: props.dataSourceParceladoEntradaState?.valorEntrada,
                            tipoPagamento: props.tipoPagamentoSelecionadoIdState,
                            taxaJuros: props.dataSourceParceladoEntradaState?.taxaJuros,
                            vencimentoParcela: convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                            vencimentoEntrada: convertDateForJsonUpdate(vencimentoEntradaAtual),
                            qtdParcelas: props.dataSourceParceladoEntradaState?.quantidadePrestacoes,
                            conta: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.Conta,
                            debitoAutomatico: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.DebitoAutomatico
                        })
                        
                        props.setCondicaoRenegociacaoDataSourceState(resultreNegociacaoSimulacaoParcelamentoJson)
                        props.setDataSourceParceladoEntradaState({
                            ...props.dataSourceParceladoEntradaState,
                            iof: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.IOF.slice(3).replace(".", "").replace(",", ".")),
                            iofAdicional: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.IOFAdicional.slice(3).replace(".", "").replace(",", ".")),
                            valorPrestacao: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.ValorParcela.slice(3).replace(".", "").replace(",", ".")),
                            valorRebate: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.VALORDOREBATE.slice(3).replace(".", "").replace(",", ".")),
                            valorEntrada: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.VALORDAENTRADA.slice(3).replace(".", "").replace(",", ".")),
                            taxaJuros: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.TAXADEJUROS.replace(",", ".").replace("%", "")),
                        })
                        // props.dataSourceParceladoEntradaState["iof"] = resultreNegociacaoSimulacaoParcelamentoJson?.IOF
                        // props.dataSourceParceladoEntradaState["iofAdicional"] = resultreNegociacaoSimulacaoParcelamentoJson?.IOFAdicional
                        // props.dataSourceParceladoEntradaState["valorPrestacao"] = resultreNegociacaoSimulacaoParcelamentoJson?.ValorParcela
                        props.setModeEdit(false)

                        hideLoadPanel()
                    
                        console.log(resultreNegociacaoSimulacaoParcelamentoJson)
                    })
                    .catch((error) => {
                        // console.log("entrou erro request banese")
                        // console.log(error)
                        hideLoadPanel()
                        if(error?.data?.error){
                            setMessage(error.data.error)
                            setVisible(true)
                        }
                        else{
                            setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                            setVisible(true)
                        }
                    })
                }
                else{
                    hideLoadPanel()
                    setMessage(`Vencimento 1a Prestação tem que estar entre ${convertDateForJsonUpdate(vencimento1aPrestacaoMin)} e ${convertDateForJsonUpdate(vencimento1aPrestacaoMax)}`)
                    setVisible(true)
                }
            }
            else{
                hideLoadPanel()
                setMessage(`Vencimento Entrada tem que estar entre ${convertDateForJsonUpdate(vencimentoEntradaMin)} e ${convertDateForJsonUpdate(vencimentoEntradaMax)}`)
                setVisible(true)
            }
        }
        else if(props.tipoPagamentoSelecionadoIdState === 2){
            const vencimento1aPrestacaoMin = new Date(getDateAddDay(11).getTime()).toISOString().slice(0, 10) 
            const vencimento1aPrestacaoMax = new Date(getDateAddDay(50).getTime()).toISOString().slice(0, 10) 
            const vencimento1aPrestacaoAtual = new Date(props.dataSourceParceladoState?.vencimento1aPrestacao.getTime()).toISOString().slice(0, 10)
            if(Date.parse(vencimento1aPrestacaoMin) <= Date.parse(vencimento1aPrestacaoAtual) && Date.parse(vencimento1aPrestacaoAtual) <= Date.parse(vencimento1aPrestacaoMax)){
                httpRequestBanese('POST', props.requestsBaneseState.renegociacaoSimulacaoParcelamento, {
                    "relationId": relationId,
                    "rebate": props.dataSourceParceladoState?.valorRebate,
                    "tipoPagamento": 2,
                    "entrada": 0,
                    "taxaJuros": props.dataSourceParceladoState?.taxaJuros,
                    "vencimentoParcela": convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                    "vencimentoEntrada": convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                    "qtdParcelas": props.dataSourceParceladoState?.quantidadePrestacoes,
                    "tituloId": props.tituloId
                })
                .then((sender) => {
                    console.log("entrou then da request banese")
                    const resultreNegociacaoSimulacaoParcelamentoJson = JSON.parse(sender)

                    props.setRenegociacaoConfirmacaoJsonState({
                        rebate: props.dataSourceParceladoState?.valorRebate,
                        entrada: props.dataSourceParceladoState?.valorEntrada,
                        tipoPagamento: props.tipoPagamentoSelecionadoIdState,
                        taxaJuros: props.dataSourceParceladoState?.taxaJuros,
                        vencimentoParcela: convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                        vencimentoEntrada: convertDateForJsonUpdate(vencimento1aPrestacaoAtual),
                        qtdParcelas: props.dataSourceParceladoState?.quantidadePrestacoes,
                        conta: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.Conta,
                        debitoAutomatico: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.DebitoAutomatico
                    })
                    props.setCondicaoRenegociacaoDataSourceState(resultreNegociacaoSimulacaoParcelamentoJson)
                   
                    props.setDataSourceParceladoState({
                        ...props.dataSourceParceladoState,
                        iof: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.IOF.slice(3).replace(".", "").replace(",", ".")),
                        iofAdicional: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.IOFAdicional.slice(3).replace(".", "").replace(",", ".")),
                        valorPrestacao: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.ValorParcela.slice(3).replace(".", "").replace(",", ".")),
                        valorRebate: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.VALORDOREBATE.slice(3).replace(".", "").replace(",", ".")),
                        taxaJuros: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.TAXADEJUROS.replace(",", ".").replace("%", "")),
                    })
                    props.setModeEdit(false)
                    hideLoadPanel()
                })
                .catch((error) => {
                    // console.log("entrou erro request banese")
                    // console.log(error)
                    hideLoadPanel()
                    if(error?.data?.error){
                        setMessage(error.data.error)
                        setVisible(true)
                    }
                    else{
                        setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                        setVisible(true)
                    }
                })
            }
            else{
                hideLoadPanel()
                setMessage(`Vencimento 1a Prestação tem que estar entre ${convertDateForJsonUpdate(vencimento1aPrestacaoMin)} e ${convertDateForJsonUpdate(vencimento1aPrestacaoMax)}`)
                setVisible(true)
            }
            
        }
        else{
            const vencimentoPagamento = new Date(props.dataSourceAVistaState.vencimentoPagamento.getTime()).toISOString().slice(0, 10)

            httpRequestBanese('POST', props.requestsBaneseState.renegociacaoSimulacaoParcelamento, {
                "relationId": relationId,
                "rebate": props.dataSourceAVistaState?.valorRebate,
                "tipoPagamento": 3,
                "entrada": props.dataSourceAVistaState?.valorPagamento,
                "taxaJuros": 0,
                "vencimentoParcela": convertDateForJsonUpdate(vencimentoPagamento),
                "vencimentoEntrada": convertDateForJsonUpdate(vencimentoPagamento),
                "qtdParcelas": 0,
                "tituloId": props.tituloId
            })
            .then((sender) => {
                console.log("entrou then da request banese")
                const resultreNegociacaoSimulacaoParcelamentoJson = JSON.parse(sender)
                props.setRenegociacaoConfirmacaoJsonState({
                    rebate: props.dataSourceAVistaState?.valorRebate,
                    entrada: props.dataSourceAVistaState?.valorPagamento,
                    tipoPagamento: props.tipoPagamentoSelecionadoIdState,
                    taxaJuros: 0,
                    vencimentoParcela: convertDateForJsonUpdate(vencimentoPagamento),
                    vencimentoEntrada: convertDateForJsonUpdate(vencimentoPagamento),
                    qtdParcelas: 0,
                    conta: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.Conta,
                    debitoAutomatico: resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.DebitoAutomatico
                })
                props.setCondicaoRenegociacaoDataSourceState(resultreNegociacaoSimulacaoParcelamentoJson)
               
                props.setDataSourceAVistaState({
                    ...props.dataSourceAVistaState,
                    valorRebate: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.VALORDOREBATE.slice(3).replace(".", "").replace(",", ".")), 
                    valorPagamento: parseFloat(resultreNegociacaoSimulacaoParcelamentoJson?.[0]?.VALORDOPAGAMENTO.slice(3).replace(".", "").replace(",", ".")),
                })
                
                props.setModeEdit(false)
                hideLoadPanel()
            })
            .catch((error) => {
                // console.log("entrou erro request banese")
                // console.log(error)
                hideLoadPanel()
                if(error?.data?.error){
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else{
                    setMessage("Ocorreu um erro. Tente novamente mais tarde.")
                    setVisible(true)
                }
            })
        }
    }

    const [tipoPagamentoValue, setTipoPagamentoValue] = useState(3)


    return(
        <div style={{display: "flex", flexDirection: "column", width: "100%", padding: "0.5em"}}
        >
            {/* <ScrollView> */}
            <Popup
                visible={visible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {message &&
                    <p>{message}</p>
                }
            </Popup>

            <PopupDesconto
                popupDescontoVisibleState={popupDescontoVisibleState}
                setPopupDescontoVisibleState={setPopupDescontoVisibleState}
                descontoMaximo={props.gerarAcordoDataSource?.campanhaDesconto}
                valorSemDescontoState={cobrancasPontuaisSelecionadasDataSouceState.total}
                valorComDescontoState={valorComDescontoState}
                setValorComDescontoState={setValorComDescontoState}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />


            <HtmlFormPopup
                setHtmlPopupVisible={setHtmlPopupVisible}
                htmlPopupVisible={htmlPopupVisible}
                htmlTemplate={htmlTemplate}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
                onHidden={() => {
                    if(states.onHidden){
                        fecharFormAtual(tabPanelItems, indexTabPanelMain, setIndexTabPanelMain, props.hideUpfPopupForm)
                        setStates({...states, 
                            onHidden: false
                        })
                    }
                }}
            />

            <Toast
                visible={toastGerarCobrancaVisible}
                message="Cobrança gerada com sucesso!"
                type="success"
                onHiding={onHidingToastGerarCobranca}
                displayTime={1800}
                height={60}
            />

           
            <h4>Renegociação</h4>
            <Form 
                ref={_formReadOnlyValues}
                colCount={3}
                // formData={props?.renegociacaoSimulacaoState ? props.renegociacaoSimulacaoState : {}}
                formData={props?.renegociacaoSimulacaoState}
                // onFieldDataChanged={(e) => {
                //     if(!props.modeEditForm){
                //         props.modeEditOn()
                //     }
                // }}
                // labelLocation="top"
                showColonAfterLabel={true}
                // minColWidth={200}
                alignItemLabelsInAllGroups={true}
                alignItemLabels={true}
                // onInitialized={(e) => {
                //     console.log(e)
                //     e.component.option("value", Boolean(e.component.option("value")))
                // }}
            >
                <GroupItem 
                    // visible={}
                    // colCount={props.groupItemColSpan}
                    // colSpan={props.groupItemColCount}
                    colCount={3}
                    colSpan={3}
                    // cssClass={'group-item'}
                    // colCountByScreen={true}
                    cssClass={'group-item'}
                    itemType={'group'}
                    showClearButton={true}
                    icon={'folder'}
                >
                    {entityStructureFieldsDefs.map((item) => {
                        if(item.editorType === 'dxDateBox'){
                            return(
                                <SimpleItem
                                    colSpan={1}
                                    dataField={item.fieldName}
                                    caption={item.fieldCaption}
                                    dataType='date'
                                    visible={item.visible}
                                    defaultSortIndex={item.defaultSortIndex}
                                    defaultSortOrder={item.defaultSortOrder}
                                    allowGrouping={item?.allowGrouping}
                                    groupIndex={item?.groupIndex}
                                    editorOptions={{
                                        readOnly: true
                                    }}
                                >
                                    <Label text={item.fieldCaption}/>
                                </SimpleItem>
                            )
                        }
                        else if(item.editorType === 'dxCheckBox'){
                            return(
                                <SimpleItem
                                    colSpan={1}
                                    dataField={item.fieldName}
                                    caption={item.fieldCaption}
                                    dataType='boolean'
                                    visible={item.visible}
                                    defaultSortIndex={item.defaultSortIndex}
                                    defaultSortOrder={item.defaultSortOrder}
                                    allowGrouping={item?.allowGrouping}
                                    groupIndex={item?.groupIndex}
                                    editorOptions={{
                                        readOnly: true
                                    }}
                                >
                                    <Label text={item.fieldCaption}/>
                                </SimpleItem>
                            )
                        }
                        else if(item.format === 'money') {
                            return(
                                <SimpleItem
                                    colSpan={1}
                                    dataField={item.fieldName}
                                    caption={item.fieldCaption}
                                    visible={item.visible}
                                    //"R$ #,##0.##;(R$ #,##0.##)"
                                    defaultSortIndex={item.defaultSortIndex}
                                    defaultSortOrder={item.defaultSortOrder}
                                    allowGrouping={item?.allowGrouping}
                                    groupIndex={item?.groupIndex}
                                    editorOptions={{
                                        readOnly: true,
                                        format: "R$ #,##0.00"
                                    }}
                                >
                                    <Label text={item.fieldCaption}/>
                                </SimpleItem>
                            )
                        }
                        else if (item.format === 'percent') {
                            return (
                                <SimpleItem
                                    colSpan={1}
                                    dataField={item.fieldName}
                                    caption={item.fieldCaption}
                                    visible={item.visible}
                                    showInColumnChooser={item.showInColumnChooser}
                                    format="#0'%'"
                                    defaultSortIndex={item.defaultSortIndex}
                                    defaultSortOrder={item.defaultSortOrder}
                                    allowGrouping={item?.allowGrouping}
                                    groupIndex={item?.groupIndex}
                                    editorOptions={{
                                        readOnly: true
                                    }}
                                >
                                    <Label text={item.fieldCaption}/>
                                </SimpleItem>
                            )
                        }
                        else {
                            return(
                                <SimpleItem
                                    colSpan={1}
                                    dataField={item.fieldName}
                                    caption={item.fieldCaption}
                                    visible={item.visible}
                                    showInColumnChooser={item.showInColumnChooser}
                                    defaultSortIndex={item.defaultSortIndex}
                                    defaultSortOrder={item.defaultSortOrder}
                                    allowGrouping={item?.allowGrouping}
                                    groupIndex={item?.groupIndex}
                                    editorOptions={{
                                        readOnly: true
                                    }}
                                >
                                    <Label text={item.fieldCaption}/>
                                </SimpleItem>
                            )
                        }
                        
                    })}
                </GroupItem>
            </Form>

            
            

            <div style={{marginBottom: "1em"}}>
                <Form
                    colCount={4}
                    ref={_formRef}
                    // formData={{
                    //     tipoPagamento: 3,
                    // }}
                >
                    <GroupItem 
                        // visible={}
                        // colCount={props.groupItemColSpan}
                        // colSpan={props.groupItemColCount}
                        colCount={4}
                        colSpan={4}
                        // cssClass={'group-item'}
                        // colCountByScreen={true}
                        cssClass={'group-item'}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >
                        <GroupItem
                            colSpan={2}
                            colCount={2}
                        >
                            <SimpleItem
                                colSpan={2}
                                editorType="dxSelectBox" 
                                dataField="tipoPagamento"
                                editorOptions=
                                {{ 
                                    onSelectionChanged: (e) => {
                                        if(e?.selectedItem?.id !== props.tipoPagamentoSelecionadoIdState){
                                            console.log(props.tipoPagamentoSelecionadoIdState)
                                            console.log(e?.selectedItem?.id)
                                            props.setTipoPagamentoSelecionadoIdState(e.selectedItem.id)
                                            props.setModeEdit(true)

                                            if(e?.selectedItem?.id === 1){
                                                //Parcelado c/ Entrada
                                                props.setDataSourceParceladoEntradaState({
                                                    valorRebate: props.renegociacaoSimulacaoState?.rebateMaximo,
                                                    valorEntrada: (props.renegociacaoSimulacaoState?.correcaoCheia - props.renegociacaoSimulacaoState?.rebateMaximo) / 10,
                                                    vencimentoEntrada: new Date(),
                                                    taxaJuros: 4.5,
                                                    iof: 0,
                                                    iofAdicional: 0,
                                                    vencimento1aPrestacao: getDateAddDay(11),
                                                    quantidadePrestacoes: 10,
                                                    valorPrestacao: (props.renegociacaoSimulacaoState?.correcaoCheia - props.renegociacaoSimulacaoState?.rebateMaximo) / 10
                                                })
                                            }
                                
                                            if(e?.selectedItem?.id === 2){
                                                //Parcelado s/ Entrada
                                                props.setDataSourceParceladoState({
                                                    valorRebate: props.renegociacaoSimulacaoState?.rebateMaximo,
                                                    taxaJuros: 4.5,
                                                    iof: 0,
                                                    iofAdicional: 0,
                                                    vencimento1aPrestacao: getDateAddDay(11),
                                                    quantidadePrestacoes: 10,
                                                    valorPrestacao: (props.renegociacaoSimulacaoState?.correcaoCheia - props.renegociacaoSimulacaoState?.rebateMaximo) / 10
                                                })
                                            }
                                
                                            if(e?.selectedItem?.id === 3){
                                                //À Vista
                                                props.setDataSourceAVistaState({
                                                    valorRebate: props.renegociacaoSimulacaoState?.rebateMaximo, 
                                                    valorPagamento: (props.renegociacaoSimulacaoState?.correcaoCheia - props.renegociacaoSimulacaoState?.rebateMaximo),
                                                    vencimentoPagamento: new Date()
                                                })
                                            }

                                        }
                                    },
                                    items: [{id: 1, descricao: "Parcelado c/ Entrada"}, {id: 2, descricao: "Parcelado s/ Entrada"}, {id: 3, descricao: "À Vista"}],
                                    valueExpr: "id",
                                    displayExpr: "descricao",
                                    value: props.tipoPagamentoSelecionadoIdState
                                    // onInitialized: () => {
                                    //     setSelectBoxValue(entityStructure[0].queryFormProperties[0].defaultSearchField)
                                    // }
                                }}
                            >
                                <Label 
                                    text="Tipo do Pagamento"
                                />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        ></GroupItem>
                        <GroupItem
                            colSpan={1}
                            colCount={1}
                        >
                            <ButtonItem
                                buttonOptions={{
                                    // icon: 'find'
                                    text: 'Atualizar cálculo'
                                    , type: 'default'
                                    , onClick: getRenegociacaoSimulacaoParcelamento
                                    // , stylingMode: 'default'
                                    // refreshButtonOptions: false,
                                    // , useSubmitBehavior: true
                                }}
                            />
                        </GroupItem>
                    </GroupItem>
                </Form>
            </div>
            

            {props.tipoPagamentoSelecionadoIdState === 1 &&
                 <Form
                    ref={_refFormParceladoEntrada}
                    colCount={3}
                    formData={props.dataSourceParceladoEntradaState}
                    onFieldDataChanged={(e) => {
                        if(!props.modeEdit){
                            props.setModeEdit(true)
                        }
                    }}
                >
                    <GroupItem 
                        colCount={3}
                        colSpan={3}
                        cssClass={'group-item'}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >
                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorRebate"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor do Rebate"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorEntrada"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor da Entrada"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            dataField="vencimentoEntrada"
                            editorType="dxDateBox"
                            editorOptions={{
                                min: new Date(),
                                // max: getDateAddDay(10),
                                // max: new Date.setDate(new Date.getDate() + (10)),
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label text="Vencimento Entrada"/>
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="taxaJuros"
                            editorOptions=
                            {{ 
                                format: "#0.##'%'",
                                valueChangeEvent: ["keyup", "paste"],
                                min: 1.7,
                                max: 6.5
                            }}
                        >
                            <Label 
                                text="Taxa de Juros"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="iof"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="IOF"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="iofAdicional"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="IOF Adicional"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            dataField="vencimento1aPrestacao"
                            editorType="dxDateBox"
                            editorOptions={{
                                // min: getDateAddDay(11),
                                // max: getDateAddDay(50),
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label text="Vencimento 1a Prestação"/>
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="quantidadePrestacoes"
                            editorOptions=
                            {{ 
                                max: 36,
                                min: 1,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Quantidade de Prestações"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorPrestacao"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor da Prestação"
                            />
                        </SimpleItem>

                        {/* <ButtonItem
                            buttonOptions={{
                                // icon: 'find'
                                text: 'Atualizar cálculo'
                                , type: 'default'
                                , onClick: getRenegociacaoSimulacaoParcelamento
                                // , stylingMode: 'default'
                                // refreshButtonOptions: false,
                                // , useSubmitBehavior: true
                            }}
                        /> */}
                    </GroupItem>
                </Form>

            }

            {props.tipoPagamentoSelecionadoIdState === 2 &&

                <Form
                    colCount={3}
                    formData={props.dataSourceParceladoState}
                    onFieldDataChanged={(e) => {
                        if(!props.modeEdit){
                            props.setModeEdit(true)
                        }
                    }}
                >
                    <GroupItem 
                        colCount={3}
                        colSpan={3}
                        cssClass={'group-item'}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >
                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorRebate"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor do Rebate"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="taxaJuros"
                            editorOptions=
                            {{ 
                                format: "#0.##'%'",
                                valueChangeEvent: ["keyup", "paste"],
                                min: 1.7,
                                max: 6.5
                            }}
                        >
                            <Label 
                                text="Taxa de Juros"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="iof"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="IOF"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="iofAdicional"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="IOF Adicional"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            dataField="vencimento1aPrestacao"
                            editorType="dxDateBox"
                            editorOptions={{
                                // min: getDateAddDay(11),
                                // max: getDateAddDay(50),
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label text="Vencimento 1a Prestação"/>
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="quantidadePrestacoes"
                            editorOptions=
                            {{ 
                                max: 36,
                                min: 1,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Quantidade de Prestações"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorPrestacao"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                readOnly: true,
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor da Prestação"
                            />
                        </SimpleItem>

                        {/* <ButtonItem
                            buttonOptions={{
                                // icon: 'find'
                                text: 'Atualizar cálculo'
                                , type: 'default'
                                , onClick: getRenegociacaoSimulacaoParcelamento
                                // , stylingMode: 'default'
                                // refreshButtonOptions: false,
                                // , useSubmitBehavior: true
                            }}
                        /> */}
                    </GroupItem>
                </Form>
            }

            {props.tipoPagamentoSelecionadoIdState === 3 &&
                <>

                <Form
                    colCount={3}
                    formData={props.dataSourceAVistaState}
                    onFieldDataChanged={(e) => {
                        if(!props.modeEdit){
                            props.setModeEdit(true)
                        }
                    }}
                >
                    <GroupItem 
                        colCount={3}
                        colSpan={3}
                        cssClass={'group-item'}
                        itemType={'group'}
                        showClearButton={true}
                        icon={'folder'}
                    >
                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorRebate"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                onChange: (e) => {
                                    props.setModeEdit(true)
                                    console.log(e)
                                    props.setDataSourceAVistaState({
                                        ...props.dataSourceAVistaState,
                                        valorPagamento: props.renegociacaoSimulacaoState?.correcaoCheia - props.dataSourceAVistaState.valorRebate
                                    })
                                },
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor do Rebate"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxNumberBox"
                            dataField="valorPagamento"
                            editorOptions=
                            {{ 
                                format: "R$ #,##0.00",
                                // readOnly: true
                                onChange: (e) => {
                                    props.setModeEdit(true)
                                    props.setDataSourceAVistaState({
                                        ...props.dataSourceAVistaState,
                                        valorRebate: props.renegociacaoSimulacaoState?.correcaoCheia - props.dataSourceAVistaState.valorPagamento
                                    })
                                },
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Valor do Pagamento"
                            />
                        </SimpleItem>

                        <SimpleItem
                            colSpan={1}
                            editorType="dxDateBox"
                            dataField="vencimentoPagamento"
                            editorOptions=
                            {{ 
                                min: new Date(),
                                // max: getDateAddDay(10),
                                // max: new Date.setDate(new Date.getDate() + (10)),
                                // onChange: (e) => {
                                //     props.setModeEdit(true)
                                //     if(props.renegociacaoSimulacaoState?.correcaoCheia && props.dataSourceParceladoEntradaState?.valorRebate){
                                //         props.dataSourceParceladoEntradaState["valorRebate"] = e.value
                                //         props.dataSourceParceladoEntradaState["valorPagamento"] = props.renegociacaoSimulacaoState?.correcaoCheia - e.value
                                //         props.setValorDividaRenegociada(props.renegociacaoSimulacaoState?.correcaoCheia - e.value)
                                //         console.log(e.value)
                                //     }
                                // }
                                valueChangeEvent: ["keyup", "paste"]
                            }}
                        >
                            <Label 
                                text="Vencimento Pagamento"
                            />
                        </SimpleItem>

                        {/* <ButtonItem
                            buttonOptions={{
                                // icon: 'find'
                                text: 'Atualizar cálculo'
                                , type: 'default'
                                , onClick: getRenegociacaoSimulacaoParcelamento
                                // , stylingMode: 'default'
                                // refreshButtonOptions: false,
                                // , useSubmitBehavior: true
                            }}
                        /> */}
                    </GroupItem>
                </Form>
                    {/* <div className="dx-field">
                        <div className="dx-field-label">
                            Valor do Rebate:
                        </div>
                            
                        <div className="dx-field-value">
                            <NumberBox
                                value={props.dataSourceAVistaState?.valorRebate}
                                format={"R$ #,##0.00"}
                                onChange={(e) => {
                                props.setModeEdit(true)
                                    console.log(e)
                                    if(props.renegociacaoSimulacaoState?.correcaoCheia && props.dataSourceAVistaState?.valorRebate){
                                        
                                        props.dataSourceAVistaState["valorRebate"] = e.value
                                        props.dataSourceAVistaState["valorPagamento"] = props.renegociacaoSimulacaoState?.correcaoCheia - e.value
                                        props.setValorDividaRenegociada(props.renegociacaoSimulacaoState?.correcaoCheia - e.value)
                                        console.log(e.value)
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">
                            Valor do Pagamento:
                        </div>
                            
                        <div className="dx-field-value">
                            <NumberBox
                                value={props.dataSourceAVistaState?.valorPagamento}
                                readOnly={true}
                                format={"R$ #,##0.00"}
                                onChange={(e) => {
                                props.setModeEdit(true)
                                    console.log(e)
                                    if(props.renegociacaoSimulacaoState?.correcaoCheia && props.dataSourceAVistaState?.valorPagamento){
                                        console.log("entrou aqui no if do campo q ta dando err")
                                        props.setDataSourceAVistaState({
                                            ...props.dataSourceAVistaState,
                                            valorPagamento: e.value
                                        })
                                        console.log(props.dataSourceAVistaState)
                                        console.log(props.renegociacaoSimulacaoState?.correcaoCheia)
                                        
                                        console.log(e.value)
                                    }
                                    else{
                                        console.log("entrou aqui no else")
                                        props.dataSourceAVistaState["valorPagamento"] = props?.renegociacaoSimulacaoState?.[0]?.correcaoCheia

                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">
                            Vencimento Pagamento:
                        </div>
                            
                        <div className="dx-field-value">
                            <DateBox
                                // value={new Date()}
                                //vencimento maximo +10 dias
                                dateSerializationFormat={"dd-MM-yyyy"}
                                // displayFormat="dd.MM.yyyy"
                                min={new Date()}
                                max={getDateAddDay(10)}
                                value={props.dataSourceAVistaState?.vencimentoPagamento} 
                                onChange={(e) => {
                                props.setModeEdit(true)
                                    if(props.renegociacaoSimulacaoState?.correcaoCheia && props.dataSourceAVistaState?.vencimentoPagamento){
                                        props.setDataSourceAVistaState({
                                            ...props.dataSourceAVistaState,
                                            vencimentoPagamento: e.value
                                        })
                                        console.log(e.value)
                                    }
                                }}
                            />
                        </div>
                    </div> */}
                </>
            }


            <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "3em"}}>
                <div style={{marginLeft: "3em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Button
                        width={180}
                        height={40}
                        text="Processar"
                        type="default"
                        stylingMode="contained"
                        // disabled={cobrancasPontuaisSelecionadasDataSouceState?.arrayCodCobranca?.length <= 0 || disabledProcessarState}
                        onClick={() => {
                            if(props.modeEdit){
                                setMessage("É necessário atualizar cálculo para continuar")
                                setVisible(true)
                            }
                            else{
                                props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                            }
                        }}
                    />
                </div>

                <div>
                    <Button
                        width={130}
                        height={40}
                        text="Anterior"
                        type="default"
                        stylingMode="contained"
                        onClick={() => {
                            props.setArrayPendenciasProcessadasState("")
                            // props.setGerarAcordoDataSource(null)
                            //pegar todos os dados e enviar para a API 
                            //...
                            //ir para o proximo item
                            
                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo - 1)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(Passo2);