// import { Container } from './styles';
import { useEffect, useCallback, memo, useState } from 'react';
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useTabPanelItems } from '../../../Context/TabPanelItems';
import Toolbar, {Item} from 'devextreme-react/toolbar';
import MultiView from 'devextreme-react/multi-view';
import ScrollView from 'devextreme-react/scroll-view';
import { useTelecobIndexTabPanelGlobalState } from '../../../Context/TelecobIndexTabPanelGlobalState';
import { useTelecobTabPanelItemsGlobalState } from '../../../Context/TelecobTabPanelItemsGlobalState';
import "./style.css"

const MultiVIewMainTeleCob = (props) => {
    // MASTER DO MASTER
    const {telecobTabPanelItemsGlobalState} = useTelecobTabPanelItemsGlobalState()
    const {telecobIndexTabPanelGlobalState} = useTelecobIndexTabPanelGlobalState()

    // MASTER
    const {tabPanelItems, setTabPanelItems} = useTabPanelItems();
    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain();

    // LOCAL
    const [telecobMultiViewAtualItemsState, setTelecobMultiViewAtualItemsState] = useState([]);
    const [indexTelecobMultiViewAtualItemsState, setIndexTelecobMultiViewAtualItemsState] = useState(0);
    // const [editState, setEditState] = useState(false);
    const enviarSelecionado = () => {
        if(props.idValue && props.dataSource) {
            if(props.abrirFicha){
                props.abrirFicha({
                    tituloId: props.idValue,
                    credorId: props.dataSource?._credorid,
                    _clienteId: props.dataSource?._clienteid,
                    nomeFilaAtual: null,
                    nomeRegraAtual: null,
                    _qtdoutroscontratoscliente: props.dataSource?._qtdoutroscontratoscliente,
                    _qtdoutroscontratosclientecredoratual: props.dataSource?._qtdoutroscontratosclientecredoratual
                })

            }
        }
    }

    useEffect(() => {
        enviarSelecionado()
    }, [props.idValue, props.dataSource])
    
    useEffect(() => {
        const idTabSelecionada = parseInt(sessionStorage.getItem("idTabSelecionada"))
        console.log(idTabSelecionada)
        console.log(props.idTab)
        if(idTabSelecionada === props.idTab){
            console.log(tabPanelItems)
            if(!tabPanelItems[indexTabPanelMain]?.tipoFormularioTelecob){
                const tabPanelItemsTemp = tabPanelItems
                for(let c = 0; c <= tabPanelItemsTemp.length - 1; c++){
                    if((tabPanelItemsTemp[c]?.tipoFormularioTelecob === "queryForm") || (tabPanelItemsTemp[c]?.tipoFormularioTelecob === "recordForm")){
                        console.log("deletando...")
                        console.log(tabPanelItemsTemp[c])
                        tabPanelItemsTemp.splice(c, 1)
                    }
        
                }
                console.log(tabPanelItemsTemp)
                setTabPanelItems(tabPanelItemsTemp)
            }
        }
    }, [indexTabPanelMain])

    useEffect(() => {
        console.log(telecobMultiViewAtualItemsState)
        if(!telecobMultiViewAtualItemsState[indexTelecobMultiViewAtualItemsState]?.tipoFormularioTelecob){
            const tabPanelItemsTemp = telecobMultiViewAtualItemsState
            for(let c = 0; c <= tabPanelItemsTemp.length - 1; c++){
                if((tabPanelItemsTemp[c]?.tipoFormularioTelecob === "queryForm") || (tabPanelItemsTemp[c]?.tipoFormularioTelecob === "recordForm")){
                    console.log("deletando...")
                    console.log(tabPanelItemsTemp[c])
                    tabPanelItemsTemp.splice(c, 1)
                }
    
            }
            console.log(tabPanelItemsTemp)
            setTelecobMultiViewAtualItemsState(tabPanelItemsTemp)
        }
    }, [indexTelecobMultiViewAtualItemsState])

    useEffect(() => {
        try {
            const idTabSelecionada = parseInt(sessionStorage.getItem("idTabSelecionada"))
            console.log(idTabSelecionada)
            console.log(props.idTab)
            console.log(telecobMultiViewAtualItemsState)
            if(idTabSelecionada === props.idTab){
                if(telecobMultiViewAtualItemsState.length > 0){
                    // setEditState(false)
                    setTabPanelItems(telecobMultiViewAtualItemsState)
                    setIndexTabPanelMain(indexTelecobMultiViewAtualItemsState)
                }
                else{
                    setTelecobMultiViewAtualItemsState(tabPanelItems)
                    setIndexTelecobMultiViewAtualItemsState(indexTabPanelMain)
                }
            }
        }
        catch{
            console.log(`erro no telecobIndexTabPanelGlobalState`)
        }
    }, [telecobIndexTabPanelGlobalState])

    useEffect(() => {
        
        try {
            const idTabSelecionada = parseInt(sessionStorage.getItem("idTabSelecionada"))
            console.log(idTabSelecionada)
            console.log(props.idTab)
            // console.log(editState)
            if(idTabSelecionada === props.idTab){
                // if(editState){
                    setTelecobMultiViewAtualItemsState(tabPanelItems)
                    setIndexTelecobMultiViewAtualItemsState(indexTabPanelMain)
                // }
                // else{
                //     setEditState(true)
                // }
            }
        }
        catch{
            console.log(`erro no telecobTabPanelItemsGlobalState`)
        }
    }, [tabPanelItems, indexTabPanelMain])

    // useEffect(() => {
        
    //     try {
    //         const idTabSelecionada = parseInt(sessionStorage.getItem("idTabSelecionada"))
    //         console.log(idTabSelecionada)
    //         console.log(props.idTab)
    //         if(idTabSelecionada === props.idTab){
    //             setEditState(false)
    //         }
    //     }
    //     catch{
    //         console.log(`erro no telecobTabPanelItemsGlobalState`)
    //     }
    // }, [telecobTabPanelItemsGlobalState])

    const contentTab = useCallback((sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    },[])
    // const optionChange = useCallback((sender)=>{
    //     if(sender.name === "selectedIndex"){
    //         // setIndexTabPanelMain(sender.value)
    //     }
    // }, [setIndexTabPanelMain])

    return(
            <div className="multiview-main">
                    <MultiView
                        // dataSource={tabPanelItems}
                        // itemComponent={contentTab}
                        items={telecobMultiViewAtualItemsState} 
                        deferRendering={false}
                        itemRender={contentTab}
                        // selectedIndex={indexTabPanelMain}
                        // tabIndex={indexTabPanelMain}
                        repaintChangesOnly={true}
                        animationEnabled={false}
                        // loop={false}
                        noDataText="Bem vindo(a)!"
                        swipeEnabled={false}
                        showNavButtons={true}
                        scrollByContent={true}
                        selectedItem={telecobMultiViewAtualItemsState[indexTelecobMultiViewAtualItemsState]}
                        // onOptionChanged={optionChange}
                    />
            </div>
    );
}

export default memo(MultiVIewMainTeleCob);