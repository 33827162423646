import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    TotalItem
    } from 'devextreme-react/data-grid';
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID } from '../../../api/ApplicationID';
import { useState, useEffect, useRef, memo } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import UpfPopupMessage from "../../../CardComponents/UpfPopupMessage"
import { convertDate } from '../../../functions/convertDate';
import { LoadPanel } from 'devextreme-react/load-panel';
import TextBox from 'devextreme-react/text-box';
import Loading from "../../../CardComponents/Loading";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import LoadCircular from '../../../CardComponents/LoadCircular';
import Form, { 
    SimpleItem, 
    GroupItem,
    RequiredRule,
    PatternRule,
    TabbedItem, 
    TabPanelOptions, 
    Tab, 
    Label, 
    ButtonItem } from 'devextreme-react/form';
import "../style.css"

const Passo1 = (props) => {
    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [disabledDataGridState, setDisabledDataGridState] = useState(false)
    const [dataLookup, setDataLookup] = useState()

    const setTipoOcorrencia = () => {
        if(props?.gerarAcordoDataSource?.tipoOcorrencia){
            return props?.gerarAcordoDataSource?.tipoOcorrencia
        }
        else{
            return 1
        }
    }
    const statesJson = {
        dataSource: null,
        tipoOcorrencia: setTipoOcorrencia(),
        dateBoxState: null,
        tipoNegociacaoState: null,
        opcaoNegociacaoState: null,
    }

    const [formData, setFormData] = useState({"campanhaDesconto": null, "dataCampanha": null,"tipoOcorrencia": setTipoOcorrencia()})

    const _dataGridParcelasRef = useRef(null)
    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [cdPessoaState, setCdPessoaState] = useState()

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const calculateCustomSummary = (options) => {
        // Calculating "customSummary1"
        if(options.name == "_value") {
            switch(options.summaryProcess) {
                case "start":
                    // Initializing "totalValue" here
                    break;
                case "calculate":
                    // Modifying "totalValue" here
                    break;
                case "finalize":
                    // Assigning the final value to "totalValue" here
                    break;
            }
        }
     
        // Calculating "customSummary2"
        if(options.name == "customSummary2") {
            // ...
            // Same "switch" statement here
        }
    }

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    

    const entityStructureFieldsDefs = [
        {
            fieldName: "logradouro",
            fieldCaption: "Logradouro",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "numero",
            fieldCaption: "Número",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "complemento",
            fieldCaption: "Complemento",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "bairro",
            fieldCaption: "Bairro",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "localidade",
            fieldCaption: "Localidade",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "cep",
            fieldCaption: "CEP",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "uf",
            fieldCaption: "UF",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "tiporesidencia",
            fieldCaption: "Tipo Residência",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "tipoendereco",
            fieldCaption: "Tipo Endereço",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "temporesidencia",
            fieldCaption: "Tempo Residência",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        },
        {
            fieldName: "ultimaalteracao",
            fieldCaption: "Última Alteração",
            editorType: null,
            format: null,
            visible: 1,
            showInColumnChooser: 1
        }
    ]

    const convertValuesStringToNumber = (estruturura, dados) => {
        for(let c = 0; c <= estruturura.length - 1; c++){
            if(estruturura[c]?.format === "money"){
                for(let i = 0; i <= dados.length - 1; i++){
                    if(dados[i]?.[estruturura[c].fieldName]){
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName]).toFixed(2)
                        dados[i][estruturura[c].fieldName] = parseFloat(dados[i][estruturura[c].fieldName])
                    }
                }
            }
            
        }
    }

    return(
        <div style={{height: "100%", padding: "0.5em"}}>
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            {props.dataSourcePrimeiraTela ? 
                (
                    <>    
                        <div>
                            <Form 
                                colCount={4}
                                formData={{
                                    email: props?.dataSourcePrimeiraTela?.email,
                                    telefone: props?.dataSourcePrimeiraTela?.telefone
                                }}
                                // showColonAfterLabel={true}
                                // minColWidth={200}
                                cssClass={'group-item'}
                                readOnly={true}
                                alignItemLabelsInAllGroups={true}
                                alignItemLabels={true}
                                // onInitialized={(e) => {
                                //     console.log(e)
                                //     e.component.option("value", Boolean(e.component.option("value")))
                                // }}
                            >
                                <GroupItem 
                                    colCount={4}
                                    colSpan={4}
                                    cssClass={'group-item'}
                                    itemType={'group'}
                                    showClearButton={true}
                                    icon={'folder'}
                                >

                                    <SimpleItem
                                        colSpan={1}
                                        editorType="dxTextBox"
                                        dataField={"telefone"}
                                        editorOptions={{
                                            readOnly: true
                                        }}
                                    >
                                        <Label text={"Telefone"}/>
                                    </SimpleItem>

                                    <GroupItem
                                        colSpan={1}
                                        colCount={1}
                                    >
                                        <ButtonItem
                                            buttonOptions={{
                                                // icon: 'find'
                                                text: 'Atualizar telefone'
                                                , type: 'default'
                                                , disabled: true
                                                , width: 147
                                                // , onClick: getRenegociacaoSimulacaoParcelamento
                                                // , stylingMode: 'default'
                                                // refreshButtonOptions: false,
                                                // , useSubmitBehavior: true
                                            }}
                                        />
                                    </GroupItem>

                                    <SimpleItem
                                        colSpan={1}
                                        editorType="dxTextBox"
                                        dataField={"email"}
                                        editorOptions={{
                                            readOnly: true
                                        }}
                                    >
                                        <Label text={"E-mail"}/>
                                    </SimpleItem>

                                    <GroupItem
                                        colSpan={1}
                                        colCount={1}
                                    >
                                        <ButtonItem
                                            buttonOptions={{
                                                // icon: 'find'
                                                text: 'Atualizar e-mail'
                                                , type: 'default'
                                                , disabled: true
                                                , width: 147
                                                // , onClick: getRenegociacaoSimulacaoParcelamento
                                                // , stylingMode: 'default'
                                                // refreshButtonOptions: false,
                                                // , useSubmitBehavior: true
                                            }}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </Form>

                            <div className="div-parcelas">
                                <div style={{ marginTop: '1em' }}>
                                    <Button
                                        icon="columnchooser"
                                        hint="Mostrar ou esconder colunas do grid"
                                        type="normal"
                                        onClick={() => {
                                            _dataGridParcelasRef.current.instance.showColumnChooser()
                                        }}
                                    />
                                </div>
                            </div>

                            <DataGrid
                                ref={_dataGridParcelasRef}
                                dataSource={props.dataSourcePrimeiraTela?.enderecos ? (props.dataSourcePrimeiraTela?.enderecos) : ([])}
                                columnAutoWidth={true}
                                showBorders={true}
                                cacheEnabled={false}
                                disabled={disabledDataGridState}
                            >

                                <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />

                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />

                                <Selection 
                                    mode="single"
                                    keyExpr='ID'
                                />
                                <Scrolling 
                                    rowRenderingMode='infinity'
                                    showScrollbar='always'
                                    useNative={true}
                                >
                                </Scrolling>

                                <Paging defaultPageSize={8} />

                                <Sorting
                                    mode="none"
                                />
                                
                                <Pager
                                    visible={true}
                                    allowedPageSizes={8}
                                    // showPageSizeSelector={true}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                
                                {/* <HeaderFilter
                                        allowSearch={true}
                                        visible={true}
                                    />
                                <GroupPanel
                                    visible={true}
                                    allowColumnDragging={true}
                                />
                                <FilterRow visible={true} /> */}
                                
                                <ColumnChooser 
                                    enabled={false} 
                                    width={400} 
                                    height={800} 
                                    mode={"select"} 
                                    allowSearch={true}
                                />

                                {entityStructureFieldsDefs.map((item) => {
                                    if(item.editorType === 'dxDateBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='date'
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.editorType === 'dxCheckBox'){
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                dataType='boolean'
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                            />
                                        )
                                    }
                                    else if(item.format === 'money') {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                format="R$ #,##0.##00"
                                            />
                                        )
                                    }
                                    else if (item.format === 'percent') {
                                        return (
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                showInColumnChooser={item.showInColumnChooser}
                                                format="#0'%'"
                                            />
                                        )
                                    }
                                    else {
                                        return(
                                            <Column
                                                dataField={item.fieldName}
                                                caption={item.fieldCaption}
                                                visible={item.visible}
                                                groupIndex={item?.groupIndex}
                                                showInColumnChooser={item.showInColumnChooser}
                                            />
                                        )
                                    }
                                    
                                })}
                                <Summary
                                    calculateCustomSummary={calculateCustomSummary}
                                >

                                    {entityStructureFieldsDefs.map((item) => {
                                        if(item.format === 'money') {
                                            return(
                                                <TotalItem
                                                    column={item.fieldName}
                                                    summaryType="sum"
                                                    format={{
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        precision: 2,
                                                    }}
                                                    customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                />
                                            )
                                        }
                                    })}

                                </Summary>
                            </DataGrid>
                            <div className="div-boleto-form-vencimento">
                                <div style={{marginLeft: "1em"}}>
                                    <Button
                                        width={200}
                                        height={40}
                                        text="Validar cadastro"
                                        type="default"
                                        // disabled={disabledButtonState}
                                        disabled={!props.renegociacaoSimulacaoState}
                                        stylingMode="contained"
                                        onClick={() => {
                                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                        }}
                                    ></Button>

                                    {/* <LoadIndicator
                                        className="button-indicator"
                                        visible={!props.renegociacaoSimulacaoState}
                                    /> */}
                                    {!props.renegociacaoSimulacaoState &&
                                        <LoadCircular/>
                                    }
                                </div>
                                <Button
                                    width={200}
                                    height={40}
                                    text="Atualizar cadastro"
                                    type="default"
                                    disabled={true}
                                    stylingMode="contained"
                                    // onClick={() => {
                                    // }}
                                ></Button>
                            </div>
                        </div>
                    </>
                ) 
                :
                (
                    <Loading/>
                )

            }

        </div>
    )
}

export default memo(Passo1);