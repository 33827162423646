import { useEffect, useState, useCallback } from "react";
import { applicationID, relationId } from "../../api/ApplicationID";
import { useRequestsApi } from "../../Context/RequestsApi"
import { httpRequest } from "../../functions";
import { TreeList, Editing, Column, Buttom, RequiredRule, Lookup, RowDragging } from 'devextreme-react/tree-list';
import CheckBox from 'devextreme-react/check-box';
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ScrollView } from "devextreme-react";
import { Button } from 'devextreme-react/button';

export default function OcorrenciaTarefaCustomForm(props) {

    const { requestsApi } = useRequestsApi()
    const [change, setChange] = useState()
    const [data, setData] =
        useState(
            {
                allowReordering: false
            }
        )
    const [ocorrencia, setocorrencia] = useState([])

    function UpdateData(value) {
        httpRequest("POST",
            requestsApi.gravarTreeView
            , requestsApi.basicAuth
            , {
                "applicationId": applicationID,
                "relationId": relationId,
                "entityName": props.parameters,
                "changes": value
            })
            .then((sender) => {
                const senderInsertRecordFromJson = JSON.parse(sender);
                console.log(senderInsertRecordFromJson);
                setocorrencia(senderInsertRecordFromJson.masterEntity[0].records)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        httpRequest("POST",
            requestsApi.getDataEntity
            , requestsApi.basicAuth
            , {
                "entityName": props.parameters,
                "fieldName": "*",
                "expressao": "*",
                "applicationId": applicationID,
            })
            .then((sender) => {
                const senderInsertRecordFromJson = JSON.parse(sender);
                console.log(senderInsertRecordFromJson);
                setocorrencia(senderInsertRecordFromJson.masterEntity[0].records)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    function onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        const sourceIndex = ocorrencia.indexOf(sourceData);
        let ocorrenciacontent = ocorrencia
        let ocorrenciadata
        setocorrencia([])

        if (e.dropInsideItem) {
            sourceData = { ...sourceData, _parentid: visibleRows[e.toIndex].key };
            ocorrenciadata = [
                ...ocorrenciacontent.slice(0, sourceIndex),
                sourceData,
                ...ocorrenciacontent.slice(sourceIndex + 1),
            ];
            // e.fromIndex > e.toIndex ? ocorrenciadata.splice(sourceIndex + 1, 1) : ocorrenciadata.splice(sourceIndex, 1)
            console.log(ocorrenciadata)
            UpdateData([{ data: sourceData, key: sourceData.id, type: "update" }])
            setocorrencia(ocorrenciadata)
        } else {
            const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
            if (targetData && e.component.isRowExpanded(targetData.id)) {
                sourceData = { ...sourceData, _parentid: targetData.id };
                targetData = null;
            } else {
                const headId = targetData ? targetData._parentid : -1;
                if (sourceData._parentid !== headId) {
                    sourceData = { ...sourceData, _parentid: headId };
                }
            }
            ocorrenciadata = [...ocorrenciacontent.slice(0, sourceIndex), ...ocorrenciacontent.slice(sourceIndex + 1)];

            const targetIndex = ocorrenciacontent.indexOf(targetData) + 1;
            ocorrenciadata = [...ocorrenciacontent.slice(0, targetIndex), sourceData, ...ocorrenciacontent.slice(targetIndex)];
            // const soIndex = e.fromIndex > e.toIndex ? ocorrenciacontent.indexOf(sourceData) - 1 : ocorrenciacontent.indexOf(sourceData);
            e.fromIndex > e.toIndex ? ocorrenciadata.splice(sourceIndex + 1, 1) : ocorrenciadata.splice(sourceIndex, 1)

            console.log(ocorrenciadata)
            setocorrencia(ocorrenciadata)
        }
    }
    function onAllowReorderingChanged(args) {
        setData({
            allowReordering: args.value,
        });
    }
    function onDragChange(e) {
        const visibleRows = e.component.getVisibleRows();
        const sourceNode = e.component.getNodeByKey(e.itemData.ID);
        let targetNode = visibleRows[e.toIndex].node;

        console.log(e)
        while (targetNode && targetNode?.data) {
            if (targetNode?.data?.ID === sourceNode?.data?.ID) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode?.parent;
        }
    }

    const learningOptions = {
        text: "salvar alterações"
        , icon: "check"
        , hoverStateEnabled: true
        , height: 35
        , stylingMode: "text"
        , disabled: !!change
        , onClick: () => {

        }
    }

    function setChanges(change) {
        console.log(change)
        setChange(change)
    }

    return (
        <div className="div-tree-list">
        <ScrollView width={"100%"} height={"100%"} useNative={true}>
            <TreeList
                className="tree-list-upf"
                id="ocorrencia"
                dataSource={ocorrencia}
                rootValue={-1}
                keyExpr="id"
                showRowLines={true}
                showBorders={true}
                parentIdExpr="_parentid"
                columnAutoWidth={true}
                focusedRowEnabled={true}
                width={"100%"}
                // onEditingChange={e=>{console.log(e); console.log(ocorrencia)}}
                onSaved={e => UpdateData(e.changes)}
            >
                <Editing
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    mode="batch"
                    confirmDelete={true}
                    useIcons={true}
                />

                <RowDragging
                    // onDragChange={onDragChange}
                    onReorder={(e) => { onReorder(e) }}
                    allowDropInsideItem={data.allowReordering}
                    allowReordering={data.allowReordering}
                    showDragIcons={data.allowReordering}
                />
                <Column dataField="_descricao" caption="Descrição" />
                <Column dataField="_codigo" />
            </TreeList>
            <div className="check-box-allow">
                <CheckBox
                    value={data.allowReordering}
                    text="Permitir reagrupar itens"
                    onValueChanged={onAllowReorderingChanged}
                />
            </div>
        </ScrollView>
    </div>)
}