import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from './MenuIcon';
import { MoreVert } from '@mui/icons-material';
import { ChevronLeft } from '@mui/icons-material';
import { ChevronRight } from '@mui/icons-material';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { MoveToInbox } from '@mui/icons-material';
import { Mail } from '@mui/icons-material';
import { useState, useEffect, useCallback, memo } from 'react';
import WelcomeTab from '../TabPanelMain/WelcomeTab';
import { validatePermission } from '../../../functions/validatePermission';
import { useTabPanelItems } from '../../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { Logout } from '@mui/icons-material';
import { BrowserUpdated } from '@mui/icons-material';
import { ColorLens } from '@mui/icons-material';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import UpfPopupAtualizacoes from '../../../CardComponents/UpfPopupAtualizacoes';
import { 
  Popup, 
  ToolbarItem,
} from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import ScrollView from 'devextreme-react/scroll-view';
import QueryForm from '../../QueryForm';
import RecordForm from '../../RecordForm';
import ViewForm from '../../ViewForm';
import DashboardDesignerForm from '../../DashBoardsComponents/DashboardDesignerForm';
import DashboardViewerForm from '../../DashBoardsComponents/DashboardViewerForm';
import OcorrenciaCustomForm from '../../OcorrenciaCustomForm';
import OcorrenciaTarefaCustomForm from '../../OcorrenciaTarefaCustomForm';
import FileManagerForm from '../../FileManagerForm';
import HistoricoCustomForm from '../../HistoricoCustomForm';
import { httpRequest } from '../../../functions';
import { applicationID } from '../../../api/ApplicationID';
import SubItemMenu from './SubItemMenu';
import Menu from 'devextreme-react/menu';
import { Folder } from '@mui/icons-material';
import { Source } from '@mui/icons-material';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import TextBox from 'devextreme-react/text-box';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import TechfyTitle from "../../../TechFy-Title.png"
import $ from 'jquery'
import './style.scss';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,

  },
  hide: {
    display: 'none',
  },
  drawer: {
    backgroundColor: 'transparent',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: 'transparent',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      // duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: 'transparent',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
      // duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 40,
    // width: theme.spacing(7) +1,
    [theme.breakpoints.up('sm')]: {
      width: 40,
      // width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    height: '100vh',
    width: `calc(100% - ${drawerWidth}px)`,
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: 50,
    height: 50,
    position: 'relative',
    zIndex: 2
  },
}));

let chamouWelcomePage = false

let elementoAtual = null;

const DrawerVariant = (props) => {

  function mouseEventHandler(mEvent) {
    elementoAtual = mEvent.srcElement.outerHTML || mEvent.target.outerHTML;
  }

  document.body.onmousemove = mouseEventHandler;

  const {tabPanelItems, setTabPanelItems} = useTabPanelItems()
  const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain()


  const permissionSubitems = (array) => {
    const arraySubitensVisiveis = []

    for(let i = 0; i <= array.length - 1; i++){
      let permissoesSubItem = validatePermission(array[i]?._objectId)

      if(permissoesSubItem?._show === 1){
        arraySubitensVisiveis.push(array[i])

        if(array[i]?.items?.length > 0){
            permissionSubitems(array[i]?.items)
        }
        if(array[i]?.subMenuItems?.length > 0){
            permissionSubitems(array[i]?.subMenuItems)
        }
      }
    }

    return arraySubitensVisiveis
  }

  // const dispatch = useDispatch();

  // const tabPanelIndexGlobalState = useSelector(state => state.tabPanelComponentGlobalStateReducer)
  // const tabPanelComponentGlobalState = useSelector(state => state.tabPanelComponentGlobalStateReducer)
  const { requestsApi, setRequestsApi } = useRequestsApi()
  const [menuItems, setMenuItems] = useState();
  const [menuAntesFiltro, setMenuAntesFiltro] = useState()
  const [filtroMenu, setFiltroMenu] = useState();

  const mountMenuLinearArray = (array, mainItems) => {
    if(mainItems?.length > 0){
      for(let c = 0; c <= mainItems.length - 1; c++){
        array.push(mainItems[c])
        if(mainItems[c]?.items?.length > 0){
          for(let i = 0; i <= mainItems[c].items.length - 1; i++){
            array.push(mainItems[c].items[i])
            if(mainItems[c].items[i]?.items){
              mountMenuLinearArray(array, mainItems[c].items[i]?.items)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if(filtroMenu && menuItems){
      console.log(menuItems)
      console.log(menuAntesFiltro)

      const newMenuItems = []

      mountMenuLinearArray(newMenuItems, menuAntesFiltro)
      
      const itensFiltrados = newMenuItems.filter((item) => item?.text.toLowerCase().includes(filtroMenu.toLowerCase()))
      setMenuItems(itensFiltrados)
    }
    else{
      setMenuItems(menuAntesFiltro)
    }

  }, [filtroMenu])

  const [themesModal, setThemesModal] = useState(false)
  const [exit, setExit] = useState({
    isVisible: false
    ,message: "Atenção"
  });
  const [confirmTheme, setConfirmTheme] = useState({
    isVisible: false
    ,message: "Atenção"
  });
  const [themeSystem, setThemeSystem] = useState("");
  const [messageError, setMessageError] = useState({message: ""})
  const dataThemes = [
     {fieldName: "greendark", fieldCaption: "Green Dark"}
    ,{fieldName: "darkblue", fieldCaption: "Dark Blue"}
    ,{fieldName: "softblue", fieldCaption: "Soft Blue"}
    ,{fieldName: "greenmist", fieldCaption: "Green Mist"}
    ,{fieldName: "darkviolet", fieldCaption: "Dark Violet"}
    ,{fieldName: "carmine", fieldCaption: "Carmine"}
    ,{fieldName: "darkmoon", fieldCaption: "Dark Moon"}
  ]

  // const [getServerInfoState, setGetServerInfoState] = useState()
  // const [getDashboardInfo, setGetDashboardInfo] = useState();

  const [indexSelectedState, setIndexSelectedState] = useState()

  const [upfPopupAtualizacoesVisibleState, setUpfPopupAtualizacoesVisibleState] = useState(false)
  const [anchorElPopper, setAnchorElPopper] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [placementPopper, setPlacementPopper] = useState();
  const [contentPopper, setContentPopper] = useState();

  const addNewTab = useCallback((itemClicado) => {
    console.log(itemClicado)
    let tabItemId = Math.random()
    if (itemClicado.path !== null && itemClicado.path === 'queryForm') {
        setTabPanelItems([...tabPanelItems, 
          {
            title: itemClicado.text
            , icon: itemClicado.icon
            , className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            , component:
                <ScrollView width='100%' height='100%' useNative={true}>
                    <QueryForm
                        entityName={itemClicado.parameters}
                        tabItemId={tabItemId}
                        caption={itemClicado.text}
                    />
                </ScrollView>
            , text: Math.random()
          }
        ])

        // dispatch(addTabComponent(
        //   {
        //     title: itemClicado.text
        //     , icon: itemClicado.icon
        //     , className: "tab-sheet"
        //     //,icon: '/images/favicon.ico'
        //     , component:
        //         <ScrollView width='100%' height='100%'>
        //             <QueryForm
        //                 entityName={itemClicado.parameters}
        //                 tabItemId={tabItemId}
        //                 caption={itemClicado.text}
        //             />
        //         </ScrollView>
        //     , text: Math.random()
        // }
        // ))
        // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
        // dispatch(addTabIndice())
        // dispatch(changeTabIndice(tabPanelIndexGlobalState + 1))
    }
    else if (itemClicado.path !== null && itemClicado.path === 'recordForm') {

        const parametersJson = JSON.parse(itemClicado.parameters)

        const parametersJsonToLowerCase = {}

        for(let c = 0; c <= Object.keys(parametersJson).length - 1; c++){
            parametersJsonToLowerCase[Object.keys(parametersJson)[c].toLowerCase()] = parametersJson[Object.keys(parametersJson)[c]]
        }

        if(parametersJsonToLowerCase?.entityname){
            httpRequest("POST", requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: parametersJsonToLowerCase.entityname,
                applicationId: applicationID,
            })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)

                if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                    })
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)

                            setTabPanelItems([...tabPanelItems, 
                              {
                                title: itemClicado.text
                                , icon: itemClicado.icon
                                , component:
                                    <ScrollView useNative={true}>
                                        <RecordForm
                                            idValue={parametersJsonToLowerCase?.id && parametersJsonToLowerCase.id}
                                            entityName={parametersJsonToLowerCase?.entityname}
                                            modeEditOn={!parametersJsonToLowerCase?.id && true}
                                            icon={itemClicado.icon}
                                            entityStructure={resultGetEntityStructureJson}
                                            dataLookup={senderDataLookup}
                                        />
                                    </ScrollView>
                                , text: Math.random()
                              }
                            ])
                            // dispatch(addTabComponent(
                            //   {
                            //     title: itemClicado.text
                            //     , icon: itemClicado.icon
                            //     , component:
                            //         <ScrollView>
                            //             <RecordForm
                            //                 idValue={parametersJsonToLowerCase?.id && parametersJsonToLowerCase.id}
                            //                 entityName={parametersJsonToLowerCase?.entityname}
                            //                 modeEditOn={!parametersJsonToLowerCase?.id && true}
                            //                 icon={itemClicado.icon}
                            //                 entityStructure={resultGetEntityStructureJson}
                            //                 dataLookup={senderDataLookup}
                            //             />
                            //         </ScrollView>
                            //     , text: Math.random()
                            // }
                            // ))
                            // setIndexTabPanelMain(tabPanelItems.length)
                            // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))

                        })
                        .catch((error) => console.log(error))
                }
                else {
                    setTabPanelItems([...tabPanelItems, 
                      {
                        title: itemClicado.text
                        , icon: itemClicado.icon
                        , component:
                            <ScrollView useNative={true}>
                                <RecordForm
                                    entityName={parametersJsonToLowerCase?.entityname}
                                    idValue={parametersJsonToLowerCase?.id && parametersJsonToLowerCase.id}
                                    icon={itemClicado.icon}
                                    entityStructure={resultGetEntityStructureJson}
                                />
                            </ScrollView>
                        , text: Math.random()
                      }
                    ])
                    // dispatch(addTabComponent(
                    //   {
                    //     title: itemClicado.text
                    //     , icon: itemClicado.icon
                    //     , component:
                    //         <ScrollView>
                    //             <RecordForm
                    //                 entityName={parametersJsonToLowerCase?.entityname}
                    //                 idValue={parametersJsonToLowerCase?.id && parametersJsonToLowerCase.id}
                    //                 icon={itemClicado.icon}
                    //                 entityStructure={resultGetEntityStructureJson}
                    //             />
                    //         </ScrollView>
                    //     , text: Math.random()
                    // }
                    // ))
                    // setIndexTabPanelMain(tabPanelItems.length)
                    // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
                }
            })
            .catch((error) => console.log(error))
        }  
    }
    else if (itemClicado.path !== null && itemClicado.path === 'viewForm') {

      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width='100%' height='100%' useNative={true}>
                  <ViewForm
                      id={itemClicado.parameters}
                      caption={itemClicado.text}
                      tabItemId={tabItemId}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
        // dispatch(addTabComponent(
        //   {
        //     title: itemClicado.text
        //     , icon: itemClicado.icon
        //     , component:
        //         <ScrollView width='100%' height='100%'>
        //             <ViewForm
        //                 id={itemClicado.parameters}
        //                 caption={itemClicado.text}
        //                 tabItemId={tabItemId}
        //             />
        //         </ScrollView>
        //     , text: Math.random()
        //   }
        // ))
        // setIndexTabPanelMain(tabPanelItems.length)
        // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }
    else if (itemClicado.path !== null && itemClicado.path === 'dashboardDesignerForm') {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width='100%' height='100%' useNative={true}>
                  <DashboardDesignerForm />
              </ScrollView>
          , text: Math.random()
        }
      ])
        // dispatch(addTabComponent(
        //   {
        //     title: itemClicado.text
        //     , icon: itemClicado.icon
        //     , component:
        //         <ScrollView width='100%' height='100%'>
        //             <DashboardDesignerForm />
        //         </ScrollView>
        //     , text: Math.random()
        //   }
        // ))
        
        // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }
    else if (itemClicado.path !== null && itemClicado.path === 'dashboardViwerForm') {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width='100%' height='100%' useNative={true}>
                  <DashboardViewerForm
                      dashboardId={itemClicado.parameters}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width='100%' height='100%'>
      //             <DashboardViewerForm
      //                 dashboardId={itemClicado.parameters}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))
        
      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }
    else if (itemClicado.path !== null && itemClicado.path === 'fileManagerForm') {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width='100%' height='100%' useNative={true}>
                  <FileManagerForm
                      path={itemClicado.parameters}
                      caption={itemClicado.text}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width='100%' height='100%'>
      //             <FileManagerForm
      //                 path={itemClicado.parameters}
      //                 caption={itemClicado.text}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))

      // console.log(tabPanelIndexGlobalState)
      // console.log(tabPanelComponentGlobalState)
      // console.log(tabPanelIndexGlobalState)
      
      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }
    else if (itemClicado.path !== null && itemClicado.path === 'ocorrenciaCustomForm') {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width="100%" height="100%" useNative={true}>
                  <OcorrenciaCustomForm 
                      parameters={itemClicado?.parameters}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width="100%" height="100%">
      //             <OcorrenciaCustomForm 
      //                 parameters={itemClicado?.parameters}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))
        
      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }

    else if (itemClicado.path !== null && itemClicado.path === 'ocorrenciaTarefaCustomForm') {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width="100%" height="100%" useNative={true}>
                  <OcorrenciaTarefaCustomForm 
                      parameters={itemClicado?.parameters}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])

      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width="100%" height="100%">
      //             <OcorrenciaTarefaCustomForm 
      //                 parameters={itemClicado?.parameters}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))
      
      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }

    else if(itemClicado['customRecordForm'] === "historicoForm") {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width="100%" height="100%" useNative={true}>
                  <HistoricoCustomForm
                      // ocorrencias={props.ocorrencias}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width="100%" height="100%">
      //             <HistoricoCustomForm
      //                 // ocorrencias={props.ocorrencias}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))

      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }
    

    else if(itemClicado['customInsertRecordForm'] === "historicoForm") {
      setTabPanelItems([...tabPanelItems, 
        {
          title: itemClicado.text
          , icon: itemClicado.icon
          , component:
              <ScrollView width="100%" height="100%" useNative={true}>
                  <HistoricoCustomForm
                      // ocorrencias={props.ocorrencias}
                  />
              </ScrollView>
          , text: Math.random()
        }
      ])
      // dispatch(addTabComponent(
      //   {
      //     title: itemClicado.text
      //     , icon: itemClicado.icon
      //     , component:
      //         <ScrollView width="100%" height="100%">
      //             <HistoricoCustomForm
      //                 // ocorrencias={props.ocorrencias}
      //             />
      //         </ScrollView>
      //     , text: Math.random()
      //   }
      // ))

      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
    }

}, [indexTabPanelMain, tabPanelItems])

  const [open, setOpen] = useState(false);
  const [openFixo, setOpenFixo] = useState(false);
  const [closeFixo, setCloseFixo] = useState(false);


  const onClickMenuItem = (item) => {
    if(item.itemData.path){
      if(props.addNewTabTelecob){
        props.addNewTabTelecob(item)
      }
      else{
        addNewTab(item.itemData)
      }
    }
  };

  const handleDrawerClose = (e) => {
    if(!openFixo && !closeFixo){
      // setOpenPopper(false)
      setOpen(false);
    }
  };

  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerOpen = () => {
    if(!closeFixo){
      document.getElementById("input-pesquisa-menu").focus()
      setOpen(true);
    }
  };


  const fixarMenu = () => {
    if(!openFixo){
      document.getElementById("input-pesquisa-menu").focus()
      localStorage.setItem("statusMenuFixo", "fixoAberto")
    }
    else{
      localStorage.setItem("statusMenuFixo", "")
    }

    setOpenFixo(!openFixo)
  }

  const fixarMenuFechado = () => {
    if(!closeFixo){
      localStorage.setItem("statusMenuFixo", "fixoFechado")
    }
    else{
      localStorage.setItem("statusMenuFixo", "")
    }
    setCloseFixo(!closeFixo)
  }


  useEffect(() => {
    if(!chamouWelcomePage){
      console.log(chamouWelcomePage)
      chamouWelcomePage = true
      console.log(chamouWelcomePage)
      setTabPanelItems([...tabPanelItems, 
        {
          title: ""
          , icon: "home"
          , badge: ""
          , component: <WelcomeTab />
          // , component: "WelcomeTab"
          // , props: {}
          , text: Math.random()
        }
      ])

      try{
        const statusMenuFixo = localStorage.getItem("statusMenuFixo")
        if(statusMenuFixo === "fixoAberto"){
          setOpenFixo(true)

        }
        else if(statusMenuFixo === "fixoFechado"){
          setCloseFixo(true)

        }
      }
      catch{
        
      }

      // dispatch(addTabComponent(
      //   {
      //     title: "Tarefas"
      //     , icon: "home"
      //     , badge: ""
      //     , component: <WelcomeTab />
      //     // , component: "WelcomeTab"
      //     // , props: {}
      //     , text: Math.random()
      //   }
      // ))
  
      // dispatch(addTabIndice(tabPanelComponentGlobalState.length - 1))
      // dispatch(changeTabIndice(tabPanelIndexGlobalState + 1))
    }

    if(props.telecobMenu){
      setMenuItems(props.telecobMenu)
      setMenuAntesFiltro(props.telecobMenu)
    }
    else{
      const menuItemsPermission = JSON.parse(sessionStorage.getItem('MenuDataSource'))

      const arrayResult = []
  
      let indiceArrayResult = 0
  
      for(let c = 0; c <= menuItemsPermission[0].mainMenu[0].items.length - 1; c++){
          let permissoesItem = validatePermission(menuItemsPermission[0].mainMenu[0].items[c]?._objectId)
          
          if(permissoesItem?._show === 1){
              arrayResult.push(menuItemsPermission[0].mainMenu[0].items[c])
              indiceArrayResult++
              if(arrayResult[indiceArrayResult - 1]?.items?.length > 0){
  
                arrayResult[indiceArrayResult - 1]["items"] = permissionSubitems(arrayResult[indiceArrayResult - 1]?.items)
                  
              }
          }
      }

      
      setMenuItems(arrayResult)
      setMenuAntesFiltro(arrayResult)
    }

  }, [props.telecobMenu])

  const changeTheme = () => {
    setThemesModal(true)
  }

  const updateVersions = () => {
    setUpfPopupAtualizacoesVisibleState(true)
  }

  const logOut = () =>{
    setExit({...exit, isVisible:true})
  }

  const selectedTheme = (sender) => {
    window.localStorage.setItem('dx-theme', `${sender}`)
    window.location.reload()
  }


  const choosedTheme = {
    text: "Ok"
    ,icon: "check"
    ,type: 'default'
    ,stylingMode: 'default' 
    ,onClick: ()=>{
      if(theme){
        setConfirmTheme({...confirmTheme, isVisible: true})
        setMessageError({message:""})
      }else{
        setMessageError({message: "Você precisa selecionar um tema para prosseguir."})
      }
    }
  } 
  const closeChoosedTheme = {
    text:"Cancelar"
    ,icon: "chevronleft"
    ,onClick: ()=>{
      setMessageError({message: ""})
      setThemesModal(false)}
  }

  const closeConfirmTheme = {
    text:"Cancelar"
    ,icon: "chevronleft"
    ,onClick: ()=>{
      setConfirmTheme({isVisible: false})}
  }
  const confirmButtonSelectedTheme = {
    text: "Mudar Tema"
    ,icon: "color"
    ,type: 'default'
    ,stylingMode: 'default' 
    ,onClick: ()=>{
      selectedTheme(themeSystem)
    }
  }
  const confirmExit = {
    text: "Sair"
    ,icon: "export"
    ,type: 'default'
    ,stylingMode: 'default' 
    ,onClick: ()=>{
      window.location.href = ""  //  /login
      // localStorage.removeItem("MenuDataSource")
      sessionStorage.clear()
    }
  }
  const closeExit = {
    text: "Cancelar"
    ,icon: "close"
    ,onClick: ()=>setExit({...exit, isVisible: false})
  }


  const [indexSelectedSubItemMenuState, setIndexSelectedSubItemMenuState] = useState()

  return (
    <div className={classes.root}>

      <Popup
        visible={exit.isVisible}
        onHiding={()=>{setExit({isVisible: false})}}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={"Atenção"}
        width={600}
        height={280}
        resizeEnabled={true}
        id="popupForm"
      >
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={confirmExit}
        />
        
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeExit}
        />
        
        <p>
            Se você sair perderá todas as informações.
        </p>
        <p>
          Deseja realmente sair?
        </p>  
      </Popup>

      <Popup
        visible={themesModal}
        onHiding={() => setThemesModal(false)}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={"Escolha uma opção"}
        width={300}
        height={280}
        resizeEnabled={true}
        >
        <SelectBox
          dataSource={dataThemes}
          displayExpr={"fieldCaption"}
          valueExpr={"fieldName"}
          onValueChanged = {
            (sender) => {
              // setChosedTheme(sender.value)
              // selectedTheme(sender.value
              setThemeSystem(sender.value)
            }
          }
        />
        <h4 style={{textAlign:"center"}}>{messageError.message}</h4>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"             
          options={choosedTheme}
        />

        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeChoosedTheme}
        />
        
      </Popup>

      <Popup
        visible={confirmTheme.isVisible}
        onHiding={()=>{setConfirmTheme({isVisible: false})}}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title={"Atenção"}
        width={400}
        height={300}
        resizeEnabled={true}
        id="popupForm"
      >     
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"                  
          options={confirmButtonSelectedTheme}
        />

        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"appBar
            options={closeConfirmTheme}
        />

        <p>
            Se você mudar o tema, perderá as informações pesquisadas.
        </p>
        <p>
          Deseja realmente continuar?
        </p>
      </Popup>

      <UpfPopupAtualizacoes
        visible={upfPopupAtualizacoesVisibleState}
        setVisible={setUpfPopupAtualizacoesVisibleState}
        dataSourceLogin={props.getServerInfoState}
        dataSourceLoginDashboard={props.getDashboardInfo}
      />
      <CssBaseline />

      <AppBar
        position="fixed"
        className={
          clsx(classes.appBar, {[classes.appBarShift]: (open || openFixo)})
        }
        // onMouseLeave={mouseSaiuDoMenu}
      >
        <Toolbar className="toolbar-drawer-main dx-theme-text-color dx-theme-background-color">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // title="Fixar/soltar menu"
            onClick={fixarMenuFechado}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: (open || openFixo),
            })}
          >
            {/* <MenuIcon/> */}
            {/* <div style={{position: "relative", top: "-8px"}}> */}
              {closeFixo ? <LockTwoToneIcon/> : <LockOpenTwoToneIcon/> } 
            {/* </div> */}
          </IconButton>

          <Typography variant="h6" noWrap>
            Techfy
            {/* <img src={TechfyTitle} alt="" width="80" height="auto" style={{margin: "1em"}}/> */}
          </Typography>
          
          <div style={{width: "100%", display: "flex", flexDirection: "row-reverse"}}>

            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              aria-describedby={idPopperToolbarMain}
              // style={{backgroundColor: "black"}}
              onClick={openOptionsToolbar}
              edge="end"
            >
              <MoreVert />
            </IconButton> */}

            <IconButton
              style={{marginLeft: "1em"}}
              color="inherit"
              aria-label="open drawer"
              // style={{backgroundColor: "black"}}
              onClick={logOut}
              edge="end"
            >
              <Logout />
            </IconButton>

            <IconButton
              style={{marginLeft: "1em"}}
              color="inherit"
              aria-label="open drawer"
              // style={{backgroundColor: "black"}}
              onClick={updateVersions}
              edge="end"
            >
              <BrowserUpdated />
            </IconButton>

            <IconButton
              style={{marginLeft: "1em"}}
              color="inherit"
              aria-label="open drawer"
              // style={{backgroundColor: "black"}}
              onClick={changeTheme}
              edge="end"
            >
              <ColorLens />
            </IconButton>

          </div>
        </Toolbar>
      </AppBar>

      <div 
        className='dx-theme-text-color dx-theme-background-color'
        style={{overflow: "hidden"}}
        onMouseEnter={() => {
          // mouseSaiuDoMenu() 
          handleDrawerOpen()

        }}
        onMouseLeave={(e) => {
          // class: dx-submenu
          if(e?.relatedTarget?.classList !== undefined){
            handleDrawerClose()
          }

          // mouseSaiuDoMenu()
        }}
      >

      <Drawer
        id="drawer-main-items-bar"
        position="fixed"
        variant="permanent"
        className={
          `dx-theme-text-color dx-theme-background-color ${clsx(classes.drawer, 
            {
              [classes.drawerOpen]: (open || openFixo),
            [classes.drawerClose]: (!open && !openFixo),
            }
          )}`
        }
        classes={{
          paper: clsx({
            [classes.drawerOpen]: (open || openFixo),
            [classes.drawerClose]: (!open && !openFixo),
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton 
            // title="Fixar/soltar menu"
            onClick={fixarMenu}
            className='dx-theme-text-color dx-theme-background-color'
          >
            <div style={{position: "relative", top: "-8px"}}>
              {openFixo ? <LockTwoToneIcon/> : <LockOpenTwoToneIcon/> } 
            </div>
            {/* {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />} */}
          </IconButton>
        </div>
        <List 
          className='dx-theme-text-color dx-theme-background-color'
          style={{paddingTop: "0"}}
          // onMouseLeave={mouseSaiuDoMenu}
        >

          <ListItem 
            button 
            className="dx-theme-text-color dx-theme-background-color"
          >

            <ListItemIcon 
              // id={`list-item-icon-main${index}`}
              className="list-item-icon-main dx-theme-text-color dx-theme-background-color"
            >
              <SearchIcon fontSize="small"/>
            </ListItemIcon>
            <form autocomplete="off" className='dx-theme-text-color dx-theme-background-color'> 
              <ListItemText 
                // id={`list-item-text-main${index}`}
                primary={
                  <input
                    id="input-pesquisa-menu"
                    name="pesquisar"
                    type='search'
                    className='dx-theme-text-color dx-theme-background-color'
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave={false}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setFiltroMenu(e.target.value)
                    }}
                  />
                } 
                className={"dx-theme-text-color dx-theme-background-color"}
              />
            </form>
          </ListItem>

          {menuItems && 
            <div>
              <Menu 
                className={`menu-principal-${open ? "open": "close"}`}
                dataSource={menuItems}
                displayExpr="text"
                showFirstSubmenuMode={{
                  name: 'onHover',
                  delay: { show: 0, hide: 0 },
                }}
                showSubmenuMode={{
                  name: 'onHover',
                  delay: { show: 0, hide: 0 },
                }}
                // onSubmenuShown={() => {
                //   setOpen(true)
                // }}
                onSubmenuShowing={() => {
                  if(!closeFixo){
                    setOpen(true)
                  }
                }}
                onSubmenuHidden={() => {
                  if(
                    elementoAtual !== '<div class="dx-context-menu-container-border"></div>' &&
                    elementoAtual !== '<div class="dx-context-menu-container-border dx-state-invisible"></div>' &&
                    elementoAtual !== '<div class="dx-menu-item-popout"></div>'  &&
                    elementoAtual.indexOf('<div class="dx-item-content dx-menu-item-content">') === -1 &&
                    elementoAtual.indexOf('<div class="makeStyles-toolbar-42">') === -1 &&
                    elementoAtual.indexOf('<svg class="MuiSvgIcon-root') === -1 &&
                    elementoAtual.indexOf('<path') === -1 &&
                    elementoAtual.indexOf('<div class="dx-context-menu-content-delimiter"') === -1 &&
                    elementoAtual.indexOf('<span class="dx-menu-item-text">') === -1 &&
                    elementoAtual.indexOf('<div class="MuiListItemIcon-root list-item-icon-main dx-theme-text-color dx-theme-background-color"') === -1 &&
                    elementoAtual.indexOf('<input id="input-pesquisa-menu"') === -1

                  
                  ){
                    console.log(elementoAtual)
                    handleDrawerClose()
                  }
                }}
                orientation="vertical"
                hideSubmenuOnMouseLeave={true}
                onItemClick={onClickMenuItem}
              />
            </div>
          }
        </List>
        {/* <Divider /> */}
      </Drawer>
      </div>
    
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.component}
      </main>
    </div>
  );
}

export default memo(DrawerVariant);