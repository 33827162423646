export const formatFunction = (formatType, componentType, fieldName, data, editando) => {
    // "#0.##'%'" => 25 = 25%
    // "#0.##%" => 0.25 = 25%
    const maskCpfCnpj = (jsonColunas, fieldName) => {
        for(let c = 0; c <= jsonColunas.length - 1; c++){
            if(editando){
                if(jsonColunas[c]?.taComMascara){
                    delete jsonColunas[c]?.taComMascara
                }
                jsonColunas[c][fieldName] = jsonColunas[c][fieldName].replace(/\D/g, '')
            }
            else{
                if(jsonColunas[c][fieldName]?.length === 11 && !jsonColunas[c]?.taComMascara){
                    jsonColunas[c][fieldName] = jsonColunas[c][fieldName].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
                    jsonColunas[c]["taComMascara"] = true
    
                }
                else if(jsonColunas[c][fieldName]?.length === 14 && !jsonColunas[c]?.taComMascara){
                    jsonColunas[c][fieldName] = jsonColunas[c][fieldName].replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                    jsonColunas[c]["taComMascara"] = true
                }
            }
        }
    }
    
    if(componentType === "column"){
        if(formatType === "money"){
            return { style: 'currency', currency: 'BRL', useGrouping: true, minimumSignificantDigits: 2, 
            type: "fixedPoint", precision: 2 }
        }
        else if(formatType === "percent"){
            return "#0.##'%'"
        }
        else if(formatType === "cpf/cnpj" && fieldName && data?.length > 0){
            maskCpfCnpj(data, fieldName)
            return null
        }
    }
    else if(componentType === "field"){
        if(formatType === "money"){
            return "R$ #,##0.##;(R$ #,##0.##)"
        }
        else if(formatType === "percent"){
            return "#0.##'%'"
        }
        else if(formatType === "cpf/cnpj" && data[fieldName]){
            const dataSemCaracteresEspeciais = data[fieldName].replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')
            
            if(dataSemCaracteresEspeciais?.length === 11){
                return "000.000.000-00"
            }
            else if(dataSemCaracteresEspeciais?.length === 14){
                return "00.000.000/0000-00"
            }
        }
    }
    
}