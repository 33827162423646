const { createContext, useContext, useState } = require("react");

const ConnectionErrorOccurredGlobalState = createContext();

export const ConnectionErrorOccurredGlobalStateProvider = ({ children }) => {
  const [connectionErrorOccurredGlobalState, setConnectionErrorOccurredGlobalState] = useState(false)

  return (
    <ConnectionErrorOccurredGlobalState.Provider value={{ connectionErrorOccurredGlobalState, setConnectionErrorOccurredGlobalState }}>
      {children}
    </ConnectionErrorOccurredGlobalState.Provider>
  );
};

export const useConnectionErrorOccurredGlobalState = () => useContext(ConnectionErrorOccurredGlobalState);