import { 
    applicationID, 
    GetDataEntity, 
    getViewStructure, 
    InsertRecordFromJson, 
    relationId, 
    userName } from '../../../api/ApplicationID';
import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import { httpRequest } from '../../../functions';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import './style.css'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CheckBox from 'devextreme-react/check-box';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from 'devextreme-react/chart';
import PivotGrid, {
  FieldChooser,
  FieldPanel,
  HeaderFilter
} from 'devextreme-react/pivot-grid';
import Loading from '../../../CardComponents/Loading';
import TabPanel from 'devextreme-react/tab-panel';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import {PivotGridFieldChooser, Texts} from 'devextreme-react/pivot-grid-field-chooser';
import {RadioGroup} from 'devextreme-react/radio-group';
import DataGridViewForm from "../DataGridViewForm"
import RecordFormPopup from '../../RecordForm/RecordFormPopup';
import DataGrid, { 
    Column, 
    Selection,
    Summary,
    GroupItem,
    // HeaderFilter, 
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    SearchPanel,
    Paging,
    FilterRow 
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import { Toast } from 'devextreme-react/toast';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import RecordForm from "../../RecordForm";
import {useTabPanelItems} from "../../../Context/TabPanelItems"
import {useIndexTabPanelMain} from "../../../Context/IndexTabPanelMain"
import { useRequestsApi } from "../../../Context/RequestsApi"
import { convertDate } from '../../../functions/convertDate';
import PercentageBar from "../DataGridViewForm/PercentageBar"
import PokeComponent from "../DataGridViewForm/PokeComponent"
import LoadCircular from '../../../CardComponents/LoadCircular';

const PivotGridViewForm = (props) =>{
    const {requestsApi} = useRequestsApi()
    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };

    const [popup, setPopup] = useState(popups);
    const [messageError, setMessageError] = useState();

    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()


    const [states, setStates] = useState({
        resultViewStructure: null,
        errorPopupVisible: false,
        errorMessage: "Houve um erro",
        drillDownPopupTitle: "",
        drillDownDataSource: null,
        drillDownPopupVisible: false,
        allowedPageSizes: [10, 25, 50],
        dataGridJsonLote: null,
        cellDescription: null,
        dataAreaVisible:false,
        dataFieldArea:'column',
        cellDescriptionArray: [], //essa array vai servir para trocar as cores das celulas selecionadas
        selectedItensCount: 0,
        selectedItens: [],
        loginResultJson: JSON.parse(sessionStorage.getItem("MenuDataSource")),
        loteId : "",
        recordFormPopupRender : false,
        toastVisible: false,
        toastType: "info",
        toastMessage: "",
        loadIndicatorOpenBatchVisible: false,
        buttonTextOpenBatch: "Abrir Lote",
        chartType: props.getViewStructure?.typeOfChart ? props.getViewStructure.typeOfChart : "bar"
    })

    const defaultTypeOfChart = (typeOfChart) => {
        if(typeOfChart === "line"){
            return "Linhas"
        }
        else if(typeOfChart === "area"){
            return "Área"
        }
        else{
            return "Barras"
        }
    }

    //estados globais
    const {tabPanelItems, setTabPanelItems} = useTabPanelItems()
    const {setIndexTabPanelMain} = useIndexTabPanelMain()
    //estados globais


    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
      }

      const createRecordForm = () => {

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: "_lote",
            applicationId: applicationID
        })
        .then((sender) => {
            const resultGetEntityStructureJson = JSON.parse(sender)

            if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                    "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                })
                .then((sender) => {
                    const senderDataLookup = JSON.parse(sender)

                    setTabPanelItems([
                        ...tabPanelItems, 
                        {
                            'title': "Lote selecionado"
                            ,'component': 
                                <ScrollView width='100%' height='100%' useNative={true}>
                                    <RecordForm 
                                        id={Math.random()} 
                                        idValue={states.loteId} 
                                        entityName="_lote"
                                        entityStructure={resultGetEntityStructureJson}
                                        dataLookup={senderDataLookup}
                                    />
                                </ScrollView>
                            ,'text': Math.random()
                            ,'icon':'edit'
                        }
                    ])
                    setIndexTabPanelMain(tabPanelItems.length)
                })
                .catch((error) => console.log(error))
            }
            else{
                setTabPanelItems([
                    ...tabPanelItems, 
                    {
                        'title': "Lote selecionado"
                        ,'component': 
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <RecordForm 
                                    id={Math.random()} 
                                    idValue={states.loteId} 
                                    entityName="_lote"
                                    entityStructure={resultGetEntityStructureJson}
                                />
                            </ScrollView>
                        ,'text': Math.random()
                        ,'icon':'edit'
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            } 
        })
    }
    
    const [resultViewStructure, setResultViewStructure] = useState();

    const layouts = [ {id:0,descricao:"Layout padrão" },
                      {id:1,descricao:"Todas os campos do lado esquerdo"} ] 
    const _pivotGrid = useRef(null)
    const _chart = useRef(null)
    const _dataGridPopup = useRef(null)


    useEffect(()=>{
        const dataRequest = {
            "viewId": props.id
            ,"applicationId": applicationID
        }
        httpRequest('POST', requestsApi.getViewStructure, requestsApi.basicAuth, dataRequest)
            .then(senderGetViewStructure=>{
                if(senderGetViewStructure === "<HTML><BODY><B>200 OK</B></BODY></HTML>" ||
                Object.values(senderGetViewStructure).length === 0) {
                    setResultViewStructure([])
                } else {
                    const resultGetViewScructure = JSON.parse(senderGetViewStructure)
                    if(resultGetViewScructure[0].view.pivotGridProperties.fieldsDefs){
                        resultGetViewScructure[0].view.pivotGridProperties.fieldsDefs.unshift({
                            "fieldName": "_qtd_",
                            "fieldCaption": "Qtd",
                            "fieldType": "numeric",
                            "pivotGridFieldArea": resultGetViewScructure[0].view.pivotGridProperties.autoSummarySum === 1 ? "data": "filter",
                            "pivotGridTabOrder": 0,
                            "pivotGridSummaryType": "count"
    
                        })
                        resultGetViewScructure[0].view.pivotGridProperties.fieldsDefs.unshift({
                            "fieldName": "cellDescription",
                            "fieldCaption": "Célula",
                            "fieldType": "varchar",
                            "fieldSize": 500,
                            "fieldMask": null,
                            "fieldDisplayFormat": null,
                            "gridVisible": 1,
                            "groupIndex": 0,
                            "gridVisibleForCustomization": 0,
                            "colSpan": null
                        })
                        convertDate(props.resultViewDataSet, resultGetViewScructure[0].view.dataGridProperties.fieldsDefs)
    
                        setResultViewStructure(resultGetViewScructure);
                        setDataSource(new PivotGridDataSource(getDataSource(resultGetViewScructure)))
                    }
                    else{
                        setStates({
                            ...states,
                            errorMessage: "Campos não definidos.",
                            errorPopupVisible: true
                          }) 
                    }
                    
                }
            })
            .catch((error)=>{
                if(error.data.error) {
                    // hideLoadPanel()
                    setStates({
                        ...states,
                        errorMessage: error.data.error,
                        errorPopupVisible: true
                    }) 
                }
                else {
                    // hideLoadPanel()
                    setStates({
                        ...states,
                        errorMessage: error.statusText,
                        errorPopupVisible: true
                    }) 
                } 
            })

    },[setResultViewStructure, props.id, _pivotGrid, _chart])


    const drillDownPopupOnHiding = () => {
        setStates({
            ...states,
            drillDownPopupVisible: false
          });
    }
    const drillDownPopupOnShown = () => {
        setStates({
            ...states,
            drillDownPopupVisible: true
          });
    }

    const errorPopupOnHiding = () => {
        setStates({
            ...states,
            errorPopupVisible: false
        })
    }

    const errorPopupOnShown = () => {
        setStates({
            ...states,
            errorPopupVisible: true
        })
    }

    const onCellPreparedFunctionColor = (e) => {
        if(states.cellDescriptionArray.length>0) {
            if (e.cell.rowPath && e.cell.columnPath){
                
                let rowPathName = ""
                for (let a=0;a<=e.cell.rowPath.length-1;a++){
                    rowPathName = rowPathName + e.cell.rowPath[a]+" / "
                }
                rowPathName = rowPathName.substring(0,rowPathName.length-2)
                    
                let columnPathName = ""
                for (let a=0;a<=e.cell.columnPath.length-1;a++){
                    columnPathName = columnPathName + e.cell.columnPath[a]+" / "
                }
                columnPathName = columnPathName.substring(0,columnPathName.length-2)

                let celula = `${columnPathName} X ${rowPathName}`

                if (states.cellDescriptionArray.indexOf(celula)>=0) {
                    e.cellElement.style.border = "solid 2.5px blue"

                }
            }
        }
    }
    
    const onCellClick = (e, type) => {

        if (e.cell.rowPath && e.cell.columnPath){
            let rowPathName = ""
            for (let a = 0; a <= e.cell.rowPath.length - 1; a++){
                rowPathName = rowPathName + e.cell.rowPath[a]+" / "
            }
            // tirando a ultima barrinha
            rowPathName = rowPathName.substring(0,rowPathName.length-2)
            
            let columnPathName = ""
            for (let a = 0; a <= e.cell.columnPath.length - 1; a++){
                columnPathName = columnPathName + e.cell.columnPath[a]+" / "
            }
            columnPathName = columnPathName.substring(0,columnPathName.length-2)
            states.cellDescription = `${columnPathName} X ${rowPathName}`

        if(type === "Gravar") {
            if (e.area == 'data' && rowPathName!==undefined && columnPathName !== undefined){
                let pivotGridDataSource = e.component.getDataSource()
                states.drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(e.cell)
                // setStates({
                //     ...states,
                //     drillDownPopupTitle: `${columnPathName ? columnPathName : 'Total'} X ${rowPathName ? rowPathName : 'Total'}`,
                //     drillDownDataSource: testes,
                //     drillDownPopupVisible: true,
                //     cellDescription: `${columnPathName} X ${rowPathName}`
                // })
                states.drillDownDataSource.store().load().done((data) => {
                    states.selectedItens = data
                })
                getDrillDownAllItens()
            }
            // selectAllItensDataGrid()
        } 
        else{
            states.selectedItens = []
            if (e.area == 'data' && rowPathName!==undefined && columnPathName !== undefined){
                let pivotGridDataSource = e.component.getDataSource()
                setStates({
                    ...states,
                    drillDownPopupTitle: `${columnPathName ? columnPathName : 'Total'} X ${rowPathName ? rowPathName : 'Total'}`,
                    drillDownDataSource: pivotGridDataSource.createDrillDownDataSource(e.cell),
                    drillDownPopupVisible: true,
                    cellDescription: `${columnPathName} X ${rowPathName}`
                })
                // selectAllItensDataGrid()
            }
        } 
        }
    }

    const getDrillDownSelectedItens = () => {
        if(states.selectedItens.length > 0) {
            for(let count = 0; count <= states.selectedItens.length - 1; count++) {
                states.selectedItens[count]["cellDescription"] = states.cellDescription
            }

            if(states.dataGridJsonLote) {
                let a = states.dataGridJsonLote.concat(states.selectedItens)
                setStates({...states, dataGridJsonLote: a})
            } 
            else {
                setStates({...states, dataGridJsonLote: states.selectedItens})
            }
            states.cellDescriptionArray.push(states.cellDescription)

            repaintPivotGrid()
            drillDownPopupOnHiding()
   
        }
        else {
            
          //abir popup com mensagem Nenhum item foi selecionado 
          setStates({
              ...states,
              errorMessage: "Nenhum item foi selecionado.",
              errorPopupVisible: true
            }) 
        }

    }

    const getDrillDownAllItens = () => {
        for(let count = 0; count <= states.selectedItens.length - 1; count++) {
            states.selectedItens[count]["cellDescription"] = states.cellDescription
        }

        if(states.dataGridJsonLote) {
            let a = states.dataGridJsonLote.concat(states.selectedItens)
            setStates({...states, dataGridJsonLote: a})
            states.selectedItens = []
        } 
        else {
            setStates({...states, dataGridJsonLote: states.selectedItens})
            states.selectedItens = []
        }
        states.cellDescriptionArray.push(states.cellDescription)

        repaintPivotGrid()
    }

    const clearDrillDownDataSource = () => {
        states.drillDownDataSource = null
        states.dataGridJsonLote = null
        setStates({...states, cellDescriptionArray: []})
        repaintPivotGrid()
    }

    const [dataSource, setDataSource] = useState()

    const getDataSource = (resultViewStructure) => {
        let fieldsArray = {
            fields: [],
            store: props.resultViewDataSet,
            retrieveFields: false
        }
        for(let count = 0; count <= resultViewStructure[0].view.pivotGridProperties.fieldsDefs.length - 1; count++) {
            let fieldFormat = {}
                if (resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].fieldType==='money') {
                    fieldFormat = { 
                        style: 'currency', currency: 'BRL', useGrouping: true, minimumSignificantDigits: 2, 
                        type: "fixedPoint", precision: 2
                    };
                } 
                fieldsArray.fields.push({
                    caption: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].fieldCaption,
                    dataField: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].fieldName,
                    dataType: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].fieldType,
                    summaryType: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].pivotGridSummaryType,
                    area: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].pivotGridFieldArea,
                    displayFolder: resultViewStructure[0].view.pivotGridProperties.fieldsDefs[count].displayFolder,
                    format: fieldFormat,
                    headerFilter: {
                        allowSearch: true
                      }
                  })  
            }
        return fieldsArray
    }

    const openBatch = () => {
        //estado carregando true mostrando um load menor e
        // desativando o botão 
        setStates({...states, loadIndicatorOpenBatchVisible: true})

        let observacoes = ""
        for( let a = 0; a <= states.cellDescriptionArray.length-1;a++){
            observacoes = observacoes + states.cellDescriptionArray[a]+"\n"
        }
        //essas linhas pegam a data e hora no momento que chama a função
        let stringDate = new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 11).concat(stringDate.slice(11, 19))
        let json = {  
            _descricao: `Lote aberto por ${userName}`,
            _observacoes: `${observacoes}`,
            _liberado: 1,
            _data: stringDateResult,
            _funcionarioId: relationId
        }

        httpRequest("POST", 
                requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                    {
                    "entityName": "_lote",
                    "applicationId": applicationID,
                    "relationId": relationId,
                    "json": json
                })
                .then((sender) => {
                    states.loteId = JSON.parse(sender).idValue
                    let jsonLoteItem = []
                    for( let b=0;b<=states.dataGridJsonLote.length-1;b++){
                        jsonLoteItem.push({
                            "_loteId": states.loteId,
                            "_tituloId": states.dataGridJsonLote[b]._tituloid
                        })
                    }

                    httpRequest("POST", 
                            requestsApi.insertRecordFromJson, requestsApi.basicAuth, 
                                {
                                "entityName": "_loteItem",
                                "json": jsonLoteItem,
                                "applicationId": applicationID,
                                "relationId": relationId
                            })
                            .then((sender) => {
                                toastConfiguration(true, "success", "Lote gravado")
                                //trocar estado do componente para false
                                setStates({...states, loadIndicatorOpenBatchVisible: true})
                                clearDrillDownDataSource()
                                //vai precisar abrir o record form em outra aba
                                createRecordForm()
                            })
                            .catch((error)=>{
                                if(error.data.error) {
                                    // hideLoadPanel()
                                    setMessageError(error.data.error)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: `Erro: ${error.status} ` + `(${error.statusText})` 
                                    })
                                }
                                else {
                                    // hideLoadPanel()
                                    setMessageError(error.statusText)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: `Erro: ${error.status} ` + `(${error.statusText})` 
                                    })
                                } 
                            })

                })
                .catch((error)=>{
                    if(error.data.error) {
                        // hideLoadPanel()
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: `Erro: ${error.status} ` + `(${error.statusText})` 
                        })
                    }
                    else {
                        // hideLoadPanel()
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: `Erro: ${error.status} ` + `(${error.statusText})` 
                        })
                    } 
                })
    }

    const RenderRecordFormPopup = memo(() => {
        //lembrar de alimentar pelo menos o entityStructureRecordFormPopupState
        return(
            <RecordFormPopup
                type={"view"}
                key={Math.random()}
                entityName="_lote"
                idValue={states.loteId}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
            />
        );
    })

    const pivotGridRefFunction = (sender) => {
        if(_pivotGrid.current) {
            _pivotGrid.current.instance.beginUpdate()
            _pivotGrid.current.instance.bindChart(_chart.current.instance, {
                dataFieldsDisplayMode: 'splitPanes',
                alternateDataFields: false
              })
            _pivotGrid.current.instance.endUpdate()
        }

    }

    const repaintPivotGrid = () => {
        _pivotGrid.current.instance.beginUpdate()
        _pivotGrid.current.instance.repaint()
        _pivotGrid.current.instance.endUpdate()
    }

    const setDataAreaVisible = (e) =>{
        setStates({
            ...states,
            dataAreaVisible: e.value
          });
    }
    
    const openMenuContextFunction = (e) =>{
        // if(e.target === "content" && states.selectedItensCount > 0){
        //     if (e.row.rowType === "data") {
                e.items = [{
                    text: "Visualizar registros da célula"
                    ,icon: "info"
                    ,onItemClick: () => {
                        // e.component.editRow(e.row.rowIndex);
                        onCellClick(e)
                    }
                },
                {
                    text: "Adicionar à seleção"
                    ,icon: "plus"
                    ,disabled: props.resultViewDataSet[0]["_tituloid"] ? false : true
                    ,onItemClick: ()=> {
                        onCellClick(e, "Gravar")
                    }
                }];
        //     }
        // }
    }

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: errorPopupOnHiding
    }

    const onSelectionChangedDataGridFunction = (e) => {
        setStates({
            ...states,
            selectedItensCount: e.component.getSelectedRowsData().length,
            selectedItens: e.component.getSelectedRowsData()
        })
    }

    const selectAllItensDataGrid = () => {
        _dataGridPopup.current.instance.selectAll()
    }

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    return(
        <div> 
            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />

            <Popup 
                visible={states.errorPopupVisible}
                onHiding={errorPopupOnHiding}
                onShown={errorPopupOnShown}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={"Atenção!"}
                width={300}
                height={280}
                resizeEnabled={true}
                >
                {/* <p>
                    {states.errorMessage}
                </p> */}
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {states.errorMessage &&
                    <p>{states.errorMessage}</p>
                }
            </Popup>
            {resultViewStructure  ? (
                <div>
                    {states.recordFormPopupRender &&
                        <RenderRecordFormPopup 
                            key={Math.random()}
                            entityName="_lote"
                        />
                    }
                    {resultViewStructure.length > 0 &&
                    <>
                        {/* <LoadPanel                        
                            //showIndicator={loadingPanelState.showIndicator}
                            //visible={loadingPanelState.loadPanelVisible}
                            // shading={loadingPanelState.shading}
                            //showPane={loadingPanelState.showPane}
                        /> */}
                        
                        <div className="div-gridResultView">
                        <React.Fragment>
                            <div className="selectbox-chart-type">
                                <div style={{marginBottom: "0.5em"}}>Tipo do Gráfico: </div>
                                <SelectBox
                                    // value={states.selectedItemChartType}
                                    width={200}
                                    dataSource={[
                                        "Linhas",
                                        "Barras",
                                        "Área",
                                    ]}
                                    onSelectionChanged={(item) => {
                                        if(item.selectedItem === "Linhas") {
                                            setStates({...states, chartType: "line"})
                                        }
                                        else if (item.selectedItem === "Barras") {
                                            setStates({...states, chartType: "bar"})
                                        }
                                        else if (item.selectedItem === "Área") {
                                            setStates({...states, chartType: "area"})
                                        }
                                        
                                    }}
                                    defaultValue={defaultTypeOfChart(props.getViewStructure?.typeOfChart)}
                                ></SelectBox>
                            </div>
                            <div style={{padding:"0.5em", marginTop: "1.5em", marginBottom: "1em"}}>
                                <Chart 
                                    ref={_chart}>
                                    <Size height={300} />
                                    <Tooltip enabled={true} />
                                    <CommonSeriesSettings 
                                        type={states.chartType} 
                                    />
                                    <AdaptiveLayout width={600} />
                                </Chart>
                                
                            </div>
                            <div className="tab-panel-pivot">
                                <TabPanel
                                >
                                    <Item 
                                        title={"Tabela dinâmica"} 
                                        key={1}
                                        // entityName={senderDetailEntity.entityName}
                                        // keyFieldName={senderDetailEntity.keyFieldName}
                                    >
                                    <div className="options">
                                            <div>
                                                <CheckBox 
                                                    id="show-data-fields"
                                                    value={states.dataAreaVisible}
                                                    onValueChanged={setDataAreaVisible}
                                                    text="Visualizar cabeçalho das colunas e linhas" 
                                                />
                                            </div>
                                        </div>
                                        <div className="div-pivot-grid">
                                            <PivotGrid
                                                id="pivotgrid"
                                                dataSource={dataSource}
                                                allowSortingBySummary={true}
                                                allowFiltering={true}
                                                showBorders={true}
                                                showColumnTotals={true}
                                                showColumnGrandTotals={states.showColumnGrandTotals}
                                                showRowTotals={true}
                                                showRowGrandTotals={true}
                                                allowSorting={true}
                                                allowExpandAll={true}
                                                onContentReady={pivotGridRefFunction}
                                                onCellPrepared={onCellPreparedFunctionColor}
                                                onCellClick={onCellClick}
                                                // onOptionChanged={repaintFunction}
                                                dataFieldArea={states.dataFieldArea}
                                                onContextMenuPreparing={openMenuContextFunction}
                                                ref={_pivotGrid}
                                            >
                                                <HeaderFilter
                                                    allowSearch={true}
                                                    showRelevantValues={true}
                                                    width={300}
                                                    height={400}
                                                />
                                                <FieldPanel
                                                    showFilterFields={states.dataAreaVisible}
                                                    showDataFields={states.dataAreaVisible}
                                                    showColumnFields={states.dataAreaVisible}
                                                    showRowFields={states.dataAreaVisible}
                                                    allowFieldDragging={true}
                                                    visible={true}

                                                />
                                                <FieldChooser 
                                                    enabled={true} 
                                                    allowSearch={true}
                                                    height={500} 
                                                />
                                                <Scrolling 
                                                    rowRenderingMode='infinity'
                                                    showScrollbar='always'
                                                    useNative={true}
                                                    >
                                                </Scrolling>

                                            </PivotGrid>

                                            <Popup
                                                visible={states.drillDownPopupVisible}
                                                //minwidth={800}
                                                //minheight={660}
                                                fullScreen={true}
                                                title={states.drillDownPopupTitle} 
                                                onHiding={drillDownPopupOnHiding}
                                                onShown={drillDownPopupOnShown}
                                                resizeEnabled={true}
                                                dragEnabled={true}
                                                hideOnOutsideClick={false}
                                                
                                                >
                                            <ScrollView width='100%' height='100%' useNative={true}>
                                                <div style={{padding:"0", marginTop: "0em", border: "2.5px solid blue"}}>
                                                    <div style={{padding:"1em", marginTop: "0em", border: ""}}>
                                                        <DataGrid
                                                            keyExpr="keyexpr"
                                                            dataSource={states.drillDownDataSource}
                                                            focusedRowEnabled={true}
                                                            columnAutoWidth={true}
                                                            rowAlternationEnabled={true}
                                                            showBorders={true}
                                                            repaintChangesOnly={true}
                                                            showColumnHeaders={true}
                                                            columnHidingEnabled={true}
                                                            columnMinWidth={50}
                                                            columnResizingMode={"widget"}
                                                            allowColumnReordering={true}
                                                            allowColumnResizing={true}
                                                            onSelectionChanged={onSelectionChangedDataGridFunction}
                                                            ref={_dataGridPopup}
                                                        >
                                                            <ColumnChooser 
                                                                enabled={resultViewStructure[0].view.dataGridProperties.columnChooserEnabled===1} width={400} height={800} mode={"select"} allowSearch={true}
                                                            />
                                                            {/* <LoadPanel enabled={true} /> */}

                                                            <Scrolling 
                                                                rowRenderingMode='infinity'
                                                                showScrollbar='always'
                                                                useNative={true}
                                                                >

                                                            </Scrolling>

                                                            <Paging defaultPageSize={10} />

                                                            <Sorting
                                                                mode="multiple"
                                                            />
                                                            <GroupPanel
                                                                visible={resultViewStructure[0].view.dataGridProperties.groupPanelVisible===1}
                                                                allowColumnDragging={true}
                                                            />
                                                            <Pager
                                                                visible={resultViewStructure[0].view.dataGridProperties.pagerVisible===1}
                                                                allowedPageSizes={states.allowedPageSizes}
                                                                showPageSizeSelector={true}
                                                                showInfo={true}
                                                                showNavigationButtons={true} />
                                                            <SearchPanel
                                                                visible={false}
                                                                placeholder="Buscar"
                                                                searchVisibleColumnsOnly={true}
                                                                highlightSearchText={true}
                                                                highlightCaseSensitive={false}
                                                            />
                                                            <FilterRow 
                                                                visible={false}//{resultViewStructure[0].view.dataGridProperties.filterRowVisible===1} 
                                                            />
                                                            <HeaderFilter
                                                                allowSearch={true}
                                                                visible={resultViewStructure[0].view.dataGridProperties.headerFilterVisible===1}
                                                            />
                                                            <Selection 
                                                                id="select-all-mode"
                                                                mode="multiple"
                                                                selectAllMode="allPages"
                                                                showCheckBoxesMode="onClick"
                                                            />

                                                        {resultViewStructure[0].view.dataGridProperties.fieldsDefs.map(
                                                            (sender, index)=>{
                                                                if(sender.gridVisible === 1 || 
                                                                sender.gridVisibleForCustomization === 1){
                                                                    if(sender.editorType === "dxCheckBox") {
                                                                        // senderFieldNamesCheckBox.push(sender.fieldName)
                                                                        return(
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                // editorOptions={editorOptionsColumns}
                                                                                dataType={"boolean"}
                                                                                >
                                                                                
                                                                            </Column>
                                                                        )
                                                                    }
                                                                    else if (sender.editorType === "upfProgressBar") {
                                                                        return(
                                                                            
                                                                            <Column
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                dataType="number"
                                                                                format="percent"
                                                                                alignment="right"
                                                                                allowGrouping={false}
                                                                                cellRender={PercentageBar}
                                                                                cssClass="bullet"
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                allowEditing={sender.readOnly != 1}
    
                                                                            />
                                                                        )
                                                                    }
                                                                    //upfpoke => (p)endente (ok) e (e)rro
                                                                    else if (sender.editorType === "upfPoke") {
                                                                        return(
                                                                            
                                                                            <Column
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                // dataType="number"
                                                                                // format="percent"
                                                                                alignment="center"
                                                                                allowGrouping={false}
                                                                                cellRender={PokeComponent}
                                                                                // cssClass="bullet"
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                allowEditing={sender.readOnly != 1}
    
                                                                            />
                                                                        )
                                                                    }
    
                                                                    else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "datetime") {
                                                                        return(
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortDateShortTime"
                                                                            />
                                                                        )
                                                                    }
                                                                    else if (sender.editorType === "upfTime") {
                                                                        return(
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortTime"
                                                                            />
                                                                        )
                                                                    }
                                                                    else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "date") {
                                                                        return(
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortDate"
                                                                            />
                                                                        )
                                                                    }
                                                                    else if(sender.fieldType === "money"){
                                                                        return(
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                autoExpandGroup={false}
                                                                                visible={sender.gridVisible === 1}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization===1}
                                                                                // width={sender.fieldSize}
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                allowSearch={true}
                                                                                groupIndex={sender.groupIndex}
                                                                                // dataType={sender.dataType}
                                                                                // editorType={sender.editorType}
                                                                                format={{
                                                                                    style: 'currency', 
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                    else {
                                                                        return(
                                                                            <Column
                                                                                autoExpandGroup={false}
                                                                                visible={sender.gridVisible === 1}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization===1}
                                                                                // width={sender.fieldSize}
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                allowSearch={true}
                                                                                dataType={sender.fieldType}
                                                                                format={sender.fieldMask}
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        )
                                                        }
                                                        
                                                            <Summary>
                                                                <GroupItem
                                                                    column="id"
                                                                    summaryType="count"
                                                                    displayFormat="{0} itens" 
                                                                />
                                                            </Summary>
                                                            <SortByGroupSummaryInfo summaryItem="count" />
                                                        </DataGrid>
                                                    </div>
                                                </div>
                                                <div style={{padding:"0em", marginTop: "0.5em"}}>
                                                    <Button
                                                        width={200}
                                                        height={40}
                                                        text="Adicionar à seleção"
                                                        icon="plus"
                                                        type="success"
                                                        stylingMode="contained"
                                                        onClick={getDrillDownSelectedItens}
                                                    />
                                                </div>
                                                </ScrollView>
                                            </Popup>
                                        </div>
                                    
                                        {states.dataGridJsonLote && 
                                            <div style={{padding:"0.5em", marginTop:"0em"}}>
                                                <div style={{padding:"0.5em", marginTop:"2em", border:"solid 2px blue"}}>
                                                    <h3 style={{padding:"0", marginTop:"0"}}><i>Células Selecionadas:</i></h3>
                                                    <Button
                                                        width={200}
                                                        height={40}
                                                        text="Limpar seleção"
                                                        icon="trash"
                                                        type="danger"
                                                        stylingMode="contained"
                                                        onClick={clearDrillDownDataSource}
                                                    />
                                                    
                                                    <Button
                                                        width={200}
                                                        height={40}
                                                        style={{marginLeft: "1em"}}
                                                        text="Abrir Lote"
                                                        type="default"
                                                        stylingMode="contained"
                                                        icon="plus"
                                                        onClick={openBatch}
                                                    >
                                                        {/* <LoadIndicator 
                                                            className="button-indicator" 
                                                            visible={true} 
                                                        /> */}
                                                    
                                                    </Button>

                                                    {/* <LoadIndicator 
                                                            className="button-indicator" 
                                                            visible={states.loadIndicatorOpenBatchVisible} 
                                                        /> */}

                                                    {states.loadIndicatorOpenBatchVisible &&
                                                        <LoadCircular/>
                                                    }
                                                    
                                                    <DataGridViewForm  
                                                        resultViewDataSet={states.dataGridJsonLote}
                                                        id={props.id}
                                                        focusedRowEnabled={true}
                                                        caption={props.caption}
                                                        loteId={states.loteId}
                                                        cellDescriptionVisible={1}
                                                        telecob={props.telecob}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </Item>

                                </TabPanel>
                            </div>
                        </React.Fragment>
                        </div>
                    </>    
                    }
                </div>
            ):( <Loading/>)}
        </div>
    );
}
export default PivotGridViewForm;