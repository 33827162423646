const { createContext, useContext, useState } = require("react");

const IndexTabPanelMain = createContext();

export const IndexTabPanelMainProvider = ({ children }) => {
  const [indexTabPanelMain, setIndexTabPanelMain]= useState(0);

  return (
    <IndexTabPanelMain.Provider value={{ indexTabPanelMain, setIndexTabPanelMain }}>
      {children}
    </IndexTabPanelMain.Provider>
  );
};

export const useIndexTabPanelMain = () => useContext(IndexTabPanelMain);