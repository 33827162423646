import { useState, useEffect, useCallback, useRef } from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import ProgressBar from './ProgressBar'
import Card from './Card'
import ColorBox from 'devextreme-react/color-box';
import TextBox from 'devextreme-react/text-box';
import ScrollView from 'devextreme-react/scroll-view';
import FileUploader from 'devextreme-react/file-uploader';
import { LoadPanel } from 'devextreme-react/load-panel';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Toast } from 'devextreme-react/toast';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useTabPanelItems } from '../../Context/TabPanelItems';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import UpfPopup from '../../CardComponents/UpfPopup';
import Loading from '../../CardComponents/Loading';
import './style.css'

const PortalCustomForm = (props) => {
    console.log(props)
    const {requestsApi, setRequestsApi} = useRequestsApi()


    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()


    const _toolbarRef = useRef(null)
    

    const popups = {
        isVisible: false,
        message: 'error',
    };

    const [dataEntity, setDataEntity] = useState()
    const [idValue, setIdValue] = useState(false)
    
    const [messageError, setMessageError] = useState()
    const [popup, setPopup] = useState(popups);
    const [modeEditForm, setModeEditForm] = useState(false)

    const [descricaoTemplateState, setDescricaoTemplateState] = useState("")

    const [coresProgressBarState, setCoresProgressBarState] = useState({
        corFundo:"#e03f3f",
        corTexto:"#ffffff",
        corBarraPendente:"#11e00d",
        corBarraConcluida:"#0af5ed",
        corNumeroConcluido:"#ff00ff",
    })
    // const [estruturaState, setEstruturaState] = useState()

    const hidePopup = () => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    };

    const closeButtonPopup = {
        text: 'Ok',
        onClick: hidePopup,
    };

    //---------TOAST----------//
    //Estados
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })

    //Funções
    const onHiding = useCallback(() => {
        hideLoadPanel()
        modeEditOff()
        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }, [toastConfig])
    
    const [stateModeEdit, setStateModeEdit] = useState(false);
    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        })
    }, [toastConfig])

    //---------TOAST----------//

    const [coresCardState, setCoresCardState] = useState({
        corFundo:"#e03f3f",
        corTexto:"#ffffff",
        corBotao:"#11e00d",
    })

    const [coresGeralState, setCoresGeralState] = useState({
        corFundo:"#e03f3f",
        corTexto:"#ffffff",
    })

    const uploadHeaders = {
        "Authorization": "Basic " + btoa(`${requestsApi.basicAuth}`),        
        "applicationId": applicationID,
        "objectId": props.idValue,
        "relationId": relationId,
    }

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    const modeEditOn = useCallback(() => {
        setModeEditForm(true)
    }, [])

    const modeEditOff = useCallback(() => {
        setModeEditForm(false)
    }, [])

    const [carregouDadosState, setCarregouDadosState] = useState(false)

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--cor-barra-pendente', coresProgressBarState.corBarraPendente)
        root.style.setProperty('--cor-barra-concluida', coresProgressBarState.corBarraConcluida)
        root.style.setProperty('--cor-numeros', coresProgressBarState.corNumeroConcluido)

        root.style.setProperty('--cor-fundo-card', coresCardState.corFundo)
        root.style.setProperty('--cor-texto-card', coresCardState.corTexto)
        root.style.setProperty('--cor-botao', coresCardState.corBotao)

        if(!dataEntity){
            if(props.idValue || idValue){
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                    "applicationId": applicationID,
                    "entityName": props.entityName,
                    "fieldName": 'id',
                    "expressao": props.idValue ? props.idValue : idValue
                })
                .then((sender) => {
                    const resultGetDataEntityJson = JSON.parse(sender)
    
                    const dataEntityRecords = resultGetDataEntityJson?.masterEntity?.[0]?.records?.[0]
                    
                    setDataEntity(dataEntityRecords)
    
                    setCoresProgressBarState({
                        corFundo: dataEntityRecords ? dataEntityRecords._corbarrafundo : "#e03f3f",
                        corTexto: dataEntityRecords ? dataEntityRecords._corbarratexto : "#ffffff",
                        corBarraPendente: dataEntityRecords ? dataEntityRecords._corbarrapendente : "#11e00d",
                        corBarraConcluida: dataEntityRecords ? dataEntityRecords._corbarraconcluida : "#0af5ed",
                        corNumeroConcluido: dataEntityRecords ? dataEntityRecords._corbarranumeros : "#ff00ff",
                    })
    
                    setCoresCardState({
                        corFundo: dataEntityRecords ? dataEntityRecords?._corcardfundo : "#e03f3f",
                        corTexto: dataEntityRecords ? dataEntityRecords?._corcardtexto : "#ffffff",
                        corBotao: dataEntityRecords ? dataEntityRecords?._corcardbotao : "#11e00d",
                    })
    
                    setCoresGeralState({
                        corFundo: dataEntityRecords ? dataEntityRecords?._corgeralfundo : "#e03f3f",
                        corTexto: dataEntityRecords ? dataEntityRecords?._corgeraltexto : "#ffffff",
                    })
    
                    setDescricaoTemplateState(dataEntityRecords?._descricao)
                    // if(resetFormData.current){
                    //     resetFormData.current.instance.repaint()
                    //     console.log(resetFormData)
                    // }
                    // setLoadedContentState(true)
                    setCarregouDadosState(true)
                })
                .catch((error) => {
                    console.log(error)
                    if (error?.data?.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError("Ocorreu um erro! Tente novamente mais tarde.")
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
            }
            else if(props.modeEditOn){
                modeEditOn()
                setCarregouDadosState(true)
            }
        }
        
    }, [coresProgressBarState, coresCardState])

    const onClickRefreshButton = (insertIdValueParam) => {
        showLoadPanel()

        if (insertIdValueParam || props.idValue || idValue) {
            modeEditOff()
            httpRequest('POST',requestsApi.getDataEntity, requestsApi.basicAuth,
            {
                "applicationId": applicationID
                , "entityName": props.entityName
                , "fieldName": 'id'
                , "expressao": props.idValue ? props.idValue : (insertIdValueParam ? insertIdValueParam : idValue)
            })
            .then(senderGetDataEntity => {
                const resultGetDataEntityJson = JSON.parse(senderGetDataEntity);

                const dataEntityRecords = resultGetDataEntityJson?.masterEntity?.[0]?.records?.[0]

                setDataEntity(dataEntityRecords)

                setCoresProgressBarState({
                    corFundo: dataEntityRecords ? dataEntityRecords._corbarrafundo : "#e03f3f",
                    corTexto: dataEntityRecords ? dataEntityRecords._corbarratexto : "#ffffff",
                    corBarraPendente: dataEntityRecords ? dataEntityRecords._corbarrapendente : "#11e00d",
                    corBarraConcluida: dataEntityRecords ? dataEntityRecords._corbarraconcluida : "#0af5ed",
                    corNumeroConcluido: dataEntityRecords ? dataEntityRecords._corbarranumeros : "#ff00ff",
                })

                setCoresCardState({
                    corFundo: dataEntityRecords ? dataEntityRecords?._corcardfundo : "#e03f3f",
                    corTexto: dataEntityRecords ? dataEntityRecords?._corcardtexto : "#ffffff",
                    corBotao: dataEntityRecords ? dataEntityRecords?._corcardbotao : "#11e00d",
                })


                setCoresGeralState({
                    corFundo: dataEntityRecords ? dataEntityRecords?._corgeralfundo : "#e03f3f",
                    corTexto: dataEntityRecords ? dataEntityRecords?._corgeraltexto : "#ffffff",
                })

                setDescricaoTemplateState(dataEntityRecords?._descricao)

                modeEditOff()
                hideLoadPanel()
            })
            .catch((error) => {
                console.log(error)
                hideLoadPanel()
                if (error?.data?.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
            .then(() => {
                modeEditOff()
                hideLoadPanel()
            })
        }
    }

    const recordInsertUpdateJson = () => {
        // showLoadPanel()
        console.log(dataEntity)
        //condição para gravar alterações em um item, já existente, vindo do RecordForm principal
        if (props.idValue) {
            httpRequest("POST",
                requestsApi.updateRecordFromJson, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "json": {
                        id: dataEntity.id,
                        _corbarraconcluida: coresProgressBarState.corBarraConcluida,
                        _corbarrafundo: coresProgressBarState.corFundo,
                        _corbarranumeros: coresProgressBarState.corNumeroConcluido,
                        _corbarrapendente: coresProgressBarState.corBarraPendente,
                        _corbarratexto: coresProgressBarState.corTexto,
                        _corcardbotao: coresCardState.corBotao,
                        _corcardfundo: coresCardState.corFundo,
                        _corcardtexto: coresCardState.corTexto,
                        _corgeralfundo: coresGeralState.corFundo,
                        _corgeraltexto: coresGeralState.corTexto,
                        _descricao : descricaoTemplateState
                    },
                    "applicationId": applicationID,
                    "relationId": relationId
                })
                .then((sender) => {
                    const senderUpdateRecordFromJson = JSON.parse(sender)
                    toastConfiguration(true, 'success', "Registro atualizado com sucesso!")
                    // onClickRefreshButton()
                })
                .catch((error) => {
                    hideLoadPanel()
                    if (error.data.error) {
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                    else {
                        setMessageError(error.statusText)
                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                    }
                })
        }
        //condição para gravar um novo item, quando for chamado pelo botão novo no query ou no record
        else {
            httpRequest("POST",
                requestsApi.insertRecordFromJson, requestsApi.basicAuth,
                {
                    "entityName": props.entityName,
                    "json":
                    {
                        _corbarraconcluida: coresProgressBarState.corBarraConcluida,
                        _corbarrafundo: coresProgressBarState.corFundo,
                        _corbarranumeros: coresProgressBarState.corNumeroConcluido,
                        _corbarrapendente: coresProgressBarState.corBarraPendente,
                        _corbarratexto: coresProgressBarState.corTexto,
                        _corcardbotao: coresCardState.corBotao,
                        _corcardfundo: coresCardState.corFundo,
                        _corcardtexto: coresCardState.corTexto,
                        _corgeralfundo: coresGeralState.corFundo,
                        _corgeraltexto: coresGeralState.corTexto,
                        _descricao : descricaoTemplateState
                    },
                    "applicationId": applicationID,
                    "relationId": relationId
                })
            .then((sender) => {
                const senderInsertRecordFromJson = JSON.parse(sender)

                toastConfiguration(true, 'success', "Registro criado com sucesso!")

                // setInsertIdValue(senderInsertRecordFromJson.idValue)// no lugar do consol.log colocar setInsertIdValue
                setIdValue(senderInsertRecordFromJson.idValue)
                // if(props.insertId?.value === null){
                //     props.insertId.value = senderInsertRecordFromJson.idValue
                // }

                onClickRefreshButton(senderInsertRecordFromJson.idValue)
                
                
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                if (error.data.error) {
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
            .then(() => {
                //local pra mexer no refresh depois de um insert
                // onClickRefreshButton()
                // hideLoadPanel()
                modeEditOff()
            })

        }

    }

    const createForm = () => {

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: props.entityName,
            applicationId: applicationID
        })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)

                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        'title': props.entityStructure?.[0].masterEntity.caption
                        , 'component':
                            <ScrollView useNative={true}>
                                <PortalCustomForm
                                    id={Math.random()}
                                    entityName={props.entityName}
                                    modeEditOn={true}
                                    insertId={{value: null}}
                                    entityStructure={resultGetEntityStructureJson}
                                />
                            </ScrollView>
                        , 'text': Math.random()
                        , 'icon': 'edit'
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            })
            .catch((error) => console.log(error))
    }

    const addButtonOptions = {
        icon: 'add',
        hint: 'Incluir um novo registro',
        // disabled: objectPermissionsState && !objectPermissionsState._insert,
        onClick: createForm,
    }

    const recordButtonOptions = {
        icon: 'check',
        useSubmitBehavior: true,
        validationGroup: `groupName${props.id}`,
        onClick: recordInsertUpdateJson,
    }

    const refreshButtonOptions = {
        icon: 'refresh',
        hint: 'Atualizar dados do servidor',
        onClick: () => onClickRefreshButton(),
    }

    const [confirmDelete, setConfirmDelete] = useState({ isVisible: false, message: "Atenção" });

    const closeButtonHandler = useCallback(() => {
        const Handle = [...tabPanelItems];
        // const index = Handle.indexOf(tabPanelItems);

        Handle.splice(indexTabPanelMain, 1);
        setTabPanelItems(Handle);

        if (tabPanelItems.length !== 0) {
            setIndexTabPanelMain(tabPanelItems.length - 2)
        }
    }, [tabPanelItems])

    const recordDeleteJson = () => {
        if (props.idValue || idValue) {
            httpRequest('POST', requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
            {
                "applicationId": applicationID,
                "entityName": props.entityName,
                "fieldName": 'id',
                "expressao": props.idValue ? props.idValue : idValue,
                "relationId": relationId
            })
            .then((response) => {
                const resultDelete = JSON.parse(response)
                toastConfiguration(true, 'success', resultDelete.mensagem)
                closeButtonHandler()
                //função para fechr ultima tab criada, precisa pega o item para fazer indeOf
                //fechar o form atual
                // closeButtonHandler(tabPanelItems[tabPanelItems.length-1])
                hidePopupDelete()
            })
            .catch((error) => {
                if (error.data.error) {
                    hideLoadPanel()
                    setMessageError(error.data.error)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
                else {
                    hideLoadPanel()
                    setMessageError(error.statusText)
                    setPopup({
                        ...popup
                        , isVisible: true
                        , message: "Atenção!"
                    })
                }
            })
        } 
        hidePopupDelete()
    }



    const deleteButtonOptions = {
        icon: 'close',
        // disabled: objectPermissionsState && !objectPermissionsState._delete,
        onClick: () => setConfirmDelete({ ...confirmDelete, isVisible: true })
    }

    const hidePopupDelete = () => {
        setConfirmDelete({ ...confirmDelete, isVisible: false })
    }
    const closeDeleteRecord = {
        text: "Cancelar"
        , onClick: hidePopupDelete
    }
    const confirmDeleteRecord = {
        text: "Ok"
        , onClick: recordDeleteJson
    }

    const [revertPopupVisible, setRevertPopupVisible] = useState(false)


    const revertButtonOptions = {
        icon: 'revert',
        hint: 'Desfazer alterações no registro',
        // onClick: () => {
        //     setRevertPopupVisible(true)
        // }
    }

    const [clearTabRuleState, setClearTabRuleState] = useState()
    //estado e função do fecharAbasPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hideFecharAbas = () => {
        setVisible(false)
    }

    //configuração das opçoes de abas
    const clearAllTabs = {
        text: "Fechar todas as abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("clearAllTabs")
            }
        }
    }

    const clearTabsRight = {
        text: "Fechar abas a direita"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas a direita da atual serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("clearTabsRight")
            }
        }
    }

    const keepThisTab = {
        text: "Fechar outras abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessage("Todas as abas, com excessão da atual, serão fechadas! Deseja continuar?")
                setVisible(true)
                setClearTabRuleState("keepThisTab")
            }
        }
    }


    return(
        <div style={{height: "81vh"}}>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>

            <Popup
                visible={confirmDelete.isVisible}
                onHiding={hidePopupDelete}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={confirmDelete.message}
                width={400}
                height={250}
                resizeEnabled={true}
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={confirmDeleteRecord}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeDeleteRecord}
                />
                <p>
                    Você realmente deseja apagar este registro?
                </p>
            </Popup>

            {/* FECHAR ABAS */}
            <UpfPopup
                typePopup="closeTabs"
                popupVisibleState={visible}
                popupVisibleFunctionChangeState={setVisible}
                message={message}
                clearTabRuleState={clearTabRuleState}
                toolbarRef={_toolbarRef}
            />


            {/* POPUP DO REVERT */}
            {/* <Popup
                visible={revertPopupVisible}
                onHiding={closeRevertPopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Atenção!"
                width={600}
                height={280}
                resizeEnabled={true}
                id="popupForm"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={confirmRevertButtonPopup}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelRevertButtonPopup}
                />
                <p>As alterações serão perdidas! Deseja cotinuar?</p>

            </Popup> */}

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={3000}
                height={60}
            />

            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={"Atenção!"}
                width={400}
                height={300}
                resizeEnabled={true}
                id="popupForm"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                {messageError &&
                    <p>{messageError}</p>
                }
            </Popup>

            {carregouDadosState ? 
                (
                    <>
                    <LoadPanel
                        style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={loadingPanelState.loadPanelVisible}
                        // visible={true}
                        showIndicator={loadingPanelState.showIndicator}
                        shading={loadingPanelState.shading}
                        showPane={loadingPanelState.showPane}
                    />

                            <Toolbar
                                className={"div-toolbar"}
                                ref={_toolbarRef}
                                height={70}
                            >
                                {!modeEditForm && 
                                    <Item
                                        location="before"
                                        widget="dxButton"
                                        options={addButtonOptions}
                                    />
                                }

                                {modeEditForm && 
                                    <Item
                                        location="before"
                                        widget="dxButton"
                                        options={recordButtonOptions} 
                                        // disabled={!modeEditForm}
                                    />
                                }

                                {/* {modeEditForm && 
                                    <Item
                                        location="before"
                                        widget="dxButton"
                                        options={revertButtonOptions}
                                    />
                                } */}

                                {!modeEditForm && 
                                    <Item
                                        location="before"
                                        widget="dxButton"
                                        options={refreshButtonOptions}
                                        disabled={modeEditForm}

                                    />
                                }

                                {!modeEditForm && 
                                    <Item
                                        location="before"
                                        widget="dxButton"
                                        options={deleteButtonOptions}
                                    />
                                }

                                {/* OPÇÕES DAS ABAS */}
                                {!props.popupRecordForm && !props.teleCobranca &&
                                    <Item
                                        location="after"
                                        locateInMenu="always"
                                        widget="dxButton"
                                        options={clearAllTabs}
                                    />
                                }

                                {!props.popupRecordForm && !props.teleCobranca &&
                                    <Item
                                        location="after"
                                        locateInMenu="always"
                                        widget="dxButton"
                                        options={clearTabsRight}
                                        disabled={tabPanelItems.length === indexTabPanelMain + 1}
                                    />
                                }

                                {!props.popupRecordForm && !props.teleCobranca &&
                                    <Item
                                        location="after"
                                        locateInMenu="always"
                                        widget="dxButton"
                                        options={keepThisTab}
                                    />
                                }
                            </Toolbar>

                            <div className="group-item">
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Descrição :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <TextBox
                                        value={descricaoTemplateState}
                                        onValueChanged={(e) => {
                                            if(e?.value){
                                                setDescricaoTemplateState(e.value)
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="group-item">
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor fundo :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresProgressBarState.corFundo}
                                        onValueChanged={(e) => {
                                            console.log(e)
                                            modeEditOn()
                                            if(e?.value){
                                                setCoresProgressBarState({...coresProgressBarState,
                                                    corFundo: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                Cor Texto :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresProgressBarState.corTexto}
                                        onValueChanged={(e) => {
                                            console.log(e)
                                            modeEditOn()
                                            if(e?.value){
                                                setCoresProgressBarState({...coresProgressBarState,
                                                    corTexto: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                Cor barra pendente :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresProgressBarState.corBarraPendente}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresProgressBarState({...coresProgressBarState,
                                                    corBarraPendente: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                Cor barra concluida :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresProgressBarState.corBarraConcluida}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresProgressBarState({...coresProgressBarState,
                                                    corBarraConcluida: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                Cor números :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresProgressBarState.corNumeroConcluido}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresProgressBarState({...coresProgressBarState,
                                                    corNumeroConcluido: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <ProgressBar
                                coresProgressBarState={coresProgressBarState}
                            />

                            {/* <div className='dx-card'>
                                <h2 style={{color: coresProgressBarState.corTexto}}>Negocie suas dívidas em apenas 3 passos!</h2>
                                <div className="div-intro-text">
                                    <div className="number-intro-text">1</div> 
                                    <div style={{color: coresProgressBarState.corTexto}}>Identifique-se com CPF ou CNPJ</div>
                                </div> <br/>
                                <div className="div-intro-text">
                                    <div className="number-intro-text">2</div> 
                                    <div style={{color: coresProgressBarState.corTexto}}>Escolha a melhor opção</div>
                                </div> <br/>
                                <div className="div-intro-text">
                                    <div className="number-intro-text">3</div> 
                                    <div style={{color: coresProgressBarState.corTexto}}>Gere seu boleto e pronto!</div>
                                </div> <br/>
                            </div>

                            <ColorBox></ColorBox> */}

                        </div>

                        <div className="group-item">

                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor fundo cartão :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresCardState.corFundo}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresCardState({...coresCardState,
                                                    corFundo: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor botão :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresCardState.corBotao}
                                        onValueChanged={(e) => {
                                            console.log(e)
                                            modeEditOn()
                                            if(e?.value){
                                                setCoresCardState({...coresCardState,
                                                    corBotao: e.value
                                                })
                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                        // value={emailState}
                                        // onValueChanged={(e) => {
                                        //     setEmailState(e.value)
                                        //     console.log(e.value)
                                        // }}
                                    />
                                </div>

                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor texto :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresCardState.corTexto}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresCardState({...coresCardState,
                                                    corTexto: e.value
                                                })

                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                    />
                                </div>
                            </div>
                            <Card/>
                        </div>

                        <div className="group-item">
                        <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor fundo geral :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresGeralState.corFundo}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresGeralState({...coresGeralState,
                                                    corFundo: e.value
                                                })

                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="dx-field">
                                <div className="dx-field-label">
                                    Cor texto geral:
                                </div>
                                    
                                <div className="dx-field-value">
                                    <ColorBox
                                        value={coresGeralState.corTexto}
                                        onValueChanged={(e) => {
                                            modeEditOn()
                                            console.log(e)
                                            if(e?.value){
                                                setCoresGeralState({...coresGeralState,
                                                    corTexto: e.value
                                                })

                                            }
                                        }}
                                        onKeyDown={() => {
                                            modeEditOn()
                                        }}
                                        onPaste={() => {
                                            modeEditOn()
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="dx-field">
                                <div className="dx-field-label">
                                    Imagem fundo :
                                </div>
                                    
                                <div className="dx-field-value">
                                    <input 
                                        id="image_upload" 
                                        type="file" 
                                        onChange={(data) => {
                                            console.log(data)
                                            setTeste(data?.target?.value)
                                        }} />
                                    <img src={teste} alt=""/>
                                </div>
                            </div> */}

                            <div style={{backgroundColor: `${coresGeralState.corFundo}`, color: `${coresGeralState.corTexto}`, width: "300px", height: "100px", padding: "0.5em"}}>
                                Mensagem de texto de exemplo.
                            </div>
                        </div>
                    </>
                ) 
                : 
                (
                    <Loading/>
                )
            }
            
            </div>
        </div>
    )
}

export default PortalCustomForm;