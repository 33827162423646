import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    GroupItem,
    HeaderFilter,
    SearchPanel,
    Lookup,
    Paging,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    FilterRow,
    TotalItem
} from 'devextreme-react/data-grid';
import { useEffect, useState } from 'react';
import "./style.css"

const DataGridLog = (props) => {

    const [dataSourceStateFiltrado, setDataSourceStateFiltrado] = useState([])

    useEffect(() => {
        if(props?.idItemPai){
            const arraySubItems = []
            for(let c = 0; c <= props.dataSourceState.length - 1; c++){
                if(props.dataSourceState[c]?._objectid === props.idItemPai){
                    arraySubItems.push(props.dataSourceState[c])
                }
            }
    
            console.log(arraySubItems)
    
            setDataSourceStateFiltrado(arraySubItems)
        }
        else{
            setDataSourceStateFiltrado(props.dataSourceState)
        }

    }, [props.dataSourceState])

    console.log(props)

    return(
        <DataGrid
            keyExpr="fieldname"
            className="datagrid-log"
            dataSource={dataSourceStateFiltrado}
            columnAutoWidth={true}
            showBorders={true}
            rowAlternationEnabled={true}
            repaintChangesOnly={true}
            showColumnHeaders={true}
            columnHidingEnabled={true}
            columnMinWidth={50}
            contextMenuEnabled={true}
            columnResizingMode={"widget"}
            allowColumnReordering={true}
            focusedRowEnabled={false} //tava dando erro Row focusing requires the key field to be specified 
            allowColumnResizing={true}
        >
            <HeaderFilter
                allowSearch={true}
                visible={true}
            />
            {/* <SearchPanel visible={true} /> */}
            <Scrolling
                // rowRenderingMode='virtual'
                // showScrollbar='always'
                useNative={true}
                rowRenderingMode="standard"
                mode="virtual"
            >
            </Scrolling>
            <Column
                dataField="funcionario"
                caption="Funcionário"
                allowSearch={true}
            />
            <Column
                dataField="_descricao"
                caption="Tipo"
                allowSearch={true}
            />
            <Column
                dataField="date_time_insert"
                caption="Data"
                defaultSortOrder="desc"
                defaultSortIndex={1}
                dataType={"datetime"}
                format="shortDateShortTime"
            />
            <Column
                dataField="fieldname"
                caption="Campo"
                allowSearch={true}
            />
            <Column
                dataField="oldvalue"
                caption="De"
                allowSearch={true}
            />
            <Column
                dataField="newvalue"
                caption="Para"
                allowSearch={true}
            />
            
        </DataGrid>
    )
}

export default DataGridLog;