import DrawerMain from "../../Components/DrawerMain"
import TeleCob from "../../Components/TeleCob"
import { WebSocketFunction } from "../../webSocket/WebSocketFunction"
import { useState, useEffect, memo, useCallback } from 'react'
import { relationId, securityGroupId, ramal, loginName, applicationID } from "../../api/ApplicationID"
import { useBackendJson } from "../../Context/BackendJson"
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage"
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { FaUser, FaLock } from 'react-icons/fa'
import * as Yup from 'yup';
import { httpRequest } from "../../functions"
import { useRequestsApi } from "../../Context/RequestsApi"
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import "./style.css"

const Main = (props) => {
    const { requestsApi } = useRequestsApi()
    const { backendJson } = useBackendJson()

    const [loginState, setLoginState] = useState(false)

    const [pageVisible, setPageVisible] = useState(false)

    const [paramsProcessamentoMensagemSocketState, setParamsProcessamentoMensagemSocketState] = useState()

    const [validationError, setValidationError] = useState();
    const [invalidMessage, setInvalidMessage] = useState(false)
    const [styleValidation, setStyleValidation] = useState({
        password: { border: 'solid 1.5px #8F8F8F' }
    });

    const [popupConfirPasswordVisibleState, setPopupConfirPasswordVisibleState] = useState(false)
    const [passwordState, setPasswordState] = useState('');

    useEffect(() => {
        if(backendJson && !loginState){
            console.log(backendJson)
            setLoginState(true)
        }
    }, [backendJson, loginState])

    //estado e função do UpfPopupMessage usuário não definido
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(true)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("Grupo de usuário não definido!")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
        window.location.href = ""
        sessionStorage.clear()
    }

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])

    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    
    const deslogarUsuario = () => {
        window.location.href = ""
        sessionStorage.clear()
    }

    const pedirDadosUsuario = () => {
        setPopupConfirPasswordVisibleState(true)
    }

    const confirmarDadosUsuario = useCallback(async () => {
        showLoadPanel()
        try {
            const formData = {
                password: passwordState,
                applicationId: applicationID
            }

            document.getElementsByClassName("form-confirm-login").onsubmit = false
            const schema = Yup.object().shape({
                password: Yup.string().required("Senha obrigatória"),
            });
            await schema.validate(formData, {
                abortEarly: false,
            });

            setValidationError({})
            setStyleValidation({
                password: { border: 'solid 1.5px #8F8F8F' }
            })
           
            await httpRequest('POST', requestsApi.loginUserCRM, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "userName": loginName,
                "password": passwordState
            }).then(senderLoginUser => {
                setInvalidMessage(false)
                setPasswordState('')
                setPopupConfirPasswordVisibleState(false)
                hideLoadPanel()
            })
                .catch((error) => {
                    hideLoadPanel()
                    if (error?.data?.error) {
                        if(error?.data?.error === "Usuário ou senha inválido!"){
                            setInvalidMessage("Senha inválida!")
                            setStyleValidation({password: { border: '2px solid red' } })
                        }
                    } 
                    else {
                        setInvalidMessage("Não foi possível conectar ao servidor. Verifique sua conexão e tente novamente.")
                    }

                })
        } catch (error) {
            hideLoadPanel()
            if (error.message === "Senha obrigatória") {
                setValidationError({ password: error.message })
                setStyleValidation({ password: { border: '2px solid red' } })
            }
        }
    }, [passwordState])

    useEffect(() => {
        if(backendJson?.checarInatividadeMinutos){
            let intervaloTimer = setTimeout(() => {
                pedirDadosUsuario()
            }, backendJson?.checarInatividadeMinutos * 60 * 1000)

            const handleActivity = (event) => {
                if(!popupConfirPasswordVisibleState){
                    clearTimeout(intervaloTimer)
                    intervaloTimer = setTimeout(() => {
                        pedirDadosUsuario()
                    }, backendJson?.checarInatividadeMinutos * 60 * 1000)
                }
                else{
                    clearTimeout(intervaloTimer)
                }
                
            };

            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('keydown', handleActivity);

            return () => {
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('keydown', handleActivity);
            };
        }
    }, [backendJson]);

    const quitButtonOptions = {
        text: "Sair"
        ,type: "default"
        ,onClick: deslogarUsuario
    }
    const confirmButtonOptions = {
        text: "Confirmar"
        ,type: "default"
        ,onClick: confirmarDadosUsuario
    }

    return (
        <div className="main-page dx-theme-text-color dx-theme-background-color">
            {securityGroupId ?
            
                (
                <>
                    {pageVisible &&
                        <>
                            {sessionStorage.getItem("@EquipeID") ? 
                                (
                                    <TeleCob
                                        setParamsProcessamentoMensagemSocketState={setParamsProcessamentoMensagemSocketState}
                                        paramsProcessamentoMensagemSocket={paramsProcessamentoMensagemSocketState}
                                    />
                                ) 
                                : 
                                (
                                    <DrawerMain
                                        dataSourceLogin={props.dataSourceLogin}
                                        dataSourceLoginDashboard={props.dataSourceLoginDashboard}
                                    />
                                )
                            }
                        </>
                    }
                </>
                )
                :
                (
                    <UpfPopupMessage
                        hidePopup={hidePopupError}
                        visible={popupErrorVisibleState}
                        message={popupErrorMessageState}
                    />
                )
            }

            <LoadPanel
                style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            {popupConfirPasswordVisibleState === true &&
                <Popup
                    visible={popupConfirPasswordVisibleState}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title="Deslogado por inatividade"
                    resizeEnabled={false}
                    width={400}
                    height={300}
                >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={confirmButtonOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={quitButtonOptions}
                    />
                    <form className="form-confirm-login" action="">
                            {/* <div className="inputs"> */}
                        {validationError && validationError.password && (<>
                            <p
                                style={{ color: 'red', textAlign: 'center', fontSize: '14px', marginBottom: '-12px' }}
                            >{validationError.password}*</p>
                        </>)}
                        <div onBlur={() => setStyleValidation({ ...styleValidation, password: { border: 'solid 1.5px #8F8F8F' } })} onFocus={() => setStyleValidation({ ...styleValidation, password: { border: '2px solid #1663E8' } })} style={styleValidation.password} className="input-container div-input2">
                            <FaLock />
                            <input
                                name="password"
                                className="input-form"
                                placeholder="Senha"
                                type="password"
                                value={passwordState}
                                onChange={(e) => { setPasswordState(e.target.value) }} />
                        </div>
                        {invalidMessage &&
                            <div className="error-message-div">
                                <h5>{invalidMessage}</h5>
                            </div>
                        }
                    </form>
                </Popup>
            }
            
            {loginState &&
                <WebSocketFunction
                    jsonLogin={{
                        "acao":"LOGIN",
                        "ramal": ramal,
                        "relationId": relationId
                    }}
                    hostName={backendJson.api_host}
                    api_socket_port={backendJson.api_socket_port}
                    loginState={loginState}
                    setLoginState={setLoginState}
                    setPageVisible={setPageVisible}
                    paramsProcessamentoMensagemSocket={paramsProcessamentoMensagemSocketState}
                    setParamsProcessamentoMensagemSocketState={setParamsProcessamentoMensagemSocketState}
                />
            }
        </div>
    )
}

export default memo(Main);