const { createContext, useContext, useState } = require("react");

const WhatsAppChatUptadeGlobalState = createContext();

export const WhatsAppChatUptadeGlobalStateProvider = ({ children }) => {
  const [whatsAppChatUptadeGlobalState, setWhatsAppChatUptadeGlobalState] = useState({
      telefone: false
  })

  return (
    <WhatsAppChatUptadeGlobalState.Provider value={{ whatsAppChatUptadeGlobalState, setWhatsAppChatUptadeGlobalState }}>
      {children}
    </WhatsAppChatUptadeGlobalState.Provider>
  );
};

export const useWhatsAppChatUptadeGlobalState = () => useContext(WhatsAppChatUptadeGlobalState);