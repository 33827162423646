import useWebSocket from 'react-use-websocket';
import { getTokenLogin } from './getTokenLogin';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useState, useEffect, useRef } from 'react'
import { ramal } from '../api/ApplicationID';
import { processamentoMensagemSocket } from '../functions/processamentoMensagemSocket/processamentoMensagemSocket';
import { useTabPanelItems } from '../Context/TabPanelItems';
import { httpRequest } from '../functions';
import { useMainMenuJson } from "../Context/MainMenuJson";
import { useStatusDiscadorGlobalState } from '../Context/StatusDiscadorGlobalState';
import { useDataSourceLastMenssageSocketGlobalState } from '../Context/DataSourceLastMenssageSocketGlobalState';
import { useWhatsAppChatUptadeGlobalState } from '../Context/WhatsAppChatUptadeGlobalState'
import { useTelecobTabPanelItemsGlobalState } from '../Context/TelecobTabPanelItemsGlobalState';
import { useUpfFloatPopupMessageVisibleGlobalState } from '../Context/UpfFloatPopupMessageVisibleGlobalState';


export const WebSocketFunction = (props) => {
    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()
    const {statusDiscadorGlobalState, setStatusDiscadorGlobalState} = useStatusDiscadorGlobalState()
    const {dataSourceLastMenssageSocketGlobalState, setDataSourceLastMenssageSocketGlobalState} = useDataSourceLastMenssageSocketGlobalState()
    const {telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState} = useTelecobTabPanelItemsGlobalState()
    const {whatsAppChatUptadeGlobalState, setWhatsAppChatUptadeGlobalState} = useWhatsAppChatUptadeGlobalState()

    const [returnMessageUndefinedState, setReturnMessageUndefinedState] = useState(false)
    const [conectionErrorSocketState, setConectionErrorSocketState] = useState(false)
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems()
    const {mainMenuJson, setMainMenuJson} = useMainMenuJson();

    const fecharPopup = () => {
        setReturnMessageUndefinedState(false)
        // window.location.href = ""
        // sessionStorage.clear()
    }

    const closeButtonPopup = {
        text: 'ok'
        , icon: 'arrowright'
        , onClick: fecharPopup
    };

    const fecharSocketPopup = () => {
        setConectionErrorSocketState(false)
    }


    const closeButtonSocketPopup = {
        text: 'ok'
        , icon: 'arrowright'
        , onClick: fecharSocketPopup
    };

    //TESTE DE REQUISIÇÃO SOCKET
    let webSocketProtocol = 'ws:'
    if (window.location.protocol == 'https:'){
        webSocketProtocol = 'wss:'
    } 

    const clientRef = useRef(false);

    const checarStatus = () => {
        if(dataSourceLastMenssageSocketGlobalState?.socketLastMsgTime){
            sessionStorage.setItem("checandoStatus", "true")
            setInterval(() => {
                // console.log(clientRef)
                // console.log(clientRef?.current)
                // console.log(clientRef.current?.instance())
                const tempoAtual = new Date()
                const dataSourceLastMenssageSocket = JSON.parse(sessionStorage.getItem("dataSourceLastMenssageSocket"))
                // console.log(dataSourceLastMenssageSocket)
                // console.log(new Date(dataSourceLastMenssageSocket?.socketLastMsgTime))
                // console.log((tempoAtual.getTime() / 1000) - (dataSourceLastMenssageSocket?.socketLastMsgTime / 1000))

                // if(sessionStorage.getItem("conectado")){
                    if (((tempoAtual.getTime() / 1000) - (dataSourceLastMenssageSocket?.socketLastMsgTime / 1000)) >= 10){
                        // console.log("10s")
                        dataSourceLastMenssageSocketGlobalState.getWebSocket().send(JSON.stringify({"acao":"STATUS","status":""}))
                        setStatusDiscadorGlobalState({ativo: false, getWebSocket: dataSourceLastMenssageSocketGlobalState.getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
                    }
                    else if(((tempoAtual.getTime() / 1000) - (dataSourceLastMenssageSocket?.socketLastMsgTime / 1000)) >= 4){
                        // console.log("4s")
                        dataSourceLastMenssageSocketGlobalState.getWebSocket().send(JSON.stringify({"acao":"STATUS","status":""}))
                    }
                // }

            }, 1000)
        }
    }

    useEffect(() => {
        if(!sessionStorage.getItem("checandoStatus") && dataSourceLastMenssageSocketGlobalState?.socketLastMsgTime){
            checarStatus()
        }
    }, [dataSourceLastMenssageSocketGlobalState])

    const { lastJsonMessage, sendMessage, readyState, getWebSocket } = useWebSocket(`${webSocketProtocol}//${props.hostName}:${props.api_socket_port}`, {
        fromSocketIO: true,
    onOpen: () => {
        console.log(`conectou com sucesso`)
        console.log(props.jsonLogin)
        sendMessage(JSON.stringify(props.jsonLogin))

        setDataSourceLastMenssageSocketGlobalState({getWebSocket: getWebSocket})
    },
    onMessage: (sender) => {
        try{
            const mensagemJson = JSON.parse(sender.data)
            console.log(mensagemJson)
            // alert("mensagem nova socket")

            const socketLastMsgTime = new Date()

            const dataSourceLastMenssageSocket = {getWebSocket: getWebSocket, socketLastMsgTime: socketLastMsgTime.getTime()}

            sessionStorage.setItem("dataSourceLastMenssageSocket", JSON.stringify(dataSourceLastMenssageSocket))

            setDataSourceLastMenssageSocketGlobalState({getWebSocket: getWebSocket, socketLastMsgTime: socketLastMsgTime})

            if(mensagemJson?.status && mensagemJson.acao !== "STATUS"){
                if(mensagemJson?.descricao){
                    setStatusDiscadorGlobalState({ativo: true, texto: mensagemJson.status + " " + mensagemJson.descricao, getWebSocket: getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
                }
                else{
                    setStatusDiscadorGlobalState({ativo: true, texto: mensagemJson.status, getWebSocket: getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
                }
            }
            else{
                setStatusDiscadorGlobalState(
                    {
                        ...statusDiscadorGlobalState, 
                        ativo: true, 
                        getWebSocket: getWebSocket, 
                        loginMsg : JSON.stringify(props.jsonLogin)
                    })
            }
            

            if(mensagemJson.acao === "LOGIN"){
                getTokenLogin(mensagemJson, props.setPageVisible, setReturnMessageUndefinedState)
            }
            else if(mensagemJson.acao === "STATUS"){
                getWebSocket().send(JSON.stringify({
                    "acao":"STATUS",
                    "status":"ON"
                }))
            }
            else if(mensagemJson?.status === "LIGACAO" && mensagemJson?.ramal == ramal){ //&& mensagemJson?.callId?.length > 0){
                // console.log(mensagemJson)
                sessionStorage.setItem("mensagemSocket", JSON.stringify(mensagemJson))
                // sessionStorage.setItem("callIdLigacao", mensagemJson.callId);
            }
            else if(mensagemJson.acao === "PAUSA" && mensagemJson?.ramal === ramal){
                props.paramsProcessamentoMensagemSocket["mensagem"] = mensagemJson
                processamentoMensagemSocket(props.paramsProcessamentoMensagemSocket)
            }
            // else if(mensagemJson.acao === "WHATSAPP"){
            //     const abaMensagemAtual = telecobTabPanelItemsGlobalState?.find((e) => e.title == mensagemJson?._telefone)
            //     console.log(abaMensagemAtual)
            //     setWhatsAppChatUptadeGlobalState({
            //         ...whatsAppChatUptadeGlobalState,
            //         telefone: mensagemJson?._telefone
            //     })

            //     if(!abaMensagemAtual){
            //         setTelecobTabPanelItemsGlobalState([
            //             ...telecobTabPanelItemsGlobalState,
            //             {   
            //                 title: `${mensagemJson?._telefone}`
            //                 , icon: "WhatsAppCustomForm"
            //                 , badge: ""
            //                 , component: 
            //                 <ScrollView width='100%' height='100%'>
            //                     <WhatsAppCustomForm 
            //                         tituloId={mensagemJson?._tituloId}
            //                         telefone={mensagemJson?._telefone}
            //                         relationId={mensagemJson?._relationId}
            //                         clienteId={mensagemJson?._clienteId}
            //                         texto={mensagemJson?._texto}
            //                     />
            //                 </ScrollView>
            //                 , text: Math.random()
            //             }
            //         ])
            //     }
            // }
            //chegou ficha
            else if(mensagemJson.identificador && mensagemJson?.ramal === ramal) {
                new Promise((resolve, _) => {
                    if(props.paramsProcessamentoMensagemSocket) {
                        props.paramsProcessamentoMensagemSocket["mensagem"] = mensagemJson
                        props.paramsProcessamentoMensagemSocket["setTabPanelItems"] = setTabPanelItems
                        props.paramsProcessamentoMensagemSocket["tabPanelItems"] = tabPanelItems
                        props.paramsProcessamentoMensagemSocket["resolve"] = resolve

                        processamentoMensagemSocket(props.paramsProcessamentoMensagemSocket)                    
                    } else {
                        const paramsProcessamentoMensagemSocket = {}
                        paramsProcessamentoMensagemSocket["mensagem"] = mensagemJson
                        paramsProcessamentoMensagemSocket["setTabPanelItems"] = setTabPanelItems
                        paramsProcessamentoMensagemSocket["tabPanelItems"] = tabPanelItems
                        paramsProcessamentoMensagemSocket["resolve"] = resolve

                        props.setParamsProcessamentoMensagemSocketState(paramsProcessamentoMensagemSocket)
            
                        processamentoMensagemSocket(paramsProcessamentoMensagemSocket)                    
                    }

                    sessionStorage.setItem("mensagemSocket", JSON.stringify(mensagemJson));
                })
                .then(() => {
                    const dataSourceFichaAtual = JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"));
                    const exibirOutrosContratos = JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].exibirOutrosContratos;


                    const outrosContratosMenu = mainMenuJson.filter(menu => menu.text.toLowerCase() !== "outros contratos");
                    if ((exibirOutrosContratos === 2 && dataSourceFichaAtual._qtdoutroscontratoscliente > 0) || (exibirOutrosContratos === 1 && dataSourceFichaAtual._qtdoutroscontratosclientecredoratual > 0)) {
                        setMainMenuJson([
                            ...outrosContratosMenu,
                            {
                                text: "Outros contratos",
                                path: "outrosContratosForm",
                                icon: "folder",
                                entityName: "_titulo",
                                badge: null,
                                parameters: null,
                                permissionName: null,
                                items: null
                            }
                        ]); 
                    } else {
                        setMainMenuJson(outrosContratosMenu);
                    }
                })
            }
            // else if(mensagemJson.acao === "CHAT" && mensagemJson._destinatarioId === relationId){
            //     console.log("CHAT")

            //     let jsonMensagensNaoLidasResult = []

            //     if(upfFloatPopupMessageVisibleGlobalState.jsonMensagensNaoLidas?.length > 0){
            //         jsonMensagensNaoLidasResult.concat(upfFloatPopupMessageVisibleGlobalState.jsonMensagensNaoLidas)
            //         jsonMensagensNaoLidasResult.push(mensagemJson)
            //     }
            //     else{
            //         jsonMensagensNaoLidasResult.push(mensagemJson)
            //     }

            //     const mensagemJsonToLowerCase = Object.fromEntries(
            //         Object.entries(mensagemJson).map(([k, v]) => [k.toLowerCase(), v])
            //     );

            //     setUpfFloatPopupMessageVisibleGlobalState({
            //         ...upfFloatPopupMessageVisibleGlobalState,
            //         newMessageSocket: mensagemJsonToLowerCase,
            //         newMessage: true,
            //         jsonMensagensNaoLidas: jsonMensagensNaoLidasResult
            //     })

            // }
        }
        catch{
            console.log(sender.data)
        }
    },
    
    shouldReconnect: (event) => {
        setStatusDiscadorGlobalState({ativo: false, getWebSocket: getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
        console.log(event)
        console.log("requer reconect")
        sessionStorage.removeItem("dataSourceLastMenssageSocket")
        setDataSourceLastMenssageSocketGlobalState(null)
        sessionStorage.removeItem("conectado")
        return clientRef.current === false
        // props.setLoginState(false)
        
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onClose: (event) => { 
        setStatusDiscadorGlobalState({ativo: false, getWebSocket: getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
        console.log(event)
        // props.setLoginState(false)
    },
    onError: (event) => { console.log(event)
        setStatusDiscadorGlobalState({ativo: false, getWebSocket: getWebSocket, loginMsg : JSON.stringify(props.jsonLogin)})
        // setConectionErrorSocketState("Erro ao conectar com servidor socket!"); 
        console.log("Erro ao conectar com servidor socket!"); 
    },
    })

    useEffect(() => {
        return () => {
            clientRef.current = true;
        };
    }, []);

    // window.addEventListener('offline', event => {
    //     console.log(props.jsonLogin)
    //     sessionStorage.setItem("usuarioDesconectou", true)
    // })

    // window.addEventListener('online', event => {
    //     if(sessionStorage.getItem("usuarioDesconectou")){
    //         sessionStorage.setItem("usuarioDesconectou", false)
    //         sendMessage(JSON.stringify(props.jsonLogin))
    //     }
    // })

    
    return(
        <>
            {returnMessageUndefinedState &&
                <Popup
                    onHiding={fecharPopup}
                    visible={returnMessageUndefinedState}
                    height={200}
                    width={350}
                    // title='Atenção!'
                    titleRender={() => {return (<b style={{fontSize: "20px"}}>Atenção!</b>)}}//colocado no lugar da propriedade title, pq as vezes não carregava o nome todo

                >
                    <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
                    {returnMessageUndefinedState}
                </Popup>
            }

            {conectionErrorSocketState &&
                <Popup
                    onHiding={fecharSocketPopup}
                    visible={conectionErrorSocketState}
                    height={200}
                    width={350}
                    // title='Atenção!'
                    titleRender={() => {return (<b style={{fontSize: "20px"}}>Atenção!</b>)}}//colocado no lugar da propriedade title, pq as vezes não carregava o nome todo

                >
                    <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonSocketPopup}
                />
                    {conectionErrorSocketState}
                </Popup>
            }
        </>
    )
}